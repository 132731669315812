import {
  AuthPinApi,
  ClearApiState,
  ConnectorProps,
  GetUserDetailsApi,
  SendResetPinEmailApi,
} from '@hellobrigit/brigit-common';
import { FadeIn } from 'animate-components';
import { partial } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { SetStepAction } from '../../actions/nextStepActions';
import { OnboardingStep } from '../../constants/OnboardingSteps';
import { AppState } from '../../store';
import { setAxiosAuthHeader } from '../../utils/setAxiosAuthHeader';
import { normalizePin, required } from '../../utils/validators';
import { ApiErrorAlert } from '../ApiErrorAlert';
import { BrigitButton } from '../buttons/BrigitButton';
import { LargeLoadingSpinner } from '../LoadingSpinner';
import { Modal } from '../Modal';
import { ModalName } from '../Modal/constants/ModalName';
import { ReduxInputField } from '../ReduxInputField';
import { ButtonText, H1, P } from '../Typography';

interface AuthPinRequest {
  pin: string;
}

const mapStateToProps = (state: AppState) => ({
  getUserDetailsCall: state.api.get(GetUserDetailsApi.id),
  authPinCall: state.api.get(AuthPinApi.id),
  sendResetPinEmailCall: state.api.get(SendResetPinEmailApi.id),
});

const mapDispatchToProps = (dispatch) => ({
  authPin: AuthPinApi.bindDispatch(dispatch),
  sendResetPinEmail: SendResetPinEmailApi.bindDispatch(dispatch),
  ...bindActionCreators(
    {
      setStep: SetStepAction,
      dismissSendResetPinEmailApi: partial(ClearApiState, SendResetPinEmailApi),
    },
    dispatch,
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = InjectedFormProps<AuthPinRequest> &
  ConnectorProps<typeof connector> &
  RouteComponentProps;

export class AuthPin extends React.Component<Props> {
  public componentDidUpdate() {
    const { getUserDetailsCall, setStep, history } = this.props;

    if (getUserDetailsCall.error) {
      setStep({ step: OnboardingStep.LOGIN });
      history.push('/login');
      setAxiosAuthHeader(null);
    }
  }

  public render() {
    const {
      invalid,
      handleSubmit,
      authPinCall,
      sendResetPinEmail,
      sendResetPinEmailCall,
      dismissSendResetPinEmailApi,
    } = this.props;

    return (
      <FadeIn duration="1s">
        <Row className="justify-content-center title-margin-top brigit-row">
          <Col lg="6" md="6" sm="12">
            {!authPinCall.requesting ? (
              <div>
                <H1 className="text-center">Enter Your Pin</H1>
                <div className="pt-2 field-container">
                  <ApiErrorAlert apiAction={AuthPinApi} />
                  <ApiErrorAlert
                    apiAction={SendResetPinEmailApi}
                    content={
                      sendResetPinEmailCall.code === 403 && (
                        <P fixed className="mb-0">
                          {sendResetPinEmailCall.error.message} has not been verified yet. You must
                          verify your email before we can send your reset pin link.
                        </P>
                      )
                    }
                  />
                </div>
                <form onSubmit={handleSubmit(this.submit)}>
                  <div className="py-5 field-container">
                    <Field
                      id="enter-pin"
                      name="pin"
                      component={ReduxInputField}
                      type="password"
                      pattern="\d*"
                      placeholder="Enter Pin"
                      props={{ inputMode: 'numeric' }}
                      maxLength={4}
                      normalize={normalizePin}
                      validate={[required]}
                    />
                  </div>
                  <div className="text-center">
                    <BrigitButton
                      submit
                      invalid={invalid}
                      apiCall={authPinCall}
                      eventStage="authPin"
                    >
                      <ButtonText id="submit-pin" className="font-white">
                        Continue
                      </ButtonText>
                    </BrigitButton>
                  </div>
                  <P
                    className="pt-2 font-dark-green semi-bold pointer text-center"
                    onClick={() => sendResetPinEmail()}
                  >
                    Forgot your pin?
                  </P>
                </form>
              </div>
            ) : (
              <LargeLoadingSpinner color="black" />
            )}
          </Col>
        </Row>
        {/* email sent */}
        <Modal
          isOpen={sendResetPinEmailCall.success}
          onSubmit={dismissSendResetPinEmailApi}
          submitButtonString="Okay"
          title="Forgot your pin?"
          onRequestClose={dismissSendResetPinEmailApi}
          shouldCloseOnOverlayClick
          name={ModalName.SEND_RESET_PIN_EMAIL_CONFIRMATION}
        >
          <P fixed className="text-center">
            We've sent you a confirmation email. Follow the instructions in the email to reset your
            pin.
          </P>
        </Modal>
      </FadeIn>
    );
  }

  private submit = (formData: AuthPinRequest) => this.props.authPin(formData);
}

export const AuthPinForm = connector(
  reduxForm<AuthPinRequest>({
    form: 'authPin',
  })(withRouter(AuthPin)),
);
