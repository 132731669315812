import * as Sentry from '@sentry/browser';
import { fetchAndActivate, getAll } from 'firebase/remote-config';
import { reduce } from 'lodash';
import { Action, Dispatch } from 'redux';
import { SetRemoteConfigAction } from '../actions/firebaseActions';
import { RemoteConfig } from '../remoteConfig';

export function refreshRemoteConfig(FirebaseRemoteConfig, dispatch: Dispatch<Action>) {
  fetchAndActivate(FirebaseRemoteConfig)
    .then(() => {
      // This config is an object with jsonified values.
      // We format this config by parsing values, and returning the original data types.
      const config = getAll(FirebaseRemoteConfig);
      const formattedConfig = reduce(
        config,
        // Value is an object wrapper with getter methods for different data types.
        (obj, Value, key) => {
          // Under the surface, this just returns this._value
          let value = Value.asString();
          try {
            value = JSON.parse(value);
          } catch (error) {
            // No need for reassignment. Value is already a string.
          }
          return {
            ...obj,
            [key]: value,
          };
        },
        {},
      ) as RemoteConfig;
      dispatch(SetRemoteConfigAction(formattedConfig));
    })
    .catch((error) => Sentry.captureException(error));
}
