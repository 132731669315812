import { ConnectorProps, SetNameApi } from '@hellobrigit/brigit-common';
import { FadeIn } from 'animate-components';
import { isNil } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { AppState } from '../../store';
import { required } from '../../utils/validators';
import { ApiErrorAlert } from '../ApiErrorAlert';
import { BrigitButton } from '../buttons/BrigitButton';
import { ReduxInputField } from '../ReduxInputField';
import { ButtonText, H1, P } from '../Typography';

const mapStateToProps = (state: AppState) => {
  const {
    onboarding: { webFunnelSetNameAddContextAssignment },
  } = state;
  return {
    apiCall: state.api.get(SetNameApi.id),
    webFunnelSetNameAddContextAssignment,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setName: SetNameApi.bindDispatch(dispatch),
});

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (stateProps: StoreProps, dispatchProps: DispatchProps) => {
  const { setName } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    onSubmit: (form) => {
      return setName({
        ...form,
      });
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

type Props = InjectedFormProps & ConnectorProps<typeof connector>;

const SetName: FunctionComponent<Props> = (props) => {
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      const lastNameInput = document.getElementById('lastNameInput');
      lastNameInput.focus();
    }
  }

  const { invalid, handleSubmit, apiCall, webFunnelSetNameAddContextAssignment } = props;

  const shouldDisplaySetNameContext = useMemo(
    () =>
      !isNil(webFunnelSetNameAddContextAssignment) &&
      webFunnelSetNameAddContextAssignment.isControlFlow === false,
    [webFunnelSetNameAddContextAssignment],
  );

  return (
    <FadeIn duration="1s">
      <Row className="justify-content-center title-margin-top brigit-row">
        <Col className="mb-3 brigit-row" lg="6" md="6" sm="12">
          <div>
            <H1 className="text-center">What's Your Name?</H1>
            {shouldDisplaySetNameContext && (
              <P className="text-center mb-lg-3 mb-2">
                What's your full legal name? We'll use this information to personalize your
                experience.
              </P>
            )}
            <div className="pt-2 field-container">
              <ApiErrorAlert apiAction={SetNameApi} />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="pt-3 field-container">
                <Field
                  name="firstName"
                  component={ReduxInputField}
                  label="First Name"
                  fullWidth
                  type="text"
                  validate={[required]}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </div>
              <div className="pt-4 pb-5 field-container">
                <Field
                  id="lastNameInput"
                  name="lastName"
                  component={ReduxInputField}
                  label="Last Name"
                  fullWidth
                  type="text"
                  validate={[required]}
                />
              </div>
              <div className="text-center pb-3">
                <BrigitButton submit invalid={invalid} apiCall={apiCall} eventStage="setName">
                  <ButtonText className="font-white">Continue</ButtonText>
                </BrigitButton>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </FadeIn>
  );
};

export const SetNameForm = connector(
  reduxForm({
    form: 'name',
  })(SetName),
);
