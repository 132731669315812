import React, { FunctionComponent } from 'react';
import { P } from '../Typography';
import { SuccessScreenTemplate } from './SuccessScreenTemplate';

export const RecoveryInstallmentsScheduled: FunctionComponent = () => (
  <SuccessScreenTemplate title="Payments Scheduled!">
    <P className="font-grey text-center mb-0">
      We’ve scheduled your payments and sent you a confirmation email.
    </P>
  </SuccessScreenTemplate>
);
