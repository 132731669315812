import { TypedReducer } from 'redoodle';
import { SetABTestsAction } from '../actions/abTestsActions';
import { ABTestsState } from '../store/types';

const builder = TypedReducer.builder<ABTestsState>();

builder.withHandler(SetABTestsAction.TYPE, (state, payload) => {
  return {
    ...state,
    ...payload,
  };
});

export default builder.build();
