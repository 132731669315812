import '../../styles/sideNav.css';
import React, { CSSProperties, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { P } from '../Typography';

interface Props {
  id?: string;
  iconPath?: string;
  text: string;
  navPath: string;
  externalLink?: boolean;
  color?: string;
  imgStyle?: CSSProperties;
}

export const NavIconRow: FunctionComponent<Props> = (props) => {
  const { id, iconPath, text, navPath, externalLink, color, imgStyle } = props;
  const textColor = color || 'dk-font';
  if (externalLink) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={navPath} className="mb-4 d-flex">
        <div className="icon-rp">
          <img src={iconPath} style={imgStyle} />
        </div>
        <P className={`d-flex align-items-center bold mb-0 ${textColor}`}>{text}</P>
      </a>
    );
  } else {
    return (
      <Link id={id} to={navPath} className="mb-4 d-flex">
        <div className="icon-rp">
          <img src={iconPath} style={{ ...imgStyle }} />
        </div>
        <P className={`mb-0 d-flex align-items-center bold ${textColor}`}>{text}</P>
      </Link>
    );
  }
};
