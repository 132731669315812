import { ConnectorProps, LogoutUserActionApi } from '@hellobrigit/brigit-common';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SetStepAction } from '../actions/nextStepActions';
import { OnboardingStep } from '../constants/OnboardingSteps';
import { AppState } from '../store';

const mapDispatchToProps = (dispatch) => {
  return {
    logout: LogoutUserActionApi.bindDispatch(dispatch),
    ...bindActionCreators({ setStep: SetStepAction }, dispatch),
  };
};

const mapStateToProps = (state: AppState) => {
  return { token: state.user.token };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = { token: string } & ConnectorProps<typeof connector>;

const LogoutContainer: FunctionComponent<Props> = (props) => {
  const { token, logout, setStep } = props;

  if (token) {
    logout();
  }

  setStep({ step: OnboardingStep.LOGIN });

  return <Redirect to="/login" />;
};

export default connector(LogoutContainer);
