import { ApiAction, ClearApiState, ConnectorProps } from '@hellobrigit/brigit-common';
import { ApiCallState } from '@hellobrigit/brigit-common/dist/store/ApiState';
import { partial } from 'lodash';
import React, { Component, ComponentType, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import { P } from '../Typography';

interface ApiErrorAlertProps {
  apiAction: ApiAction;
  content?: ReactElement;
  contentComponent?: ComponentType<ApiCallState>;
}

const mapStateToProps = (state: AppState, ownProps: ApiErrorAlertProps) => {
  const { api } = state;
  const { apiAction } = ownProps;
  return {
    apiCall: api.get(apiAction.id),
  };
};

const mapDispatchToProps = (dispatch, ownProps: ApiErrorAlertProps) => {
  const { apiAction } = ownProps;
  return {
    ...bindActionCreators({ clearApiState: partial(ClearApiState, apiAction) }, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector> & ApiErrorAlertProps;

class ApiErrorAlertBase extends Component<Props> {
  public componentWillUnmount() {
    const { apiCall, clearApiState } = this.props;
    if (apiCall.error !== undefined) {
      clearApiState();
    }
  }

  render() {
    const { apiCall, contentComponent: ContentComponent, content } = this.props;
    const showError = apiCall.error !== undefined;
    const customContent = showError && ContentComponent && <ContentComponent {...apiCall} />;

    if (showError) {
      return (
        <Alert color="danger">
          {apiCall.code === 504 && (
            <P className="mb-0">
              Brigit is responding slowly right now, please try again in a little bit.
            </P>
          )}
          {apiCall.code !== 504 &&
            (customContent ||
              content || ( // short circuits to `content` if provided
                // otherwise render default message
                <P className="mb-0">{apiCall.error.message}</P>
              ))}
        </Alert>
      );
    } else {
      return null;
    }
  }
}

export const ApiErrorAlert = connector(ApiErrorAlertBase);
