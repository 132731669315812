import {
  ConnectorProps,
  getMostRecentOutstandingLoan,
  GetUserDetailsApi,
  isProcessing,
} from '@hellobrigit/brigit-common';
import { FrontendSubscriptionPauseStatus, NextStepV4 } from '@hellobrigit/brigit-rest-api';
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { SetStepAction } from '../actions/nextStepActions';
import { ConfirmEmailPage } from '../components/account-settings/ConfirmEmailPage';
import ResetPinPage from '../components/account-settings/ResetPinPage';
import { ConditionalRoute } from '../components/ConditionalRoute';
import { DeactivateSuccessWithAdvance } from '../components/deactivate/DeactivateSuccessWithAdvance';
import { DeleteSuccess } from '../components/delete/DeleteSuccess';
import { LargeLoadingSpinner } from '../components/LoadingSpinner';
import { ManualRepayment } from '../components/repayment/ManualRepayment';
import { OnboardingStep } from '../constants/onboarding';
import { OnboardingRoutes } from '../constants/OnboardingSteps';
import RedditApril5 from '../landingPages/reddit-april-5';
import { AppState } from '../store';
import { primary } from '../utils/colors';
import { DeactivateRoutes, RootRoutes } from '../utils/routes';
import { setAxiosAuthHeader } from '../utils/setAxiosAuthHeader';
import AuthEmailContainer from './AuthEmailContainer';
import AuthPhoneContainer from './AuthPhoneContainer';
import { DeleteContainer } from './DeleteContainer';
import LoginContainer from './LoginContainer';
import LogoutContainer from './LogoutContainer';
import OnboardingContainer from './OnboardingContainer';
import { RecoveryContainer } from './RecoveryV2Container';
import { RelinkBankContainer } from './RelinkBankContainer';

const HomeContainer = React.lazy(() => import('./HomeContainer'));
const SettingsContainer = React.lazy(() =>
  import('./SettingsContainer').then((module) => ({
    default: module.SettingsContainer,
  })),
);
const PauseSuccess = React.lazy(() =>
  import('../components/pause-membership/PauseSuccess').then((module) => ({
    default: module.PauseSuccess,
  })),
);
const DeactivationContainer = React.lazy(() =>
  import('./DeactivationContainer').then((module) => ({
    default: module.DeactivationContainer,
  })),
);
const DowngradeContainer = React.lazy(() =>
  import('./DowngradeContainer').then((module) => ({
    default: module.DowngradeContainer,
  })),
);
const DeactivateSuccess = React.lazy(() =>
  import('../components/deactivate/DeactivateSuccess').then((module) => ({
    default: module.DeactivateSuccess,
  })),
);
const HowItWorks = React.lazy(() => import('../components/HowItWorks'));

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: GetUserDetailsApi.bindDispatch(dispatch),
    ...bindActionCreators({ setStep: SetStepAction }, dispatch),
  };
};

const mapStateToProps = (state: AppState) => {
  const {
    user: {
      token,
      nextStep,
      details: {
        protection: { isEnabled, loansV5, isPendingDeactivation, subscriptionPauseDetails },
        flags: { mayDeleteAccount },
      },
    },
  } = state;
  const outstandingLoan = getMostRecentOutstandingLoan(loansV5);

  return {
    mayDeleteAccount,
    token,
    nextStep,
    isEnabled,
    outstandingLoan,
    isPendingDeactivation,
    pauseStatus: subscriptionPauseDetails?.status,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector> & RouteComponentProps;

class RootContainer extends Component<Props> {
  constructor(props: Props) {
    super(props);
    const { history, getUser, token } = this.props;
    // eslint-disable-next-line no-undef
    history.listen(() => analytics.page());

    // When a user clicks the link for the RESET_PIN route they often have an expired token
    // which ultimately causes them to be routed to the login page instead of the RESET_PIN
    // page. That page doesn't need the getUser() method to load and work properly, skipping
    // it solves that problem without creating any new ones.
    const { pathname } = props.location;
    if (token && !pathname.includes(RootRoutes.RESET_PIN)) {
      setAxiosAuthHeader(token);
      getUser().catch(this.handleIncorrectToken);
    }
  }

  public componentDidMount() {
    const {
      location: { pathname },
      token,
      setStep,
    } = this.props;

    if (pathname === RootRoutes.LOGIN && !token) {
      setStep({ step: OnboardingStep.LOGIN });
    }

    if (pathname === RootRoutes.SIGNUP_EMAIL && !token) {
      setStep({ step: OnboardingStep.EMAIL_NUMBER });
    }
  }

  public handleIncorrectToken = () => {
    const { setStep, history } = this.props;
    setStep({ step: OnboardingStep.LOGIN });
    setAxiosAuthHeader(null);
    history.push(OnboardingRoutes[OnboardingStep.LOGIN]);
  };

  public render() {
    const { token, nextStep, mayDeleteAccount, isEnabled, outstandingLoan, pauseStatus } =
      this.props;
    const isPaused =
      pauseStatus === FrontendSubscriptionPauseStatus.SCHEDULED ||
      pauseStatus === FrontendSubscriptionPauseStatus.ACTIVE;

    return (
      <Suspense
        fallback={
          <div style={{ flex: 1 }}>
            <LargeLoadingSpinner color={primary} />
          </div>
        }
      >
        <Switch>
          <Route
            exact
            path={RootRoutes.ROOT}
            render={() =>
              token && !nextStep ? (
                <Redirect to={RootRoutes.HOME} />
              ) : (
                <Redirect to={RootRoutes.SIGNUP} />
              )
            }
          />
          <Route path={RootRoutes.SIGNUP} render={() => <OnboardingContainer />} />
          <Route path={RootRoutes.AUTH_PHONE} render={() => <AuthPhoneContainer />} />
          <Route path={RootRoutes.AUTH_EMAIL} render={() => <AuthEmailContainer />} />
          <Route path={RootRoutes.HOME} render={(props) => <HomeContainer {...props} />} />
          <Route path={RootRoutes.LOGIN} render={(props) => <LoginContainer {...props} />} />
          <Route path={RootRoutes.LOGOUT} render={() => <LogoutContainer />} />
          <ConditionalRoute
            component={RelinkBankContainer}
            condition={nextStep === NextStepV4.RELINK_BANK}
            path={RootRoutes.RELINK_BANK}
          />
          <ConditionalRoute
            component={SettingsContainer}
            condition={!!token}
            path={RootRoutes.SETTINGS}
          />
          <ConditionalRoute
            component={ManualRepayment}
            condition={
              !!token && isEnabled && outstandingLoan && !isProcessing(outstandingLoan.timeline)
            }
            path={RootRoutes.MANUAL_REPAYMENT}
          />

          {/* Pause Membership */}
          <ConditionalRoute
            component={PauseSuccess}
            condition={!!token && isPaused}
            path={RootRoutes.PAUSE_MEMBERSHIP_SUCCESS}
          />

          {/* Deactivation Routes */}
          <ConditionalRoute
            component={DeactivateSuccessWithAdvance}
            condition={!!token && outstandingLoan && !isEnabled}
            path={DeactivateRoutes.SUCCESS_WITH_ADVANCE}
          />
          <ConditionalRoute
            component={DeactivationContainer}
            condition={!!token}
            path={RootRoutes.DEACTIVATE}
          />
          <ConditionalRoute
            component={DowngradeContainer}
            condition={!!token}
            path={RootRoutes.DOWNGRADE}
          />
          <ConditionalRoute
            component={DeactivateSuccess}
            condition={!!token && !isEnabled}
            path={RootRoutes.DEACTIVATE_SUCCESS}
          />

          {/* Deletion Routes */}
          <ConditionalRoute
            component={DeleteContainer}
            condition={!!token && mayDeleteAccount}
            path={RootRoutes.DELETE_ACCOUNT}
          />
          <ConditionalRoute
            component={DeleteSuccess}
            condition={!token}
            path={RootRoutes.DELETE_SUCCESS}
          />
          <Route path={RootRoutes.HOW_IT_WORKS} render={() => <HowItWorks token={token} />} />

          {/* Reset Pin and Confirm Email Routes */}
          <Route
            path={`${RootRoutes.RESET_PIN}/:tokenId`}
            render={({ match }) => <ResetPinPage token={match.params.tokenId} />}
          />
          <Route
            path={`${RootRoutes.CONFIRM_EMAIL}/:tokenId`}
            render={({ match }) => <ConfirmEmailPage token={match.params.tokenId} />}
          />

          <Route
            path={RootRoutes.RECOVERY_REPAYMENT}
            render={(props) => <RecoveryContainer {...props} />}
          />

          <Route path={RootRoutes.REDDIT_ELI5} render={() => <RedditApril5 />} />
          <Redirect to={RootRoutes.ROOT} />
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(connector(RootContainer));
