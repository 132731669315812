import { ApiAction } from '@hellobrigit/brigit-common';
import React, { FunctionComponent } from 'react';
import { ApiErrorAlert } from '../ApiErrorAlert';

interface ApiErrorAlertGroupProps {
  actions: ApiAction[];
}

export const ApiErrorAlertGroup: FunctionComponent<ApiErrorAlertGroupProps> = ({ actions }) => {
  return (
    <>
      {actions.map((action) => {
        return <ApiErrorAlert apiAction={action} key={action.id} />;
      })}
    </>
  );
};
