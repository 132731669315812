import { TypedReducer } from 'redoodle';
import { SetRemoteConfigAction } from '../actions/firebaseActions';
import { FirebaseState } from '../remoteConfig';

const builder = TypedReducer.builder<FirebaseState>();

builder.withHandler(SetRemoteConfigAction.TYPE, (state, payload) => ({
  ...state,
  remoteConfig: { ...state.remoteConfig, ...payload },
}));

export default builder.build();
