import { useCallback, useState } from 'react';

export function useVerifyRecaptcha() {
  const [token, setToken] = useState<string | null>(null);

  const toggleCaptchaBadge = (show: boolean) => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
    }
  };

  const hideCaptchaBadge = () => toggleCaptchaBadge(false);

  const onVerify = useCallback((providedToken) => {
    setToken(providedToken);
    toggleCaptchaBadge(true);
  }, []);

  return { token, onVerify, hideCaptchaBadge };
}
