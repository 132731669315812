import { ConnectorProps, SetProtectedBankAccountApi } from '@hellobrigit/brigit-common';
import { FadeIn } from 'animate-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { SetStepAction } from '../../actions/nextStepActions';
import { OnboardingStep } from '../../constants/OnboardingSteps';
import { AppState } from '../../store';
import { ApiErrorAlert } from '../ApiErrorAlert';
import { BrigitButton } from '../buttons/BrigitButton';
import { ButtonText, H1, P } from '../Typography';
import { AccountCard } from './AccountCard';

interface State {
  selectedAccountIndex: number;
  isJoint: boolean;
}

const mapStateToProps = (state: AppState) => {
  const {
    api,
    user: {
      details: { bankV2 },
    },
  } = state;

  return {
    apiCall: api.get(SetProtectedBankAccountApi.id),
    bankDetails: bankV2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProtectedAccount: SetProtectedBankAccountApi.bindDispatch(dispatch),
    ...bindActionCreators({ setStep: SetStepAction }, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector>;

class SelectBankAccount extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedAccountIndex: 0,
      isJoint: false,
    };
  }

  public setSelectedAccount = (selectedAccountIndex: number) => {
    this.setState({ selectedAccountIndex });
  };

  public setProtectedAccount = () => {
    const { setProtectedAccount, bankDetails, setStep } = this.props;
    const checkingAccounts = bankDetails?.checkingAccounts;
    const { selectedAccountIndex, isJoint } = this.state;
    const { bankAccountId } = checkingAccounts[selectedAccountIndex];

    setProtectedAccount({ isJoint, protectedBankAccountId: bankAccountId }).then(() => {
      setStep({ step: OnboardingStep.WAIT_FOR_BANK });
    });
  };

  public render() {
    const { selectedAccountIndex, isJoint } = this.state;
    const { bankDetails, apiCall } = this.props;
    const checkingAccounts = bankDetails?.checkingAccounts;

    return (
      <FadeIn duration="1s">
        <Row className="justify-content-center title-margin-top brigit-row">
          <Col lg="6" md="6" sm="12">
            <div className="text-center">
              <H1>Confirm Bank Account</H1>
              <P className="text-center mb-3">
                {checkingAccounts?.length === 1
                  ? "Confirm that this is the account you'd like to connect to Brigit:"
                  : 'For best results, choose the checking account that you most frequently use:'}
              </P>
              <div className="pt-2 field-container">
                <ApiErrorAlert apiAction={SetProtectedBankAccountApi} />
              </div>
              <div className="field-container py-5">
                {checkingAccounts?.map((a, i) => (
                  <AccountCard
                    key={a.bankAccountId}
                    account={a}
                    isSelected={i === selectedAccountIndex}
                    // eslint-disable-next-line react/jsx-no-bind
                    onSelect={this.setSelectedAccount.bind(null, i)}
                    idx={i}
                  />
                ))}
              </div>
              <div className="field-container pb-5">
                <P className="mb-3 bold font-black">Is this a joint account?</P>
                <div className="d-flex justify-content-center">
                  <P className="font-black">Yes</P>
                  <div onClick={() => this.setJointStatus(true)} className="pl-2 pointer">
                    <img src={isJoint ? '/radial_filled.svg' : '/radial_empty.svg'} />
                  </div>
                  <P className="ml-3 font-black">No</P>
                  <div onClick={() => this.setJointStatus(false)} className="pointer pl-2">
                    <img src={!isJoint ? '/radial_filled.svg' : '/radial_empty.svg'} />
                  </div>
                </div>
              </div>
              <div>
                <BrigitButton
                  submit
                  invalid={selectedAccountIndex === undefined}
                  apiCall={apiCall}
                  onClick={this.setProtectedAccount}
                >
                  <ButtonText className="font-white">Continue</ButtonText>
                </BrigitButton>
              </div>
            </div>
          </Col>
        </Row>
      </FadeIn>
    );
  }

  private setJointStatus = (isJoint: boolean) => {
    this.setState({ isJoint });
  };
}

export default connector(SelectBankAccount);
