import React, { FunctionComponent } from 'react';
import { SuccessAnimatedIcon } from '../lottie-icons/success';
import { H4 } from '../Typography';

interface Props {
  title: string;
}

export const SuccessScreenTemplate: FunctionComponent<Props> = ({ title, children }) => (
  <>
    <div style={{ paddingTop: '91px' }}>
      <SuccessAnimatedIcon width={100} />
    </div>
    <H4
      className="mb-0 text-center font-black"
      style={{ paddingTop: '15px', paddingBottom: '15px' }}
    >
      {title}
    </H4>
    {children}
  </>
);
