import React, { FunctionComponent } from 'react';
import { RecoveryLocationState } from '../../../containers/types';
import { DebitRecoveryRoutes, RecoveryRoutes } from '../../../utils/routes';
import { Breadcrumb } from '../../breadcrumbs/Breadcrumb';
import { BreadcrumbItem } from '../../breadcrumbs/BreadcrumbItem';

interface Props extends RecoveryLocationState {
  activeRoute: DebitRecoveryRoutes | RecoveryRoutes;
}

export const DebitBreadCrumbs: FunctionComponent<Props> = ({ activeRoute, payNow }) => {
  const isSchedulePayment =
    activeRoute === DebitRecoveryRoutes.SCHEDULE_PAYMENT ||
    activeRoute === RecoveryRoutes.SET_UP_INSTALLMENT_PLAN;
  const isCardDetails = activeRoute === DebitRecoveryRoutes.CARD_DETAILS;
  const isBillingDetails = activeRoute === DebitRecoveryRoutes.BILLING_DETAILS;
  const isConfirmPayment = activeRoute === DebitRecoveryRoutes.CONFIRM_PAYMENT;

  return (
    <div style={{ paddingTop: 17, paddingBottom: 25 }}>
      <div className="d-block justify-content-start d-lg-flex justify-content-lg-center">
        <Breadcrumb>
          {!payNow && <BreadcrumbItem active={isSchedulePayment} displayName="Set up" />}
          <BreadcrumbItem active={isCardDetails} displayName="Payment" />
          <BreadcrumbItem active={isBillingDetails} displayName="Billing" />
          <BreadcrumbItem active={isConfirmPayment} displayName="Review" />
        </Breadcrumb>
      </div>
    </div>
  );
};
