import { RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import { FadeIn } from 'animate-components';
import React, { FunctionComponent } from 'react';
import { Row } from 'reactstrap';
import { dangerLight } from '../../../utils/colors';
import { RecoveryRoutes } from '../../../utils/routes';
import { BrigitButton } from '../../buttons/BrigitButton';
import { ListHeader } from '../../ListHeader';
import { ButtonText } from '../../Typography';
import { RecoveryErrorAlert } from '../RecoveryErrorAlert';
import { RepaymentHeader } from '../RepaymentHeader';
import { InstallmentDetails } from './InstallmentDetails';

const expiredErrorMessage = (
  <>
    This installment plan's acceptance period has expired. Please contact{' '}
    <a href="mailto:info@hellobrigit.com" className="bold" style={{ color: dangerLight }}>
      info@hellobrigit.com
    </a>{' '}
    to create a new plan.
  </>
);

interface Props {
  navigateToCardDetails: () => void;
  isAcceptancePeriodExpired: boolean;
}

export const ScheduleInstallments: FunctionComponent<Props> = (props) => {
  const { navigateToCardDetails, isAcceptancePeriodExpired } = props;

  return (
    <>
      {/* TODO: in the future, will need to also support ACH */}
      <RepaymentHeader
        paymentMethod={RecoveryPaymentSource.CARD}
        payNow={false}
        activeRoute={RecoveryRoutes.SET_UP_INSTALLMENT_PLAN}
      />
      <Row className="text-lg-center">
        <ListHeader style={{ width: '100%' }}>Payment plan details</ListHeader>
      </Row>
      {isAcceptancePeriodExpired && <RecoveryErrorAlert errors={[expiredErrorMessage]} />}
      <FadeIn duration="1s">
        <div className="d-flex flex-column align-items-center py-2">
          <div style={{ width: '100%', maxWidth: '500px' }}>
            <InstallmentDetails />
          </div>

          {!isAcceptancePeriodExpired && (
            <BrigitButton
              onClick={navigateToCardDetails}
              fullWidthOnMobile
              style={{ marginTop: 30 }}
            >
              <ButtonText className="font-white">Continue to Add Payment</ButtonText>
            </BrigitButton>
          )}
        </div>
      </FadeIn>
    </>
  );
};
