import validator from 'card-validator';
import { parse } from 'libphonenumber-js';
import mailcheck from 'mailcheck';
import { statesInUS } from '../constants/States';

export const required = (value) => (value ? undefined : 'Required');

export const validState = (value) => {
  if (value.length !== 2) {
    return 'Must be 2 characters';
  } else if (!Object.keys(statesInUS).includes(value.toUpperCase())) {
    return 'Not a valid US state';
  } else {
    return undefined;
  }
};

export const validZipCode = (value) => {
  if (value.length < 5) {
    return 'Must be at least 5 digits';
  } else {
    return undefined;
  }
};

export const validPhone = (value) =>
  parse(value, 'US').phone ? undefined : 'Invalid phone number';
export const matchingPhones = (value, allValues) => {
  return allValues.phoneNumber === value ? undefined : 'Numbers Do Not Match';
};

export const matchingPins = (value, allValues) => {
  return allValues.pin === value ? undefined : 'Pins Do Not Match';
};

export const validPinLength = (value) => (value.length === 4 ? undefined : 'Not 4 Digits');

export const normalizePin = (value) => (value.length > 4 ? value.slice(0, 4) : value);

export const validCard = (cardNumber: string) => {
  const result = validator.number(cardNumber);
  if (result.isValid) {
    return undefined;
  } else {
    return 'Invalid Number';
  }
};

export const balls = (value) => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = matches?.[0] || '';
  const parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join(' ');
  } else {
    return value;
  }
};

export const validEmail = (value) => {
  // eslint-disable-next-line no-useless-escape
  if (!/^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/.test(value)) {
    // basic format check
    return 'Please enter a valid email';
  }

  return undefined;
};

export const emailWarning = (value) => {
  let warningResponse;
  mailcheck.run({
    email: value,
    topLevelDomains: ['com', 'net', 'org', 'edu', 'uk', 'ca'],
    suggested: (suggestion) => {
      if (suggestion && value !== suggestion.full) {
        warningResponse = `Did you mean ${suggestion.full}?`;
      }
    },
  });
  return warningResponse;
};
