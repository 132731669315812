import { ApolloClient, concat, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { InvalidationPolicyCache, RenewalPolicy } from '@nerdwallet/apollo-cache-policies';
import { isFinite, isNil, isNumber, isString } from 'lodash';
import { Selector } from '../../graphql/zeus';
import { typedGql } from '../../graphql/zeus/typedDocumentNode';

const scalars = {
  BigDecimal: {
    encode: (e: unknown) => {
      if (isNumber(e) && isFinite(e)) {
        return JSON.stringify(e);
      }
      throw new Error(`Value '${e}' cannot be serialized as a BigDecimal for graphql input`);
    },
    decode: (e: unknown) => {
      if (isNumber(e)) {
        return e;
      }
      if (isString(e)) {
        return Number.parseFloat(e);
      }
      throw new Error(
        `Value '${e}' returned from graphql query cannot be deserialized as a BigDecimal`,
      );
    },
  },
};

export const createQuery = typedGql('query', {
  scalars,
});

const createMutation = typedGql('mutation', {
  scalars,
});

class BrigitGraphQLClient {
  selector = Selector;

  public client: ApolloClient<NormalizedCacheObject>;

  private cache: InvalidationPolicyCache;

  private token: string;

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.setBaseUri(undefined);
  }

  public setBaseUri(token: string | undefined) {
    this.token = token;

    // The Apollo Client doesn't offer first class support for time-based cache invalidation so we
    // are using https://github.com/NerdWalletOSS/apollo-cache-policies.
    // Note: This cache is currently disabled since the client is configured to use "network-only"
    // for the fetch policy
    const cache = new InvalidationPolicyCache({
      typePolicies: {
        UserAccount: {
          // Singleton types that have no identifying field can use an empty
          // array for their keyFields. If we ever return more users we can use
          // "id" as the keyfield here.
          keyFields: [],
        },
      },
      invalidationPolicies: {
        timeToLive: 5 * 60 * 1000, // 5 minute TTL on all types in the cache
        renewalPolicy: RenewalPolicy.WriteOnly,
      },
    });

    const fullURL = `/api/graphql-v1/graphql`;
    // Link without batching
    const batchLink = new HttpLink({
      uri: fullURL,
    });
    // Link with batching
    // const batchLink = new BatchHttpLink({
    //   uri: fullURL,
    //   batchMax: 5, // No more than 8 operations per batch
    //   batchInterval: 20, // Wait no more than 20ms after first batched operation
    // });

    const authMiddleware = setContext(() => {
      let authorization: string | undefined;
      if (!isNil(this.token)) {
        authorization = `Bearer ${this.token}`;
      }
      return {
        headers: {
          'Brigit-Device-Platform': 'web',
          authorization,
        },
      };
    });

    this.cache = cache;

    this.client = new ApolloClient({
      // Provide required constructor fields
      cache,
      link: concat(authMiddleware, batchLink),

      // Provide some optional constructor fields
      name: 'brigit-mobile',
      version: '1.0',
      defaultOptions: {
        watchQuery: {
          // This is the default setting so it always pulls the data from the server
          fetchPolicy: 'network-only',
        },
        query: {
          // This is the default setting so it always pulls the data from the server
          fetchPolicy: 'network-only',
        },
      },
    });
  }

  // @ts-ignore: The type instantiation is not infinate
  query = async <T extends Parameters<typeof createQuery>[0]>(
    o: T,
    ops: Parameters<typeof createQuery>[1],
  ) => {
    const response = await this.client.query({
      query: createQuery(o, ops),
    });
    return response.data;
  };

  // @ts-ignore: The type instantiation is not infinate
  mutate = async <T extends Parameters<typeof createMutation>[0]>(
    o: T,
    ops: Parameters<typeof createMutation>[1],
  ) => {
    // For now, in the interest of not allowing data to get out of sync we want to reset the cache
    // with every mutation call
    const response = await this.client.mutate({
      mutation: createMutation(o, ops),
    });
    if (response.data) {
      return response.data;
    }
    throw new Error('GraphQL query failed to retrieve data');
  };

  resetStore = () => {
    this.cache.reset();
  };
}

export const GraphQL = new BrigitGraphQLClient();
