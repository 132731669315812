import { AuthCodeApi, ConnectorProps } from '@hellobrigit/brigit-common';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { SetStepAction } from '../../actions/nextStepActions';
import { OnboardingStep } from '../../constants/OnboardingSteps';
import { AppState } from '../../store';
import { AuthCodeForm } from '../AuthCodeForm';

const mapStateToProps = (state: AppState) => {
  const {
    onboarding: { phone },
  } = state;
  return {
    apiCall: state.api.get(AuthCodeApi.id),
    phone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authPhone: AuthCodeApi.bindDispatch(dispatch),
    ...bindActionCreators({ setStep: SetStepAction }, dispatch),
  };
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (stateProps: StoreProps, dispatchProps: DispatchProps) => {
  const { authPhone } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    onSubmit: (form) => {
      return authPhone({ ...form });
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

type Props = InjectedFormProps & ConnectorProps<typeof connector>;

const AuthPhone: FunctionComponent<Props> = (props) => {
  const { phone, setStep } = props;
  return (
    <AuthCodeForm
      authFormType="phone"
      authFormValue={phone}
      authCodeFieldName="mobileVerificationCode"
      handleClickTryAgain={() => setStep({ step: OnboardingStep.EMAIL_NUMBER })}
      eventStage="authPhone"
      {...props}
    />
  );
};

export const AuthPhoneForm = connector(
  reduxForm({
    form: 'confirm',
  })(AuthPhone),
);
