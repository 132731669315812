import { ConnectorProps, GetLatestInstallmentPlanApi } from '@hellobrigit/brigit-common';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { bindActionCreators } from 'redux';
import { SetInstallmentPlanDetailsAction } from '../actions/recoveryActions';
import { ApiErrorModal } from '../components/ApiErrorModal';
import { LargeLoadingSpinner } from '../components/LoadingSpinner';
import { ScheduleInstallments } from '../components/recovery/installments/ScheduleInstallments';
import { ViewInstallmentDetails } from '../components/recovery/installments/ViewInstallmentDetails';
import { RecoveryInstallmentsScheduled } from '../components/recovery/RecoveryInstallmentsScheduled';
import { AppState } from '../store';
import { primary } from '../utils/colors';
import {
  DebitRecoveryRoutes,
  InstallmentRoutes,
  RecoveryRoutes,
  RootRoutes,
} from '../utils/routes';
import { InstallmentsDebitContainer } from './InstallmentsDebitContainer';

const mapStateToProps = (state: AppState) => {
  const { api, recoveryDetails } = state;
  const { loanUUID, installmentPlanDetails } = {
    ...recoveryDetails,
  };

  return {
    loanUUID,
    installmentPlanDetails,
    apiCall: api.get(GetLatestInstallmentPlanApi.id),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLatestInstallmentPlan: GetLatestInstallmentPlanApi.bindDispatch(dispatch),
  ...bindActionCreators({ setInstallmentPlanDetails: SetInstallmentPlanDetailsAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector> & RouteComponentProps;

class InstallmentsContainerBase extends Component<Props> {
  public componentDidMount() {
    const { getLatestInstallmentPlan, loanUUID, setInstallmentPlanDetails, history } = this.props;

    if (!loanUUID) {
      history.push(RootRoutes.ROOT);
    }

    getLatestInstallmentPlan(loanUUID).then(({ data }) => {
      const { acceptedAt } = data;
      setInstallmentPlanDetails(data);
      this.handleNavigationForAcceptedStatus(acceptedAt);
    });
  }

  public componentDidUpdate(prevProps) {
    const { installmentPlanDetails } = this.props;

    if (prevProps.installmentPlanDetails?.acceptedAt !== installmentPlanDetails?.acceptedAt) {
      this.handleNavigationForAcceptedStatus(installmentPlanDetails?.acceptedAt);
    }
  }

  render() {
    const {
      loanUUID,
      match: { path, url },
      apiCall: { requesting },
      installmentPlanDetails,
    } = this.props;
    const rootInstallmentsRoute = `${url}?id=${loanUUID}`;
    const firstInstallment = installmentPlanDetails?.installments[0];
    const firstInstallmentDate = firstInstallment?.date;
    const lastDateToAccept =
      firstInstallmentDate && moment(firstInstallmentDate).subtract(1, 'day').endOf('day');
    const isAcceptancePeriodExpired = lastDateToAccept && moment().isAfter(lastDateToAccept);

    return (
      <>
        {requesting && <LargeLoadingSpinner color={primary} />}
        {installmentPlanDetails && (
          <Switch>
            {/* set up installments before confirmation */}
            <Route
              exact
              path={path}
              render={() => (
                <ScheduleInstallments
                  navigateToCardDetails={this.navigateToCardDetails}
                  isAcceptancePeriodExpired={isAcceptancePeriodExpired}
                />
              )}
            />
            {/* or review installment details after confirmation */}
            <Route
              path={`${path}/${InstallmentRoutes.REVIEW_DETAILS}`}
              render={() => <ViewInstallmentDetails />}
            />
            {/* success screen after accepting plan */}
            <Route
              path={`${path}/${InstallmentRoutes.PAYMENTS_SCHEDULED}`}
              render={() => <RecoveryInstallmentsScheduled />}
            />
            {/* if using debit card for installments */}
            <Route
              path={`${path}/${RecoveryRoutes.DEBIT}`}
              render={() => <InstallmentsDebitContainer />}
            />
            <Redirect to={loanUUID ? rootInstallmentsRoute : RootRoutes.ROOT} />
          </Switch>
        )}
        <ApiErrorModal
          apiAction={GetLatestInstallmentPlanApi}
          onRequestClose={this.redirectHome}
          onSubmit={this.redirectHome}
        />
      </>
    );
  }

  private navigateToCardDetails = () => {
    const {
      history,
      loanUUID,
      match: { url },
    } = this.props;

    history.push({
      pathname: `${url}/${RecoveryRoutes.DEBIT}/${DebitRecoveryRoutes.CARD_DETAILS}`,
      search: `?id=${loanUUID}`,
    });
  };

  private redirectHome = () => this.props.history.push(RootRoutes.ROOT);

  private handleNavigationForAcceptedStatus = (acceptedAt: string) => {
    const {
      location: { pathname },
      match: { url },
      loanUUID,
      history,
    } = this.props;

    const setUpInstallmentsPath = url;
    const reviewInstallmentsPath = `${url}/${InstallmentRoutes.REVIEW_DETAILS}`;
    const successInstallmentsPath = `${url}/${InstallmentRoutes.PAYMENTS_SCHEDULED}`;

    if (acceptedAt && pathname !== reviewInstallmentsPath && pathname !== successInstallmentsPath) {
      // user already accepted plan
      history.push(`${reviewInstallmentsPath}?id=${loanUUID}`);
    } else if (!acceptedAt && pathname !== setUpInstallmentsPath) {
      // user has not accepted plan
      history.push(`${setUpInstallmentsPath}?id=${loanUUID}`);
    }
  };
}

export const InstallmentsContainer = connector(InstallmentsContainerBase);
