import { ConnectorProps } from '@hellobrigit/brigit-common';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Card, CardBody } from 'reactstrap';
import { RecoveryLocationState } from '../../../containers/types';
import { AppState } from '../../../store';
import { H5 } from '../../Typography';

const ARROW_RIGHT_ICON_SRC = '/icons/arrow-right.svg';

interface OwnProps {
  logoUrl: string;
  pathname: string;
  type: string;
}

const mapStateToProps = (state: AppState) => {
  const { recoveryDetails } = state;
  const { loanUUID } = { ...recoveryDetails };

  return { loanUUID };
};

const connector = connect(mapStateToProps);

type Props = OwnProps &
  RouteComponentProps &
  ConnectorProps<typeof connector> &
  RecoveryLocationState;

const LinkedPaymentOptionCardBase: FunctionComponent<Props> = (props) => {
  const { pathname, logoUrl, type, history, loanUUID, payNow } = props;

  const handleNavigate = () => {
    const locationState: RecoveryLocationState = { payNow };
    history.push({
      pathname,
      search: `?id=${loanUUID}`,
      state: locationState,
    });
  };

  return (
    <Card className="payment-card center-in-parent pointer" onClick={handleNavigate}>
      <CardBody>
        <div className="font-black">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="d-flex mr-3">
                <img src={logoUrl} />
              </div>
              <H5 className="mb-0 d-flex align-items-center euclid-medium">{type}</H5>
            </div>
            <div className="d-flex align-items-center">
              <img src={ARROW_RIGHT_ICON_SRC} />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export const LinkedPaymentOptionCard = withRouter(connector(LinkedPaymentOptionCardBase));
