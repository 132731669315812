import { TypedReducer } from 'redoodle';
import { SetStepAction } from '../actions/nextStepActions';

export interface OnboardingStageState {
  step?: string;
}

const builder = TypedReducer.builder<OnboardingStageState>();

builder.withHandler(SetStepAction.TYPE, (state, payload) => {
  return { ...state, ...payload };
});

export default builder.build();
