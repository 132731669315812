import * as Sentry from '@sentry/browser';
import { isEqual, isNil } from 'lodash';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { distinctUntilChanged, filter, ignoreElements, map, pairwise, tap } from 'rxjs/operators';
import { AppState } from '../store/types';
import { isProductionEnv } from '../utils/environment';

export const analyticsEpic: Epic<Action, Action, AppState> = (action$, state$) => {
  const idAndTraits$ = state$.pipe(
    map((state) => {
      const {
        user: {
          id,
          details: {
            flags,
            personal: {
              /*
               * We explicitly pull out email to identify the user with, this
               * is mostly for Iterable. If a user that's not on Iterable (perhaps
               * they haven't logged back in for a while), and we identify them
               * with only userId, but no email, Iterable will create a @placeholder.email
               * for them, which is not what we want - poses problems for us later when
               * we accidentally try to send this user an email.
               */
              email,
              /*
               * The rest are pieces of identity that we should minimally identify
               * on the client side. While these may seem unnecessary because we identity these
               * traits on the backend as well, they actually get passed along the `context.traits`
               * property in track events, not just for the `identity` calls.
               * These do get used in facebook audiences for matching to
               * users that have previously seen ads and may affect cost of acquisition.
               */
              firstName,
              lastName,
              phoneNumber,
            },
          },
        },
        firebase: { remoteConfig },
        abTests,
      } = state;

      // As we move forward with using remote config more for storing data/different copy,
      // we should destructure out any non-flag properties (complex objects describing the copy),
      // so that they are not tracked for analytics
      const {
        sideGigsV2,
        workFromHomeJobs,
        fullTimeJobs,
        joinBrigitReasons,
        premiumPerks,
        unemploymentBanner,
        ...remoteConfigFlags
      } = remoteConfig;

      const production = isProductionEnv();

      return {
        id,
        email,
        firstName,
        lastName,
        phone: phoneNumber,
        ...remoteConfigFlags,
        ...flags,
        abTests,
        environment: production ? 'production' : 'staging',
      };
    }),
    filter(({ abTests }) => abTests.initialized),
    distinctUntilChanged((x, y) => isEqual(x, y)),
    tap(({ id }) => {
      Sentry.configureScope((scope) => scope.setUser({ id }));
    }),
    tap(({ id, abTests, ...traits }) => {
      // eslint-disable-next-line no-undef
      analytics.identify(id, {
        ...traits,
        webAbTests: abTests.parameters,
      });
    }),
    pairwise(),
    tap(([prev, curr]) => {
      // Mixpanel requires us to manually alias a defined userId with a previous anonymousId
      if (isNil(prev.id) && !isNil(curr.id)) {
        // eslint-disable-next-line no-undef
        analytics.alias(curr.id, prev.id);
      }
    }),
  );

  return idAndTraits$.pipe(ignoreElements());
};
