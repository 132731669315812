import { ConnectorProps } from '@hellobrigit/brigit-common';
import { noop } from 'lodash';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { AppState } from '../../../store';
import { required } from '../../../utils/validators';
import { BrigitButton } from '../../buttons/BrigitButton';
import { Modal } from '../../Modal';
import { ReduxInputField } from '../../ReduxInputField';
import { ButtonText, H1, LinkText, P } from '../../Typography';

const POST_PAYMENT_SURVEY_FORM = 'postPaymentSurveyForm';

interface PostPaymentSurveyFormData {
  recoveryFlowFeedback: string;
  payLaterReason: string;
  otherFeedback: string;
}
interface OwnProps {
  paymentScheduledLater: boolean;
  isOpen: boolean;
  onClose: () => void;
}

interface FormProps {
  paymentScheduledLater: boolean;
  onClose: () => void;
}

const mapStateToProps = (state: AppState) => {
  const {
    user: { id },
    recoveryDetails,
  } = state;
  const { loanUUID } = { ...recoveryDetails };

  return {
    userId: id,
    loanUUID,
    formValues: getFormValues(POST_PAYMENT_SURVEY_FORM)(state) as PostPaymentSurveyFormData,
  };
};

const connector = connect(mapStateToProps);

type Props = FormProps &
  InjectedFormProps<Record<string, unknown>, FormProps> &
  ConnectorProps<typeof connector>;

const PostPaymentSurveyFormBase: FunctionComponent<Props> = ({
  paymentScheduledLater,
  onClose,
  userId,
  loanUUID,
  formValues,
  invalid,
}) => {
  const handleSubmit = () => {
    const date = new Date();
    analytics.track('Post payment survey submitted', {
      recoveryFlowFeedback: formValues.recoveryFlowFeedback,
      payLaterReason: paymentScheduledLater ? formValues.payLaterReason : undefined,
      otherFeedback: formValues.otherFeedback,
      userId,
      loanUUID,
      submissionDate: date.toISOString(),
    });
    onClose();
  };

  return (
    <>
      <H1 className="text-center mt-3 mb-0" style={{ paddingBottom: 10 }}>
        Help us improve
      </H1>
      <P className="font-black mt-3">Thank you for making a payment.</P>
      <P className="font-black mt-3">
        How could we make this easier for you next time?
        <Field
          id="field-recoveryFlowFeedback"
          name="recoveryFlowFeedback"
          component={ReduxInputField}
          type="textarea"
          validate={[required]}
        />
      </P>
      {paymentScheduledLater && (
        <P className="font-black mt-3">
          Why did you choose Schedule for later?
          <Field
            name="payLaterReason"
            id="field-whyScheduleLater"
            component={ReduxInputField}
            type="text"
            validate={[required]}
          />
        </P>
      )}
      <P className="font-black mt-3">
        Anything else you would like to share with us?
        <Field name="otherFeedback" component={ReduxInputField} type="text" />
      </P>
      <div className="d-flex flex-column align-items-center w-100 mt-4" style={{ paddingTop: 20 }}>
        <BrigitButton
          submit
          onClick={handleSubmit}
          invalid={invalid}
          style={{ width: '100%', fontSize: '18px' }}
        >
          <ButtonText
            id="submit-repayment-survey"
            className="d-flex justify-content-center font-white"
          >
            Submit
          </ButtonText>
        </BrigitButton>
        <LinkText className="font-color-primary pointer mt-3 mb-4">
          <span onClick={onClose}>Cancel</span>
        </LinkText>
      </div>
    </>
  );
};

export const PostPaymentSurveyForm = connector(
  reduxForm<Record<string, unknown>, FormProps>({
    form: POST_PAYMENT_SURVEY_FORM,
  })(PostPaymentSurveyFormBase),
);

export const PostPaymentSurveyModal: FunctionComponent<OwnProps> = ({
  isOpen,
  onClose,
  paymentScheduledLater,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} onSubmit={noop}>
      <PostPaymentSurveyForm paymentScheduledLater={paymentScheduledLater} onClose={onClose} />
    </Modal>
  );
};
