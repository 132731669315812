import { TypedAction } from 'redoodle';
import { Attribution } from '../store/types';

export enum AttributionAction {
  SET_ATTRIBUTION = 'SET_ATTRIBUTION',
}

export const SetAttributionAction = TypedAction.define(
  AttributionAction.SET_ATTRIBUTION,
)<Attribution>();
