import { every, isEmpty } from 'lodash';
/**
 * Takes the query string from the URL (window.location.search) and parses for attribution params to return.
 * @param qs value from window.location.search
 */
export const getAttributionParamsFromQueryString = (qs) => {
  const urlParams = new URLSearchParams(qs);
  let params = {
    signupCode: urlParams.get('signupCode'),
    referralSourceId: urlParams.get('referralSourceId'),
    sourceCampaign:
      urlParams.get('sourceCampaign') || urlParams.get('utm_campaign') || urlParams.get('c'),
    sourceAdType:
      urlParams.get('sourceAdType') || urlParams.get('utm_medium') || urlParams.get('af_ad_type'),
    sourceChannel:
      urlParams.get('sourceChannel') || urlParams.get('utm_source') || urlParams.get('af_channel'),
    sourceAdGroup:
      urlParams.get('sourceAdGroup') || urlParams.get('utm_content') || urlParams.get('adset'),
    sourceKeywords:
      urlParams.get('sourceKeywords') || urlParams.get('utm_term') || urlParams.get('af_keywords'),
    irclickid: urlParams.get('irclickid'),
    snapchatClickID: urlParams.get('ScCid'),
  };

  if (every(params, isEmpty)) {
    params = undefined;
  }
  return params;
};

/**
 *  Randomly shuffles an array using the Durstenfeld shuffle algorithm.
 */
export const shuffleArray = (originalArr: Array<any>) => {
  const arr = originalArr.slice(0);

  for (let i = arr.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }

  return arr;
};
