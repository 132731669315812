import { createMuiTheme } from '@material-ui/core';

export const textFieldTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):before': {
          borderBottom: '1px solid #333333',
        },
        '&:after': {
          borderBottom: '1px solid #333333',
        },
        '&:focus': {
          borderBottom: '1px solid #333333',
        },
        '&.Mui-error:after': {
          borderBottom: '1px solid #333333',
        },
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        fontFamily: 'EuclidCircular-B-Regular',
        '&$error': {
          // increase the specificity for the pseudo class
          color: '#333333',
        },
      },
    },
    MuiFormLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        fontFamily: 'EuclidCircular-B-Regular',
        '&$error': {
          // increase the specificity for the pseudo class
          color: '#dc3545',
        },
        '&$focused': {
          // increase the specificity for the pseudo class
          color: '#333333',
        },
      },
    },
  },
});

export const datePickerTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($error):before': {
          borderBottom: '1px solid #333333',
        },
        '&:after': {
          borderBottom: '1px solid #333333',
        },
        '&:focus': {
          borderBottom: '1px solid #333333',
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'EuclidCircular-B-Regular',
        color: '#24956a',
        '&$error': {
          color: '#dc3545',
        },
        '&$focused': {
          color: '#24956a',
        },
      },
    },
  },
});
