import { TypedReducer } from 'redoodle';
import { SetLocationStateAction } from '../actions/underwritingActions';
import { UnderwritingState } from '../store/types';

const builder = TypedReducer.builder<UnderwritingState>();

builder.withHandler(SetLocationStateAction.TYPE, (state, { locationState }) => {
  return { ...state, locationState };
});

export default builder.build();
