import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { RootRoutes } from '../utils/routes';

interface ConditionalRouteProps extends RouteProps {
  component: React.ComponentType;
  condition: boolean;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customProps?: any;
}

export const ConditionalRoute: FunctionComponent<ConditionalRouteProps> = (props): Route => {
  const { component: Component, condition, path, customProps, ...rest } = props;
  return (
    <Route
      {...rest}
      path={path}
      render={(renderProps) => {
        return condition ? (
          <Component {...renderProps} {...customProps} />
        ) : (
          <Redirect to={RootRoutes.ROOT} />
        );
      }}
    />
  );
};
