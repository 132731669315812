import {
  ConnectorProps,
  GetLoanRepaymentDetailsApi,
  InitiateTabapayDebitApi,
  RegisterTabapayCardRecoveryApiV2,
} from '@hellobrigit/brigit-common';
import { PaymentMethodId } from '@hellobrigit/brigit-rest-api';
import { Moment } from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import { SetRecoveryRepaymentDetailsAction } from '../actions/recoveryActions';
import {
  BILLING_DETAILS_FORM,
  BillingDetails,
  BillingDetailsFormData,
} from '../components/recovery/debit/BillingDetails';
import { CardDetails } from '../components/recovery/debit/CardDetails';
import { ConfirmDebitPayment } from '../components/recovery/debit/ConfirmDebitPayment';
import { ScheduleDebitPayment } from '../components/recovery/debit/ScheduleDebitPayment';
import { AppState } from '../store';
import { determineCardNetwork } from '../utils/cardNetwork';
import { DebitRecoveryRoutes, RootRoutes } from '../utils/routes';
import { RecoveryLocationState } from './types';

interface State {
  tabapayCardDigits: string;
  tabapayToken: string;
  errors: string[];
  paymentMethodId: PaymentMethodId;
  paymentFrom: string;
  selectedPaymentDate: Moment;
}

const mapStateToProps = (state: AppState) => {
  const { recoveryDetails } = state;
  const { loanUUID } = { ...recoveryDetails };

  return {
    loanUUID,
    formValues: getFormValues(BILLING_DETAILS_FORM)(state) as BillingDetailsFormData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLoanRepaymentDetails: GetLoanRepaymentDetailsApi.bindDispatch(dispatch),
  registerTabapayRecoveryCard: RegisterTabapayCardRecoveryApiV2.bindDispatch(dispatch),
  initiateDebit: InitiateTabapayDebitApi.bindDispatch(dispatch),
  ...bindActionCreators({ setRepaymentDetails: SetRecoveryRepaymentDetailsAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector> &
  RouteComponentProps<Record<string, string>, Record<string, unknown>, RecoveryLocationState>;

class DebitRecoveryContainerBase extends Component<Props, State> {
  static addCardPrefix = (cardNumber: string) => {
    const cardPrefix = determineCardNetwork(cardNumber) || 'Card ending in';
    return `${cardPrefix} ${cardNumber.slice(-4)}`;
  };

  constructor(props) {
    super(props);

    this.state = {
      tabapayCardDigits: null,
      tabapayToken: null,
      errors: [],
      paymentMethodId: null,
      paymentFrom: null,
      selectedPaymentDate: null,
    };
  }

  public componentDidMount() {
    const {
      location: { state },
      history,
      loanUUID,
    } = this.props;
    const { payNow } = { ...state };

    if (!loanUUID) {
      history.push(RootRoutes.ROOT);
    } else if (payNow) {
      // if paying now, go directly to card details page
      this.navigate(DebitRecoveryRoutes.CARD_DETAILS);
    } else if (payNow === false) {
      // if not paying now, first go to schedule payment page
      this.navigate(DebitRecoveryRoutes.SCHEDULE_PAYMENT);
    } else {
      // if pay now is undefined, go back to repay now/later page
      // so user can make a selection
      const rootRecoveryRoute = `${RootRoutes.RECOVERY_REPAYMENT}?id=${loanUUID}`;
      history.push(rootRecoveryRoute);
    }
  }

  render() {
    const { errors, paymentFrom, selectedPaymentDate } = this.state;
    const {
      match: { path },
      location: { state },
      loanUUID,
    } = this.props;
    const rootRecoveryRoute = `${RootRoutes.RECOVERY_REPAYMENT}?id=${loanUUID}`;
    const { payNow } = { ...state };
    return (
      <Switch>
        <Route
          path={`${path}/${DebitRecoveryRoutes.SCHEDULE_PAYMENT}`}
          render={() => (
            <ScheduleDebitPayment
              payNow={payNow}
              selectedPaymentDate={selectedPaymentDate}
              setSelectedPaymentDate={this.setSelectedPaymentDate}
              navigateToCardDetails={this.navigateToCardDetails}
              errors={errors}
            />
          )}
        />
        <Route
          path={`${path}/${DebitRecoveryRoutes.CARD_DETAILS}`}
          render={(props) => (
            <CardDetails
              {...props}
              payNow={payNow}
              completeTabapayCardDetails={this.completeTabapayCardDetails}
              setError={this.setError}
              clearErrors={this.clearErrors}
              errors={errors}
              loanUUID={loanUUID}
              hasInstallmentPlan={false}
            />
          )}
        />
        <Route
          path={`${path}/${DebitRecoveryRoutes.BILLING_DETAILS}`}
          render={() => (
            <BillingDetails
              payNow={payNow}
              registerTabapayCard={this.registerTabapayCard}
              errors={errors}
            />
          )}
        />
        <Route
          path={`${path}/${DebitRecoveryRoutes.CONFIRM_PAYMENT}`}
          render={() => (
            <ConfirmDebitPayment
              paymentFrom={paymentFrom}
              payNow={payNow}
              initiateDebit={this.initiateDebit}
              selectedPaymentDate={selectedPaymentDate}
              errors={errors}
            />
          )}
        />
        <Redirect to={loanUUID ? rootRecoveryRoute : RootRoutes.ROOT} />
      </Switch>
    );
  }

  private completeTabapayCardDetails = (tabapayCardDigits: string, tabapayToken: string) => {
    this.setState({ tabapayCardDigits, tabapayToken }, () =>
      this.navigate(DebitRecoveryRoutes.BILLING_DETAILS),
    );
  };

  private navigateToConfirmPayment = () => this.navigate(DebitRecoveryRoutes.CONFIRM_PAYMENT);

  private navigateToCardDetails = () => this.navigate(DebitRecoveryRoutes.CARD_DETAILS);

  private setError = (error: string) =>
    this.setState((prevState) => ({ errors: [...prevState.errors, error] }));

  private clearErrors = () => this.setState({ errors: [] });

  private registerTabapayCard = () => {
    // clear errors for new attempt to register card
    this.clearErrors();
    const { tabapayCardDigits, tabapayToken } = this.state;
    const { loanUUID, formValues, registerTabapayRecoveryCard } = this.props;

    const hasApartment = formValues.lineTwo;

    const userInfo = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      lineOne: formValues.lineOne,
      ...(hasApartment && { lineTwo: formValues.lineTwo }),
      city: formValues.city,
      state: formValues.state,
      zipcode: formValues.zipCode,
    };

    const registerTabapayRecoveryCardRequest = {
      token: tabapayToken,
      userInfo,
      lastFour: tabapayCardDigits.slice(-4),
    };

    registerTabapayRecoveryCard(loanUUID, registerTabapayRecoveryCardRequest)
      .then(({ data }) => {
        const { paymentMethodId } = data;
        this.setState(
          {
            paymentMethodId,
            paymentFrom: DebitRecoveryContainerBase.addCardPrefix(tabapayCardDigits),
          },
          this.navigateToConfirmPayment,
        );
      })
      .catch((error) => {
        const data = error.response?.data;
        const { message = 'We were unable to confirm some of your personal details.' } = {
          ...data,
        };
        this.setError(message);
      });
  };

  private initiateDebit = (repaymentDate: string) => {
    // clear errors for new attempt to initiate debit payment
    this.clearErrors();
    const { paymentMethodId, paymentFrom } = this.state;
    const { initiateDebit, loanUUID, getLoanRepaymentDetails, setRepaymentDetails } = this.props;

    initiateDebit({
      loanUUID,
      repaymentDate,
      nickName: paymentFrom.slice(-4),
      paymentMethodId: paymentMethodId as string,
    })
      .then(() => getLoanRepaymentDetails(loanUUID))
      .then(({ data }) => {
        // get repayment details after initiating payment to refresh status and
        // trigger componentDidUpdate in recovery container that handles
        // navigation to success screen
        setRepaymentDetails({ ...data, loanUUID });
      })
      .catch((error) => {
        const data = error.response?.data;
        const { message = 'We were unable to process and make a charge to your debit card. ' } = {
          ...data,
        };
        this.setError(message);
      });
  };

  private setSelectedPaymentDate = (selectedPaymentDate: Moment) =>
    this.setState({ selectedPaymentDate });

  private navigate = (route: DebitRecoveryRoutes) => {
    const {
      history,
      loanUUID,
      match: { url },
      location: { state },
    } = this.props;

    history.push({
      pathname: `${url}/${route}`,
      search: `?id=${loanUUID}`,
      state,
    });
  };
}

export const DebitRecoveryContainer = withRouter(connector(DebitRecoveryContainerBase));
