import { AuthCodeApi } from '@hellobrigit/brigit-common';
import { ApiCallState } from '@hellobrigit/brigit-common/dist/store/ApiState';
import { FadeIn } from 'animate-components';
import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';
import { Field, InjectedFormProps } from 'redux-form';
import { required } from '../utils/validators';
import { ApiErrorAlert } from './ApiErrorAlert';
import { BrigitButton } from './buttons/BrigitButton';
import { ReduxInputField } from './ReduxInputField';
import { ButtonText, H1, P, P1 } from './Typography';

interface AuthCodeFormProps {
  authCodeFieldName: string;
  apiCall: ApiCallState;
  eventStage: string;
  handleClickTryAgain(): void;
  authFormType: string;
  authFormValue: string;
}

type Props = AuthCodeFormProps & InjectedFormProps;

export const AuthCodeForm: FC<Props> = (props) => {
  const {
    invalid,
    apiCall,
    authCodeFieldName,
    handleSubmit,
    handleClickTryAgain,
    eventStage,
    authFormType,
    authFormValue,
  } = props;

  return (
    <FadeIn duration="1s">
      <Row className="justify-content-center title-margin-top brigit-row">
        <Col lg="6" md="6" sm="12">
          <div>
            <H1 className="text-center">Verify your {authFormType}</H1>
            <P className="text-center mb-lg-3 mb-2">
              with the code we sent to <span className="bold font-black">{authFormValue}</span>
            </P>
            <div className="pt-2 field-container">
              <ApiErrorAlert apiAction={AuthCodeApi} />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="py-5 field-container">
                <Field
                  id="enter-code"
                  name={authCodeFieldName}
                  component={ReduxInputField}
                  type="number"
                  pattern="\d*"
                  label="Enter code"
                  fullWidth
                  props={{ inputMode: 'numeric' }}
                  validate={[required]}
                  autoFocus
                />
              </div>
              <div className="text-center pb-3">
                <BrigitButton submit invalid={invalid} apiCall={apiCall} eventStage={eventStage}>
                  <ButtonText id="submit-code" className="font-white">
                    Continue
                  </ButtonText>
                </BrigitButton>
              </div>
            </form>
            <P1 className="text-center font-color-lg">
              Didn't receive a code?{' '}
              <span className="font-dark-green semi-bold pointer" onClick={handleClickTryAgain}>
                Try again
              </span>
            </P1>
          </div>
        </Col>
      </Row>
    </FadeIn>
  );
};
