import {
  ConnectorProps,
  DisableProtectionApi,
  getMostRecentOutstandingLoan,
  GetRepaymentParametersApi,
  RepayLoanApi,
} from '@hellobrigit/brigit-common';
import { PricingStrategy } from '@hellobrigit/brigit-rest-api';
import { FadeIn } from 'animate-components';
import { find } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { AppState } from '../../store';
import { DeactivateRoutes, RootRoutes } from '../../utils/routes';
import { ApiErrorAlertGroup } from '../ApiErrorAlertGroup';
import { ApiErrorModal } from '../ApiErrorModal';
import { BrigitButton } from '../buttons/BrigitButton';
import { ListHeader } from '../ListHeader';
import { ListItem } from '../ListItem';
import { ListSeparator } from '../ListSeparator';
import { NavWrapper } from '../navigation/NavWrapper';
import { ButtonText, LinkText } from '../Typography';

export enum RepaymentNextStep {
  DEACTIVATE = 'DEACTIVATE',
}

export interface RepaymentLocationState {
  nextStep: RepaymentNextStep;
}

const mapStateToProps = (state: AppState) => {
  const {
    api,
    user: {
      details: {
        bankV2,
        protection: { loansV5, pricingDetails, repaymentParams },
      },
    },
  } = state;

  const pricingStrategy = pricingDetails?.pricingStrategy;
  const pricingAmount = pricingDetails?.pricingAmount;
  const expectedRemovalDate = repaymentParams?.expectedRemovalDate;
  const outstandingLoan = getMostRecentOutstandingLoan(loansV5);

  let balance;
  let accountName;
  if (bankV2) {
    const { balanceAvailable, balanceCurrent, protectedBankAccountId, checkingAccounts } = bankV2;
    balance = balanceAvailable || balanceCurrent;
    const protectedAccount = find(
      checkingAccounts,
      (a) => a.bankAccountId === protectedBankAccountId,
    );
    accountName = protectedAccount?.name;
  }

  return {
    balance,
    accountName,
    pricingStrategy,
    pricingAmount,
    expectedRemovalDate,
    outstandingLoan,
    repayLoanCall: api.get(RepayLoanApi.id),
    disableCall: api.get(DisableProtectionApi.id),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    repayLoan: RepayLoanApi.bindDispatch(dispatch),
    getRepaymentParams: GetRepaymentParametersApi.bindDispatch(dispatch),
    disableProtection: DisableProtectionApi.bindDispatch(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = RouteComponentProps<
  Record<string, string>,
  Record<string, unknown>,
  RepaymentLocationState
> &
  ConnectorProps<typeof connector>;

class ManualRepaymentBase extends Component<Props> {
  public componentDidMount() {
    const { getRepaymentParams, outstandingLoan } = this.props;
    getRepaymentParams({ loanId: outstandingLoan.id });
  }

  public render() {
    const {
      balance,
      accountName,
      pricingStrategy,
      pricingAmount,
      expectedRemovalDate,
      repayLoanCall,
      disableCall,
      outstandingLoan,
    } = this.props;

    return (
      <NavWrapper>
        <Row>
          <ApiErrorAlertGroup actions={[RepayLoanApi, DisableProtectionApi]} />
          <FadeIn className="flex-fill" duration="1s">
            <ListHeader>Repayment Details</ListHeader>
            <ListItem
              right={
                <span className="font-black bold">
                  {numeral(outstandingLoan.outstandingAmount).format('$0,0')}
                </span>
              }
            >
              <span className="font-black">Amount</span>
            </ListItem>
            <ListSeparator />

            {pricingStrategy === PricingStrategy.FLAT_UTILIZATION_FEE && (
              <>
                <ListItem
                  right={
                    <span className="font-black bold">
                      {numeral(pricingAmount).format('$0,0.00')}
                    </span>
                  }
                >
                  <span className="font-black">Fee</span>
                </ListItem>
                <ListSeparator />
              </>
            )}

            <ListItem right={<span className="font-black bold">Today</span>}>
              <span className="font-black">Initiating Repayment</span>
            </ListItem>
            <ListSeparator />

            <ListItem
              right={
                <span className="font-black bold">
                  {moment(expectedRemovalDate, 'YYYYMMDD').format('MMMM D')}
                </span>
              }
            >
              <span className="font-black">Payment clears</span>
            </ListItem>
            <ListSeparator />

            <ListItem
              right={
                <>
                  <div className="font-black bold text-right">{accountName}</div>
                  <div className="font-grey text-right">{numeral(balance).format('$0,0.00')}</div>
                </>
              }
            >
              <span className="font-black">Payment from</span>
            </ListItem>
            <ListSeparator />

            <Container className="del-action" style={{ paddingTop: 30 }}>
              <BrigitButton
                fullWidthOnMobileWithSideNav
                apiCall={repayLoanCall}
                invalid={disableCall.requesting}
                onClick={this.handleNextStep}
                eventStage="manualRepayLoan"
              >
                <ButtonText className="font-white">Confirm repayment</ButtonText>
              </BrigitButton>
              <Link to={this.getGoBackRoute()} style={{ paddingTop: 20 }}>
                <LinkText className="pointer bold font-color-primary">Go back</LinkText>
              </Link>
            </Container>
          </FadeIn>
        </Row>
        <ApiErrorModal
          apiAction={GetRepaymentParametersApi}
          onRequestClose={this.handleGoBack}
          onSubmit={this.handleGoBack}
        />
      </NavWrapper>
    );
  }

  private handleNextStep = () => {
    const {
      location: { state },
    } = this.props;
    const { nextStep } = state;

    switch (nextStep) {
      case RepaymentNextStep.DEACTIVATE:
        this.repayLoanAndRequestDeactivate();
        break;
      default:
        this.repayLoanAndReturnHome();
        break;
    }
  };

  private repayLoanAndRequestDeactivate = () => {
    const {
      history,
      repayLoan,
      disableProtection,
      outstandingLoan,
      location: { state },
    } = this.props;
    const loanId = outstandingLoan.id;
    repayLoan({ loanId })
      .then(() => disableProtection())
      .then(() => {
        history.push(DeactivateRoutes.PAYMENT_PROCESSING, state);
      });
  };

  private repayLoanAndReturnHome = () => {
    const { history, repayLoan, outstandingLoan } = this.props;
    const loanId = outstandingLoan.id;
    repayLoan({ loanId }).then(() => {
      history.push(RootRoutes.ROOT);
    });
  };

  private getGoBackRoute = () => {
    const {
      location: { state },
      outstandingLoan,
    } = this.props;
    const { nextStep } = { ...state };

    switch (nextStep) {
      case RepaymentNextStep.DEACTIVATE:
        if (outstandingLoan.isPrechargeOff) {
          return DeactivateRoutes.PRECHARGE_OFF_NOT_REPAID;
        } else {
          return DeactivateRoutes.NOT_REPAID;
        }
      default:
        return RootRoutes.ROOT;
    }
  };

  private handleGoBack = () => {
    const { history } = this.props;
    history.push(this.getGoBackRoute());
  };
}

export const ManualRepayment = connector(ManualRepaymentBase);
