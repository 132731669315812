import {
  DeleteAccountApi,
  reducers as commonReducers,
  UserState,
} from '@hellobrigit/brigit-common';
import { combineReducers, composeReducers, TypedReducer } from 'redoodle';
import { reducer as form } from 'redux-form';
import { AppState } from '../store/types';
import abTests from './abTestsReducers';
import attribution from './attributionReducers';
import credit from './creditReducers';
import firebase from './firebaseReducers';
import onboardingStage from './nextStepReducers';
import onboarding from './onboardingReducers';
import recoveryDetails from './recoveryReducers';
import subscriptions from './subscriptionsReducers';
import underwriting from './underwritingReducers';

const userBuilderReducer = TypedReducer.builder<UserState>();
// the DeleteSuccess component declared in src/containers/RootContainer depends on the
// token being undefined in order to navigate to that component.
userBuilderReducer.withHandler(DeleteAccountApi.SUCCESS.TYPE, (state, payload) => {
  return { ...state, ...payload, token: undefined };
});

userBuilderReducer.withDefaultHandler(commonReducers.user);

const reducers = {
  ...commonReducers,
  form,
  onboardingStage,
  onboarding,
  attribution,
  recoveryDetails,
  firebase,
  abTests,
  credit,
  subscriptions,
  underwriting,
  user: userBuilderReducer.build(),
};

const combinedReducers = combineReducers<AppState>(reducers);
export const rootReducer = composeReducers(combinedReducers);
