import { TypedAction } from 'redoodle';
import { ModelTypes } from '../graphql/zeus';

export enum OnboardingActionTypes {
  SET_EMAIL = 'SET_EMAIL',
  SET_PHONE = 'SET_PHONE',
  SET_WEB_FUNNEL_SET_NAME_ADD_CONTEXT_ASSIGNMENT = 'SET_WEB_FUNNEL_SET_NAME_ADD_CONTEXT_ASSIGNMENT',
}

interface SetEmailPayload {
  email: string;
}

interface SetPhonePayload {
  phone: string;
}

interface SetWebFunnelSetNameAddContextAssignmentPayload {
  webFunnelSetNameAddContextAssignment: Pick<
    ModelTypes['FrontendExperimentVariantAssignment'],
    'isControlFlow'
  >;
}

export const SetEmailAction = TypedAction.define(
  OnboardingActionTypes.SET_EMAIL,
)<SetEmailPayload>();

export const SetPhoneAction = TypedAction.define(
  OnboardingActionTypes.SET_PHONE,
)<SetPhonePayload>();

export const SetWebFunnelSetNameAddContextAssignmentAction = TypedAction.define(
  OnboardingActionTypes.SET_WEB_FUNNEL_SET_NAME_ADD_CONTEXT_ASSIGNMENT,
)<SetWebFunnelSetNameAddContextAssignmentPayload>();
