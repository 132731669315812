import { RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import { MuiThemeProvider } from '@material-ui/core';
import { FadeIn } from 'animate-components';
import moment, { Moment } from 'moment-business-days';
import React, { FunctionComponent } from 'react';
import { Row } from 'reactstrap';
import { RecoveryLocationState } from '../../../containers/types';
import { datePickerTheme } from '../../../styles/muiTheme';
import { DebitRecoveryRoutes } from '../../../utils/routes';
import { BrigitButton } from '../../buttons/BrigitButton';
import { DatePickerField } from '../../DatePickerField';
import { ListHeader } from '../../ListHeader';
import { ButtonText } from '../../Typography';
import { RecoveryErrorAlert } from '../RecoveryErrorAlert';
import { RepaymentHeader } from '../RepaymentHeader';

const TODAY = moment();
const DATE_MAX = TODAY.businessAdd(30);
const DATE_MIN = TODAY.businessAdd(1);

interface OwnProps {
  selectedPaymentDate: Moment;
  setSelectedPaymentDate: (date: Moment) => void;
  navigateToCardDetails: () => void;
  errors: string[];
}

type Props = OwnProps & RecoveryLocationState;

export const ScheduleDebitPayment: FunctionComponent<Props> = (props) => {
  const { payNow, selectedPaymentDate, setSelectedPaymentDate, navigateToCardDetails, errors } =
    props;

  return (
    <MuiThemeProvider theme={datePickerTheme}>
      <RepaymentHeader
        paymentMethod={RecoveryPaymentSource.CARD}
        payNow={payNow}
        activeRoute={DebitRecoveryRoutes.SCHEDULE_PAYMENT}
      />
      <Row className="text-lg-center">
        <ListHeader style={{ width: '100%' }}>Schedule payment</ListHeader>
      </Row>
      {errors.length > 0 && <RecoveryErrorAlert errors={errors} />}

      <div className="d-flex flex-column align-items-center py-2">
        <FadeIn duration="1s" style={{ width: '100%', maxWidth: '500px' }}>
          <DatePickerField
            isBusDayOnly={false}
            selectedDate={selectedPaymentDate}
            setSelectedDate={setSelectedPaymentDate}
            label="Scheduled Payment Date"
            maxDate={DATE_MAX}
            minDate={DATE_MIN}
          />
        </FadeIn>

        <BrigitButton
          onClick={navigateToCardDetails}
          invalid={!selectedPaymentDate}
          fullWidthOnMobile
          style={{ marginTop: 30 }}
        >
          <ButtonText className="font-white">Continue to Add Payment Details</ButtonText>
        </BrigitButton>
      </div>
    </MuiThemeProvider>
  );
};
