import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { assignParameter } from '../abTests/parameters';
import {
  freeSubscription as freeSubscriptionConfig,
  presetPauseSubscriptionDays as presetPauseSubscriptionDaysConfig,
} from '../abTests/parametersConfig';
import { SetABTestsAction } from '../actions/abTestsActions';
import { AppState } from '../store/types';

export const abTestsEpic: Epic<Action, Action, AppState> = (action$, state$) => {
  return action$.pipe(
    withLatestFrom(state$),
    map(([, state]) => {
      const {
        user: { id },
        abTests: { initialized },
      } = state;
      return {
        id,
        initialized,
      };
    }),
    filter(({ id, initialized }) => id && !initialized),
    map(({ id }) =>
      SetABTestsAction({
        initialized: true,
        parameters: {
          presetPauseSubscriptionDays: assignParameter(id, presetPauseSubscriptionDaysConfig),
          freeSubscription: assignParameter(id, freeSubscriptionConfig),
        },
      }),
    ),
  );
};
