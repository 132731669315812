import { apiCallMiddleware } from '@hellobrigit/brigit-common';
import { loggingMiddleware } from 'redoodle';
import { Action, applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { appStateEnhancer } from '../enhancers/appStateEnhancer';
import { analyticsMiddleware } from '../middleware/analyticsMiddleware';
import { attributionMiddleware } from '../middleware/attributionMiddleware';
import { creditBuilderConfigurationMiddleware } from '../middleware/creditBuilderConfigurationMiddleware';
import { subscriptionsMiddleware } from '../middleware/subscriptionsMiddleware';
import { underwritingMiddleware } from '../middleware/underwritingMiddleware';
import { webApiCallMiddleware } from '../middleware/webApiCallMiddleware';
import { observeStore, rootEpic } from '../observables';
import { rootReducer } from '../reducers';
import { initialState } from './initialState';
import { AppState } from './types';

const epicMiddleware = createEpicMiddleware<Action, Action, AppState>();

let enhancers;
if (process.env.NODE_ENV === 'development') {
  const composeEnhancers = composeWithDevTools({
    name: 'brigit',
  });

  enhancers = composeEnhancers(
    appStateEnhancer,
    applyMiddleware(
      apiCallMiddleware,
      webApiCallMiddleware,
      attributionMiddleware,
      epicMiddleware,
      loggingMiddleware({ enableInProduction: false }),
      subscriptionsMiddleware,
      creditBuilderConfigurationMiddleware,
      underwritingMiddleware,
    ),
  );
} else {
  enhancers = compose(
    appStateEnhancer,
    applyMiddleware(
      apiCallMiddleware,
      analyticsMiddleware,
      webApiCallMiddleware,
      attributionMiddleware,
      epicMiddleware,
      loggingMiddleware({ enableInProduction: false }),
      subscriptionsMiddleware,
      creditBuilderConfigurationMiddleware,
      underwritingMiddleware,
    ),
  );
}
const persistConfig = {
  key: 'brigit-app',
  storage,
  whitelist: ['user', 'config', 'onboardingStage', 'onboarding', 'attribution'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, initialState, enhancers);

observeStore(store);
epicMiddleware.run(rootEpic);

export const persistor = persistStore(store);
