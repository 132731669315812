import {
  ConnectorProps,
  LoginUserApi,
  required,
  validPhoneNumber,
} from '@hellobrigit/brigit-common';
import { FadeIn } from 'animate-components';
import { AsYouType } from 'libphonenumber-js';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Field, InjectedFormProps, reduxForm, reset } from 'redux-form';
import { SetStepAction } from '../../actions/nextStepActions';
import { SetEmailAction, SetPhoneAction } from '../../actions/onboardingActions';
import { InternalUrls } from '../../constants/InternalUrls';
import { OnboardingRoutes, OnboardingStep } from '../../constants/OnboardingSteps';
import { useVerifyRecaptcha } from '../../hooks/useVerifyRecaptcha';
import { AppState } from '../../store';
import { isProductionEnv } from '../../utils/environment';
import { HttpHeader } from '../../utils/http';
import { validEmail } from '../../utils/validators';
import { ApiErrorAlert } from '../ApiErrorAlert';
import { BrigitButton } from '../buttons/BrigitButton';
import { ReduxInputField } from '../ReduxInputField';
import { ButtonText, H1, P, P1, P2 } from '../Typography';

const mapStateToProps = (state: AppState) => ({
  apiCall: state.api.get(LoginUserApi.id),
});

const mapDispatchToProps = (dispatch) => {
  return {
    login: LoginUserApi.bindDispatch(dispatch),
    resetForm: () => dispatch(reset('login')),
    ...bindActionCreators(
      { setStep: SetStepAction, setEmail: SetEmailAction, setPhone: SetPhoneAction },
      dispatch,
    ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = RouteComponentProps & InjectedFormProps & ConnectorProps<typeof connector>;

const PhoneLoginInstructions: FunctionComponent = () => (
  <>
    <P1 className="font-color-lg">We'll send you an SMS to confirm your number.</P1>
    <P2 className="font-color-lg">
      By providing your phone number and tapping 'Text me a code', you agree to receive promotional
      emails and texts from Brigit. Message and data rates may apply.{' '}
      <a
        href={InternalUrls.TERMS_OF_SERVICE}
        target="_blank"
        rel="noopener noreferrer"
        className="font-grey"
        style={{ textDecoration: 'underline' }}
      >
        Terms of Service
      </a>{' '}
      and{' '}
      <a
        href={InternalUrls.PRIVACY_POLICY}
        target="_blank"
        rel="noopener noreferrer"
        className="font-grey"
        style={{ textDecoration: 'underline' }}
      >
        Privacy Policy
      </a>
      .
    </P2>
  </>
);

const EmailLoginInstructions: FunctionComponent = () => (
  <>
    <P1 className="font-color-lg">We'll email you a code to verify it's you.</P1>
    <P2 className="font-color-lg">
      By providing your email and tapping 'Email me a code', you agree to receive promotional emails
      and texts from Brigit. Message and data rates may apply.{' '}
      <a
        href={InternalUrls.TERMS_OF_SERVICE}
        target="_blank"
        rel="noopener noreferrer"
        className="font-grey"
        style={{ textDecoration: 'underline' }}
      >
        Terms of Service
      </a>{' '}
      and{' '}
      <a
        href={InternalUrls.PRIVACY_POLICY}
        target="_blank"
        rel="noopener noreferrer"
        className="font-grey"
        style={{ textDecoration: 'underline' }}
      >
        Privacy Policy
      </a>
      .
    </P2>
  </>
);

const PhoneLoginField: FunctionComponent = () => (
  <Field
    id="phoneNumber"
    name="phoneNumber"
    component={ReduxInputField}
    type="tel"
    format={(value) => new AsYouType('US').input(value).replace(/\s*\)$/, '')}
    placeholder="Phone"
    validate={[required, validPhoneNumber]}
    autoFocus
  />
);

const EmailLoginField: FunctionComponent = () => (
  <Field
    id="emailAddress"
    name="emailAddress"
    component={ReduxInputField}
    type="email"
    placeholder="Email Address"
    validate={[required, validEmail]}
    autoFocus
  />
);

const enableCaptcha = isProductionEnv();

const Login: FunctionComponent<Props> = (props) => {
  const { invalid, apiCall, setEmail, setPhone, setStep, handleSubmit, resetForm } = props;
  const [loginType, setLoginType] = useState('phone');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const shouldResubmitFormWithNewCaptcha = useRef(false);
  const previousToken = useRef<string>();
  const resubmitForm = useRef<(token?: string) => void>();

  const isPhoneLogintype = useMemo(() => loginType === 'phone', [loginType]);

  const handleChangeLoginType = useCallback(() => {
    setRefreshReCaptcha(false);
    resetForm();
    setLoginType(loginType === 'phone' ? 'email' : 'phone');
  }, [loginType, resetForm]);
  const { token, hideCaptchaBadge, onVerify } = useVerifyRecaptcha();

  const submitHandler = useCallback(
    (form, _, api) => {
      window.gtag('event', 'click', { category: 'log-in-button' });
      const submitForm = (recaptchaToken = token) => {
        api.login(
          {
            ...form,
          },
          enableCaptcha && { headers: { [HttpHeader.RECAPTCHA]: recaptchaToken } },
          hideCaptchaBadge(),
        );
      };
      if (isPhoneLogintype) {
        setPhone({ phone: form.phoneNumber });
      } else {
        setEmail({ email: form.emailAddress });
      }
      submitForm();
      // Store the callback to resubmit with refreshed recaptcha if neccessary
      resubmitForm.current = submitForm;
    },
    [hideCaptchaBadge, isPhoneLogintype, setEmail, setPhone, token],
  );

  // If the API call fails, get a new recapcha code to submit
  useEffect(() => {
    if (
      apiCall?.error &&
      apiCall?.error.message.includes('reCAPTCHA') &&
      shouldResubmitFormWithNewCaptcha.current === false
    ) {
      shouldResubmitFormWithNewCaptcha.current = true;
      setRefreshReCaptcha(!refreshReCaptcha);
    }
  }, [apiCall?.error, refreshReCaptcha]);

  // Submit the form with a new recapcha code if the form failed due to the recapcha code
  useEffect(() => {
    if (shouldResubmitFormWithNewCaptcha.current === true && token !== previousToken.current) {
      shouldResubmitFormWithNewCaptcha.current = false;
      if (resubmitForm.current) {
        resubmitForm.current(token);
      }
      resubmitForm.current = undefined;
    }
  }, [token, apiCall?.error, handleSubmit, submitHandler]);

  useEffect(() => {
    previousToken.current = token;
  }, [token]);

  return (
    <FadeIn duration="1s">
      <Row className="justify-content-center title-margin-top brigit-row">
        <Col lg="6" md="6" sm="12">
          <div>
            {enableCaptcha && (
              <div className="Google-Recaptcha-id">
                <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
              </div>
            )}
            <H1 className="text-center">Welcome Back!</H1>
            <P className="text-center mb-lg-3 mb-2">
              Sign in with your {isPhoneLogintype ? 'phone number' : 'email address'}
            </P>
            <div className="pt-2 field-container">
              <ApiErrorAlert apiAction={LoginUserApi} />
            </div>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="pt-4 pb-4 field-container">
                {isPhoneLogintype ? <PhoneLoginField /> : <EmailLoginField />}
              </div>
              <div
                className="text-center field-container"
                style={{ maxWidth: '464px', marginBottom: '55px' }}
              >
                {isPhoneLogintype ? <PhoneLoginInstructions /> : <EmailLoginInstructions />}
              </div>

              <div className="text-center pb-3">
                <BrigitButton
                  id={isPhoneLogintype ? 'submit-phone' : 'submit-email'}
                  style={{ marginBottom: '24px' }}
                  submit
                  invalid={invalid}
                  apiCall={apiCall}
                  eventStage="login"
                >
                  <ButtonText className="font-white">
                    {isPhoneLogintype ? 'Text me a code' : 'Email me a code'}
                  </ButtonText>
                </BrigitButton>
                <P
                  className="font-dark-green semi-bold pointer text-center"
                  onClick={handleChangeLoginType}
                >
                  {isPhoneLogintype ? 'Sign in with email' : 'Sign in with phone'}
                </P>
              </div>
            </form>
            <P className="text-center">
              Don't have an account?{' '}
              <Link
                to={OnboardingRoutes[OnboardingStep.EMAIL_NUMBER]}
                onClick={() => setStep({ step: OnboardingStep.EMAIL_NUMBER })}
                className="font-dark-green semi-bold"
              >
                Sign Up
              </Link>
            </P>
          </div>
        </Col>
      </Row>
    </FadeIn>
  );
};

export const LoginForm = connector(
  reduxForm({
    form: 'login',
  })(Login),
);
