import {
  ConnectorProps,
  FetchIdentityAndInitiateSVBDebitApi,
  GetRecoveryRepaymentParamsApi,
} from '@hellobrigit/brigit-common';
import { CheckingAccountResponse, RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import { FadeIn } from 'animate-components';
import moment, { Moment } from 'moment';
import numeral from 'numeral';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Row } from 'reactstrap';
import { InternalUrls } from '../../../constants/InternalUrls';
import { RecoveryLocationState } from '../../../containers/types';
import { AppState } from '../../../store';
import { BankRecoveryRoutes } from '../../../utils/routes';
import { ApiErrorModal } from '../../ApiErrorModal';
import { BrigitButton } from '../../buttons/BrigitButton';
import { ListHeader } from '../../ListHeader';
import { ListItem } from '../../ListItem';
import { ListSeparator } from '../../ListSeparator';
import { ButtonText, P, P2 } from '../../Typography';
import { RecoveryErrorAlert } from '../RecoveryErrorAlert';
import { RepaymentHeader } from '../RepaymentHeader';

const mapStateToProps = (state: AppState) => {
  const { api, recoveryDetails } = state;
  const { dueAmount, loanUUID } = { ...recoveryDetails };

  return {
    apiCall: api.get(FetchIdentityAndInitiateSVBDebitApi.id),
    dueAmount,
    loanUUID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRecoveryRepaymentParams: GetRecoveryRepaymentParamsApi.bindDispatch(dispatch),
});

interface OwnProps {
  selectedAccount: CheckingAccountResponse;
  selectedPaymentDate?: Moment;
  initiateSVBDebit: (repaymentDate: string) => void;
  errors: string[];
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps &
  RecoveryLocationState &
  ConnectorProps<typeof connector> &
  RouteComponentProps;

const ConfirmBankPaymentBase: FunctionComponent<Props> = (props) => {
  const {
    payNow,
    selectedPaymentDate,
    dueAmount,
    selectedAccount,
    apiCall,
    initiateSVBDebit,
    getRecoveryRepaymentParams,
    loanUUID,
    history: { goBack },
    errors,
  } = props;

  const paymentFrom = selectedAccount && `${selectedAccount.name} ${selectedAccount.mask}`;
  const formattedDueAmount = numeral(dueAmount).format('$0,0');
  const [expectedRemovalDate, setExpectedRemovalDate] = useState<string>(null);

  const repaymentDate = payNow ? moment().startOf('day') : selectedPaymentDate;
  const paymentClearsDate =
    payNow && expectedRemovalDate ? moment(expectedRemovalDate, 'YYYY-MM-DD') : selectedPaymentDate;

  useEffect(
    () => {
      if (payNow) {
        getRecoveryRepaymentParams(loanUUID).then(({ data }) =>
          setExpectedRemovalDate(data.expectedRemovalDate),
        );
      }
    },
    // Emulating componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const initiatePayment = () => initiateSVBDebit(repaymentDate.format('YYYY-MM-DD'));

  return (
    <>
      <RepaymentHeader
        paymentMethod={RecoveryPaymentSource.ACH}
        payNow={payNow}
        activeRoute={BankRecoveryRoutes.CONFIRM_PAYMENT}
      />
      <Row className="text-lg-center">
        <ListHeader style={{ width: '100%' }}>Confirm details</ListHeader>
      </Row>
      {errors.length > 0 && <RecoveryErrorAlert errors={errors} />}

      <div className="d-flex flex-column align-items-center py-2">
        <FadeIn duration="1s" style={{ width: '100%', maxWidth: '500px' }}>
          <ListItem right={<P className="font-black mb-0">{formattedDueAmount}</P>}>
            <P className="font-black semi-bold mb-0">Amount</P>
          </ListItem>
          <ListSeparator />
          <ListItem right={<P className="font-black mb-0">{paymentFrom}</P>}>
            <P className="font-black semi-bold mb-0">Payment From</P>
          </ListItem>
          <ListSeparator />
          <ListItem
            right={<P className="font-black mb-0">{repaymentDate?.format('MMMM D, YYYY')}</P>}
          >
            <P className="font-black semi-bold mb-0">
              {payNow ? 'Payment date' : 'Scheduled payment date'}
            </P>
          </ListItem>
          <ListSeparator />
          <ListItem
            right={<P className="font-black mb-0">{paymentClearsDate?.format('MMMM D, YYYY')}</P>}
          >
            <P className="font-black semi-bold mb-0">Payment clears</P>
          </ListItem>
          <ListSeparator />
          <P2
            className="font-grey mb-0"
            style={{
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingTop: '10px',
            }}
          >
            By clicking pay now, you agree to our{' '}
            <a
              href={InternalUrls.TERMS_OF_SERVICE}
              target="_blank"
              rel="noopener noreferrer"
              className="bold font-grey"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href={InternalUrls.PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
              className="bold font-grey"
            >
              Privacy Policy
            </a>
            .
          </P2>
        </FadeIn>

        <BrigitButton
          eventStage="initiateBankRecoveryPayment"
          onClick={initiatePayment}
          invalid={!repaymentDate}
          apiCall={apiCall}
          fullWidthOnMobile
          style={{ marginTop: 30 }}
        >
          <ButtonText className="font-white">
            {payNow ? `Confirm and Pay ${formattedDueAmount}` : 'Confirm and Schedule Payment'}
          </ButtonText>
        </BrigitButton>
      </div>
      <ApiErrorModal
        apiAction={GetRecoveryRepaymentParamsApi}
        onRequestClose={goBack}
        onSubmit={goBack}
      />
    </>
  );
};

export const ConfirmBankPayment = withRouter(connector(ConfirmBankPaymentBase));
