import { ChevronRight } from '@material-ui/icons';
import React, { CSSProperties, FunctionComponent } from 'react';
import { light } from '../utils/colors';

interface Props {
  left?: JSX.Element;
  right?: JSX.Element;
  caret?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}

export const ListItem: FunctionComponent<Props> = (props) => {
  const { children, left, right, caret, style, onClick } = props;

  return (
    <div
      className="font-black"
      style={{
        padding: '25px 15px',
        ...style,
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {left && <div style={{ paddingRight: 15 }}>{left}</div>}
        <div style={{ flexShrink: 1, flex: 1 }}>{children}</div>
        {right && <div style={{ paddingLeft: 15 }}>{right}</div>}
        {caret && <ChevronRight style={{ color: light }} />}
      </div>
    </div>
  );
};
