import '../../styles/main.css';
import '../../styles/navBar.css';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import { Link } from 'react-router-dom';
import { Col, Navbar, Row } from 'reactstrap';
import { NAV_LOGO_PATH } from '../../constants/navLogoPath';
import { RootRoutes } from '../../utils/routes';

interface HomeProps {
  small?: boolean;
  isOpen: boolean;
  toggle: () => void;
}

export const NavBar: FunctionComponent<HomeProps> = (props) => {
  const { isOpen, toggle, small } = props;
  const colHeight = small ? '50px' : '75px';
  const imgWidth = small ? '60px' : '90px';

  const linkClass = classNames({
    'px-0': true,
    'pt-2': small,
    'pt-3': !small,
  });

  return (
    <Row className="bg-dark-green">
      <Col className="d-none d-lg-block" style={{ height: colHeight }}>
        <Navbar color="faded" light>
          <Link to={RootRoutes.ROOT} className={linkClass}>
            <img style={{ width: imgWidth, height: 'auto' }} src={NAV_LOGO_PATH} />
          </Link>
        </Navbar>
      </Col>
      <Col className="d-sm-block d-md-block d-lg-none" style={{ height: colHeight }}>
        <Navbar color="faded" light>
          <Link to={RootRoutes.ROOT} className={linkClass}>
            <img style={{ width: imgWidth, height: 'auto' }} src={NAV_LOGO_PATH} />
          </Link>
          <span id="small-nav" style={{ cursor: 'pointer' }}>
            <HamburgerMenu
              isOpen={isOpen}
              menuClicked={toggle}
              width={small ? 25 : 30}
              height={small ? 15 : 20}
              strokeWidth={2}
              rotate={0}
              color="white"
              borderRadius={0}
              animationDuration={0.5}
              style={{ zIndex: 1000 }}
            />
          </span>
        </Navbar>
      </Col>
    </Row>
  );
};
