import { ConnectorProps, InitiateTabapayDebitApi } from '@hellobrigit/brigit-common';
import { RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import { FadeIn } from 'animate-components';
import moment, { Moment } from 'moment';
import numeral from 'numeral';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { InternalUrls } from '../../../constants/InternalUrls';
import { RecoveryLocationState } from '../../../containers/types';
import { AppState } from '../../../store';
import { DebitRecoveryRoutes } from '../../../utils/routes';
import { BrigitButton } from '../../buttons/BrigitButton';
import { ListHeader } from '../../ListHeader';
import { ListItem } from '../../ListItem';
import { ListSeparator } from '../../ListSeparator';
import { ButtonText, P, P2 } from '../../Typography';
import { RecoveryErrorAlert } from '../RecoveryErrorAlert';
import { RepaymentHeader } from '../RepaymentHeader';

const mapStateToProps = (state: AppState) => {
  const { api, recoveryDetails } = state;
  const { dueAmount } = { ...recoveryDetails };

  return {
    apiCall: api.get(InitiateTabapayDebitApi.id),
    dueAmount,
  };
};

interface OwnProps {
  paymentFrom: string;
  selectedPaymentDate?: Moment;
  initiateDebit: (repaymentDate: string) => void;
  errors: string[];
}

const connector = connect(mapStateToProps);

type Props = OwnProps & RecoveryLocationState & ConnectorProps<typeof connector>;

const ConfirmDebitPaymentBase: FunctionComponent<Props> = (props) => {
  const { payNow, selectedPaymentDate, dueAmount, paymentFrom, apiCall, initiateDebit, errors } =
    props;
  const formattedDueAmount = numeral(dueAmount).format('$0,0');
  const repaymentDate = payNow ? moment().startOf('day') : selectedPaymentDate;

  const initiatePayment = () => initiateDebit(repaymentDate.format('YYYY-MM-DD'));

  return (
    <>
      <RepaymentHeader
        paymentMethod={RecoveryPaymentSource.CARD}
        payNow={payNow}
        activeRoute={DebitRecoveryRoutes.CONFIRM_PAYMENT}
      />
      <Row className="text-lg-center">
        <ListHeader style={{ width: '100%' }}>Confirm details</ListHeader>
      </Row>
      {errors.length > 0 && <RecoveryErrorAlert errors={errors} />}

      <div className="d-flex flex-column align-items-center py-2">
        <FadeIn duration="1s" style={{ width: '100%', maxWidth: '500px' }}>
          <ListItem right={<P className="font-black mb-0">{formattedDueAmount}</P>}>
            <P className="font-black semi-bold mb-0">Total due</P>
          </ListItem>
          <ListSeparator />
          <ListItem right={<P className="font-black mb-0">{paymentFrom}</P>}>
            <P className="font-black semi-bold mb-0">Payment From</P>
          </ListItem>
          <ListSeparator />
          <ListItem
            right={<P className="font-black mb-0">{repaymentDate?.format('MMMM D, YYYY')}</P>}
          >
            <P className="font-black semi-bold mb-0">
              {payNow ? 'Payment date' : 'Scheduled payment date'}
            </P>
          </ListItem>
          <ListSeparator />
          <P2
            className="font-grey mb-0"
            style={{
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingTop: '10px',
            }}
          >
            By clicking pay now, you agree to our{' '}
            <a
              href={InternalUrls.TERMS_OF_SERVICE}
              target="_blank"
              rel="noopener noreferrer"
              className="bold font-grey"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href={InternalUrls.PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
              className="bold font-grey"
            >
              Privacy Policy
            </a>
            .
          </P2>
        </FadeIn>

        <BrigitButton
          eventStage="initiateDebitRecoveryPayment"
          onClick={initiatePayment}
          invalid={!repaymentDate}
          apiCall={apiCall}
          fullWidthOnMobile
          style={{ marginTop: 30 }}
        >
          <ButtonText id="submit-confirm-pay" className="font-white">
            {payNow ? `Confirm and Pay ${formattedDueAmount}` : 'Confirm and Schedule Payment'}
          </ButtonText>
        </BrigitButton>
      </div>
    </>
  );
};

export const ConfirmDebitPayment = connector(ConfirmDebitPaymentBase);
