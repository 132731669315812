import { RehydrateAction } from '@hellobrigit/brigit-common';
import { Middleware } from 'redux';
import { SetAttributionAction } from '../actions/AttributionActions';
import { getAttributionParamsFromQueryString } from '../utils/helpers';

export const attributionMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);
  /**
   * In the past, we used to grab URL / UTM params on store initialization, only
   * for it to be overwritten away by the Rehydrate action via redux-persist in
   * the case where a user visited with UTM params in the past
   * This allows us to grab the URL / UTM params AFTER the rehydrate so it ensures
   * that whatever is in the URL is what we want in our store
   * If there are no parameters in the URL but there are values from the reyhdrate,
   * the values from rehydrate are what we want in the store
   */
  if (RehydrateAction.is(action)) {
    const currAttribution = getAttributionParamsFromQueryString(window.location.search);
    store.dispatch(SetAttributionAction.create(currAttribution));
  }

  return result;
};
