import { RetrieveMonitoringEnrollments } from '@hellobrigit/brigit-common/dist/actions/creditActions';
import { TypedReducer } from 'redoodle';
import { SetCreditBuilderGlobalConfigurationLoadedAction } from '../actions/creditBuilderConfigurationActions';
import { CreditState } from '../store/types';

const builder = TypedReducer.builder<CreditState>();

builder.withHandler(RetrieveMonitoringEnrollments.SUCCESS.TYPE, (state, payload) => {
  return { ...state, enrollments: payload };
});

builder.withHandler(
  SetCreditBuilderGlobalConfigurationLoadedAction.TYPE,
  (state, { cbUnsupportedStates, cbUnsupportedStatesNamesString }) => {
    return { ...state, cbUnsupportedStates, cbUnsupportedStatesNamesString };
  },
);

export default builder.build();
