import '../styles/animations.css';
import React, { FunctionComponent } from 'react';
import { ClipLoader } from 'react-spinners';

interface Props {
  color: string;
}

export const LargeLoadingSpinner: FunctionComponent<Props> = ({ color }) => {
  return (
    <div className="d-flex justify-content-center mt-4">
      <ClipLoader color={color} size={100} />
    </div>
  );
};

export const SmallLoadingSpinner: FunctionComponent<Props> = ({ color }) => {
  return (
    <div className="d-flex justify-content-center">
      <ClipLoader color={color} size={30} />
    </div>
  );
};
