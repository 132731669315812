import { AppState as CommonAppState } from '@hellobrigit/brigit-common';
import { FrontendInstallmentPlan, RepaymentDetailsResponse } from '@hellobrigit/brigit-rest-api';
import { FormStateMap } from 'redux-form';
import {
  ChangeSubscriptionTierOutcome,
  ModelTypes,
  Product,
  SubscriptionTierLevel,
} from '../graphql/zeus';
import { FirebaseState } from '../remoteConfig';

export interface Parameters {
  [parameter: string]: any;
}

export interface ABTestsState {
  initialized: boolean;
  parameters: Parameters;
}

export interface OnboardingStageState {
  step?: string;
}

export interface Attribution {
  signupCode?: string;
  referralSourceId?: string;
  sourceCampaign?: string;
  sourceAdType?: string;
  sourceChannel?: string;
  sourceAdGroup?: string;
  sourceKeywords?: string;
  irclickid?: string;
  snapchatClickID?: string;
}

export interface RecoveryDetailsState extends RepaymentDetailsResponse {
  loanUUID: string;
  installmentPlanDetails?: FrontendInstallmentPlan;
}

export interface UsaState {
  name: string;
  code: string;
}
export interface CreditState {
  enrollments?: string[];
  cbUnsupportedStates?: UsaState[];
  cbUnsupportedStatesNamesString?: string;
}

export interface SubscriptionTierPromotion {
  numMonths?: number | undefined;
  discountedAmount: number;
}

export interface SubscriptionTier {
  level: SubscriptionTierLevel;
  displayName: string;
  pricingAmount: number;
  products: Product[];
  isActiveTier?: boolean;
  availablePromotion?: SubscriptionTierPromotion | undefined;
}

/**
 * Represents the subscription experience that users are on as we move
 * into a tiered subscriptions world.
 */
export enum SubscriptionType {
  /**
   * True "Legacy" users who are not on tiered subscriptions at all
   */
  DEFAULT,

  /**
   * Users who onboarded in a tiered subscriptions world and were sampled for
   * access to the free tier and the new Pro and Plus tiers.
   */
  TIERED_SUBSCRIPTIONS_NEW_TIERS,
}

export interface GraphQLErrorMessage {
  message: string;
  userFacingMessage: string;
}

export interface SubscriptionsState {
  availableSubscriptionTiers: SubscriptionTier[];
  currentSubscriptionTier: SubscriptionTier | undefined;
  subscriptionType: SubscriptionType | undefined;
  subscriptionScheduleText: string | undefined;
  changeSubscriptionTierOutcome: ChangeSubscriptionTierOutcome;
  changeSubscriptionTierError: GraphQLErrorMessage;
  changeSubscriptionTierLoading: boolean;
  proposedBillDate: string | undefined;
  loadSubscriptionTiersLoading: boolean;
}

export interface UnderwritingState {
  locationState: string | undefined;
}

export interface OnboardingState {
  phone?: string;
  email?: string;
  webFunnelSetNameAddContextAssignment?: Pick<
    ModelTypes['FrontendExperimentVariantAssignment'],
    'isControlFlow'
  >;
}

export interface AppState extends CommonAppState {
  form: FormStateMap;
  onboardingStage: OnboardingStageState;
  onboarding: OnboardingState;
  attribution: Attribution;
  recoveryDetails: RecoveryDetailsState;
  firebase: FirebaseState;
  abTests: ABTestsState;
  credit: CreditState;
  subscriptions: SubscriptionsState;
  underwriting: UnderwritingState;
}
