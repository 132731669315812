import '../styles/onboarding/ReduxInputField.css';
import { MuiThemeProvider, TextField } from '@material-ui/core';
import React, { FunctionComponent, InputHTMLAttributes, KeyboardEvent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { textFieldTheme } from '../styles/muiTheme';

export const ReduxInputField: FunctionComponent<
  WrappedFieldProps & InputHTMLAttributes<Record<string, unknown>>
> = (props) => {
  const KEY_EXCEPTIONS = [
    '0',
    'Backspace',
    'Tab',
    'Meta',
    'v',
    'z',
    'a',
    'ArrowLeft',
    'ArrowRight',
  ];
  const {
    placeholder,
    input,
    type,
    meta: { error, touched, warning },
    inputMode,
    onKeyDown,
    ...rest
  } = props;
  const isTelType = type === 'tel';
  const touchedAndError = touched && error !== undefined;

  return (
    <div
      role="presentation"
      onKeyDown={(e) => {
        if (isTelType && !Number(e.key) && !(KEY_EXCEPTIONS.indexOf(e.key) >= 0)) {
          e.preventDefault();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (onKeyDown) onKeyDown(e as KeyboardEvent<any>);
      }}
    >
      <MuiThemeProvider theme={textFieldTheme}>
        <TextField
          fullWidth
          label={placeholder}
          type={type}
          inputProps={{
            style: { fontFamily: 'EuclidCircular-B-Light', color: '#333333', borderBottom: 0 },
            inputMode,
          }}
          {...input}
          error={touchedAndError}
          {...(rest as Record<string, unknown>)}
        />
      </MuiThemeProvider>
      {touched &&
        ((error && <div className="error pb-3">{error}</div>) ||
          (warning && <div className="warning pb-3">{warning}</div>))}
    </div>
  );
};
