import { Config } from './parametersConfig';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const seedrandom = require('seedrandom');

//  https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
/* eslint-disable no-bitwise */
const hashCode = (str: string) =>
  Array.from(str).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);

export const getDeterministicRand = (userId: string, offset: number) => {
  const hashedSeed = hashCode(userId) + offset;
  const double = seedrandom(hashedSeed).double();
  return double;
};

/**
 * A function that assigns AB testing variants to a user. It creates a number from 0 - 1 using the the hashed userId + offset as a seed.
 * Then, assigns the user a variant out of the list.
 * @param userId UUID
 * @param variants Object with the variants.
 * @param offset Randomizes variants for different flags.
 */
export const assignParameter = (userId: string, config: Config) => {
  const { variants, offset } = config;
  const numVariants = variants.length;
  const double = getDeterministicRand(userId, offset);
  const index = Math.floor(double * numVariants);
  return variants[index];
};
