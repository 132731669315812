// primary
export const primary = '#24956A'; // dark green
export const primaryLight = '#27D682'; // light green

// secondary
export const secondary = '#3C6CD3'; // dark blue
export const secondaryLight = '#00AEFF'; // light blue

// grays
export const dark = '#333333';
export const semiDark = '#696969';
export const light = '#B3B3B3';
export const lighter = '#EBEBEB';
export const semiLighter = '#F5F5F5';
export const lightest = '#F4F4F4';
export const lightest2 = '#FAFAFA';

// accents
export const warning = '#F5A724'; // dark yellow
export const warningLight = '#F1C732'; // light red
export const danger = '#A73440'; // dark red
export const dangerLight = '#F53758'; // light yellow

export const white = '#FFFFFFF';

// bg colors
export const warningBg = 'rgba(241,199,50,0.15)';
export const primaryBg = 'rgba(39,214,130,0.15)';
export const dangerBg = 'rgba(245,55,88,0.15)';
