import { RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import { FadeIn } from 'animate-components';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Row } from 'reactstrap';
import { TABAPAY_PRODUCTION_SSO_URL, TABAPAY_SANDBOX_SSO_URL } from '../../../api/ApiConstants';
import { RecoveryLocationState } from '../../../containers/types';
import { isProductionEnv } from '../../../utils/environment';
import { DebitRecoveryRoutes, RecoveryRoutes, RootRoutes } from '../../../utils/routes';
import { BrigitButton } from '../../buttons/BrigitButton';
import { ListHeader } from '../../ListHeader';
import { ButtonText } from '../../Typography';
import { RecoveryErrorAlert } from '../RecoveryErrorAlert';
import { RepaymentHeader } from '../RepaymentHeader';

const TABAPAY_URL = isProductionEnv() ? TABAPAY_PRODUCTION_SSO_URL : TABAPAY_SANDBOX_SSO_URL;

interface OwnProps {
  completeTabapayCardDetails: (tabapayCardDigits: string, tabapayToken: string) => void;
  setError: (error: string) => void;
  clearErrors: () => void;
  errors: string[];
  loanUUID: string;
  hasInstallmentPlan: boolean;
}

type Props = OwnProps & RecoveryLocationState & RouteComponentProps;

export class CardDetails extends Component<Props> {
  public componentDidMount() {
    window.addEventListener('message', this.tabapayEventHandler, false);
  }

  public componentWillUnmount() {
    window.removeEventListener('message', this.tabapayEventHandler, false);
  }

  render() {
    const { payNow, errors, hasInstallmentPlan } = this.props;
    return (
      <>
        <RepaymentHeader
          paymentMethod={RecoveryPaymentSource.CARD}
          payNow={payNow}
          activeRoute={DebitRecoveryRoutes.CARD_DETAILS}
        />
        <Row className="text-lg-center">
          <ListHeader style={{ width: '100%' }}>Add card details</ListHeader>
        </Row>
        {errors.length > 0 && <RecoveryErrorAlert errors={errors} />}

        <FadeIn
          duration="1s"
          style={{
            display: 'flex',
            paddingTop: '25px',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <iframe
            className="border-0 overflow-hidden w-100"
            scrolling="no"
            title="Tabapay Debit Card Form"
            height="320"
            src={TABAPAY_URL}
            data-hj-allow-iframe=""
          />
          {!hasInstallmentPlan && (
            <BrigitButton
              onClick={this.navigatePaymentOptions}
              inverted
              fullWidthOnMobile
              style={{ marginBottom: 15 }}
              eventStage="recoveryRepayLater"
            >
              <ButtonText
                className="font-dark-green"
                style={{ paddingTop: '2px', paddingBottom: '2px' }}
              >
                Choose different payment method
              </ButtonText>
            </BrigitButton>
          )}
        </FadeIn>
      </>
    );
  }

  private tabapayEventHandler = (event: MessageEvent) => {
    const { completeTabapayCardDetails, setError, clearErrors, history } = this.props;
    // If message doesn't originate from the Tabapay iFrame, return.
    if (!TABAPAY_URL.includes(event.origin)) return;

    // clear errors first before handling new event
    clearErrors();

    // event.data returns an error or a string with a combination of digits, expiration and token.
    if (event.data.includes('Error')) {
      setError('We were unable to confirm some of your personal details.');
      // eslint-disable-next-line no-undef
      analytics.track('Tabapay Card Error Occurred');
    } else if (event.data === 'Close') {
      history.goBack();
    } else {
      const data = event.data.split('|');
      if (data.length === 3) {
        const digits = data[0];
        const token = data[2];
        completeTabapayCardDetails(digits, token);
      } else {
        setError('We were unable to confirm some of your personal details.');
        // eslint-disable-next-line no-undef
        analytics.track('Tabapay Card Error Occurred');
      }
    }
  };

  private navigatePaymentOptions = () => {
    const { payNow, history, loanUUID } = this.props;
    const locationState: RecoveryLocationState = { payNow };

    history.push({
      pathname: `${RootRoutes.RECOVERY_REPAYMENT}/${RecoveryRoutes.PAYMENT_OPTIONS}`,
      search: `?id=${loanUUID}`,
      state: locationState,
    });
  };
}
