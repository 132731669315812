import { useQuery } from '@apollo/client';
import {
  ConnectorProps,
  GetUserDetailsApi,
  PreEnableProtectionApi,
} from '@hellobrigit/brigit-common';
import { FadeIn } from 'animate-components';
import { isNil } from 'lodash';
import React, { FC, useMemo } from 'react';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { SetStepAction } from '../../actions/nextStepActions';
import * as animationData from '../../assets/lottie/progressLoading.json';
import { OnboardingStep } from '../../constants/OnboardingSteps';
import { ExperimentName, FrontendSampleKeyType, SamplingTrigger } from '../../graphql/zeus';
import { typedGql } from '../../graphql/zeus/typedDocumentNode';
import { AppState } from '../../store';
import { ApiErrorModal } from '../ApiErrorModal';
import { BrigitButton } from '../buttons/BrigitButton';
import { ButtonText, H1, P } from '../Typography';

const mapStateToProps = (state: AppState) => {
  const {
    user: {
      details: { bankV2 },
      id,
      token,
    },
  } = state;
  return { bankDetails: bankV2, id, token };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: GetUserDetailsApi.bindDispatch(dispatch),
    preEnableProtection: PreEnableProtectionApi.bindDispatch(dispatch),
    ...bindActionCreators({ setStep: SetStepAction }, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector>;

const BankConnectProgressBase: FC<Props> = (props) => {
  const { bankDetails, id, token } = props;
  const percent = bankDetails?.connectProgress ? bankDetails.connectProgress.progressPercent : 0;

  const query = typedGql('query')({
    experimentVariantAssignment: [
      {
        name: ExperimentName.WEB_FUNNEL_SHOULD_DISPLAY_HOW_IT_WORKS_ONBOARDING_TEST,
        sampleKeyType: FrontendSampleKeyType.USER_ID,
        sampleKey: id,
        samplingTrigger: SamplingTrigger.WEB_BANK_CONNECT_PROGRESS,
        shouldPerformAssignment: true,
      },
      {
        isControlFlow: true,
      },
    ],
  });

  /**
   * We aren't utilizing error handling here, instead we are just showing the default experience
   * if this call fails
   */
  const { data } = useQuery(query);

  const shouldShowHowItWorksOnboarding = useMemo(() => {
    if (isNil(data)) {
      // We want to show how it works by default if the experiment call fails
      return true;
    }
    /**
     * We want to show how it works if the experiment is in the control flow or if the assignment
     * was not retrieved for some reason (error on API call)
     */
    return (
      isNil(data.experimentVariantAssignment) ||
      data.experimentVariantAssignment.isControlFlow === true
    );
  }, [data]);

  const [nextStep, nextRoute] = useMemo(() => {
    if (shouldShowHowItWorksOnboarding) {
      return ['HOW_IT_WORKS', '/how_it_works'];
    } else if (token) {
      return ['HOME', '/home'];
    } else {
      return ['EMAIL_NUMBER', '/email'];
    }
  }, [shouldShowHowItWorksOnboarding, token]);

  const handleClick = () => {
    const { preEnableProtection, getUserDetails, setStep } = props;
    return preEnableProtection().then(() => {
      getUserDetails();
      setStep({ step: OnboardingStep[nextStep] });
    });
  };

  const renderProgress = (progressPercent) => {
    let img;
    let title;
    let text;
    if (progressPercent <= 40) {
      img = '/bank_progress/gathering-info.png';
      title = 'Gathering info';
      text = 'Reviewing your income history and past expenses';
    } else if (progressPercent < 100) {
      img = '/bank_progress/calculating.png';
      title = 'Calculating';
      text = 'Determining the funds we will send you';
    } else if (progressPercent === 100) {
      img = '/bank_progress/done.png';
      img = '/bank_progress/done.png';
      title = 'Done!';
      text = 'Our calculation is complete!';
    } else {
      return null;
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <Row className="justify-content-center title-margin-top brigit-row">
        <Col lg="6" md="6" sm="12">
          <div className="text-center">
            <img className="mb-lg-5 mb-2" style={{ height: '150px' }} src={img} />
            <H1>{title}</H1>
            <P className="mb-5">{text}</P>
            <div>
              {progressPercent !== 100 ? (
                <Lottie options={defaultOptions} height={50} isStopped={false} isPaused={false} />
              ) : (
                <Link to={nextRoute}>
                  <BrigitButton
                    block
                    submit
                    className="onboarding-submit nohover-dg bg-dark-green mt-3"
                    onClick={handleClick}
                  >
                    <ButtonText className="font-white">Proceed</ButtonText>
                  </BrigitButton>
                </Link>
              )}
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <FadeIn duration="1s">
      {renderProgress(percent)}
      <ApiErrorModal apiAction={GetUserDetailsApi} />
      <ApiErrorModal apiAction={PreEnableProtectionApi} />
    </FadeIn>
  );
};

export const BankConnectProgress = connector(BankConnectProgressBase);
