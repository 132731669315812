import { CheckingAccountResponse, FrontendAccountWithCardV5 } from '@hellobrigit/brigit-rest-api';
import numeral from 'numeral';
import React, { FunctionComponent } from 'react';
import { Card } from 'reactstrap';
import { P, P1 } from '../Typography';

interface CardProps {
  account: CheckingAccountResponse | FrontendAccountWithCardV5;
  isSelected: boolean;
  onSelect: () => void;
  idx: number;
}

export const AccountCard: FunctionComponent<CardProps> = ({
  onSelect,
  isSelected,
  account,
  idx,
}) => {
  const backgroundColor = 'white';
  const radialButton = isSelected ? '/radial_filled.svg' : '/radial_empty.svg';
  const style = {
    borderRight: 'none',
    borderLeft: 'none',
    borderRadius: 0,
    borderTop: '1px solid rgba(0,0,0,.125)',
  };

  if (idx !== 0) {
    style.borderTop = 'none';
  }
  return (
    <Card body onClick={onSelect} inverse={!isSelected} color={backgroundColor} style={style}>
      <div className="d-flex justify-content-between py-2">
        <div className="d-flex flex-column text-left">
          <P fixed className="font-dark-grey mb-0">
            {account.name} - {account.mask}
          </P>
          <P1 fixed className="font-light-grey mb-0">
            {numeral(
              (account as FrontendAccountWithCardV5).currentBalance ||
                (account as CheckingAccountResponse).balanceAvailable,
            ).format('$0,0.00')}
          </P1>
        </div>
        <div className="d-flex">
          <img src={radialButton} />
        </div>
      </div>
    </Card>
  );
};
