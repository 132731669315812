import { TypedAction } from 'redoodle';

export enum OnboardingActionTypes {
  SET_STEP = 'SET_STEP',
}

interface GetOnboardingStagePayload {
  step: string;
}

export const SetStepAction = TypedAction.define(
  OnboardingActionTypes.SET_STEP,
)<GetOnboardingStagePayload>();
