import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { LinkedBrigitLogo } from './LinkedBrigitLogo';

interface Props {
  linkUrl: string;
}

export const BannerCornerLogo: FunctionComponent<Props> = ({ linkUrl }) => {
  const isMobile = window.innerWidth <= 500;
  const brigitLogoWidth = !isMobile ? '90px' : '60px';
  return (
    <Row className="bg-dark-green py-4">
      <Col sm="12">
        <div className="d-flex justify-content-between">
          <div className="ml-0 ml-md-5">
            <LinkedBrigitLogo
              url={linkUrl}
              style={{ width: brigitLogoWidth }}
              navLogoPath="/logos/brigit-white-logo-2024.png"
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};
