import { initialState as commonInitialState } from '@hellobrigit/brigit-common';
import { initialRemoteConfigState } from '../remoteConfig';
import { AppState } from './types';

export const initialState: AppState = {
  ...commonInitialState,
  form: {},
  onboardingStage: {
    step: 'EMAIL_NUMBER',
  },
  onboarding: {
    phone: undefined,
    email: undefined,
  },
  attribution: {
    signupCode: undefined,
    referralSourceId: undefined,
    sourceCampaign: undefined,
    sourceAdType: undefined,
    sourceChannel: undefined,
    sourceAdGroup: undefined,
    sourceKeywords: undefined,
    irclickid: undefined,
    snapchatClickID: undefined,
  },
  firebase: {
    adminOverride: false,
    deviceToken: undefined,
    remoteConfig: initialRemoteConfigState,
  },
  recoveryDetails: {
    dueAmount: undefined,
    isTabapayUser: false,
    status: undefined,
    userId: undefined,
    loanUUID: undefined,
    validPaymentMethods: [],
    scheduledPaymentDate: undefined,
    installmentPlanDetails: undefined,
  },
  abTests: {
    initialized: false,
    parameters: {
      presetPauseSubscriptionDays: 31,
    },
  },
  credit: {
    enrollments: [],
    cbUnsupportedStates: [],
    cbUnsupportedStatesNamesString: '',
  },
  subscriptions: {
    availableSubscriptionTiers: [],
    currentSubscriptionTier: undefined,
    subscriptionType: undefined,
    subscriptionScheduleText: undefined,
    changeSubscriptionTierLoading: false,
    changeSubscriptionTierOutcome: undefined,
    changeSubscriptionTierError: {
      message: '',
      userFacingMessage: '',
    },
    proposedBillDate: '',
    loadSubscriptionTiersLoading: false,
  },
  underwriting: {
    locationState: undefined,
  },
};
