import { ConnectorProps } from '@hellobrigit/brigit-common';
import { RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import { FadeIn } from 'animate-components';
import { isNil } from 'lodash';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Col, Row } from 'reactstrap';
import { RecoveryLocationState } from '../../../containers/types';
import { AppState } from '../../../store';
import { RecoveryRoutes, RootRoutes } from '../../../utils/routes';
import { P } from '../../Typography';
import { LinkedPaymentOptionCard } from './LinkedPaymentOptionCard';

const BANK_LOGO_URL = '/icons/bank.svg';
const DEBIT_LOGO_URL = '/icons/debit-card.svg';

const mapStateToProps = (state: AppState) => {
  const { recoveryDetails } = state;
  const { validPaymentMethods, loanUUID } = { ...recoveryDetails };

  return { validPaymentMethods, loanUUID };
};

const connector = connect(mapStateToProps);

type Props = RouteComponentProps & ConnectorProps<typeof connector>;

const PaymentOptionsBase: FunctionComponent<Props> = (props) => {
  const {
    validPaymentMethods,
    loanUUID,
    history,
    location: { state },
  } = props;
  const { payNow } = { ...(state as RecoveryLocationState) };

  useEffect(
    () => {
      if (!loanUUID) {
        history.push(RootRoutes.ROOT);
      } else if (isNil(payNow)) {
        const rootRecoveryRoute = `${RootRoutes.RECOVERY_REPAYMENT}?id=${loanUUID}`;
        history.push(rootRecoveryRoute);
      }
    },
    // Emulating componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Row className="font-black bg-dark-grey flex-grow-1">
      <Col>
        <FadeIn duration="1s">
          <P style={{ paddingTop: '30px' }} className="font-black mb-0">
            Select payment method:
          </P>

          <div className="my-3">
            {validPaymentMethods.includes(RecoveryPaymentSource.CARD) && (
              <div className="mb-3">
                <LinkedPaymentOptionCard
                  logoUrl={DEBIT_LOGO_URL}
                  pathname={`${RootRoutes.RECOVERY_REPAYMENT}/${RecoveryRoutes.DEBIT}`}
                  payNow={payNow}
                  type="Debit Card"
                />
              </div>
            )}

            {validPaymentMethods.includes(RecoveryPaymentSource.ACH) && (
              <div className="mb-3 text-center">
                <LinkedPaymentOptionCard
                  logoUrl={BANK_LOGO_URL}
                  pathname={`${RootRoutes.RECOVERY_REPAYMENT}/${RecoveryRoutes.BANK}`}
                  payNow={payNow}
                  type="Bank Account"
                />
              </div>
            )}
          </div>
        </FadeIn>
      </Col>
    </Row>
  );
};

export const PaymentOptions = connector(PaymentOptionsBase);
