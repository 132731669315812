import '../../styles/modal.css';
import React, { Component, CSSProperties, ReactElement } from 'react';
import ReactModal from 'react-modal';
import { BrigitButton } from '../buttons/BrigitButton';
import { ButtonText, H1, LinkText, P } from '../Typography';

interface ModalStyle {
  overlay?: CSSProperties;
  content: CSSProperties;
}

export interface ModalWrapperProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: () => void;
  contentLabel?: string;
  shouldCloseOnOverlayClick?: boolean;
  title?: string;
  subtitle?: string;
  svgImage?: string;
  submitButtonString?: string;
  cancelButtonString?: string;
  buttonComponent?: ReactElement;
  buttonStyle?: CSSProperties;
  contentClassName?: string;
  overlayClassName?: string;
  style?: ModalStyle;
  name?: string; // optional because the hamburger nav is also a modal
}

export class Modal extends Component<ModalWrapperProps> {
  // Track open events in Segment
  public componentDidUpdate(prevProps: ModalWrapperProps) {
    const { name, isOpen } = this.props;
    if (name && isOpen !== prevProps.isOpen) {
      if (isOpen) {
        // eslint-disable-next-line no-undef
        analytics.track('Modal Opened', { name });
      }
    }
  }

  render() {
    const {
      children,
      contentLabel,
      isOpen,
      shouldCloseOnOverlayClick,
      onRequestClose,
      title,
      style,
      subtitle,
      svgImage,
      buttonComponent,
      buttonStyle,
      submitButtonString,
      cancelButtonString,
      contentClassName,
      overlayClassName,
    } = this.props;

    return (
      <ReactModal
        contentLabel={contentLabel}
        isOpen={isOpen}
        style={style}
        className={!style && (contentClassName || 'modal-content')}
        overlayClassName={!style && (overlayClassName || 'modal-overlay')}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        onRequestClose={this.handleRequestClose}
      >
        <div
          style={{
            flex: 1,
            padding: 10,
            paddingTop: 40,
            justifyContent: 'center',
          }}
        >
          {/* Title */}
          {title && <H1 className="text-center">{title}</H1>}
          {subtitle && <P className="text-center">{subtitle}</P>}
          {/* Modal Icon */}
          {svgImage && (
            <div
              style={{
                alignItems: 'center',
                paddingTop: 30,
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <img src={`/${svgImage}`} />
            </div>
          )}
          {/* Modal Content */}
          <div style={{ marginTop: 20, marginBottom: 20, flexWrap: 'wrap' }}>{children}</div>
          {/* Submit Button */}
          {buttonComponent ||
            (submitButtonString && (
              <BrigitButton onClick={this.handleSubmit} style={{ ...buttonStyle, width: '100%' }}>
                <ButtonText className="font-white">{submitButtonString}</ButtonText>
              </BrigitButton>
            ))}
          {/* Cancel Button */}
          {cancelButtonString && (
            <LinkText
              className="text-center"
              onClick={onRequestClose}
              style={{ marginTop: 20, width: '100%', cursor: 'pointer' }}
            >
              {cancelButtonString}
            </LinkText>
          )}
        </div>
      </ReactModal>
    );
  }

  private handleRequestClose = () => {
    const { onRequestClose, name } = this.props;
    if (onRequestClose) {
      onRequestClose();
    }
    // Track close events in Segment (do this here instead of in
    // `componentDidUpdate` because some modals when closed unmount
    // the component and will not be captured)
    if (name) {
      // eslint-disable-next-line no-undef
      analytics.track('Modal Closed', { name });
    }
  };

  private handleSubmit = () => {
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit();
    }
    this.handleRequestClose();
  };
}
