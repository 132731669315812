import 'react-select/dist/react-select.css';
import { LocationOn } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'debounce-promise';
import { each } from 'lodash';
import React, { InputHTMLAttributes } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { ReduxInputField } from './ReduxInputField';

// Google Places API location types
enum PlacesLocationTypes {
  STREET_NUMBER = 'street_number',
  STREET = 'route',
  LOCALITY = 'locality',
  SUBLOCALITY = 'sublocality',
  TOWNSHIP = 'administrative_area_level_3',
  COUNTY = 'administrative_area_level_2',
  STATE = 'administrative_area_level_1',
  MINOR_CIVIL = 'administrative_area_level_4',
  MINOR_CIVIL_DIVISION = 'administrative_area_level_5',
  POSTAL_CODE = 'postal_code',
}

const NOT_FOUND = 'NOT_FOUND';

export interface AddressFields {
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
}

interface PlacePrediction {
  name: string;
  mainText: string;
  secondaryText: string;
  placeId: string;
}

interface OwnProps {
  placeholder: string;
  inputOnChange?: (parsedAddress: AddressFields) => void;
}

interface State {
  open: boolean;
  loading: boolean;
  options: PlacePrediction[];
  inputValue: string; // used to correctly render the value in the autocomplete textfield
}

type Props = OwnProps & WrappedFieldProps & InputHTMLAttributes<Record<string, unknown>>;

// TODO: try to add typing for Google Maps (a bit difficult to import/use)
export class PlacesDropdown extends React.Component<Props, State> {
  private GOOGLE_OK_STATUS;

  private ZERO_RESULTS;

  private autocompleteService;

  private geocoder;

  /**
   * Google Places address components is an array of components, and each component has a `types` array.
   * Parses through the array of components and finds street address, city, state, and zip code by matching
   * with the location `types` array
   */
  static parseAddress = (addressComponents): AddressFields => {
    const parsed = {} as AddressFields;
    let streetNumber;
    let street;

    each(addressComponents, (c) => {
      if (c.types.includes(PlacesLocationTypes.STREET_NUMBER)) {
        streetNumber = c.short_name;
      } else if (c.types.includes(PlacesLocationTypes.STREET)) {
        street = c.short_name;
      } else if (
        /**
         * The order of these types are important, as there is hierarchy for determining
         * what the city name is (i.e. LOCALITY is the best match, whereas MINOR_CIVIL_DIVISION
         * is the least best match). As all of these fields are not guaranteed to exist for every
         * place/address returned from the API, we are including logic that checks for each one.
         * Once a match is found, we do not want to override the city name with a lower match
         * type, so we are also checking for the existance of `parsed.city`.
         */
        (c.types.includes(PlacesLocationTypes.LOCALITY) ||
          c.types.includes(PlacesLocationTypes.SUBLOCALITY) ||
          c.types.includes(PlacesLocationTypes.TOWNSHIP) ||
          c.types.includes(PlacesLocationTypes.COUNTY) ||
          c.types.includes(PlacesLocationTypes.MINOR_CIVIL) ||
          c.types.includes(PlacesLocationTypes.MINOR_CIVIL_DIVISION)) &&
        !parsed.city
      ) {
        parsed.city = c.short_name;
      } else if (c.types.includes(PlacesLocationTypes.STATE)) {
        parsed.state = c.short_name;
      } else if (c.types.includes(PlacesLocationTypes.POSTAL_CODE)) {
        parsed.zipCode = c.short_name;
      }
    });

    if (streetNumber && street) {
      parsed.streetAddress = `${streetNumber} ${street}`;
    }

    return parsed;
  };

  constructor(props) {
    super(props);
    this.initGoogleServices();

    this.fetchPredictions = debounce(this.fetchPredictions, 300, { trailing: true });

    this.state = {
      open: false,
      loading: false,
      options: [],
      inputValue: '',
    };
  }

  /**
   * Autocomplete props:
   * `freeSolo` - means that the user is not constrained to the options given in the autocomplete select
   * field (i.e. they can also manual input their adddress)
   * `getOptionDisabled` - disable option if it is the "no options found" option
   * `onInputChange` - gets predictions as the user types
   * `onChange` - gets Google geocode info when a user selects an option
   * `filterOptions` - allows all options, including not found option to be shown
   *                   (was filtering this option out without this prop)
   * */
  public render() {
    const { open, options, inputValue, loading } = this.state;
    const { inputOnChange, input, ...rest } = this.props;

    return (
      <Autocomplete
        freeSolo
        size="small"
        open={open}
        loading={loading}
        loadingText="Loading..."
        onOpen={this.open}
        onClose={this.close}
        inputValue={inputValue}
        options={options}
        filterOptions={(allOptions) => allOptions}
        getOptionLabel={(option: PlacePrediction) => option.name}
        getOptionDisabled={(option: PlacePrediction) => option.name === NOT_FOUND}
        onInputChange={(_event, value: string) => this.handleGetPredictions(value)}
        onChange={this.handleOnChange}
        onBlur={() => input.onBlur(input.value)}
        renderInput={(params) => <ReduxInputField input={input} {...params} {...rest} />}
        renderOption={(option: PlacePrediction) => {
          return (
            <div className="d-flex align-items-end" style={{ fontFamily: 'Helvetica' }}>
              <LocationOn color="disabled" fontSize="small" />
              <div
                className="font-black"
                style={{ fontWeight: 'bold', fontSize: '13px', paddingLeft: '5px' }}
              >
                {option.mainText}
              </div>
              <div
                className="font-grey"
                style={{
                  fontSize: option.name === NOT_FOUND ? '13px' : '11px',
                  paddingLeft: '3px',
                }}
              >
                {option.secondaryText}
              </div>
            </div>
          );
        }}
      />
    );
  }

  private initGoogleServices = () => {
    if (!window.google || !window.google.maps.places) {
      throw new Error('Google Service must be loaded');
    }
    this.autocompleteService = new window.google.maps.places.AutocompleteService();
    this.geocoder = new window.google.maps.Geocoder();
    this.GOOGLE_OK_STATUS = window.google.maps.places.PlacesServiceStatus.OK;
    this.ZERO_RESULTS = window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS;
  };

  private handleOnChange = (_event, value: PlacePrediction) => {
    const { inputOnChange, input } = this.props;

    if (value?.placeId) {
      // the value is present and has a place id (not the no options found option)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.fetchGeoInfo(value.placeId).then((r: any) => {
        // type this later
        if (r.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const parsed = PlacesDropdown.parseAddress(r[0].address_components);

          if (inputOnChange) {
            // change handler was passed as a prop, use that to handle the input change
            inputOnChange(parsed);
            // set to an empty string if there is no street address found
            // TODO: show user why we set to empty string
            this.setState({ inputValue: parsed.streetAddress || '' });
          } else {
            // no change handler passed, use input's onChange to change input value to the
            // parsed address
            input.onChange(parsed);
          }
        } else {
          // no geocode information found, change input value to just the value's name
          input.onChange(value.name);
        }
      });
    } else {
      // if value or placeId is not present, just close the autocomplete select field
      this.close();
    }
  };

  /**
   * Fired as user types, sets the input value and fetches predictions,
   * then sets the autocomplete options
   */
  private handleGetPredictions = (address: string) => {
    this.setState({ inputValue: address, loading: true });

    const req = {
      input: address,
      componentRestrictions: { country: 'US' },
      types: ['address'],
    };

    if (address) {
      this.fetchPredictions(req).then((predictions: PlacePrediction[]) => {
        this.setState({ options: predictions, loading: false });
      });
    } else {
      // if address is an empty string, close the autocomplete select prompt
      // and set options to an empty array
      this.setState({ options: [], loading: false });
      this.close();
    }
  };

  private fetchPredictions = (req) => {
    return new Promise((resolve, reject) => {
      this.autocompleteService.getPlacePredictions(req, (results, status) => {
        let predictions;
        if (status === this.GOOGLE_OK_STATUS) {
          // filter to only get results that are of type street_address or premise
          // which are more likely to include a full street address
          predictions = results
            .filter((p) => p.types.includes('street_address') || p.types.includes('premise'))
            .map((p) => ({
              name: p.description,
              placeId: p.place_id,
              mainText: p.structured_formatting.main_text,
              secondaryText: p.structured_formatting.secondary_text,
            }));

          resolve(predictions);
        } else if (status === this.ZERO_RESULTS) {
          predictions = [
            {
              name: NOT_FOUND,
              placeId: null,
              mainText: 'Not found.',
              secondaryText: 'Add it manually',
            },
          ];
          resolve(predictions);
        } else {
          reject(status);
        }
      });
    });
  };

  private fetchGeoInfo = (placeId: string) => {
    const req = { placeId };
    return new Promise((resolve, reject) => {
      this.geocoder.geocode(req, (results, status) => {
        if (status === this.GOOGLE_OK_STATUS) {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  };

  private open = () => this.setState({ open: true });

  private close = () => this.setState({ open: false });
}
