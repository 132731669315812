import React, { FunctionComponent } from 'react';
import { P } from '../Typography';

export interface BreadcrumbItemProps {
  active: boolean;
  displayName: string;
}

export const BreadcrumbItem: FunctionComponent<BreadcrumbItemProps> = ({ active, displayName }) => (
  <P className="semi-bold font-white mb-0" style={{ opacity: active ? 1 : 0.45 }}>
    {displayName}
  </P>
);
