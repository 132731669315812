import React, { FunctionComponent } from 'react';
import { REDIRECT_TO_MOBILE_BRANCH_LINK } from '../../../constants/general';
import { SuccessAnimatedIcon } from '../../lottie-icons/success';
import { AccountSettingChangeType } from '../../types/ResetPinOrConfirmEmailTypes';

interface Props {
  changeType: AccountSettingChangeType;
}

export const SuccessDisplay: FunctionComponent<Props> = ({ changeType }) => {
  return (
    <>
      <div className="debit-form-section mt-5">
        <SuccessAnimatedIcon />
      </div>
      <div style={{ fontSize: '22px' }} className="bold debit-form-section mt-5">
        {changeType === AccountSettingChangeType.RESET_PIN && (
          <>
            Your pin has been reset! Log back into the{' '}
            <a className="font-dark-green" href={REDIRECT_TO_MOBILE_BRANCH_LINK}>
              mobile
            </a>{' '}
            app to set your new pin.
          </>
        )}
        {changeType === AccountSettingChangeType.CONFIRM_EMAIL && (
          <>
            Your email has been confirmed. Head back to the{' '}
            <a className="font-dark-green" href={REDIRECT_TO_MOBILE_BRANCH_LINK}>
              mobile
            </a>{' '}
            app!
          </>
        )}
      </div>
    </>
  );
};
