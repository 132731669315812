import { ConnectorProps } from '@hellobrigit/brigit-common';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { SetStepAction } from '../actions/nextStepActions';
import { AuthEmailForm } from '../components/onboarding/AuthEmail';
import { OnboardingNavBar } from '../components/onboarding/OnboardingNavBar';
import { OnboardingStep } from '../constants/onboarding';
import { OnboardingRoutes } from '../constants/OnboardingSteps';
import { AppState } from '../store';

const mapStateToProps = (state: AppState) => {
  const {
    onboardingStage: { step },
  } = state;
  return { step };
};

const connector = connect(mapStateToProps, { setStep: SetStepAction });

type Props = ConnectorProps<typeof connector>;

const AuthEmailContainer: FC<Props> = (props) => {
  const { step } = props;

  return step === OnboardingStep.AUTH_EMAIL ? (
    <Container className="min-height-100vh" fluid>
      <OnboardingNavBar step={step} />
      <AuthEmailForm />
    </Container>
  ) : (
    <Redirect to={OnboardingRoutes[step]} />
  );
};

export default connector(AuthEmailContainer);
