import MomentUtils from '@date-io/moment';
import { InputAdornment } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment-business-days';
import React, { FunctionComponent } from 'react';
import { P1 } from './Typography';

const DATE_FORMAT = 'dddd, MMM D';

interface Props {
  isBusDayOnly: boolean;
  selectedDate: Moment;
  setSelectedDate: (date: Moment) => void;
  label: string;
  maxDate: Moment;
  minDate?: Moment;
}

export const DatePickerField: FunctionComponent<Props> = (props) => {
  const { isBusDayOnly, selectedDate, setSelectedDate, label, maxDate, minDate } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        fullWidth
        shouldDisableDate={(day) => {
          return (
            day.isAfter(maxDate) ||
            (isBusDayOnly && !moment(day).isBusinessDay()) ||
            (minDate && day.isBefore(minDate))
          );
        }}
        disableToolbar
        variant="inline"
        format={DATE_FORMAT}
        margin="normal"
        id="date-picker-inline"
        label={label}
        value={selectedDate}
        onChange={setSelectedDate}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <P1
                style={{ paddingRight: '10px', cursor: 'pointer' }}
                className="semi-bold font-dark-green mb-0"
              >
                Edit
              </P1>
            </InputAdornment>
          ),
          readOnly: true,
          style: {
            fontFamily: 'EuclidCircular-B-Bold',
            color: '#333333',
            borderBottom: 0,
            fontSize: '16px',
            opacity: 0.8,
          },
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
