export interface PremiumPerk {
  title: string;
  text: string;
  svgUri: string;
}

export const initialRemoteConfigState = {
  sideGigsV2: [],
  workFromHomeJobs: [],
  fullTimeJobs: [],
  joinBrigitReasons: {},
  premiumMembershipName: 'Plus',
  premiumPerks: [
    {
      title: 'Earn Extra',
      text: 'Easily find opportunities to increase your overall income',
      svgUri:
        'https://storage.googleapis.com/publicstatic.hellobrigit.com/premium-perks/cash-bills-coins.svg',
    },
    {
      title: 'Insights',
      text: 'Receive updates about upcoming bills in real time',
      svgUri:
        'https://storage.googleapis.com/publicstatic.hellobrigit.com/premium-perks/message-bubbles.svg',
    },
    {
      title: 'Finance Helper',
      text: 'See insights into your monthly earnings and expenses',
      svgUri:
        'https://storage.googleapis.com/publicstatic.hellobrigit.com/premium-perks/positive-negative-bars.svg',
    },
  ],
  creditMonitoringPerk: {
    title: 'Credit Protect & Secure',
    text: 'Monitor and protect your credit score',
    svgUri:
      'https://storage.googleapis.com/publicstatic.hellobrigit.com/premium-perks/credit-tracker.svg',
  },
  creditMonitoringIdentityProtectionPerk: {
    title: 'Identity Protection',
    text: 'Protect your identity from theft with $1,000,000 insurance',
    svgUri:
      'https://storage.googleapis.com/publicstatic.hellobrigit.com/premium-perks/identity-protection.svg',
  },
  creditBuilderPerk: {
    title: 'Credit Builder',
    text: 'Build your credit and track your progress',
    svgUri:
      'https://storage.googleapis.com/publicstatic.hellobrigit.com/premium-perks/hot-air-balloon.svg',
  },
  creditMonitoringProductName: 'Credit Protect & Secure',
  advanceProductName: 'Instant Cash',
  creditBuilderProductName: 'Credit Builder',
  allowSecondaryAccountPayment: false,
};
interface ExtraIncomeSource {
  title: string;
  description: string;
  details: string;
  logo: string;
  url: string;
}

interface JoinBrigitReason {
  joinBrigitSvgUri: string;
  joinBrigitText: string;
  playbackSvgUri: string;
  playbackText: string;
  preCheckText: string;
}

interface JoinBrigitReasons {
  [key: string]: JoinBrigitReason;
}

export interface RemoteConfig {
  // Unemployment Banner
  unemploymentBanner?: {
    message: string;
    cta: string;
    url: string;
  };

  sideGigsV2: ExtraIncomeSource[];
  workFromHomeJobs: ExtraIncomeSource[];
  fullTimeJobs: ExtraIncomeSource[];

  // Bank Link Primer
  joinBrigitReasons: JoinBrigitReasons;

  // 'Safety Net' or 'Brigit Plus'?
  premiumMembershipName: string;
  premiumPerks: PremiumPerk[];
  creditMonitoringPerk: PremiumPerk;
  creditMonitoringIdentityProtectionPerk: PremiumPerk;
  creditBuilderPerk: PremiumPerk;

  // Credit Monitoring Membership Name TBD
  creditMonitoringProductName: string;
  advanceProductName: string;
  creditBuilderProductName: string;

  // Whether or not to show the secondary account authorization
  allowSecondaryAccountPayment: boolean;
}

export interface FirebaseState {
  adminOverride: boolean;
  deviceToken?: string;
  remoteConfig: RemoteConfig;
}
