import React, { Children, FunctionComponent } from 'react';
import { P } from '../Typography';
import { BreadcrumbItem, BreadcrumbItemProps } from './BreadcrumbItem';

const BreadCrumbDivider: FunctionComponent = () => (
  <P style={{ marginLeft: 8, marginRight: 8, opacity: 0.45 }} className="font-white semi-bold mb-0">
    /
  </P>
);

export const Breadcrumb: FunctionComponent = ({ children }) => {
  const numChildren = Children.count(children);
  return (
    <div className="d-flex">
      {Children.map(
        children,
        (child: React.ReactElement<BreadcrumbItemProps>, i) =>
          child && (
            <div className="d-flex justify-content-center">
              <BreadcrumbItem {...child.props} />
              {i !== numChildren - 1 && <BreadCrumbDivider />}
            </div>
          ),
      )}
    </div>
  );
};
