import '@fortawesome/fontawesome/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ScrollToTop from './components/ScrollToTop';
import RootContainer from './containers/RootContainer';
import { unregister } from './registerServiceWorker';
import { GraphQL } from './services/graphql';
import { persistor, store } from './store';

unregister();

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://ce86754bd4f84f3592fefd68b3b47be1@sentry.io/1227175',
  enabled: process.env.NODE_ENV === 'production',
  release: process.env.SENTRY_RELEASE,
  // This enables automatic instrumentation (highly recommended)
  integrations: [
    new Integrations.BrowserTracing({
      // TODO make sure to update this if we upgrade react-router from v4->5
      routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
    }),
  ],
  // To set a uniform sample rate
  tracesSampleRate: 0.1,
  // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
  ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
});

// eslint-disable-next-line no-undef
analytics.debug(process.env.NODE_ENV !== 'production');

axios.defaults.baseURL = '/api';
Modal.setAppElement('#root');

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <ApolloProvider client={GraphQL.client}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleReCaptchaProvider
            reCaptchaKey="6LcNdHIdAAAAABO30M78azFw45PVbUyWfY0Db3Jk"
            useEnterprise
            scriptProps={{ async: true }}
          >
            <BrowserRouter>
              <ScrollToTop>
                <RootContainer />
              </ScrollToTop>
            </BrowserRouter>
          </GoogleReCaptchaProvider>
        </PersistGate>
      </ApolloProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
