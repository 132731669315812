import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DeleteAccountConfirm } from '../components/delete/DeleteAccountConfirm';
import { NavWrapper } from '../components/navigation/NavWrapper';
import { DeleteRoutes, RootRoutes } from '../utils/routes';

export const DeleteContainer: FunctionComponent = () => {
  return (
    <NavWrapper>
      <Switch>
        <Route
          path={DeleteRoutes.DELETE_ACCOUNT_CONFIRM}
          render={(renderProps) => <DeleteAccountConfirm {...renderProps} />}
        />
        <Redirect to={RootRoutes.ROOT} />
      </Switch>
    </NavWrapper>
  );
};
