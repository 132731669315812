import { ApiAction, ClearApiState, ConnectorProps } from '@hellobrigit/brigit-common';
import { ApiCallState } from '@hellobrigit/brigit-common/dist/store/ApiState';
import { partial } from 'lodash';
import React, { Component, ComponentType, ReactElement } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../store';
import { Modal, ModalWrapperProps } from '../Modal';
import { ModalName } from '../Modal/constants/ModalName';
import { P } from '../Typography';

interface ApiErrorModalProps extends Partial<ModalWrapperProps> {
  apiAction: ApiAction;
  content?: ReactElement;
  contentComponent?: ComponentType<ApiCallState>;
  submitButtonString?: string;
  cancelButtonString?: string;
}

const mapStateToProps = (state: AppState, ownProps: ApiErrorModalProps) => {
  const { api } = state;
  const { apiAction } = ownProps;
  return {
    apiCall: api.get(apiAction.id),
  };
};

const mapDispatchToProps = (dispatch, ownProps: ApiErrorModalProps) => {
  const { apiAction } = ownProps;
  return {
    ...bindActionCreators({ dismissError: partial(ClearApiState, apiAction) }, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector> & ApiErrorModalProps;

/**
 * Error modal tied to a specific API Action read
 * from the redux state. If onSubmit or onRequestClose is passed in,
 * it will always call that, and then dismiss the error modal.
 */
class ApiErrorModalBase extends Component<Props> {
  public render() {
    const {
      apiCall,
      contentComponent: ContentComponent,
      content,
      svgImage,
      title,
      apiAction,
    } = this.props;
    const showError = apiCall.error !== undefined;
    const customContent = showError && ContentComponent && <ContentComponent {...apiCall} />;

    return (
      <Modal
        {...this.props}
        svgImage={svgImage}
        isOpen={showError}
        onSubmit={this.submitWithDismiss}
        submitButtonString="Okay"
        title={title || 'Uh oh!'}
        onRequestClose={this.closeWithDismiss}
        shouldCloseOnOverlayClick
        name={`${ModalName.API_ERROR} ${apiAction.id}`}
      >
        {showError && apiCall.code === 504 && (
          <P className="mb-0 text-center">
            Brigit is responding slowly right now, please try again in a little bit.
          </P>
        )}
        {showError &&
          apiCall.code !== 504 &&
          (customContent ||
            content || ( // short circuits to `content` if provided
              // otherwise render default message
              <P className="mb-0 text-center">{apiCall.error.message}</P>
            ))}
      </Modal>
    );
  }

  private closeWithDismiss = () => {
    const { onRequestClose, dismissError } = this.props;
    if (onRequestClose) {
      onRequestClose();
    }
    dismissError();
  };

  private submitWithDismiss = () => {
    const { onSubmit, dismissError } = this.props;
    if (onSubmit) {
      onSubmit();
    }
    dismissError();
  };
}

export const ApiErrorModal = connector(ApiErrorModalBase);
