import { CheckingAccountResponse, RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import { InputAdornment, MuiThemeProvider, TextField } from '@material-ui/core';
import { FadeIn } from 'animate-components';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { Row } from 'reactstrap';
import { RecoveryLocationState } from '../../../containers/types';
import { datePickerTheme } from '../../../styles/muiTheme';
import { BankRecoveryRoutes, RecoveryRoutes, RootRoutes } from '../../../utils/routes';
import { BrigitButton } from '../../buttons/BrigitButton';
import { ListHeader } from '../../ListHeader';
import { ButtonText, P1 } from '../../Typography';
import { RecoveryErrorAlert } from '../RecoveryErrorAlert';
import { RepaymentHeader } from '../RepaymentHeader';

const LOCK_ICON_FILLED_SRC = '/icons/lock-icon-filled.png';

interface OwnProps {
  selectedAccount: CheckingAccountResponse;
  openPlaidLink: () => void;
  navigateToConfirmPayment: () => void;
  errors: string[];
  loanUUID: string;
}

type Props = OwnProps & RecoveryLocationState & RouteComponentProps;

export const BankPaymentAccount: FunctionComponent<Props> = (props) => {
  const {
    payNow,
    selectedAccount,
    openPlaidLink,
    navigateToConfirmPayment,
    errors,
    history,
    loanUUID,
  } = props;

  const paymentFrom =
    selectedAccount && `${selectedAccount.name} ending in ${selectedAccount.mask}`;

  const navigatePaymentOptions = () => {
    const locationState: RecoveryLocationState = { payNow };

    history.push({
      pathname: `${RootRoutes.RECOVERY_REPAYMENT}/${RecoveryRoutes.PAYMENT_OPTIONS}`,
      search: `?id=${loanUUID}`,
      state: locationState,
    });
  };

  return (
    <MuiThemeProvider theme={datePickerTheme}>
      <RepaymentHeader
        paymentMethod={RecoveryPaymentSource.ACH}
        payNow={payNow}
        activeRoute={BankRecoveryRoutes.PAYMENT}
      />
      <Row className="text-lg-center">
        <ListHeader style={{ width: '100%' }}>Add bank account details</ListHeader>
      </Row>
      {errors.length > 0 && <RecoveryErrorAlert errors={errors} />}

      <div className="d-flex flex-column align-items-center" style={{ paddingTop: '25px' }}>
        <FadeIn duration="1s" style={{ width: '100%', maxWidth: '500px' }}>
          <div onClick={paymentFrom ? null : openPlaidLink}>
            <TextField
              fullWidth
              label={paymentFrom ? 'Bank Account Details' : 'Enter Bank Account Details'}
              value={paymentFrom || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {/* Two `onClick` handlers here because `onClick` on field is disabled */}
                    {/* if a user has already selected an account. In these cases, the user */}
                    {/* must click on "Edit" to change their bank */}
                    <P1
                      onClick={openPlaidLink}
                      style={{ paddingRight: '10px', cursor: 'pointer' }}
                      className="semi-bold font-dark-green mb-0"
                    >
                      {paymentFrom ? 'Edit' : <img src={LOCK_ICON_FILLED_SRC} height={20} />}
                    </P1>
                  </InputAdornment>
                ),
                readOnly: true,
                style: {
                  fontFamily: 'EuclidCircular-B-Medium',
                  color: '#696969',
                  fontSize: '16px',
                },
              }}
            />
          </div>
        </FadeIn>

        <BrigitButton
          onClick={navigateToConfirmPayment}
          invalid={!selectedAccount}
          fullWidthOnMobile
          style={{ marginTop: 32 }}
        >
          <ButtonText className="font-white">Continue to Review Plan</ButtonText>
        </BrigitButton>

        <BrigitButton
          onClick={navigatePaymentOptions}
          inverted
          fullWidthOnMobile
          style={{ marginTop: 8, flexShrink: 0 }}
        >
          <ButtonText
            style={{ paddingBottom: 5, paddingTop: 5, marginLeft: 8, marginRight: 8 }}
            className="font-dark-green"
          >
            Choose different payment method
          </ButtonText>
        </BrigitButton>
      </div>
    </MuiThemeProvider>
  );
};
