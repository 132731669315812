import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Col, Navbar, Row } from 'reactstrap';
import { InternalUrls } from '../../constants/InternalUrls';
import { NAV_LOGO_PATH } from '../../constants/navLogoPath';
import { RootRoutes } from '../../utils/routes';

interface Props {
  // if passed in, clicking on the logo will lead to an external url
  // (route if given, homepage if not)
  logoOnClickToExternalUrl?: boolean;
  // aligns logo to the right instead of the left (default)
  alignLogoRight?: boolean;
  // smaller nav bar
  small?: boolean;
  // route for licking on the logo
  route?: string;
}

export const LogoNoMenuNav: FunctionComponent<Props> = (props: Props) => {
  const { logoOnClickToExternalUrl, route, alignLogoRight, small } = props;

  const colClass = classNames({
    'd-flex': true,
    'justify-content-end': alignLogoRight,
  });

  return (
    <Row className="bg-dark-green">
      <Col style={{ height: small ? '55px' : '75px' }} className={colClass}>
        <Navbar color="faded" light>
          {logoOnClickToExternalUrl ? (
            <a href={route || InternalUrls.HOMEPAGE} className="px-0">
              <img style={{ width: small ? '60px' : '90px', height: 'auto' }} src={NAV_LOGO_PATH} />
            </a>
          ) : (
            <Link to={route || RootRoutes.ROOT} className="px-0">
              <img style={{ width: small ? '60px' : '90px', height: 'auto' }} src={NAV_LOGO_PATH} />
            </Link>
          )}
        </Navbar>
      </Col>
    </Row>
  );
};
