import React, { ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { OnboardingRoutes } from '../constants/OnboardingSteps';

interface RouteProps {
  currentStep: string;
  routeStep: string;
  component: ReactNode;
  path: string;
  /**
   * This param isn't actually needed but it needs to be on the underlying implementation of
   * BrigitRoute because react router does some magic that requires it to be on the child of the
   * navigator or switch.
   */
  exact?: boolean;
}

export const BrigitRoute = (props: RouteProps) => {
  const { currentStep, exact = false, routeStep, component, path } = props;
  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        return currentStep === routeStep ? (
          component
        ) : (
          <Redirect to={OnboardingRoutes[currentStep]} />
        );
      }}
    />
  );
};
