import '../../styles/delete.css';
import { ConnectorProps, DeleteAccountApi, GetSubscriptionsApi } from '@hellobrigit/brigit-common';
import { FrontendSubscriptionPayment, SubscriptionStatus } from '@hellobrigit/brigit-rest-api';
import { format, parseISO } from 'date-fns';
import { isNil } from 'lodash';
import React, { FC, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Container } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { SetStepAction } from '../../actions/nextStepActions';
import { AppState, SubscriptionTier } from '../../store';
import { RootRoutes } from '../../utils/routes';
import { required } from '../../utils/validators';
import { BrigitButton } from '../buttons/BrigitButton';
import { Checkbox } from '../Checkbox';
import { ScreenTemplate } from '../ScreenTemplate';
import { ButtonText, H1, P, P1 } from '../Typography';

const mapStateToProps = (state: AppState) => {
  const {
    api,
    firebase: {
      remoteConfig: { advanceProductName },
    },
    user: {
      details: {
        protection: { subscriptions },
      },
    },
    subscriptions: { currentSubscriptionTier },
  } = state;

  const mostRecentSubscription = subscriptions?.[0];
  let latestPendingSubscription;
  if (mostRecentSubscription?.status === SubscriptionStatus.PENDING) {
    latestPendingSubscription = mostRecentSubscription;
  }

  return {
    apiCall: api.get(DeleteAccountApi.id),
    advanceProductName,
    latestPendingSubscription,
    currentSubscriptionTier,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAccount: DeleteAccountApi.bindDispatch(dispatch),
    getSubscriptions: GetSubscriptionsApi.bindDispatch(dispatch),
    ...bindActionCreators({ setStep: SetStepAction }, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface PendingSubscriptionBulletPointsProps {
  subscriptionPayment: FrontendSubscriptionPayment;
  currentSubscriptionTier: SubscriptionTier | undefined;
}

const PendingSubscriptionBulletPoints: FC<PendingSubscriptionBulletPointsProps> = ({
  subscriptionPayment: { paymentDate },
  currentSubscriptionTier,
}) => {
  const finalSubscriptionPaymentDate = format(parseISO(paymentDate), 'MMMM do');

  const items: ReactNode[] = [
    <>You have a pending Brigit subscription payment.</>,
    <>
      If you request deletion now, we will finalize deletion on{' '}
      <span className="bold">{finalSubscriptionPaymentDate}</span> after your final subscription is
      paid.
    </>,
    <>
      You won't be able to reactivate {currentSubscriptionTier?.displayName ?? 'your subscription'}{' '}
      or create a new Brigit account until deletion is completed.
    </>,
  ];

  return (
    <div>
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="d-flex flex-row">
          <P className="mr-2">•</P>
          <P className="text-left">{item}</P>
        </div>
      ))}
    </div>
  );
};

interface DeleteAccountForm {
  understandDeletion: boolean;
}

type Props = ConnectorProps<typeof connector> &
  InjectedFormProps<DeleteAccountForm> &
  RouteComponentProps;

const DeleteAccountConfirmBase: FC<Props> = ({
  apiCall,
  getSubscriptions,
  latestPendingSubscription,
  currentSubscriptionTier,
  invalid,
  history,
  deleteAccount,
}) => {
  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  const onClickDeleteMyAccount = async () => {
    await deleteAccount();

    history.push(RootRoutes.DELETE_SUCCESS);
  };

  const navigateToHome = () => history.push(RootRoutes.ROOT);

  const hasPendingSubscription = !isNil(latestPendingSubscription);
  return (
    <Container fluid style={{ paddingTop: '60px' }}>
      <ScreenTemplate
        svgImage={hasPendingSubscription ? undefined : 'delete/warning-trash-can.svg'}
      >
        <H1 className="mb-4">Are you sure you want to delete your account?</H1>

        {!hasPendingSubscription ? (
          <P className="mb-4">
            If you need further assistance, or to sign up again, visit{' '}
            <span className="bold">hellobrigit.com</span> or email us at{' '}
            <span className="bold">info@hellobrigit.com</span>
          </P>
        ) : (
          <PendingSubscriptionBulletPoints
            subscriptionPayment={latestPendingSubscription}
            currentSubscriptionTier={currentSubscriptionTier}
          />
        )}

        <div
          className="d-flex flex-column align-items-center"
          style={{ rowGap: 16, marginTop: 16 }}
        >
          <Field
            name="understandDeletion"
            component={Checkbox}
            type="checkbox"
            validate={[required]}
          >
            <P1 className="mb-0 text-left">
              I understand that Brigit may retain certain data elements in order to comply with
              legal obligations or other permitted uses. I understand that upon account deletion,
              Brigit will no longer be able to complete transactions with or make adjustments to my
              Brigit account. I understand that deleting my account is irreversible.
            </P1>
          </Field>

          <BrigitButton
            fullWidthOnMobileWithSideNav
            invalid={invalid}
            onClick={onClickDeleteMyAccount}
            apiCall={apiCall}
          >
            <ButtonText className="font-white">Yes, delete my account</ButtonText>
          </BrigitButton>

          <BrigitButton minimal onClick={navigateToHome}>
            <ButtonText className="font-color-primary">No, I've changed my mind</ButtonText>
          </BrigitButton>
        </div>
      </ScreenTemplate>
    </Container>
  );
};

export const DeleteAccountConfirm = connector(
  reduxForm<DeleteAccountForm>({
    form: 'deleteAccount',
  })(DeleteAccountConfirmBase),
);
