import { TypedReducer } from 'redoodle';
import {
  ClearChangeSubscriptionTierErrorAction,
  ClearChangeSubscriptionTierOutcomeAction,
  SetChangeSubscriptionTierErrorAction,
  SetChangeSubscriptionTierLoadingAction,
  SetLoadSubscriptionTiersLoadingAction,
  SetSubscriptionsProposedBillDateAction,
  SetSubscriptionsScheduleTextAction,
  SetSubscriptionTiersLoadedAction,
  UpdateCurrentSubscriptionTierAction,
} from '../actions/subscriptionsActions';
import { SubscriptionsState } from '../store/types';

const builder = TypedReducer.builder<SubscriptionsState>();

builder.withHandler(
  SetSubscriptionTiersLoadedAction.TYPE,
  (state, { currentSubscriptionTier, availableSubscriptionTiers, subscriptionType }) => {
    return { ...state, currentSubscriptionTier, availableSubscriptionTiers, subscriptionType };
  },
);

builder.withHandler(
  UpdateCurrentSubscriptionTierAction.TYPE,
  (state, { currentSubscriptionTier, changeSubscriptionTierOutcome }) => {
    return {
      ...state,
      currentSubscriptionTier,
      changeSubscriptionTierOutcome,
      changeSubscriptionTierLoading: false,
    };
  },
);

builder.withHandler(
  SetChangeSubscriptionTierErrorAction.TYPE,
  (state, { message, userFacingMessage }) => {
    return {
      ...state,
      changeSubscriptionTierError: { message, userFacingMessage },
      changeSubscriptionTierLoading: false,
    };
  },
);

builder.withHandler(ClearChangeSubscriptionTierErrorAction.TYPE, (state) => {
  return { ...state, changeSubscriptionTierError: { message: '', userFacingMessage: '' } };
});

builder.withHandler(
  SetSubscriptionsScheduleTextAction.TYPE,
  (state, { subscriptionScheduleText }) => {
    return {
      ...state,
      subscriptionScheduleText,
    };
  },
);

builder.withHandler(SetSubscriptionsProposedBillDateAction.TYPE, (state, { proposedBillDate }) => {
  return {
    ...state,
    proposedBillDate,
  };
});

builder.withHandler(ClearChangeSubscriptionTierOutcomeAction.TYPE, (state) => {
  return { ...state, changeSubscriptionTierOutcome: undefined };
});

builder.withHandler(SetChangeSubscriptionTierLoadingAction.TYPE, (state) => {
  return { ...state, changeSubscriptionTierLoading: true };
});

builder.withHandler(SetLoadSubscriptionTiersLoadingAction.TYPE, (state, loading) => {
  return { ...state, loadSubscriptionTiersLoading: loading };
});

export default builder.build();
