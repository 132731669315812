import { ConnectorProps } from '@hellobrigit/brigit-common';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { BrigitRoute } from '../components/BrigitRoute';
import { LogoNoMenuNav } from '../components/navigation/LogoNoMenuNav';
import { BankConnectProgress, LinkBank } from '../components/onboarding';
import SelectBankAccount from '../components/onboarding/SelectBankAccount';
import { OnboardingRoutes, OnboardingStep } from '../constants/OnboardingSteps';
import { AppState } from '../store';
import { RootRoutes } from '../utils/routes';

const mapStateToProps = (state: AppState) => {
  const {
    onboardingStage: { step },
  } = state;
  return { step };
};

const connector = connect(mapStateToProps);

type Props = ConnectorProps<typeof connector>;

const RelinkBankContainerBase: FunctionComponent<Props> = ({ step }) => (
  <Container className="min-height-100vh" fluid>
    <LogoNoMenuNav />
    <Switch>
      <BrigitRoute
        path={RootRoutes.RELINK_BANK}
        currentStep={step}
        component={<LinkBank />}
        routeStep={OnboardingStep.RELINK_BANK}
      />
      <BrigitRoute
        path={OnboardingRoutes[OnboardingStep.SELECT_ACCOUNT]}
        currentStep={step}
        component={<SelectBankAccount />}
        routeStep={OnboardingStep.SELECT_ACCOUNT}
      />
      <BrigitRoute
        path={OnboardingRoutes[OnboardingStep.WAIT_FOR_BANK]}
        currentStep={step}
        component={<BankConnectProgress />}
        routeStep={OnboardingStep.WAIT_FOR_BANK}
      />
    </Switch>
  </Container>
);

export const RelinkBankContainer = connector(RelinkBankContainerBase);
