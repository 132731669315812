import {
  ApiLogoutUserAction,
  DeleteAccountApi,
  LogoutUserActionApi,
  PreEnableProtectionApi,
  RegisterBankLoginApi,
  RegisterUserApi,
} from '@hellobrigit/brigit-common';
import * as Sentry from '@sentry/browser';
import { Middleware } from 'redux';
import { RedditAds } from '../constants/adwordkeys';

declare const rdt;

export const analyticsMiddleware: Middleware = () => (next) => (action) => {
  if (RegisterUserApi.SUCCESS.is(action)) {
    // eslint-disable-next-line no-undef
    analytics.track('Sign Up');
    rdt('track', RedditAds.EMAIL_NUMBER); // currently no segment integration
  }

  if (RegisterBankLoginApi.SUCCESS.is(action)) {
    // eslint-disable-next-line no-undef
    analytics.track('Link Bank');
    rdt('track', RedditAds.LINK_BANK); // currently no segment integration
  }

  if (PreEnableProtectionApi.SUCCESS.is(action) && action.payload.approval.isApproved) {
    // eslint-disable-next-line no-undef
    analytics.track('PreEnable Approved');
  }

  if (
    LogoutUserActionApi.REQUEST.is(action) ||
    ApiLogoutUserAction.is(action) ||
    DeleteAccountApi.SUCCESS.is(action)
  ) {
    Sentry.configureScope((scope) => scope.setUser({ id: null }));
    // eslint-disable-next-line no-undef
    analytics.reset();
  }

  return next(action);
};
