import '../../styles/main.css';
import { ConnectorProps } from '@hellobrigit/brigit-common';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { SetStepAction } from '../../actions/nextStepActions';
import { OnboardingStep } from '../../constants/OnboardingSteps';

const connector = connect(null, { setStep: SetStepAction });

interface Props extends ConnectorProps<typeof connector> {
  step?: string;
  hideLogout?: boolean;
}

const showLogout = (step, hideLogout) => {
  if (hideLogout) {
    return false;
  }

  switch (step) {
    case OnboardingStep.EMAIL_NUMBER:
    case OnboardingStep.AUTH_PIN:
    case OnboardingStep.AUTH_PHONE:
    case OnboardingStep.AUTH_EMAIL:
      return false;
    default:
      return true;
  }
};

const OnboardingNavBarBase: FunctionComponent<Props> = (props) => {
  const { step, hideLogout, setStep } = props;
  const navLogoPath = '/logos/brigit-white-logo-2024.png';

  return (
    step !== OnboardingStep.HOW_IT_WORKS && (
      <Row className="justify-content-center bg-dark-green">
        <Col>
          <div className="d-flex justify-content-between">
            <div className="d-none d-md-block" style={{ width: '100px' }} />
            <Link
              to="/signup"
              onClick={() => setStep({ step: OnboardingStep.EMAIL_NUMBER })}
              className="py-3"
            >
              <img style={{ width: '130px' }} src={navLogoPath} />
            </Link>
            {showLogout(step, hideLogout) ? (
              <Link
                style={{ color: 'white', width: '100px' }}
                className="d-flex flex-column justify-content-center align-items-end cursor-pointer bold"
                to="/logout"
              >
                Log Out
              </Link>
            ) : (
              <div style={{ width: '100px' }} />
            )}
          </div>
        </Col>
      </Row>
    )
  );
};

export const OnboardingNavBar = connector(OnboardingNavBarBase);
