import { FrontendInstallmentPlan } from '@hellobrigit/brigit-rest-api';
import { TypedAction } from 'redoodle';
import { RecoveryDetailsState } from '../store/types';

export const SetRecoveryRepaymentDetailsAction = TypedAction.define(
  '@brigit/in_app_notifications/set_recovery_repayment_details',
)<RecoveryDetailsState>();

export const SetInstallmentPlanDetailsAction = TypedAction.define(
  '@brigit/in_app_notifications/set_installment_plan_details',
)<FrontendInstallmentPlan>();
