import { Middleware } from 'redux';
import {
  LoadCreditBuilderGlobalConfigurationAction,
  SetCreditBuilderGlobalConfigurationLoadedAction,
} from '../actions/creditBuilderConfigurationActions';
import { GraphQL } from '../services/graphql';

export const creditBuilderConfigurationMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);

  if (LoadCreditBuilderGlobalConfigurationAction.is(action)) {
    GraphQL.query(
      {
        creditBuilderGlobalConfiguration: {
          unsupportedStates: {
            name: true,
            code: true,
          },
        },
      },
      { operationName: 'FetchCreditBuilderGlobalConfiguration' },
    ).then(({ creditBuilderGlobalConfiguration }) => {
      const cbUnsupportedStates = creditBuilderGlobalConfiguration.unsupportedStates;
      const cbUnsupportedStatesNames = cbUnsupportedStates.map(
        (unsupportedState) => unsupportedState.name,
      );
      const cbUnsupportedStatesNamesString = cbUnsupportedStatesNames.join(', ');

      store.dispatch(
        SetCreditBuilderGlobalConfigurationLoadedAction.create({
          cbUnsupportedStates,
          cbUnsupportedStatesNamesString,
        }),
      );
    });
  }

  return result;
};
