import React, { CSSProperties, FunctionComponent } from 'react';

interface Props {
  url: string;
  style: CSSProperties;
  navLogoPath: string;
}

export const LinkedBrigitLogo: FunctionComponent<Props> = (props) => {
  const { url, style, navLogoPath } = props;
  return (
    <a href={url}>
      <img style={style} src={navLogoPath} />
    </a>
  );
};
