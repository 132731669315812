import { FadeIn } from 'animate-components';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { DeleteRoutes, RootRoutes } from '../utils/routes';
import { BrigitButton } from './buttons/BrigitButton';
import { ButtonText, Display, H1, H4, H5, LinkText, P } from './Typography';

export interface ScreenTemplateProps {
  title?: string;
  svgImage?: string;
  subtitleSmGray?: string;
  subtitleSmGrayReverse?: string;
  subtitleSmGreen?: string;
  subtitleMdBlue?: string;
  subtitleMdBlueReverse?: string;
  subtitleLgBlack?: string;
  subtitleMdBlack?: string;
  subtitleLgGreen?: string;
  subtitleLgRed?: string;
  cta?: string;
  subCta?: string;
  returnRoute?: string;
  nextStep?: DeleteRoutes | RootRoutes;
}

export const ScreenTemplate: FunctionComponent<ScreenTemplateProps> = (props) => {
  const {
    title,
    svgImage,
    subtitleSmGray,
    subtitleSmGrayReverse,
    subtitleSmGreen,
    subtitleMdBlue,
    subtitleMdBlueReverse,
    subtitleLgBlack,
    subtitleLgGreen,
    subtitleLgRed,
    cta,
    subtitleMdBlack,
    subCta,
    children,
    returnRoute,
    nextStep,
  } = props;

  return (
    <FadeIn duration="1s">
      <div className="del-wrapper" style={{ padding: 10, paddingTop: 0 }}>
        {/* Title */}
        {title && (
          <H1 className="text-center" style={{ paddingTop: 60 }}>
            {title}
          </H1>
        )}
        {/* Image */}
        {svgImage && <img src={`/${svgImage}`} style={{ paddingTop: title ? 30 : 0 }} />}
        {/* Small Subtitle */}
        {subtitleSmGreen && (
          <div className="text-center">
            <H5 className="mb-0 font-dark-green" style={{ paddingTop: 10 }}>
              {subtitleSmGreen}
            </H5>
            {subtitleLgBlack ? (
              <Display className="mb-0 font-black">{subtitleLgBlack}</Display>
            ) : (
              <H4 className="mb-0 font-black">{subtitleMdBlack}</H4>
            )}
          </div>
        )}
        {/* Regular Subtitle */}
        {!subtitleSmGreen && (
          <div className="text-center" style={{ paddingTop: 20 }}>
            {subtitleSmGray && <P className="mb-0">{subtitleSmGray}</P>}
            {subtitleMdBlueReverse && (
              <H4 className="mb-0 font-color-secondary">{subtitleMdBlueReverse}</H4>
            )}
            {subtitleSmGrayReverse && <P className="mb-0">{subtitleSmGrayReverse}</P>}
            {subtitleMdBlue && <H4 className="mb-0 font-color-secondary">{subtitleMdBlue}</H4>}
            {subtitleLgGreen && (
              <Display className="mb-0 font-color-primary">{subtitleLgGreen}</Display>
            )}
            {subtitleLgRed && <Display className="mb-0 font-color-danger">{subtitleLgRed}</Display>}
          </div>
        )}
        {/* Content */}
        {children && (
          <P className="text-center" style={{ paddingTop: 20 }}>
            {children}
          </P>
        )}
        {/* Action Items */}
        {(cta || subCta) && (
          <div className="del-action" style={{ paddingTop: 30 }}>
            {cta && (
              <Link to={returnRoute || '/'} className="full-width-on-mobile-with-side-nav">
                <BrigitButton style={{ width: '100%' }}>
                  <ButtonText className="font-white">{cta}</ButtonText>
                </BrigitButton>
              </Link>
            )}
            {subCta && (
              <Link to={nextStep} style={{ paddingTop: 20 }}>
                <LinkText className="mb-0 font-color-primary">{subCta}</LinkText>
              </Link>
            )}
          </div>
        )}
      </div>
    </FadeIn>
  );
};
