import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { P } from '../Typography';
import { PostPaymentSurveyModal } from './modals/PostPaymentSurveyModal';
import { SuccessScreenTemplate } from './SuccessScreenTemplate';

interface Props {
  scheduledPaymentDate: string;
}

export const RecoveryPaymentScheduled: FunctionComponent<Props> = ({ scheduledPaymentDate }) => {
  const [showSurveyModal, setShowSurveyModal] = useState(true);

  return (
    <>
      <SuccessScreenTemplate title="Payment Scheduled!">
        <P id="success-scheduled" className="font-grey text-center mb-0">
          We’ve scheduled your payment
          {scheduledPaymentDate
            ? ` on ${moment(scheduledPaymentDate, 'YYYYMMDD').format('MMMM D')}`
            : ''}{' '}
          and sent you a scheduled payment confirmation email.
        </P>
      </SuccessScreenTemplate>
      <PostPaymentSurveyModal
        isOpen={showSurveyModal}
        onClose={() => setShowSurveyModal(false)}
        paymentScheduledLater={!!scheduledPaymentDate}
      />
    </>
  );
};
