import {
  ConnectorProps,
  emailSuggestionWarning,
  RegisterUserApi,
  required,
  validPhoneNumber,
} from '@hellobrigit/brigit-common';
import { SignupSourceType } from '@hellobrigit/brigit-rest-api';
import { FadeIn } from 'animate-components';
import { AsYouType } from 'libphonenumber-js';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { SetStepAction } from '../../actions/nextStepActions';
import { SetEmailAction, SetPhoneAction } from '../../actions/onboardingActions';
import { InternalUrls } from '../../constants/InternalUrls';
import { OnboardingRoutes, OnboardingStep } from '../../constants/OnboardingSteps';
import { useVerifyRecaptcha } from '../../hooks/useVerifyRecaptcha';
import { AppState } from '../../store';
import { isProductionEnv } from '../../utils/environment';
import { HttpHeader } from '../../utils/http';
import { validEmail } from '../../utils/validators';
import { ApiErrorAlert } from '../ApiErrorAlert';
import { BrigitButton } from '../buttons/BrigitButton';
import { ReduxInputField } from '../ReduxInputField';
import { ButtonText, H1, P, P1 } from '../Typography';

const mapStateToProps = (state: AppState) => ({
  attribution: state.attribution,
  apiCall: state.api.get(RegisterUserApi.id),
});

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: RegisterUserApi.bindDispatch(dispatch),
    ...bindActionCreators(
      {
        setStep: SetStepAction,
        setEmail: SetEmailAction,
        setPhone: SetPhoneAction,
        changeFormValue: change,
      },
      dispatch,
    ),
  };
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (stateProps: StoreProps, dispatchProps: DispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

type Props = InjectedFormProps & ConnectorProps<typeof connector>;

const enableCaptcha = isProductionEnv();

const EmailAndNumber: FunctionComponent<Props> = (props) => {
  const { changeFormValue, invalid, handleSubmit, apiCall, setEmail, setPhone, setStep } = props;

  const { token, onVerify, hideCaptchaBadge } = useVerifyRecaptcha();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const shouldResubmitFormWithNewCaptcha = useRef(false);
  const previousToken = useRef<string>();
  const resubmitForm = useRef<(token?: string) => void>();

  const submitHandler = useCallback(
    (form, _, api) => {
      const anonymousId =
        analytics && typeof analytics.user === 'function'
          ? analytics.user().anonymousId()
          : undefined;
      const { irclickid, snapchatClickID, ...other } = api.attribution;

      window.gtag('event', 'click', { category: 'sign-up-button' });
      setPhone({ phone: form.phoneNumber });
      setEmail({ email: form.emailAddress });
      const submitForm = (recaptchaToken = token) => {
        return api.registerUser(
          {
            ...form,
            ...other,
            sourceClickId: irclickid || snapchatClickID,
            anonymousId,
            signupSource: SignupSourceType.WEB,
          },
          enableCaptcha && {
            headers: {
              [HttpHeader.RECAPTCHA]: recaptchaToken,
            },
          },
          hideCaptchaBadge(),
        );
      };
      submitForm();
      // Store the callback to resubmit with refreshed recaptcha if neccessary
      resubmitForm.current = submitForm;
    },
    [hideCaptchaBadge, setEmail, setPhone, token],
  );

  // Sets the email to the email passed in from the URL params
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    if (email) {
      changeFormValue('emailAndNumber', 'email', email);
    }
  }, [changeFormValue]);

  // If the API call fails, get a new recapcha code to submit
  useEffect(() => {
    if (
      apiCall?.error &&
      apiCall?.error.message.includes('reCAPTCHA') &&
      shouldResubmitFormWithNewCaptcha.current === false
    ) {
      shouldResubmitFormWithNewCaptcha.current = true;
      setRefreshReCaptcha(!refreshReCaptcha);
    }
  }, [apiCall?.error, refreshReCaptcha]);

  // Submit the form with a new recapcha code if the form failed due to the recapcha code
  useEffect(() => {
    if (shouldResubmitFormWithNewCaptcha.current === true && token !== previousToken.current) {
      shouldResubmitFormWithNewCaptcha.current = false;
      if (resubmitForm.current) {
        resubmitForm.current(token);
      }
      resubmitForm.current = undefined;
    }
  }, [token, apiCall?.error, handleSubmit, submitHandler]);

  useEffect(() => {
    previousToken.current = token;
  }, [token]);

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      const phoneInput = document.getElementById('signup-phone');
      phoneInput.focus();
    }
  }

  return (
    <FadeIn duration="1s">
      {enableCaptcha && <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />}
      <Row className="justify-content-center title-margin-top brigit-row">
        <Col lg="6" md="6" sm="12">
          <div>
            <H1 className="mb-4 text-center">Let's get started</H1>
            <div className="pt-2 field-container">
              <ApiErrorAlert apiAction={RegisterUserApi} />
            </div>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="pt-3 field-container">
                <Field
                  id="signup-email"
                  name="email"
                  component={ReduxInputField}
                  type="text"
                  placeholder="Email"
                  validate={[required, validEmail]}
                  warn={[emailSuggestionWarning]}
                  autoFocus
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </div>
              <div className="pt-4 pb-4 field-container">
                <Field
                  id="signup-phone"
                  name="phoneNumber"
                  component={ReduxInputField}
                  type="tel"
                  format={(value) => new AsYouType('US').input(value).replace(/\s*\)$/, '')}
                  placeholder="Phone"
                  validate={[required, validPhoneNumber]}
                />
              </div>
              <div className="text-center pb-3 pt-2 field-container">
                <P1>
                  We’ll send you an SMS to confirm your number. By providing your phone number and
                  clicking ‘Text Code’, you agree to receive promotional emails and texts from
                  Brigit. Message and data rates may apply.
                </P1>
              </div>
              <div className="text-center pb-2">
                <P1 className="font-color-lg">
                  By signing up, you agree to our{' '}
                  <a href={InternalUrls.TERMS_OF_SERVICE}>
                    <span className="font-dark-green semi-bold">Terms of Service</span>
                  </a>
                  {' and '}
                  <a href={InternalUrls.PRIVACY_POLICY}>
                    <span className="font-dark-green semi-bold">Privacy Policy</span>
                  </a>
                  .
                </P1>
              </div>
              <div className="text-center pb-3">
                <BrigitButton submit invalid={invalid} apiCall={apiCall} eventStage="phone">
                  <ButtonText className="font-white">Text code</ButtonText>
                </BrigitButton>
              </div>
            </form>
            <P className="text-center">
              Already have an account?{' '}
              <Link
                onClick={() => setStep({ step: OnboardingStep.LOGIN })}
                to={OnboardingRoutes[OnboardingStep.LOGIN]}
                className="font-dark-green semi-bold"
              >
                Log in
              </Link>
            </P>
          </div>
        </Col>
      </Row>
    </FadeIn>
  );
};

export const EmailAndNumberForm = connector(
  reduxForm({
    form: 'emailAndNumber',
  })(EmailAndNumber),
);
