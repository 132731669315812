import { ConnectorProps } from '@hellobrigit/brigit-common';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import { AppLinks } from '../../constants/AppLinks';
import { InternalUrls } from '../../constants/InternalUrls';
import { AppState, SubscriptionType } from '../../store/types';
import { lightest2 } from '../../utils/colors';
import { RootRoutes } from '../../utils/routes';
import { NavIconRow } from '../home/NavIconRow';
import { P1 } from '../Typography';

const mapStateToProps = (state: AppState) => {
  const {
    subscriptions: { subscriptionType },
  } = state;
  return {
    hasTieredSubscriptionsAccess:
      subscriptionType === SubscriptionType.TIERED_SUBSCRIPTIONS_NEW_TIERS,
  };
};

const connector = connect(mapStateToProps);

interface Props extends ConnectorProps<typeof connector> {
  isSettingsScreen?: boolean;
}

const SideNavBase: FC<Props> = ({ hasTieredSubscriptionsAccess, isSettingsScreen }) => {
  const shouldShowDownloadLinks = !(isSettingsScreen && hasTieredSubscriptionsAccess);
  return (
    <Col
      className="d-lg-flex d-none"
      style={{ background: lightest2, minWidth: hasTieredSubscriptionsAccess ? 247 : undefined }}
      lg={hasTieredSubscriptionsAccess ? '2' : '3'}
    >
      <div style={{ position: 'fixed' }}>
        <div className="mt-5 pl-4 d-flex flex-column">
          <NavIconRow navPath="/home" iconPath="/nav/home_nav_icon.svg" text="Home" />
          <NavIconRow
            id="nav-settings"
            navPath={RootRoutes.SETTINGS}
            iconPath="/nav/settings.svg"
            imgStyle={{ padding: '4px' }}
            text="Settings"
          />
          <NavIconRow
            navPath={RootRoutes.HOW_IT_WORKS}
            iconPath="/nav/faq.svg"
            text="How Brigit Works"
          />
          <NavIconRow
            externalLink
            navPath={InternalUrls.BRIGIT_FAQ}
            iconPath="/nav/how_it_works.svg"
            text="FAQ"
          />
          <NavIconRow
            externalLink
            navPath="mailto:info@hellobrigit.com"
            iconPath="/nav/contact_us_nav.svg"
            text="Contact Us"
          />
          <NavIconRow
            externalLink={false}
            iconPath="/nav/empty_nav_icon.svg"
            navPath={RootRoutes.LOGOUT}
            text={hasTieredSubscriptionsAccess ? 'Log out' : 'Log Out'}
            color={hasTieredSubscriptionsAccess ? '#333333' : 'text-danger'}
          />
          {shouldShowDownloadLinks && (
            <div style={{ position: 'fixed', bottom: 10 }}>
              <P1 className="font-black bold mb-0">Download the app</P1>
              <div className="pt-2 d-flex flex-column">
                <a href={AppLinks.GOOGLE_PLAY}>
                  <img style={{ width: '126px' }} src="/google_play.svg" />
                </a>
                <a href={AppLinks.APP_STORE}>
                  <img className="pt-3" style={{ width: '126px' }} src="/app_store.svg" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};

export const SideNav = connector(SideNavBase);
