import { ConnectorProps, RegisterTabapayCardRecoveryApiV2 } from '@hellobrigit/brigit-common';
import { RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import { FadeIn } from 'animate-components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { compose } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { RecoveryLocationState } from '../../../containers/types';
import { AppState } from '../../../store';
import { DebitRecoveryRoutes } from '../../../utils/routes';
import { required, validState, validZipCode } from '../../../utils/validators';
import { BrigitButton } from '../../buttons/BrigitButton';
import { ListHeader } from '../../ListHeader';
import { AddressFields, PlacesDropdown } from '../../PlacesDropdown';
import { ReduxInputField } from '../../ReduxInputField';
import { ButtonText, P2 } from '../../Typography';
import { RecoveryErrorAlert } from '../RecoveryErrorAlert';
import { RepaymentHeader } from '../RepaymentHeader';

export const BILLING_DETAILS_FORM = 'billingDetailsForm';

export interface BillingDetailsFormData {
  firstName: string;
  lastName: string;
  lineOne: string;
  lineTwo: string;
  city: string;
  state: string;
  zipCode: string;
}

const mapStateToProps = (state: AppState) => {
  const { api } = state;
  return {
    apiCall: api.get(RegisterTabapayCardRecoveryApiV2.id),
  };
};

const connector = connect(mapStateToProps);

interface OwnProps extends RecoveryLocationState {
  registerTabapayCard: () => void;
  errors: string[];
}

type Props = OwnProps &
  ConnectorProps<typeof connector> &
  InjectedFormProps<BillingDetailsFormData, OwnProps>;

const BillingDetailsBase: FunctionComponent<Props> = (props) => {
  const { payNow, registerTabapayCard, apiCall, invalid, change, errors } = props;

  /**
   * Passed in to `PlacesDropdown` to handle when user selects an autocomplete
   * option -- once an option is selected, will change the other address fields to match
   * the address selected
   */
  const inputOnChange = (parsedAddress: AddressFields) => {
    const { streetAddress, city, state, zipCode } = parsedAddress;
    change('lineOne', streetAddress);
    change('city', city);
    change('state', state);
    change('zipCode', zipCode);
  };

  return (
    <>
      <RepaymentHeader
        paymentMethod={RecoveryPaymentSource.CARD}
        payNow={payNow}
        activeRoute={DebitRecoveryRoutes.BILLING_DETAILS}
      />
      <Row className="text-lg-center">
        <ListHeader style={{ width: '100%' }}>Add billing details</ListHeader>
      </Row>
      {errors.length > 0 && <RecoveryErrorAlert errors={errors} />}

      <div className="d-flex flex-column align-items-center pb-2" style={{ paddingTop: '25px' }}>
        <FadeIn duration="1s" style={{ width: '100%', maxWidth: '500px' }}>
          <P2 className="semi-bold font-black pb-1 mb-0">Name on Card</P2>
          <div className="d-flex justify-content-between">
            <div style={{ width: '45%' }}>
              <Field
                id="field-firstName"
                name="firstName"
                component={ReduxInputField}
                type="name"
                placeholder="First"
                validate={[required]}
              />
            </div>
            <div style={{ width: '45%' }}>
              <Field
                id="field-lastName"
                name="lastName"
                component={ReduxInputField}
                type="name"
                placeholder="Last"
                validate={[required]}
              />
            </div>
          </div>
          <div className="pt-2">
            <Field
              id="field-lineOne"
              name="lineOne"
              component={PlacesDropdown}
              type="text"
              placeholder="Street Address"
              inputOnChange={inputOnChange}
              validate={[required]}
            />
          </div>
          <div className="pt-2">
            <Field
              id="field-lineTwo"
              name="lineTwo"
              component={ReduxInputField}
              type="text"
              placeholder="Street Address Line 2 (Optional)"
            />
          </div>
          <div className="d-flex justify-content-between pt-2">
            <div style={{ width: '45%' }}>
              <Field
                id="field-city"
                name="city"
                component={ReduxInputField}
                type="text"
                placeholder="City"
                validate={[required]}
              />
            </div>
            <div style={{ width: '45%' }}>
              <Field
                id="field-state"
                name="state"
                component={ReduxInputField}
                type="text"
                placeholder="State"
                validate={[required, validState]}
              />
            </div>
          </div>
          <div className="pt-2">
            <Field
              id="field-zip"
              name="zipCode"
              component={ReduxInputField}
              type="text"
              placeholder="Zip Code"
              validate={[required, validZipCode]}
            />
          </div>
        </FadeIn>

        <BrigitButton
          eventStage="registerTabapayRecoveryCard"
          onClick={registerTabapayCard}
          apiCall={apiCall}
          invalid={invalid}
          fullWidthOnMobile
          style={{ marginTop: 25 }}
        >
          <ButtonText id="submit-review" className="font-white">
            Continue to Review Plan
          </ButtonText>
        </BrigitButton>
      </div>
    </>
  );
};

export const BillingDetails = compose(
  reduxForm<BillingDetailsFormData, OwnProps>({ form: BILLING_DETAILS_FORM }),
  connector,
)(BillingDetailsBase);
