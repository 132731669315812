import { ConnectorProps } from '@hellobrigit/brigit-common';
import moment from 'moment';
import numeral from 'numeral';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { ListItem } from '../../ListItem';
import { ListSeparator } from '../../ListSeparator';
import { P, P1 } from '../../Typography';

interface OwnProps {
  paymentFrom?: string;
}

const mapStateToProps = (state: AppState) => {
  const { recoveryDetails } = state;
  const { dueAmount, installmentPlanDetails } = { ...recoveryDetails };
  const { installments } = { ...installmentPlanDetails };

  return {
    dueAmount,
    installments,
  };
};

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectorProps<typeof connector>;

const InstallmentDetailsBase: FunctionComponent<Props> = (props) => {
  const { dueAmount, paymentFrom, installments } = props;
  const formattedDueAmount = numeral(dueAmount).format('$0,0.00');

  const installmentAmountAndDates = installments.map((i) => (
    <div key={i.id} className="col">
      <P1 className="font-black mb-0">{numeral(i.amount).format('$0,0.00')}</P1>
      <P1 className="font-grey mb-0">{moment(i.date).format('MMM D')}</P1>
    </div>
  ));

  return (
    <>
      <ListItem right={<P className="font-black mb-0">{formattedDueAmount}</P>}>
        <P className="font-black semi-bold mb-0">Total due</P>
      </ListItem>
      <ListSeparator />
      <ListItem right={<P className="font-black mb-0">{installments.length}</P>}>
        <P className="font-black semi-bold mb-0">No. of payments</P>
      </ListItem>
      <ListSeparator />
      {paymentFrom && (
        <>
          <ListItem right={<P className="font-black mb-0">{paymentFrom}</P>}>
            <P className="font-black semi-bold mb-0">Payment from</P>
          </ListItem>
          <ListSeparator />
        </>
      )}
      <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 25, paddingBottom: 25 }}>
        <P className="font-black semi-bold mb-0" style={{ paddingBottom: 20 }}>
          Payment schedule
        </P>
        <div className="row">{installmentAmountAndDates}</div>
      </div>
      <ListSeparator />
    </>
  );
};

export const InstallmentDetails = connector(InstallmentDetailsBase);
