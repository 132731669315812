import './css/reddit-april-5.css';
import '../styles/brigitButtons.css';
import '../styles/main.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { BrigitButton } from '../components/buttons/BrigitButton';

const BASE_URL = 'www.app.hellobrigit.com';

export default class RedditApril5 extends Component {
  public render() {
    return (
      <Container fluid className="p-0">
        <Row className="m-0">
          <Col lg={6} xl={5} className="d-none d-lg-block p-0" style={{ minHeight: '100vh' }}>
            <img className="phone" src="/landing_pages/reddit-april-5/phone.png" />
          </Col>
          <Col
            lg={6}
            xl={7}
            className="d-flex flex-column justify-content-center ph-4 pt-3"
            style={{ minHeight: '100vh' }}
          >
            <div className="d-flex align-items-center pb-4">
              <h1 className="bold m-0">Hey Reddit</h1>
              <img
                height={40}
                className="pl-2"
                src="/landing_pages/reddit-april-5/reddit-logo.jpg"
              />
            </div>

            <h5 className="grey pb-4" style={{ lineHeight: '1.3' }}>
              Thanks for taking an interest in Brigit. We're a financial app that sends our users up
              to $250 when they need it most.
            </h5>

            <h4 className="pb-1">/r/explainLikeImFive</h4>
            <ul className="pb-3">
              <li>
                <p>We send cash advances when you need it</p>
              </li>
              <li>
                <p>Up to $250 (determined based on income)</p>
              </li>
              <li>
                <p>You repay us back when you have funds</p>
              </li>
              <li>
                <p>No interest</p>
              </li>
              <li>
                <p>$9.99/month for unlimited advances</p>
              </li>
              <li>
                <p>No credit check (we analyze your transaction history)</p>
              </li>
            </ul>

            <div className="d-flex flex-column cta-section pl-4">
              {this.navigateToApp()}
              <div className="rating-items">
                <img
                  className="m-0"
                  width={125}
                  src="/landing_pages/reddit-april-5/review-stars.png"
                />
                <p className="mt-1 mh-0 ml-sm-1 mb-sm-0">
                  <b className="bold">4.8 rating</b> on Apple and Google Play
                </p>
              </div>
              <img
                className="mt-1 mt-sm-3"
                width={100}
                src="/landing_pages/reddit-april-5/brigit-logo.png"
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  private navigateToApp() {
    const url = BASE_URL + window.location.search;
    return (
      <Link to={url}>
        <BrigitButton
          className="onboarding-submit bg-dark-green nohover-dg mb-3"
          onClick={this.navigateToApp}
        >
          Get Brigit
        </BrigitButton>
      </Link>
    );
  }
}
