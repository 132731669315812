import '../../styles/brigitButtons.css';
import { ApiCallState } from '@hellobrigit/brigit-common/dist/store/ApiState';
import classNames from 'classnames';
import { isNil } from 'lodash';
import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import { Button, ButtonProps } from 'reactstrap';
import { primary, white } from '../../utils/colors';

interface Props extends Omit<ButtonProps, 'ref' | 'onClick'> {
  invalid?: boolean;
  apiCall?: ApiCallState;
  loading?: boolean;
  eventStage?: string;
  minimal?: boolean;
  inverted?: boolean;
  submit?: boolean;
  fullWidthOnMobile?: boolean;
  fullWidthOnMobileWithSideNav?: boolean;
  onClick?: () => void;
}

export class BrigitButton extends Component<Props> {
  public trackClick = () => {
    const { eventStage, onClick } = this.props;

    if (!isNil(eventStage)) {
      analytics.track('click', {
        category: `web_${eventStage}`,
        label: eventStage,
      });
    }

    if (!isNil(onClick)) {
      onClick();
    }
  };

  public render() {
    const {
      invalid,
      apiCall,
      submit,
      children,
      eventStage,
      minimal,
      inverted,
      fullWidthOnMobile,
      fullWidthOnMobileWithSideNav,
      className,
      loading,
      ...props
    } = this.props;
    const buttonClass =
      className ||
      classNames({
        'full-width-on-mobile': fullWidthOnMobile,
        'full-width-on-mobile-with-side-nav': fullWidthOnMobileWithSideNav,
        'minimal font-dark-green': minimal,
        'onboarding-submit bg-dark-green nohover-dg': !minimal && !inverted,
        'inverted font-dark-green': inverted,
      });

    return (
      <Button
        {...props}
        disabled={invalid || apiCall?.requesting}
        className={buttonClass}
        type={submit ? 'submit' : 'button'}
        onClick={this.trackClick}
      >
        {apiCall?.requesting || loading ? (
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <ClipLoader color={minimal ? primary : white} size={20} />
          </div>
        ) : (
          children
        )}
      </Button>
    );
  }
}
