export enum OnboardingStep {
  EMAIL_NUMBER = 'EMAIL_NUMBER',
  AUTH_PHONE = 'AUTH_PHONE',
  SET_PIN = 'SET_PIN',
  LINK_BANK = 'LINK_BANK',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  WAIT_FOR_BANK = 'WAIT_FOR_BANK',
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  WAITLIST = 'WAITLIST',
  ENTER_CODE = 'ENTER_CODE',
  LOGIN = 'LOGIN',
  AUTH_PIN = 'AUTH_PIN',
  RELINK_BANK = 'RELINK_BANK',
  ACTIVATE_SAFETY = 'ACTIVATE_SAFETY',
  DENIED_PROTECTION = 'DENIED_PROTECTION',
  HOME = 'HOME',
  AUTH_EMAIL = 'AUTH_EMAIL',
}

export enum SignupSource {
  WEB = 'WEB',
}
