import styled, { css } from 'styled-components';
import * as Color from '../utils/colors';
import { Font } from '../utils/fonts';

const breakpoint = `min-width: 992px`; // lines up with breakpoint where side nav bar becomes hamburger menu

/**
 * The fixed field indicates if the Typography element's font size is responsive
 * based on the screen size.  It only impacts Body elements (P, P1, P2) --
 * all others are automatically responsive.
 * This field addresses the fact that some Body elements should be the same size
 * on Desktop and Mobile.  By default, the elements are responsive.
 */
interface TypographyProps {
  fixed?: boolean;
}

// Jumbo display
export const Display = styled.h1`
  font-family: ${Font.BOLD};
  font-size: 3.125rem;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: ${Color.dark};

  @media (${breakpoint}) {
    font-size: 4.25rem;
  }
`;

// Header 1
export const H1 = styled.h1`
  font-family: ${Font.BOLD};
  font-size: 1.875rem;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: ${Color.dark};

  @media (${breakpoint}) {
    font-size: 2.25rem;
    line-height: 130%;
  }
`;

// Header 2
// all !important to override main.css
export const H2 = styled.h2`
  font-family: ${Font.BOLD} !important;
  font-size: 1.75rem !important;
  color: ${Color.dark} !important;
  line-height: 2.25rem !important;
  letter-spacing: -0.02em !important;

  @media (${breakpoint}) {
    font-size: 1.875rem !important;
    line-height: 2.375rem !important;
  }
`;

// Header 3
export const H3 = styled.h3`
  font-family: ${Font.BOLD};
  font-size: 1.5rem;
  color: ${Color.dark};
  line-height: 1.875rem;

  @media (${breakpoint}) {
    font-size: 1.625rem;
    line-height: 2.0625rem;
  }
`;

// Header 4
export const H4 = styled.h4`
  font-family: ${Font.BOLD};
  font-size: 1.375rem;
  color: ${Color.dark};
  line-height: 1.75rem;

  @media (${breakpoint}) {
    font-size: 1.5rem;
    line-height: 150%;
  }
`;

// body large
export const H5 = styled.h5`
  font-family: ${Font.BOLD};
  color: ${Color.white};
  letter-spacing: -0.02em;
  font-size: 1.125rem;
  line-height: 180%;
`;

// If not fixed (responsive): Body Large on Desktop, Body on Mobile
// Otherwise, Body on both
export const P = styled.p<TypographyProps>`
  font-family: ${Font.REGULAR};
  font-size: 1rem;
  color: ${Color.semiDark};
  line-height: 180%;

  ${(props) =>
    !props.fixed &&
    css`
      @media (${breakpoint}) {
        font-size: 1.125rem;
      }
    `}
`;

// If not fixed (responsive), Body on Desktop, Body small on Mobile
// Otherwise, Body small on both
export const P1 = styled.p<TypographyProps>`
  font-family: ${Font.REGULAR};
  font-size: 0.875rem;
  color: ${Color.dark};
  line-height: 180%;

  ${(props) =>
    !props.fixed &&
    css`
      @media (${breakpoint}) {
        font-size: 1rem;
      }
    `}
`;

// If not fixed (responsive), Body small on Desktop, Body tiny on Mobile
// Otherwise, Body tiny on both
export const P2 = styled.p<TypographyProps>`
  font-family: ${Font.REGULAR};
  font-size: 0.75rem;
  color: ${Color.dark};
  line-height: 140%;

  ${(props) =>
    !props.fixed &&
    css`
      @media (${breakpoint}) {
        font-size: 0.875rem;
      }
    `}
`;

// pulled from figma
export const P3 = styled.p<TypographyProps>`
  font-family: ${Font.REGULAR};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

// pulled from figma
export const P4 = styled.p<TypographyProps>`
  font-family: ${Font.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
`;

// pulled from figma
export const P5 = styled.p<TypographyProps>`
  font-family: ${Font.REGULAR};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
`;

export const Subscript = styled.p<TypographyProps>`
  font-family: ${Font.REGULAR};
  font-size: 0.625rem;
  color: ${Color.dark};
  line-height: 0.8125rem;

  @media (${breakpoint}) {
    font-size: 0.75rem;
  }
`;

export const LinkText = styled.p<TypographyProps>`
  font-family: ${Font.BOLD};
  color: ${Color.primary};
  font-size: 1rem;
  line-height: 180%;
  text-decoration: none;

  :hover {
    color: ${Color.primary};
  }
  @media (${breakpoint}) {
    font-size: 1.125rem;
  }
`;

export const ButtonText = styled.p<TypographyProps>`
  font-family: ${Font.BOLD};
  color: ${Color.dark};
  font-size: 1.125rem;
  line-height: 1.4375rem;
  letter-spacing: 0.04em;
  margin-bottom: 0;
`;
