import { SetPinApi } from '@hellobrigit/brigit-common';
import { ApiCallState } from '@hellobrigit/brigit-common/dist/store/ApiState';
import { FadeIn } from 'animate-components';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { matchingPins, normalizePin, required } from '../../utils/validators';
import { ApiErrorAlert } from '../ApiErrorAlert';
import { BrigitButton } from '../buttons/BrigitButton';
import { ReduxInputField } from '../ReduxInputField';
import { ButtonText, H1, P } from '../Typography';

interface OwnProps {
  apiCall: ApiCallState;
}

type Props = OwnProps & InjectedFormProps<Record<string, unknown>, OwnProps>;

const SetPin: FunctionComponent<Props> = (props) => {
  const { invalid, handleSubmit, apiCall } = props;
  return (
    <FadeIn duration="1s">
      <Row className="justify-content-center title-margin-top brigit-row">
        <Col className="mb-3 brigit-row" lg="6" md="6" sm="12">
          <div className="text-center">
            <H1>Secure your account with a PIN</H1>
            <P className="text-center mb-lg-3 mb-2">
              It should be 4 digits and easy to remember.
              <br />
              You will use this to log into your account in the future.
            </P>
            <div className="pt-2 field-container">
              <ApiErrorAlert apiAction={SetPinApi} />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="pt-3 field-container">
                <Field
                  name="pin"
                  component={ReduxInputField}
                  type="password"
                  inputMode="numeric"
                  pattern="\d*"
                  placeholder="Set 4-digit pin"
                  maxLength={4}
                  props={{ inputMode: 'numeric' }}
                  normalize={normalizePin}
                  validate={[required]}
                />
              </div>
              <div className="pt-4 pb-5 field-container">
                <Field
                  name="confirmPin"
                  component={ReduxInputField}
                  type="password"
                  inputMode="numeric"
                  pattern="\d*"
                  placeholder="Confirm pin"
                  maxLength={4}
                  normalize={normalizePin}
                  validate={[matchingPins]}
                />
              </div>
              <div className="text-center pb-3">
                <BrigitButton submit invalid={invalid} apiCall={apiCall} eventStage="pinSetup">
                  <ButtonText className="font-white">Continue</ButtonText>
                </BrigitButton>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </FadeIn>
  );
};

export const SetPinForm = reduxForm<Record<string, unknown>, OwnProps>({
  form: 'pin',
})(SetPin);
