import React, { FunctionComponent } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/failure.json';
import { getLottieOptionsFor } from './lottieHelper';

interface Props {
  width?: number;
}

const defaultOptions = getLottieOptionsFor(animationData);

export const FailureAnimatedIcon: FunctionComponent<Props> = ({ width }) => {
  return (
    <Lottie options={defaultOptions} width={width || 200} isStopped={false} isPaused={false} />
  );
};
