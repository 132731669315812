import { TypedAction } from 'redoodle';

export const LoadLocationStateAction = TypedAction.define(
  '@brigit/underwriting/load_location_state',
)<void>();

interface SetLocationStatePayload {
  locationState: string | undefined;
}

export const SetLocationStateAction = TypedAction.define(
  '@brigit/underwriting/set_location_state',
)<SetLocationStatePayload>();
