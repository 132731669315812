import { CheckingAccountResponse, RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import React, { FunctionComponent } from 'react';
import { Row } from 'reactstrap';
import { RecoveryLocationState } from '../../../containers/types';
import { BankRecoveryRoutes } from '../../../utils/routes';
import { ListHeader } from '../../ListHeader';
import { PlaidAccountsList } from '../../plaid/PlaidAccountsList';
import { RecoveryErrorAlert } from '../RecoveryErrorAlert';
import { RepaymentHeader } from '../RepaymentHeader';

interface Props extends RecoveryLocationState {
  accounts: CheckingAccountResponse[];
  onSubmit: (selectedAccountIdx: number) => void;
  errors: string[];
}

export const SelectRecoveryAccount: FunctionComponent<Props> = ({
  accounts,
  onSubmit,
  payNow,
  errors,
}) => (
  <>
    <RepaymentHeader
      paymentMethod={RecoveryPaymentSource.ACH}
      payNow={payNow}
      activeRoute={BankRecoveryRoutes.SELECT_ACCOUNT}
    />
    <Row className="text-lg-center">
      <ListHeader style={{ width: '100%' }}>Select account</ListHeader>
    </Row>
    {errors.length > 0 && <RecoveryErrorAlert errors={errors} />}
    <div style={{ paddingTop: '25px' }}>
      <PlaidAccountsList accounts={accounts} onSubmit={onSubmit} />
    </div>
  </>
);
