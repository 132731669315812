export enum RootRoutes {
  ROOT = '/',
  SIGNUP = '/signup',
  SIGNUP_EMAIL = '/signup/email',
  AUTH_PHONE = '/auth_phone',
  AUTH_EMAIL = '/auth_email',
  HOME = '/home',
  RELINK_BANK = '/relink_bank',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SETTINGS = '/settings',
  DEACTIVATE = '/deactivate',
  DOWNGRADE = '/downgrade',
  MANUAL_REPAYMENT = '/manual_repayment',
  DEACTIVATE_SUCCESS = '/deactivate_success',
  DELETE_ACCOUNT = '/delete_account',
  DELETE_SUCCESS = '/delete_success',
  HOW_IT_WORKS = '/how_it_works',
  RESET_PIN = '/reset_pin',
  CONFIRM_EMAIL = '/confirm_email',
  RECOVERY_REPAYMENT = '/repayment',
  REDDIT_ELI5 = '/reddit/explainLikeImFive',
  PAUSE_MEMBERSHIP_SUCCESS = '/pause_membership_success',
}

export enum SettingsRoutes {
  MEMBERSHIP = 'membership',
}

export enum MembershipRoutes {
  PAUSED = 'paused',
  PAUSE_SCHEDULED = 'pause_scheduled',
  PAYMENTS = 'payments',
  SUBSCRIPTION_PROMO = 'subscription_promo',
}

export enum DeleteRoutes {
  NOTICE = '/delete_account/notice',
  REJOIN_WARNING = '/delete_account/rejoin_warning',
  DELETE_ACCOUNT_CONFIRM = '/delete_account/confirm',
}

export enum DeactivateRoutes {
  SUCCESS = '/deactivate_success',
  SUCCESS_WITH_ADVANCE = '/deactivate/success_with_advance',
  NOT_REPAID = '/deactivate/not_repaid',
  PRECHARGE_OFF_NOT_REPAID = '/deactivate/unable_to_deactivate',
  STILL_PROCESSING = '/deactivate/still_processing',
  REQUEST_SUBMITTED = '/deactivate/request_submitted',
  PAYMENT_PROCESSING = '/deactivate/payment_processing',
  HELP_US_IMPROVE = '/deactivate/help_us_improve',
  CREDIT_LOCK_ENABLED = '/deactivate/credit_lock',
  CREDIT_BUILDER_ENABLED = '/deactivate/credit_builder',
  REQUEST_CONFIRM = '/deactivate/request_confirm',
  CREDIT_BUILDER_CONTACT_CX = '/deactivate/credit_builder_contact_cx',
  CREDIT_BUILDER_CLOSE_ACCOUNT_AGREEMENT = '/deactivate/close_account_agreement',
}

export enum DowngradeRoutes {
  REQUEST_CONFIRM = '/downgrade/request_confirm',
}

export enum RecoveryRoutes {
  REPAID = 'repaid',
  PROCESSING = 'processing',
  SCHEDULED = 'scheduled',
  BANK = 'bank',
  DEBIT = 'debit',
  PAYMENT_OPTIONS = 'payment_options',
  SET_UP_INSTALLMENT_PLAN = 'installment_plan',
}

export enum DebitRecoveryRoutes {
  SCHEDULE_PAYMENT = 'schedule_payment',
  CARD_DETAILS = 'card_details',
  BILLING_DETAILS = 'billing_details',
  CONFIRM_PAYMENT = 'confirm_payment',
}

export enum BankRecoveryRoutes {
  SCHEDULE_PAYMENT = 'schedule_payment',
  PAYMENT = 'payment',
  SELECT_ACCOUNT = 'select_account',
  CONFIRM_PAYMENT = 'confirm_payment',
}

export enum InstallmentRoutes {
  REVIEW_DETAILS = 'review_details',
  PAYMENTS_SCHEDULED = 'payments_scheduled',
}
