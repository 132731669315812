import { TypedReducer } from 'redoodle';
import {
  SetEmailAction,
  SetPhoneAction,
  SetWebFunnelSetNameAddContextAssignmentAction,
} from '../actions/onboardingActions';
import { ModelTypes } from '../graphql/zeus';

export interface OnboardingState {
  phone?: string;
  email?: string;
  webFunnelSetNameAddContextAssignment?: Pick<
    ModelTypes['FrontendExperimentVariantAssignment'],
    'isControlFlow'
  >;
}

const builder = TypedReducer.builder<OnboardingState>();

builder.withHandler(SetEmailAction.TYPE, (state, { email }) => {
  return { ...state, email };
});

builder.withHandler(SetPhoneAction.TYPE, (state, { phone }) => {
  return { ...state, phone };
});

builder.withHandler(
  SetWebFunnelSetNameAddContextAssignmentAction.TYPE,
  (state, { webFunnelSetNameAddContextAssignment }) => {
    return { ...state, webFunnelSetNameAddContextAssignment };
  },
);

export default builder.build();
