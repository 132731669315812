import {
  AppState,
  ConnectorProps,
  getMostRecentOutstandingLoan,
  getNextPaymentDate,
} from '@hellobrigit/brigit-common';
import { LoanTimelineType, UserFlag } from '@hellobrigit/brigit-rest-api';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useMemo } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import * as Color from '../../utils/colors';
import { hasUserFlag } from '../../utils/flags';
import { DeactivateRoutes, RootRoutes } from '../../utils/routes';
import { BrigitButton } from '../buttons/BrigitButton';
import { NavWrapper } from '../navigation/NavWrapper';
import { ButtonText, H1, LinkText, P1 } from '../Typography';

const mapStateToProps = (state: AppState) => {
  const {
    user: {
      id,
      details: {
        protection: { loansV5, subscriptions },
        flagsList,
      },
    },
  } = state;

  const { amount: subscriptionAmount, paymentDate: subscriptionPaymentDate } =
    subscriptions?.[0] ?? {};
  const subscriptionPaymentDateFormatted = moment(subscriptionPaymentDate, 'YYYYMMDD').format(
    'MMM Do',
  );

  const outstandingLoan = getMostRecentOutstandingLoan(loansV5);
  const outstandingLoanAmount = outstandingLoan.outstandingAmount;
  const loanUUID = outstandingLoan.id;
  const outstandingLoanSentDate = outstandingLoan.timelineV2.filter(
    (e) => e.type === LoanTimelineType.SENT,
  )[0].timestamp;
  const outstandingLoanSentDateFormatted = moment(outstandingLoanSentDate, 'YYYYMMDD').format(
    'MMMM Do',
  );
  const nextLoanPaymentDate = moment(
    getNextPaymentDate(outstandingLoan.timelineV2),
    'YYYYMMDD',
  ).format('MMM Do');

  const hasPcoLoan = outstandingLoan.isPrechargeOff;

  const showPcoLoanContent =
    hasUserFlag(flagsList, UserFlag.DEACTIVATE_WITH_PCO_LOAN_TEST) && hasPcoLoan;

  return {
    subscriptionAmount,
    subscriptionPaymentDateFormatted,
    showPcoLoanContent,
    outstandingLoanAmount,
    outstandingLoanSentDateFormatted,
    nextLoanPaymentDate,
    loanUUID,
    userId: id,
  };
};

const connector = connect(mapStateToProps);

type Props = RouteComponentProps & ConnectorProps<typeof connector>;

const DeactivateSuccessWithAdvanceBase: FunctionComponent<Props> = ({
  subscriptionAmount,
  subscriptionPaymentDateFormatted,
  showPcoLoanContent,
  outstandingLoanAmount,
  outstandingLoanSentDateFormatted,
  nextLoanPaymentDate,
  loanUUID,
}) => {
  const messages = useMemo(() => {
    const m = [];
    if (showPcoLoanContent) {
      m.push({
        iconSrc: '/deactivate/warning.svg',
        text: (
          <>
            Your advance repayment of <span className="semi-bold">${outstandingLoanAmount}</span> on{' '}
            <span className="semi-bold">{outstandingLoanSentDateFormatted}</span> is overdue
          </>
        ),
      });
    }

    m.push({
      iconSrc: '/icons/receipt.svg',
      text: (
        <>
          Your final subscription payment of{' '}
          <span className="semi-bold">${subscriptionAmount}</span> is processing on{' '}
          <span className="semi-bold">{subscriptionPaymentDateFormatted}</span>
        </>
      ),
    });

    if (!showPcoLoanContent) {
      m.push({
        iconSrc: '/icons/receipt.svg',
        text: (
          <>
            Your advance repayment of <span className="semi-bold">${outstandingLoanAmount}</span> is
            processing on <span className="semi-bold">{nextLoanPaymentDate}</span>
          </>
        ),
      });
    }

    return m;
  }, [
    nextLoanPaymentDate,
    outstandingLoanAmount,
    outstandingLoanSentDateFormatted,
    showPcoLoanContent,
    subscriptionAmount,
    subscriptionPaymentDateFormatted,
  ]);
  return (
    <NavWrapper>
      <div
        className="del-wrapper"
        style={{ paddingTop: 60, justifyContent: 'flex-start', height: '100vh' }}
      >
        <img src="/deactivate/phone-brigit-app.svg" />
        <H1 className="text-center" style={{ paddingTop: 40 }}>
          Your subscription has been cancelled!
        </H1>

        {!isEmpty(messages) ? (
          <div className="d-flex flex-column mt-4 ml-5">
            <P1 style={{ textAlign: 'left', color: Color.dark }}>Don't forget!</P1>
            <div>
              {messages.map(({ text }) => (
                <div className="d-flex flex-row mb-1" key={text.key}>
                  <img src="/deactivate/more-info-outline.svg" />
                  <P1 className="mb-0 ml-1" style={{ color: Color.semiDark }}>
                    {text}.
                  </P1>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        <div className="d-flex flex-column align-items-center w-100 mt-5">
          <Link
            to={{
              pathname: DeactivateRoutes.HELP_US_IMPROVE,
            }}
            className="full-width-on-mobile-with-side-nav"
          >
            <BrigitButton style={{ width: '100%' }}>
              <ButtonText className="font-white">Close</ButtonText>
            </BrigitButton>
          </Link>
          {showPcoLoanContent && (
            <div style={{ paddingTop: 20 }}>
              <Link to={`${RootRoutes.RECOVERY_REPAYMENT}?id=${loanUUID}`}>
                <LinkText>Manage advance repayment</LinkText>
              </Link>
            </div>
          )}
        </div>
      </div>
    </NavWrapper>
  );
};

export const DeactivateSuccessWithAdvance = connector(DeactivateSuccessWithAdvanceBase);
