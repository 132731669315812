import { ConfirmEmailApi, ConnectorProps } from '@hellobrigit/brigit-common';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { REDIRECT_TO_MOBILE_BRANCH_LINK } from '../../constants/general';
import { AppState } from '../../store';
import { primary } from '../../utils/colors';
import { BannerCornerLogo } from '../BannerCornerLogo';
import { LargeLoadingSpinner } from '../LoadingSpinner';
import { FailureAnimatedIcon } from '../lottie-icons/failure';
import { AccountSettingChangeType } from '../types/ResetPinOrConfirmEmailTypes';
import { SuccessDisplay } from './sections/SuccessDisplay';

interface OwnProps {
  token: string;
}

const mapStateToProps = (state: AppState) => ({
  apiCall: state.api.get(ConfirmEmailApi.id),
});

const mapDispatchToProps = (dispatch) => ({
  confirmEmail: ConfirmEmailApi.bindDispatch(dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectorProps<typeof connector>;

class ConfirmEmailPageBase extends React.Component<Props> {
  public componentDidMount() {
    const { token, confirmEmail } = this.props;
    confirmEmail({ token });
  }

  public render() {
    const { apiCall } = this.props;
    return (
      <Container className="min-height-100vh d-flex flex-column" fluid>
        <BannerCornerLogo linkUrl="/" />
        <Row className="font-black bg-dark-grey flex-grow-1 text-center justify-content-center">
          <Col>
            {apiCall.success && (
              <SuccessDisplay changeType={AccountSettingChangeType.CONFIRM_EMAIL} />
            )}
            {apiCall.error && (
              <>
                <div className="debit-form-section mt-5">
                  <FailureAnimatedIcon />
                </div>
                <div style={{ fontSize: '22px' }} className="bold debit-form-section mt-5">
                  This link has expired.
                </div>{' '}
                <div style={{ fontSize: '22px' }} className="bold debit-form-section">
                  Request a new reset link{' '}
                  <a className="font-dark-green" href={REDIRECT_TO_MOBILE_BRANCH_LINK}>
                    here
                  </a>
                  .
                </div>
              </>
            )}
            {apiCall.requesting && (
              <div className="mt-5">
                <LargeLoadingSpinner color={primary} />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export const ConfirmEmailPage = connector(ConfirmEmailPageBase);
