export enum ModalName {
  UNAPPROVED = 'UnapprovedModal',
  API_ERROR = 'ApiErrorModal',
  PRECHARGE_OFF_REPAY_ADVANCE_FOR_DEACTIVATE = 'PrechargeOffRepayAdvanceForDeactivateModal',
  ACTIVATE = 'ActivateModal',
  MANUAL_DISBURSAL = 'ManualDisbursalModal',
  SEND_RESET_PIN_EMAIL_CONFIRMATION = 'SendResetPinEmailConfirmationModal',
  RECOVERY_V1_PLAID_FORM_SECTION_ERROR = 'RecoveryV1PlaidFormSectionErrorModal',
  RESUME_MEMBERSHIP_CONFIRMATION = 'ResumeMembershipConfirmationModal',
  SUBSCRIPTION_PROMO_CONFIRMATION = 'SubscriptionPromoConfirmationModal',
  SUBSCRIPTION_PROMO_FAILURE = 'SubscriptionPromoFailureModal',
  RESUME_MEMBERSHIP = 'ResumeMembershipModal',
  DEACTIVATE_REQUEST_CANCELLED = 'DeactivateRequestCancelledModal',
  ACTIVATION_CONFIRMATION = 'ActivationConfirmationModal',
  INSTANT_CASH_LEARN_MORE = 'InstantCashLearnMoreModal',
  CREDIT_BUILDER_LEARN_MORE = 'CreditBuilderLearnMoreModal',
  ACTIVATE_TIERED_SUBSCRIPTION = 'ActivateTieredSubscriptionModal',
  ADDITIONAL_INFO_NEEDED_MODAL = 'AdditionalInfoNeededModal',
  ACTIVATION_CONFIRMATION_UNSUPPORTED_STATE_MODAL = 'ActivationConfirmationUnsupportedStateModal',
  SUBSCRIPTION_SUCCESS_MODAL = 'SubscriptionSuccessModal',
  GRAPHQL_ERROR_MODAL = 'GraphQLErrorModal',
  HOW_BILLING_WORKS_MODAL = 'HowBillingWorksModal',
  DELETE_CHECKLIST_MODAL = 'DeleteChecklistModal',
}
