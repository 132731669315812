import { CheckingAccountResponse } from '@hellobrigit/brigit-rest-api/index';
import { FadeIn } from 'animate-components';
import React from 'react';
import { BrigitButton } from '../buttons/BrigitButton';
import { AccountCard } from '../onboarding/AccountCard';
import { ButtonText } from '../Typography';

interface Props {
  accounts: CheckingAccountResponse[];
  onSubmit: (selectedAccountIdx: number) => void;
}

interface State {
  selectedAccountIdx: number;
}

export class PlaidAccountsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedAccountIdx: 0,
    };
  }

  public setSelectedAccount = (i) => {
    this.setState({
      selectedAccountIdx: i,
    });
  };

  public render() {
    const { accounts, onSubmit } = this.props;
    const { selectedAccountIdx } = this.state;
    return (
      <FadeIn duration="1s">
        <div className="debit-form-section mb-4">
          {accounts?.map((a, i) => (
            <AccountCard
              key={a.accountId}
              account={a}
              isSelected={i === selectedAccountIdx}
              onSelect={() => this.setSelectedAccount(i)}
              idx={i}
            />
          ))}
        </div>
        <div className="text-center">
          <BrigitButton submit onClick={() => onSubmit(selectedAccountIdx)}>
            <ButtonText className="font-white">Continue</ButtonText>
          </BrigitButton>
        </div>
      </FadeIn>
    );
  }
}
