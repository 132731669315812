enum Offsets {
  PRESET_PAUSE_SUBSCRIPTION_DAYS = 0,
  FREE_SUBSCRIPTION = 1,
}

export interface Config {
  variants: string[] | number[] | boolean[];
  offset: number;
}

export const presetPauseSubscriptionDays: Config = {
  variants: [31, 60],
  offset: Offsets.PRESET_PAUSE_SUBSCRIPTION_DAYS,
};

export const freeSubscription: Config = {
  variants: ['A', 'B', 'C', 'D'],
  offset: Offsets.FREE_SUBSCRIPTION,
};
