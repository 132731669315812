import { ConnectorProps, GetLoanRepaymentDetailsApi } from '@hellobrigit/brigit-common';
import { RecoveryCollectionStatus, RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import { AxiosError } from 'axios';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { Container } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { SetRecoveryRepaymentDetailsAction } from '../actions/recoveryActions';
import { ApiErrorModal } from '../components/ApiErrorModal';
import { LargeLoadingSpinner } from '../components/LoadingSpinner';
import { LogoNoMenuNav } from '../components/navigation/LogoNoMenuNav';
import { PaymentOptions } from '../components/recovery/payment-options/PaymentOptions';
import { RecoveryAdvanceRepaid } from '../components/recovery/RecoveryAdvanceRepaid';
import { RecoveryPaymentProcessing } from '../components/recovery/RecoveryPaymentProcessing';
import { RecoveryPaymentScheduled } from '../components/recovery/RecoveryPaymentScheduled';
import { RepayNowOrLater } from '../components/recovery/RepayNowOrLater';
import { AppState } from '../store';
import { primary } from '../utils/colors';
import { InstallmentRoutes, RecoveryRoutes, RootRoutes } from '../utils/routes';
import { BankRecoveryContainer } from './BankRecoveryContainer';
import { DebitRecoveryContainer } from './DebitRecoveryContainer';
import { InstallmentsContainer } from './InstallmentsContainer';
import { RecoveryLocationState, RecoveryParams } from './types';

const mapStateToProps = (state: AppState) => {
  const { api, recoveryDetails } = state;
  const { scheduledPaymentDate, status, validPaymentMethods, loanUUID } = {
    ...recoveryDetails,
  };

  return {
    getLoanRepaymentDetailsCall: api.get(GetLoanRepaymentDetailsApi.id),
    scheduledPaymentDate,
    status,
    validPaymentMethods,
    loanUUID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLoanRepaymentDetails: GetLoanRepaymentDetailsApi.bindDispatch(dispatch),
  ...bindActionCreators({ setRepaymentDetails: SetRecoveryRepaymentDetailsAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector> & RouteComponentProps;

class RecoveryContainerBase extends Component<Props> {
  public componentDidMount() {
    const { getLoanRepaymentDetails, setRepaymentDetails } = this.props;
    const { loanUUID, payNow, source } = this.getQueryParams();
    // eslint-disable-next-line no-undef
    analytics.track('Recovery App Link Opened', { source });

    getLoanRepaymentDetails(loanUUID)
      .then(({ data }) => {
        const { status, validPaymentMethods } = data;
        setRepaymentDetails({ ...data, loanUUID });
        this.handleNavigationForStatus(status, loanUUID, validPaymentMethods, payNow);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status === 404) return;

        throw error;
      });
  }

  public componentDidUpdate(prevProps) {
    const { status, loanUUID, validPaymentMethods } = this.props;

    if (prevProps.status !== status) {
      this.handleNavigationForStatus(status, loanUUID, validPaymentMethods);
    }
  }

  render() {
    const {
      getLoanRepaymentDetailsCall: { requesting },
      status,
      loanUUID,
      validPaymentMethods,
      scheduledPaymentDate,
      match: { path },
    } = this.props;
    const hasInstallmentPlan =
      status === RecoveryCollectionStatus.NEEDS_INSTALLMENT_PLAN_ACCEPTANCE ||
      status === RecoveryCollectionStatus.INSTALLMENT_PLAN_ACCEPTED;
    const rootRecoveryRoute = `${RootRoutes.RECOVERY_REPAYMENT}?id=${loanUUID}`;

    let logoRoute;

    // if on a success screen, clicking on logo will direct user to root page
    // otherwise will direct user to root recovery page
    if (status === RecoveryCollectionStatus.NEEDS_INSTALLMENT_PLAN_ACCEPTANCE) {
      logoRoute = rootRecoveryRoute;
    } else if (
      status === RecoveryCollectionStatus.INSTALLMENT_PLAN_ACCEPTED ||
      status !== RecoveryCollectionStatus.UNPAID
    ) {
      logoRoute = RootRoutes.ROOT;
    } else {
      logoRoute = rootRecoveryRoute;
    }

    return (
      <Container className="min-height-100vh d-flex flex-column" fluid>
        <LogoNoMenuNav route={logoRoute} alignLogoRight small />
        {requesting && <LargeLoadingSpinner color={primary} />}
        {status && (
          <Switch>
            <Route
              exact
              path={RootRoutes.RECOVERY_REPAYMENT}
              render={(props) => (
                <RepayNowOrLater
                  {...props}
                  status={status}
                  navigateRepayNow={this.navigateRepayNow}
                  navigateRepayLater={this.navigateRepayLater}
                />
              )}
            />
            <Route
              path={`${path}/${RecoveryRoutes.SCHEDULED}`}
              render={() => (
                <RecoveryPaymentScheduled scheduledPaymentDate={scheduledPaymentDate} />
              )}
            />
            <Route
              path={`${path}/${RecoveryRoutes.PROCESSING}`}
              render={() => <RecoveryPaymentProcessing />}
            />
            <Route
              path={`${path}/${RecoveryRoutes.REPAID}`}
              render={() => <RecoveryAdvanceRepaid />}
            />
            <Route
              path={`${path}/${RecoveryRoutes.PAYMENT_OPTIONS}`}
              render={(props) => <PaymentOptions {...props} />}
            />
            <Route
              path={`${path}/${RecoveryRoutes.DEBIT}`}
              render={() => {
                if (validPaymentMethods.includes(RecoveryPaymentSource.CARD)) {
                  return <DebitRecoveryContainer />;
                } else {
                  // show root recovery screen if card is not a valid option
                  return <Redirect to={rootRecoveryRoute} />;
                }
              }}
            />
            <Route
              path={`${path}/${RecoveryRoutes.BANK}`}
              render={() => {
                if (validPaymentMethods.includes(RecoveryPaymentSource.ACH)) {
                  return <BankRecoveryContainer />;
                } else {
                  // show root recovery screen if bank ach is not a valid option
                  return <Redirect to={rootRecoveryRoute} />;
                }
              }}
            />
            <Route
              path={`${path}/${RecoveryRoutes.SET_UP_INSTALLMENT_PLAN}`}
              render={(props) => {
                // check if user has installment plan
                if (hasInstallmentPlan) {
                  return <InstallmentsContainer {...props} />;
                } else {
                  // if user does not have installment plan
                  return <Redirect to={rootRecoveryRoute} />;
                }
              }}
            />
            <Redirect to={rootRecoveryRoute} />
          </Switch>
        )}
        <ApiErrorModal
          apiAction={GetLoanRepaymentDetailsApi}
          onRequestClose={this.redirectHome}
          onSubmit={this.redirectHome}
        />
      </Container>
    );
  }

  private handleNavigationForStatus = (
    status: RecoveryCollectionStatus,
    loanUUID: string,
    validPaymentMethods: RecoveryPaymentSource[],
    payNow?: string,
  ) => {
    const {
      history,
      match: { url },
      location: { pathname },
    } = this.props;

    const reviewInstallmentsPath = `${url}/${RecoveryRoutes.SET_UP_INSTALLMENT_PLAN}/${InstallmentRoutes.REVIEW_DETAILS}`;
    const successInstallmentsPath = `${url}/${RecoveryRoutes.SET_UP_INSTALLMENT_PLAN}/${InstallmentRoutes.PAYMENTS_SCHEDULED}`;

    switch (status) {
      case RecoveryCollectionStatus.REPAID:
        history.push(`${url}/${RecoveryRoutes.REPAID}?id=${loanUUID}`);
        break;
      case RecoveryCollectionStatus.SCHEDULED:
        history.push(`${url}/${RecoveryRoutes.SCHEDULED}?id=${loanUUID}`);
        break;
      case RecoveryCollectionStatus.PROCESSING:
        history.push(`${url}/${RecoveryRoutes.PROCESSING}?id=${loanUUID}`);
        break;
      case RecoveryCollectionStatus.INSTALLMENT_PLAN_ACCEPTED:
        if (pathname !== reviewInstallmentsPath && pathname !== successInstallmentsPath) {
          history.push(`${reviewInstallmentsPath}?id=${loanUUID}`);
        }
        break;
      default:
        if (payNow === 'true') {
          this.navigateRepayNow(validPaymentMethods, loanUUID);
          break;
        }

        if (payNow === 'false') {
          this.navigateRepayLater(validPaymentMethods, loanUUID);
          break;
        }

        break;
    }
  };

  private redirectHome = () => {
    this.props.history.push(RootRoutes.ROOT);
  };

  private getQueryParams = (): RecoveryParams => {
    const {
      location: { search },
    } = this.props;
    const values = queryString.parse(search);
    const loanUUID = values.id as string;
    const payNow = values.payNow as string;
    const source = values.source as string;
    return { loanUUID, payNow, source };
  };

  private navigateRepayNow = (validPaymentMethods: RecoveryPaymentSource[], loanUUID: string) => {
    const {
      history,
      match: { url },
    } = this.props;
    const locationState: RecoveryLocationState = { payNow: true };
    let pathname;
    if (validPaymentMethods.includes(RecoveryPaymentSource.CARD)) {
      pathname = `${url}/${RecoveryRoutes.DEBIT}`;
    } else {
      // if cannnot pay by card, show valid payment options
      pathname = `${url}/${RecoveryRoutes.PAYMENT_OPTIONS}`;
    }

    history.push({
      pathname,
      search: `?id=${loanUUID}`,
      state: locationState,
    });
  };

  private navigateRepayLater = (validPaymentMethods: RecoveryPaymentSource[], loanUUID: string) => {
    const {
      history,
      match: { url },
    } = this.props;
    const locationState: RecoveryLocationState = { payNow: false };
    let pathname;
    if (validPaymentMethods.includes(RecoveryPaymentSource.ACH)) {
      pathname = `${url}/${RecoveryRoutes.BANK}`;
    } else {
      // if cannot pay with ach, show valid payment options
      pathname = `${url}/${RecoveryRoutes.PAYMENT_OPTIONS}`;
    }

    history.push({
      pathname,
      search: `?id=${loanUUID}`,
      state: locationState,
    });
  };
}

export const RecoveryContainer = connector(RecoveryContainerBase);
