import 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';
import { StoreEnhancer } from 'redoodle'; // using the type from redoodle instead of redux as a hack
import { refreshRemoteConfig } from '../utils/firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyCCmfro2jEx77NY1_hMxkBj_RijSHFjuqw',
  authDomain: 'brigit-182417.firebaseapp.com',
  databaseURL: 'https://brigit-182417.firebaseio.com',
  projectId: 'brigit-182417',
  storageBucket: 'brigit-182417.appspot.com',
  messagingSenderId: '834088644407',
  appId: '1:834088644407:web:026b4e95fdef8e5e40b4b2',
  measurementId: 'G-75DWPJ13YP',
};

const FirebaseApp = initializeApp(firebaseConfig);
const FirebaseRemoteConfig = getRemoteConfig(FirebaseApp);

// To change throttle time, change to the desired duration in seconds;
const FETCH_INTERVAL_SECONDS = 3600;
const FETCH_TIMEOUT_SECONDS = 60;

FirebaseRemoteConfig.settings = {
  minimumFetchIntervalMillis: FETCH_INTERVAL_SECONDS * 1000,
  fetchTimeoutMillis: FETCH_TIMEOUT_SECONDS * 1000,
};

export const appStateEnhancer: StoreEnhancer = (createStore) => (reducer, preloadedState) => {
  const store = createStore(reducer, preloadedState);
  const { dispatch } = store;
  refreshRemoteConfig(FirebaseRemoteConfig, dispatch);
  return store;
};
