import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HamburgerNav } from './HamburgerNav';
import { NavBar } from './NavBar';
import { SideNav } from './SideNav';

interface State {
  menuOpen: boolean;
}

export class NavWrapper extends Component<Record<string, unknown>, State> {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  public render() {
    const { children } = this.props;
    const { menuOpen } = this.state;

    return (
      <>
        <div className="fixed-nav">
          <NavBar isOpen={menuOpen} toggle={this.toggleMenu} />
          <HamburgerNav isOpen={this.state.menuOpen} toggle={this.toggleMenu} />
        </div>
        <div className="fixed-nav-padding" />
        <Container className="container-with-nav d-flex flex-column" fluid>
          <Row className="flex-fill">
            <SideNav />
            <Col>{children}</Col>
          </Row>
        </Container>
      </>
    );
  }

  private toggleMenu = () => this.setState((state) => ({ menuOpen: !state.menuOpen }));
}
