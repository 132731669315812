import { AcceptInstallmentPlanApi, ConnectorProps } from '@hellobrigit/brigit-common';
import { RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import { FadeIn } from 'animate-components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { InternalUrls } from '../../../constants/InternalUrls';
import { AppState } from '../../../store';
import { DebitRecoveryRoutes } from '../../../utils/routes';
import { BrigitButton } from '../../buttons/BrigitButton';
import { ListHeader } from '../../ListHeader';
import { ButtonText, P2 } from '../../Typography';
import { RecoveryErrorAlert } from '../RecoveryErrorAlert';
import { RepaymentHeader } from '../RepaymentHeader';
import { InstallmentDetails } from './InstallmentDetails';

interface OwnProps {
  paymentMethod: RecoveryPaymentSource;
  acceptInstallmentPlan: () => void;
  errors: string[];
  paymentFrom: string;
}

const mapStateToProps = (state: AppState) => {
  const { api } = state;

  return {
    apiCall: api.get(AcceptInstallmentPlanApi.id),
  };
};

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectorProps<typeof connector>;

const ConfirmInstallmentDetailsBase: FunctionComponent<Props> = (props) => {
  const { apiCall, paymentMethod, errors, acceptInstallmentPlan, paymentFrom } = props;

  return (
    <>
      <RepaymentHeader
        paymentMethod={paymentMethod}
        payNow={false}
        activeRoute={DebitRecoveryRoutes.CONFIRM_PAYMENT}
      />
      <Row className="text-lg-center">
        <ListHeader style={{ width: '100%' }}>Confirm details</ListHeader>
      </Row>
      {errors.length > 0 && <RecoveryErrorAlert errors={errors} />}
      <FadeIn duration="1s">
        <div className="d-flex flex-column align-items-center py-2">
          <div style={{ width: '100%', maxWidth: '500px' }}>
            <InstallmentDetails paymentFrom={paymentFrom} />

            <P2
              className="font-grey mb-0"
              style={{
                paddingLeft: '15px',
                paddingRight: '15px',
                paddingTop: '10px',
              }}
            >
              By clicking pay now, you agree to our{' '}
              <a
                href={InternalUrls.TERMS_OF_SERVICE}
                target="_blank"
                rel="noopener noreferrer"
                className="bold font-grey"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href={InternalUrls.PRIVACY_POLICY}
                target="_blank"
                rel="noopener noreferrer"
                className="bold font-grey"
              >
                Privacy Policy
              </a>
              .
            </P2>
          </div>

          <BrigitButton
            eventStage="acceptInstallmentPlan"
            onClick={acceptInstallmentPlan}
            apiCall={apiCall}
            fullWidthOnMobile
            style={{ marginTop: 30 }}
          >
            <ButtonText className="font-white">Confirm and Schedule Payments</ButtonText>
          </BrigitButton>
        </div>
      </FadeIn>
    </>
  );
};

export const ConfirmInstallmentDetails = connector(ConfirmInstallmentDetailsBase);
