import { AuthCodeApi, ConnectorProps } from '@hellobrigit/brigit-common';
import { AuthCodeRequestV4_1 } from '@hellobrigit/brigit-rest-api';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { SetStepAction } from '../../actions/nextStepActions';
import { OnboardingStep } from '../../constants/OnboardingSteps';
import { AppState } from '../../store';
import { AuthCodeForm } from '../AuthCodeForm';

const mapStateToProps = (state: AppState) => {
  const {
    onboarding: { email },
  } = state;
  return {
    apiCall: state.api.get(AuthCodeApi.id),
    email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authEmail: AuthCodeApi.bindDispatch(dispatch),
    ...bindActionCreators({ setStep: SetStepAction }, dispatch),
  };
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mergeProps = (stateProps: StoreProps, dispatchProps: DispatchProps) => {
  const { authEmail } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    onSubmit: (form: AuthCodeRequestV4_1) => {
      return authEmail({ ...form });
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

type Props = InjectedFormProps & ConnectorProps<typeof connector>;

const AuthEmail: FunctionComponent<Props> = (props) => {
  const { email, setStep } = props;
  return (
    <AuthCodeForm
      authFormType="email"
      authFormValue={email}
      authCodeFieldName="emailVerificationCode"
      handleClickTryAgain={() => setStep({ step: OnboardingStep.EMAIL_NUMBER })}
      eventStage="authEmail"
      {...props}
    />
  );
};

export const AuthEmailForm = connector(
  reduxForm({
    form: 'confirmEmail',
  })(AuthEmail),
);
