import {
  ConnectorProps,
  FetchIdentityAndInitiateSVBDebitApi,
  GetLoanRepaymentDetailsApi,
  GetRecoveryPlaidLinkConfigApi,
  InsertPlaidItemForRecoveryApi,
} from '@hellobrigit/brigit-common';
import { CheckingAccountResponse, FrontendPlaidLinkConfig } from '@hellobrigit/brigit-rest-api';
import * as Sentry from '@sentry/browser';
import { Moment } from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { SetRecoveryRepaymentDetailsAction } from '../actions/recoveryActions';
import { OnEventMetadata, PlaidLinkService } from '../api/PlaidLinkService';
import { ApiErrorModal } from '../components/ApiErrorModal';
import { BankPaymentAccount } from '../components/recovery/bank/BankPaymentAccount';
import { ConfirmBankPayment } from '../components/recovery/bank/ConfirmBankPayment';
import { ScheduleBankPayment } from '../components/recovery/bank/ScheduleBankPayment';
import { SelectRecoveryAccount } from '../components/recovery/bank/SelectRecoveryAccount';
import { AppState } from '../store';
import { BankRecoveryRoutes, RootRoutes } from '../utils/routes';
import { RecoveryLocationState } from './types';

interface State {
  accounts: CheckingAccountResponse[];
  selectedAccount: CheckingAccountResponse;
  errors: string[];
  selectedPaymentDate: Moment;
}

const mapStateToProps = (state: AppState) => {
  const { api, config, recoveryDetails } = state;
  const { loanUUID } = { ...recoveryDetails };

  return {
    config,
    loanUUID,
    initiateSVBDebitCall: api.get(FetchIdentityAndInitiateSVBDebitApi.id),
    createPlaidItemCall: api.get(InsertPlaidItemForRecoveryApi.id),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createPlaidItemAndFetchAccounts: InsertPlaidItemForRecoveryApi.bindDispatch(dispatch),
  initiateSVBDebit: FetchIdentityAndInitiateSVBDebitApi.bindDispatch(dispatch),
  getLoanRepaymentDetails: GetLoanRepaymentDetailsApi.bindDispatch(dispatch),
  getRecoveryPlaidLinkConfig: GetRecoveryPlaidLinkConfigApi.bindDispatch(dispatch),
  ...bindActionCreators({ setRepaymentDetails: SetRecoveryRepaymentDetailsAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector> &
  RouteComponentProps<Record<string, string>, Record<string, unknown>, RecoveryLocationState>;

class BankRecoveryContainerBase extends Component<Props, State> {
  static onEvent = (_, metadata: OnEventMetadata) => {
    // eslint-disable-next-line no-undef
    analytics.track('Plaid Link Event', metadata);
  };

  static onExit = (_, metadata: object) => {
    // eslint-disable-next-line no-undef
    analytics.track('Plaid Link Exit', metadata);
  };

  constructor(props) {
    super(props);

    this.state = {
      accounts: null,
      selectedAccount: null,
      errors: [],
      selectedPaymentDate: null,
    };
  }

  public componentDidMount() {
    const {
      location: { state },
      history,
      loanUUID,
      getRecoveryPlaidLinkConfig,
    } = this.props;
    const { payNow } = { ...state };

    if (!loanUUID) {
      history.push(RootRoutes.ROOT);
    } else if (payNow) {
      // if paying now, go to bank payment page
      getRecoveryPlaidLinkConfig(loanUUID).then(() => {
        this.navigate(BankRecoveryRoutes.PAYMENT);
      });
    } else if (payNow === false) {
      // if not paying now, go to bank schedule payment page
      getRecoveryPlaidLinkConfig(loanUUID).then(() => {
        this.navigate(BankRecoveryRoutes.SCHEDULE_PAYMENT);
      });
    } else {
      // if pay now is undefined, go back to repay now/later page
      // so user can make a selection
      const rootRecoveryRoute = `${RootRoutes.RECOVERY_REPAYMENT}?id=${loanUUID}`;
      history.push(rootRecoveryRoute);
    }
  }

  render() {
    const { accounts, errors, selectedAccount, selectedPaymentDate } = this.state;
    const {
      match: { path },
      location: { state },
      loanUUID,
    } = this.props;
    const rootRecoveryRoute = `${RootRoutes.RECOVERY_REPAYMENT}?id=${loanUUID}`;
    const { payNow } = { ...state };

    return (
      <>
        <Switch>
          <Route
            path={`${path}/${BankRecoveryRoutes.SCHEDULE_PAYMENT}`}
            render={() => (
              <ScheduleBankPayment
                selectedPaymentDate={selectedPaymentDate}
                payNow={payNow}
                setSelectedPaymentDate={this.setSelectedPaymentDate}
                navigateToBankPaymentAccount={this.navigateToBankPaymentAccount}
                errors={errors}
              />
            )}
          />
          <Route
            path={`${path}/${BankRecoveryRoutes.PAYMENT}`}
            render={(props) => (
              <BankPaymentAccount
                {...props}
                selectedAccount={selectedAccount}
                openPlaidLink={this.openPlaidLink}
                payNow={payNow}
                navigateToConfirmPayment={this.navigateToConfirmPayment}
                errors={errors}
                loanUUID={loanUUID}
              />
            )}
          />
          <Route
            path={`${path}/${BankRecoveryRoutes.SELECT_ACCOUNT}`}
            render={() => (
              <SelectRecoveryAccount
                accounts={accounts}
                onSubmit={this.onSelectAccountSubmit}
                payNow={payNow}
                errors={errors}
              />
            )}
          />
          <Route
            path={`${path}/${BankRecoveryRoutes.CONFIRM_PAYMENT}`}
            render={() => (
              <ConfirmBankPayment
                selectedAccount={selectedAccount}
                selectedPaymentDate={selectedPaymentDate}
                initiateSVBDebit={this.initiatePayment}
                payNow={payNow}
                errors={errors}
              />
            )}
          />
          <Redirect to={loanUUID ? rootRecoveryRoute : RootRoutes.ROOT} />
        </Switch>
        <ApiErrorModal
          apiAction={InsertPlaidItemForRecoveryApi}
          onRequestClose={this.redirectToRoot}
          onSubmit={this.redirectToRoot}
        />
      </>
    );
  }

  private redirectToRoot = () => {
    const { history, loanUUID } = this.props;
    if (loanUUID) {
      history.push(`${RootRoutes.RECOVERY_REPAYMENT}?id=${loanUUID}`);
    } else {
      history.push(RootRoutes.ROOT);
    }
  };

  private setError = (error: string) =>
    this.setState((prevState) => ({ errors: [...prevState.errors, error] }));

  private clearErrors = () => this.setState({ errors: [] });

  private openPlaidLink = () => {
    // clear errors if new attempt to open Plaid link
    this.clearErrors();
    const {
      config: { plaid },
    } = this.props;

    // Get PlaidLinkService's authentication params
    try {
      PlaidLinkService.open({
        token: (plaid as FrontendPlaidLinkConfig).linkToken,
        onSuccess: this.onSuccess,
        onExit: BankRecoveryContainerBase.onExit,
        onEvent: BankRecoveryContainerBase.onEvent,
      });
    } catch (err) {
      const message = 'Uh oh! Something went wrong';
      this.setError(message);
      Sentry.captureException(err);
    }
  };

  private onSuccess = (plaidPublicToken: string) => {
    // eslint-disable-next-line no-undef
    analytics.track('Plaid Token Handover');
    const { loanUUID, createPlaidItemAndFetchAccounts } = this.props;
    createPlaidItemAndFetchAccounts(loanUUID, { plaidPublicToken }).then(({ data }) => {
      this.setState({ accounts: data }, this.navigateToSelectAccount);
    });
  };

  private onSelectAccountSubmit = (selectedAccountIdx: number) => {
    const selectedAccount = this.state.accounts[selectedAccountIdx];

    if (selectedAccount) {
      this.setState({ selectedAccount }, this.navigateToBankPaymentAccount);
    }
  };

  private navigateToBankPaymentAccount = () => this.navigate(BankRecoveryRoutes.PAYMENT);

  private navigateToSelectAccount = () => this.navigate(BankRecoveryRoutes.SELECT_ACCOUNT);

  private navigateToConfirmPayment = () => this.navigate(BankRecoveryRoutes.CONFIRM_PAYMENT);

  private initiatePayment = (repaymentDate: string) => {
    // clear errors if new attempt to initiate payment
    this.clearErrors();
    const { selectedAccount } = this.state;
    const { initiateSVBDebit, loanUUID, getLoanRepaymentDetails, setRepaymentDetails } = this.props;

    const body = {
      loanUUID,
      plaidAccountId: selectedAccount.accountId,
      repaymentDate,
    };

    initiateSVBDebit(body)
      .then(() => getLoanRepaymentDetails(loanUUID))
      .then(({ data }) => {
        // get repayment details after initiating payment to refresh status and
        // trigger componentDidUpdate in recovery container that handles
        // navigation to success screen
        setRepaymentDetails({ ...data, loanUUID });
      })
      .catch((error) => {
        const data = error.response?.data;
        const { message = 'We were unable to process your payment.' } = {
          ...data,
        };
        this.setError(message);
      });
  };

  private setSelectedPaymentDate = (selectedPaymentDate: Moment) =>
    this.setState({ selectedPaymentDate });

  private navigate = (route: BankRecoveryRoutes) => {
    const {
      history,
      loanUUID,
      match: { url },
      location: { state },
    } = this.props;

    history.push({
      pathname: `${url}/${route}`,
      search: `?id=${loanUUID}`,
      state,
    });
  };
}

export const BankRecoveryContainer = withRouter(connector(BankRecoveryContainerBase));
