import { TypedReducer } from 'redoodle';
import {
  SetInstallmentPlanDetailsAction,
  SetRecoveryRepaymentDetailsAction,
} from '../actions/recoveryActions';
import { RecoveryDetailsState } from '../store/types';

const builder = TypedReducer.builder<RecoveryDetailsState>();

builder.withHandler(SetRecoveryRepaymentDetailsAction.TYPE, (state, payload) => {
  return { ...state, ...payload };
});

builder.withHandler(SetInstallmentPlanDetailsAction.TYPE, (state, payload) => {
  return { ...state, installmentPlanDetails: payload };
});

export default builder.build();
