import React, { FunctionComponent } from 'react';
import { Container } from 'reactstrap';
import { LogoNoMenuNav } from '../navigation/LogoNoMenuNav';
import { ScreenTemplate } from '../ScreenTemplate';
import { H1, P } from '../Typography';

// This component is only navigated to when the token property from UserState is undefined, so a custom reducer declared in src/reducers/index.ts explicity sets the token to undefined for the DeleteAccountApi.SUCCESS.TYPE Redux Action type.
export const DeleteSuccess: FunctionComponent = () => {
  return (
    <>
      <div className="fixed-nav">
        <LogoNoMenuNav logoOnClickToExternalUrl />
      </div>
      <div className="fixed-nav-padding" />
      <Container fluid style={{ paddingTop: '60px' }}>
        <ScreenTemplate svgImage="delete/trash-can.svg">
          <H1 className="mb-4">Your account has been deleted</H1>
          <P>
            If you need further assistance, or to sign up again, visit{' '}
            <span className="bold">hellobrigit.com</span> or email us at{' '}
            <span className="bold">info@hellobrigit.com</span>
          </P>
        </ScreenTemplate>
      </Container>
    </>
  );
};
