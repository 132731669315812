import { FadeIn } from 'animate-components';
import React, { FunctionComponent } from 'react';
import { Row } from 'reactstrap';
import { MOBILE_ADVANCES_TAB_DEEP_LINK } from '../../../constants/general';
import { InstallmentRoutes } from '../../../utils/routes';
import { ListHeader } from '../../ListHeader';
import { P } from '../../Typography';
import { RepaymentHeader } from '../RepaymentHeader';
import { InstallmentDetails } from './InstallmentDetails';

export const ViewInstallmentDetails: FunctionComponent = () => (
  <>
    <RepaymentHeader
      paymentMethod={null}
      payNow={false}
      activeRoute={InstallmentRoutes.REVIEW_DETAILS}
    />
    <Row className="text-lg-center">
      <ListHeader style={{ width: '100%' }}>Details</ListHeader>
    </Row>
    <FadeIn duration="1s">
      <div className="d-flex flex-column align-items-center py-2">
        <div style={{ width: '100%', maxWidth: '500px' }}>
          {/* TODO: will need to change paymentFrom logic once we support ACH payments */}
          <InstallmentDetails paymentFrom="Debit card" />
          <P className="bold text-center mb-0" style={{ marginTop: 20 }}>
            Head to the{' '}
            <a className="font-dark-green" href={MOBILE_ADVANCES_TAB_DEEP_LINK}>
              mobile
            </a>{' '}
            app to view your advance status details.
          </P>
        </div>
      </div>
    </FadeIn>
  </>
);
