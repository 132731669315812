import '../../styles/hamburger-menu.css';
import { FadeIn } from 'animate-components';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import { AppLinks } from '../../constants/AppLinks';
import { InternalUrls } from '../../constants/InternalUrls';
import { RootRoutes } from '../../utils/routes';
import { NavIconRow } from '../home/NavIconRow';
import { P } from '../Typography';

interface HamburgerNavProps {
  small?: boolean;
  isOpen: boolean;
  toggle: () => void;
}

export const HamburgerNav: FunctionComponent<HamburgerNavProps> = (props) => {
  const { isOpen, toggle, small } = props;

  const modalClassName = classNames({
    'menu-content': true,
    'menu-small-top-position': small,
  });

  return (
    <div className="d-sm-block d-md-block d-lg-none">
      <Modal
        isOpen={isOpen}
        onRequestClose={toggle}
        className={modalClassName}
        overlayClassName="menu-overlay"
      >
        <FadeIn duration="1s">
          <div className="mt-5 pb-2 d-flex flex-column" style={{ alignItems: 'flex-end' }}>
            <NavIconRow
              id="nav-settings"
              navPath={RootRoutes.SETTINGS}
              iconPath="/nav/settings.svg"
              imgStyle={{ padding: '4px' }}
              text="Settings"
            />
            <NavIconRow
              navPath={RootRoutes.HOW_IT_WORKS}
              iconPath="/nav/faq.svg"
              text="How Brigit Works"
            />
            <NavIconRow
              externalLink
              navPath={InternalUrls.BRIGIT_FAQ}
              iconPath="/nav/how_it_works.svg"
              text="FAQ"
            />
            <NavIconRow
              externalLink
              navPath="mailto:info@hellobrigit.com"
              iconPath="/nav/contact_us_nav.svg"
              text="Contact Us"
            />
            <NavIconRow
              externalLink={false}
              iconPath="/nav/empty_nav_icon.svg"
              navPath={RootRoutes.LOGOUT}
              text="Log Out"
              color="text-danger"
            />
            <P className="font-black bold mb-0">Download the app</P>
            <div className="pt-2 d-flex flex-column">
              <a target="_blank" rel="noopener noreferrer" href={AppLinks.GOOGLE_PLAY}>
                <img style={{ width: '126px' }} src="/google_play.svg" />
              </a>
              <a target="_blank" rel="noopener noreferrer" href={AppLinks.APP_STORE}>
                <img className="pt-3" style={{ width: '126px' }} src="/app_store.svg" />
              </a>
            </div>
          </div>
        </FadeIn>
      </Modal>
    </div>
  );
};
