import {
  AcceptInstallmentPlanApi,
  ConnectorProps,
  GetLatestInstallmentPlanApi,
  GetLoanRepaymentDetailsApi,
  RegisterTabapayCardRecoveryApiV2,
} from '@hellobrigit/brigit-common';
import { PaymentMethodId, RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import {
  SetInstallmentPlanDetailsAction,
  SetRecoveryRepaymentDetailsAction,
} from '../actions/recoveryActions';
import {
  BILLING_DETAILS_FORM,
  BillingDetails,
  BillingDetailsFormData,
} from '../components/recovery/debit/BillingDetails';
import { CardDetails } from '../components/recovery/debit/CardDetails';
import { ConfirmInstallmentDetails } from '../components/recovery/installments/ConfirmInstallmentDetails';
import { AppState } from '../store';
import { determineCardNetwork } from '../utils/cardNetwork';
import {
  DebitRecoveryRoutes,
  InstallmentRoutes,
  RecoveryRoutes,
  RootRoutes,
} from '../utils/routes';

interface State {
  tabapayCardDigits: string;
  tabapayToken: string;
  errors: string[];
  paymentMethodId: PaymentMethodId;
  paymentFrom: string;
}

const mapStateToProps = (state: AppState) => {
  const { recoveryDetails } = state;
  const { loanUUID, installmentPlanDetails } = { ...recoveryDetails };

  return {
    loanUUID,
    installmentPlanDetails,
    formValues: getFormValues(BILLING_DETAILS_FORM)(state) as BillingDetailsFormData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLatestInstallmentPlan: GetLatestInstallmentPlanApi.bindDispatch(dispatch),
  registerTabapayRecoveryCard: RegisterTabapayCardRecoveryApiV2.bindDispatch(dispatch),
  acceptInstallmentPlan: AcceptInstallmentPlanApi.bindDispatch(dispatch),
  getLoanRepaymentDetails: GetLoanRepaymentDetailsApi.bindDispatch(dispatch),
  ...bindActionCreators(
    {
      setInstallmentPlanDetails: SetInstallmentPlanDetailsAction,
      setRepaymentDetails: SetRecoveryRepaymentDetailsAction,
    },
    dispatch,
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector> & RouteComponentProps;

class InstallmentsDebitContainerBase extends Component<Props, State> {
  static addCardPrefix = (cardNumber: string) => {
    const cardPrefix = determineCardNetwork(cardNumber) || 'Card ending in';
    return `${cardPrefix} ${cardNumber.slice(-4)}`;
  };

  constructor(props) {
    super(props);

    this.state = {
      tabapayCardDigits: null,
      tabapayToken: null,
      errors: [],
      paymentMethodId: null,
      paymentFrom: null,
    };
  }

  render() {
    const { errors, paymentFrom } = this.state;
    const {
      match: { path },
      loanUUID,
    } = this.props;
    const rootInstallmentsRoute = `${RootRoutes.RECOVERY_REPAYMENT}/${RecoveryRoutes.SET_UP_INSTALLMENT_PLAN}?id=${loanUUID}`;

    return (
      <Switch>
        <Route
          path={`${path}/${DebitRecoveryRoutes.CARD_DETAILS}`}
          render={(props) => (
            <CardDetails
              {...props}
              payNow={false}
              completeTabapayCardDetails={this.completeTabapayCardDetails}
              setError={this.setError}
              clearErrors={this.clearErrors}
              errors={errors}
              loanUUID={loanUUID}
              hasInstallmentPlan
            />
          )}
        />
        <Route
          path={`${path}/${DebitRecoveryRoutes.BILLING_DETAILS}`}
          render={() => (
            <BillingDetails
              payNow={false}
              registerTabapayCard={this.registerTabapayCard}
              errors={errors}
            />
          )}
        />
        <Route
          path={`${path}/${DebitRecoveryRoutes.CONFIRM_PAYMENT}`}
          render={() => (
            <ConfirmInstallmentDetails
              paymentMethod={RecoveryPaymentSource.CARD}
              acceptInstallmentPlan={this.acceptInstallmentPlan}
              errors={errors}
              paymentFrom={paymentFrom}
            />
          )}
        />
        <Redirect to={loanUUID ? rootInstallmentsRoute : RootRoutes.ROOT} />
      </Switch>
    );
  }

  private acceptInstallmentPlan = () => {
    const { paymentMethodId } = this.state;
    const {
      acceptInstallmentPlan,
      getLatestInstallmentPlan,
      setInstallmentPlanDetails,
      getLoanRepaymentDetails,
      setRepaymentDetails,
      history,
      loanUUID,
      installmentPlanDetails,
    } = this.props;
    acceptInstallmentPlan(loanUUID, paymentMethodId as string, installmentPlanDetails?.id)
      .then(() => {
        const successUrl = `${RootRoutes.RECOVERY_REPAYMENT}/${RecoveryRoutes.SET_UP_INSTALLMENT_PLAN}/${InstallmentRoutes.PAYMENTS_SCHEDULED}?id=${loanUUID}`;
        history.push(successUrl);
        return getLatestInstallmentPlan(loanUUID);
      })
      .then(({ data }) => {
        setInstallmentPlanDetails(data);
        return getLoanRepaymentDetails(loanUUID);
      })
      .then(({ data }) => setRepaymentDetails({ ...data, loanUUID }))
      .catch((error) => {
        const data = error.response?.data;
        const {
          message = 'We were unable to process your payment and confirm your installment plan',
        } = {
          ...data,
        };
        this.setError(message);
      });
  };

  private completeTabapayCardDetails = (tabapayCardDigits: string, tabapayToken: string) => {
    this.setState({ tabapayCardDigits, tabapayToken }, () =>
      this.navigate(DebitRecoveryRoutes.BILLING_DETAILS),
    );
  };

  private navigateToConfirmPayment = () => this.navigate(DebitRecoveryRoutes.CONFIRM_PAYMENT);

  private setError = (error: string) =>
    this.setState((prevState) => ({ errors: [...prevState.errors, error] }));

  private clearErrors = () => this.setState({ errors: [] });

  private registerTabapayCard = () => {
    // clear errors for new attempt to register card
    this.clearErrors();
    const { tabapayCardDigits, tabapayToken } = this.state;
    const { loanUUID, formValues, registerTabapayRecoveryCard } = this.props;

    const hasApartment = formValues.lineTwo;

    const userInfo = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      lineOne: formValues.lineOne,
      ...(hasApartment && { lineTwo: formValues.lineTwo }),
      city: formValues.city,
      state: formValues.state,
      zipcode: formValues.zipCode,
    };

    const registerTabapayRecoveryCardRequest = {
      token: tabapayToken,
      userInfo,
      lastFour: tabapayCardDigits.slice(-4),
    };

    registerTabapayRecoveryCard(loanUUID, registerTabapayRecoveryCardRequest)
      .then(({ data }) => {
        const { paymentMethodId } = data;
        this.setState(
          {
            paymentMethodId,
            paymentFrom: InstallmentsDebitContainerBase.addCardPrefix(tabapayCardDigits),
          },
          this.navigateToConfirmPayment,
        );
      })
      .catch((error) => {
        const data = error.response?.data;
        const { message = 'We were unable to confirm some of your personal details.' } = {
          ...data,
        };
        this.setError(message);
      });
  };

  private navigate = (route: DebitRecoveryRoutes) => {
    const {
      history,
      loanUUID,
      match: { url },
      location: { state },
    } = this.props;

    history.push({
      pathname: `${url}/${route}`,
      search: `?id=${loanUUID}`,
      state,
    });
  };
}

export const InstallmentsDebitContainer = withRouter(connector(InstallmentsDebitContainerBase));
