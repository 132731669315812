import {
  ConnectorProps,
  GetBankUpdateToken,
  GetPlaidLinkConfigApi,
  RegisterBankLoginApi,
} from '@hellobrigit/brigit-common';
import {
  BankProvider,
  FrontendPaymentAuthFlow,
  FrontendPlaidLinkConfig,
} from '@hellobrigit/brigit-rest-api';
import { FadeIn } from 'animate-components';
import React from 'react';
import LockClosedOutline from 'react-ionicons/lib/LockClosedOutline';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Col, Row } from 'reactstrap';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { OnEventMetadata, PlaidLinkService } from '../../api/PlaidLinkService';
import { FrontendBankInstitution } from '../../graphql/zeus';
import { AppState } from '../../store';
import { semiDark } from '../../utils/colors';
import { Checkbox } from '../Checkbox';
import { H1, P, P1, P2, P4 } from '../Typography';

interface LinkBankForm {
  accountAgeCheckOpen: boolean;
  incomeDepositCheckOpen: boolean;
  incomeAmountCheckOpen: boolean;
}

interface State {
  isInvalidUpdatedUsername: boolean;
  isTransitionedToCredential: boolean;
}

const mapStateToProps = (state: AppState) => {
  const {
    api,
    config,
    user: {
      details: {
        bankV2,
        flags: { isBankTimeout },
      },
    },
  } = state;

  const requesting =
    api.get(RegisterBankLoginApi.id).requesting ||
    api.get(GetPlaidLinkConfigApi.id).requesting ||
    api.get(GetBankUpdateToken.id).requesting;

  const isBankCapitalOne = bankV2?.bankInstitution === FrontendBankInstitution.CAPITAL_ONE;

  return {
    requesting,
    isBankTimeout,
    bankDetails: bankV2,
    config,
    isBankCapitalOne,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerBankLogin: ({ publicToken, isUpdate, paymentAuthFlow }) =>
      RegisterBankLoginApi.bindDispatch(dispatch)({
        publicToken,
        provider: BankProvider.PLAID,
        isUpdate,
        paymentAuthFlow,
      }),
    getPlaidLinkConfig: GetPlaidLinkConfigApi.bindDispatch(dispatch),
    getBankUpdateToken: GetBankUpdateToken.bindDispatch(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = InjectedFormProps<LinkBankForm> & ConnectorProps<typeof connector>;

class LinkBankClass extends React.Component<Props, State> {
  static onExit = (error, metadata) => {
    // eslint-disable-next-line no-undef
    analytics.track('Plaid Link Exit', metadata);
  };

  constructor(props) {
    super(props);
    const { initialize } = props;

    this.state = {
      isInvalidUpdatedUsername: false,
      isTransitionedToCredential: false,
    };

    initialize({
      accountAgeCheckOpen: false,
      incomeDepositCheckOpen: false,
      incomeAmountCheckOpen: false,
    });
  }

  public componentDidMount() {
    const { isBankTimeout, getPlaidLinkConfig, getBankUpdateToken, isBankCapitalOne } = this.props;
    getPlaidLinkConfig();
    if (isBankTimeout && !isBankCapitalOne) {
      getBankUpdateToken();
    }
  }

  public determinePlaidStyling(requesting) {
    const { invalid: preChecksNotSelected } = this.props;

    if (requesting) {
      return 'btn no-button-border';
    } else if (preChecksNotSelected) {
      return 'btn onboarding-submit bg-dark-green nohover-dg disabled';
    } else if (!preChecksNotSelected) {
      return 'btn onboarding-submit nohover-dg bg-dark-green';
    }

    return null;
  }

  public render() {
    const { requesting, isBankTimeout, invalid: preChecksNotSelected } = this.props;
    const plaidClass = this.determinePlaidStyling(requesting);

    return (
      <FadeIn duration="1s">
        <Row className="justify-content-center title-margin-top brigit-row">
          <Col lg="6" md="6" sm="12">
            <div className="text-center">
              <H1>{isBankTimeout ? 'Relogin to bank' : 'Connect your bank'}</H1>
              {isBankTimeout ? (
                <P className="text-center mb-lg-5 mb-3">
                  It looks like your bank logged us out. Reconnect your account to get back to what
                  you were doing. Please confirm:
                </P>
              ) : (
                <P className="text-center mb-3 mb-lg-5">
                  To get financial tools and qualify for money,{' '}
                  <span className="bold">please confirm</span>:
                </P>
              )}
              <div className="d-flex justify-content-center mb-5">
                <div className="text-left">
                  <Field name="accountAgeCheckOpen" component={Checkbox} type="checkbox">
                    <P1 className="mb-0 font-dark-grey">My account is at least 2 months old</P1>
                  </Field>
                  <Field name="incomeDepositCheckOpen" component={Checkbox} type="checkbox">
                    <P1 className="mb-0 font-dark-grey">I deposit money into this account</P1>
                  </Field>

                  <Field name="incomeAmountCheckOpen" component={Checkbox} type="checkbox">
                    <P1 className="mb-0 font-dark-grey">I deposit more than $1,500/month</P1>
                  </Field>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center text-left mb-5">
                <span>
                  <LockClosedOutline style={{ fontSize: 20 }} />
                </span>
                <P2 className="pl-2 font-grey mb-0">
                  We secure your information with the same 256-bit <br />
                  encryption that banks use.
                </P2>
              </div>

              <div className="d-flex justify-content-center align-items-center text-left mb-3">
                <P4 className="pl-2 font-grey mb-0">
                  By linking your bank, you are not committing to a payment.
                </P4>
              </div>

              <div
                className={plaidClass}
                onClick={!preChecksNotSelected ? this.openPlaidLink : undefined}
              >
                {requesting ? <ClipLoader color={semiDark} /> : 'Connect bank'}
              </div>
            </div>
          </Col>
        </Row>
      </FadeIn>
    );
  }

  /**
   * Workaround for open issue on `react-plaid-link` for not updating/re-rendering when
   * new props are passed in (i.e. plaid updateToken)
   */
  private openPlaidLink = () => {
    const { config, isBankTimeout, bankDetails } = this.props;
    const { plaid } = config;

    const updateBank = isBankTimeout && !this.isRetryingFromInvalidUserName();
    const token = updateBank
      ? bankDetails?.timeout?.updateToken
      : (plaid as FrontendPlaidLinkConfig).linkToken;

    PlaidLinkService.open({
      token,
      onEvent: this.onEvent,
      onSuccess: this.onSuccess,
      onExit: LinkBankClass.onExit,
    });
  };

  private onSuccess = (plaidPublicToken) => {
    const { requesting, isBankTimeout, registerBankLogin } = this.props;

    if (!requesting) {
      // Defensive check against plaid accidentally calling this twice
      // eslint-disable-next-line no-undef
      analytics.track('Plaid Token Handover');

      // If user encountered an INVALID_UPDATED_USERNAME, push them through the switch endpoint
      const isSwitchingBank = this.isRetryingFromInvalidUserName();

      registerBankLogin({
        publicToken: plaidPublicToken,
        isUpdate: !isSwitchingBank && isBankTimeout,
        // TODO: Handle setting paymentAuthFlow to INSTANT_MATCH or INSTANT_AUTH based on whether
        // user hits numbers view.
        paymentAuthFlow: FrontendPaymentAuthFlow.UNKNOWN,
      });
    }
  };

  private isRetryingFromInvalidUserName() {
    const { isInvalidUpdatedUsername, isTransitionedToCredential } = this.state;
    return isInvalidUpdatedUsername && isTransitionedToCredential;
  }

  private onEvent = (_, metadata: OnEventMetadata) => {
    const { event_name, error_code, view_name } = metadata;
    if (error_code === 'INVALID_UPDATED_USERNAME') {
      this.setState({ isInvalidUpdatedUsername: true });
    }
    if (
      event_name === 'TRANSITION_VIEW' &&
      view_name === 'CREDENTIAL' &&
      this.state.isInvalidUpdatedUsername
    ) {
      this.setState({ isTransitionedToCredential: true });
    }
    // eslint-disable-next-line no-undef
    analytics.track('Plaid Link Event', metadata);
  };
}

const validate = (values: LinkBankForm) => {
  const { accountAgeCheckOpen, incomeDepositCheckOpen, incomeAmountCheckOpen } = values;
  const errors: {
    accountAgeCheckOpen?: string;
    incomeDepositCheckOpen?: string;
    incomeAmountCheckOpen?: string;
  } = {};

  if (!accountAgeCheckOpen) {
    errors.accountAgeCheckOpen = 'Account must be at least 2 months old';
  }

  if (!incomeDepositCheckOpen) {
    errors.incomeDepositCheckOpen = 'Account must receive deposits';
  }

  if (!incomeAmountCheckOpen) {
    errors.incomeAmountCheckOpen = 'Must deposit more than $1500/month';
  }

  return errors;
};

export const LinkBank = connector(
  reduxForm<LinkBankForm>({
    form: 'linkBankForm',
    validate,
  })(LinkBankClass),
);
