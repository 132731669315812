import {
  AuthCodeApi,
  AuthPinApi,
  DeleteAccountApi,
  DisableProtectionApi,
  GetUserDetailsApi,
  LoginUserApi,
  LogoutUserActionApi,
  RegisterBankLoginApi,
  RegisterUserApi,
  SetNameApi,
  SetPinApi,
} from '@hellobrigit/brigit-common';
import { Middleware } from 'redux';
import { SetStepAction } from '../actions/nextStepActions';
import { OnboardingStep } from '../constants/OnboardingSteps';
import { setAxiosAuthHeader } from '../utils/setAxiosAuthHeader';

export const webApiCallMiddleware: Middleware = (store) => (next) => (action) => {
  if (RegisterUserApi.SUCCESS.is(action)) {
    setAxiosAuthHeader(action.payload.token);
    // Set next step
    store.dispatch(SetStepAction.create({ step: action.payload.nextStep }));
  }

  if (LoginUserApi.SUCCESS.is(action)) {
    setAxiosAuthHeader(action.payload.token);
    store.dispatch(SetStepAction.create({ step: action.payload.nextStep }));
  }

  if (AuthCodeApi.SUCCESS.is(action) || AuthPinApi.SUCCESS.is(action)) {
    setAxiosAuthHeader(action.payload.token);
  }

  // After registered and authed
  if (AuthPinApi.SUCCESS.is(action) && !action.payload.nextStep) {
    store.dispatch(SetStepAction.create({ step: OnboardingStep.HOME }));
  }

  if (
    (AuthPinApi.SUCCESS.is(action) && action.payload.nextStep) ||
    AuthCodeApi.SUCCESS.is(action) ||
    SetPinApi.SUCCESS.is(action) ||
    SetNameApi.SUCCESS.is(action)
  ) {
    store.dispatch(SetStepAction.create({ step: action.payload.nextStep }));
  }

  if (RegisterBankLoginApi.SUCCESS.is(action)) {
    store.dispatch(SetStepAction.create({ step: action.payload.nextStep }));
  }

  if (DisableProtectionApi.SUCCESS.is(action)) {
    GetUserDetailsApi.bindDispatch(store.dispatch)();
  }

  if (GetUserDetailsApi.SUCCESS.is(action) && action.payload.nextStep) {
    store.dispatch(SetStepAction.create({ step: action.payload.nextStep }));
  }

  if (LogoutUserActionApi.SUCCESS.is(action) || DeleteAccountApi.SUCCESS.is(action)) {
    setAxiosAuthHeader(null);
  }

  return next(action);
};
