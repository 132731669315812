import { ConnectorProps } from '@hellobrigit/brigit-common';
import { RecoveryCollectionStatus, RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import numeral from 'numeral';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AppState } from '../../store';
import { light } from '../../utils/colors';
import { RecoveryRoutes } from '../../utils/routes';
import { BrigitButton } from '../buttons/BrigitButton';
import { ButtonText, H4, P, P1 } from '../Typography';

const PAY_NOW_IMG_SRC = '/recovery/phone-register-pay-now.png';
const LOCK_ICON_IMG_SRC = '/icons/security-lock.svg';

interface OwnProps {
  status: RecoveryCollectionStatus;
  navigateRepayNow: (validPaymentMethods: RecoveryPaymentSource[], loanUUID: string) => void;
  navigateRepayLater: (validPaymentMethods: RecoveryPaymentSource[], loanUUID: string) => void;
}

const mapStateToProps = (state: AppState) => {
  const { recoveryDetails } = state;
  const { dueAmount, loanUUID, validPaymentMethods } = { ...recoveryDetails };
  return { dueAmount, loanUUID, validPaymentMethods };
};

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectorProps<typeof connector> & RouteComponentProps;

const RepayNowOrLaterBase: FunctionComponent<Props> = (props) => {
  const {
    navigateRepayNow,
    navigateRepayLater,
    dueAmount,
    loanUUID,
    history,
    match,
    validPaymentMethods,
    status,
  } = props;

  const handleNavigateRepayNow = () => navigateRepayNow(validPaymentMethods, loanUUID);

  const handleNavigateRepayLater = () => navigateRepayLater(validPaymentMethods, loanUUID);

  const navigateInstallmentPLan = () =>
    history.push(`${match.url}/${RecoveryRoutes.SET_UP_INSTALLMENT_PLAN}?id=${loanUUID}`);

  return (
    <div className="d-flex flex-column align-items-center text-center">
      <img src={PAY_NOW_IMG_SRC} height={130} style={{ marginTop: 50, marginBottom: 30 }} />
      <P className="font-black">Hey there</P>
      <H4 className="font-black" style={{ marginBottom: 35 }}>
        Ready to pay your {numeral(dueAmount).format('$0,0')} advance now?
      </H4>
      <BrigitButton
        onClick={handleNavigateRepayNow}
        fullWidthOnMobile
        style={{ marginBottom: 20 }}
        eventStage="recoveryRepayNow"
      >
        <ButtonText
          id="btn-pay-now"
          className="font-white"
          style={{ paddingTop: '2px', paddingBottom: '2px' }}
        >
          Yes, pay now
        </ButtonText>
      </BrigitButton>
      <BrigitButton
        onClick={handleNavigateRepayLater}
        inverted
        fullWidthOnMobile
        style={{ marginBottom: 15 }}
      >
        <ButtonText className="font-dark-green" style={{ paddingTop: '2px', paddingBottom: '2px' }}>
          No, schedule a payment
        </ButtonText>
      </BrigitButton>

      {status === RecoveryCollectionStatus.NEEDS_INSTALLMENT_PLAN_ACCEPTANCE && (
        <BrigitButton
          onClick={navigateInstallmentPLan}
          minimal
          eventStage="recoveryContinueWithInstallmentPlan"
        >
          <ButtonText className="font-dark-green" style={{ color: light }}>
            Continue with my repayment plan
          </ButtonText>
        </BrigitButton>
      )}

      <div
        className="d-flex text-left align-items-center"
        style={{ marginTop: 25, marginBottom: 10 }}
      >
        <img src={LOCK_ICON_IMG_SRC} height={20} style={{ marginRight: 14 }} />
        <P1 className="font-grey mb-0">
          We secure your information with the same 256-bit encryption that banks use.
        </P1>
      </div>
    </div>
  );
};

export const RepayNowOrLater = connector(RepayNowOrLaterBase);
