import { CardNetwork } from '../constants/CardNetwork';

export const determineCardNetwork = (s: string) => {
  if (s.length > 3 && s.startsWith('4')) {
    return CardNetwork.VISA;
  } else if (s.length > 3 && s.startsWith('5')) {
    return CardNetwork.MASTERCARD;
  }
  return null;
};
