import { TypedReducer } from 'redoodle';
import { SetAttributionAction } from '../actions/AttributionActions';
import { Attribution } from '../store/types';

const builder = TypedReducer.builder<Attribution>();

builder.withHandler(SetAttributionAction.TYPE, (state, payload) => {
  return { ...state, ...payload };
});

export default builder.build();
