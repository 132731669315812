import { TypedAction } from 'redoodle';
import { UsaState } from '../store/types';

interface CreditBuilderGlobalConfigurationLoadedPayload {
  cbUnsupportedStates: UsaState[];
  cbUnsupportedStatesNamesString: string;
}

export const LoadCreditBuilderGlobalConfigurationAction = TypedAction.define(
  '@brigit/credit_builder_configuration/load_credit_builder_global_configuration',
)<void>();

export const SetCreditBuilderGlobalConfigurationLoadedAction = TypedAction.define(
  '@brigit/credit_builder_configuration/set_credit_builder_global_configuration_loaded',
)<CreditBuilderGlobalConfigurationLoadedPayload>();
