import { ProtectionMediumType } from '@hellobrigit/brigit-rest-api';
import { TypedAction } from 'redoodle';
import { ChangeSubscriptionTierOutcome, SubscriptionTierLevel } from '../graphql/zeus';
import { SubscriptionTier, SubscriptionType } from '../store/types';

interface LoadSubscriptionTiersPayload {
  userId: string;
}

interface SubscriptionTiersLoadedPayload {
  currentSubscriptionTier: SubscriptionTier;
  availableSubscriptionTiers: SubscriptionTier[];
  subscriptionType: SubscriptionType | undefined;
}

interface ChangeSubscriptionTierPayload {
  previousTierLevel?: SubscriptionTierLevel;
  subscriptionTierLevel: SubscriptionTierLevel;
  mediumType?: ProtectionMediumType;
  loanAmount?: number;
}

interface UpdateCurrentSubscriptionTierPayload {
  currentSubscriptionTier: SubscriptionTier;
  changeSubscriptionTierOutcome: ChangeSubscriptionTierOutcome;
}

interface GetSubscriptionsScheduleTextPayload {
  subscriptionTierLevel: SubscriptionTierLevel;
}

interface SetSubscriptionScheduleTextPayload {
  subscriptionScheduleText: string | undefined;
}

interface SetGraphQLErrorPayload {
  message: string;
  userFacingMessage: string;
}

interface SetSubscriptionProposedBillDatePayload {
  proposedBillDate: string | undefined;
}

export const SetLoadSubscriptionTiersLoadingAction = TypedAction.define(
  '@brigit/subscriptions/set_load_subscription_tiers_loading',
)<boolean>();

export const LoadSubscriptionTiersAction = TypedAction.define(
  '@brigit/subscriptions/load_subscription_tiers',
)<LoadSubscriptionTiersPayload>();

export const SetSubscriptionTiersLoadedAction = TypedAction.define(
  '@brigit/subscriptions/set_subscription_tiers_loaded',
)<SubscriptionTiersLoadedPayload>();

export const UpdateCurrentSubscriptionTierAction = TypedAction.define(
  '@brigit/subscriptions/update_current_tier',
)<UpdateCurrentSubscriptionTierPayload>();

export const ChangeSubscriptionTierAction = TypedAction.define(
  '@brigit/subscriptions/change_subscription_tiers',
)<ChangeSubscriptionTierPayload>();

export const SetChangeSubscriptionTierLoadingAction = TypedAction.define(
  '@brigit/subscriptions/set_change_subscription_tier_loading',
)<void>();

export const SetChangeSubscriptionTierErrorAction = TypedAction.define(
  '@brigit/graphql/set_graphql_error',
)<SetGraphQLErrorPayload>();

export const ClearChangeSubscriptionTierErrorAction = TypedAction.define(
  '@brigit/graphql/clear_graphql_error',
)<void>();

export const GetSubscriptionsScheduleTextAction = TypedAction.define(
  '@brigit/subscriptions/get_subscription_schedule_text',
)<GetSubscriptionsScheduleTextPayload>();

export const SetSubscriptionsScheduleTextAction = TypedAction.define(
  '@brigit/subscriptions/set_subscription_schedule_text',
)<SetSubscriptionScheduleTextPayload>();

export const ClearChangeSubscriptionTierOutcomeAction = TypedAction.define(
  '@brigit/subscriptions/clear_change_subscription_tier_outcome',
)<void>();

export const GetSubscriptionsProposedBillDateAction = TypedAction.define(
  '@brigit/subscriptions/get_subscription_proposed_bill_date',
)<void>();

export const SetSubscriptionsProposedBillDateAction = TypedAction.define(
  '@brigit/subscriptions/set_subscription_proposed_bill_date',
)<SetSubscriptionProposedBillDatePayload>();
