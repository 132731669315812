/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';


export const HOST="Specify host"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json() as Promise<GraphQLResponse>;
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const);
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v;
      return a;
    }, {});
    return objectFromEntries;
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return undefined;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return undefined;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
    // Open issue in GH, not really being addressed: https://github.com/graphql-editor/graphql-zeus/issues/325
    return undefined;
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
    // Open issue in GH, not really being addressed: https://github.com/graphql-editor/graphql-zeus/issues/325
    return undefined;
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : never,
) => fn as (args?: any, source?: any) => ReturnType<typeof fn>;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : never, SCLR>
        : never;
    }[keyof SRC] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	BigDecimal?: ScalarResolver;
	Date?: ScalarResolver;
	Long?: ScalarResolver;
	UUID?: ScalarResolver;
}
type ZEUS_UNIONS = GraphQLTypes["LoginUnion"] | GraphQLTypes["RegisterOneTimeCardUnion"]

export type ValueTypes = {
    ["LoginUnion"]: AliasType<{		["...on LoginFailure"] : ValueTypes["LoginFailure"],
		["...on LoginSuccess"] : ValueTypes["LoginSuccess"]
		__typename?: boolean | `@${string}`
}>;
	["RegisterOneTimeCardUnion"]: AliasType<{		["...on RegisterOneTimeCardInvalidInput"] : ValueTypes["RegisterOneTimeCardInvalidInput"],
		["...on RegisterOneTimeCardSuccess"] : ValueTypes["RegisterOneTimeCardSuccess"]
		__typename?: boolean | `@${string}`
}>;
	["AccountConnectedResponseFetcher"]: AliasType<{
	/** The provider ID of the connected payroll account */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ActiveBankLogins"]: AliasType<{
	/** List of active logins for user */
	logins?:ValueTypes["FrontendBankLogin"],
		__typename?: boolean | `@${string}`
}>;
	["ApprovedLoanAmounts"]: AliasType<{
	/** Approved loan amounts for user - used for frontend display purposes */
	approvedLoanAmounts?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details about the user's auto advance configuration */
["AutoAdvanceDetails"]: AliasType<{
	/** The amount of the user's auto advance */
	amount?:boolean | `@${string}`,
	/** The estimated delivery date if an advance was taken today */
	deliveryDate?:boolean | `@${string}`,
	/** The estimated due date if an advance was taken today */
	dueDate?:boolean | `@${string}`,
	/** Whether the user has auto advances enabled for disbursal when the user's balance is low */
	isAutoEnabled?:boolean | `@${string}`,
	/** Whether the user is due to receive an auto advance */
	willAutoDisburse?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AverageScoreChangeDetailsFetcher"]: AliasType<{
	/** An Optional<Integer> representing the average score change for this score bucket and sample period. */
	averageScoreChange?:boolean | `@${string}`,
	/** The ending date the average was computed. */
	samplePeriodEnd?:boolean | `@${string}`,
	/** The starting date the average was computed. */
	samplePeriodStart?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Predictions based on the user's linked bank account such as their income sources and likelihood to overdraft. */
["BankAccountPrediction"]: AliasType<{
	/** List of the user's income sources based on their linked bank account. */
	incomeSources?:ValueTypes["Income_32_Source"],
	/** The user's next predicted payday based on their linked bank account. */
	nextPayday?:boolean | `@${string}`,
	/** The likelihood the user's balance will drop to 0 soon. */
	overdraftLikelihood?:boolean | `@${string}`,
	/** The date we predict the user's balance to drop to 0. */
	zeroDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BankConnection"]: AliasType<{
	/** The protected bank account mask */
	accountMask?:boolean | `@${string}`,
	/** The protected bank account's available balance */
	balanceAvailable?:boolean | `@${string}`,
	/** The protected bank account's current balance */
	balanceCurrent?:boolean | `@${string}`,
	/** Bank login ID */
	bankLoginId?:boolean | `@${string}`,
	/** Provider associated with bank connection (Plaid or Capital One) */
	bankProvider?:boolean | `@${string}`,
	/** Checking accounts that belong to the user protected login */
	checkingAccounts?:ValueTypes["FrontendAccountWithCard"],
	/** The bank institution details for the user's current bank connection. */
	institution?:ValueTypes["BankInstitution"],
	/** The last time we call the bank data provider API */
	lastUpdatedTimestamp?:boolean | `@${string}`,
	/** The protected bank account's payment auth flow */
	paymentAuthFlow?:boolean | `@${string}`,
	/** The protected bank account ID */
	protectedAccountId?:boolean | `@${string}`,
	/** Bank connection status. */
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BankInstitution"]: AliasType<{
	/** The protected bank account's institution id. Only populated for Plaid users. */
	id?:boolean | `@${string}`,
	/** The protected bank account's institution name */
	institutionName?:boolean | `@${string}`,
	/** The institution logo URL */
	logoUrl?:boolean | `@${string}`,
	/** The bank institution type */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChangeSubscriptionTierResponse"]: AliasType<{
	/** The result of the attempt to change the user's subscription tier. */
	outcome?:boolean | `@${string}`,
	/** The user's subscription after the request is completed. If the outcome is not SUCCESS, the subscription will be the same as it was previously. */
	updatedSubscription?:ValueTypes["Subscription"],
		__typename?: boolean | `@${string}`
}>;
	["CompleteQuizFetcher"]: AliasType<{
	/** The id of the quiz */
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreatePayrollAccountResponse"]: AliasType<{
	/** The provider ID of the payroll account created */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreatePayrollAccountResponseV2"]: AliasType<{
	/** The outcome of creating the user's payroll account. */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateTargetDepositDestinationResponse"]: AliasType<{
	/** Encrypted version of the user's target deposit destination */
	encryptedDDSConfig?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details on which type of enrollment is applicable to the user and any reasons they are ineligible for that enrollment type. */
["CreditBuilderEnrollmentEligibility"]: AliasType<{
	/** Whether the user is eligible for the type of enrollment. If this is false, the ineligibilityDetails will have the reasons they are ineligible. */
	eligible?:boolean | `@${string}`,
	/** The type of enrollment that the user's eligibility was checked for. */
	enrollmentType?:boolean | `@${string}`,
	/** The details on why the user is ineligible for the type of enrollment. */
	ineligibilityDetails?:ValueTypes["CreditBuilderEnrollmentIneligibilityDetails"],
		__typename?: boolean | `@${string}`
}>;
	/** Information on a reason that a user is ineligible for a given enrollment type. */
["CreditBuilderEnrollmentIneligibilityDetails"]: AliasType<{
	/** A textual description of the reason that can be displayed to the user. */
	description?:boolean | `@${string}`,
	/** The reason the user is ineligible. */
	reason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Contains global configuration data for the Credit Builder system. */
["CreditBuilderGlobalConfiguration"]: AliasType<{
	/** The USA states in which Credit Builder is not available. */
	unsupportedStates?:ValueTypes["UsaState"],
		__typename?: boolean | `@${string}`
}>;
	/** A credit builder installment loan is a secured loan which the user has taken out to build their credit. The loan contains monthly payments which are reported to the bureaus for credit score calculation. */
["CreditBuilderInstallmentLoan"]: AliasType<{
	/** User's external contributions minus disbursals */
	availableSavingsBalance?:boolean | `@${string}`,
	/** The charge policy configured for the credit builder loan. This could either be AUTO, meaning standard payments are automatically deducted from the user's external account or MANUAL, meaning the user would need to initiate standard payments themselves. */
	chargePolicy?:boolean | `@${string}`,
	/** The date which the Credit Builder loan was closed on. If the loan was never closed, this should be empty. */
	closedOn?:boolean | `@${string}`,
	/** The disbursals taken against the loans savings balance. */
	disbursals?:ValueTypes["CreditBuilderTransaction"],
	/** The date which the Credit Builder loan is expected to close if all payments are completed successfully. */
	expectedClosedDate?:boolean | `@${string}`,
	/** The numeric value for the day of the month the first installment payment is due. */
	expectedDayOfMonthForBilling?:boolean | `@${string}`,
	/** The amount user has set to debit their external bank account every month to pay off the installment in dollars. */
	externalMonthlyContributionAmountInDollars?:boolean | `@${string}`,
	/** A List of the credit builder installment loan payment schedule dates and the amount due */
	installmentLoanPaymentSchedule?:ValueTypes["InstallmentLoanPaymentSchedule"],
	/** The amount user has set to debit from the Brigit restricted savings account every month to pay off the installment in dollars. */
	internalMonthlyContributionAmountInDollars?:boolean | `@${string}`,
	/** The date which the Credit Builder loan was opened. */
	openedOn?:boolean | `@${string}`,
	/** Details about past due payments, if applicable. */
	pastDuePayment?:ValueTypes["PastDuePayment"],
	/** The payment schedule strategy stored in the cb enrollments table for the credit builder loan. If the payment strategy is empty then it calculates the strategy on the fly and it could either be MONTHLY, meaning a user had no income and they selected a specific day of the month to make CB payments or INCOME, where a CB payment due date corresponds with a users payments */
	paymentStrategyForSchedule?:boolean | `@${string}`,
	/** The payments that have been made towards the loan. */
	payments?:ValueTypes["CreditBuilderTransaction"],
	/** Details about possible pre-payments user can make toward their CB account. */
	prePayment?:ValueTypes["PrePayment"],
proposedDraftInstallmentLoanPaymentSchedule?: [{	reason: ValueTypes["FrontendCBScheduleVersionReason"] | Variable<any, string>},ValueTypes["InstallmentLoanPaymentSchedule"]],
	/** A list of reminders for the user's installment loan account. This list may include upcoming scheduled payment, past due balance,  and same day manual payment reminders. */
	reminders?:ValueTypes["CreditBuilderReminder"],
	/** Payment details that are populated when user has manual payment method and payment is due today */
	sameDatePayment?:ValueTypes["SameDayPayment"],
	/** Details regarding user's CB savings */
	savingsDetails?:ValueTypes["CreditBuilderSavingsDetails"],
	/** Details regarding user's credit score */
	scoreDetails?:ValueTypes["CreditBuilderScoreDetails"],
	/** The fixed number of months for the Credit Builder loan term. */
	termInMonths?:boolean | `@${string}`,
	/** The total loan amount for the given Credit Builder enrollment term. */
	totalLoanAmountInDollars?:boolean | `@${string}`,
	/** The total monthly amount between external and savings contributions that goes to paying off the installment in dollars. */
	totalMonthlyPaymentAmountInDollars?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A reminder associated with the user's open installment loan account. */
["CreditBuilderReminder"]: AliasType<{
	/** The description of the reminder to show the user. */
	description?:boolean | `@${string}`,
	/** The type of reminder */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details about user's credit score */
["CreditBuilderSavingsDetails"]: AliasType<{
	/** The user’s all time total savings across all CB accounts ever (how much the user contributed to all of their Credit Builder account(s), regardless of any subsequent disbursals */
	allTimeSavings?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details about user's credit score */
["CreditBuilderScoreDetails"]: AliasType<{
	/** If CB account is open, delta is the difference between first score since first ever CB enrollment and latest credit score on file. If CB account is closed, delta is the difference between first score since first ever CB enrollment and last score before a user closed their CB account. */
	allTimeScoreDelta?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A transaction that was processed for a Credit Builder installment loan. */
["CreditBuilderTransaction"]: AliasType<{
	/** The amount of the transaction in dollars. */
	amountInDollars?:boolean | `@${string}`,
	/** The due date of the payment */
	dueOn?:boolean | `@${string}`,
	/** The unique id of the transaction. */
	installmentLoanPaymentId?:boolean | `@${string}`,
	/** The source account type from which the transaction originated. */
	source?:boolean | `@${string}`,
	/** The status of the transaction at the current point in time. */
	status?:boolean | `@${string}`,
	/** The type of the transaction. Either a payment or a disbursal. */
	type?:boolean | `@${string}`,
	/** The time that the transaction was last updated. */
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** User details related to credit builder */
["CreditBuilderUserDetails"]: AliasType<{
	/** The user's Credit Builder population type to determine whether to show CB related content. */
	populationType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreditLimitBoostDetails"]: AliasType<{
	/** The amount of the credit limit boost in dollars. */
	boostAmount?:boolean | `@${string}`,
	/** Whether the boost is available to be used for a loan which means the boost status is equal to OFFERED or ACCEPTED. */
	boostAvailable?:boolean | `@${string}`,
	/** List of advance options that apply part or all of the credit limit boost. */
	boostedAdvanceOptions?:boolean | `@${string}`,
	/** User's max advance amount plus their credit limit boost. */
	boostedMaxLoanAmount?:boolean | `@${string}`,
	/** Unique ID of the credit limit boost */
	creditLimitBoostId?:boolean | `@${string}`,
	/** Current status of the credit limit boost */
	creditLimitBoostStatus?:boolean | `@${string}`,
	/** Whether the boost should be offered to the user which means the boost status equals OFFERED. */
	shouldOfferBoost?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A credit monitoring enrollment is a user's relationship with Brigit's Credit Monitoring product, which maintains a history of their credit scores while they are enrolled. */
["CreditMonitoringEnrollment"]: AliasType<{
	/** The credit scores that Brigit has collected for the user while they have been enrolled in credit monitoring. Scores are sorted by date, starting with the earliest scores. */
	creditScores?:ValueTypes["CreditScore"],
	/** Describes whether a user is currently, or has been, enrolled in credit monitoring with Brigit. */
	enrollmentStatus?:boolean | `@${string}`,
	/** Representation of the user's recent credit score delta */
	recentScoreChange?:ValueTypes["RecentScoreChangeFetcher"],
	/** Representation of how the user's credit score journey has progressed since starting credit builder relative to their peer group. */
	scoreMarker?:ValueTypes["CreditScoreMarkerFetcher"],
		__typename?: boolean | `@${string}`
}>;
	/** A credit monitoring enrollment is a user's relationship with Brigit's Credit Monitoring product, which maintains a history of their credit scores while they are enrolled. */
["CreditMonitoringUser"]: AliasType<{
	authToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A credit score is a numerical rating representing the perceived creditwothiness of an individual, as evaluated by one of the major US credit bureaus on a particular date. */
["CreditScore"]: AliasType<{
	/** The date that this score was calculated, in yyyy-MM-dd format. */
	date?:boolean | `@${string}`,
	/** The numerical value of the credit score, ranging from about 250 for a poor score to about 900 for a great score. */
	score?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreditScoreMarkerFetcher"]: AliasType<{
	/** The score bucket that the user falls into (e.g. 300-349, 350-399, etc). */
	averageScoreChangeDetails?:ValueTypes["AverageScoreChangeDetailsFetcher"],
	/** A number representing the days since latest CB enrollment. */
	days?:boolean | `@${string}`,
	/** A number representing the period the user is in (e.g. 1, 2, 3, ..., 24). */
	period?:boolean | `@${string}`,
	/** The score bucket that the user falls into (e.g. 300-349, 350-399, etc). */
	scoreBucket?:ValueTypes["ScoreBucketFetcher"],
	/** The value of the users score change since opening their CB account. */
	userScoreChange?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeleteBankLogin"]: AliasType<{
	/** An Enum denoting if the outcome of deleting the bank login was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeletePayrollAccountResponse"]: AliasType<{
	/** The ID of the payroll account being deleted */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DisableDirectDepositsResponse"]: AliasType<{
	/** The outcome of disabling the user's direct deposit. */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DisablePayrollAccountResponse"]: AliasType<{
	/** The provider ID of the payroll account being disabled */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A discount is a temporary reduction in the price of a subscription. */
["Discount"]: AliasType<{
	/** If present, contains additional text to show users to explain the discount, such as the date range for which it applies, how it compares to the typical price, or any other information the user should know. */
	disclaimer?:boolean | `@${string}`,
	/** The price, in US dollars, that will be charged for subscription payments that this discount applies to. */
	discountedAmount?:boolean | `@${string}`,
	/** The estimated dates of upcoming subscription payments that this discount will be applied to. */
	discountedPaymentDates?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Fetcher for Dynamic Promo Card offers */
["DynamicPromoCards"]: AliasType<{
	/** Affiliate link URL for the offer */
	affiliateLinkUrl?:boolean | `@${string}`,
	/** List of background colors for the offer */
	backgroundColors?:boolean | `@${string}`,
	/** Copy color for the offer */
	copyColor?:boolean | `@${string}`,
	/** Call-to-action copy for the offer */
	ctaCopy?:boolean | `@${string}`,
	/** Description of the offer */
	description?:boolean | `@${string}`,
	/** URL for the offer icon */
	iconUrl?:boolean | `@${string}`,
	/** Unique identifier for the offer */
	id?:boolean | `@${string}`,
	/** URL for the offer logo */
	logoUrl?:boolean | `@${string}`,
	/** Name of the offer */
	offerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FilteredSalaryData"]: AliasType<{
	/** The filter used to aggregate the salary data */
	filter?:boolean | `@${string}`,
	/** The average salary for users specific to this filter */
	salary?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Debit card information */
["FrontendAccountWithCard"]: AliasType<{
	/** The bank account ID associated with the card */
	bankAccountId?:boolean | `@${string}`,
	/** Associated debit card info */
	card?:ValueTypes["FrontendDebitCardInfo"],
	/** Account current balance */
	currentBalance?:boolean | `@${string}`,
	/** Whether or not account is eligible to be selected as the protected account */
	isEligibleProtectedAccount?:boolean | `@${string}`,
	/** Account joint status */
	isJoint?:boolean | `@${string}`,
	/** Account removal status */
	isRemoved?:boolean | `@${string}`,
	/** Account mask */
	mask?:boolean | `@${string}`,
	/** The name associated with the card */
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Bank account associated with bank login */
["FrontendBankAccount"]: AliasType<{
	/** Available balance */
	availableBalance?:boolean | `@${string}`,
	/** Current balance */
	currentBalance?:boolean | `@${string}`,
	/** Bank account ID */
	id?:boolean | `@${string}`,
	/** Is joint */
	isJoint?:boolean | `@${string}`,
	/** Boolean representing whether login has been identified as the user's recommended protected account. Refer to: https://brigit.atlassian.net/browse/ENG-13002 */
	isRecommendedProtectedAccount?:boolean | `@${string}`,
	/** Bank account mask */
	mask?:boolean | `@${string}`,
	/** Bank account name */
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FrontendBankLogin"]: AliasType<{
	/** Checking accounts associated with login */
	bankAccounts?:ValueTypes["FrontendBankAccount"],
	/** Time bank login was created */
	createdAt?:boolean | `@${string}`,
	/** Bank login ID */
	id?:boolean | `@${string}`,
	/** Institution details */
	institution?:ValueTypes["BankInstitution"],
	/** Institution name */
	institutionName?:boolean | `@${string}`,
	/** Boolean representing whether login has been identified as the user's recommended protected login. Refer to: https://brigit.atlassian.net/browse/ENG-13002 */
	isRecommendedProtectedLogin?:boolean | `@${string}`,
	/** Bank connection status. */
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FrontendDebitCardInfo"]: AliasType<{
	/** Card block status */
	blockStatus?:boolean | `@${string}`,
	/** Card's nick name and normally is it's last 4 */
	cardNickname?:boolean | `@${string}`,
	/** Card's expiration date */
	expirationDate?:boolean | `@${string}`,
	/** Card network such as Visa, Mastercard, etc */
	network?:boolean | `@${string}`,
	/** Payment method ID associated with the card */
	paymentMethodId?:boolean | `@${string}`,
	/** Reflects micro-deposit verification and status of government id. */
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FrontendExperimentVariantAssignment"]: AliasType<{
	/** Flag to indicate whether the variant returned is the control flow. If the variant is either the
'Control' or 'Holdout', this will return as true. */
	isControlFlow?:boolean | `@${string}`,
	/** Name of the variant assigned for the sample key. */
	variantName?:boolean | `@${string}`,
	/** Value associated with the variant assigned for the sample key. */
	variantValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents a missed item which consists of all payments due on the same scheduled due date that are outstanding */
["FrontendMissedItem"]: AliasType<{
	/** late fee in cents */
	lateFeeAmountInCents?:boolean | `@${string}`,
	/** original principal payment in cents */
	principalPaymentAmountInCents?:boolean | `@${string}`,
	/** Original scheduled date in iso format */
	scheduledDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents a late fee with the pairing of the fee amount and date it was accrued (the original principal payment date) */
["FrontendOverduePayment"]: AliasType<{
	/** Date accrued in iso format */
	dateAccrued?:boolean | `@${string}`,
	/** fee in cents */
	feeAmountInCents?:boolean | `@${string}`,
	/** original principal payment in cents */
	principalPaymentAmountInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents the pairing of the payment amount and date due for a user */
["FrontendPrincipalPayment"]: AliasType<{
	/** Monthly payment in cents */
	amountInCents?:boolean | `@${string}`,
	/** Payment date in iso format */
	paymentDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Report underwriting checks */
["FrontendUnderwritingCheck"]: AliasType<{
	/** Underwriting rule category */
	category?:boolean | `@${string}`,
	/** Whether user has fulfilled rule or not */
	isFulfilled?:boolean | `@${string}`,
	/** Underwriting description based on if rule is fulfilled or not */
	reason?:boolean | `@${string}`,
	/** Underwriting rule */
	rule?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Controls a customizable banner we may display to users on the home screen when we want to get out some message to users with a quick turnaround. */
["HomeScreenBanner"]: AliasType<{
	/** Cta to show with the banner */
	callToActionText?:boolean | `@${string}`,
	/** Link url that we will open when a user clicks on the banner */
	linkUrl?:boolean | `@${string}`,
	/** Main message associated with banner. */
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Frequently Asked Questions related to IC Activation. */
["ICActivationFAQs"]: AliasType<{
	/** Description of the frequently asked question. */
	description?:boolean | `@${string}`,
	/** Positional id returned for front end ordering purposes */
	id?:boolean | `@${string}`,
	/** Title of the Frequently Asked Question. */
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Experiments related to Instant Cash */
["ICExperimentProperties"]: AliasType<{
	/** Whether we should display the upsell screen or not based on the user's flag and tier history */
	shouldDisplayUpsell?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["IncomeProfile"]: AliasType<{
	/** Primary filter used to organize salary data */
	filterOne?:boolean | `@${string}`,
	/** Possible values for filter one */
	filterOneValues?:boolean | `@${string}`,
	/** Secondary filter used to organize salary data */
	filterTwo?:boolean | `@${string}`,
	/** Possible values for filter two */
	filterTwoValues?:boolean | `@${string}`,
	/** List of data points based on the user's income */
	salaryAggregators?:ValueTypes["SalaryAggregators"],
		__typename?: boolean | `@${string}`
}>;
	/** An income source contains the user's paycheck and payday details based on their linked bank account. */
["Income_32_Source"]: AliasType<{
	/** The average amount of money the user earns from this income source. */
	amountMean?:boolean | `@${string}`,
	/** For users who get paid twice a month, their payday may be pinned to specific dates such as the 1st and 15th or the 15th and 30th. Day of month 1 refers to the first date that's pinned for the user's payday. The day of month 1 will be 0 if the user doesn't have a paycheck that follows this pattern. */
	dayOfMonth1?:boolean | `@${string}`,
	/** For users who get paid twice a month, their payday may be pinned to specific dates such as the 1st and 15th or the 15th and 30th. Day of month 2 refers to the second date that's pinned for the user's payday. The day of month 2 will be 0 if the user doesn't have a paycheck that follows this pattern. */
	dayOfMonth2?:boolean | `@${string}`,
	/** Day of week is the specific day the user's payday is on e.g. Thursday. If there's no specific day for that user's payday, Monday will be used as the default. */
	dayOfWeek?:boolean | `@${string}`,
	/** Early payday offset is the number of days the user's income will be deposited into their account early. */
	earlyPayOffset?:boolean | `@${string}`,
	/** Is end of month refers to whether or not the user's payday will fall at the end of the month since not all months have the same number of days. */
	endOfMonth?:boolean | `@${string}`,
	/** The frequency refers to how often the user gets paid such as biweekly, weekly, monthly, etc. */
	frequency?:boolean | `@${string}`,
	/** This is true or false depending on if the user's payday is adjusted if it falls on a holiday. If so, then it's true; otherwise, false. */
	holidayAdjustNext?:boolean | `@${string}`,
	/** The id is the unique id for this specific user's income source. */
	id?:boolean | `@${string}`,
	/** The income payer ID is similar to a bank memo for the transaction. If there are similar bank memos, they will be fuzzy matched to produce a singular income payer ID. */
	incomePayerId?:boolean | `@${string}`,
	/** The period mean refers to the average number of days between paychecks for this income source. This value is typically set for irregular income sources. 0 is set as the default value if this income source doesn't have a period mean. */
	periodMean?:boolean | `@${string}`,
	/** The rejection status is the user-friendly description for why the income source could not be verified. If the income source can be verified, then the rejection status will be an empty string. */
	rejectionStatus?:boolean | `@${string}`,
	/** Status refers to the verification status of this income source e.g. VERIFIED, PENDING, UNVERIFIABLE, and BLACKLISTED. */
	status?:boolean | `@${string}`,
	/** Is verified is true or false depending on whether or not someone has verified this income source and confirmed it's legitimate. */
	verified?:boolean | `@${string}`,
	/** Week of month refers to the week that the user's payday is on. For example, 1 would refer to the 1st week of the month. The week of month would equal 0 if there's no specific week the user's payday falls on. */
	weekOfMonth?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitiateCatchUpPaymentForInstallmentLoan"]: AliasType<{
	/** An Enum denoting if the outcome of the catch up payment was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitiateOneTimePayment"]: AliasType<{
	/** An Enum denoting if the outcome of the oneTimePayment was successful or failure */
	oneTimePaymentOutcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitiatePayOffPaymentForInstallmentLoan"]: AliasType<{
	/** An Enum denoting if the outcome of the pay off payment was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitiatePrePaymentForInstallmentLoan"]: AliasType<{
	/** An Enum denoting if the outcome of the pre payment was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InputSalaryData"]: AliasType<{
	/** Outcome denoting if adding the salary data was successful or not */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Credit builder installment loan payment schedule due dates with amount due. */
["InstallmentLoanPaymentSchedule"]: AliasType<{
	/** The amount of the payment in dollars. */
	amountInDollars?:boolean | `@${string}`,
	/** Payment schedule due date */
	paymentDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents the post disbursal details for an installment loan */
["InstallmentLoanPostDisbursalInfo"]: AliasType<{
	/** Name of bank account installment loan was disbursed to */
	disbursalBankAccountName?:boolean | `@${string}`,
	/** Date loan was disbursed */
	disbursalDate?:boolean | `@${string}`,
	/** Status of loan disbursal */
	disbursalStatus?:boolean | `@${string}`,
	/** Estimated delivery for the principal payment credit if loan is not delivered */
	estimatedDeliveryDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details related to both a potential and an existing installment loans user */
["InstallmentLoans"]: AliasType<{
	/** Fetch if a user have an installment loan account or not.  */
	currentLoanStatus?:boolean | `@${string}`,
	/** Fetch current open installment loan details */
	currentOpenInstallmentLoan?:ValueTypes["InstallmentLoansDetails"],
	/** Fetches all installment loans including details for a user */
	details?:ValueTypes["details"],
	/** Fetches the KYC status for a prospective IL user */
	kycDecision?:boolean | `@${string}`,
signedLoanAgreement?: [{	locUuid: string | Variable<any, string>},boolean | `@${string}`],
	/** Evaluates and fetches the loan term details for a prospective IL account */
	termRequestDetails?:ValueTypes["TermRequestDetails"],
	/** Evaluates and fetches the underwriting status for a prospective IL user */
	underwritingDetails?:ValueTypes["LocUnderwritingDetails"],
	/** Fetches the string contents for the proposed loan agreement */
	unsignedProposedLoanAgreement?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents the details for a installment loan */
["InstallmentLoansDetails"]: AliasType<{
	/** Is auto pay enabled or disabled */
	autoPayStatus?:boolean | `@${string}`,
	/** Field that denotes if a user should be able to see the ability to make a and 'manual' payment on the FE */
	manualPaymentEligibilityStatus?:boolean | `@${string}`,
	/** List of missed items */
	missedItems?:ValueTypes["FrontendMissedItem"],
	/** Next scheduled principal payment if it exists */
	nextScheduledPrincipalPayment?:ValueTypes["FrontendPrincipalPayment"],
	/** Post disbursal info */
	postDisbursalInstallmentLoanInfo?:ValueTypes["InstallmentLoanPostDisbursalInfo"],
	/** Remaining due principal payments on the schedule that haven't been processed yet */
	remainingDuePayments?:ValueTypes["FrontendPrincipalPayment"],
	/** Fetches the string contents for the signed loan agreement */
	signedLoanAgreement?:boolean | `@${string}`,
	/** Status of the installment loan */
	status?:boolean | `@${string}`,
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalPaymentsInCents?:boolean | `@${string}`,
	/** Total paid amount so far in cents */
	totalPrincipalAmountInCents?:boolean | `@${string}`,
	/** Installment Loan Id */
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details related to both an existing or unopened line of credit for a user */
["LineOfCredit"]: AliasType<{
	/** Credit line limit details */
	creditLineLimitDetails?:ValueTypes["LineOfCreditLineLimitDetails"],
	/** Returns the user to KYC submission response and unfulfilled reasons if it is present */
	kycRequirementResponse?:ValueTypes["LocRequireKycResponse"],
	/** Checks if it is required for the user to create a KYC submission to progress in onboarding */
	kycRequirementStatus?:boolean | `@${string}`,
	/** The users currently open line of credit account. */
	openAccount?:ValueTypes["LineOfCreditAccount"],
paymentsBreakdown?: [{	creditDrawAmountInCents: ValueTypes["Long"] | Variable<any, string>},ValueTypes["LocPaymentBreakdown"]],
	/** Fetches the user facing product name */
	productName?:boolean | `@${string}`,
	/** Account details for a prospective user */
	prospectiveTermDetails?:ValueTypes["LineOfCreditProspectiveTerms"],
	/** Evaluates and fetches the underwriting status for a prospective LOC user */
	underwritingDetails?:ValueTypes["LocUnderwritingDetails"],
	/** Fetches the string contents for the proposed unsigned loan agreement */
	unsignedProposedUserAgreement?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A line of credit account that a user can draw money from. */
["LineOfCreditAccount"]: AliasType<{
	/** The current status of the account. */
	accountStatus?:boolean | `@${string}`,
	/** The unique UUID that identifies the account. */
	accountUuid?:boolean | `@${string}`,
	activeCreditDraw?:ValueTypes["LineOfCreditDrawFetcher"],
	/** The autopay status of the account. */
	autopayStatus?:boolean | `@${string}`,
	/** The credit draw details for this line of credit account */
	creditDrawDetails?:ValueTypes["LineOfCreditDrawRequestDetailsFetcher"],
	/** The total limit that can be drawn up to in cents. */
	creditLimitInCents?:boolean | `@${string}`,
	/** Fetches the upcoming payment details for the minimum payment. */
	minPaymentDetails?:ValueTypes["LineOfCreditMinPaymentDetailsFetcher"],
	/** The date the account was opened. */
	openedOn?:boolean | `@${string}`,
	/** Total balance owed for fees */
	outstandingAccessFeeBalanceInCents?:boolean | `@${string}`,
	/** Total outstanding principal balance in cents */
	outstandingPrincipalBalanceInCents?:boolean | `@${string}`,
	/** The payment charge type of the account. */
	paymentChargeType?:boolean | `@${string}`,
	/** Historical payments for given LOC account */
	paymentHistory?:ValueTypes["LineOfCreditPaymentHistory"],
	prevDraw?:ValueTypes["LineOfCreditDrawFetcher"],
	/** Contains the list of historical statements and the start + end period for the CURRENT billing cycle / statement period. */
	statementDetails?:ValueTypes["LineOfCreditStatementDetailsFetcher"],
	/** Total balance owed for principal and remainder owed for fees */
	totalBalanceRemainingIncludingFullFee?:boolean | `@${string}`,
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details of an LOC object for line of credit account */
["LineOfCreditDrawFetcher"]: AliasType<{
	/** Disbursal amount */
	disbursalAmountInCents?:boolean | `@${string}`,
	/** When credit draw was disbursed */
	disbursalDate?:boolean | `@${string}`,
	/** When disbursal amount is estimated to arrive to user. Returns empty optional if amount has already been delivered. */
	estimatedDeliveryDate?:boolean | `@${string}`,
	/** Payment status */
	paymentStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details on amount that can be or has been drawn from a line of credit */
["LineOfCreditDrawRequestDetailsFetcher"]: AliasType<{
	/** The amount that is due today */
	amountDueTodayInCents?:boolean | `@${string}`,
	/** The estimated date that this the credit draw will settle */
	estimatedDeliveryDate?:boolean | `@${string}`,
	/** Max amount a user can request for a credit draw - deprecated */
	maxDrawAmountInCents?:boolean | `@${string}`,
	/** Minimum amount a user can request for a credit draw - deprecated */
	minDrawAmountInCents?:boolean | `@${string}`,
	/** The prospective min payment amount for the first payment post this draw. This is different from what lives in the minPaymentDetails */
	prospectiveMinPaymentAmount?:boolean | `@${string}`,
	/** The estimated date that this the credit draw will settle */
	prospectiveMinPaymentDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Contains global configuration data for the Line of credit. */
["LineOfCreditGlobalConfiguration"]: AliasType<{
	/** The USA states in which line of credit is available. */
	supportedStates?:ValueTypes["UsaState"],
		__typename?: boolean | `@${string}`
}>;
	/** Fetches LOC credit limit details for user */
["LineOfCreditLineLimitDetails"]: AliasType<{
	/** Max amount a user can request for a credit draw */
	maxDrawAmountInCents?:boolean | `@${string}`,
	/** Minimum amount a user can request for a credit draw */
	minDrawAmountInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details regarding minimum payment details */
["LineOfCreditMinPaymentDetailsFetcher"]: AliasType<{
	/** Represents the fee amount that a user must make a payment on. */
	feeAmountInCents?:boolean | `@${string}`,
	/** Represents the amount that a user must make a payment on. */
	minPaymentAmountInCents?:boolean | `@${string}`,
	/** Represents the date that a user must make a payment on. */
	minPaymentDate?:boolean | `@${string}`,
	/** If the status is NOT DUE, most of the fields are generated on the fly. If it is due, it is the result of the persisted billing cycle details */
	minPaymentStatus?:boolean | `@${string}`,
	/** Represents the principal amount that a user must make a payment on. */
	principalAmountInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Payment made against a user's LOC account */
["LineOfCreditPayment"]: AliasType<{
	/** Payment amount in cents */
	amountInCents?:boolean | `@${string}`,
	/** Type of payment attempt (manual or automatic) */
	paymentAttemptType?:boolean | `@${string}`,
	/** Date payment was made on */
	paymentDate?:boolean | `@${string}`,
	/** Payment status (SETTLED, RETURNED, etc.) */
	paymentStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Contains list of historical payments made against LOC account */
["LineOfCreditPaymentHistory"]: AliasType<{
	/** List of historical payments */
	payments?:ValueTypes["LineOfCreditPayment"],
		__typename?: boolean | `@${string}`
}>;
	/** Detail of the LOC account that a user is thinking of opening */
["LineOfCreditProspectiveTerms"]: AliasType<{
	/** Membership fees for the year */
	annualFeesInCents?:boolean | `@${string}`,
	/** The day of month the user will have to make their first payment */
	dayOfMonthForRecurringPayment?:boolean | `@${string}`,
	/** The end date of the 'monthly statement'. All transactions after this date and before the end are included in the statement */
	firstBillingCycleEnd?:boolean | `@${string}`,
	/** The start date of the 'monthly statement'. All transactions after this date and before the end are included in the statement */
	firstBillingCycleStart?:boolean | `@${string}`,
	/** The minimum payment a user must make every billing cycle. This is moreso a ceiling. */
	minMonthlyPayment?:boolean | `@${string}`,
	/** Membership fee per month */
	monthlyFeeInCents?:boolean | `@${string}`,
	/** Total Credit Line Amount */
	totalCreditLineAmountInCents?:boolean | `@${string}`,
	/** The date the user will have to make their first payment */
	upcomingMinPaymentDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A reminder associated with the user's open installment loan account. */
["LineOfCreditStatement"]: AliasType<{
	endDate?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details statement period and prev statements */
["LineOfCreditStatementDetailsFetcher"]: AliasType<{
	currPeriodEndDate?:boolean | `@${string}`,
	currPeriodStartDate?:boolean | `@${string}`,
	/** Statement balance for the 'prev' statement balance which is the same definition of the balance of the due billing cycle */
	prevStatementBalance?:boolean | `@${string}`,
	/** Remaining statement balance for the 'prev' statement balance which is the same definition of the remaining balance of the due billing cycle */
	remainingStatementBalance?:boolean | `@${string}`,
	statementHistory?:ValueTypes["LineOfCreditStatement"],
		__typename?: boolean | `@${string}`
}>;
	/** A timeline showing the payment history for a loan. */
["LoanTimelineEvent"]: AliasType<{
	/** User-friendly description explaining the status of the loan payment. */
	description?:boolean | `@${string}`,
	/** The amount of the loan payment being made in dollars. */
	dollarAmount?:boolean | `@${string}`,
	/** If present, the return type explains why the loan payment was returned. */
	returnType?:boolean | `@${string}`,
	/** The loan timeline status refers to the payment status of the loan payment such as whether it succeeded or failed. */
	status?:boolean | `@${string}`,
	/** The date and time of the loan payment or event. */
	timestamp?:boolean | `@${string}`,
	/** The loan timeline type refers to the type of loan payment being made. */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Loans"]: AliasType<{
	/** The number of days that a loan is past the current due date by. */
	daysPastCurrentDueDate?:boolean | `@${string}`,
	/** The number of days that a loan is past the original due date by. */
	daysPastDue?:boolean | `@${string}`,
	/** The loan's current due date. */
	dueDate?:boolean | `@${string}`,
	/** Whether express delivery was used or not */
	express?:boolean | `@${string}`,
	/** Fee for express delivery in dollars */
	expressTransferFeeDollars?:boolean | `@${string}`,
	/** True only if the loan is outstanding with no scheduled payment */
	extendable?:boolean | `@${string}`,
	/** Unique ID for loan */
	loanId?:boolean | `@${string}`,
	/** The Reason a loan is not recoverable */
	notRecoverableReason?:boolean | `@${string}`,
	/** The When the Loan was first created */
	originationDate?:boolean | `@${string}`,
	/** Advance amount for loan in dollars */
	originationDollarAmount?:boolean | `@${string}`,
	/** Unpaid loan amount in dollars */
	outstandingDollarAmount?:boolean | `@${string}`,
	/** Whether the loan's status is Pre-Charge-Off or not */
	prechargeOff?:boolean | `@${string}`,
	/** Loan amount that's been repaid in dollars */
	repaidDollarAmount?:boolean | `@${string}`,
	/** Whether the loan is repayable or not which means the loan is delivered, outstanding, and has no scheduled payment */
	repayable?:boolean | `@${string}`,
	/** Timeline of all the payments made for the loan. */
	timeline?:ValueTypes["LoanTimelineEvent"],
		__typename?: boolean | `@${string}`
}>;
	/** Represents Loc list of payment breakdown for a credit draw */
["LocPaymentBreakdown"]: AliasType<{
	/** Loc access fee payment amount in cents */
	accessFeeAmountInCents?:boolean | `@${string}`,
	/** Loc payments in month   */
	lengthInMonth?:boolean | `@${string}`,
	/** Loc minimum payment amount in cents */
	minimumPaymentAmountInCents?:boolean | `@${string}`,
	/** Loc principal payment amount in cents */
	principalPaymentAmountInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents Loc kyc response and the list of unfulfilled reasons */
["LocRequireKycResponse"]: AliasType<{
	/** Loc requirement status */
	requirementStatus?:boolean | `@${string}`,
	/** Loc unfulfillment reasons */
	unfulfillmentReasons?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents the underwriting results for a line of credit application. If it is unapproved, the list of unapproved strings will contain data */
["LocUnderwritingDetails"]: AliasType<{
	/** Unapproval reasons */
	unapprovalReasons?:boolean | `@${string}`,
	/** Underwriting Status */
	underwritingStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LoginFailure"]: AliasType<{
	/** A reason for the failure to log in, should be used by the mobile code to decide what to do. */
	loginFailureReason?:boolean | `@${string}`,
	/** An error message to show the user when logging in fails. */
	userFacingMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LoginSuccess"]: AliasType<{
	/** The auth token for the user, should be put in the store so it can be included is subsequent requests. */
	token?:boolean | `@${string}`,
	/** The logged in users id. */
	userId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarkNotificationCenterEntriesViewed"]: AliasType<{
	/** Enum denoting if the outcome of marking the list of notification centers as viewed was a success or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarkUserViewedClgModal"]: AliasType<{
	/** The user's remaining flags after their CLG Modal flag has been removed. */
	userFlags?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarketplaceBadge"]: AliasType<{
	/** The badges display name. */
	displayName?:boolean | `@${string}`,
	/** The badges id. */
	id?:boolean | `@${string}`,
	/** The badges type. */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarketplaceCategory"]: AliasType<{
	/** Marketplace category description */
	description?:boolean | `@${string}`,
	/** Marketplace category display name */
	displayName?:boolean | `@${string}`,
	/** Whether any of the offers in this category have a badge or not */
	hasBadge?:boolean | `@${string}`,
	/** Marketplace category type */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarketplaceMaintenance"]: AliasType<{
	/** Whether the feature is in active maintenance */
	activeMaintenance?:boolean | `@${string}`,
	/** The feature associated with the potentially active maintenance flag */
	feature?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarketplaceOfferByCategory"]: AliasType<{
	category?:boolean | `@${string}`,
	categoryIconUrl?:boolean | `@${string}`,
	offers?:ValueTypes["TablessMarketplaceOffer"],
		__typename?: boolean | `@${string}`
}>;
	["MarketplaceOffersV2"]: AliasType<{
	/** The number of badges on the offers. */
	badgeCount?:boolean | `@${string}`,
	/** A list of marketplace categories. */
	categories?:ValueTypes["MarketplaceCategory"],
	/** A list of offers sorted either by EPC, RPM or priority, depending on what experiments the user is sampled for. */
	fullOffers?:ValueTypes["TablessMarketplaceOffer"],
		__typename?: boolean | `@${string}`
}>;
	["MoneyLionEngineOffers"]: AliasType<{
	/** LeadUUID */
	leadUUID?:boolean | `@${string}`,
	/** Loan Offers grouped by Partner */
	loanOffers?:ValueTypes["PartnerLoanOffers"],
	/** Special Offers grouped by Partner */
	specialOffers?:ValueTypes["PartnerSpecialOffers"],
	/** UUID */
	uUID?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MoneyLionLoanOffers"]: AliasType<{
	/** APR */
	apr?:boolean | `@${string}`,
	/** Company UUID */
	companyUuid?:boolean | `@${string}`,
	/** Disclosure */
	disclosure?:boolean | `@${string}`,
	/** Estimated Payment */
	estPayment?:boolean | `@${string}`,
	/** Expiration */
	expiration?:boolean | `@${string}`,
	/** Financial Institution UUID */
	financialInstitutionUuid?:boolean | `@${string}`,
	/** Loan Amount */
	loanAmount?:boolean | `@${string}`,
	/** Originator ID */
	originatorId?:boolean | `@${string}`,
	/** Partner Image URL */
	partnerImageUrl?:boolean | `@${string}`,
	/** Partner Name */
	partnerName?:boolean | `@${string}`,
	/** Term */
	term?:boolean | `@${string}`,
	/** URL */
	url?:boolean | `@${string}`,
	/** UUID */
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MoneyLionSpecialOffers"]: AliasType<{
	/** Description of the specialOffer */
	desc?:boolean | `@${string}`,
	/** Name of the specialOffer */
	name?:boolean | `@${string}`,
	/** URL of the partner image */
	partnerImageUrl?:boolean | `@${string}`,
	/** Name of the partner */
	partnerName?:boolean | `@${string}`,
	/** Payout amount */
	payout?:boolean | `@${string}`,
	/** Product subtype */
	productSubType?:boolean | `@${string}`,
	/** Recommendation score */
	recommendationScore?:boolean | `@${string}`,
	/** URL of the specialOffer */
	url?:boolean | `@${string}`,
	/** Unique identifier */
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
accountConnected?: [{	payrollAccountId: string | Variable<any, string>},ValueTypes["AccountConnectedResponseFetcher"]],
changeSubscriptionTier?: [{	loanAmount?: ValueTypes["BigDecimal"] | undefined | null | Variable<any, string>,	mediumType?: ValueTypes["ProtectionMediumType"] | undefined | null | Variable<any, string>,	subscriptionTierLevel: ValueTypes["SubscriptionTierLevel"] | Variable<any, string>},ValueTypes["ChangeSubscriptionTierResponse"]],
completeQuiz?: [{	quizId: string | Variable<any, string>},ValueTypes["CompleteQuizFetcher"]],
createCreditBuilderUserAccount?: [{	chargePolicy: ValueTypes["FrontendInstallmentLoanChargePolicy"] | Variable<any, string>,	userContributionInCents: ValueTypes["Long"] | Variable<any, string>,	userSetTargetDayForDueDate?: number | undefined | null | Variable<any, string>},boolean | `@${string}`],
createCreditMonitoringUser?: [{	enrollment: ValueTypes["FrontEndCreditMonitoringEnrollment"] | Variable<any, string>},ValueTypes["CreditMonitoringUser"]],
createInstallmentLoansAccount?: [{	autoPayStatus: ValueTypes["FrontendInstallmentLoansAutoPay"] | Variable<any, string>},boolean | `@${string}`],
createPayrollAccount?: [{	payrollAccountId: string | Variable<any, string>,	payrollItemId: string | Variable<any, string>,	payrollUserId: string | Variable<any, string>},ValueTypes["CreatePayrollAccountResponse"]],
createPayrollAccountV2?: [{	payrollAccountId: string | Variable<any, string>,	payrollItemId: string | Variable<any, string>,	payrollUserId: string | Variable<any, string>},ValueTypes["CreatePayrollAccountResponseV2"]],
	/** Creates a deposit destination for a payroll user's direct deposits */
	createTargetDepositDestination?:ValueTypes["CreateTargetDepositDestinationResponse"],
deleteBankLogin?: [{	bankLoginId: string | Variable<any, string>},ValueTypes["DeleteBankLogin"]],
deletePayrollAccount?: [{	payrollAccountId: string | Variable<any, string>},ValueTypes["DeletePayrollAccountResponse"]],
disableDirectDeposits?: [{	depositDestinationId: string | Variable<any, string>},ValueTypes["DisableDirectDepositsResponse"]],
disablePayrollAccount?: [{	payrollAccountId: string | Variable<any, string>},ValueTypes["DisablePayrollAccountResponse"]],
initiateCatchUpPaymentForInstallmentLoan?: [{	installmentLoansUuid: string | Variable<any, string>},ValueTypes["InitiateCatchUpPaymentForInstallmentLoan"]],
initiateCreditDraw?: [{	creditDrawAmount: ValueTypes["Long"] | Variable<any, string>,	minPaymentAmount: ValueTypes["Long"] | Variable<any, string>},boolean | `@${string}`],
initiateOneTimePayment?: [{	loanId: string | Variable<any, string>,	paymentMethodId: string | Variable<any, string>},ValueTypes["InitiateOneTimePayment"]],
initiatePayOffPaymentForInstallmentLoan?: [{	installmentLoansUuid: string | Variable<any, string>},ValueTypes["InitiatePayOffPaymentForInstallmentLoan"]],
initiatePrePaymentForInstallmentLoan?: [{	installmentLoansUuid: string | Variable<any, string>},ValueTypes["InitiatePrePaymentForInstallmentLoan"]],
initiateUserPaymentForLoc?: [{	chargeType: ValueTypes["FrontendLocUserInitiatedPaymentChargeType"] | Variable<any, string>},boolean | `@${string}`],
inputSalaryData?: [{	averageSalary: ValueTypes["Long"] | Variable<any, string>,	company: string | Variable<any, string>,	gender: string | Variable<any, string>,	location: string | Variable<any, string>,	yearsOfExperience: number | Variable<any, string>},ValueTypes["InputSalaryData"]],
login?: [{	emailAddress?: string | undefined | null | Variable<any, string>,	phoneNumber?: string | undefined | null | Variable<any, string>,	recaptchaToken?: string | undefined | null | Variable<any, string>},ValueTypes["LoginUnion"]],
markNotificationCenterEntriesViewed?: [{	notificationIds: Array<ValueTypes["Long"] | undefined | null> | Variable<any, string>},ValueTypes["MarkNotificationCenterEntriesViewed"]],
	/** Marks that the user has viewed the CLG modal screen by removing their user flag. */
	markUserViewedClgModal?:ValueTypes["MarkUserViewedClgModal"],
openLocAccount?: [{	userSetAutoPayStatus: ValueTypes["FrontendLocAutopayStatus"] | Variable<any, string>},boolean | `@${string}`],
publishCreditBuilderDraftPaymentSchedule?: [{	reason: ValueTypes["FrontendCBScheduleVersionReason"] | Variable<any, string>},boolean | `@${string}`],
recordUserPromptResponse?: [{	promptType: ValueTypes["CampaignPromptType"] | Variable<any, string>,	responseType: ValueTypes["PromptResponseType"] | Variable<any, string>},ValueTypes["RecordUserPromptResponse"]],
registerBankLoginMaintainingExistingLogin?: [{	isUpdate: boolean | Variable<any, string>,	paymentAuthFlow: ValueTypes["FrontendPaymentAuthFlow"] | Variable<any, string>,	provider: ValueTypes["BankProvider"] | Variable<any, string>,	publicToken: string | Variable<any, string>,	selectedProviderAccountId?: string | undefined | null | Variable<any, string>},ValueTypes["RegisterBankLoginMaintainingExistingLogin"]],
registerOneTimeCard?: [{	city: string | Variable<any, string>,	encryptedCardData: string | Variable<any, string>,	firstName: string | Variable<any, string>,	keyId: string | Variable<any, string>,	lastFour: string | Variable<any, string>,	lastName: string | Variable<any, string>,	lineOne: string | Variable<any, string>,	lineTwo?: string | undefined | null | Variable<any, string>,	state: string | Variable<any, string>,	zipcode: string | Variable<any, string>},ValueTypes["RegisterOneTimeCardResponse"]],
registerOneTimeCardV2?: [{	city: string | Variable<any, string>,	encryptedCardData: string | Variable<any, string>,	firstName: string | Variable<any, string>,	keyId: string | Variable<any, string>,	lastFour: string | Variable<any, string>,	lastName: string | Variable<any, string>,	lineOne: string | Variable<any, string>,	lineTwo?: string | undefined | null | Variable<any, string>,	state: string | Variable<any, string>,	zipcode: string | Variable<any, string>},ValueTypes["RegisterOneTimeCardUnion"]],
repayAdvance?: [{	advanceId: string | Variable<any, string>},ValueTypes["RepayAdvance"]],
requestManualDisbursal?: [{	amount: ValueTypes["BigDecimal"] | Variable<any, string>,	dueDate: string | Variable<any, string>,	isExpress: boolean | Variable<any, string>,	secondaryBankAccountIds: Array<string | undefined | null> | Variable<any, string>},ValueTypes["RequestManualDisbursal"]],
resendAuthVerificationCode?: [{	isPhoneVerification: boolean | Variable<any, string>,	userUUID: string | Variable<any, string>},ValueTypes["ResendAuthVerificationCode"]],
	/** Schedules same day manual payment for installment loan account for Credit Builder */
	scheduleCreditBuilderSameDayManualPayment?:ValueTypes["ScheduleCreditBuilderSameDayManualPayment"],
startOrResumeQuiz?: [{	name: ValueTypes["QuizName"] | Variable<any, string>},ValueTypes["StartOrResumeQuizResponse"]],
submitCreditBuilderPaymentChargePolicy?: [{	chargePolicy: ValueTypes["FrontendInstallmentLoanChargePolicy"] | Variable<any, string>},boolean | `@${string}`],
submitCreditBuilderPaymentStrategy?: [{	retryStrategyType: ValueTypes["FrontendCreditBuilderPaymentRetryStrategyType"] | Variable<any, string>},boolean | `@${string}`],
submitMoneyLionPersonalLoansQuiz?: [{	address1: string | Variable<any, string>,	address2?: string | undefined | null | Variable<any, string>,	annualIncome: number | Variable<any, string>,	city: string | Variable<any, string>,	consentsToFcra: boolean | Variable<any, string>,	consentsToTcpa: boolean | Variable<any, string>,	creditRating: ValueTypes["CreditRating"] | Variable<any, string>,	dateOfBirth: string | Variable<any, string>,	educationLevel: ValueTypes["EducationLevel"] | Variable<any, string>,	email: string | Variable<any, string>,	employmentPayFrequency: ValueTypes["EmploymentPayFrequency"] | Variable<any, string>,	employmentStatus: ValueTypes["EmploymentStatus"] | Variable<any, string>,	fcraLanguage?: string | undefined | null | Variable<any, string>,	firstName: string | Variable<any, string>,	lastName: string | Variable<any, string>,	loanAmount: number | Variable<any, string>,	primaryPhone: string | Variable<any, string>,	productTypes: Array<string | undefined | null> | Variable<any, string>,	propertyStatus: ValueTypes["PropertyStatus"] | Variable<any, string>,	purpose: ValueTypes["LoanPurpose"] | Variable<any, string>,	ssn: string | Variable<any, string>,	state: string | Variable<any, string>,	tcpaLanguage?: string | undefined | null | Variable<any, string>,	zipcode: string | Variable<any, string>},ValueTypes["SubmitMoneyLionOffers"]],
submitQuizFeedback?: [{	feedback: string | Variable<any, string>,	feedbackType: ValueTypes["QuizFeedbackType"] | Variable<any, string>,	quizId: string | Variable<any, string>},ValueTypes["SubmitQuizFeedbackFetcher"]],
submitQuizResponse?: [{	quizId: string | Variable<any, string>,	quizStepId: string | Variable<any, string>,	response: string | Variable<any, string>},ValueTypes["SubmitQuizResponse"]],
submitResponseForPromptType?: [{	promptResponseType: ValueTypes["PromptResponseType"] | Variable<any, string>,	promptType: ValueTypes["UserPromptType"] | Variable<any, string>},ValueTypes["SubmitUserPromptResponse"]],
switchProtectedLoginToExistingLogin?: [{	bankAccountId?: string | undefined | null | Variable<any, string>,	bankLoginId: string | Variable<any, string>,	publicToken?: string | undefined | null | Variable<any, string>,	shouldMaintainExistingLogin: boolean | Variable<any, string>},ValueTypes["SwitchProtectedLoginToExistingLogin"]],
switchProtectedLoginToExistingNonConnectedLogin?: [{	bankLoginId: string | Variable<any, string>,	publicToken: string | Variable<any, string>,	shouldMaintainExistingLogin: boolean | Variable<any, string>},ValueTypes["SwitchProtectedLoginToExistingNonConnectedLogin"]],
testDisablePayrollAccount?: [{	payrollAccountId: string | Variable<any, string>,	payrollProvider: ValueTypes["PayrollProvider"] | Variable<any, string>},ValueTypes["DisablePayrollAccountResponse"]],
throwTestException?: [{	message: string | Variable<any, string>,	useErrorHandler?: boolean | undefined | null | Variable<any, string>},boolean | `@${string}`],
trackImpressionEvent?: [{	durationMs: number | Variable<any, string>,	elementName: string | Variable<any, string>,	endedAt: string | Variable<any, string>,	/** Strigified JSON referencing data that provides context to this event. */
	eventForeignKeys?: string | undefined | null | Variable<any, string>,	impressionId: string | Variable<any, string>,	screenName: string | Variable<any, string>,	startedAt: string | Variable<any, string>},ValueTypes["TrackImpressionsFetcher"]],
trackNotificationCenterEntryClick?: [{	notificationId: ValueTypes["Long"] | Variable<any, string>},ValueTypes["TrackNotificationCenterEntryClick"]],
updateAutoPayStatusForInstallmentLoan?: [{	installmentLoansUuid: string | Variable<any, string>,	newAutoPayStatus: ValueTypes["FrontendInstallmentLoansAutoPay"] | Variable<any, string>},ValueTypes["UpdateInstallmentLoansAutoPayStatusFetcher"]],
updateAutopayStatusForLoc?: [{	newAutopayStatus: ValueTypes["FrontendLocAutopayStatus"] | Variable<any, string>},boolean | `@${string}`],
updateBankAccountJointStatus?: [{	bankAccountId: string | Variable<any, string>,	isJoint: boolean | Variable<any, string>},ValueTypes["UpdateBankAccountJointStatus"]],
updateCreditLimitBoostStatus?: [{	creditLimitBoostId: string | Variable<any, string>,	creditLimitBoostStatus: ValueTypes["FrontendCreditLimitBoostStatus"] | Variable<any, string>},ValueTypes["UpdateCreditLimitBoostStatus"]],
updatePaymentChargeTypeForLoc?: [{	newPaymentChargeType: ValueTypes["FrontendLocUserInitiatedPaymentChargeType"] | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["NotificationCenter"]: AliasType<{
	/** Return notifications for user to display in notification center */
	notifications?:ValueTypes["NotificationCenterEntry"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationCenterEntry"]: AliasType<{
	/** Time notification was added to notification center for user */
	createdAt?:boolean | `@${string}`,
	/** Description of notification */
	description?:boolean | `@${string}`,
	/** Where we will navigate the user when they click on the notification */
	destination?:boolean | `@${string}`,
	/** Whether or not user has viewed notification */
	hasViewed?:boolean | `@${string}`,
	/** Id of notification */
	id?:boolean | `@${string}`,
	/** Image URL to display with the notification on the frontend */
	imageUrl?:boolean | `@${string}`,
	/** Title of notification */
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Information about pagination in a connection. */
["PageInfo"]: AliasType<{
	/** When paginating forwards, the cursor to continue. */
	endCursor?:boolean | `@${string}`,
	/** When paginating forwards, are there more items? */
	hasNextPage?:boolean | `@${string}`,
	/** When paginating backwards, are there more items? */
	hasPreviousPage?:boolean | `@${string}`,
	/** When paginating backwards, the cursor to continue. */
	startCursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PartnerLoanOffers"]: AliasType<{
	/** Offers */
	offers?:ValueTypes["MoneyLionLoanOffers"],
	/** Partner Name */
	partnerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PartnerSpecialOffers"]: AliasType<{
	/** Offers */
	offers?:ValueTypes["MoneyLionSpecialOffers"],
	/** Partner Name */
	partnerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details about past due credit builder payments. */
["PastDuePayment"]: AliasType<{
	/** The total amount past due in dollars. */
	amountDollars?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details about the user's payroll connection */
["PayrollConnection"]: AliasType<{
	/** User ID generated by a payroll vendor for client-side authorization */
	payrollUserId?:boolean | `@${string}`,
	/** User token generated by a payroll vendor for client-side authorization */
	userToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Payroll_32_Account_32_Fetcher_32_With_32_Outcome"]: AliasType<{
	/** Whether the request was successful */
	outcome?:boolean | `@${string}`,
	/** List of all payroll accounts for the user */
	payrollAccounts?:ValueTypes["Payroll_32_Accounts_32_V2"],
		__typename?: boolean | `@${string}`
}>;
	["Payroll_32_Accounts"]: AliasType<{
	/** Connection status of payroll account */
	connectionStatus?:boolean | `@${string}`,
	/** Name of employer associated with payroll account */
	employerName?:boolean | `@${string}`,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	manualDisconnect?:boolean | `@${string}`,
	/** Status indicating whether payroll account will be updated when data refreshes */
	ongoingRefreshStatus?:boolean | `@${string}`,
	/** Unique ID for payroll account */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Payroll_32_Accounts_32_V2"]: AliasType<{
	/** Connection status of payroll account */
	connectionStatus?:boolean | `@${string}`,
	/** User facing message explaining why the user isn't eligible for DDS */
	dDSEligibilityMessage?:boolean | `@${string}`,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	eligibleForDDS?:boolean | `@${string}`,
	/** Name of employer associated with payroll account */
	employerName?:boolean | `@${string}`,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	manualDisconnect?:boolean | `@${string}`,
	/** Status indicating whether payroll account will be updated when data refreshes */
	ongoingRefreshStatus?:boolean | `@${string}`,
	/** Unique ID for payroll account */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A user facing summary of insights for a user's bank transactions of loan is a secured loan which the user has taken out to build such as upcoming fees, cashflow, spend, etc. */
["PfmSummary"]: AliasType<{
	/** Returns the sum of the the total debit amounts for plaid transactions with fee categories */
	totalFeesPaid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Payment details about possible pre-payments user can make. */
["PrePayment"]: AliasType<{
	/** The payment amount in dollars. */
	amountDollars?:boolean | `@${string}`,
	/** When the pre-payment is due. */
	dueDate?:boolean | `@${string}`,
	/** An optional date that is returned if the the prepayment is not yet eligible for prepayment.   */
	payableAfter?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PromoCardOffer"]: AliasType<{
	/** The url for the offers affiliate link. */
	affiliateLinkUrl?:boolean | `@${string}`,
	/** The offers description */
	description?:boolean | `@${string}`,
	/** The offers details */
	details?:boolean | `@${string}`,
	/** The Offers Id */
	id?:boolean | `@${string}`,
	/** The url for the offers logos image file. */
	logoUrl?:boolean | `@${string}`,
	/** The offers name. */
	offerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A promotion is an offer to provide a discount to a user for subscribing to Brigit. */
["Promotion"]: AliasType<{
	/** The amount that the user would pay while the discount is active, in US dollars. */
	discountedAmount?:boolean | `@${string}`,
	/** How many months the discount would apply to the user's subscription. */
	numMonths?:boolean | `@${string}`,
	/** The amount that the user would pay while the discount is active, in US dollars. */
	subscriptionAmountAfterDiscount?:boolean | `@${string}`,
	/** The subscription tier level that the promotion applies to. Users would need to subscribe to this particular tier for the promotion to be applied as a discount. */
	subscriptionTierLevel?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProtectionDetails"]: AliasType<{
	/** List of user's loans and loan details */
	loans?:ValueTypes["Loans"],
	/** Status of the user's current advance */
	protectionStatus?:boolean | `@${string}`,
	/** User-friendly description of the status of the user's current advance */
	protectionStatusDescription?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Query"]: AliasType<{
	/** The available subscription tiers are those tiers which can be accessed by the user making the request. They're what we would advertise as the options available to the user. */
	availableSubscriptionTiers?:ValueTypes["SubscriptionTier"],
	/** Global configurations related to the Credit Builder system. */
	creditBuilderGlobalConfiguration?:ValueTypes["CreditBuilderGlobalConfiguration"],
	/** Get Promo cards under new dynamic marketplace model */
	dynamicPromoCards?:ValueTypes["DynamicPromoCards"],
experimentVariantAssignment?: [{	name: ValueTypes["ExperimentName"] | Variable<any, string>,	sampleKey: string | Variable<any, string>,	sampleKeyType: ValueTypes["FrontendSampleKeyType"] | Variable<any, string>,	samplingTrigger: ValueTypes["SamplingTrigger"] | Variable<any, string>,	shouldPerformAssignment: boolean | Variable<any, string>},ValueTypes["FrontendExperimentVariantAssignment"]],
	/** Return Frequently Asked Questions as part of an experiment to add clarity to the subscription process when a user is activating via the IC flow. See: https://brigit.atlassian.net/browse/ENG-8843. */
	iCActivationFAQs?:ValueTypes["ICActivationFAQs"],
	/** Global configurations related to the Line of credit system. */
	lineOfCreditGlobalConfiguration?:ValueTypes["LineOfCreditGlobalConfiguration"],
marketplaceMaintenance?: [{	feature?: ValueTypes["MarketplaceFeature"] | undefined | null | Variable<any, string>},ValueTypes["MarketplaceMaintenance"]],
	/** The account of the user making the request */
	me?:ValueTypes["UserAccount"],
	/** Returns information to the frontend about whether or not the user's current mobile version is supported and, if it's not, whether we should nudge or force users to upgrade.Returns UNKNOWN if the user is on web or if we can't parse the mobile version we receive. */
	mobileVersionSupportType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["QuizFetcher"]: AliasType<{
	/** Whether the quiz is completed */
	completed?:boolean | `@${string}`,
	/** The zoned datetime of when the quiz was completed. Returns an optional because quiz may be incomplete */
	completedAt?:boolean | `@${string}`,
	/** The zoned datetime of when the quiz was started */
	createdAt?:boolean | `@${string}`,
	/** Optional zoned datetime of when the quiz was deleted */
	deletedAt?:boolean | `@${string}`,
	/** The id of the quiz */
	id?:boolean | `@${string}`,
	/** The name of the quiz */
	quizName?:boolean | `@${string}`,
	/** The taker of the quiz. It may be a user id or a device id if the user is not yet authenticated. */
	quizTaker?:boolean | `@${string}`,
	/** The version id of the quiz */
	quizVersionId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["QuizStateFetcher"]: AliasType<{
	/** Whether a user can exit the quiz at any point */
	canExitEarly?:boolean | `@${string}`,
	/** The current step of the quiz that the user is on. If the quiz has no more steps to complete, an empty optional is returned. */
	currentStepId?:boolean | `@${string}`,
	/** Whether the quiz can be resume part way through */
	isResumable?:boolean | `@${string}`,
	/** An array of QuizSteps */
	steps?:ValueTypes["Step"],
	/** The total number of steps in the current state. If branching logic changes length, returns empty optional */
	totalSteps?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecentScoreChangeFetcher"]: AliasType<{
	/** An enum representing the CTA link that should be rendered after the text. */
	callToAction?:boolean | `@${string}`,
	/** An optional integer. If present will be used to render the score change indicator on the right side of the card and should contain a positive, negative, or 0 change to show/ If they only have one score and thus can not compute a delta this should be empty. */
	scoreChange?:boolean | `@${string}`,
	/** The date of the last score pulled. Will be used to render the footer of the component.  */
	scoreUpdateDate?:boolean | `@${string}`,
	/** The text to display in the component. */
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecordUserPromptResponse"]: AliasType<{
	/** The prompt type of a user engagement event */
	campaignPromptType?:boolean | `@${string}`,
	/** The response type of a user engagement event */
	promptResponseType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Referral object maintains a users referral status for a specific referral. */
["Referral"]: AliasType<{
	/** Represents whether the user has been paid for the referral. */
	paid?:boolean | `@${string}`,
	/** Represents the value for which the user was paid for the referral. */
	payoutValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterBankLoginMaintainingExistingLogin"]: AliasType<{
	/** An Enum denoting if the outcome of registering the bank account was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterOneTimeCardInvalidInput"]: AliasType<{
	/** User facing message explaining why the user input was invalid. */
	userFacingMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterOneTimeCardResponse"]: AliasType<{
	/** The display name of the card payment method i.e. VISA ending in 1234 */
	cardNickname?:boolean | `@${string}`,
	/** The payment method ID of the one time card payment method created */
	paymentMethodId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterOneTimeCardSuccess"]: AliasType<{
	/** User friendly nickname to refer to card. */
	cardNickname?:boolean | `@${string}`,
	/** Newly created payment method ID. */
	paymentMethodId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RepayAdvance"]: AliasType<{
	/** The advance that we attempted to repay */
	advanceId?:boolean | `@${string}`,
	/** An Enum denoting if the outcome of the request was successful or failure */
	repayAdvanceOutcome?:boolean | `@${string}`,
	/** A String denoting why this request succeeded or failed */
	repayAdvanceOutcomeReason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RequestManualDisbursal"]: AliasType<{
	/** The loan that was disbursed to the user, on success */
	loanId?:boolean | `@${string}`,
	/** An Enum denoting if the outcome of the requestManualDisbursal was successful or failure */
	requestManualDisbursalOutcome?:boolean | `@${string}`,
	/** A String denoting why this request succeeded or failed */
	requestManualDisbursalReason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ResendAuthVerificationCode"]: AliasType<{
	/** An Enum denoting if the outcome of resending the verification code was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SalaryAggregators"]: AliasType<{
	/** Primary filter used to aggregate salary data */
	filterOne?:boolean | `@${string}`,
	/** List of aggregated salary data based on a second filter */
	filterTwoData?:ValueTypes["FilteredSalaryData"],
	/** The average salary for the primary filter */
	salary?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Installment payment made manually on the due date from the user's external bank account for credit builder. */
["SameDayManualPayment"]: AliasType<{
	/** Amount scheduled to be paid for the manual payment */
	amountInCents?:boolean | `@${string}`,
	/** Date and time of scheduled manual payment in yyyy-MM-dd format */
	paidOn?:boolean | `@${string}`,
	/** Status of installment loan payment after manual payment is scheduled */
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Payment details for manual payment that is due today. */
["SameDayPayment"]: AliasType<{
	/** The payment amount in dollars. */
	amountDollars?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ScheduleCreditBuilderSameDayManualPayment"]: AliasType<{
	/** The result of scheduling same day manual payment */
	outcome?:boolean | `@${string}`,
	/** The response object scheduling same day manual payment. Only returns something if the outcome is successful */
	sameDayManualPayment?:ValueTypes["SameDayManualPayment"],
		__typename?: boolean | `@${string}`
}>;
	["ScoreBucketFetcher"]: AliasType<{
	/** The lower range of the score bucket, e.g. 349. */
	maximumScore?:boolean | `@${string}`,
	/** The lower range of the score bucket, e.g. 300. */
	minimumScore?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ScoreCard"]: AliasType<{
	/** The maximum score to be shown on the user's score card */
	maxScore?:boolean | `@${string}`,
	/** The minimum approval score needed for the user to be approved based on their underwriting strategy. */
	minApprovalScore?:boolean | `@${string}`,
	/** The minimum score to be shown on the user's score card */
	minScore?:boolean | `@${string}`,
	/** The user's underwriting score. If their score meets the minimum approval score, then the user will be approved for Instant Cash. */
	score?:boolean | `@${string}`,
	/** List of categories that influence the user's underwriting score. */
	scoreCategories?:ValueTypes["ScoreCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ScoreCategory"]: AliasType<{
	/** User-friendly text describing improvements the user can make to improve their performance in this score category. */
	description?:boolean | `@${string}`,
	/** The name of the larger categories that affect the user's approval status such as bank account, spending, and earning. */
	name?:boolean | `@${string}`,
	/** The status of this category is based on the lowest rank of the statuses in its subcategories. */
	status?:boolean | `@${string}`,
	/** List of more specific subcategories within this score category. */
	subcategories?:ValueTypes["ScoreSubcategory"],
		__typename?: boolean | `@${string}`
}>;
	["ScoreSubcategory"]: AliasType<{
	/** User-friendly text explaining what data is used in this subcategory */
	description?:boolean | `@${string}`,
	/** Name of the score subcategory */
	name?:boolean | `@${string}`,
	/** A rank of how important this score subcategory contributes to the user's underwriting score */
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SelectFieldFetcher"]: AliasType<{
	/** An optional image to display to the left of the text.  Only populated if the type is GRID */
	icon?:boolean | `@${string}`,
	/** The text to display for the option */
	optionDisplayValue?:boolean | `@${string}`,
	/** Whether the option should be selected by default */
	selected?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StartOrResumeQuizResponse"]: AliasType<{
	/** The quiz definition */
	quiz?:ValueTypes["QuizFetcher"],
	/** The state of the quiz */
	state?:ValueTypes["QuizStateFetcher"],
		__typename?: boolean | `@${string}`
}>;
	["Step"]: AliasType<{
	/** Optional copy to provide additional context or instruction for the question */
	assistiveCopy?:boolean | `@${string}`,
	/** Whether a ‘Skip’ button should be rendered allowing user to bypass the step. */
	canSkip?:boolean | `@${string}`,
	/** Whether assistive copy should be centered */
	centerAssistiveCopy?:boolean | `@${string}`,
	/** Whether the main question text should be centered */
	centerQuestionText?:boolean | `@${string}`,
	/** Whether select options should be centered */
	centered?:boolean | `@${string}`,
	/** Optional disclosure primary text */
	disclosureCopy?:boolean | `@${string}`,
	/** Whether a step has information that needs to be sent via VGS or other security proxy */
	hasSensitiveResponse?:boolean | `@${string}`,
	/** Optional icon image name */
	heroIcon?:boolean | `@${string}`,
	/** Required if a hero icon exists */
	heroIconSize?:boolean | `@${string}`,
	/** A unique string identifier for the step. Will be used when submitting responses to the step (if the step has responses). For example, the first step of a quiz may have an ID of '1'. */
	id?:boolean | `@${string}`,
	/** Whether the next step is known or not (e.g. dependant on the response to this step */
	isBranching?:boolean | `@${string}`,
	/** Whether its the last step of the quiz */
	isLastStep?:boolean | `@${string}`,
	/** Whether the user should be able to select/submit multiple options. Only populated for MULTI_SELECT or GRID */
	multipleSelect?:boolean | `@${string}`,
	/** The ID of the next quiz step, if present */
	nextStepId?:boolean | `@${string}`,
	/** The select field options. Only populated if the type is MULTI-SELECT or GRID */
	options?:ValueTypes["SelectFieldFetcher"],
	/** Only populated for type YES_NO where the field has a prior submisson */
	previousSelectedYesNoOption?:boolean | `@${string}`,
	/** The ID of the prior quiz step, if present */
	priorStepId?:boolean | `@${string}`,
	/** The main text of the step. You can imagine this as the question text, e.g. 'Do you like pickles?' */
	questionText?:boolean | `@${string}`,
	/** Optional copy for the submit button */
	submitText?:boolean | `@${string}`,
	/** Optional supplemental text */
	supplementalCopy?:boolean | `@${string}`,
	/** Optional icon to display with the supplemental text */
	supplementalCopyIcon?:boolean | `@${string}`,
	/** Optional disclosure secondary text */
	supplementalDisclosureCopy?:boolean | `@${string}`,
	/** Only populated if the type is FREE_TEXT */
	textFields?:ValueTypes["TextFieldFetcher"],
	/** The type of the quiz step, e.g. 'INFORMATION', 'TEXT_INPUT', 'MULTI_SELECT' etc */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitMoneyLionOffers"]: AliasType<{
	/** LeadUUID */
	leadUUID?:boolean | `@${string}`,
	/** Loan Offers grouped by Partner */
	loanOffers?:ValueTypes["PartnerLoanOffers"],
	/** Special Offers grouped by Partner */
	specialOffers?:ValueTypes["PartnerSpecialOffers"],
	/** UUID */
	uUID?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitQuizFeedbackFetcher"]: AliasType<{
	/** The id of the quiz */
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitQuizResponse"]: AliasType<{
	/** The state of the quiz */
	state?:ValueTypes["QuizStateFetcher"],
		__typename?: boolean | `@${string}`
}>;
	["SubmitUserPromptResponse"]: AliasType<{
	/** The outcome of submitting a user prompt response */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A subscription is an agreement between Brigit and a user, for them to pay us periodically, and for us to provide them access to some of our services in exchange for that payment. Subscriptions are associated with a particular tier, which dictates what price the user pays and what features they are provided. */
["Subscription"]: AliasType<{
	/** The tier that the subscription is currently linked to. */
	currentTier?:ValueTypes["SubscriptionTier"],
	/** The time the current subscription tier became effective, or empty if user is NPE */
	currentTierValidFromTimestamp?:boolean | `@${string}`,
	/** If present, the subscription currently has a discount applied to it. */
	discount?:ValueTypes["Discount"],
	/** If user has a connected account, is approved, was previously enabled but currently disabled, has a loan, and does not have an outstanding loan, they are eligible for the reactivation experience. */
	isEligibleForReactivationExperience?:boolean | `@${string}`,
	/** An explanation of how Instant Cash Express Transfer fees would work for a user on this tier. If the tier does not provide access to Instant Cash, instant transfer fees are not applicable and this field will be empty. NOTE: for NPE users this will be the subscription date if the user activates today with their same bank account, this could change if they change their bank account or income source. */
	proposedBillDate?:boolean | `@${string}`,
	/** If present, the subscription fee schedule the user has been assigned to determine when in the month we'll schedule their monthly subscription payment. */
	subscriptionFeeSchedule?:boolean | `@${string}`,
	/** Subscription payments that have been scheduled for a user, excluding those that were cancelled because the user deactivated during the grace period or upgraded to the Premium plan. */
	subscriptionPayments?:ValueTypes["SubscriptionPayment"],
subscriptionTierExperiment?: [{	experimentName: ValueTypes["SubscriptionTierExperimentName"] | Variable<any, string>},ValueTypes["SubscriptionTierExperiment"]],
	/** A subscription tier strategy indicates the subscription experience users are subject to,
    the details of which will be reflected in their `availableSubscriptionTiers`. */
	subscriptionTierStrategyName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionPayment"]: AliasType<{
	/** Full subscription amount user is paying month over month, not taking promotions into account. */
	intendedAmount?:boolean | `@${string}`,
	/** Whether a subscription is repayable. Controls whether we'll allow usersto repay a missed subscription from the home screen. */
	isPayable?:boolean | `@${string}`,
	/** Amount that we attempted to collect for a given subscription. Will be 1:1 with intendedAmount unless user received a promotion for a given month. */
	netAmount?:boolean | `@${string}`,
	/** Date on which we'll attempt to collect on the subscription payment, formatted YYYY-MM-dd */
	paymentDate?:boolean | `@${string}`,
	/** Status of subscription payment. */
	status?:boolean | `@${string}`,
	/** If present, the subscription tier the user was on when we scheduled the givensubscription payment. If not present, the user is a legacy non-tiered user. */
	subscriptionTierLevel?:boolean | `@${string}`,
	/** UUID of subscription payment */
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A subscription tier is an offering for a type of subscription that a user can enroll in. A tier has a price that the user pays to access it, and includes features that Brigit provides in exchange. Some tiers provide fewer features for a lower price, and others provide more features for a higher price. */
["SubscriptionTier"]: AliasType<{
	/** The name of this tier to show to the user. */
	displayName?:boolean | `@${string}`,
	/** An explanation of how Instant Cash Express Transfer fees would work for a user on this tier. If the tier does not provide access to Instant Cash, instant transfer fees are not applicable and this field will be empty. NOTE: for NPE users this will be the subscription date if the user activates today with their same bank account, this could change if they change their bank account or income source. */
	expressTransferFeeText?:boolean | `@${string}`,
	/** An identifier for which subscription tier this is. There are a small, fixed number of tiers. */
	level?:boolean | `@${string}`,
	/** The monthly cost that a user on this tier would pay, in US dollars. */
	pricingAmount?:boolean | `@${string}`,
	/** The Brigit products that a user on this tier would have access to. */
	products?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A subscription tier experiment involves splitting users into different groups to provide them with different subscription-related experiences, to see which experiences work best. This object records a user's participation in a single experiment. */
["SubscriptionTierExperiment"]: AliasType<{
	/** Identifies the experiment. */
	experimentName?:boolean | `@${string}`,
	/** A variant name identifies which group the user is part of for this experiment. The names will depend on the experiment, but CONTROL is typically the name given to the variant that provides an unchanged experience. If only one alternate experience is being tested, the variant for users being shown the new experience is typically TEST. Some experiments may have other variants, however, with unique names. */
	variantName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SwitchProtectedLoginToExistingLogin"]: AliasType<{
	/** An Enum denoting if the outcome of switching the protected login was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SwitchProtectedLoginToExistingNonConnectedLogin"]: AliasType<{
	/** An Enum denoting if the outcome of switching the protected login to an existing, non-connected login was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TablessMarketplaceOffer"]: AliasType<{
	/** The url for the offers affiliate link. */
	affiliateLinkUrl?:boolean | `@${string}`,
	/** A list of badges to be rendered with the offer. */
	badges?:ValueTypes["MarketplaceBadge"],
	/** The categories the offer is under. */
	categories?:boolean | `@${string}`,
	/** The offers description. */
	description?:boolean | `@${string}`,
	/** The offers details. */
	details?:boolean | `@${string}`,
	/** The offers id. */
	id?:boolean | `@${string}`,
	/** The url for the offers logos image file. */
	logoUrl?:boolean | `@${string}`,
	/** The offers name. */
	offerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents the installment loan term details for a prospective user */
["TermRequestDetails"]: AliasType<{
	/** Application fee in cents */
	applicationFeeInCents?:boolean | `@${string}`,
	/** Estimated delivery for the principal payment credit */
	estimatedDeliveryDateForPrincipalCredit?:boolean | `@${string}`,
	/** Monthly fee in cents */
	monthlyFeeInCents?:boolean | `@${string}`,
	/** Pay Frequency type for the payment schedule */
	payFrequency?:boolean | `@${string}`,
	/** Payment schedule */
	paymentSchedule?:ValueTypes["FrontendPrincipalPayment"],
	/** Total months of repayment for the term */
	totalMonthsInTerm?:boolean | `@${string}`,
	/** Loan amount in cents */
	totalPrincipalAmountInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TextFieldFetcher"]: AliasType<{
	/** Prior persisted value input by the user, if exists */
	initValue?:boolean | `@${string}`,
	/** Whether the text field may contain sensitive information */
	isSensitive?:boolean | `@${string}`,
	/** What type of keyboard should be shown */
	keyboardType?:boolean | `@${string}`,
	/** The text field label */
	label?:boolean | `@${string}`,
	/** A list of strings containing the names of methods to format text, e.g. date or dollarAmount */
	textFormat?:boolean | `@${string}`,
	/** An array of strings of validation that should be applied to the field */
	validate?:boolean | `@${string}`,
	/** An array of strings of possible warnings that should be applied to the field */
	warn?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TrackImpressionsFetcher"]: AliasType<{
	/** Tracked impression that is saved to the backend if the outcome indicates a success */
	impression?:ValueTypes["UserImpression"],
	/** Result of the attempt to save the impression */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TrackNotificationCenterEntryClick"]: AliasType<{
	/** Enum denoting if the outcome of tracking click on notification center entry was a success or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnderwritingDetails"]: AliasType<{
	/** Describes the approval of the user's account which could be approved, unapproved, or not run if the user has not been underwritten yet */
	accountApprovalState?:boolean | `@${string}`,
	/** Whether the user is approved or not based on their credit risk state */
	creditRiskState?:boolean | `@${string}`,
	/** Fetch users approval state from the latest underwriting report */
	isApproved?:boolean | `@${string}`,
	/** Latest credit limit boost for a user */
	latestCreditLimitBoost?:ValueTypes["CreditLimitBoostDetails"],
	/** Maximum loan amount for user */
	maxLoanAmount?:boolean | `@${string}`,
	/** Minimum loan amount for user */
	minLoanAmount?:boolean | `@${string}`,
	/** A numeric representation of the user's progress in being approved based on the number of underwriting rules the user has met and not met */
	progress?:boolean | `@${string}`,
	/** A representation of the user's underwriting score and score categories. This field is only present if the user is on a score-based underwriting strategy. */
	scoreCard?:ValueTypes["ScoreCard"],
	/** Fetch all underwriting checks which include knockout rules and account level rules from the latest underwriting report */
	underwritingChecks?:ValueTypes["FrontendUnderwritingCheck"],
	/** List of underwriting rules and metadata used for underwriting the user */
	underwritingRulesMetadata?:ValueTypes["UnderwritingRuleMetadata"],
	/** All credit limit boosts for a user */
	userCreditLimitBoosts?:ValueTypes["CreditLimitBoostDetails"],
		__typename?: boolean | `@${string}`
}>;
	["UnderwritingRuleMetadata"]: AliasType<{
	/** Depending on the underwriting rule, the cutoff serves as the maximum or minimum amount needed for the user to meet this UW rule */
	cutoff?:boolean | `@${string}`,
	/** Name of underwriting rule used for calculating the user's underwriting score */
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateBankAccountJointStatus"]: AliasType<{
	/** An Enum denoting if the outcome of updating the bank account's joint status was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateCreditLimitBoostStatus"]: AliasType<{
	/** The updated status of the credit limit boost */
	updatedCreditLimitBoostStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateInstallmentLoansAutoPayStatusFetcher"]: AliasType<{
	/** An Enum denoting if the outcome of the auto update status is successful */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A USA state, territory, or region that has a human-readable name and associated code. */
["UsaState"]: AliasType<{
	/** The two-letter code for the state (e.g. 'MD') */
	code?:boolean | `@${string}`,
	/** The human readable name for the state (e.g. 'Maryland'). */
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A user's account is like their profile within Brigit. It's the object that records the user's state, preferences, and the interactions and agreements made between the user and Brigit. */
["UserAccount"]: AliasType<{
	/** List of active logins for user. This will include the user's protected login (which holds the account we're sending advances to and collecting subscriptions from) and their non-protected, active logins, which are non-deleted primary logins that we still maintain a connection to. */
	activeBankLogins?:ValueTypes["ActiveBankLogins"],
	/** The user's approved loan amounts */
	approvedLoanAmounts?:ValueTypes["ApprovedLoanAmounts"],
	/** The user's auto advance configuration */
	autoAdvanceDetails?:ValueTypes["AutoAdvanceDetails"],
	/** Predictions based on the user's linked bank account such as their income sources and likelihood to overdraft. */
	bankAccountPrediction?:ValueTypes["BankAccountPrediction"],
	/** The user's bank connection details */
	bankConnection?:ValueTypes["BankConnection"],
	/** Date on which user created their account */
	creationDate?:boolean | `@${string}`,
creditBuilderEnrollmentEligibility?: [{	reasons?: Array<ValueTypes["CreditBuilderEnrollmentIneligibilityReason"] | undefined | null> | undefined | null | Variable<any, string>},ValueTypes["CreditBuilderEnrollmentEligibility"]],
	/** The user's most recent Credit Builder installment loan. */
	creditBuilderInstallmentLoan?:ValueTypes["CreditBuilderInstallmentLoan"],
	/** The Sweep Stakes qualification determines if user is qualifies for sweep stake */
	creditBuilderSweepStakesQualification?:boolean | `@${string}`,
	/** User details related to Credit Builder */
	creditBuilderUserDetails?:ValueTypes["CreditBuilderUserDetails"],
	/** The user's relationship with Brigit's Credit Monitoring product. */
	creditMonitoringEnrollment?:ValueTypes["CreditMonitoringEnrollment"],
	/** Promo card v1 offers from the dynamic data model */
	dynamicPromoCardV1Offers?:ValueTypes["PromoCardOffer"],
	/** The user's email address. */
	email?:boolean | `@${string}`,
	/** The user's email address that is in the process of being verified. */
	emailToVerify?:boolean | `@${string}`,
	/** Extension credits for user - they can use this to extend the loan due date for any outstanding loan */
	extensionCredits?:boolean | `@${string}`,
	/** The user's first name. */
	firstName?:boolean | `@${string}`,
	/** Flags represent states that a user may be in. Historically they have been used to mark users for inclusion or exclusion in tests, though we hope to move to an alternative way of managing that. */
	flags?:boolean | `@${string}`,
	/** Get if user is in a state where they have been approved for a free instant cash transfer. */
	freeInstantCashTransferState?:boolean | `@${string}`,
	/** Customizable banner to display to user on the home screen */
	homeScreenBanner?:ValueTypes["HomeScreenBanner"],
	/** User properties related to Instant Cash experiments */
	iCExperimentProperties?:ValueTypes["ICExperimentProperties"],
	/** The user's ID is their unique identifier within Brigit. While mostly used internally, it may need to be provided to external services to link a user's account to them. */
	id?:boolean | `@${string}`,
	/** Whether the user is enrolled, or was previously enrolled, in Brigit's Identity Protection product. */
	identityProtectionEnrollmentStatus?:boolean | `@${string}`,
incomeProfile?: [{	filterOne: ValueTypes["SalaryAggregateFilterCategory"] | Variable<any, string>,	filterTwo?: ValueTypes["SalaryAggregateFilterCategory"] | undefined | null | Variable<any, string>},ValueTypes["IncomeProfile"]],
	/** The user's prospective and current details related with the Installment Loans product */
	installmentLoans?:ValueTypes["InstallmentLoans"],
	/** The user's last name. */
	lastName?:boolean | `@${string}`,
	/** This field is no longer relevant as we have rolled out tiered subscriptions at 100%. It remains here as a placeholder until we set the minimum app version to >= v704, which is the version where we removed this field from the query in the FE */
	legacyProposedNextMonthDiscountedAmount?:boolean | `@${string}`,
	/** The user's prospective and current details related with the Installment Loans product */
	lineOfCredit?:ValueTypes["LineOfCredit"],
	/** The user's location state */
	locationState?:ValueTypes["UsaState"],
	/** A list of MarketplaceOffers by Category */
	marketplaceOffers?:ValueTypes["MarketplaceOfferByCategory"],
	/** Lists of marketplace offers. */
	marketplaceOffersV2?:ValueTypes["MarketplaceOffersV2"],
	/** MoneyLionEngineOffers */
	moneyLionEngineOffers?:ValueTypes["MoneyLionEngineOffers"],
	/** In-app notification center */
	notificationCenter?:ValueTypes["NotificationCenter"],
	/** Details related to the user's payroll accounts */
	payrollAccounts?:ValueTypes["Payroll_32_Accounts"],
	/** Details related to the user's payroll accounts */
	payrollAccountsV2?:ValueTypes["Payroll_32_Accounts_32_V2"],
	/** Details related to the user's payroll accounts */
	payrollAccountsWithOutcome?:ValueTypes["Payroll_32_Account_32_Fetcher_32_With_32_Outcome"],
	/** Details related to the user's payroll connection */
	payrollConnection?:ValueTypes["PayrollConnection"],
	/** The user's bank transactional data insights. */
	pfmSummary?:ValueTypes["PfmSummary"],
	/** The user's phone number. */
	phoneNumber?:boolean | `@${string}`,
	/** The user's phone number that is in the process of being verified. */
	phoneNumberToVerify?:boolean | `@${string}`,
	/** Variant on premium upsell card to display to users on the home screen */
	premiumUpsellCardVariant?:boolean | `@${string}`,
	/** Promo card v1 and v2 offers from the legacy data model */
	promoCardOffers?:ValueTypes["PromoCardOffer"],
promotion?: [{	level: ValueTypes["SubscriptionTierLevel"] | Variable<any, string>},ValueTypes["Promotion"]],
	/** Details related to the user's advances */
	protectionDetails?:ValueTypes["ProtectionDetails"],
	/** The user's referral link. */
	referralLink?:boolean | `@${string}`,
	/** The user's list of referrals. */
	referrals?:ValueTypes["Referral"],
	/** Includes the user's current payment plan, and what features they have access to as part of that. */
	subscription?:ValueTypes["Subscription"],
	/** Return the subscription fee schedule text dynamically for the activate screen https://brigit.atlassian.net/browse/ENG-9573. */
	subscriptionFeeBillingScheduleText?:boolean | `@${string}`,
subscriptionScheduleText?: [{	level?: ValueTypes["SubscriptionTierLevel"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
	/** The user's underwriting details - holds details around the underwriting report such as if a user is eligible for an Instant Cash advance */
	underwritingDetails?:ValueTypes["UnderwritingDetails"],
	/** The user's Credit Builder debt persona as a result of user engagement quiz */
	userCreditBuilderDebtPersona?:ValueTypes["UserDebtPersona"],
	/** The user's debt persona as a result of user engagement quiz */
	userDebtPersona?:ValueTypes["UserDebtPersona"],
	/** The user's consent prompts to display */
	userPrompts?:ValueTypes["UserPrompts"],
		__typename?: boolean | `@${string}`
}>;
	/** User's debt persona information as a result of user engagement quiz */
["UserDebtPersona"]: AliasType<{
	/** The user's debt persona */
	debtPersonaType?:boolean | `@${string}`,
	/** The ID of the quiz */
	quizId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserImpression"]: AliasType<{
	/** Duration in milliseconds */
	durationMs?:boolean | `@${string}`,
	/** UI element tracked */
	elementName?:boolean | `@${string}`,
	/** Impression end time */
	endedAt?:boolean | `@${string}`,
	/** Foreign keys to other data useful for understanding the impression */
	eventForeignKeys?:boolean | `@${string}`,
	/** Impression ID */
	impressionId?:boolean | `@${string}`,
	/** Screen of the tracked event */
	screenName?:boolean | `@${string}`,
	/** Impression start time */
	startedAt?:boolean | `@${string}`,
	/** User ID */
	userId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents a consent experience the user must undergo to opt into a certain feature set */
["UserPrompt"]: AliasType<{
	/** The 0 indexed display rank. Lower the number, the 'higher' the priority */
	displayRank?:boolean | `@${string}`,
	/** The user prompt type */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A list of user prompts to display consent experiences for certain features */
["UserPrompts"]: AliasType<{
	/** List of prompt objects containing the prompt type and the display priority */
	prompts?:ValueTypes["UserPrompt"],
		__typename?: boolean | `@${string}`
}>;
	/** Represents the details for a installment loan */
["details"]: AliasType<{
	/** Is auto pay enabled or disabled */
	autoPayStatus?:boolean | `@${string}`,
	/** Field that denotes if a user should be able to see the ability to make a 'manual' catchup payment on the FE */
	catchupEligibility?:boolean | `@${string}`,
	/** Name of bank account installment loan was disbursed to */
	disbursalBankAccountName?:boolean | `@${string}`,
	/** Date loan was disbursed */
	disbursalDate?:boolean | `@${string}`,
	/** Status of loan disbursal */
	disbursalStatus?:boolean | `@${string}`,
	/** Estimated delivery for the principal payment credit if loan is not delivered */
	estimatedDeliveryDateForPrincipalCredit?:boolean | `@${string}`,
	/** List of late fees accrued with a fee amount and the date it accrued */
	lateFees?:ValueTypes["FrontendOverduePayment"],
	/** Late fees due in cents */
	lateFeesDue?:boolean | `@${string}`,
	/** Next scheduled principal payment if it exists */
	nextScheduledPrincipalPayment?:ValueTypes["FrontendPrincipalPayment"],
	/** Field that denotes if a user should be able to see the ability to make a 'manual' prepay or pay-off payment on the FE */
	prepayAndPayoffEligibility?:boolean | `@${string}`,
	/** Principal amount that is past due and overdue in cents */
	principalPaymentOutstanding?:boolean | `@${string}`,
	/** Remaining due principal payments on the schedule that haven't been processed yet */
	remainingDuePayments?:ValueTypes["FrontendPrincipalPayment"],
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalPaymentsInCents?:boolean | `@${string}`,
	/** Total paid amount so far in cents */
	totalPrincipalAmountInCents?:boolean | `@${string}`,
	/** Installment Loan Id */
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BankProvider"]:BankProvider;
	["CampaignPromptType"]:CampaignPromptType;
	["ChangeSubscriptionTierOutcome"]:ChangeSubscriptionTierOutcome;
	["CopyColor"]:CopyColor;
	["CreatePayrollAccountOutcome"]:CreatePayrollAccountOutcome;
	["CreditBuilderEnrollmentIneligibilityReason"]:CreditBuilderEnrollmentIneligibilityReason;
	/** The type of enrollment that currently applies to the user. */
["CreditBuilderEnrollmentType"]:CreditBuilderEnrollmentType;
	["CreditBuilderPopulationType"]:CreditBuilderPopulationType;
	["CreditLimitBoostStatus"]:CreditLimitBoostStatus;
	["CreditMonitoringEnrollmentStatus"]:CreditMonitoringEnrollmentStatus;
	["CreditRating"]:CreditRating;
	["DayOfWeek"]:DayOfWeek;
	["DebtPersonaType"]:DebtPersonaType;
	["DeleteBankLoginOutcome"]:DeleteBankLoginOutcome;
	["DisableDirectDepositOutcome"]:DisableDirectDepositOutcome;
	["EducationLevel"]:EducationLevel;
	["EmploymentPayFrequency"]:EmploymentPayFrequency;
	["EmploymentStatus"]:EmploymentStatus;
	["ExperimentName"]:ExperimentName;
	["FrontEndCreditMonitoringEnrollment"]:FrontEndCreditMonitoringEnrollment;
	["FrontendAccountApprovalState"]:FrontendAccountApprovalState;
	["FrontendBankConnectionStatus"]:FrontendBankConnectionStatus;
	["FrontendBankInstitution"]:FrontendBankInstitution;
	["FrontendCBScheduleVersionReason"]:FrontendCBScheduleVersionReason;
	["FrontendCBScheduleVersionStatus"]:FrontendCBScheduleVersionStatus;
	["FrontendCreditBuilderEnrollmentStatus"]:FrontendCreditBuilderEnrollmentStatus;
	["FrontendCreditBuilderPaymentRetryStrategyType"]:FrontendCreditBuilderPaymentRetryStrategyType;
	["FrontendCreditBuilderReminderType"]:FrontendCreditBuilderReminderType;
	["FrontendCreditBuilderSweepStakesQualificationType"]:FrontendCreditBuilderSweepStakesQualificationType;
	["FrontendCreditBuilderTransactionSource"]:FrontendCreditBuilderTransactionSource;
	["FrontendCreditBuilderTransactionStatus"]:FrontendCreditBuilderTransactionStatus;
	["FrontendCreditBuilderTransactionType"]:FrontendCreditBuilderTransactionType;
	["FrontendCreditLimitBoostStatus"]:FrontendCreditLimitBoostStatus;
	["FrontendCreditRiskState"]:FrontendCreditRiskState;
	["FrontendDebitCardStatus"]:FrontendDebitCardStatus;
	["FrontendInitiateCreditDrawStatus"]:FrontendInitiateCreditDrawStatus;
	["FrontendInstallmentLoanChargePolicy"]:FrontendInstallmentLoanChargePolicy;
	["FrontendInstallmentLoanPaymentStrategy"]:FrontendInstallmentLoanPaymentStrategy;
	["FrontendInstallmentLoansAccountCreationStatus"]:FrontendInstallmentLoansAccountCreationStatus;
	["FrontendInstallmentLoansAutoPay"]:FrontendInstallmentLoansAutoPay;
	["FrontendInstallmentLoansCurrentStatus"]:FrontendInstallmentLoansCurrentStatus;
	["FrontendInstallmentLoansDisbursalStatus"]:FrontendInstallmentLoansDisbursalStatus;
	["FrontendInstallmentLoansKYCDecisionType"]:FrontendInstallmentLoansKYCDecisionType;
	["FrontendInstallmentLoansManualPaymentEligibilityStatus"]:FrontendInstallmentLoansManualPaymentEligibilityStatus;
	["FrontendInstallmentLoansPayFrequency"]:FrontendInstallmentLoansPayFrequency;
	["FrontendInstallmentLoansPaymentOutcome"]:FrontendInstallmentLoansPaymentOutcome;
	["FrontendInstallmentLoansStatus"]:FrontendInstallmentLoansStatus;
	["FrontendInstallmentLoansUpdateAutoPayOutcome"]:FrontendInstallmentLoansUpdateAutoPayOutcome;
	["FrontendLocAccountCreationStatus"]:FrontendLocAccountCreationStatus;
	["FrontendLocAccountStatus"]:FrontendLocAccountStatus;
	["FrontendLocAutopayStatus"]:FrontendLocAutopayStatus;
	["FrontendLocInitiatePaymentStatus"]:FrontendLocInitiatePaymentStatus;
	["FrontendLocKycRequirementStatus"]:FrontendLocKycRequirementStatus;
	["FrontendLocKycUnfulfillmentReasons"]:FrontendLocKycUnfulfillmentReasons;
	["FrontendLocMinPaymentStatus"]:FrontendLocMinPaymentStatus;
	["FrontendLocPaymentAttemptType"]:FrontendLocPaymentAttemptType;
	["FrontendLocUnderwritingStatus"]:FrontendLocUnderwritingStatus;
	["FrontendLocUserInitiatedPaymentChargeType"]:FrontendLocUserInitiatedPaymentChargeType;
	["FrontendMobileVersionSupportType"]:FrontendMobileVersionSupportType;
	["FrontendOngoingRefreshStatus"]:FrontendOngoingRefreshStatus;
	["FrontendPaymentAuthFlow"]:FrontendPaymentAuthFlow;
	["FrontendPaymentStatus"]:FrontendPaymentStatus;
	["FrontendPayrollAccountConnectionStatus"]:FrontendPayrollAccountConnectionStatus;
	["FrontendReturnType"]:FrontendReturnType;
	["FrontendSampleKeyType"]:FrontendSampleKeyType;
	["HeroIconSize"]:HeroIconSize;
	["IdentityProtectionEnrollmentStatus"]:IdentityProtectionEnrollmentStatus;
	["ImpressionableElement"]:ImpressionableElement;
	["InputSalaryDataOutcome"]:InputSalaryDataOutcome;
	["KeyboardType"]:KeyboardType;
	["LoanPurpose"]:LoanPurpose;
	["LoanTimelineStatus"]:LoanTimelineStatus;
	["LoanTimelineType"]:LoanTimelineType;
	["LoginFailureReason"]:LoginFailureReason;
	["MarkNotificationCenterEntriesViewedOutcome"]:MarkNotificationCenterEntriesViewedOutcome;
	["MarketplaceBadgeType"]:MarketplaceBadgeType;
	["MarketplaceFeature"]:MarketplaceFeature;
	["ModelRank"]:ModelRank;
	["NonRepaymentReason"]:NonRepaymentReason;
	["OneTimePaymentOutcome"]:OneTimePaymentOutcome;
	["OngoingRefreshStatus"]:OngoingRefreshStatus;
	["OverdraftLikelihood"]:OverdraftLikelihood;
	["PayFrequency"]:PayFrequency;
	["PayrollAccountConnectionStatus"]:PayrollAccountConnectionStatus;
	["PayrollAccountFetcherOutcome"]:PayrollAccountFetcherOutcome;
	["PayrollProvider"]:PayrollProvider;
	["PersistedIncomeStatus"]:PersistedIncomeStatus;
	["PremiumUpsellCardVariant"]:PremiumUpsellCardVariant;
	["Product"]:Product;
	["ProductSubType"]:ProductSubType;
	["PromptResponseType"]:PromptResponseType;
	["PropertyStatus"]:PropertyStatus;
	["ProtectionMediumType"]:ProtectionMediumType;
	["ProtectionStatusV4"]:ProtectionStatusV4;
	["QuizFeedbackType"]:QuizFeedbackType;
	["QuizName"]:QuizName;
	["QuizStepType"]:QuizStepType;
	["RecentScoreChangeText"]:RecentScoreChangeText;
	["RegisterBankLoginMaintainingExistingLoginOutcome"]:RegisterBankLoginMaintainingExistingLoginOutcome;
	["RepayAdvanceOutcome"]:RepayAdvanceOutcome;
	["RequestManualDisbursalOutcome"]:RequestManualDisbursalOutcome;
	["ResendAuthVerificationCodeOutcome"]:ResendAuthVerificationCodeOutcome;
	["SalaryAggregateFilterCategory"]:SalaryAggregateFilterCategory;
	["SamplingTrigger"]:SamplingTrigger;
	["ScheduleCreditBuilderSameDayManualPaymentOutcome"]:ScheduleCreditBuilderSameDayManualPaymentOutcome;
	["ScoreChangeCallToAction"]:ScoreChangeCallToAction;
	["SubmitUserPromptOutcome"]:SubmitUserPromptOutcome;
	["SubscriptionFeeSchedule"]:SubscriptionFeeSchedule;
	["SubscriptionStatus"]:SubscriptionStatus;
	["SubscriptionTierExperimentName"]:SubscriptionTierExperimentName;
	["SubscriptionTierLevel"]:SubscriptionTierLevel;
	["SwitchProtectedLoginToExistingLoginOutcome"]:SwitchProtectedLoginToExistingLoginOutcome;
	["SwitchProtectedLoginToExistingNonConnectedLoginOutcome"]:SwitchProtectedLoginToExistingNonConnectedLoginOutcome;
	["TablessMarketplaceCategoryType"]:TablessMarketplaceCategoryType;
	["TextFormatter"]:TextFormatter;
	["TextInputValidators"]:TextInputValidators;
	["TextInputWarning"]:TextInputWarning;
	["TrackNewImpressionsOutcome"]:TrackNewImpressionsOutcome;
	["TrackNotificationCenterEntryClickOutcome"]:TrackNotificationCenterEntryClickOutcome;
	["UXPromptResponseType"]:UXPromptResponseType;
	["UnderwritingCategory"]:UnderwritingCategory;
	["UnderwritingRule"]:UnderwritingRule;
	["UpdateBankAccountJointStatusOutcome"]:UpdateBankAccountJointStatusOutcome;
	["UserFlag"]:UserFlag;
	["UserFriendlyCardNetwork"]:UserFriendlyCardNetwork;
	/** The type of prompt a user will see */
["UserPromptType"]:UserPromptType;
	/** java.math.BigDecimal */
["BigDecimal"]:unknown;
	/** A calendar day, in ISO-8601 "yyyy-MM-dd" format */
["Date"]:unknown;
	/** Long type */
["Long"]:unknown;
	/** UUID V4 format */
["UUID"]:unknown
  }

export type ResolverInputTypes = {
    ["LoginUnion"]: AliasType<{
	LoginFailure?:ResolverInputTypes["LoginFailure"],
	LoginSuccess?:ResolverInputTypes["LoginSuccess"],
		__typename?: boolean | `@${string}`
}>;
	["RegisterOneTimeCardUnion"]: AliasType<{
	RegisterOneTimeCardInvalidInput?:ResolverInputTypes["RegisterOneTimeCardInvalidInput"],
	RegisterOneTimeCardSuccess?:ResolverInputTypes["RegisterOneTimeCardSuccess"],
		__typename?: boolean | `@${string}`
}>;
	["AccountConnectedResponseFetcher"]: AliasType<{
	/** The provider ID of the connected payroll account */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ActiveBankLogins"]: AliasType<{
	/** List of active logins for user */
	logins?:ResolverInputTypes["FrontendBankLogin"],
		__typename?: boolean | `@${string}`
}>;
	["ApprovedLoanAmounts"]: AliasType<{
	/** Approved loan amounts for user - used for frontend display purposes */
	approvedLoanAmounts?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details about the user's auto advance configuration */
["AutoAdvanceDetails"]: AliasType<{
	/** The amount of the user's auto advance */
	amount?:boolean | `@${string}`,
	/** The estimated delivery date if an advance was taken today */
	deliveryDate?:boolean | `@${string}`,
	/** The estimated due date if an advance was taken today */
	dueDate?:boolean | `@${string}`,
	/** Whether the user has auto advances enabled for disbursal when the user's balance is low */
	isAutoEnabled?:boolean | `@${string}`,
	/** Whether the user is due to receive an auto advance */
	willAutoDisburse?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AverageScoreChangeDetailsFetcher"]: AliasType<{
	/** An Optional<Integer> representing the average score change for this score bucket and sample period. */
	averageScoreChange?:boolean | `@${string}`,
	/** The ending date the average was computed. */
	samplePeriodEnd?:boolean | `@${string}`,
	/** The starting date the average was computed. */
	samplePeriodStart?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Predictions based on the user's linked bank account such as their income sources and likelihood to overdraft. */
["BankAccountPrediction"]: AliasType<{
	/** List of the user's income sources based on their linked bank account. */
	incomeSources?:ResolverInputTypes["Income_32_Source"],
	/** The user's next predicted payday based on their linked bank account. */
	nextPayday?:boolean | `@${string}`,
	/** The likelihood the user's balance will drop to 0 soon. */
	overdraftLikelihood?:boolean | `@${string}`,
	/** The date we predict the user's balance to drop to 0. */
	zeroDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BankConnection"]: AliasType<{
	/** The protected bank account mask */
	accountMask?:boolean | `@${string}`,
	/** The protected bank account's available balance */
	balanceAvailable?:boolean | `@${string}`,
	/** The protected bank account's current balance */
	balanceCurrent?:boolean | `@${string}`,
	/** Bank login ID */
	bankLoginId?:boolean | `@${string}`,
	/** Provider associated with bank connection (Plaid or Capital One) */
	bankProvider?:boolean | `@${string}`,
	/** Checking accounts that belong to the user protected login */
	checkingAccounts?:ResolverInputTypes["FrontendAccountWithCard"],
	/** The bank institution details for the user's current bank connection. */
	institution?:ResolverInputTypes["BankInstitution"],
	/** The last time we call the bank data provider API */
	lastUpdatedTimestamp?:boolean | `@${string}`,
	/** The protected bank account's payment auth flow */
	paymentAuthFlow?:boolean | `@${string}`,
	/** The protected bank account ID */
	protectedAccountId?:boolean | `@${string}`,
	/** Bank connection status. */
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BankInstitution"]: AliasType<{
	/** The protected bank account's institution id. Only populated for Plaid users. */
	id?:boolean | `@${string}`,
	/** The protected bank account's institution name */
	institutionName?:boolean | `@${string}`,
	/** The institution logo URL */
	logoUrl?:boolean | `@${string}`,
	/** The bank institution type */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChangeSubscriptionTierResponse"]: AliasType<{
	/** The result of the attempt to change the user's subscription tier. */
	outcome?:boolean | `@${string}`,
	/** The user's subscription after the request is completed. If the outcome is not SUCCESS, the subscription will be the same as it was previously. */
	updatedSubscription?:ResolverInputTypes["Subscription"],
		__typename?: boolean | `@${string}`
}>;
	["CompleteQuizFetcher"]: AliasType<{
	/** The id of the quiz */
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreatePayrollAccountResponse"]: AliasType<{
	/** The provider ID of the payroll account created */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreatePayrollAccountResponseV2"]: AliasType<{
	/** The outcome of creating the user's payroll account. */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateTargetDepositDestinationResponse"]: AliasType<{
	/** Encrypted version of the user's target deposit destination */
	encryptedDDSConfig?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details on which type of enrollment is applicable to the user and any reasons they are ineligible for that enrollment type. */
["CreditBuilderEnrollmentEligibility"]: AliasType<{
	/** Whether the user is eligible for the type of enrollment. If this is false, the ineligibilityDetails will have the reasons they are ineligible. */
	eligible?:boolean | `@${string}`,
	/** The type of enrollment that the user's eligibility was checked for. */
	enrollmentType?:boolean | `@${string}`,
	/** The details on why the user is ineligible for the type of enrollment. */
	ineligibilityDetails?:ResolverInputTypes["CreditBuilderEnrollmentIneligibilityDetails"],
		__typename?: boolean | `@${string}`
}>;
	/** Information on a reason that a user is ineligible for a given enrollment type. */
["CreditBuilderEnrollmentIneligibilityDetails"]: AliasType<{
	/** A textual description of the reason that can be displayed to the user. */
	description?:boolean | `@${string}`,
	/** The reason the user is ineligible. */
	reason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Contains global configuration data for the Credit Builder system. */
["CreditBuilderGlobalConfiguration"]: AliasType<{
	/** The USA states in which Credit Builder is not available. */
	unsupportedStates?:ResolverInputTypes["UsaState"],
		__typename?: boolean | `@${string}`
}>;
	/** A credit builder installment loan is a secured loan which the user has taken out to build their credit. The loan contains monthly payments which are reported to the bureaus for credit score calculation. */
["CreditBuilderInstallmentLoan"]: AliasType<{
	/** User's external contributions minus disbursals */
	availableSavingsBalance?:boolean | `@${string}`,
	/** The charge policy configured for the credit builder loan. This could either be AUTO, meaning standard payments are automatically deducted from the user's external account or MANUAL, meaning the user would need to initiate standard payments themselves. */
	chargePolicy?:boolean | `@${string}`,
	/** The date which the Credit Builder loan was closed on. If the loan was never closed, this should be empty. */
	closedOn?:boolean | `@${string}`,
	/** The disbursals taken against the loans savings balance. */
	disbursals?:ResolverInputTypes["CreditBuilderTransaction"],
	/** The date which the Credit Builder loan is expected to close if all payments are completed successfully. */
	expectedClosedDate?:boolean | `@${string}`,
	/** The numeric value for the day of the month the first installment payment is due. */
	expectedDayOfMonthForBilling?:boolean | `@${string}`,
	/** The amount user has set to debit their external bank account every month to pay off the installment in dollars. */
	externalMonthlyContributionAmountInDollars?:boolean | `@${string}`,
	/** A List of the credit builder installment loan payment schedule dates and the amount due */
	installmentLoanPaymentSchedule?:ResolverInputTypes["InstallmentLoanPaymentSchedule"],
	/** The amount user has set to debit from the Brigit restricted savings account every month to pay off the installment in dollars. */
	internalMonthlyContributionAmountInDollars?:boolean | `@${string}`,
	/** The date which the Credit Builder loan was opened. */
	openedOn?:boolean | `@${string}`,
	/** Details about past due payments, if applicable. */
	pastDuePayment?:ResolverInputTypes["PastDuePayment"],
	/** The payment schedule strategy stored in the cb enrollments table for the credit builder loan. If the payment strategy is empty then it calculates the strategy on the fly and it could either be MONTHLY, meaning a user had no income and they selected a specific day of the month to make CB payments or INCOME, where a CB payment due date corresponds with a users payments */
	paymentStrategyForSchedule?:boolean | `@${string}`,
	/** The payments that have been made towards the loan. */
	payments?:ResolverInputTypes["CreditBuilderTransaction"],
	/** Details about possible pre-payments user can make toward their CB account. */
	prePayment?:ResolverInputTypes["PrePayment"],
proposedDraftInstallmentLoanPaymentSchedule?: [{	reason: ResolverInputTypes["FrontendCBScheduleVersionReason"]},ResolverInputTypes["InstallmentLoanPaymentSchedule"]],
	/** A list of reminders for the user's installment loan account. This list may include upcoming scheduled payment, past due balance,  and same day manual payment reminders. */
	reminders?:ResolverInputTypes["CreditBuilderReminder"],
	/** Payment details that are populated when user has manual payment method and payment is due today */
	sameDatePayment?:ResolverInputTypes["SameDayPayment"],
	/** Details regarding user's CB savings */
	savingsDetails?:ResolverInputTypes["CreditBuilderSavingsDetails"],
	/** Details regarding user's credit score */
	scoreDetails?:ResolverInputTypes["CreditBuilderScoreDetails"],
	/** The fixed number of months for the Credit Builder loan term. */
	termInMonths?:boolean | `@${string}`,
	/** The total loan amount for the given Credit Builder enrollment term. */
	totalLoanAmountInDollars?:boolean | `@${string}`,
	/** The total monthly amount between external and savings contributions that goes to paying off the installment in dollars. */
	totalMonthlyPaymentAmountInDollars?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A reminder associated with the user's open installment loan account. */
["CreditBuilderReminder"]: AliasType<{
	/** The description of the reminder to show the user. */
	description?:boolean | `@${string}`,
	/** The type of reminder */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details about user's credit score */
["CreditBuilderSavingsDetails"]: AliasType<{
	/** The user’s all time total savings across all CB accounts ever (how much the user contributed to all of their Credit Builder account(s), regardless of any subsequent disbursals */
	allTimeSavings?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details about user's credit score */
["CreditBuilderScoreDetails"]: AliasType<{
	/** If CB account is open, delta is the difference between first score since first ever CB enrollment and latest credit score on file. If CB account is closed, delta is the difference between first score since first ever CB enrollment and last score before a user closed their CB account. */
	allTimeScoreDelta?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A transaction that was processed for a Credit Builder installment loan. */
["CreditBuilderTransaction"]: AliasType<{
	/** The amount of the transaction in dollars. */
	amountInDollars?:boolean | `@${string}`,
	/** The due date of the payment */
	dueOn?:boolean | `@${string}`,
	/** The unique id of the transaction. */
	installmentLoanPaymentId?:boolean | `@${string}`,
	/** The source account type from which the transaction originated. */
	source?:boolean | `@${string}`,
	/** The status of the transaction at the current point in time. */
	status?:boolean | `@${string}`,
	/** The type of the transaction. Either a payment or a disbursal. */
	type?:boolean | `@${string}`,
	/** The time that the transaction was last updated. */
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** User details related to credit builder */
["CreditBuilderUserDetails"]: AliasType<{
	/** The user's Credit Builder population type to determine whether to show CB related content. */
	populationType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreditLimitBoostDetails"]: AliasType<{
	/** The amount of the credit limit boost in dollars. */
	boostAmount?:boolean | `@${string}`,
	/** Whether the boost is available to be used for a loan which means the boost status is equal to OFFERED or ACCEPTED. */
	boostAvailable?:boolean | `@${string}`,
	/** List of advance options that apply part or all of the credit limit boost. */
	boostedAdvanceOptions?:boolean | `@${string}`,
	/** User's max advance amount plus their credit limit boost. */
	boostedMaxLoanAmount?:boolean | `@${string}`,
	/** Unique ID of the credit limit boost */
	creditLimitBoostId?:boolean | `@${string}`,
	/** Current status of the credit limit boost */
	creditLimitBoostStatus?:boolean | `@${string}`,
	/** Whether the boost should be offered to the user which means the boost status equals OFFERED. */
	shouldOfferBoost?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A credit monitoring enrollment is a user's relationship with Brigit's Credit Monitoring product, which maintains a history of their credit scores while they are enrolled. */
["CreditMonitoringEnrollment"]: AliasType<{
	/** The credit scores that Brigit has collected for the user while they have been enrolled in credit monitoring. Scores are sorted by date, starting with the earliest scores. */
	creditScores?:ResolverInputTypes["CreditScore"],
	/** Describes whether a user is currently, or has been, enrolled in credit monitoring with Brigit. */
	enrollmentStatus?:boolean | `@${string}`,
	/** Representation of the user's recent credit score delta */
	recentScoreChange?:ResolverInputTypes["RecentScoreChangeFetcher"],
	/** Representation of how the user's credit score journey has progressed since starting credit builder relative to their peer group. */
	scoreMarker?:ResolverInputTypes["CreditScoreMarkerFetcher"],
		__typename?: boolean | `@${string}`
}>;
	/** A credit monitoring enrollment is a user's relationship with Brigit's Credit Monitoring product, which maintains a history of their credit scores while they are enrolled. */
["CreditMonitoringUser"]: AliasType<{
	authToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A credit score is a numerical rating representing the perceived creditwothiness of an individual, as evaluated by one of the major US credit bureaus on a particular date. */
["CreditScore"]: AliasType<{
	/** The date that this score was calculated, in yyyy-MM-dd format. */
	date?:boolean | `@${string}`,
	/** The numerical value of the credit score, ranging from about 250 for a poor score to about 900 for a great score. */
	score?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreditScoreMarkerFetcher"]: AliasType<{
	/** The score bucket that the user falls into (e.g. 300-349, 350-399, etc). */
	averageScoreChangeDetails?:ResolverInputTypes["AverageScoreChangeDetailsFetcher"],
	/** A number representing the days since latest CB enrollment. */
	days?:boolean | `@${string}`,
	/** A number representing the period the user is in (e.g. 1, 2, 3, ..., 24). */
	period?:boolean | `@${string}`,
	/** The score bucket that the user falls into (e.g. 300-349, 350-399, etc). */
	scoreBucket?:ResolverInputTypes["ScoreBucketFetcher"],
	/** The value of the users score change since opening their CB account. */
	userScoreChange?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeleteBankLogin"]: AliasType<{
	/** An Enum denoting if the outcome of deleting the bank login was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeletePayrollAccountResponse"]: AliasType<{
	/** The ID of the payroll account being deleted */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DisableDirectDepositsResponse"]: AliasType<{
	/** The outcome of disabling the user's direct deposit. */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DisablePayrollAccountResponse"]: AliasType<{
	/** The provider ID of the payroll account being disabled */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A discount is a temporary reduction in the price of a subscription. */
["Discount"]: AliasType<{
	/** If present, contains additional text to show users to explain the discount, such as the date range for which it applies, how it compares to the typical price, or any other information the user should know. */
	disclaimer?:boolean | `@${string}`,
	/** The price, in US dollars, that will be charged for subscription payments that this discount applies to. */
	discountedAmount?:boolean | `@${string}`,
	/** The estimated dates of upcoming subscription payments that this discount will be applied to. */
	discountedPaymentDates?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Fetcher for Dynamic Promo Card offers */
["DynamicPromoCards"]: AliasType<{
	/** Affiliate link URL for the offer */
	affiliateLinkUrl?:boolean | `@${string}`,
	/** List of background colors for the offer */
	backgroundColors?:boolean | `@${string}`,
	/** Copy color for the offer */
	copyColor?:boolean | `@${string}`,
	/** Call-to-action copy for the offer */
	ctaCopy?:boolean | `@${string}`,
	/** Description of the offer */
	description?:boolean | `@${string}`,
	/** URL for the offer icon */
	iconUrl?:boolean | `@${string}`,
	/** Unique identifier for the offer */
	id?:boolean | `@${string}`,
	/** URL for the offer logo */
	logoUrl?:boolean | `@${string}`,
	/** Name of the offer */
	offerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FilteredSalaryData"]: AliasType<{
	/** The filter used to aggregate the salary data */
	filter?:boolean | `@${string}`,
	/** The average salary for users specific to this filter */
	salary?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Debit card information */
["FrontendAccountWithCard"]: AliasType<{
	/** The bank account ID associated with the card */
	bankAccountId?:boolean | `@${string}`,
	/** Associated debit card info */
	card?:ResolverInputTypes["FrontendDebitCardInfo"],
	/** Account current balance */
	currentBalance?:boolean | `@${string}`,
	/** Whether or not account is eligible to be selected as the protected account */
	isEligibleProtectedAccount?:boolean | `@${string}`,
	/** Account joint status */
	isJoint?:boolean | `@${string}`,
	/** Account removal status */
	isRemoved?:boolean | `@${string}`,
	/** Account mask */
	mask?:boolean | `@${string}`,
	/** The name associated with the card */
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Bank account associated with bank login */
["FrontendBankAccount"]: AliasType<{
	/** Available balance */
	availableBalance?:boolean | `@${string}`,
	/** Current balance */
	currentBalance?:boolean | `@${string}`,
	/** Bank account ID */
	id?:boolean | `@${string}`,
	/** Is joint */
	isJoint?:boolean | `@${string}`,
	/** Boolean representing whether login has been identified as the user's recommended protected account. Refer to: https://brigit.atlassian.net/browse/ENG-13002 */
	isRecommendedProtectedAccount?:boolean | `@${string}`,
	/** Bank account mask */
	mask?:boolean | `@${string}`,
	/** Bank account name */
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FrontendBankLogin"]: AliasType<{
	/** Checking accounts associated with login */
	bankAccounts?:ResolverInputTypes["FrontendBankAccount"],
	/** Time bank login was created */
	createdAt?:boolean | `@${string}`,
	/** Bank login ID */
	id?:boolean | `@${string}`,
	/** Institution details */
	institution?:ResolverInputTypes["BankInstitution"],
	/** Institution name */
	institutionName?:boolean | `@${string}`,
	/** Boolean representing whether login has been identified as the user's recommended protected login. Refer to: https://brigit.atlassian.net/browse/ENG-13002 */
	isRecommendedProtectedLogin?:boolean | `@${string}`,
	/** Bank connection status. */
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FrontendDebitCardInfo"]: AliasType<{
	/** Card block status */
	blockStatus?:boolean | `@${string}`,
	/** Card's nick name and normally is it's last 4 */
	cardNickname?:boolean | `@${string}`,
	/** Card's expiration date */
	expirationDate?:boolean | `@${string}`,
	/** Card network such as Visa, Mastercard, etc */
	network?:boolean | `@${string}`,
	/** Payment method ID associated with the card */
	paymentMethodId?:boolean | `@${string}`,
	/** Reflects micro-deposit verification and status of government id. */
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FrontendExperimentVariantAssignment"]: AliasType<{
	/** Flag to indicate whether the variant returned is the control flow. If the variant is either the
'Control' or 'Holdout', this will return as true. */
	isControlFlow?:boolean | `@${string}`,
	/** Name of the variant assigned for the sample key. */
	variantName?:boolean | `@${string}`,
	/** Value associated with the variant assigned for the sample key. */
	variantValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents a missed item which consists of all payments due on the same scheduled due date that are outstanding */
["FrontendMissedItem"]: AliasType<{
	/** late fee in cents */
	lateFeeAmountInCents?:boolean | `@${string}`,
	/** original principal payment in cents */
	principalPaymentAmountInCents?:boolean | `@${string}`,
	/** Original scheduled date in iso format */
	scheduledDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents a late fee with the pairing of the fee amount and date it was accrued (the original principal payment date) */
["FrontendOverduePayment"]: AliasType<{
	/** Date accrued in iso format */
	dateAccrued?:boolean | `@${string}`,
	/** fee in cents */
	feeAmountInCents?:boolean | `@${string}`,
	/** original principal payment in cents */
	principalPaymentAmountInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents the pairing of the payment amount and date due for a user */
["FrontendPrincipalPayment"]: AliasType<{
	/** Monthly payment in cents */
	amountInCents?:boolean | `@${string}`,
	/** Payment date in iso format */
	paymentDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Report underwriting checks */
["FrontendUnderwritingCheck"]: AliasType<{
	/** Underwriting rule category */
	category?:boolean | `@${string}`,
	/** Whether user has fulfilled rule or not */
	isFulfilled?:boolean | `@${string}`,
	/** Underwriting description based on if rule is fulfilled or not */
	reason?:boolean | `@${string}`,
	/** Underwriting rule */
	rule?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Controls a customizable banner we may display to users on the home screen when we want to get out some message to users with a quick turnaround. */
["HomeScreenBanner"]: AliasType<{
	/** Cta to show with the banner */
	callToActionText?:boolean | `@${string}`,
	/** Link url that we will open when a user clicks on the banner */
	linkUrl?:boolean | `@${string}`,
	/** Main message associated with banner. */
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Frequently Asked Questions related to IC Activation. */
["ICActivationFAQs"]: AliasType<{
	/** Description of the frequently asked question. */
	description?:boolean | `@${string}`,
	/** Positional id returned for front end ordering purposes */
	id?:boolean | `@${string}`,
	/** Title of the Frequently Asked Question. */
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Experiments related to Instant Cash */
["ICExperimentProperties"]: AliasType<{
	/** Whether we should display the upsell screen or not based on the user's flag and tier history */
	shouldDisplayUpsell?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["IncomeProfile"]: AliasType<{
	/** Primary filter used to organize salary data */
	filterOne?:boolean | `@${string}`,
	/** Possible values for filter one */
	filterOneValues?:boolean | `@${string}`,
	/** Secondary filter used to organize salary data */
	filterTwo?:boolean | `@${string}`,
	/** Possible values for filter two */
	filterTwoValues?:boolean | `@${string}`,
	/** List of data points based on the user's income */
	salaryAggregators?:ResolverInputTypes["SalaryAggregators"],
		__typename?: boolean | `@${string}`
}>;
	/** An income source contains the user's paycheck and payday details based on their linked bank account. */
["Income_32_Source"]: AliasType<{
	/** The average amount of money the user earns from this income source. */
	amountMean?:boolean | `@${string}`,
	/** For users who get paid twice a month, their payday may be pinned to specific dates such as the 1st and 15th or the 15th and 30th. Day of month 1 refers to the first date that's pinned for the user's payday. The day of month 1 will be 0 if the user doesn't have a paycheck that follows this pattern. */
	dayOfMonth1?:boolean | `@${string}`,
	/** For users who get paid twice a month, their payday may be pinned to specific dates such as the 1st and 15th or the 15th and 30th. Day of month 2 refers to the second date that's pinned for the user's payday. The day of month 2 will be 0 if the user doesn't have a paycheck that follows this pattern. */
	dayOfMonth2?:boolean | `@${string}`,
	/** Day of week is the specific day the user's payday is on e.g. Thursday. If there's no specific day for that user's payday, Monday will be used as the default. */
	dayOfWeek?:boolean | `@${string}`,
	/** Early payday offset is the number of days the user's income will be deposited into their account early. */
	earlyPayOffset?:boolean | `@${string}`,
	/** Is end of month refers to whether or not the user's payday will fall at the end of the month since not all months have the same number of days. */
	endOfMonth?:boolean | `@${string}`,
	/** The frequency refers to how often the user gets paid such as biweekly, weekly, monthly, etc. */
	frequency?:boolean | `@${string}`,
	/** This is true or false depending on if the user's payday is adjusted if it falls on a holiday. If so, then it's true; otherwise, false. */
	holidayAdjustNext?:boolean | `@${string}`,
	/** The id is the unique id for this specific user's income source. */
	id?:boolean | `@${string}`,
	/** The income payer ID is similar to a bank memo for the transaction. If there are similar bank memos, they will be fuzzy matched to produce a singular income payer ID. */
	incomePayerId?:boolean | `@${string}`,
	/** The period mean refers to the average number of days between paychecks for this income source. This value is typically set for irregular income sources. 0 is set as the default value if this income source doesn't have a period mean. */
	periodMean?:boolean | `@${string}`,
	/** The rejection status is the user-friendly description for why the income source could not be verified. If the income source can be verified, then the rejection status will be an empty string. */
	rejectionStatus?:boolean | `@${string}`,
	/** Status refers to the verification status of this income source e.g. VERIFIED, PENDING, UNVERIFIABLE, and BLACKLISTED. */
	status?:boolean | `@${string}`,
	/** Is verified is true or false depending on whether or not someone has verified this income source and confirmed it's legitimate. */
	verified?:boolean | `@${string}`,
	/** Week of month refers to the week that the user's payday is on. For example, 1 would refer to the 1st week of the month. The week of month would equal 0 if there's no specific week the user's payday falls on. */
	weekOfMonth?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitiateCatchUpPaymentForInstallmentLoan"]: AliasType<{
	/** An Enum denoting if the outcome of the catch up payment was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitiateOneTimePayment"]: AliasType<{
	/** An Enum denoting if the outcome of the oneTimePayment was successful or failure */
	oneTimePaymentOutcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitiatePayOffPaymentForInstallmentLoan"]: AliasType<{
	/** An Enum denoting if the outcome of the pay off payment was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitiatePrePaymentForInstallmentLoan"]: AliasType<{
	/** An Enum denoting if the outcome of the pre payment was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InputSalaryData"]: AliasType<{
	/** Outcome denoting if adding the salary data was successful or not */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Credit builder installment loan payment schedule due dates with amount due. */
["InstallmentLoanPaymentSchedule"]: AliasType<{
	/** The amount of the payment in dollars. */
	amountInDollars?:boolean | `@${string}`,
	/** Payment schedule due date */
	paymentDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents the post disbursal details for an installment loan */
["InstallmentLoanPostDisbursalInfo"]: AliasType<{
	/** Name of bank account installment loan was disbursed to */
	disbursalBankAccountName?:boolean | `@${string}`,
	/** Date loan was disbursed */
	disbursalDate?:boolean | `@${string}`,
	/** Status of loan disbursal */
	disbursalStatus?:boolean | `@${string}`,
	/** Estimated delivery for the principal payment credit if loan is not delivered */
	estimatedDeliveryDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details related to both a potential and an existing installment loans user */
["InstallmentLoans"]: AliasType<{
	/** Fetch if a user have an installment loan account or not.  */
	currentLoanStatus?:boolean | `@${string}`,
	/** Fetch current open installment loan details */
	currentOpenInstallmentLoan?:ResolverInputTypes["InstallmentLoansDetails"],
	/** Fetches all installment loans including details for a user */
	details?:ResolverInputTypes["details"],
	/** Fetches the KYC status for a prospective IL user */
	kycDecision?:boolean | `@${string}`,
signedLoanAgreement?: [{	locUuid: string},boolean | `@${string}`],
	/** Evaluates and fetches the loan term details for a prospective IL account */
	termRequestDetails?:ResolverInputTypes["TermRequestDetails"],
	/** Evaluates and fetches the underwriting status for a prospective IL user */
	underwritingDetails?:ResolverInputTypes["LocUnderwritingDetails"],
	/** Fetches the string contents for the proposed loan agreement */
	unsignedProposedLoanAgreement?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents the details for a installment loan */
["InstallmentLoansDetails"]: AliasType<{
	/** Is auto pay enabled or disabled */
	autoPayStatus?:boolean | `@${string}`,
	/** Field that denotes if a user should be able to see the ability to make a and 'manual' payment on the FE */
	manualPaymentEligibilityStatus?:boolean | `@${string}`,
	/** List of missed items */
	missedItems?:ResolverInputTypes["FrontendMissedItem"],
	/** Next scheduled principal payment if it exists */
	nextScheduledPrincipalPayment?:ResolverInputTypes["FrontendPrincipalPayment"],
	/** Post disbursal info */
	postDisbursalInstallmentLoanInfo?:ResolverInputTypes["InstallmentLoanPostDisbursalInfo"],
	/** Remaining due principal payments on the schedule that haven't been processed yet */
	remainingDuePayments?:ResolverInputTypes["FrontendPrincipalPayment"],
	/** Fetches the string contents for the signed loan agreement */
	signedLoanAgreement?:boolean | `@${string}`,
	/** Status of the installment loan */
	status?:boolean | `@${string}`,
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalPaymentsInCents?:boolean | `@${string}`,
	/** Total paid amount so far in cents */
	totalPrincipalAmountInCents?:boolean | `@${string}`,
	/** Installment Loan Id */
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details related to both an existing or unopened line of credit for a user */
["LineOfCredit"]: AliasType<{
	/** Credit line limit details */
	creditLineLimitDetails?:ResolverInputTypes["LineOfCreditLineLimitDetails"],
	/** Returns the user to KYC submission response and unfulfilled reasons if it is present */
	kycRequirementResponse?:ResolverInputTypes["LocRequireKycResponse"],
	/** Checks if it is required for the user to create a KYC submission to progress in onboarding */
	kycRequirementStatus?:boolean | `@${string}`,
	/** The users currently open line of credit account. */
	openAccount?:ResolverInputTypes["LineOfCreditAccount"],
paymentsBreakdown?: [{	creditDrawAmountInCents: ResolverInputTypes["Long"]},ResolverInputTypes["LocPaymentBreakdown"]],
	/** Fetches the user facing product name */
	productName?:boolean | `@${string}`,
	/** Account details for a prospective user */
	prospectiveTermDetails?:ResolverInputTypes["LineOfCreditProspectiveTerms"],
	/** Evaluates and fetches the underwriting status for a prospective LOC user */
	underwritingDetails?:ResolverInputTypes["LocUnderwritingDetails"],
	/** Fetches the string contents for the proposed unsigned loan agreement */
	unsignedProposedUserAgreement?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A line of credit account that a user can draw money from. */
["LineOfCreditAccount"]: AliasType<{
	/** The current status of the account. */
	accountStatus?:boolean | `@${string}`,
	/** The unique UUID that identifies the account. */
	accountUuid?:boolean | `@${string}`,
	activeCreditDraw?:ResolverInputTypes["LineOfCreditDrawFetcher"],
	/** The autopay status of the account. */
	autopayStatus?:boolean | `@${string}`,
	/** The credit draw details for this line of credit account */
	creditDrawDetails?:ResolverInputTypes["LineOfCreditDrawRequestDetailsFetcher"],
	/** The total limit that can be drawn up to in cents. */
	creditLimitInCents?:boolean | `@${string}`,
	/** Fetches the upcoming payment details for the minimum payment. */
	minPaymentDetails?:ResolverInputTypes["LineOfCreditMinPaymentDetailsFetcher"],
	/** The date the account was opened. */
	openedOn?:boolean | `@${string}`,
	/** Total balance owed for fees */
	outstandingAccessFeeBalanceInCents?:boolean | `@${string}`,
	/** Total outstanding principal balance in cents */
	outstandingPrincipalBalanceInCents?:boolean | `@${string}`,
	/** The payment charge type of the account. */
	paymentChargeType?:boolean | `@${string}`,
	/** Historical payments for given LOC account */
	paymentHistory?:ResolverInputTypes["LineOfCreditPaymentHistory"],
	prevDraw?:ResolverInputTypes["LineOfCreditDrawFetcher"],
	/** Contains the list of historical statements and the start + end period for the CURRENT billing cycle / statement period. */
	statementDetails?:ResolverInputTypes["LineOfCreditStatementDetailsFetcher"],
	/** Total balance owed for principal and remainder owed for fees */
	totalBalanceRemainingIncludingFullFee?:boolean | `@${string}`,
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details of an LOC object for line of credit account */
["LineOfCreditDrawFetcher"]: AliasType<{
	/** Disbursal amount */
	disbursalAmountInCents?:boolean | `@${string}`,
	/** When credit draw was disbursed */
	disbursalDate?:boolean | `@${string}`,
	/** When disbursal amount is estimated to arrive to user. Returns empty optional if amount has already been delivered. */
	estimatedDeliveryDate?:boolean | `@${string}`,
	/** Payment status */
	paymentStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details on amount that can be or has been drawn from a line of credit */
["LineOfCreditDrawRequestDetailsFetcher"]: AliasType<{
	/** The amount that is due today */
	amountDueTodayInCents?:boolean | `@${string}`,
	/** The estimated date that this the credit draw will settle */
	estimatedDeliveryDate?:boolean | `@${string}`,
	/** Max amount a user can request for a credit draw - deprecated */
	maxDrawAmountInCents?:boolean | `@${string}`,
	/** Minimum amount a user can request for a credit draw - deprecated */
	minDrawAmountInCents?:boolean | `@${string}`,
	/** The prospective min payment amount for the first payment post this draw. This is different from what lives in the minPaymentDetails */
	prospectiveMinPaymentAmount?:boolean | `@${string}`,
	/** The estimated date that this the credit draw will settle */
	prospectiveMinPaymentDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Contains global configuration data for the Line of credit. */
["LineOfCreditGlobalConfiguration"]: AliasType<{
	/** The USA states in which line of credit is available. */
	supportedStates?:ResolverInputTypes["UsaState"],
		__typename?: boolean | `@${string}`
}>;
	/** Fetches LOC credit limit details for user */
["LineOfCreditLineLimitDetails"]: AliasType<{
	/** Max amount a user can request for a credit draw */
	maxDrawAmountInCents?:boolean | `@${string}`,
	/** Minimum amount a user can request for a credit draw */
	minDrawAmountInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details regarding minimum payment details */
["LineOfCreditMinPaymentDetailsFetcher"]: AliasType<{
	/** Represents the fee amount that a user must make a payment on. */
	feeAmountInCents?:boolean | `@${string}`,
	/** Represents the amount that a user must make a payment on. */
	minPaymentAmountInCents?:boolean | `@${string}`,
	/** Represents the date that a user must make a payment on. */
	minPaymentDate?:boolean | `@${string}`,
	/** If the status is NOT DUE, most of the fields are generated on the fly. If it is due, it is the result of the persisted billing cycle details */
	minPaymentStatus?:boolean | `@${string}`,
	/** Represents the principal amount that a user must make a payment on. */
	principalAmountInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Payment made against a user's LOC account */
["LineOfCreditPayment"]: AliasType<{
	/** Payment amount in cents */
	amountInCents?:boolean | `@${string}`,
	/** Type of payment attempt (manual or automatic) */
	paymentAttemptType?:boolean | `@${string}`,
	/** Date payment was made on */
	paymentDate?:boolean | `@${string}`,
	/** Payment status (SETTLED, RETURNED, etc.) */
	paymentStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Contains list of historical payments made against LOC account */
["LineOfCreditPaymentHistory"]: AliasType<{
	/** List of historical payments */
	payments?:ResolverInputTypes["LineOfCreditPayment"],
		__typename?: boolean | `@${string}`
}>;
	/** Detail of the LOC account that a user is thinking of opening */
["LineOfCreditProspectiveTerms"]: AliasType<{
	/** Membership fees for the year */
	annualFeesInCents?:boolean | `@${string}`,
	/** The day of month the user will have to make their first payment */
	dayOfMonthForRecurringPayment?:boolean | `@${string}`,
	/** The end date of the 'monthly statement'. All transactions after this date and before the end are included in the statement */
	firstBillingCycleEnd?:boolean | `@${string}`,
	/** The start date of the 'monthly statement'. All transactions after this date and before the end are included in the statement */
	firstBillingCycleStart?:boolean | `@${string}`,
	/** The minimum payment a user must make every billing cycle. This is moreso a ceiling. */
	minMonthlyPayment?:boolean | `@${string}`,
	/** Membership fee per month */
	monthlyFeeInCents?:boolean | `@${string}`,
	/** Total Credit Line Amount */
	totalCreditLineAmountInCents?:boolean | `@${string}`,
	/** The date the user will have to make their first payment */
	upcomingMinPaymentDate?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A reminder associated with the user's open installment loan account. */
["LineOfCreditStatement"]: AliasType<{
	endDate?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details statement period and prev statements */
["LineOfCreditStatementDetailsFetcher"]: AliasType<{
	currPeriodEndDate?:boolean | `@${string}`,
	currPeriodStartDate?:boolean | `@${string}`,
	/** Statement balance for the 'prev' statement balance which is the same definition of the balance of the due billing cycle */
	prevStatementBalance?:boolean | `@${string}`,
	/** Remaining statement balance for the 'prev' statement balance which is the same definition of the remaining balance of the due billing cycle */
	remainingStatementBalance?:boolean | `@${string}`,
	statementHistory?:ResolverInputTypes["LineOfCreditStatement"],
		__typename?: boolean | `@${string}`
}>;
	/** A timeline showing the payment history for a loan. */
["LoanTimelineEvent"]: AliasType<{
	/** User-friendly description explaining the status of the loan payment. */
	description?:boolean | `@${string}`,
	/** The amount of the loan payment being made in dollars. */
	dollarAmount?:boolean | `@${string}`,
	/** If present, the return type explains why the loan payment was returned. */
	returnType?:boolean | `@${string}`,
	/** The loan timeline status refers to the payment status of the loan payment such as whether it succeeded or failed. */
	status?:boolean | `@${string}`,
	/** The date and time of the loan payment or event. */
	timestamp?:boolean | `@${string}`,
	/** The loan timeline type refers to the type of loan payment being made. */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Loans"]: AliasType<{
	/** The number of days that a loan is past the current due date by. */
	daysPastCurrentDueDate?:boolean | `@${string}`,
	/** The number of days that a loan is past the original due date by. */
	daysPastDue?:boolean | `@${string}`,
	/** The loan's current due date. */
	dueDate?:boolean | `@${string}`,
	/** Whether express delivery was used or not */
	express?:boolean | `@${string}`,
	/** Fee for express delivery in dollars */
	expressTransferFeeDollars?:boolean | `@${string}`,
	/** True only if the loan is outstanding with no scheduled payment */
	extendable?:boolean | `@${string}`,
	/** Unique ID for loan */
	loanId?:boolean | `@${string}`,
	/** The Reason a loan is not recoverable */
	notRecoverableReason?:boolean | `@${string}`,
	/** The When the Loan was first created */
	originationDate?:boolean | `@${string}`,
	/** Advance amount for loan in dollars */
	originationDollarAmount?:boolean | `@${string}`,
	/** Unpaid loan amount in dollars */
	outstandingDollarAmount?:boolean | `@${string}`,
	/** Whether the loan's status is Pre-Charge-Off or not */
	prechargeOff?:boolean | `@${string}`,
	/** Loan amount that's been repaid in dollars */
	repaidDollarAmount?:boolean | `@${string}`,
	/** Whether the loan is repayable or not which means the loan is delivered, outstanding, and has no scheduled payment */
	repayable?:boolean | `@${string}`,
	/** Timeline of all the payments made for the loan. */
	timeline?:ResolverInputTypes["LoanTimelineEvent"],
		__typename?: boolean | `@${string}`
}>;
	/** Represents Loc list of payment breakdown for a credit draw */
["LocPaymentBreakdown"]: AliasType<{
	/** Loc access fee payment amount in cents */
	accessFeeAmountInCents?:boolean | `@${string}`,
	/** Loc payments in month   */
	lengthInMonth?:boolean | `@${string}`,
	/** Loc minimum payment amount in cents */
	minimumPaymentAmountInCents?:boolean | `@${string}`,
	/** Loc principal payment amount in cents */
	principalPaymentAmountInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents Loc kyc response and the list of unfulfilled reasons */
["LocRequireKycResponse"]: AliasType<{
	/** Loc requirement status */
	requirementStatus?:boolean | `@${string}`,
	/** Loc unfulfillment reasons */
	unfulfillmentReasons?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents the underwriting results for a line of credit application. If it is unapproved, the list of unapproved strings will contain data */
["LocUnderwritingDetails"]: AliasType<{
	/** Unapproval reasons */
	unapprovalReasons?:boolean | `@${string}`,
	/** Underwriting Status */
	underwritingStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LoginFailure"]: AliasType<{
	/** A reason for the failure to log in, should be used by the mobile code to decide what to do. */
	loginFailureReason?:boolean | `@${string}`,
	/** An error message to show the user when logging in fails. */
	userFacingMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LoginSuccess"]: AliasType<{
	/** The auth token for the user, should be put in the store so it can be included is subsequent requests. */
	token?:boolean | `@${string}`,
	/** The logged in users id. */
	userId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarkNotificationCenterEntriesViewed"]: AliasType<{
	/** Enum denoting if the outcome of marking the list of notification centers as viewed was a success or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarkUserViewedClgModal"]: AliasType<{
	/** The user's remaining flags after their CLG Modal flag has been removed. */
	userFlags?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarketplaceBadge"]: AliasType<{
	/** The badges display name. */
	displayName?:boolean | `@${string}`,
	/** The badges id. */
	id?:boolean | `@${string}`,
	/** The badges type. */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarketplaceCategory"]: AliasType<{
	/** Marketplace category description */
	description?:boolean | `@${string}`,
	/** Marketplace category display name */
	displayName?:boolean | `@${string}`,
	/** Whether any of the offers in this category have a badge or not */
	hasBadge?:boolean | `@${string}`,
	/** Marketplace category type */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarketplaceMaintenance"]: AliasType<{
	/** Whether the feature is in active maintenance */
	activeMaintenance?:boolean | `@${string}`,
	/** The feature associated with the potentially active maintenance flag */
	feature?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MarketplaceOfferByCategory"]: AliasType<{
	category?:boolean | `@${string}`,
	categoryIconUrl?:boolean | `@${string}`,
	offers?:ResolverInputTypes["TablessMarketplaceOffer"],
		__typename?: boolean | `@${string}`
}>;
	["MarketplaceOffersV2"]: AliasType<{
	/** The number of badges on the offers. */
	badgeCount?:boolean | `@${string}`,
	/** A list of marketplace categories. */
	categories?:ResolverInputTypes["MarketplaceCategory"],
	/** A list of offers sorted either by EPC, RPM or priority, depending on what experiments the user is sampled for. */
	fullOffers?:ResolverInputTypes["TablessMarketplaceOffer"],
		__typename?: boolean | `@${string}`
}>;
	["MoneyLionEngineOffers"]: AliasType<{
	/** LeadUUID */
	leadUUID?:boolean | `@${string}`,
	/** Loan Offers grouped by Partner */
	loanOffers?:ResolverInputTypes["PartnerLoanOffers"],
	/** Special Offers grouped by Partner */
	specialOffers?:ResolverInputTypes["PartnerSpecialOffers"],
	/** UUID */
	uUID?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MoneyLionLoanOffers"]: AliasType<{
	/** APR */
	apr?:boolean | `@${string}`,
	/** Company UUID */
	companyUuid?:boolean | `@${string}`,
	/** Disclosure */
	disclosure?:boolean | `@${string}`,
	/** Estimated Payment */
	estPayment?:boolean | `@${string}`,
	/** Expiration */
	expiration?:boolean | `@${string}`,
	/** Financial Institution UUID */
	financialInstitutionUuid?:boolean | `@${string}`,
	/** Loan Amount */
	loanAmount?:boolean | `@${string}`,
	/** Originator ID */
	originatorId?:boolean | `@${string}`,
	/** Partner Image URL */
	partnerImageUrl?:boolean | `@${string}`,
	/** Partner Name */
	partnerName?:boolean | `@${string}`,
	/** Term */
	term?:boolean | `@${string}`,
	/** URL */
	url?:boolean | `@${string}`,
	/** UUID */
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MoneyLionSpecialOffers"]: AliasType<{
	/** Description of the specialOffer */
	desc?:boolean | `@${string}`,
	/** Name of the specialOffer */
	name?:boolean | `@${string}`,
	/** URL of the partner image */
	partnerImageUrl?:boolean | `@${string}`,
	/** Name of the partner */
	partnerName?:boolean | `@${string}`,
	/** Payout amount */
	payout?:boolean | `@${string}`,
	/** Product subtype */
	productSubType?:boolean | `@${string}`,
	/** Recommendation score */
	recommendationScore?:boolean | `@${string}`,
	/** URL of the specialOffer */
	url?:boolean | `@${string}`,
	/** Unique identifier */
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
accountConnected?: [{	payrollAccountId: string},ResolverInputTypes["AccountConnectedResponseFetcher"]],
changeSubscriptionTier?: [{	loanAmount?: ResolverInputTypes["BigDecimal"] | undefined | null,	mediumType?: ResolverInputTypes["ProtectionMediumType"] | undefined | null,	subscriptionTierLevel: ResolverInputTypes["SubscriptionTierLevel"]},ResolverInputTypes["ChangeSubscriptionTierResponse"]],
completeQuiz?: [{	quizId: string},ResolverInputTypes["CompleteQuizFetcher"]],
createCreditBuilderUserAccount?: [{	chargePolicy: ResolverInputTypes["FrontendInstallmentLoanChargePolicy"],	userContributionInCents: ResolverInputTypes["Long"],	userSetTargetDayForDueDate?: number | undefined | null},boolean | `@${string}`],
createCreditMonitoringUser?: [{	enrollment: ResolverInputTypes["FrontEndCreditMonitoringEnrollment"]},ResolverInputTypes["CreditMonitoringUser"]],
createInstallmentLoansAccount?: [{	autoPayStatus: ResolverInputTypes["FrontendInstallmentLoansAutoPay"]},boolean | `@${string}`],
createPayrollAccount?: [{	payrollAccountId: string,	payrollItemId: string,	payrollUserId: string},ResolverInputTypes["CreatePayrollAccountResponse"]],
createPayrollAccountV2?: [{	payrollAccountId: string,	payrollItemId: string,	payrollUserId: string},ResolverInputTypes["CreatePayrollAccountResponseV2"]],
	/** Creates a deposit destination for a payroll user's direct deposits */
	createTargetDepositDestination?:ResolverInputTypes["CreateTargetDepositDestinationResponse"],
deleteBankLogin?: [{	bankLoginId: string},ResolverInputTypes["DeleteBankLogin"]],
deletePayrollAccount?: [{	payrollAccountId: string},ResolverInputTypes["DeletePayrollAccountResponse"]],
disableDirectDeposits?: [{	depositDestinationId: string},ResolverInputTypes["DisableDirectDepositsResponse"]],
disablePayrollAccount?: [{	payrollAccountId: string},ResolverInputTypes["DisablePayrollAccountResponse"]],
initiateCatchUpPaymentForInstallmentLoan?: [{	installmentLoansUuid: string},ResolverInputTypes["InitiateCatchUpPaymentForInstallmentLoan"]],
initiateCreditDraw?: [{	creditDrawAmount: ResolverInputTypes["Long"],	minPaymentAmount: ResolverInputTypes["Long"]},boolean | `@${string}`],
initiateOneTimePayment?: [{	loanId: string,	paymentMethodId: string},ResolverInputTypes["InitiateOneTimePayment"]],
initiatePayOffPaymentForInstallmentLoan?: [{	installmentLoansUuid: string},ResolverInputTypes["InitiatePayOffPaymentForInstallmentLoan"]],
initiatePrePaymentForInstallmentLoan?: [{	installmentLoansUuid: string},ResolverInputTypes["InitiatePrePaymentForInstallmentLoan"]],
initiateUserPaymentForLoc?: [{	chargeType: ResolverInputTypes["FrontendLocUserInitiatedPaymentChargeType"]},boolean | `@${string}`],
inputSalaryData?: [{	averageSalary: ResolverInputTypes["Long"],	company: string,	gender: string,	location: string,	yearsOfExperience: number},ResolverInputTypes["InputSalaryData"]],
login?: [{	emailAddress?: string | undefined | null,	phoneNumber?: string | undefined | null,	recaptchaToken?: string | undefined | null},ResolverInputTypes["LoginUnion"]],
markNotificationCenterEntriesViewed?: [{	notificationIds: Array<ResolverInputTypes["Long"] | undefined | null>},ResolverInputTypes["MarkNotificationCenterEntriesViewed"]],
	/** Marks that the user has viewed the CLG modal screen by removing their user flag. */
	markUserViewedClgModal?:ResolverInputTypes["MarkUserViewedClgModal"],
openLocAccount?: [{	userSetAutoPayStatus: ResolverInputTypes["FrontendLocAutopayStatus"]},boolean | `@${string}`],
publishCreditBuilderDraftPaymentSchedule?: [{	reason: ResolverInputTypes["FrontendCBScheduleVersionReason"]},boolean | `@${string}`],
recordUserPromptResponse?: [{	promptType: ResolverInputTypes["CampaignPromptType"],	responseType: ResolverInputTypes["PromptResponseType"]},ResolverInputTypes["RecordUserPromptResponse"]],
registerBankLoginMaintainingExistingLogin?: [{	isUpdate: boolean,	paymentAuthFlow: ResolverInputTypes["FrontendPaymentAuthFlow"],	provider: ResolverInputTypes["BankProvider"],	publicToken: string,	selectedProviderAccountId?: string | undefined | null},ResolverInputTypes["RegisterBankLoginMaintainingExistingLogin"]],
registerOneTimeCard?: [{	city: string,	encryptedCardData: string,	firstName: string,	keyId: string,	lastFour: string,	lastName: string,	lineOne: string,	lineTwo?: string | undefined | null,	state: string,	zipcode: string},ResolverInputTypes["RegisterOneTimeCardResponse"]],
registerOneTimeCardV2?: [{	city: string,	encryptedCardData: string,	firstName: string,	keyId: string,	lastFour: string,	lastName: string,	lineOne: string,	lineTwo?: string | undefined | null,	state: string,	zipcode: string},ResolverInputTypes["RegisterOneTimeCardUnion"]],
repayAdvance?: [{	advanceId: string},ResolverInputTypes["RepayAdvance"]],
requestManualDisbursal?: [{	amount: ResolverInputTypes["BigDecimal"],	dueDate: string,	isExpress: boolean,	secondaryBankAccountIds: Array<string | undefined | null>},ResolverInputTypes["RequestManualDisbursal"]],
resendAuthVerificationCode?: [{	isPhoneVerification: boolean,	userUUID: string},ResolverInputTypes["ResendAuthVerificationCode"]],
	/** Schedules same day manual payment for installment loan account for Credit Builder */
	scheduleCreditBuilderSameDayManualPayment?:ResolverInputTypes["ScheduleCreditBuilderSameDayManualPayment"],
startOrResumeQuiz?: [{	name: ResolverInputTypes["QuizName"]},ResolverInputTypes["StartOrResumeQuizResponse"]],
submitCreditBuilderPaymentChargePolicy?: [{	chargePolicy: ResolverInputTypes["FrontendInstallmentLoanChargePolicy"]},boolean | `@${string}`],
submitCreditBuilderPaymentStrategy?: [{	retryStrategyType: ResolverInputTypes["FrontendCreditBuilderPaymentRetryStrategyType"]},boolean | `@${string}`],
submitMoneyLionPersonalLoansQuiz?: [{	address1: string,	address2?: string | undefined | null,	annualIncome: number,	city: string,	consentsToFcra: boolean,	consentsToTcpa: boolean,	creditRating: ResolverInputTypes["CreditRating"],	dateOfBirth: string,	educationLevel: ResolverInputTypes["EducationLevel"],	email: string,	employmentPayFrequency: ResolverInputTypes["EmploymentPayFrequency"],	employmentStatus: ResolverInputTypes["EmploymentStatus"],	fcraLanguage?: string | undefined | null,	firstName: string,	lastName: string,	loanAmount: number,	primaryPhone: string,	productTypes: Array<string | undefined | null>,	propertyStatus: ResolverInputTypes["PropertyStatus"],	purpose: ResolverInputTypes["LoanPurpose"],	ssn: string,	state: string,	tcpaLanguage?: string | undefined | null,	zipcode: string},ResolverInputTypes["SubmitMoneyLionOffers"]],
submitQuizFeedback?: [{	feedback: string,	feedbackType: ResolverInputTypes["QuizFeedbackType"],	quizId: string},ResolverInputTypes["SubmitQuizFeedbackFetcher"]],
submitQuizResponse?: [{	quizId: string,	quizStepId: string,	response: string},ResolverInputTypes["SubmitQuizResponse"]],
submitResponseForPromptType?: [{	promptResponseType: ResolverInputTypes["PromptResponseType"],	promptType: ResolverInputTypes["UserPromptType"]},ResolverInputTypes["SubmitUserPromptResponse"]],
switchProtectedLoginToExistingLogin?: [{	bankAccountId?: string | undefined | null,	bankLoginId: string,	publicToken?: string | undefined | null,	shouldMaintainExistingLogin: boolean},ResolverInputTypes["SwitchProtectedLoginToExistingLogin"]],
switchProtectedLoginToExistingNonConnectedLogin?: [{	bankLoginId: string,	publicToken: string,	shouldMaintainExistingLogin: boolean},ResolverInputTypes["SwitchProtectedLoginToExistingNonConnectedLogin"]],
testDisablePayrollAccount?: [{	payrollAccountId: string,	payrollProvider: ResolverInputTypes["PayrollProvider"]},ResolverInputTypes["DisablePayrollAccountResponse"]],
throwTestException?: [{	message: string,	useErrorHandler?: boolean | undefined | null},boolean | `@${string}`],
trackImpressionEvent?: [{	durationMs: number,	elementName: string,	endedAt: string,	/** Strigified JSON referencing data that provides context to this event. */
	eventForeignKeys?: string | undefined | null,	impressionId: string,	screenName: string,	startedAt: string},ResolverInputTypes["TrackImpressionsFetcher"]],
trackNotificationCenterEntryClick?: [{	notificationId: ResolverInputTypes["Long"]},ResolverInputTypes["TrackNotificationCenterEntryClick"]],
updateAutoPayStatusForInstallmentLoan?: [{	installmentLoansUuid: string,	newAutoPayStatus: ResolverInputTypes["FrontendInstallmentLoansAutoPay"]},ResolverInputTypes["UpdateInstallmentLoansAutoPayStatusFetcher"]],
updateAutopayStatusForLoc?: [{	newAutopayStatus: ResolverInputTypes["FrontendLocAutopayStatus"]},boolean | `@${string}`],
updateBankAccountJointStatus?: [{	bankAccountId: string,	isJoint: boolean},ResolverInputTypes["UpdateBankAccountJointStatus"]],
updateCreditLimitBoostStatus?: [{	creditLimitBoostId: string,	creditLimitBoostStatus: ResolverInputTypes["FrontendCreditLimitBoostStatus"]},ResolverInputTypes["UpdateCreditLimitBoostStatus"]],
updatePaymentChargeTypeForLoc?: [{	newPaymentChargeType: ResolverInputTypes["FrontendLocUserInitiatedPaymentChargeType"]},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["NotificationCenter"]: AliasType<{
	/** Return notifications for user to display in notification center */
	notifications?:ResolverInputTypes["NotificationCenterEntry"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationCenterEntry"]: AliasType<{
	/** Time notification was added to notification center for user */
	createdAt?:boolean | `@${string}`,
	/** Description of notification */
	description?:boolean | `@${string}`,
	/** Where we will navigate the user when they click on the notification */
	destination?:boolean | `@${string}`,
	/** Whether or not user has viewed notification */
	hasViewed?:boolean | `@${string}`,
	/** Id of notification */
	id?:boolean | `@${string}`,
	/** Image URL to display with the notification on the frontend */
	imageUrl?:boolean | `@${string}`,
	/** Title of notification */
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Information about pagination in a connection. */
["PageInfo"]: AliasType<{
	/** When paginating forwards, the cursor to continue. */
	endCursor?:boolean | `@${string}`,
	/** When paginating forwards, are there more items? */
	hasNextPage?:boolean | `@${string}`,
	/** When paginating backwards, are there more items? */
	hasPreviousPage?:boolean | `@${string}`,
	/** When paginating backwards, the cursor to continue. */
	startCursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PartnerLoanOffers"]: AliasType<{
	/** Offers */
	offers?:ResolverInputTypes["MoneyLionLoanOffers"],
	/** Partner Name */
	partnerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PartnerSpecialOffers"]: AliasType<{
	/** Offers */
	offers?:ResolverInputTypes["MoneyLionSpecialOffers"],
	/** Partner Name */
	partnerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details about past due credit builder payments. */
["PastDuePayment"]: AliasType<{
	/** The total amount past due in dollars. */
	amountDollars?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Details about the user's payroll connection */
["PayrollConnection"]: AliasType<{
	/** User ID generated by a payroll vendor for client-side authorization */
	payrollUserId?:boolean | `@${string}`,
	/** User token generated by a payroll vendor for client-side authorization */
	userToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Payroll_32_Account_32_Fetcher_32_With_32_Outcome"]: AliasType<{
	/** Whether the request was successful */
	outcome?:boolean | `@${string}`,
	/** List of all payroll accounts for the user */
	payrollAccounts?:ResolverInputTypes["Payroll_32_Accounts_32_V2"],
		__typename?: boolean | `@${string}`
}>;
	["Payroll_32_Accounts"]: AliasType<{
	/** Connection status of payroll account */
	connectionStatus?:boolean | `@${string}`,
	/** Name of employer associated with payroll account */
	employerName?:boolean | `@${string}`,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	manualDisconnect?:boolean | `@${string}`,
	/** Status indicating whether payroll account will be updated when data refreshes */
	ongoingRefreshStatus?:boolean | `@${string}`,
	/** Unique ID for payroll account */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Payroll_32_Accounts_32_V2"]: AliasType<{
	/** Connection status of payroll account */
	connectionStatus?:boolean | `@${string}`,
	/** User facing message explaining why the user isn't eligible for DDS */
	dDSEligibilityMessage?:boolean | `@${string}`,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	eligibleForDDS?:boolean | `@${string}`,
	/** Name of employer associated with payroll account */
	employerName?:boolean | `@${string}`,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	manualDisconnect?:boolean | `@${string}`,
	/** Status indicating whether payroll account will be updated when data refreshes */
	ongoingRefreshStatus?:boolean | `@${string}`,
	/** Unique ID for payroll account */
	payrollAccountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A user facing summary of insights for a user's bank transactions of loan is a secured loan which the user has taken out to build such as upcoming fees, cashflow, spend, etc. */
["PfmSummary"]: AliasType<{
	/** Returns the sum of the the total debit amounts for plaid transactions with fee categories */
	totalFeesPaid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Payment details about possible pre-payments user can make. */
["PrePayment"]: AliasType<{
	/** The payment amount in dollars. */
	amountDollars?:boolean | `@${string}`,
	/** When the pre-payment is due. */
	dueDate?:boolean | `@${string}`,
	/** An optional date that is returned if the the prepayment is not yet eligible for prepayment.   */
	payableAfter?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PromoCardOffer"]: AliasType<{
	/** The url for the offers affiliate link. */
	affiliateLinkUrl?:boolean | `@${string}`,
	/** The offers description */
	description?:boolean | `@${string}`,
	/** The offers details */
	details?:boolean | `@${string}`,
	/** The Offers Id */
	id?:boolean | `@${string}`,
	/** The url for the offers logos image file. */
	logoUrl?:boolean | `@${string}`,
	/** The offers name. */
	offerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A promotion is an offer to provide a discount to a user for subscribing to Brigit. */
["Promotion"]: AliasType<{
	/** The amount that the user would pay while the discount is active, in US dollars. */
	discountedAmount?:boolean | `@${string}`,
	/** How many months the discount would apply to the user's subscription. */
	numMonths?:boolean | `@${string}`,
	/** The amount that the user would pay while the discount is active, in US dollars. */
	subscriptionAmountAfterDiscount?:boolean | `@${string}`,
	/** The subscription tier level that the promotion applies to. Users would need to subscribe to this particular tier for the promotion to be applied as a discount. */
	subscriptionTierLevel?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProtectionDetails"]: AliasType<{
	/** List of user's loans and loan details */
	loans?:ResolverInputTypes["Loans"],
	/** Status of the user's current advance */
	protectionStatus?:boolean | `@${string}`,
	/** User-friendly description of the status of the user's current advance */
	protectionStatusDescription?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Query"]: AliasType<{
	/** The available subscription tiers are those tiers which can be accessed by the user making the request. They're what we would advertise as the options available to the user. */
	availableSubscriptionTiers?:ResolverInputTypes["SubscriptionTier"],
	/** Global configurations related to the Credit Builder system. */
	creditBuilderGlobalConfiguration?:ResolverInputTypes["CreditBuilderGlobalConfiguration"],
	/** Get Promo cards under new dynamic marketplace model */
	dynamicPromoCards?:ResolverInputTypes["DynamicPromoCards"],
experimentVariantAssignment?: [{	name: ResolverInputTypes["ExperimentName"],	sampleKey: string,	sampleKeyType: ResolverInputTypes["FrontendSampleKeyType"],	samplingTrigger: ResolverInputTypes["SamplingTrigger"],	shouldPerformAssignment: boolean},ResolverInputTypes["FrontendExperimentVariantAssignment"]],
	/** Return Frequently Asked Questions as part of an experiment to add clarity to the subscription process when a user is activating via the IC flow. See: https://brigit.atlassian.net/browse/ENG-8843. */
	iCActivationFAQs?:ResolverInputTypes["ICActivationFAQs"],
	/** Global configurations related to the Line of credit system. */
	lineOfCreditGlobalConfiguration?:ResolverInputTypes["LineOfCreditGlobalConfiguration"],
marketplaceMaintenance?: [{	feature?: ResolverInputTypes["MarketplaceFeature"] | undefined | null},ResolverInputTypes["MarketplaceMaintenance"]],
	/** The account of the user making the request */
	me?:ResolverInputTypes["UserAccount"],
	/** Returns information to the frontend about whether or not the user's current mobile version is supported and, if it's not, whether we should nudge or force users to upgrade.Returns UNKNOWN if the user is on web or if we can't parse the mobile version we receive. */
	mobileVersionSupportType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["QuizFetcher"]: AliasType<{
	/** Whether the quiz is completed */
	completed?:boolean | `@${string}`,
	/** The zoned datetime of when the quiz was completed. Returns an optional because quiz may be incomplete */
	completedAt?:boolean | `@${string}`,
	/** The zoned datetime of when the quiz was started */
	createdAt?:boolean | `@${string}`,
	/** Optional zoned datetime of when the quiz was deleted */
	deletedAt?:boolean | `@${string}`,
	/** The id of the quiz */
	id?:boolean | `@${string}`,
	/** The name of the quiz */
	quizName?:boolean | `@${string}`,
	/** The taker of the quiz. It may be a user id or a device id if the user is not yet authenticated. */
	quizTaker?:boolean | `@${string}`,
	/** The version id of the quiz */
	quizVersionId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["QuizStateFetcher"]: AliasType<{
	/** Whether a user can exit the quiz at any point */
	canExitEarly?:boolean | `@${string}`,
	/** The current step of the quiz that the user is on. If the quiz has no more steps to complete, an empty optional is returned. */
	currentStepId?:boolean | `@${string}`,
	/** Whether the quiz can be resume part way through */
	isResumable?:boolean | `@${string}`,
	/** An array of QuizSteps */
	steps?:ResolverInputTypes["Step"],
	/** The total number of steps in the current state. If branching logic changes length, returns empty optional */
	totalSteps?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecentScoreChangeFetcher"]: AliasType<{
	/** An enum representing the CTA link that should be rendered after the text. */
	callToAction?:boolean | `@${string}`,
	/** An optional integer. If present will be used to render the score change indicator on the right side of the card and should contain a positive, negative, or 0 change to show/ If they only have one score and thus can not compute a delta this should be empty. */
	scoreChange?:boolean | `@${string}`,
	/** The date of the last score pulled. Will be used to render the footer of the component.  */
	scoreUpdateDate?:boolean | `@${string}`,
	/** The text to display in the component. */
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecordUserPromptResponse"]: AliasType<{
	/** The prompt type of a user engagement event */
	campaignPromptType?:boolean | `@${string}`,
	/** The response type of a user engagement event */
	promptResponseType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Referral object maintains a users referral status for a specific referral. */
["Referral"]: AliasType<{
	/** Represents whether the user has been paid for the referral. */
	paid?:boolean | `@${string}`,
	/** Represents the value for which the user was paid for the referral. */
	payoutValue?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterBankLoginMaintainingExistingLogin"]: AliasType<{
	/** An Enum denoting if the outcome of registering the bank account was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterOneTimeCardInvalidInput"]: AliasType<{
	/** User facing message explaining why the user input was invalid. */
	userFacingMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterOneTimeCardResponse"]: AliasType<{
	/** The display name of the card payment method i.e. VISA ending in 1234 */
	cardNickname?:boolean | `@${string}`,
	/** The payment method ID of the one time card payment method created */
	paymentMethodId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterOneTimeCardSuccess"]: AliasType<{
	/** User friendly nickname to refer to card. */
	cardNickname?:boolean | `@${string}`,
	/** Newly created payment method ID. */
	paymentMethodId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RepayAdvance"]: AliasType<{
	/** The advance that we attempted to repay */
	advanceId?:boolean | `@${string}`,
	/** An Enum denoting if the outcome of the request was successful or failure */
	repayAdvanceOutcome?:boolean | `@${string}`,
	/** A String denoting why this request succeeded or failed */
	repayAdvanceOutcomeReason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RequestManualDisbursal"]: AliasType<{
	/** The loan that was disbursed to the user, on success */
	loanId?:boolean | `@${string}`,
	/** An Enum denoting if the outcome of the requestManualDisbursal was successful or failure */
	requestManualDisbursalOutcome?:boolean | `@${string}`,
	/** A String denoting why this request succeeded or failed */
	requestManualDisbursalReason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ResendAuthVerificationCode"]: AliasType<{
	/** An Enum denoting if the outcome of resending the verification code was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SalaryAggregators"]: AliasType<{
	/** Primary filter used to aggregate salary data */
	filterOne?:boolean | `@${string}`,
	/** List of aggregated salary data based on a second filter */
	filterTwoData?:ResolverInputTypes["FilteredSalaryData"],
	/** The average salary for the primary filter */
	salary?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Installment payment made manually on the due date from the user's external bank account for credit builder. */
["SameDayManualPayment"]: AliasType<{
	/** Amount scheduled to be paid for the manual payment */
	amountInCents?:boolean | `@${string}`,
	/** Date and time of scheduled manual payment in yyyy-MM-dd format */
	paidOn?:boolean | `@${string}`,
	/** Status of installment loan payment after manual payment is scheduled */
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Payment details for manual payment that is due today. */
["SameDayPayment"]: AliasType<{
	/** The payment amount in dollars. */
	amountDollars?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ScheduleCreditBuilderSameDayManualPayment"]: AliasType<{
	/** The result of scheduling same day manual payment */
	outcome?:boolean | `@${string}`,
	/** The response object scheduling same day manual payment. Only returns something if the outcome is successful */
	sameDayManualPayment?:ResolverInputTypes["SameDayManualPayment"],
		__typename?: boolean | `@${string}`
}>;
	["ScoreBucketFetcher"]: AliasType<{
	/** The lower range of the score bucket, e.g. 349. */
	maximumScore?:boolean | `@${string}`,
	/** The lower range of the score bucket, e.g. 300. */
	minimumScore?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ScoreCard"]: AliasType<{
	/** The maximum score to be shown on the user's score card */
	maxScore?:boolean | `@${string}`,
	/** The minimum approval score needed for the user to be approved based on their underwriting strategy. */
	minApprovalScore?:boolean | `@${string}`,
	/** The minimum score to be shown on the user's score card */
	minScore?:boolean | `@${string}`,
	/** The user's underwriting score. If their score meets the minimum approval score, then the user will be approved for Instant Cash. */
	score?:boolean | `@${string}`,
	/** List of categories that influence the user's underwriting score. */
	scoreCategories?:ResolverInputTypes["ScoreCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ScoreCategory"]: AliasType<{
	/** User-friendly text describing improvements the user can make to improve their performance in this score category. */
	description?:boolean | `@${string}`,
	/** The name of the larger categories that affect the user's approval status such as bank account, spending, and earning. */
	name?:boolean | `@${string}`,
	/** The status of this category is based on the lowest rank of the statuses in its subcategories. */
	status?:boolean | `@${string}`,
	/** List of more specific subcategories within this score category. */
	subcategories?:ResolverInputTypes["ScoreSubcategory"],
		__typename?: boolean | `@${string}`
}>;
	["ScoreSubcategory"]: AliasType<{
	/** User-friendly text explaining what data is used in this subcategory */
	description?:boolean | `@${string}`,
	/** Name of the score subcategory */
	name?:boolean | `@${string}`,
	/** A rank of how important this score subcategory contributes to the user's underwriting score */
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SelectFieldFetcher"]: AliasType<{
	/** An optional image to display to the left of the text.  Only populated if the type is GRID */
	icon?:boolean | `@${string}`,
	/** The text to display for the option */
	optionDisplayValue?:boolean | `@${string}`,
	/** Whether the option should be selected by default */
	selected?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StartOrResumeQuizResponse"]: AliasType<{
	/** The quiz definition */
	quiz?:ResolverInputTypes["QuizFetcher"],
	/** The state of the quiz */
	state?:ResolverInputTypes["QuizStateFetcher"],
		__typename?: boolean | `@${string}`
}>;
	["Step"]: AliasType<{
	/** Optional copy to provide additional context or instruction for the question */
	assistiveCopy?:boolean | `@${string}`,
	/** Whether a ‘Skip’ button should be rendered allowing user to bypass the step. */
	canSkip?:boolean | `@${string}`,
	/** Whether assistive copy should be centered */
	centerAssistiveCopy?:boolean | `@${string}`,
	/** Whether the main question text should be centered */
	centerQuestionText?:boolean | `@${string}`,
	/** Whether select options should be centered */
	centered?:boolean | `@${string}`,
	/** Optional disclosure primary text */
	disclosureCopy?:boolean | `@${string}`,
	/** Whether a step has information that needs to be sent via VGS or other security proxy */
	hasSensitiveResponse?:boolean | `@${string}`,
	/** Optional icon image name */
	heroIcon?:boolean | `@${string}`,
	/** Required if a hero icon exists */
	heroIconSize?:boolean | `@${string}`,
	/** A unique string identifier for the step. Will be used when submitting responses to the step (if the step has responses). For example, the first step of a quiz may have an ID of '1'. */
	id?:boolean | `@${string}`,
	/** Whether the next step is known or not (e.g. dependant on the response to this step */
	isBranching?:boolean | `@${string}`,
	/** Whether its the last step of the quiz */
	isLastStep?:boolean | `@${string}`,
	/** Whether the user should be able to select/submit multiple options. Only populated for MULTI_SELECT or GRID */
	multipleSelect?:boolean | `@${string}`,
	/** The ID of the next quiz step, if present */
	nextStepId?:boolean | `@${string}`,
	/** The select field options. Only populated if the type is MULTI-SELECT or GRID */
	options?:ResolverInputTypes["SelectFieldFetcher"],
	/** Only populated for type YES_NO where the field has a prior submisson */
	previousSelectedYesNoOption?:boolean | `@${string}`,
	/** The ID of the prior quiz step, if present */
	priorStepId?:boolean | `@${string}`,
	/** The main text of the step. You can imagine this as the question text, e.g. 'Do you like pickles?' */
	questionText?:boolean | `@${string}`,
	/** Optional copy for the submit button */
	submitText?:boolean | `@${string}`,
	/** Optional supplemental text */
	supplementalCopy?:boolean | `@${string}`,
	/** Optional icon to display with the supplemental text */
	supplementalCopyIcon?:boolean | `@${string}`,
	/** Optional disclosure secondary text */
	supplementalDisclosureCopy?:boolean | `@${string}`,
	/** Only populated if the type is FREE_TEXT */
	textFields?:ResolverInputTypes["TextFieldFetcher"],
	/** The type of the quiz step, e.g. 'INFORMATION', 'TEXT_INPUT', 'MULTI_SELECT' etc */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitMoneyLionOffers"]: AliasType<{
	/** LeadUUID */
	leadUUID?:boolean | `@${string}`,
	/** Loan Offers grouped by Partner */
	loanOffers?:ResolverInputTypes["PartnerLoanOffers"],
	/** Special Offers grouped by Partner */
	specialOffers?:ResolverInputTypes["PartnerSpecialOffers"],
	/** UUID */
	uUID?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitQuizFeedbackFetcher"]: AliasType<{
	/** The id of the quiz */
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitQuizResponse"]: AliasType<{
	/** The state of the quiz */
	state?:ResolverInputTypes["QuizStateFetcher"],
		__typename?: boolean | `@${string}`
}>;
	["SubmitUserPromptResponse"]: AliasType<{
	/** The outcome of submitting a user prompt response */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A subscription is an agreement between Brigit and a user, for them to pay us periodically, and for us to provide them access to some of our services in exchange for that payment. Subscriptions are associated with a particular tier, which dictates what price the user pays and what features they are provided. */
["Subscription"]: AliasType<{
	/** The tier that the subscription is currently linked to. */
	currentTier?:ResolverInputTypes["SubscriptionTier"],
	/** The time the current subscription tier became effective, or empty if user is NPE */
	currentTierValidFromTimestamp?:boolean | `@${string}`,
	/** If present, the subscription currently has a discount applied to it. */
	discount?:ResolverInputTypes["Discount"],
	/** If user has a connected account, is approved, was previously enabled but currently disabled, has a loan, and does not have an outstanding loan, they are eligible for the reactivation experience. */
	isEligibleForReactivationExperience?:boolean | `@${string}`,
	/** An explanation of how Instant Cash Express Transfer fees would work for a user on this tier. If the tier does not provide access to Instant Cash, instant transfer fees are not applicable and this field will be empty. NOTE: for NPE users this will be the subscription date if the user activates today with their same bank account, this could change if they change their bank account or income source. */
	proposedBillDate?:boolean | `@${string}`,
	/** If present, the subscription fee schedule the user has been assigned to determine when in the month we'll schedule their monthly subscription payment. */
	subscriptionFeeSchedule?:boolean | `@${string}`,
	/** Subscription payments that have been scheduled for a user, excluding those that were cancelled because the user deactivated during the grace period or upgraded to the Premium plan. */
	subscriptionPayments?:ResolverInputTypes["SubscriptionPayment"],
subscriptionTierExperiment?: [{	experimentName: ResolverInputTypes["SubscriptionTierExperimentName"]},ResolverInputTypes["SubscriptionTierExperiment"]],
	/** A subscription tier strategy indicates the subscription experience users are subject to,
    the details of which will be reflected in their `availableSubscriptionTiers`. */
	subscriptionTierStrategyName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionPayment"]: AliasType<{
	/** Full subscription amount user is paying month over month, not taking promotions into account. */
	intendedAmount?:boolean | `@${string}`,
	/** Whether a subscription is repayable. Controls whether we'll allow usersto repay a missed subscription from the home screen. */
	isPayable?:boolean | `@${string}`,
	/** Amount that we attempted to collect for a given subscription. Will be 1:1 with intendedAmount unless user received a promotion for a given month. */
	netAmount?:boolean | `@${string}`,
	/** Date on which we'll attempt to collect on the subscription payment, formatted YYYY-MM-dd */
	paymentDate?:boolean | `@${string}`,
	/** Status of subscription payment. */
	status?:boolean | `@${string}`,
	/** If present, the subscription tier the user was on when we scheduled the givensubscription payment. If not present, the user is a legacy non-tiered user. */
	subscriptionTierLevel?:boolean | `@${string}`,
	/** UUID of subscription payment */
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A subscription tier is an offering for a type of subscription that a user can enroll in. A tier has a price that the user pays to access it, and includes features that Brigit provides in exchange. Some tiers provide fewer features for a lower price, and others provide more features for a higher price. */
["SubscriptionTier"]: AliasType<{
	/** The name of this tier to show to the user. */
	displayName?:boolean | `@${string}`,
	/** An explanation of how Instant Cash Express Transfer fees would work for a user on this tier. If the tier does not provide access to Instant Cash, instant transfer fees are not applicable and this field will be empty. NOTE: for NPE users this will be the subscription date if the user activates today with their same bank account, this could change if they change their bank account or income source. */
	expressTransferFeeText?:boolean | `@${string}`,
	/** An identifier for which subscription tier this is. There are a small, fixed number of tiers. */
	level?:boolean | `@${string}`,
	/** The monthly cost that a user on this tier would pay, in US dollars. */
	pricingAmount?:boolean | `@${string}`,
	/** The Brigit products that a user on this tier would have access to. */
	products?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A subscription tier experiment involves splitting users into different groups to provide them with different subscription-related experiences, to see which experiences work best. This object records a user's participation in a single experiment. */
["SubscriptionTierExperiment"]: AliasType<{
	/** Identifies the experiment. */
	experimentName?:boolean | `@${string}`,
	/** A variant name identifies which group the user is part of for this experiment. The names will depend on the experiment, but CONTROL is typically the name given to the variant that provides an unchanged experience. If only one alternate experience is being tested, the variant for users being shown the new experience is typically TEST. Some experiments may have other variants, however, with unique names. */
	variantName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SwitchProtectedLoginToExistingLogin"]: AliasType<{
	/** An Enum denoting if the outcome of switching the protected login was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SwitchProtectedLoginToExistingNonConnectedLogin"]: AliasType<{
	/** An Enum denoting if the outcome of switching the protected login to an existing, non-connected login was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TablessMarketplaceOffer"]: AliasType<{
	/** The url for the offers affiliate link. */
	affiliateLinkUrl?:boolean | `@${string}`,
	/** A list of badges to be rendered with the offer. */
	badges?:ResolverInputTypes["MarketplaceBadge"],
	/** The categories the offer is under. */
	categories?:boolean | `@${string}`,
	/** The offers description. */
	description?:boolean | `@${string}`,
	/** The offers details. */
	details?:boolean | `@${string}`,
	/** The offers id. */
	id?:boolean | `@${string}`,
	/** The url for the offers logos image file. */
	logoUrl?:boolean | `@${string}`,
	/** The offers name. */
	offerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents the installment loan term details for a prospective user */
["TermRequestDetails"]: AliasType<{
	/** Application fee in cents */
	applicationFeeInCents?:boolean | `@${string}`,
	/** Estimated delivery for the principal payment credit */
	estimatedDeliveryDateForPrincipalCredit?:boolean | `@${string}`,
	/** Monthly fee in cents */
	monthlyFeeInCents?:boolean | `@${string}`,
	/** Pay Frequency type for the payment schedule */
	payFrequency?:boolean | `@${string}`,
	/** Payment schedule */
	paymentSchedule?:ResolverInputTypes["FrontendPrincipalPayment"],
	/** Total months of repayment for the term */
	totalMonthsInTerm?:boolean | `@${string}`,
	/** Loan amount in cents */
	totalPrincipalAmountInCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TextFieldFetcher"]: AliasType<{
	/** Prior persisted value input by the user, if exists */
	initValue?:boolean | `@${string}`,
	/** Whether the text field may contain sensitive information */
	isSensitive?:boolean | `@${string}`,
	/** What type of keyboard should be shown */
	keyboardType?:boolean | `@${string}`,
	/** The text field label */
	label?:boolean | `@${string}`,
	/** A list of strings containing the names of methods to format text, e.g. date or dollarAmount */
	textFormat?:boolean | `@${string}`,
	/** An array of strings of validation that should be applied to the field */
	validate?:boolean | `@${string}`,
	/** An array of strings of possible warnings that should be applied to the field */
	warn?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TrackImpressionsFetcher"]: AliasType<{
	/** Tracked impression that is saved to the backend if the outcome indicates a success */
	impression?:ResolverInputTypes["UserImpression"],
	/** Result of the attempt to save the impression */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TrackNotificationCenterEntryClick"]: AliasType<{
	/** Enum denoting if the outcome of tracking click on notification center entry was a success or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnderwritingDetails"]: AliasType<{
	/** Describes the approval of the user's account which could be approved, unapproved, or not run if the user has not been underwritten yet */
	accountApprovalState?:boolean | `@${string}`,
	/** Whether the user is approved or not based on their credit risk state */
	creditRiskState?:boolean | `@${string}`,
	/** Fetch users approval state from the latest underwriting report */
	isApproved?:boolean | `@${string}`,
	/** Latest credit limit boost for a user */
	latestCreditLimitBoost?:ResolverInputTypes["CreditLimitBoostDetails"],
	/** Maximum loan amount for user */
	maxLoanAmount?:boolean | `@${string}`,
	/** Minimum loan amount for user */
	minLoanAmount?:boolean | `@${string}`,
	/** A numeric representation of the user's progress in being approved based on the number of underwriting rules the user has met and not met */
	progress?:boolean | `@${string}`,
	/** A representation of the user's underwriting score and score categories. This field is only present if the user is on a score-based underwriting strategy. */
	scoreCard?:ResolverInputTypes["ScoreCard"],
	/** Fetch all underwriting checks which include knockout rules and account level rules from the latest underwriting report */
	underwritingChecks?:ResolverInputTypes["FrontendUnderwritingCheck"],
	/** List of underwriting rules and metadata used for underwriting the user */
	underwritingRulesMetadata?:ResolverInputTypes["UnderwritingRuleMetadata"],
	/** All credit limit boosts for a user */
	userCreditLimitBoosts?:ResolverInputTypes["CreditLimitBoostDetails"],
		__typename?: boolean | `@${string}`
}>;
	["UnderwritingRuleMetadata"]: AliasType<{
	/** Depending on the underwriting rule, the cutoff serves as the maximum or minimum amount needed for the user to meet this UW rule */
	cutoff?:boolean | `@${string}`,
	/** Name of underwriting rule used for calculating the user's underwriting score */
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateBankAccountJointStatus"]: AliasType<{
	/** An Enum denoting if the outcome of updating the bank account's joint status was successful or failure */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateCreditLimitBoostStatus"]: AliasType<{
	/** The updated status of the credit limit boost */
	updatedCreditLimitBoostStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateInstallmentLoansAutoPayStatusFetcher"]: AliasType<{
	/** An Enum denoting if the outcome of the auto update status is successful */
	outcome?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A USA state, territory, or region that has a human-readable name and associated code. */
["UsaState"]: AliasType<{
	/** The two-letter code for the state (e.g. 'MD') */
	code?:boolean | `@${string}`,
	/** The human readable name for the state (e.g. 'Maryland'). */
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A user's account is like their profile within Brigit. It's the object that records the user's state, preferences, and the interactions and agreements made between the user and Brigit. */
["UserAccount"]: AliasType<{
	/** List of active logins for user. This will include the user's protected login (which holds the account we're sending advances to and collecting subscriptions from) and their non-protected, active logins, which are non-deleted primary logins that we still maintain a connection to. */
	activeBankLogins?:ResolverInputTypes["ActiveBankLogins"],
	/** The user's approved loan amounts */
	approvedLoanAmounts?:ResolverInputTypes["ApprovedLoanAmounts"],
	/** The user's auto advance configuration */
	autoAdvanceDetails?:ResolverInputTypes["AutoAdvanceDetails"],
	/** Predictions based on the user's linked bank account such as their income sources and likelihood to overdraft. */
	bankAccountPrediction?:ResolverInputTypes["BankAccountPrediction"],
	/** The user's bank connection details */
	bankConnection?:ResolverInputTypes["BankConnection"],
	/** Date on which user created their account */
	creationDate?:boolean | `@${string}`,
creditBuilderEnrollmentEligibility?: [{	reasons?: Array<ResolverInputTypes["CreditBuilderEnrollmentIneligibilityReason"] | undefined | null> | undefined | null},ResolverInputTypes["CreditBuilderEnrollmentEligibility"]],
	/** The user's most recent Credit Builder installment loan. */
	creditBuilderInstallmentLoan?:ResolverInputTypes["CreditBuilderInstallmentLoan"],
	/** The Sweep Stakes qualification determines if user is qualifies for sweep stake */
	creditBuilderSweepStakesQualification?:boolean | `@${string}`,
	/** User details related to Credit Builder */
	creditBuilderUserDetails?:ResolverInputTypes["CreditBuilderUserDetails"],
	/** The user's relationship with Brigit's Credit Monitoring product. */
	creditMonitoringEnrollment?:ResolverInputTypes["CreditMonitoringEnrollment"],
	/** Promo card v1 offers from the dynamic data model */
	dynamicPromoCardV1Offers?:ResolverInputTypes["PromoCardOffer"],
	/** The user's email address. */
	email?:boolean | `@${string}`,
	/** The user's email address that is in the process of being verified. */
	emailToVerify?:boolean | `@${string}`,
	/** Extension credits for user - they can use this to extend the loan due date for any outstanding loan */
	extensionCredits?:boolean | `@${string}`,
	/** The user's first name. */
	firstName?:boolean | `@${string}`,
	/** Flags represent states that a user may be in. Historically they have been used to mark users for inclusion or exclusion in tests, though we hope to move to an alternative way of managing that. */
	flags?:boolean | `@${string}`,
	/** Get if user is in a state where they have been approved for a free instant cash transfer. */
	freeInstantCashTransferState?:boolean | `@${string}`,
	/** Customizable banner to display to user on the home screen */
	homeScreenBanner?:ResolverInputTypes["HomeScreenBanner"],
	/** User properties related to Instant Cash experiments */
	iCExperimentProperties?:ResolverInputTypes["ICExperimentProperties"],
	/** The user's ID is their unique identifier within Brigit. While mostly used internally, it may need to be provided to external services to link a user's account to them. */
	id?:boolean | `@${string}`,
	/** Whether the user is enrolled, or was previously enrolled, in Brigit's Identity Protection product. */
	identityProtectionEnrollmentStatus?:boolean | `@${string}`,
incomeProfile?: [{	filterOne: ResolverInputTypes["SalaryAggregateFilterCategory"],	filterTwo?: ResolverInputTypes["SalaryAggregateFilterCategory"] | undefined | null},ResolverInputTypes["IncomeProfile"]],
	/** The user's prospective and current details related with the Installment Loans product */
	installmentLoans?:ResolverInputTypes["InstallmentLoans"],
	/** The user's last name. */
	lastName?:boolean | `@${string}`,
	/** This field is no longer relevant as we have rolled out tiered subscriptions at 100%. It remains here as a placeholder until we set the minimum app version to >= v704, which is the version where we removed this field from the query in the FE */
	legacyProposedNextMonthDiscountedAmount?:boolean | `@${string}`,
	/** The user's prospective and current details related with the Installment Loans product */
	lineOfCredit?:ResolverInputTypes["LineOfCredit"],
	/** The user's location state */
	locationState?:ResolverInputTypes["UsaState"],
	/** A list of MarketplaceOffers by Category */
	marketplaceOffers?:ResolverInputTypes["MarketplaceOfferByCategory"],
	/** Lists of marketplace offers. */
	marketplaceOffersV2?:ResolverInputTypes["MarketplaceOffersV2"],
	/** MoneyLionEngineOffers */
	moneyLionEngineOffers?:ResolverInputTypes["MoneyLionEngineOffers"],
	/** In-app notification center */
	notificationCenter?:ResolverInputTypes["NotificationCenter"],
	/** Details related to the user's payroll accounts */
	payrollAccounts?:ResolverInputTypes["Payroll_32_Accounts"],
	/** Details related to the user's payroll accounts */
	payrollAccountsV2?:ResolverInputTypes["Payroll_32_Accounts_32_V2"],
	/** Details related to the user's payroll accounts */
	payrollAccountsWithOutcome?:ResolverInputTypes["Payroll_32_Account_32_Fetcher_32_With_32_Outcome"],
	/** Details related to the user's payroll connection */
	payrollConnection?:ResolverInputTypes["PayrollConnection"],
	/** The user's bank transactional data insights. */
	pfmSummary?:ResolverInputTypes["PfmSummary"],
	/** The user's phone number. */
	phoneNumber?:boolean | `@${string}`,
	/** The user's phone number that is in the process of being verified. */
	phoneNumberToVerify?:boolean | `@${string}`,
	/** Variant on premium upsell card to display to users on the home screen */
	premiumUpsellCardVariant?:boolean | `@${string}`,
	/** Promo card v1 and v2 offers from the legacy data model */
	promoCardOffers?:ResolverInputTypes["PromoCardOffer"],
promotion?: [{	level: ResolverInputTypes["SubscriptionTierLevel"]},ResolverInputTypes["Promotion"]],
	/** Details related to the user's advances */
	protectionDetails?:ResolverInputTypes["ProtectionDetails"],
	/** The user's referral link. */
	referralLink?:boolean | `@${string}`,
	/** The user's list of referrals. */
	referrals?:ResolverInputTypes["Referral"],
	/** Includes the user's current payment plan, and what features they have access to as part of that. */
	subscription?:ResolverInputTypes["Subscription"],
	/** Return the subscription fee schedule text dynamically for the activate screen https://brigit.atlassian.net/browse/ENG-9573. */
	subscriptionFeeBillingScheduleText?:boolean | `@${string}`,
subscriptionScheduleText?: [{	level?: ResolverInputTypes["SubscriptionTierLevel"] | undefined | null},boolean | `@${string}`],
	/** The user's underwriting details - holds details around the underwriting report such as if a user is eligible for an Instant Cash advance */
	underwritingDetails?:ResolverInputTypes["UnderwritingDetails"],
	/** The user's Credit Builder debt persona as a result of user engagement quiz */
	userCreditBuilderDebtPersona?:ResolverInputTypes["UserDebtPersona"],
	/** The user's debt persona as a result of user engagement quiz */
	userDebtPersona?:ResolverInputTypes["UserDebtPersona"],
	/** The user's consent prompts to display */
	userPrompts?:ResolverInputTypes["UserPrompts"],
		__typename?: boolean | `@${string}`
}>;
	/** User's debt persona information as a result of user engagement quiz */
["UserDebtPersona"]: AliasType<{
	/** The user's debt persona */
	debtPersonaType?:boolean | `@${string}`,
	/** The ID of the quiz */
	quizId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserImpression"]: AliasType<{
	/** Duration in milliseconds */
	durationMs?:boolean | `@${string}`,
	/** UI element tracked */
	elementName?:boolean | `@${string}`,
	/** Impression end time */
	endedAt?:boolean | `@${string}`,
	/** Foreign keys to other data useful for understanding the impression */
	eventForeignKeys?:boolean | `@${string}`,
	/** Impression ID */
	impressionId?:boolean | `@${string}`,
	/** Screen of the tracked event */
	screenName?:boolean | `@${string}`,
	/** Impression start time */
	startedAt?:boolean | `@${string}`,
	/** User ID */
	userId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Represents a consent experience the user must undergo to opt into a certain feature set */
["UserPrompt"]: AliasType<{
	/** The 0 indexed display rank. Lower the number, the 'higher' the priority */
	displayRank?:boolean | `@${string}`,
	/** The user prompt type */
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A list of user prompts to display consent experiences for certain features */
["UserPrompts"]: AliasType<{
	/** List of prompt objects containing the prompt type and the display priority */
	prompts?:ResolverInputTypes["UserPrompt"],
		__typename?: boolean | `@${string}`
}>;
	/** Represents the details for a installment loan */
["details"]: AliasType<{
	/** Is auto pay enabled or disabled */
	autoPayStatus?:boolean | `@${string}`,
	/** Field that denotes if a user should be able to see the ability to make a 'manual' catchup payment on the FE */
	catchupEligibility?:boolean | `@${string}`,
	/** Name of bank account installment loan was disbursed to */
	disbursalBankAccountName?:boolean | `@${string}`,
	/** Date loan was disbursed */
	disbursalDate?:boolean | `@${string}`,
	/** Status of loan disbursal */
	disbursalStatus?:boolean | `@${string}`,
	/** Estimated delivery for the principal payment credit if loan is not delivered */
	estimatedDeliveryDateForPrincipalCredit?:boolean | `@${string}`,
	/** List of late fees accrued with a fee amount and the date it accrued */
	lateFees?:ResolverInputTypes["FrontendOverduePayment"],
	/** Late fees due in cents */
	lateFeesDue?:boolean | `@${string}`,
	/** Next scheduled principal payment if it exists */
	nextScheduledPrincipalPayment?:ResolverInputTypes["FrontendPrincipalPayment"],
	/** Field that denotes if a user should be able to see the ability to make a 'manual' prepay or pay-off payment on the FE */
	prepayAndPayoffEligibility?:boolean | `@${string}`,
	/** Principal amount that is past due and overdue in cents */
	principalPaymentOutstanding?:boolean | `@${string}`,
	/** Remaining due principal payments on the schedule that haven't been processed yet */
	remainingDuePayments?:ResolverInputTypes["FrontendPrincipalPayment"],
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalPaymentsInCents?:boolean | `@${string}`,
	/** Total paid amount so far in cents */
	totalPrincipalAmountInCents?:boolean | `@${string}`,
	/** Installment Loan Id */
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BankProvider"]:BankProvider;
	["CampaignPromptType"]:CampaignPromptType;
	["ChangeSubscriptionTierOutcome"]:ChangeSubscriptionTierOutcome;
	["CopyColor"]:CopyColor;
	["CreatePayrollAccountOutcome"]:CreatePayrollAccountOutcome;
	["CreditBuilderEnrollmentIneligibilityReason"]:CreditBuilderEnrollmentIneligibilityReason;
	/** The type of enrollment that currently applies to the user. */
["CreditBuilderEnrollmentType"]:CreditBuilderEnrollmentType;
	["CreditBuilderPopulationType"]:CreditBuilderPopulationType;
	["CreditLimitBoostStatus"]:CreditLimitBoostStatus;
	["CreditMonitoringEnrollmentStatus"]:CreditMonitoringEnrollmentStatus;
	["CreditRating"]:CreditRating;
	["DayOfWeek"]:DayOfWeek;
	["DebtPersonaType"]:DebtPersonaType;
	["DeleteBankLoginOutcome"]:DeleteBankLoginOutcome;
	["DisableDirectDepositOutcome"]:DisableDirectDepositOutcome;
	["EducationLevel"]:EducationLevel;
	["EmploymentPayFrequency"]:EmploymentPayFrequency;
	["EmploymentStatus"]:EmploymentStatus;
	["ExperimentName"]:ExperimentName;
	["FrontEndCreditMonitoringEnrollment"]:FrontEndCreditMonitoringEnrollment;
	["FrontendAccountApprovalState"]:FrontendAccountApprovalState;
	["FrontendBankConnectionStatus"]:FrontendBankConnectionStatus;
	["FrontendBankInstitution"]:FrontendBankInstitution;
	["FrontendCBScheduleVersionReason"]:FrontendCBScheduleVersionReason;
	["FrontendCBScheduleVersionStatus"]:FrontendCBScheduleVersionStatus;
	["FrontendCreditBuilderEnrollmentStatus"]:FrontendCreditBuilderEnrollmentStatus;
	["FrontendCreditBuilderPaymentRetryStrategyType"]:FrontendCreditBuilderPaymentRetryStrategyType;
	["FrontendCreditBuilderReminderType"]:FrontendCreditBuilderReminderType;
	["FrontendCreditBuilderSweepStakesQualificationType"]:FrontendCreditBuilderSweepStakesQualificationType;
	["FrontendCreditBuilderTransactionSource"]:FrontendCreditBuilderTransactionSource;
	["FrontendCreditBuilderTransactionStatus"]:FrontendCreditBuilderTransactionStatus;
	["FrontendCreditBuilderTransactionType"]:FrontendCreditBuilderTransactionType;
	["FrontendCreditLimitBoostStatus"]:FrontendCreditLimitBoostStatus;
	["FrontendCreditRiskState"]:FrontendCreditRiskState;
	["FrontendDebitCardStatus"]:FrontendDebitCardStatus;
	["FrontendInitiateCreditDrawStatus"]:FrontendInitiateCreditDrawStatus;
	["FrontendInstallmentLoanChargePolicy"]:FrontendInstallmentLoanChargePolicy;
	["FrontendInstallmentLoanPaymentStrategy"]:FrontendInstallmentLoanPaymentStrategy;
	["FrontendInstallmentLoansAccountCreationStatus"]:FrontendInstallmentLoansAccountCreationStatus;
	["FrontendInstallmentLoansAutoPay"]:FrontendInstallmentLoansAutoPay;
	["FrontendInstallmentLoansCurrentStatus"]:FrontendInstallmentLoansCurrentStatus;
	["FrontendInstallmentLoansDisbursalStatus"]:FrontendInstallmentLoansDisbursalStatus;
	["FrontendInstallmentLoansKYCDecisionType"]:FrontendInstallmentLoansKYCDecisionType;
	["FrontendInstallmentLoansManualPaymentEligibilityStatus"]:FrontendInstallmentLoansManualPaymentEligibilityStatus;
	["FrontendInstallmentLoansPayFrequency"]:FrontendInstallmentLoansPayFrequency;
	["FrontendInstallmentLoansPaymentOutcome"]:FrontendInstallmentLoansPaymentOutcome;
	["FrontendInstallmentLoansStatus"]:FrontendInstallmentLoansStatus;
	["FrontendInstallmentLoansUpdateAutoPayOutcome"]:FrontendInstallmentLoansUpdateAutoPayOutcome;
	["FrontendLocAccountCreationStatus"]:FrontendLocAccountCreationStatus;
	["FrontendLocAccountStatus"]:FrontendLocAccountStatus;
	["FrontendLocAutopayStatus"]:FrontendLocAutopayStatus;
	["FrontendLocInitiatePaymentStatus"]:FrontendLocInitiatePaymentStatus;
	["FrontendLocKycRequirementStatus"]:FrontendLocKycRequirementStatus;
	["FrontendLocKycUnfulfillmentReasons"]:FrontendLocKycUnfulfillmentReasons;
	["FrontendLocMinPaymentStatus"]:FrontendLocMinPaymentStatus;
	["FrontendLocPaymentAttemptType"]:FrontendLocPaymentAttemptType;
	["FrontendLocUnderwritingStatus"]:FrontendLocUnderwritingStatus;
	["FrontendLocUserInitiatedPaymentChargeType"]:FrontendLocUserInitiatedPaymentChargeType;
	["FrontendMobileVersionSupportType"]:FrontendMobileVersionSupportType;
	["FrontendOngoingRefreshStatus"]:FrontendOngoingRefreshStatus;
	["FrontendPaymentAuthFlow"]:FrontendPaymentAuthFlow;
	["FrontendPaymentStatus"]:FrontendPaymentStatus;
	["FrontendPayrollAccountConnectionStatus"]:FrontendPayrollAccountConnectionStatus;
	["FrontendReturnType"]:FrontendReturnType;
	["FrontendSampleKeyType"]:FrontendSampleKeyType;
	["HeroIconSize"]:HeroIconSize;
	["IdentityProtectionEnrollmentStatus"]:IdentityProtectionEnrollmentStatus;
	["ImpressionableElement"]:ImpressionableElement;
	["InputSalaryDataOutcome"]:InputSalaryDataOutcome;
	["KeyboardType"]:KeyboardType;
	["LoanPurpose"]:LoanPurpose;
	["LoanTimelineStatus"]:LoanTimelineStatus;
	["LoanTimelineType"]:LoanTimelineType;
	["LoginFailureReason"]:LoginFailureReason;
	["MarkNotificationCenterEntriesViewedOutcome"]:MarkNotificationCenterEntriesViewedOutcome;
	["MarketplaceBadgeType"]:MarketplaceBadgeType;
	["MarketplaceFeature"]:MarketplaceFeature;
	["ModelRank"]:ModelRank;
	["NonRepaymentReason"]:NonRepaymentReason;
	["OneTimePaymentOutcome"]:OneTimePaymentOutcome;
	["OngoingRefreshStatus"]:OngoingRefreshStatus;
	["OverdraftLikelihood"]:OverdraftLikelihood;
	["PayFrequency"]:PayFrequency;
	["PayrollAccountConnectionStatus"]:PayrollAccountConnectionStatus;
	["PayrollAccountFetcherOutcome"]:PayrollAccountFetcherOutcome;
	["PayrollProvider"]:PayrollProvider;
	["PersistedIncomeStatus"]:PersistedIncomeStatus;
	["PremiumUpsellCardVariant"]:PremiumUpsellCardVariant;
	["Product"]:Product;
	["ProductSubType"]:ProductSubType;
	["PromptResponseType"]:PromptResponseType;
	["PropertyStatus"]:PropertyStatus;
	["ProtectionMediumType"]:ProtectionMediumType;
	["ProtectionStatusV4"]:ProtectionStatusV4;
	["QuizFeedbackType"]:QuizFeedbackType;
	["QuizName"]:QuizName;
	["QuizStepType"]:QuizStepType;
	["RecentScoreChangeText"]:RecentScoreChangeText;
	["RegisterBankLoginMaintainingExistingLoginOutcome"]:RegisterBankLoginMaintainingExistingLoginOutcome;
	["RepayAdvanceOutcome"]:RepayAdvanceOutcome;
	["RequestManualDisbursalOutcome"]:RequestManualDisbursalOutcome;
	["ResendAuthVerificationCodeOutcome"]:ResendAuthVerificationCodeOutcome;
	["SalaryAggregateFilterCategory"]:SalaryAggregateFilterCategory;
	["SamplingTrigger"]:SamplingTrigger;
	["ScheduleCreditBuilderSameDayManualPaymentOutcome"]:ScheduleCreditBuilderSameDayManualPaymentOutcome;
	["ScoreChangeCallToAction"]:ScoreChangeCallToAction;
	["SubmitUserPromptOutcome"]:SubmitUserPromptOutcome;
	["SubscriptionFeeSchedule"]:SubscriptionFeeSchedule;
	["SubscriptionStatus"]:SubscriptionStatus;
	["SubscriptionTierExperimentName"]:SubscriptionTierExperimentName;
	["SubscriptionTierLevel"]:SubscriptionTierLevel;
	["SwitchProtectedLoginToExistingLoginOutcome"]:SwitchProtectedLoginToExistingLoginOutcome;
	["SwitchProtectedLoginToExistingNonConnectedLoginOutcome"]:SwitchProtectedLoginToExistingNonConnectedLoginOutcome;
	["TablessMarketplaceCategoryType"]:TablessMarketplaceCategoryType;
	["TextFormatter"]:TextFormatter;
	["TextInputValidators"]:TextInputValidators;
	["TextInputWarning"]:TextInputWarning;
	["TrackNewImpressionsOutcome"]:TrackNewImpressionsOutcome;
	["TrackNotificationCenterEntryClickOutcome"]:TrackNotificationCenterEntryClickOutcome;
	["UXPromptResponseType"]:UXPromptResponseType;
	["UnderwritingCategory"]:UnderwritingCategory;
	["UnderwritingRule"]:UnderwritingRule;
	["UpdateBankAccountJointStatusOutcome"]:UpdateBankAccountJointStatusOutcome;
	["UserFlag"]:UserFlag;
	["UserFriendlyCardNetwork"]:UserFriendlyCardNetwork;
	/** The type of prompt a user will see */
["UserPromptType"]:UserPromptType;
	/** java.math.BigDecimal */
["BigDecimal"]:unknown;
	/** A calendar day, in ISO-8601 "yyyy-MM-dd" format */
["Date"]:unknown;
	/** Long type */
["Long"]:unknown;
	/** UUID V4 format */
["UUID"]:unknown
  }

export type ModelTypes = {
    ["LoginUnion"]:ModelTypes["LoginFailure"] | ModelTypes["LoginSuccess"];
	["RegisterOneTimeCardUnion"]:ModelTypes["RegisterOneTimeCardInvalidInput"] | ModelTypes["RegisterOneTimeCardSuccess"];
	["AccountConnectedResponseFetcher"]: {
		/** The provider ID of the connected payroll account */
	payrollAccountId: string
};
	["ActiveBankLogins"]: {
		/** List of active logins for user */
	logins: Array<ModelTypes["FrontendBankLogin"]>
};
	["ApprovedLoanAmounts"]: {
		/** Approved loan amounts for user - used for frontend display purposes */
	approvedLoanAmounts: Array<ModelTypes["BigDecimal"] | undefined>
};
	/** Details about the user's auto advance configuration */
["AutoAdvanceDetails"]: {
		/** The amount of the user's auto advance */
	amount?: ModelTypes["BigDecimal"] | undefined,
	/** The estimated delivery date if an advance was taken today */
	deliveryDate?: string | undefined,
	/** The estimated due date if an advance was taken today */
	dueDate?: string | undefined,
	/** Whether the user has auto advances enabled for disbursal when the user's balance is low */
	isAutoEnabled: boolean,
	/** Whether the user is due to receive an auto advance */
	willAutoDisburse: boolean
};
	["AverageScoreChangeDetailsFetcher"]: {
		/** An Optional<Integer> representing the average score change for this score bucket and sample period. */
	averageScoreChange?: number | undefined,
	/** The ending date the average was computed. */
	samplePeriodEnd: string,
	/** The starting date the average was computed. */
	samplePeriodStart: string
};
	/** Predictions based on the user's linked bank account such as their income sources and likelihood to overdraft. */
["BankAccountPrediction"]: {
		/** List of the user's income sources based on their linked bank account. */
	incomeSources: Array<ModelTypes["Income_32_Source"] | undefined>,
	/** The user's next predicted payday based on their linked bank account. */
	nextPayday?: string | undefined,
	/** The likelihood the user's balance will drop to 0 soon. */
	overdraftLikelihood?: ModelTypes["OverdraftLikelihood"] | undefined,
	/** The date we predict the user's balance to drop to 0. */
	zeroDate?: string | undefined
};
	["BankConnection"]: {
		/** The protected bank account mask */
	accountMask?: string | undefined,
	/** The protected bank account's available balance */
	balanceAvailable?: ModelTypes["BigDecimal"] | undefined,
	/** The protected bank account's current balance */
	balanceCurrent?: ModelTypes["BigDecimal"] | undefined,
	/** Bank login ID */
	bankLoginId: string,
	/** Provider associated with bank connection (Plaid or Capital One) */
	bankProvider: ModelTypes["BankProvider"],
	/** Checking accounts that belong to the user protected login */
	checkingAccounts: Array<ModelTypes["FrontendAccountWithCard"]>,
	/** The bank institution details for the user's current bank connection. */
	institution: ModelTypes["BankInstitution"],
	/** The last time we call the bank data provider API */
	lastUpdatedTimestamp?: string | undefined,
	/** The protected bank account's payment auth flow */
	paymentAuthFlow?: ModelTypes["FrontendPaymentAuthFlow"] | undefined,
	/** The protected bank account ID */
	protectedAccountId?: string | undefined,
	/** Bank connection status. */
	status?: ModelTypes["FrontendBankConnectionStatus"] | undefined
};
	["BankInstitution"]: {
		/** The protected bank account's institution id. Only populated for Plaid users. */
	id?: string | undefined,
	/** The protected bank account's institution name */
	institutionName: string,
	/** The institution logo URL */
	logoUrl?: string | undefined,
	/** The bank institution type */
	type?: ModelTypes["FrontendBankInstitution"] | undefined
};
	["ChangeSubscriptionTierResponse"]: {
		/** The result of the attempt to change the user's subscription tier. */
	outcome: ModelTypes["ChangeSubscriptionTierOutcome"],
	/** The user's subscription after the request is completed. If the outcome is not SUCCESS, the subscription will be the same as it was previously. */
	updatedSubscription: ModelTypes["Subscription"]
};
	["CompleteQuizFetcher"]: {
		/** The id of the quiz */
	id?: string | undefined
};
	["CreatePayrollAccountResponse"]: {
		/** The provider ID of the payroll account created */
	payrollAccountId: string
};
	["CreatePayrollAccountResponseV2"]: {
		/** The outcome of creating the user's payroll account. */
	outcome: ModelTypes["CreatePayrollAccountOutcome"]
};
	["CreateTargetDepositDestinationResponse"]: {
		/** Encrypted version of the user's target deposit destination */
	encryptedDDSConfig: string
};
	/** Details on which type of enrollment is applicable to the user and any reasons they are ineligible for that enrollment type. */
["CreditBuilderEnrollmentEligibility"]: {
		/** Whether the user is eligible for the type of enrollment. If this is false, the ineligibilityDetails will have the reasons they are ineligible. */
	eligible: boolean,
	/** The type of enrollment that the user's eligibility was checked for. */
	enrollmentType: ModelTypes["CreditBuilderEnrollmentType"],
	/** The details on why the user is ineligible for the type of enrollment. */
	ineligibilityDetails: Array<ModelTypes["CreditBuilderEnrollmentIneligibilityDetails"]>
};
	/** Information on a reason that a user is ineligible for a given enrollment type. */
["CreditBuilderEnrollmentIneligibilityDetails"]: {
		/** A textual description of the reason that can be displayed to the user. */
	description: string,
	/** The reason the user is ineligible. */
	reason: ModelTypes["CreditBuilderEnrollmentIneligibilityReason"]
};
	/** Contains global configuration data for the Credit Builder system. */
["CreditBuilderGlobalConfiguration"]: {
		/** The USA states in which Credit Builder is not available. */
	unsupportedStates: Array<ModelTypes["UsaState"]>
};
	/** A credit builder installment loan is a secured loan which the user has taken out to build their credit. The loan contains monthly payments which are reported to the bureaus for credit score calculation. */
["CreditBuilderInstallmentLoan"]: {
		/** User's external contributions minus disbursals */
	availableSavingsBalance: ModelTypes["BigDecimal"],
	/** The charge policy configured for the credit builder loan. This could either be AUTO, meaning standard payments are automatically deducted from the user's external account or MANUAL, meaning the user would need to initiate standard payments themselves. */
	chargePolicy: ModelTypes["FrontendInstallmentLoanChargePolicy"],
	/** The date which the Credit Builder loan was closed on. If the loan was never closed, this should be empty. */
	closedOn?: string | undefined,
	/** The disbursals taken against the loans savings balance. */
	disbursals: Array<ModelTypes["CreditBuilderTransaction"]>,
	/** The date which the Credit Builder loan is expected to close if all payments are completed successfully. */
	expectedClosedDate: string,
	/** The numeric value for the day of the month the first installment payment is due. */
	expectedDayOfMonthForBilling: number,
	/** The amount user has set to debit their external bank account every month to pay off the installment in dollars. */
	externalMonthlyContributionAmountInDollars: ModelTypes["BigDecimal"],
	/** A List of the credit builder installment loan payment schedule dates and the amount due */
	installmentLoanPaymentSchedule: Array<ModelTypes["InstallmentLoanPaymentSchedule"]>,
	/** The amount user has set to debit from the Brigit restricted savings account every month to pay off the installment in dollars. */
	internalMonthlyContributionAmountInDollars: ModelTypes["BigDecimal"],
	/** The date which the Credit Builder loan was opened. */
	openedOn: string,
	/** Details about past due payments, if applicable. */
	pastDuePayment?: ModelTypes["PastDuePayment"] | undefined,
	/** The payment schedule strategy stored in the cb enrollments table for the credit builder loan. If the payment strategy is empty then it calculates the strategy on the fly and it could either be MONTHLY, meaning a user had no income and they selected a specific day of the month to make CB payments or INCOME, where a CB payment due date corresponds with a users payments */
	paymentStrategyForSchedule: ModelTypes["FrontendInstallmentLoanPaymentStrategy"],
	/** The payments that have been made towards the loan. */
	payments: Array<ModelTypes["CreditBuilderTransaction"]>,
	/** Details about possible pre-payments user can make toward their CB account. */
	prePayment?: ModelTypes["PrePayment"] | undefined,
	/** A proposed list of NEW installment loan payment schedule dates for a user in the middle of their term due */
	proposedDraftInstallmentLoanPaymentSchedule: Array<ModelTypes["InstallmentLoanPaymentSchedule"]>,
	/** A list of reminders for the user's installment loan account. This list may include upcoming scheduled payment, past due balance,  and same day manual payment reminders. */
	reminders: Array<ModelTypes["CreditBuilderReminder"]>,
	/** Payment details that are populated when user has manual payment method and payment is due today */
	sameDatePayment?: ModelTypes["SameDayPayment"] | undefined,
	/** Details regarding user's CB savings */
	savingsDetails: ModelTypes["CreditBuilderSavingsDetails"],
	/** Details regarding user's credit score */
	scoreDetails: ModelTypes["CreditBuilderScoreDetails"],
	/** The fixed number of months for the Credit Builder loan term. */
	termInMonths: ModelTypes["Long"],
	/** The total loan amount for the given Credit Builder enrollment term. */
	totalLoanAmountInDollars: ModelTypes["BigDecimal"],
	/** The total monthly amount between external and savings contributions that goes to paying off the installment in dollars. */
	totalMonthlyPaymentAmountInDollars: ModelTypes["BigDecimal"]
};
	/** A reminder associated with the user's open installment loan account. */
["CreditBuilderReminder"]: {
		/** The description of the reminder to show the user. */
	description: string,
	/** The type of reminder */
	type: ModelTypes["FrontendCreditBuilderReminderType"]
};
	/** Details about user's credit score */
["CreditBuilderSavingsDetails"]: {
		/** The user’s all time total savings across all CB accounts ever (how much the user contributed to all of their Credit Builder account(s), regardless of any subsequent disbursals */
	allTimeSavings: ModelTypes["BigDecimal"]
};
	/** Details about user's credit score */
["CreditBuilderScoreDetails"]: {
		/** If CB account is open, delta is the difference between first score since first ever CB enrollment and latest credit score on file. If CB account is closed, delta is the difference between first score since first ever CB enrollment and last score before a user closed their CB account. */
	allTimeScoreDelta?: ModelTypes["Long"] | undefined
};
	/** A transaction that was processed for a Credit Builder installment loan. */
["CreditBuilderTransaction"]: {
		/** The amount of the transaction in dollars. */
	amountInDollars: ModelTypes["BigDecimal"],
	/** The due date of the payment */
	dueOn?: string | undefined,
	/** The unique id of the transaction. */
	installmentLoanPaymentId?: ModelTypes["Long"] | undefined,
	/** The source account type from which the transaction originated. */
	source: ModelTypes["FrontendCreditBuilderTransactionSource"],
	/** The status of the transaction at the current point in time. */
	status: ModelTypes["FrontendCreditBuilderTransactionStatus"],
	/** The type of the transaction. Either a payment or a disbursal. */
	type: ModelTypes["FrontendCreditBuilderTransactionType"],
	/** The time that the transaction was last updated. */
	updatedAt?: string | undefined
};
	/** User details related to credit builder */
["CreditBuilderUserDetails"]: {
		/** The user's Credit Builder population type to determine whether to show CB related content. */
	populationType?: ModelTypes["CreditBuilderPopulationType"] | undefined
};
	["CreditLimitBoostDetails"]: {
		/** The amount of the credit limit boost in dollars. */
	boostAmount: ModelTypes["BigDecimal"],
	/** Whether the boost is available to be used for a loan which means the boost status is equal to OFFERED or ACCEPTED. */
	boostAvailable: boolean,
	/** List of advance options that apply part or all of the credit limit boost. */
	boostedAdvanceOptions: Array<ModelTypes["BigDecimal"] | undefined>,
	/** User's max advance amount plus their credit limit boost. */
	boostedMaxLoanAmount: ModelTypes["BigDecimal"],
	/** Unique ID of the credit limit boost */
	creditLimitBoostId: string,
	/** Current status of the credit limit boost */
	creditLimitBoostStatus: ModelTypes["CreditLimitBoostStatus"],
	/** Whether the boost should be offered to the user which means the boost status equals OFFERED. */
	shouldOfferBoost: boolean
};
	/** A credit monitoring enrollment is a user's relationship with Brigit's Credit Monitoring product, which maintains a history of their credit scores while they are enrolled. */
["CreditMonitoringEnrollment"]: {
		/** The credit scores that Brigit has collected for the user while they have been enrolled in credit monitoring. Scores are sorted by date, starting with the earliest scores. */
	creditScores: Array<ModelTypes["CreditScore"]>,
	/** Describes whether a user is currently, or has been, enrolled in credit monitoring with Brigit. */
	enrollmentStatus: ModelTypes["CreditMonitoringEnrollmentStatus"],
	/** Representation of the user's recent credit score delta */
	recentScoreChange?: ModelTypes["RecentScoreChangeFetcher"] | undefined,
	/** Representation of how the user's credit score journey has progressed since starting credit builder relative to their peer group. */
	scoreMarker?: ModelTypes["CreditScoreMarkerFetcher"] | undefined
};
	/** A credit monitoring enrollment is a user's relationship with Brigit's Credit Monitoring product, which maintains a history of their credit scores while they are enrolled. */
["CreditMonitoringUser"]: {
		authToken: string
};
	/** A credit score is a numerical rating representing the perceived creditwothiness of an individual, as evaluated by one of the major US credit bureaus on a particular date. */
["CreditScore"]: {
		/** The date that this score was calculated, in yyyy-MM-dd format. */
	date: string,
	/** The numerical value of the credit score, ranging from about 250 for a poor score to about 900 for a great score. */
	score: ModelTypes["Long"]
};
	["CreditScoreMarkerFetcher"]: {
		/** The score bucket that the user falls into (e.g. 300-349, 350-399, etc). */
	averageScoreChangeDetails: ModelTypes["AverageScoreChangeDetailsFetcher"],
	/** A number representing the days since latest CB enrollment. */
	days: ModelTypes["Long"],
	/** A number representing the period the user is in (e.g. 1, 2, 3, ..., 24). */
	period: ModelTypes["Long"],
	/** The score bucket that the user falls into (e.g. 300-349, 350-399, etc). */
	scoreBucket: ModelTypes["ScoreBucketFetcher"],
	/** The value of the users score change since opening their CB account. */
	userScoreChange: ModelTypes["Long"]
};
	["DeleteBankLogin"]: {
		/** An Enum denoting if the outcome of deleting the bank login was successful or failure */
	outcome: ModelTypes["DeleteBankLoginOutcome"]
};
	["DeletePayrollAccountResponse"]: {
		/** The ID of the payroll account being deleted */
	payrollAccountId: string
};
	["DisableDirectDepositsResponse"]: {
		/** The outcome of disabling the user's direct deposit. */
	outcome: ModelTypes["DisableDirectDepositOutcome"]
};
	["DisablePayrollAccountResponse"]: {
		/** The provider ID of the payroll account being disabled */
	payrollAccountId: string
};
	/** A discount is a temporary reduction in the price of a subscription. */
["Discount"]: {
		/** If present, contains additional text to show users to explain the discount, such as the date range for which it applies, how it compares to the typical price, or any other information the user should know. */
	disclaimer?: string | undefined,
	/** The price, in US dollars, that will be charged for subscription payments that this discount applies to. */
	discountedAmount: ModelTypes["BigDecimal"],
	/** The estimated dates of upcoming subscription payments that this discount will be applied to. */
	discountedPaymentDates: Array<ModelTypes["Date"]>
};
	/** Fetcher for Dynamic Promo Card offers */
["DynamicPromoCards"]: {
		/** Affiliate link URL for the offer */
	affiliateLinkUrl?: string | undefined,
	/** List of background colors for the offer */
	backgroundColors?: Array<string | undefined> | undefined,
	/** Copy color for the offer */
	copyColor?: ModelTypes["CopyColor"] | undefined,
	/** Call-to-action copy for the offer */
	ctaCopy?: string | undefined,
	/** Description of the offer */
	description?: string | undefined,
	/** URL for the offer icon */
	iconUrl?: string | undefined,
	/** Unique identifier for the offer */
	id?: ModelTypes["Long"] | undefined,
	/** URL for the offer logo */
	logoUrl?: string | undefined,
	/** Name of the offer */
	offerName?: string | undefined
};
	["FilteredSalaryData"]: {
		/** The filter used to aggregate the salary data */
	filter: string,
	/** The average salary for users specific to this filter */
	salary: number
};
	/** Debit card information */
["FrontendAccountWithCard"]: {
		/** The bank account ID associated with the card */
	bankAccountId: string,
	/** Associated debit card info */
	card?: ModelTypes["FrontendDebitCardInfo"] | undefined,
	/** Account current balance */
	currentBalance: ModelTypes["BigDecimal"],
	/** Whether or not account is eligible to be selected as the protected account */
	isEligibleProtectedAccount: boolean,
	/** Account joint status */
	isJoint: boolean,
	/** Account removal status */
	isRemoved: boolean,
	/** Account mask */
	mask: string,
	/** The name associated with the card */
	name: string
};
	/** Bank account associated with bank login */
["FrontendBankAccount"]: {
		/** Available balance */
	availableBalance?: ModelTypes["BigDecimal"] | undefined,
	/** Current balance */
	currentBalance?: ModelTypes["BigDecimal"] | undefined,
	/** Bank account ID */
	id: string,
	/** Is joint */
	isJoint: boolean,
	/** Boolean representing whether login has been identified as the user's recommended protected account. Refer to: https://brigit.atlassian.net/browse/ENG-13002 */
	isRecommendedProtectedAccount: boolean,
	/** Bank account mask */
	mask: string,
	/** Bank account name */
	name: string
};
	["FrontendBankLogin"]: {
		/** Checking accounts associated with login */
	bankAccounts: Array<ModelTypes["FrontendBankAccount"] | undefined>,
	/** Time bank login was created */
	createdAt?: string | undefined,
	/** Bank login ID */
	id: string,
	/** Institution details */
	institution: ModelTypes["BankInstitution"],
	/** Institution name */
	institutionName: string,
	/** Boolean representing whether login has been identified as the user's recommended protected login. Refer to: https://brigit.atlassian.net/browse/ENG-13002 */
	isRecommendedProtectedLogin: boolean,
	/** Bank connection status. */
	status: ModelTypes["FrontendBankConnectionStatus"]
};
	["FrontendDebitCardInfo"]: {
		/** Card block status */
	blockStatus?: string | undefined,
	/** Card's nick name and normally is it's last 4 */
	cardNickname: string,
	/** Card's expiration date */
	expirationDate?: string | undefined,
	/** Card network such as Visa, Mastercard, etc */
	network?: ModelTypes["UserFriendlyCardNetwork"] | undefined,
	/** Payment method ID associated with the card */
	paymentMethodId: ModelTypes["UUID"],
	/** Reflects micro-deposit verification and status of government id. */
	status: ModelTypes["FrontendDebitCardStatus"]
};
	["FrontendExperimentVariantAssignment"]: {
		/** Flag to indicate whether the variant returned is the control flow. If the variant is either the
'Control' or 'Holdout', this will return as true. */
	isControlFlow: boolean,
	/** Name of the variant assigned for the sample key. */
	variantName: string,
	/** Value associated with the variant assigned for the sample key. */
	variantValue: string
};
	/** Represents a missed item which consists of all payments due on the same scheduled due date that are outstanding */
["FrontendMissedItem"]: {
		/** late fee in cents */
	lateFeeAmountInCents: ModelTypes["Long"],
	/** original principal payment in cents */
	principalPaymentAmountInCents: ModelTypes["Long"],
	/** Original scheduled date in iso format */
	scheduledDate: string
};
	/** Represents a late fee with the pairing of the fee amount and date it was accrued (the original principal payment date) */
["FrontendOverduePayment"]: {
		/** Date accrued in iso format */
	dateAccrued: string,
	/** fee in cents */
	feeAmountInCents: ModelTypes["Long"],
	/** original principal payment in cents */
	principalPaymentAmountInCents: ModelTypes["Long"]
};
	/** Represents the pairing of the payment amount and date due for a user */
["FrontendPrincipalPayment"]: {
		/** Monthly payment in cents */
	amountInCents: ModelTypes["Long"],
	/** Payment date in iso format */
	paymentDate: string
};
	/** Report underwriting checks */
["FrontendUnderwritingCheck"]: {
		/** Underwriting rule category */
	category?: ModelTypes["UnderwritingCategory"] | undefined,
	/** Whether user has fulfilled rule or not */
	isFulfilled: boolean,
	/** Underwriting description based on if rule is fulfilled or not */
	reason: string,
	/** Underwriting rule */
	rule: ModelTypes["UnderwritingRule"]
};
	/** Controls a customizable banner we may display to users on the home screen when we want to get out some message to users with a quick turnaround. */
["HomeScreenBanner"]: {
		/** Cta to show with the banner */
	callToActionText?: string | undefined,
	/** Link url that we will open when a user clicks on the banner */
	linkUrl?: string | undefined,
	/** Main message associated with banner. */
	message: string
};
	/** Frequently Asked Questions related to IC Activation. */
["ICActivationFAQs"]: {
		/** Description of the frequently asked question. */
	description: string,
	/** Positional id returned for front end ordering purposes */
	id: number,
	/** Title of the Frequently Asked Question. */
	title: string
};
	/** Experiments related to Instant Cash */
["ICExperimentProperties"]: {
		/** Whether we should display the upsell screen or not based on the user's flag and tier history */
	shouldDisplayUpsell: boolean
};
	["IncomeProfile"]: {
		/** Primary filter used to organize salary data */
	filterOne: ModelTypes["SalaryAggregateFilterCategory"],
	/** Possible values for filter one */
	filterOneValues: Array<string | undefined>,
	/** Secondary filter used to organize salary data */
	filterTwo?: ModelTypes["SalaryAggregateFilterCategory"] | undefined,
	/** Possible values for filter two */
	filterTwoValues: Array<string | undefined>,
	/** List of data points based on the user's income */
	salaryAggregators: Array<ModelTypes["SalaryAggregators"] | undefined>
};
	/** An income source contains the user's paycheck and payday details based on their linked bank account. */
["Income_32_Source"]: {
		/** The average amount of money the user earns from this income source. */
	amountMean: ModelTypes["BigDecimal"],
	/** For users who get paid twice a month, their payday may be pinned to specific dates such as the 1st and 15th or the 15th and 30th. Day of month 1 refers to the first date that's pinned for the user's payday. The day of month 1 will be 0 if the user doesn't have a paycheck that follows this pattern. */
	dayOfMonth1: number,
	/** For users who get paid twice a month, their payday may be pinned to specific dates such as the 1st and 15th or the 15th and 30th. Day of month 2 refers to the second date that's pinned for the user's payday. The day of month 2 will be 0 if the user doesn't have a paycheck that follows this pattern. */
	dayOfMonth2: number,
	/** Day of week is the specific day the user's payday is on e.g. Thursday. If there's no specific day for that user's payday, Monday will be used as the default. */
	dayOfWeek: ModelTypes["DayOfWeek"],
	/** Early payday offset is the number of days the user's income will be deposited into their account early. */
	earlyPayOffset: number,
	/** Is end of month refers to whether or not the user's payday will fall at the end of the month since not all months have the same number of days. */
	endOfMonth: boolean,
	/** The frequency refers to how often the user gets paid such as biweekly, weekly, monthly, etc. */
	frequency: ModelTypes["PayFrequency"],
	/** This is true or false depending on if the user's payday is adjusted if it falls on a holiday. If so, then it's true; otherwise, false. */
	holidayAdjustNext: boolean,
	/** The id is the unique id for this specific user's income source. */
	id?: string | undefined,
	/** The income payer ID is similar to a bank memo for the transaction. If there are similar bank memos, they will be fuzzy matched to produce a singular income payer ID. */
	incomePayerId: string,
	/** The period mean refers to the average number of days between paychecks for this income source. This value is typically set for irregular income sources. 0 is set as the default value if this income source doesn't have a period mean. */
	periodMean: number,
	/** The rejection status is the user-friendly description for why the income source could not be verified. If the income source can be verified, then the rejection status will be an empty string. */
	rejectionStatus?: string | undefined,
	/** Status refers to the verification status of this income source e.g. VERIFIED, PENDING, UNVERIFIABLE, and BLACKLISTED. */
	status?: ModelTypes["PersistedIncomeStatus"] | undefined,
	/** Is verified is true or false depending on whether or not someone has verified this income source and confirmed it's legitimate. */
	verified?: boolean | undefined,
	/** Week of month refers to the week that the user's payday is on. For example, 1 would refer to the 1st week of the month. The week of month would equal 0 if there's no specific week the user's payday falls on. */
	weekOfMonth: number
};
	["InitiateCatchUpPaymentForInstallmentLoan"]: {
		/** An Enum denoting if the outcome of the catch up payment was successful or failure */
	outcome: ModelTypes["FrontendInstallmentLoansPaymentOutcome"]
};
	["InitiateOneTimePayment"]: {
		/** An Enum denoting if the outcome of the oneTimePayment was successful or failure */
	oneTimePaymentOutcome: ModelTypes["OneTimePaymentOutcome"]
};
	["InitiatePayOffPaymentForInstallmentLoan"]: {
		/** An Enum denoting if the outcome of the pay off payment was successful or failure */
	outcome: ModelTypes["FrontendInstallmentLoansPaymentOutcome"]
};
	["InitiatePrePaymentForInstallmentLoan"]: {
		/** An Enum denoting if the outcome of the pre payment was successful or failure */
	outcome: ModelTypes["FrontendInstallmentLoansPaymentOutcome"]
};
	["InputSalaryData"]: {
		/** Outcome denoting if adding the salary data was successful or not */
	outcome: ModelTypes["InputSalaryDataOutcome"]
};
	/** Credit builder installment loan payment schedule due dates with amount due. */
["InstallmentLoanPaymentSchedule"]: {
		/** The amount of the payment in dollars. */
	amountInDollars: ModelTypes["BigDecimal"],
	/** Payment schedule due date */
	paymentDate: string
};
	/** Represents the post disbursal details for an installment loan */
["InstallmentLoanPostDisbursalInfo"]: {
		/** Name of bank account installment loan was disbursed to */
	disbursalBankAccountName: string,
	/** Date loan was disbursed */
	disbursalDate: string,
	/** Status of loan disbursal */
	disbursalStatus: ModelTypes["FrontendInstallmentLoansDisbursalStatus"],
	/** Estimated delivery for the principal payment credit if loan is not delivered */
	estimatedDeliveryDate?: string | undefined
};
	/** Details related to both a potential and an existing installment loans user */
["InstallmentLoans"]: {
		/** Fetch if a user have an installment loan account or not.  */
	currentLoanStatus: ModelTypes["FrontendInstallmentLoansCurrentStatus"],
	/** Fetch current open installment loan details */
	currentOpenInstallmentLoan?: ModelTypes["InstallmentLoansDetails"] | undefined,
	/** Fetches all installment loans including details for a user */
	details: Array<ModelTypes["details"]>,
	/** Fetches the KYC status for a prospective IL user */
	kycDecision: ModelTypes["FrontendInstallmentLoansKYCDecisionType"],
	/** Fetches the string contents for the proposed loan agreement */
	signedLoanAgreement: string,
	/** Evaluates and fetches the loan term details for a prospective IL account */
	termRequestDetails: ModelTypes["TermRequestDetails"],
	/** Evaluates and fetches the underwriting status for a prospective IL user */
	underwritingDetails: ModelTypes["LocUnderwritingDetails"],
	/** Fetches the string contents for the proposed loan agreement */
	unsignedProposedLoanAgreement: string
};
	/** Represents the details for a installment loan */
["InstallmentLoansDetails"]: {
		/** Is auto pay enabled or disabled */
	autoPayStatus: ModelTypes["FrontendInstallmentLoansAutoPay"],
	/** Field that denotes if a user should be able to see the ability to make a and 'manual' payment on the FE */
	manualPaymentEligibilityStatus: ModelTypes["FrontendInstallmentLoansManualPaymentEligibilityStatus"],
	/** List of missed items */
	missedItems?: Array<ModelTypes["FrontendMissedItem"] | undefined> | undefined,
	/** Next scheduled principal payment if it exists */
	nextScheduledPrincipalPayment?: ModelTypes["FrontendPrincipalPayment"] | undefined,
	/** Post disbursal info */
	postDisbursalInstallmentLoanInfo: ModelTypes["InstallmentLoanPostDisbursalInfo"],
	/** Remaining due principal payments on the schedule that haven't been processed yet */
	remainingDuePayments: Array<ModelTypes["FrontendPrincipalPayment"]>,
	/** Fetches the string contents for the signed loan agreement */
	signedLoanAgreement: string,
	/** Status of the installment loan */
	status: ModelTypes["FrontendInstallmentLoansStatus"],
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalPaymentsInCents: ModelTypes["Long"],
	/** Total paid amount so far in cents */
	totalPrincipalAmountInCents: ModelTypes["Long"],
	/** Installment Loan Id */
	uuid: string
};
	/** Details related to both an existing or unopened line of credit for a user */
["LineOfCredit"]: {
		/** Credit line limit details */
	creditLineLimitDetails: ModelTypes["LineOfCreditLineLimitDetails"],
	/** Returns the user to KYC submission response and unfulfilled reasons if it is present */
	kycRequirementResponse: ModelTypes["LocRequireKycResponse"],
	/** Checks if it is required for the user to create a KYC submission to progress in onboarding */
	kycRequirementStatus: ModelTypes["FrontendLocKycRequirementStatus"],
	/** The users currently open line of credit account. */
	openAccount?: ModelTypes["LineOfCreditAccount"] | undefined,
	/** Payments breakdown for user selected credit draw */
	paymentsBreakdown: Array<ModelTypes["LocPaymentBreakdown"] | undefined>,
	/** Fetches the user facing product name */
	productName: string,
	/** Account details for a prospective user */
	prospectiveTermDetails: ModelTypes["LineOfCreditProspectiveTerms"],
	/** Evaluates and fetches the underwriting status for a prospective LOC user */
	underwritingDetails: ModelTypes["LocUnderwritingDetails"],
	/** Fetches the string contents for the proposed unsigned loan agreement */
	unsignedProposedUserAgreement: string
};
	/** A line of credit account that a user can draw money from. */
["LineOfCreditAccount"]: {
		/** The current status of the account. */
	accountStatus: ModelTypes["FrontendLocAccountStatus"],
	/** The unique UUID that identifies the account. */
	accountUuid: string,
	activeCreditDraw?: ModelTypes["LineOfCreditDrawFetcher"] | undefined,
	/** The autopay status of the account. */
	autopayStatus: ModelTypes["FrontendLocAutopayStatus"],
	/** The credit draw details for this line of credit account */
	creditDrawDetails: ModelTypes["LineOfCreditDrawRequestDetailsFetcher"],
	/** The total limit that can be drawn up to in cents. */
	creditLimitInCents: ModelTypes["Long"],
	/** Fetches the upcoming payment details for the minimum payment. */
	minPaymentDetails: ModelTypes["LineOfCreditMinPaymentDetailsFetcher"],
	/** The date the account was opened. */
	openedOn: string,
	/** Total balance owed for fees */
	outstandingAccessFeeBalanceInCents: ModelTypes["Long"],
	/** Total outstanding principal balance in cents */
	outstandingPrincipalBalanceInCents: ModelTypes["Long"],
	/** The payment charge type of the account. */
	paymentChargeType: ModelTypes["FrontendLocUserInitiatedPaymentChargeType"],
	/** Historical payments for given LOC account */
	paymentHistory: ModelTypes["LineOfCreditPaymentHistory"],
	prevDraw?: ModelTypes["LineOfCreditDrawFetcher"] | undefined,
	/** Contains the list of historical statements and the start + end period for the CURRENT billing cycle / statement period. */
	statementDetails: ModelTypes["LineOfCreditStatementDetailsFetcher"],
	/** Total balance owed for principal and remainder owed for fees */
	totalBalanceRemainingIncludingFullFee: ModelTypes["Long"],
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalInCents: ModelTypes["Long"]
};
	/** Details of an LOC object for line of credit account */
["LineOfCreditDrawFetcher"]: {
		/** Disbursal amount */
	disbursalAmountInCents: ModelTypes["Long"],
	/** When credit draw was disbursed */
	disbursalDate: string,
	/** When disbursal amount is estimated to arrive to user. Returns empty optional if amount has already been delivered. */
	estimatedDeliveryDate?: string | undefined,
	/** Payment status */
	paymentStatus: ModelTypes["FrontendPaymentStatus"]
};
	/** Details on amount that can be or has been drawn from a line of credit */
["LineOfCreditDrawRequestDetailsFetcher"]: {
		/** The amount that is due today */
	amountDueTodayInCents: ModelTypes["Long"],
	/** The estimated date that this the credit draw will settle */
	estimatedDeliveryDate: string,
	/** Max amount a user can request for a credit draw - deprecated */
	maxDrawAmountInCents: ModelTypes["Long"],
	/** Minimum amount a user can request for a credit draw - deprecated */
	minDrawAmountInCents: ModelTypes["Long"],
	/** The prospective min payment amount for the first payment post this draw. This is different from what lives in the minPaymentDetails */
	prospectiveMinPaymentAmount: ModelTypes["Long"],
	/** The estimated date that this the credit draw will settle */
	prospectiveMinPaymentDate: string
};
	/** Contains global configuration data for the Line of credit. */
["LineOfCreditGlobalConfiguration"]: {
		/** The USA states in which line of credit is available. */
	supportedStates: Array<ModelTypes["UsaState"]>
};
	/** Fetches LOC credit limit details for user */
["LineOfCreditLineLimitDetails"]: {
		/** Max amount a user can request for a credit draw */
	maxDrawAmountInCents: ModelTypes["Long"],
	/** Minimum amount a user can request for a credit draw */
	minDrawAmountInCents: ModelTypes["Long"]
};
	/** Details regarding minimum payment details */
["LineOfCreditMinPaymentDetailsFetcher"]: {
		/** Represents the fee amount that a user must make a payment on. */
	feeAmountInCents: ModelTypes["Long"],
	/** Represents the amount that a user must make a payment on. */
	minPaymentAmountInCents: ModelTypes["Long"],
	/** Represents the date that a user must make a payment on. */
	minPaymentDate: string,
	/** If the status is NOT DUE, most of the fields are generated on the fly. If it is due, it is the result of the persisted billing cycle details */
	minPaymentStatus: ModelTypes["FrontendLocMinPaymentStatus"],
	/** Represents the principal amount that a user must make a payment on. */
	principalAmountInCents: ModelTypes["Long"]
};
	/** Payment made against a user's LOC account */
["LineOfCreditPayment"]: {
		/** Payment amount in cents */
	amountInCents: ModelTypes["Long"],
	/** Type of payment attempt (manual or automatic) */
	paymentAttemptType: ModelTypes["FrontendLocPaymentAttemptType"],
	/** Date payment was made on */
	paymentDate: string,
	/** Payment status (SETTLED, RETURNED, etc.) */
	paymentStatus: ModelTypes["FrontendPaymentStatus"]
};
	/** Contains list of historical payments made against LOC account */
["LineOfCreditPaymentHistory"]: {
		/** List of historical payments */
	payments: Array<ModelTypes["LineOfCreditPayment"] | undefined>
};
	/** Detail of the LOC account that a user is thinking of opening */
["LineOfCreditProspectiveTerms"]: {
		/** Membership fees for the year */
	annualFeesInCents: ModelTypes["Long"],
	/** The day of month the user will have to make their first payment */
	dayOfMonthForRecurringPayment: ModelTypes["Long"],
	/** The end date of the 'monthly statement'. All transactions after this date and before the end are included in the statement */
	firstBillingCycleEnd: string,
	/** The start date of the 'monthly statement'. All transactions after this date and before the end are included in the statement */
	firstBillingCycleStart: string,
	/** The minimum payment a user must make every billing cycle. This is moreso a ceiling. */
	minMonthlyPayment: ModelTypes["Long"],
	/** Membership fee per month */
	monthlyFeeInCents: ModelTypes["Long"],
	/** Total Credit Line Amount */
	totalCreditLineAmountInCents: ModelTypes["Long"],
	/** The date the user will have to make their first payment */
	upcomingMinPaymentDate: string
};
	/** A reminder associated with the user's open installment loan account. */
["LineOfCreditStatement"]: {
		endDate: string,
	startDate: string,
	uuid: string
};
	/** Details statement period and prev statements */
["LineOfCreditStatementDetailsFetcher"]: {
		currPeriodEndDate: string,
	currPeriodStartDate: string,
	/** Statement balance for the 'prev' statement balance which is the same definition of the balance of the due billing cycle */
	prevStatementBalance: ModelTypes["Long"],
	/** Remaining statement balance for the 'prev' statement balance which is the same definition of the remaining balance of the due billing cycle */
	remainingStatementBalance: ModelTypes["Long"],
	statementHistory: Array<ModelTypes["LineOfCreditStatement"]>
};
	/** A timeline showing the payment history for a loan. */
["LoanTimelineEvent"]: {
		/** User-friendly description explaining the status of the loan payment. */
	description: string,
	/** The amount of the loan payment being made in dollars. */
	dollarAmount?: ModelTypes["BigDecimal"] | undefined,
	/** If present, the return type explains why the loan payment was returned. */
	returnType?: ModelTypes["FrontendReturnType"] | undefined,
	/** The loan timeline status refers to the payment status of the loan payment such as whether it succeeded or failed. */
	status: ModelTypes["LoanTimelineStatus"],
	/** The date and time of the loan payment or event. */
	timestamp?: string | undefined,
	/** The loan timeline type refers to the type of loan payment being made. */
	type: ModelTypes["LoanTimelineType"]
};
	["Loans"]: {
		/** The number of days that a loan is past the current due date by. */
	daysPastCurrentDueDate?: ModelTypes["Long"] | undefined,
	/** The number of days that a loan is past the original due date by. */
	daysPastDue?: ModelTypes["Long"] | undefined,
	/** The loan's current due date. */
	dueDate?: string | undefined,
	/** Whether express delivery was used or not */
	express: boolean,
	/** Fee for express delivery in dollars */
	expressTransferFeeDollars: ModelTypes["BigDecimal"],
	/** True only if the loan is outstanding with no scheduled payment */
	extendable: boolean,
	/** Unique ID for loan */
	loanId: string,
	/** The Reason a loan is not recoverable */
	notRecoverableReason?: ModelTypes["NonRepaymentReason"] | undefined,
	/** The When the Loan was first created */
	originationDate?: string | undefined,
	/** Advance amount for loan in dollars */
	originationDollarAmount: ModelTypes["BigDecimal"],
	/** Unpaid loan amount in dollars */
	outstandingDollarAmount: ModelTypes["BigDecimal"],
	/** Whether the loan's status is Pre-Charge-Off or not */
	prechargeOff: boolean,
	/** Loan amount that's been repaid in dollars */
	repaidDollarAmount: ModelTypes["BigDecimal"],
	/** Whether the loan is repayable or not which means the loan is delivered, outstanding, and has no scheduled payment */
	repayable: boolean,
	/** Timeline of all the payments made for the loan. */
	timeline: Array<ModelTypes["LoanTimelineEvent"]>
};
	/** Represents Loc list of payment breakdown for a credit draw */
["LocPaymentBreakdown"]: {
		/** Loc access fee payment amount in cents */
	accessFeeAmountInCents: ModelTypes["Long"],
	/** Loc payments in month   */
	lengthInMonth: ModelTypes["Long"],
	/** Loc minimum payment amount in cents */
	minimumPaymentAmountInCents: ModelTypes["Long"],
	/** Loc principal payment amount in cents */
	principalPaymentAmountInCents: ModelTypes["Long"]
};
	/** Represents Loc kyc response and the list of unfulfilled reasons */
["LocRequireKycResponse"]: {
		/** Loc requirement status */
	requirementStatus: ModelTypes["FrontendLocKycRequirementStatus"],
	/** Loc unfulfillment reasons */
	unfulfillmentReasons: Array<ModelTypes["FrontendLocKycUnfulfillmentReasons"] | undefined>
};
	/** Represents the underwriting results for a line of credit application. If it is unapproved, the list of unapproved strings will contain data */
["LocUnderwritingDetails"]: {
		/** Unapproval reasons */
	unapprovalReasons: Array<string | undefined>,
	/** Underwriting Status */
	underwritingStatus: ModelTypes["FrontendLocUnderwritingStatus"]
};
	["LoginFailure"]: {
		/** A reason for the failure to log in, should be used by the mobile code to decide what to do. */
	loginFailureReason: ModelTypes["LoginFailureReason"],
	/** An error message to show the user when logging in fails. */
	userFacingMessage: string
};
	["LoginSuccess"]: {
		/** The auth token for the user, should be put in the store so it can be included is subsequent requests. */
	token: string,
	/** The logged in users id. */
	userId: string
};
	["MarkNotificationCenterEntriesViewed"]: {
		/** Enum denoting if the outcome of marking the list of notification centers as viewed was a success or failure */
	outcome: ModelTypes["MarkNotificationCenterEntriesViewedOutcome"]
};
	["MarkUserViewedClgModal"]: {
		/** The user's remaining flags after their CLG Modal flag has been removed. */
	userFlags: Array<ModelTypes["UserFlag"] | undefined>
};
	["MarketplaceBadge"]: {
		/** The badges display name. */
	displayName: string,
	/** The badges id. */
	id: ModelTypes["Long"],
	/** The badges type. */
	type: ModelTypes["MarketplaceBadgeType"]
};
	["MarketplaceCategory"]: {
		/** Marketplace category description */
	description: string,
	/** Marketplace category display name */
	displayName: string,
	/** Whether any of the offers in this category have a badge or not */
	hasBadge: boolean,
	/** Marketplace category type */
	type: ModelTypes["TablessMarketplaceCategoryType"]
};
	["MarketplaceMaintenance"]: {
		/** Whether the feature is in active maintenance */
	activeMaintenance: boolean,
	/** The feature associated with the potentially active maintenance flag */
	feature: ModelTypes["MarketplaceFeature"]
};
	["MarketplaceOfferByCategory"]: {
		category: ModelTypes["TablessMarketplaceCategoryType"],
	categoryIconUrl: string,
	offers: Array<ModelTypes["TablessMarketplaceOffer"]>
};
	["MarketplaceOffersV2"]: {
		/** The number of badges on the offers. */
	badgeCount: number,
	/** A list of marketplace categories. */
	categories: Array<ModelTypes["MarketplaceCategory"] | undefined>,
	/** A list of offers sorted either by EPC, RPM or priority, depending on what experiments the user is sampled for. */
	fullOffers: Array<ModelTypes["TablessMarketplaceOffer"] | undefined>
};
	["MoneyLionEngineOffers"]: {
		/** LeadUUID */
	leadUUID: string,
	/** Loan Offers grouped by Partner */
	loanOffers: Array<ModelTypes["PartnerLoanOffers"] | undefined>,
	/** Special Offers grouped by Partner */
	specialOffers: Array<ModelTypes["PartnerSpecialOffers"] | undefined>,
	/** UUID */
	uUID: string
};
	["MoneyLionLoanOffers"]: {
		/** APR */
	apr?: number | undefined,
	/** Company UUID */
	companyUuid?: string | undefined,
	/** Disclosure */
	disclosure?: string | undefined,
	/** Estimated Payment */
	estPayment?: number | undefined,
	/** Expiration */
	expiration?: string | undefined,
	/** Financial Institution UUID */
	financialInstitutionUuid?: string | undefined,
	/** Loan Amount */
	loanAmount?: number | undefined,
	/** Originator ID */
	originatorId?: string | undefined,
	/** Partner Image URL */
	partnerImageUrl?: string | undefined,
	/** Partner Name */
	partnerName: string,
	/** Term */
	term?: string | undefined,
	/** URL */
	url?: string | undefined,
	/** UUID */
	uuid: string
};
	["MoneyLionSpecialOffers"]: {
		/** Description of the specialOffer */
	desc: string,
	/** Name of the specialOffer */
	name: string,
	/** URL of the partner image */
	partnerImageUrl: string,
	/** Name of the partner */
	partnerName: string,
	/** Payout amount */
	payout?: number | undefined,
	/** Product subtype */
	productSubType: ModelTypes["ProductSubType"],
	/** Recommendation score */
	recommendationScore?: number | undefined,
	/** URL of the specialOffer */
	url: string,
	/** Unique identifier */
	uuid: string
};
	["Mutation"]: {
		/** Connects an existing payroll account for the user using the user ID and account ID supplied by the provider. Updates connection status and ongoing refresh status if needed. */
	accountConnected: ModelTypes["AccountConnectedResponseFetcher"],
	/** Attempts to move the user to the requested subscription tier. The `ChangeSubscriptionTierOutcome` lists some of the correctable reasons this could fail. There are other unactionable reasons this could fail, which will result in errors being thrown. */
	changeSubscriptionTier: ModelTypes["ChangeSubscriptionTierResponse"],
	/** Submits the quiz to mark it as completed */
	completeQuiz?: ModelTypes["CompleteQuizFetcher"] | undefined,
	/** A new endpoint to create credit builder account regardless income strategy which takes in the contribution amount, charge policy and optional due date. */
	createCreditBuilderUserAccount?: ModelTypes["FrontendCreditBuilderEnrollmentStatus"] | undefined,
	/** Onboards a user to a credit monitoring product */
	createCreditMonitoringUser: ModelTypes["CreditMonitoringUser"],
	/** Creates an installment loans account for the user id */
	createInstallmentLoansAccount: ModelTypes["FrontendInstallmentLoansAccountCreationStatus"],
	/** Creates a new payroll account for the user using the account ID, user ID, and item ID supplied by the provider */
	createPayrollAccount: ModelTypes["CreatePayrollAccountResponse"],
	/** Creates a new payroll account and returns a success outcome or a failure if the account wasn't created. */
	createPayrollAccountV2: ModelTypes["CreatePayrollAccountResponseV2"],
	/** Creates a deposit destination for a payroll user's direct deposits */
	createTargetDepositDestination: ModelTypes["CreateTargetDepositDestinationResponse"],
	/** Delete an existing bank login */
	deleteBankLogin: ModelTypes["DeleteBankLogin"],
	/** Deletes a user's payroll account */
	deletePayrollAccount: ModelTypes["DeletePayrollAccountResponse"],
	/** Disables a deposit destination for a user's direct deposits */
	disableDirectDeposits: ModelTypes["DisableDirectDepositsResponse"],
	/** Disables connection for an existing payroll account for the user using the user ID and account ID supplied by the provider, and updates connection status */
	disablePayrollAccount: ModelTypes["DisablePayrollAccountResponse"],
	/** Makes a catch up payment for an installment loan */
	initiateCatchUpPaymentForInstallmentLoan: ModelTypes["InitiateCatchUpPaymentForInstallmentLoan"],
	/** Initiates the Credit Draw for a LOC account */
	initiateCreditDraw: ModelTypes["FrontendInitiateCreditDrawStatus"],
	/** Initiates a debit payment for the loan using the scheduled repayment date and the payment method id supplied. */
	initiateOneTimePayment: ModelTypes["InitiateOneTimePayment"],
	/** Makes a pay off payment for an installment loan */
	initiatePayOffPaymentForInstallmentLoan: ModelTypes["InitiatePayOffPaymentForInstallmentLoan"],
	/** Makes a pre payment for an installment loan */
	initiatePrePaymentForInstallmentLoan: ModelTypes["InitiatePrePaymentForInstallmentLoan"],
	/** Initiates a user initiated payment for a LOC account */
	initiateUserPaymentForLoc: ModelTypes["FrontendLocInitiatePaymentStatus"],
	/** Stores the user's salary data */
	inputSalaryData: ModelTypes["InputSalaryData"],
	/** Either initiates the phone auth process or returns a reason why the user can not be logged in. */
	login: ModelTypes["LoginUnion"],
	/** Mark notification center entries viewed */
	markNotificationCenterEntriesViewed: ModelTypes["MarkNotificationCenterEntriesViewed"],
	/** Marks that the user has viewed the CLG modal screen by removing their user flag. */
	markUserViewedClgModal: ModelTypes["MarkUserViewedClgModal"],
	/** Creates an LOC account for the user id */
	openLocAccount: ModelTypes["FrontendLocAccountCreationStatus"],
	/** Sets the CB Payment Strategy for a user to the four day retry strategy */
	publishCreditBuilderDraftPaymentSchedule: ModelTypes["FrontendCBScheduleVersionStatus"],
	/** Used for recording the user response of a prompt */
	recordUserPromptResponse: ModelTypes["RecordUserPromptResponse"],
	/** Attempts to register a new bank login for a user, maintaining the existing login for a user if it exists. */
	registerBankLoginMaintainingExistingLogin: ModelTypes["RegisterBankLoginMaintainingExistingLogin"],
	/** Registers a debit card for one-time payments and stores the new payment method */
	registerOneTimeCard: ModelTypes["RegisterOneTimeCardResponse"],
	/** Registers a debit card for one-time payments and stores the new payment method. Returns either the newly created payment method info or a user facing message if the user passed in invalid input. */
	registerOneTimeCardV2: ModelTypes["RegisterOneTimeCardUnion"],
	/** Repays an advances for user. */
	repayAdvance: ModelTypes["RepayAdvance"],
	/** Manually disburses an advance for user. */
	requestManualDisbursal: ModelTypes["RequestManualDisbursal"],
	/** Resends an auth verification code to users phone or email */
	resendAuthVerificationCode: ModelTypes["ResendAuthVerificationCode"],
	/** Schedules same day manual payment for installment loan account for Credit Builder */
	scheduleCreditBuilderSameDayManualPayment: ModelTypes["ScheduleCreditBuilderSameDayManualPayment"],
	/** Starts or resumes a user engagement quiz */
	startOrResumeQuiz: ModelTypes["StartOrResumeQuizResponse"],
	/** Sets the CB Payment charge policy Auto or Manual */
	submitCreditBuilderPaymentChargePolicy: ModelTypes["FrontendInstallmentLoanChargePolicy"],
	/** Sets the CB Payment Strategy for a user to the four day retry strategy */
	submitCreditBuilderPaymentStrategy: ModelTypes["FrontendCreditBuilderPaymentRetryStrategyType"],
	/** Submits Marketplace Loans quiz in order to request MoneyLionOffers for a user */
	submitMoneyLionPersonalLoansQuiz: ModelTypes["SubmitMoneyLionOffers"],
	/** Allows submitting feedback for a quiz */
	submitQuizFeedback: ModelTypes["SubmitQuizFeedbackFetcher"],
	/** Submits a response to a quiz question */
	submitQuizResponse: ModelTypes["SubmitQuizResponse"],
	/** Submits a response for a prompt given a type */
	submitResponseForPromptType: ModelTypes["SubmitUserPromptResponse"],
	/** Attempts to switch the user's protected login to a previously linked login */
	switchProtectedLoginToExistingLogin: ModelTypes["SwitchProtectedLoginToExistingLogin"],
	/** Attempts to switch the user's protected login to a previously linked login */
	switchProtectedLoginToExistingNonConnectedLogin: ModelTypes["SwitchProtectedLoginToExistingNonConnectedLogin"],
	/** Disables connection for an existing payroll account for the user using the user ID and account ID supplied by the provider, and updates connection status. Provider is specified by the caller. */
	testDisablePayrollAccount: ModelTypes["DisablePayrollAccountResponse"],
	/** Used for testing errors thrown from a mutation. */
	throwTestException?: string | undefined,
	/** Tracks an impression event */
	trackImpressionEvent: ModelTypes["TrackImpressionsFetcher"],
	/** Track notification center entry clicked */
	trackNotificationCenterEntryClick: ModelTypes["TrackNotificationCenterEntryClick"],
	/** Update the auto pay status (enabled or disabled) for an installment loan */
	updateAutoPayStatusForInstallmentLoan: ModelTypes["UpdateInstallmentLoansAutoPayStatusFetcher"],
	/** Updates autopay status */
	updateAutopayStatusForLoc: ModelTypes["FrontendLocAutopayStatus"],
	/** Update whether or not an existing bank account is joint */
	updateBankAccountJointStatus: ModelTypes["UpdateBankAccountJointStatus"],
	/** Updates the status of a credit limit boost */
	updateCreditLimitBoostStatus: ModelTypes["UpdateCreditLimitBoostStatus"],
	/** Updates payment charge type */
	updatePaymentChargeTypeForLoc: ModelTypes["FrontendLocUserInitiatedPaymentChargeType"]
};
	["NotificationCenter"]: {
		/** Return notifications for user to display in notification center */
	notifications: Array<ModelTypes["NotificationCenterEntry"]>
};
	["NotificationCenterEntry"]: {
		/** Time notification was added to notification center for user */
	createdAt: string,
	/** Description of notification */
	description: string,
	/** Where we will navigate the user when they click on the notification */
	destination: string,
	/** Whether or not user has viewed notification */
	hasViewed: boolean,
	/** Id of notification */
	id: ModelTypes["Long"],
	/** Image URL to display with the notification on the frontend */
	imageUrl?: string | undefined,
	/** Title of notification */
	title: string
};
	/** Information about pagination in a connection. */
["PageInfo"]: {
		/** When paginating forwards, the cursor to continue. */
	endCursor?: string | undefined,
	/** When paginating forwards, are there more items? */
	hasNextPage: boolean,
	/** When paginating backwards, are there more items? */
	hasPreviousPage: boolean,
	/** When paginating backwards, the cursor to continue. */
	startCursor?: string | undefined
};
	["PartnerLoanOffers"]: {
		/** Offers */
	offers: Array<ModelTypes["MoneyLionLoanOffers"] | undefined>,
	/** Partner Name */
	partnerName: string
};
	["PartnerSpecialOffers"]: {
		/** Offers */
	offers: Array<ModelTypes["MoneyLionSpecialOffers"] | undefined>,
	/** Partner Name */
	partnerName: string
};
	/** Details about past due credit builder payments. */
["PastDuePayment"]: {
		/** The total amount past due in dollars. */
	amountDollars: ModelTypes["BigDecimal"]
};
	/** Details about the user's payroll connection */
["PayrollConnection"]: {
		/** User ID generated by a payroll vendor for client-side authorization */
	payrollUserId: string,
	/** User token generated by a payroll vendor for client-side authorization */
	userToken: string
};
	["Payroll_32_Account_32_Fetcher_32_With_32_Outcome"]: {
		/** Whether the request was successful */
	outcome?: ModelTypes["PayrollAccountFetcherOutcome"] | undefined,
	/** List of all payroll accounts for the user */
	payrollAccounts: Array<ModelTypes["Payroll_32_Accounts_32_V2"] | undefined>
};
	["Payroll_32_Accounts"]: {
		/** Connection status of payroll account */
	connectionStatus: ModelTypes["PayrollAccountConnectionStatus"],
	/** Name of employer associated with payroll account */
	employerName?: string | undefined,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	manualDisconnect: boolean,
	/** Status indicating whether payroll account will be updated when data refreshes */
	ongoingRefreshStatus?: ModelTypes["OngoingRefreshStatus"] | undefined,
	/** Unique ID for payroll account */
	payrollAccountId: string
};
	["Payroll_32_Accounts_32_V2"]: {
		/** Connection status of payroll account */
	connectionStatus: ModelTypes["FrontendPayrollAccountConnectionStatus"],
	/** User facing message explaining why the user isn't eligible for DDS */
	dDSEligibilityMessage?: string | undefined,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	eligibleForDDS: boolean,
	/** Name of employer associated with payroll account */
	employerName?: string | undefined,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	manualDisconnect: boolean,
	/** Status indicating whether payroll account will be updated when data refreshes */
	ongoingRefreshStatus?: ModelTypes["FrontendOngoingRefreshStatus"] | undefined,
	/** Unique ID for payroll account */
	payrollAccountId: string
};
	/** A user facing summary of insights for a user's bank transactions of loan is a secured loan which the user has taken out to build such as upcoming fees, cashflow, spend, etc. */
["PfmSummary"]: {
		/** Returns the sum of the the total debit amounts for plaid transactions with fee categories */
	totalFeesPaid: ModelTypes["BigDecimal"]
};
	/** Payment details about possible pre-payments user can make. */
["PrePayment"]: {
		/** The payment amount in dollars. */
	amountDollars: ModelTypes["BigDecimal"],
	/** When the pre-payment is due. */
	dueDate: string,
	/** An optional date that is returned if the the prepayment is not yet eligible for prepayment.   */
	payableAfter?: string | undefined
};
	["PromoCardOffer"]: {
		/** The url for the offers affiliate link. */
	affiliateLinkUrl: string,
	/** The offers description */
	description: string,
	/** The offers details */
	details?: string | undefined,
	/** The Offers Id */
	id: ModelTypes["Long"],
	/** The url for the offers logos image file. */
	logoUrl: string,
	/** The offers name. */
	offerName: string
};
	/** A promotion is an offer to provide a discount to a user for subscribing to Brigit. */
["Promotion"]: {
		/** The amount that the user would pay while the discount is active, in US dollars. */
	discountedAmount: ModelTypes["BigDecimal"],
	/** How many months the discount would apply to the user's subscription. */
	numMonths?: number | undefined,
	/** The amount that the user would pay while the discount is active, in US dollars. */
	subscriptionAmountAfterDiscount: ModelTypes["BigDecimal"],
	/** The subscription tier level that the promotion applies to. Users would need to subscribe to this particular tier for the promotion to be applied as a discount. */
	subscriptionTierLevel: ModelTypes["SubscriptionTierLevel"]
};
	["ProtectionDetails"]: {
		/** List of user's loans and loan details */
	loans: Array<ModelTypes["Loans"] | undefined>,
	/** Status of the user's current advance */
	protectionStatus?: ModelTypes["ProtectionStatusV4"] | undefined,
	/** User-friendly description of the status of the user's current advance */
	protectionStatusDescription?: string | undefined
};
	["Query"]: {
		/** The available subscription tiers are those tiers which can be accessed by the user making the request. They're what we would advertise as the options available to the user. */
	availableSubscriptionTiers: Array<ModelTypes["SubscriptionTier"]>,
	/** Global configurations related to the Credit Builder system. */
	creditBuilderGlobalConfiguration: ModelTypes["CreditBuilderGlobalConfiguration"],
	/** Get Promo cards under new dynamic marketplace model */
	dynamicPromoCards: Array<ModelTypes["DynamicPromoCards"]>,
	/** Fetches an existing assignment given a sample key and experiment name. If no active assignment
exists, eligibility checks pass (such as minimum client version/conflicting experiment), and shouldPerformAssignment
is true, a new assignment will be made at the time of the call.

This method is designed to fail gracefully, returning an Optional.empty() and logging errors if there is
an unexpected issue while executing the workflow such as:
- The experiment is not found.
- The sample weights across variants under test do not sum to 100%.
- There is more than one active variant found for an experiment with a winner selected.

This allows callers to handle the Optional::isEmpty case appropriately. If no assignment
exists and the experiment is in draft, an empty response will be returned. If an assignment
does exist, it will be returned even if the experiment is in draft.

If shouldPerformAssignment is false, no new assignment will be made. However, if an existing
assignment is found, it will be returned. If no assignment has been made but a winning variant
has been selected, the winning variant will be returned. */
	experimentVariantAssignment?: ModelTypes["FrontendExperimentVariantAssignment"] | undefined,
	/** Return Frequently Asked Questions as part of an experiment to add clarity to the subscription process when a user is activating via the IC flow. See: https://brigit.atlassian.net/browse/ENG-8843. */
	iCActivationFAQs: Array<ModelTypes["ICActivationFAQs"]>,
	/** Global configurations related to the Line of credit system. */
	lineOfCreditGlobalConfiguration: ModelTypes["LineOfCreditGlobalConfiguration"],
	/** Get the maintenance status for a marketplace feature */
	marketplaceMaintenance: ModelTypes["MarketplaceMaintenance"],
	/** The account of the user making the request */
	me: ModelTypes["UserAccount"],
	/** Returns information to the frontend about whether or not the user's current mobile version is supported and, if it's not, whether we should nudge or force users to upgrade.Returns UNKNOWN if the user is on web or if we can't parse the mobile version we receive. */
	mobileVersionSupportType?: ModelTypes["FrontendMobileVersionSupportType"] | undefined
};
	["QuizFetcher"]: {
		/** Whether the quiz is completed */
	completed: boolean,
	/** The zoned datetime of when the quiz was completed. Returns an optional because quiz may be incomplete */
	completedAt?: string | undefined,
	/** The zoned datetime of when the quiz was started */
	createdAt: string,
	/** Optional zoned datetime of when the quiz was deleted */
	deletedAt?: string | undefined,
	/** The id of the quiz */
	id: ModelTypes["Long"],
	/** The name of the quiz */
	quizName: ModelTypes["QuizName"],
	/** The taker of the quiz. It may be a user id or a device id if the user is not yet authenticated. */
	quizTaker: string,
	/** The version id of the quiz */
	quizVersionId: string
};
	["QuizStateFetcher"]: {
		/** Whether a user can exit the quiz at any point */
	canExitEarly: boolean,
	/** The current step of the quiz that the user is on. If the quiz has no more steps to complete, an empty optional is returned. */
	currentStepId?: string | undefined,
	/** Whether the quiz can be resume part way through */
	isResumable: boolean,
	/** An array of QuizSteps */
	steps: Array<ModelTypes["Step"]>,
	/** The total number of steps in the current state. If branching logic changes length, returns empty optional */
	totalSteps?: ModelTypes["Long"] | undefined
};
	["RecentScoreChangeFetcher"]: {
		/** An enum representing the CTA link that should be rendered after the text. */
	callToAction?: ModelTypes["ScoreChangeCallToAction"] | undefined,
	/** An optional integer. If present will be used to render the score change indicator on the right side of the card and should contain a positive, negative, or 0 change to show/ If they only have one score and thus can not compute a delta this should be empty. */
	scoreChange?: number | undefined,
	/** The date of the last score pulled. Will be used to render the footer of the component.  */
	scoreUpdateDate: string,
	/** The text to display in the component. */
	text: ModelTypes["RecentScoreChangeText"]
};
	["RecordUserPromptResponse"]: {
		/** The prompt type of a user engagement event */
	campaignPromptType: ModelTypes["CampaignPromptType"],
	/** The response type of a user engagement event */
	promptResponseType: ModelTypes["UXPromptResponseType"]
};
	/** A Referral object maintains a users referral status for a specific referral. */
["Referral"]: {
		/** Represents whether the user has been paid for the referral. */
	paid: boolean,
	/** Represents the value for which the user was paid for the referral. */
	payoutValue: ModelTypes["BigDecimal"]
};
	["RegisterBankLoginMaintainingExistingLogin"]: {
		/** An Enum denoting if the outcome of registering the bank account was successful or failure */
	outcome: ModelTypes["RegisterBankLoginMaintainingExistingLoginOutcome"]
};
	["RegisterOneTimeCardInvalidInput"]: {
		/** User facing message explaining why the user input was invalid. */
	userFacingMessage: string
};
	["RegisterOneTimeCardResponse"]: {
		/** The display name of the card payment method i.e. VISA ending in 1234 */
	cardNickname: string,
	/** The payment method ID of the one time card payment method created */
	paymentMethodId: string
};
	["RegisterOneTimeCardSuccess"]: {
		/** User friendly nickname to refer to card. */
	cardNickname: string,
	/** Newly created payment method ID. */
	paymentMethodId: string
};
	["RepayAdvance"]: {
		/** The advance that we attempted to repay */
	advanceId: ModelTypes["Long"],
	/** An Enum denoting if the outcome of the request was successful or failure */
	repayAdvanceOutcome: ModelTypes["RepayAdvanceOutcome"],
	/** A String denoting why this request succeeded or failed */
	repayAdvanceOutcomeReason: string
};
	["RequestManualDisbursal"]: {
		/** The loan that was disbursed to the user, on success */
	loanId: ModelTypes["Long"],
	/** An Enum denoting if the outcome of the requestManualDisbursal was successful or failure */
	requestManualDisbursalOutcome: ModelTypes["RequestManualDisbursalOutcome"],
	/** A String denoting why this request succeeded or failed */
	requestManualDisbursalReason: string
};
	["ResendAuthVerificationCode"]: {
		/** An Enum denoting if the outcome of resending the verification code was successful or failure */
	outcome: ModelTypes["ResendAuthVerificationCodeOutcome"]
};
	["SalaryAggregators"]: {
		/** Primary filter used to aggregate salary data */
	filterOne: string,
	/** List of aggregated salary data based on a second filter */
	filterTwoData: Array<ModelTypes["FilteredSalaryData"] | undefined>,
	/** The average salary for the primary filter */
	salary: number
};
	/** Installment payment made manually on the due date from the user's external bank account for credit builder. */
["SameDayManualPayment"]: {
		/** Amount scheduled to be paid for the manual payment */
	amountInCents: ModelTypes["Long"],
	/** Date and time of scheduled manual payment in yyyy-MM-dd format */
	paidOn?: string | undefined,
	/** Status of installment loan payment after manual payment is scheduled */
	status: ModelTypes["FrontendCreditBuilderTransactionStatus"]
};
	/** Payment details for manual payment that is due today. */
["SameDayPayment"]: {
		/** The payment amount in dollars. */
	amountDollars: ModelTypes["BigDecimal"]
};
	["ScheduleCreditBuilderSameDayManualPayment"]: {
		/** The result of scheduling same day manual payment */
	outcome: ModelTypes["ScheduleCreditBuilderSameDayManualPaymentOutcome"],
	/** The response object scheduling same day manual payment. Only returns something if the outcome is successful */
	sameDayManualPayment: ModelTypes["SameDayManualPayment"]
};
	["ScoreBucketFetcher"]: {
		/** The lower range of the score bucket, e.g. 349. */
	maximumScore: number,
	/** The lower range of the score bucket, e.g. 300. */
	minimumScore: number
};
	["ScoreCard"]: {
		/** The maximum score to be shown on the user's score card */
	maxScore: number,
	/** The minimum approval score needed for the user to be approved based on their underwriting strategy. */
	minApprovalScore: number,
	/** The minimum score to be shown on the user's score card */
	minScore: number,
	/** The user's underwriting score. If their score meets the minimum approval score, then the user will be approved for Instant Cash. */
	score: number,
	/** List of categories that influence the user's underwriting score. */
	scoreCategories: Array<ModelTypes["ScoreCategory"] | undefined>
};
	["ScoreCategory"]: {
		/** User-friendly text describing improvements the user can make to improve their performance in this score category. */
	description: string,
	/** The name of the larger categories that affect the user's approval status such as bank account, spending, and earning. */
	name: string,
	/** The status of this category is based on the lowest rank of the statuses in its subcategories. */
	status: ModelTypes["ModelRank"],
	/** List of more specific subcategories within this score category. */
	subcategories: Array<ModelTypes["ScoreSubcategory"] | undefined>
};
	["ScoreSubcategory"]: {
		/** User-friendly text explaining what data is used in this subcategory */
	description: string,
	/** Name of the score subcategory */
	name: string,
	/** A rank of how important this score subcategory contributes to the user's underwriting score */
	status: ModelTypes["ModelRank"]
};
	["SelectFieldFetcher"]: {
		/** An optional image to display to the left of the text.  Only populated if the type is GRID */
	icon?: string | undefined,
	/** The text to display for the option */
	optionDisplayValue: string,
	/** Whether the option should be selected by default */
	selected: boolean
};
	["StartOrResumeQuizResponse"]: {
		/** The quiz definition */
	quiz: ModelTypes["QuizFetcher"],
	/** The state of the quiz */
	state?: ModelTypes["QuizStateFetcher"] | undefined
};
	["Step"]: {
		/** Optional copy to provide additional context or instruction for the question */
	assistiveCopy?: string | undefined,
	/** Whether a ‘Skip’ button should be rendered allowing user to bypass the step. */
	canSkip: boolean,
	/** Whether assistive copy should be centered */
	centerAssistiveCopy?: boolean | undefined,
	/** Whether the main question text should be centered */
	centerQuestionText: boolean,
	/** Whether select options should be centered */
	centered?: boolean | undefined,
	/** Optional disclosure primary text */
	disclosureCopy?: string | undefined,
	/** Whether a step has information that needs to be sent via VGS or other security proxy */
	hasSensitiveResponse: boolean,
	/** Optional icon image name */
	heroIcon?: string | undefined,
	/** Required if a hero icon exists */
	heroIconSize?: ModelTypes["HeroIconSize"] | undefined,
	/** A unique string identifier for the step. Will be used when submitting responses to the step (if the step has responses). For example, the first step of a quiz may have an ID of '1'. */
	id: string,
	/** Whether the next step is known or not (e.g. dependant on the response to this step */
	isBranching: boolean,
	/** Whether its the last step of the quiz */
	isLastStep: boolean,
	/** Whether the user should be able to select/submit multiple options. Only populated for MULTI_SELECT or GRID */
	multipleSelect?: boolean | undefined,
	/** The ID of the next quiz step, if present */
	nextStepId?: string | undefined,
	/** The select field options. Only populated if the type is MULTI-SELECT or GRID */
	options?: Array<ModelTypes["SelectFieldFetcher"]> | undefined,
	/** Only populated for type YES_NO where the field has a prior submisson */
	previousSelectedYesNoOption?: string | undefined,
	/** The ID of the prior quiz step, if present */
	priorStepId?: string | undefined,
	/** The main text of the step. You can imagine this as the question text, e.g. 'Do you like pickles?' */
	questionText: string,
	/** Optional copy for the submit button */
	submitText?: string | undefined,
	/** Optional supplemental text */
	supplementalCopy?: string | undefined,
	/** Optional icon to display with the supplemental text */
	supplementalCopyIcon?: string | undefined,
	/** Optional disclosure secondary text */
	supplementalDisclosureCopy?: string | undefined,
	/** Only populated if the type is FREE_TEXT */
	textFields?: Array<ModelTypes["TextFieldFetcher"]> | undefined,
	/** The type of the quiz step, e.g. 'INFORMATION', 'TEXT_INPUT', 'MULTI_SELECT' etc */
	type: ModelTypes["QuizStepType"]
};
	["SubmitMoneyLionOffers"]: {
		/** LeadUUID */
	leadUUID: string,
	/** Loan Offers grouped by Partner */
	loanOffers: Array<ModelTypes["PartnerLoanOffers"] | undefined>,
	/** Special Offers grouped by Partner */
	specialOffers: Array<ModelTypes["PartnerSpecialOffers"] | undefined>,
	/** UUID */
	uUID: string
};
	["SubmitQuizFeedbackFetcher"]: {
		/** The id of the quiz */
	id?: string | undefined
};
	["SubmitQuizResponse"]: {
		/** The state of the quiz */
	state?: ModelTypes["QuizStateFetcher"] | undefined
};
	["SubmitUserPromptResponse"]: {
		/** The outcome of submitting a user prompt response */
	outcome: ModelTypes["SubmitUserPromptOutcome"]
};
	/** A subscription is an agreement between Brigit and a user, for them to pay us periodically, and for us to provide them access to some of our services in exchange for that payment. Subscriptions are associated with a particular tier, which dictates what price the user pays and what features they are provided. */
["Subscription"]: {
		/** The tier that the subscription is currently linked to. */
	currentTier: ModelTypes["SubscriptionTier"],
	/** The time the current subscription tier became effective, or empty if user is NPE */
	currentTierValidFromTimestamp?: string | undefined,
	/** If present, the subscription currently has a discount applied to it. */
	discount?: ModelTypes["Discount"] | undefined,
	/** If user has a connected account, is approved, was previously enabled but currently disabled, has a loan, and does not have an outstanding loan, they are eligible for the reactivation experience. */
	isEligibleForReactivationExperience?: boolean | undefined,
	/** An explanation of how Instant Cash Express Transfer fees would work for a user on this tier. If the tier does not provide access to Instant Cash, instant transfer fees are not applicable and this field will be empty. NOTE: for NPE users this will be the subscription date if the user activates today with their same bank account, this could change if they change their bank account or income source. */
	proposedBillDate?: string | undefined,
	/** If present, the subscription fee schedule the user has been assigned to determine when in the month we'll schedule their monthly subscription payment. */
	subscriptionFeeSchedule?: ModelTypes["SubscriptionFeeSchedule"] | undefined,
	/** Subscription payments that have been scheduled for a user, excluding those that were cancelled because the user deactivated during the grace period or upgraded to the Premium plan. */
	subscriptionPayments?: Array<ModelTypes["SubscriptionPayment"]> | undefined,
	/** The variation of the provided experiment to use for this subscription. */
	subscriptionTierExperiment: ModelTypes["SubscriptionTierExperiment"],
	/** A subscription tier strategy indicates the subscription experience users are subject to,
    the details of which will be reflected in their `availableSubscriptionTiers`. */
	subscriptionTierStrategyName?: string | undefined
};
	["SubscriptionPayment"]: {
		/** Full subscription amount user is paying month over month, not taking promotions into account. */
	intendedAmount?: ModelTypes["BigDecimal"] | undefined,
	/** Whether a subscription is repayable. Controls whether we'll allow usersto repay a missed subscription from the home screen. */
	isPayable: boolean,
	/** Amount that we attempted to collect for a given subscription. Will be 1:1 with intendedAmount unless user received a promotion for a given month. */
	netAmount: ModelTypes["BigDecimal"],
	/** Date on which we'll attempt to collect on the subscription payment, formatted YYYY-MM-dd */
	paymentDate: string,
	/** Status of subscription payment. */
	status: ModelTypes["SubscriptionStatus"],
	/** If present, the subscription tier the user was on when we scheduled the givensubscription payment. If not present, the user is a legacy non-tiered user. */
	subscriptionTierLevel?: ModelTypes["SubscriptionTierLevel"] | undefined,
	/** UUID of subscription payment */
	uuid: string
};
	/** A subscription tier is an offering for a type of subscription that a user can enroll in. A tier has a price that the user pays to access it, and includes features that Brigit provides in exchange. Some tiers provide fewer features for a lower price, and others provide more features for a higher price. */
["SubscriptionTier"]: {
		/** The name of this tier to show to the user. */
	displayName: string,
	/** An explanation of how Instant Cash Express Transfer fees would work for a user on this tier. If the tier does not provide access to Instant Cash, instant transfer fees are not applicable and this field will be empty. NOTE: for NPE users this will be the subscription date if the user activates today with their same bank account, this could change if they change their bank account or income source. */
	expressTransferFeeText?: string | undefined,
	/** An identifier for which subscription tier this is. There are a small, fixed number of tiers. */
	level: ModelTypes["SubscriptionTierLevel"],
	/** The monthly cost that a user on this tier would pay, in US dollars. */
	pricingAmount: ModelTypes["BigDecimal"],
	/** The Brigit products that a user on this tier would have access to. */
	products: Array<ModelTypes["Product"]>
};
	/** A subscription tier experiment involves splitting users into different groups to provide them with different subscription-related experiences, to see which experiences work best. This object records a user's participation in a single experiment. */
["SubscriptionTierExperiment"]: {
		/** Identifies the experiment. */
	experimentName: ModelTypes["SubscriptionTierExperimentName"],
	/** A variant name identifies which group the user is part of for this experiment. The names will depend on the experiment, but CONTROL is typically the name given to the variant that provides an unchanged experience. If only one alternate experience is being tested, the variant for users being shown the new experience is typically TEST. Some experiments may have other variants, however, with unique names. */
	variantName: string
};
	["SwitchProtectedLoginToExistingLogin"]: {
		/** An Enum denoting if the outcome of switching the protected login was successful or failure */
	outcome: ModelTypes["SwitchProtectedLoginToExistingLoginOutcome"]
};
	["SwitchProtectedLoginToExistingNonConnectedLogin"]: {
		/** An Enum denoting if the outcome of switching the protected login to an existing, non-connected login was successful or failure */
	outcome: ModelTypes["SwitchProtectedLoginToExistingNonConnectedLoginOutcome"]
};
	["TablessMarketplaceOffer"]: {
		/** The url for the offers affiliate link. */
	affiliateLinkUrl: string,
	/** A list of badges to be rendered with the offer. */
	badges: Array<ModelTypes["MarketplaceBadge"]>,
	/** The categories the offer is under. */
	categories: Array<ModelTypes["TablessMarketplaceCategoryType"]>,
	/** The offers description. */
	description: string,
	/** The offers details. */
	details: string,
	/** The offers id. */
	id: ModelTypes["Long"],
	/** The url for the offers logos image file. */
	logoUrl: string,
	/** The offers name. */
	offerName: string
};
	/** Represents the installment loan term details for a prospective user */
["TermRequestDetails"]: {
		/** Application fee in cents */
	applicationFeeInCents: ModelTypes["Long"],
	/** Estimated delivery for the principal payment credit */
	estimatedDeliveryDateForPrincipalCredit: string,
	/** Monthly fee in cents */
	monthlyFeeInCents: ModelTypes["Long"],
	/** Pay Frequency type for the payment schedule */
	payFrequency: ModelTypes["FrontendInstallmentLoansPayFrequency"],
	/** Payment schedule */
	paymentSchedule: Array<ModelTypes["FrontendPrincipalPayment"] | undefined>,
	/** Total months of repayment for the term */
	totalMonthsInTerm: ModelTypes["Long"],
	/** Loan amount in cents */
	totalPrincipalAmountInCents: ModelTypes["Long"]
};
	["TextFieldFetcher"]: {
		/** Prior persisted value input by the user, if exists */
	initValue?: string | undefined,
	/** Whether the text field may contain sensitive information */
	isSensitive: boolean,
	/** What type of keyboard should be shown */
	keyboardType?: ModelTypes["KeyboardType"] | undefined,
	/** The text field label */
	label: string,
	/** A list of strings containing the names of methods to format text, e.g. date or dollarAmount */
	textFormat: Array<ModelTypes["TextFormatter"]>,
	/** An array of strings of validation that should be applied to the field */
	validate: Array<ModelTypes["TextInputValidators"]>,
	/** An array of strings of possible warnings that should be applied to the field */
	warn: Array<ModelTypes["TextInputWarning"]>
};
	["TrackImpressionsFetcher"]: {
		/** Tracked impression that is saved to the backend if the outcome indicates a success */
	impression: ModelTypes["UserImpression"],
	/** Result of the attempt to save the impression */
	outcome: ModelTypes["TrackNewImpressionsOutcome"]
};
	["TrackNotificationCenterEntryClick"]: {
		/** Enum denoting if the outcome of tracking click on notification center entry was a success or failure */
	outcome: ModelTypes["TrackNotificationCenterEntryClickOutcome"]
};
	["UnderwritingDetails"]: {
		/** Describes the approval of the user's account which could be approved, unapproved, or not run if the user has not been underwritten yet */
	accountApprovalState: ModelTypes["FrontendAccountApprovalState"],
	/** Whether the user is approved or not based on their credit risk state */
	creditRiskState: ModelTypes["FrontendCreditRiskState"],
	/** Fetch users approval state from the latest underwriting report */
	isApproved: boolean,
	/** Latest credit limit boost for a user */
	latestCreditLimitBoost?: ModelTypes["CreditLimitBoostDetails"] | undefined,
	/** Maximum loan amount for user */
	maxLoanAmount: ModelTypes["BigDecimal"],
	/** Minimum loan amount for user */
	minLoanAmount: ModelTypes["BigDecimal"],
	/** A numeric representation of the user's progress in being approved based on the number of underwriting rules the user has met and not met */
	progress: number,
	/** A representation of the user's underwriting score and score categories. This field is only present if the user is on a score-based underwriting strategy. */
	scoreCard?: ModelTypes["ScoreCard"] | undefined,
	/** Fetch all underwriting checks which include knockout rules and account level rules from the latest underwriting report */
	underwritingChecks: Array<ModelTypes["FrontendUnderwritingCheck"]>,
	/** List of underwriting rules and metadata used for underwriting the user */
	underwritingRulesMetadata: Array<ModelTypes["UnderwritingRuleMetadata"] | undefined>,
	/** All credit limit boosts for a user */
	userCreditLimitBoosts?: Array<ModelTypes["CreditLimitBoostDetails"] | undefined> | undefined
};
	["UnderwritingRuleMetadata"]: {
		/** Depending on the underwriting rule, the cutoff serves as the maximum or minimum amount needed for the user to meet this UW rule */
	cutoff: string,
	/** Name of underwriting rule used for calculating the user's underwriting score */
	name: string
};
	["UpdateBankAccountJointStatus"]: {
		/** An Enum denoting if the outcome of updating the bank account's joint status was successful or failure */
	outcome: ModelTypes["UpdateBankAccountJointStatusOutcome"]
};
	["UpdateCreditLimitBoostStatus"]: {
		/** The updated status of the credit limit boost */
	updatedCreditLimitBoostStatus: ModelTypes["FrontendCreditLimitBoostStatus"]
};
	["UpdateInstallmentLoansAutoPayStatusFetcher"]: {
		/** An Enum denoting if the outcome of the auto update status is successful */
	outcome: ModelTypes["FrontendInstallmentLoansUpdateAutoPayOutcome"]
};
	/** A USA state, territory, or region that has a human-readable name and associated code. */
["UsaState"]: {
		/** The two-letter code for the state (e.g. 'MD') */
	code: string,
	/** The human readable name for the state (e.g. 'Maryland'). */
	name: string
};
	/** A user's account is like their profile within Brigit. It's the object that records the user's state, preferences, and the interactions and agreements made between the user and Brigit. */
["UserAccount"]: {
		/** List of active logins for user. This will include the user's protected login (which holds the account we're sending advances to and collecting subscriptions from) and their non-protected, active logins, which are non-deleted primary logins that we still maintain a connection to. */
	activeBankLogins: ModelTypes["ActiveBankLogins"],
	/** The user's approved loan amounts */
	approvedLoanAmounts?: ModelTypes["ApprovedLoanAmounts"] | undefined,
	/** The user's auto advance configuration */
	autoAdvanceDetails: ModelTypes["AutoAdvanceDetails"],
	/** Predictions based on the user's linked bank account such as their income sources and likelihood to overdraft. */
	bankAccountPrediction: ModelTypes["BankAccountPrediction"],
	/** The user's bank connection details */
	bankConnection?: ModelTypes["BankConnection"] | undefined,
	/** Date on which user created their account */
	creationDate?: string | undefined,
	/** The user's eligibility for Credit Builder enrollment. */
	creditBuilderEnrollmentEligibility: ModelTypes["CreditBuilderEnrollmentEligibility"],
	/** The user's most recent Credit Builder installment loan. */
	creditBuilderInstallmentLoan?: ModelTypes["CreditBuilderInstallmentLoan"] | undefined,
	/** The Sweep Stakes qualification determines if user is qualifies for sweep stake */
	creditBuilderSweepStakesQualification: ModelTypes["FrontendCreditBuilderSweepStakesQualificationType"],
	/** User details related to Credit Builder */
	creditBuilderUserDetails: ModelTypes["CreditBuilderUserDetails"],
	/** The user's relationship with Brigit's Credit Monitoring product. */
	creditMonitoringEnrollment: ModelTypes["CreditMonitoringEnrollment"],
	/** Promo card v1 offers from the dynamic data model */
	dynamicPromoCardV1Offers: Array<ModelTypes["PromoCardOffer"]>,
	/** The user's email address. */
	email: string,
	/** The user's email address that is in the process of being verified. */
	emailToVerify?: string | undefined,
	/** Extension credits for user - they can use this to extend the loan due date for any outstanding loan */
	extensionCredits: number,
	/** The user's first name. */
	firstName?: string | undefined,
	/** Flags represent states that a user may be in. Historically they have been used to mark users for inclusion or exclusion in tests, though we hope to move to an alternative way of managing that. */
	flags: Array<ModelTypes["UserFlag"]>,
	/** Get if user is in a state where they have been approved for a free instant cash transfer. */
	freeInstantCashTransferState?: boolean | undefined,
	/** Customizable banner to display to user on the home screen */
	homeScreenBanner?: ModelTypes["HomeScreenBanner"] | undefined,
	/** User properties related to Instant Cash experiments */
	iCExperimentProperties: ModelTypes["ICExperimentProperties"],
	/** The user's ID is their unique identifier within Brigit. While mostly used internally, it may need to be provided to external services to link a user's account to them. */
	id: string,
	/** Whether the user is enrolled, or was previously enrolled, in Brigit's Identity Protection product. */
	identityProtectionEnrollmentStatus: ModelTypes["IdentityProtectionEnrollmentStatus"],
	/** Profile representing a user's salary based on specific filters */
	incomeProfile?: ModelTypes["IncomeProfile"] | undefined,
	/** The user's prospective and current details related with the Installment Loans product */
	installmentLoans: ModelTypes["InstallmentLoans"],
	/** The user's last name. */
	lastName?: string | undefined,
	/** This field is no longer relevant as we have rolled out tiered subscriptions at 100%. It remains here as a placeholder until we set the minimum app version to >= v704, which is the version where we removed this field from the query in the FE */
	legacyProposedNextMonthDiscountedAmount?: ModelTypes["BigDecimal"] | undefined,
	/** The user's prospective and current details related with the Installment Loans product */
	lineOfCredit: ModelTypes["LineOfCredit"],
	/** The user's location state */
	locationState?: ModelTypes["UsaState"] | undefined,
	/** A list of MarketplaceOffers by Category */
	marketplaceOffers: Array<ModelTypes["MarketplaceOfferByCategory"]>,
	/** Lists of marketplace offers. */
	marketplaceOffersV2: ModelTypes["MarketplaceOffersV2"],
	/** MoneyLionEngineOffers */
	moneyLionEngineOffers: ModelTypes["MoneyLionEngineOffers"],
	/** In-app notification center */
	notificationCenter: ModelTypes["NotificationCenter"],
	/** Details related to the user's payroll accounts */
	payrollAccounts: Array<ModelTypes["Payroll_32_Accounts"] | undefined>,
	/** Details related to the user's payroll accounts */
	payrollAccountsV2: Array<ModelTypes["Payroll_32_Accounts_32_V2"] | undefined>,
	/** Details related to the user's payroll accounts */
	payrollAccountsWithOutcome: ModelTypes["Payroll_32_Account_32_Fetcher_32_With_32_Outcome"],
	/** Details related to the user's payroll connection */
	payrollConnection: ModelTypes["PayrollConnection"],
	/** The user's bank transactional data insights. */
	pfmSummary: ModelTypes["PfmSummary"],
	/** The user's phone number. */
	phoneNumber: string,
	/** The user's phone number that is in the process of being verified. */
	phoneNumberToVerify?: string | undefined,
	/** Variant on premium upsell card to display to users on the home screen */
	premiumUpsellCardVariant?: ModelTypes["PremiumUpsellCardVariant"] | undefined,
	/** Promo card v1 and v2 offers from the legacy data model */
	promoCardOffers: Array<ModelTypes["PromoCardOffer"]>,
	/** If present, the user is being offered a reduced price for the provided tier. Currently, if a user is eligible for a promotion, this field will return the same promotion across all subscription tiers. */
	promotion?: ModelTypes["Promotion"] | undefined,
	/** Details related to the user's advances */
	protectionDetails: ModelTypes["ProtectionDetails"],
	/** The user's referral link. */
	referralLink: string,
	/** The user's list of referrals. */
	referrals: Array<ModelTypes["Referral"]>,
	/** Includes the user's current payment plan, and what features they have access to as part of that. */
	subscription: ModelTypes["Subscription"],
	/** Return the subscription fee schedule text dynamically for the activate screen https://brigit.atlassian.net/browse/ENG-9573. */
	subscriptionFeeBillingScheduleText?: string | undefined,
	/** The message to show to the user regarding how their subscription would be charged for the provided tier, including when and for how much. If absent, we either don't have enough information to know what the schedule would be, or the user would not be charged anything. */
	subscriptionScheduleText?: string | undefined,
	/** The user's underwriting details - holds details around the underwriting report such as if a user is eligible for an Instant Cash advance */
	underwritingDetails?: ModelTypes["UnderwritingDetails"] | undefined,
	/** The user's Credit Builder debt persona as a result of user engagement quiz */
	userCreditBuilderDebtPersona?: ModelTypes["UserDebtPersona"] | undefined,
	/** The user's debt persona as a result of user engagement quiz */
	userDebtPersona?: ModelTypes["UserDebtPersona"] | undefined,
	/** The user's consent prompts to display */
	userPrompts: ModelTypes["UserPrompts"]
};
	/** User's debt persona information as a result of user engagement quiz */
["UserDebtPersona"]: {
		/** The user's debt persona */
	debtPersonaType: ModelTypes["DebtPersonaType"],
	/** The ID of the quiz */
	quizId: ModelTypes["Long"]
};
	["UserImpression"]: {
		/** Duration in milliseconds */
	durationMs: number,
	/** UI element tracked */
	elementName: ModelTypes["ImpressionableElement"],
	/** Impression end time */
	endedAt: string,
	/** Foreign keys to other data useful for understanding the impression */
	eventForeignKeys?: string | undefined,
	/** Impression ID */
	impressionId: string,
	/** Screen of the tracked event */
	screenName: string,
	/** Impression start time */
	startedAt: string,
	/** User ID */
	userId: string
};
	/** Represents a consent experience the user must undergo to opt into a certain feature set */
["UserPrompt"]: {
		/** The 0 indexed display rank. Lower the number, the 'higher' the priority */
	displayRank: ModelTypes["Long"],
	/** The user prompt type */
	type: ModelTypes["UserPromptType"]
};
	/** A list of user prompts to display consent experiences for certain features */
["UserPrompts"]: {
		/** List of prompt objects containing the prompt type and the display priority */
	prompts: Array<ModelTypes["UserPrompt"]>
};
	/** Represents the details for a installment loan */
["details"]: {
		/** Is auto pay enabled or disabled */
	autoPayStatus: ModelTypes["FrontendInstallmentLoansAutoPay"],
	/** Field that denotes if a user should be able to see the ability to make a 'manual' catchup payment on the FE */
	catchupEligibility: ModelTypes["FrontendInstallmentLoansManualPaymentEligibilityStatus"],
	/** Name of bank account installment loan was disbursed to */
	disbursalBankAccountName: string,
	/** Date loan was disbursed */
	disbursalDate: string,
	/** Status of loan disbursal */
	disbursalStatus: ModelTypes["FrontendInstallmentLoansDisbursalStatus"],
	/** Estimated delivery for the principal payment credit if loan is not delivered */
	estimatedDeliveryDateForPrincipalCredit?: string | undefined,
	/** List of late fees accrued with a fee amount and the date it accrued */
	lateFees?: Array<ModelTypes["FrontendOverduePayment"] | undefined> | undefined,
	/** Late fees due in cents */
	lateFeesDue: ModelTypes["Long"],
	/** Next scheduled principal payment if it exists */
	nextScheduledPrincipalPayment?: ModelTypes["FrontendPrincipalPayment"] | undefined,
	/** Field that denotes if a user should be able to see the ability to make a 'manual' prepay or pay-off payment on the FE */
	prepayAndPayoffEligibility: ModelTypes["FrontendInstallmentLoansManualPaymentEligibilityStatus"],
	/** Principal amount that is past due and overdue in cents */
	principalPaymentOutstanding: ModelTypes["Long"],
	/** Remaining due principal payments on the schedule that haven't been processed yet */
	remainingDuePayments: Array<ModelTypes["FrontendPrincipalPayment"]>,
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalPaymentsInCents: ModelTypes["Long"],
	/** Total paid amount so far in cents */
	totalPrincipalAmountInCents: ModelTypes["Long"],
	/** Installment Loan Id */
	uuid: string
};
	["BankProvider"]:BankProvider;
	["CampaignPromptType"]:CampaignPromptType;
	["ChangeSubscriptionTierOutcome"]:ChangeSubscriptionTierOutcome;
	["CopyColor"]:CopyColor;
	["CreatePayrollAccountOutcome"]:CreatePayrollAccountOutcome;
	["CreditBuilderEnrollmentIneligibilityReason"]:CreditBuilderEnrollmentIneligibilityReason;
	["CreditBuilderEnrollmentType"]:CreditBuilderEnrollmentType;
	["CreditBuilderPopulationType"]:CreditBuilderPopulationType;
	["CreditLimitBoostStatus"]:CreditLimitBoostStatus;
	["CreditMonitoringEnrollmentStatus"]:CreditMonitoringEnrollmentStatus;
	["CreditRating"]:CreditRating;
	["DayOfWeek"]:DayOfWeek;
	["DebtPersonaType"]:DebtPersonaType;
	["DeleteBankLoginOutcome"]:DeleteBankLoginOutcome;
	["DisableDirectDepositOutcome"]:DisableDirectDepositOutcome;
	["EducationLevel"]:EducationLevel;
	["EmploymentPayFrequency"]:EmploymentPayFrequency;
	["EmploymentStatus"]:EmploymentStatus;
	["ExperimentName"]:ExperimentName;
	["FrontEndCreditMonitoringEnrollment"]:FrontEndCreditMonitoringEnrollment;
	["FrontendAccountApprovalState"]:FrontendAccountApprovalState;
	["FrontendBankConnectionStatus"]:FrontendBankConnectionStatus;
	["FrontendBankInstitution"]:FrontendBankInstitution;
	["FrontendCBScheduleVersionReason"]:FrontendCBScheduleVersionReason;
	["FrontendCBScheduleVersionStatus"]:FrontendCBScheduleVersionStatus;
	["FrontendCreditBuilderEnrollmentStatus"]:FrontendCreditBuilderEnrollmentStatus;
	["FrontendCreditBuilderPaymentRetryStrategyType"]:FrontendCreditBuilderPaymentRetryStrategyType;
	["FrontendCreditBuilderReminderType"]:FrontendCreditBuilderReminderType;
	["FrontendCreditBuilderSweepStakesQualificationType"]:FrontendCreditBuilderSweepStakesQualificationType;
	["FrontendCreditBuilderTransactionSource"]:FrontendCreditBuilderTransactionSource;
	["FrontendCreditBuilderTransactionStatus"]:FrontendCreditBuilderTransactionStatus;
	["FrontendCreditBuilderTransactionType"]:FrontendCreditBuilderTransactionType;
	["FrontendCreditLimitBoostStatus"]:FrontendCreditLimitBoostStatus;
	["FrontendCreditRiskState"]:FrontendCreditRiskState;
	["FrontendDebitCardStatus"]:FrontendDebitCardStatus;
	["FrontendInitiateCreditDrawStatus"]:FrontendInitiateCreditDrawStatus;
	["FrontendInstallmentLoanChargePolicy"]:FrontendInstallmentLoanChargePolicy;
	["FrontendInstallmentLoanPaymentStrategy"]:FrontendInstallmentLoanPaymentStrategy;
	["FrontendInstallmentLoansAccountCreationStatus"]:FrontendInstallmentLoansAccountCreationStatus;
	["FrontendInstallmentLoansAutoPay"]:FrontendInstallmentLoansAutoPay;
	["FrontendInstallmentLoansCurrentStatus"]:FrontendInstallmentLoansCurrentStatus;
	["FrontendInstallmentLoansDisbursalStatus"]:FrontendInstallmentLoansDisbursalStatus;
	["FrontendInstallmentLoansKYCDecisionType"]:FrontendInstallmentLoansKYCDecisionType;
	["FrontendInstallmentLoansManualPaymentEligibilityStatus"]:FrontendInstallmentLoansManualPaymentEligibilityStatus;
	["FrontendInstallmentLoansPayFrequency"]:FrontendInstallmentLoansPayFrequency;
	["FrontendInstallmentLoansPaymentOutcome"]:FrontendInstallmentLoansPaymentOutcome;
	["FrontendInstallmentLoansStatus"]:FrontendInstallmentLoansStatus;
	["FrontendInstallmentLoansUpdateAutoPayOutcome"]:FrontendInstallmentLoansUpdateAutoPayOutcome;
	["FrontendLocAccountCreationStatus"]:FrontendLocAccountCreationStatus;
	["FrontendLocAccountStatus"]:FrontendLocAccountStatus;
	["FrontendLocAutopayStatus"]:FrontendLocAutopayStatus;
	["FrontendLocInitiatePaymentStatus"]:FrontendLocInitiatePaymentStatus;
	["FrontendLocKycRequirementStatus"]:FrontendLocKycRequirementStatus;
	["FrontendLocKycUnfulfillmentReasons"]:FrontendLocKycUnfulfillmentReasons;
	["FrontendLocMinPaymentStatus"]:FrontendLocMinPaymentStatus;
	["FrontendLocPaymentAttemptType"]:FrontendLocPaymentAttemptType;
	["FrontendLocUnderwritingStatus"]:FrontendLocUnderwritingStatus;
	["FrontendLocUserInitiatedPaymentChargeType"]:FrontendLocUserInitiatedPaymentChargeType;
	["FrontendMobileVersionSupportType"]:FrontendMobileVersionSupportType;
	["FrontendOngoingRefreshStatus"]:FrontendOngoingRefreshStatus;
	["FrontendPaymentAuthFlow"]:FrontendPaymentAuthFlow;
	["FrontendPaymentStatus"]:FrontendPaymentStatus;
	["FrontendPayrollAccountConnectionStatus"]:FrontendPayrollAccountConnectionStatus;
	["FrontendReturnType"]:FrontendReturnType;
	["FrontendSampleKeyType"]:FrontendSampleKeyType;
	["HeroIconSize"]:HeroIconSize;
	["IdentityProtectionEnrollmentStatus"]:IdentityProtectionEnrollmentStatus;
	["ImpressionableElement"]:ImpressionableElement;
	["InputSalaryDataOutcome"]:InputSalaryDataOutcome;
	["KeyboardType"]:KeyboardType;
	["LoanPurpose"]:LoanPurpose;
	["LoanTimelineStatus"]:LoanTimelineStatus;
	["LoanTimelineType"]:LoanTimelineType;
	["LoginFailureReason"]:LoginFailureReason;
	["MarkNotificationCenterEntriesViewedOutcome"]:MarkNotificationCenterEntriesViewedOutcome;
	["MarketplaceBadgeType"]:MarketplaceBadgeType;
	["MarketplaceFeature"]:MarketplaceFeature;
	["ModelRank"]:ModelRank;
	["NonRepaymentReason"]:NonRepaymentReason;
	["OneTimePaymentOutcome"]:OneTimePaymentOutcome;
	["OngoingRefreshStatus"]:OngoingRefreshStatus;
	["OverdraftLikelihood"]:OverdraftLikelihood;
	["PayFrequency"]:PayFrequency;
	["PayrollAccountConnectionStatus"]:PayrollAccountConnectionStatus;
	["PayrollAccountFetcherOutcome"]:PayrollAccountFetcherOutcome;
	["PayrollProvider"]:PayrollProvider;
	["PersistedIncomeStatus"]:PersistedIncomeStatus;
	["PremiumUpsellCardVariant"]:PremiumUpsellCardVariant;
	["Product"]:Product;
	["ProductSubType"]:ProductSubType;
	["PromptResponseType"]:PromptResponseType;
	["PropertyStatus"]:PropertyStatus;
	["ProtectionMediumType"]:ProtectionMediumType;
	["ProtectionStatusV4"]:ProtectionStatusV4;
	["QuizFeedbackType"]:QuizFeedbackType;
	["QuizName"]:QuizName;
	["QuizStepType"]:QuizStepType;
	["RecentScoreChangeText"]:RecentScoreChangeText;
	["RegisterBankLoginMaintainingExistingLoginOutcome"]:RegisterBankLoginMaintainingExistingLoginOutcome;
	["RepayAdvanceOutcome"]:RepayAdvanceOutcome;
	["RequestManualDisbursalOutcome"]:RequestManualDisbursalOutcome;
	["ResendAuthVerificationCodeOutcome"]:ResendAuthVerificationCodeOutcome;
	["SalaryAggregateFilterCategory"]:SalaryAggregateFilterCategory;
	["SamplingTrigger"]:SamplingTrigger;
	["ScheduleCreditBuilderSameDayManualPaymentOutcome"]:ScheduleCreditBuilderSameDayManualPaymentOutcome;
	["ScoreChangeCallToAction"]:ScoreChangeCallToAction;
	["SubmitUserPromptOutcome"]:SubmitUserPromptOutcome;
	["SubscriptionFeeSchedule"]:SubscriptionFeeSchedule;
	["SubscriptionStatus"]:SubscriptionStatus;
	["SubscriptionTierExperimentName"]:SubscriptionTierExperimentName;
	["SubscriptionTierLevel"]:SubscriptionTierLevel;
	["SwitchProtectedLoginToExistingLoginOutcome"]:SwitchProtectedLoginToExistingLoginOutcome;
	["SwitchProtectedLoginToExistingNonConnectedLoginOutcome"]:SwitchProtectedLoginToExistingNonConnectedLoginOutcome;
	["TablessMarketplaceCategoryType"]:TablessMarketplaceCategoryType;
	["TextFormatter"]:TextFormatter;
	["TextInputValidators"]:TextInputValidators;
	["TextInputWarning"]:TextInputWarning;
	["TrackNewImpressionsOutcome"]:TrackNewImpressionsOutcome;
	["TrackNotificationCenterEntryClickOutcome"]:TrackNotificationCenterEntryClickOutcome;
	["UXPromptResponseType"]:UXPromptResponseType;
	["UnderwritingCategory"]:UnderwritingCategory;
	["UnderwritingRule"]:UnderwritingRule;
	["UpdateBankAccountJointStatusOutcome"]:UpdateBankAccountJointStatusOutcome;
	["UserFlag"]:UserFlag;
	["UserFriendlyCardNetwork"]:UserFriendlyCardNetwork;
	["UserPromptType"]:UserPromptType;
	/** java.math.BigDecimal */
["BigDecimal"]:any;
	/** A calendar day, in ISO-8601 "yyyy-MM-dd" format */
["Date"]:any;
	/** Long type */
["Long"]:any;
	/** UUID V4 format */
["UUID"]:any
    }

export type GraphQLTypes = {
    ["LoginUnion"]:{
        	__typename:"LoginFailure" | "LoginSuccess"
        	['...on LoginFailure']: '__union' & GraphQLTypes["LoginFailure"];
	['...on LoginSuccess']: '__union' & GraphQLTypes["LoginSuccess"];
};
	["RegisterOneTimeCardUnion"]:{
        	__typename:"RegisterOneTimeCardInvalidInput" | "RegisterOneTimeCardSuccess"
        	['...on RegisterOneTimeCardInvalidInput']: '__union' & GraphQLTypes["RegisterOneTimeCardInvalidInput"];
	['...on RegisterOneTimeCardSuccess']: '__union' & GraphQLTypes["RegisterOneTimeCardSuccess"];
};
	["AccountConnectedResponseFetcher"]: {
	__typename: "AccountConnectedResponseFetcher",
	/** The provider ID of the connected payroll account */
	payrollAccountId: string
};
	["ActiveBankLogins"]: {
	__typename: "ActiveBankLogins",
	/** List of active logins for user */
	logins: Array<GraphQLTypes["FrontendBankLogin"]>
};
	["ApprovedLoanAmounts"]: {
	__typename: "ApprovedLoanAmounts",
	/** Approved loan amounts for user - used for frontend display purposes */
	approvedLoanAmounts: Array<GraphQLTypes["BigDecimal"] | undefined>
};
	/** Details about the user's auto advance configuration */
["AutoAdvanceDetails"]: {
	__typename: "AutoAdvanceDetails",
	/** The amount of the user's auto advance */
	amount?: GraphQLTypes["BigDecimal"] | undefined,
	/** The estimated delivery date if an advance was taken today */
	deliveryDate?: string | undefined,
	/** The estimated due date if an advance was taken today */
	dueDate?: string | undefined,
	/** Whether the user has auto advances enabled for disbursal when the user's balance is low */
	isAutoEnabled: boolean,
	/** Whether the user is due to receive an auto advance */
	willAutoDisburse: boolean
};
	["AverageScoreChangeDetailsFetcher"]: {
	__typename: "AverageScoreChangeDetailsFetcher",
	/** An Optional<Integer> representing the average score change for this score bucket and sample period. */
	averageScoreChange?: number | undefined,
	/** The ending date the average was computed. */
	samplePeriodEnd: string,
	/** The starting date the average was computed. */
	samplePeriodStart: string
};
	/** Predictions based on the user's linked bank account such as their income sources and likelihood to overdraft. */
["BankAccountPrediction"]: {
	__typename: "BankAccountPrediction",
	/** List of the user's income sources based on their linked bank account. */
	incomeSources: Array<GraphQLTypes["Income_32_Source"] | undefined>,
	/** The user's next predicted payday based on their linked bank account. */
	nextPayday?: string | undefined,
	/** The likelihood the user's balance will drop to 0 soon. */
	overdraftLikelihood?: GraphQLTypes["OverdraftLikelihood"] | undefined,
	/** The date we predict the user's balance to drop to 0. */
	zeroDate?: string | undefined
};
	["BankConnection"]: {
	__typename: "BankConnection",
	/** The protected bank account mask */
	accountMask?: string | undefined,
	/** The protected bank account's available balance */
	balanceAvailable?: GraphQLTypes["BigDecimal"] | undefined,
	/** The protected bank account's current balance */
	balanceCurrent?: GraphQLTypes["BigDecimal"] | undefined,
	/** Bank login ID */
	bankLoginId: string,
	/** Provider associated with bank connection (Plaid or Capital One) */
	bankProvider: GraphQLTypes["BankProvider"],
	/** Checking accounts that belong to the user protected login */
	checkingAccounts: Array<GraphQLTypes["FrontendAccountWithCard"]>,
	/** The bank institution details for the user's current bank connection. */
	institution: GraphQLTypes["BankInstitution"],
	/** The last time we call the bank data provider API */
	lastUpdatedTimestamp?: string | undefined,
	/** The protected bank account's payment auth flow */
	paymentAuthFlow?: GraphQLTypes["FrontendPaymentAuthFlow"] | undefined,
	/** The protected bank account ID */
	protectedAccountId?: string | undefined,
	/** Bank connection status. */
	status?: GraphQLTypes["FrontendBankConnectionStatus"] | undefined
};
	["BankInstitution"]: {
	__typename: "BankInstitution",
	/** The protected bank account's institution id. Only populated for Plaid users. */
	id?: string | undefined,
	/** The protected bank account's institution name */
	institutionName: string,
	/** The institution logo URL */
	logoUrl?: string | undefined,
	/** The bank institution type */
	type?: GraphQLTypes["FrontendBankInstitution"] | undefined
};
	["ChangeSubscriptionTierResponse"]: {
	__typename: "ChangeSubscriptionTierResponse",
	/** The result of the attempt to change the user's subscription tier. */
	outcome: GraphQLTypes["ChangeSubscriptionTierOutcome"],
	/** The user's subscription after the request is completed. If the outcome is not SUCCESS, the subscription will be the same as it was previously. */
	updatedSubscription: GraphQLTypes["Subscription"]
};
	["CompleteQuizFetcher"]: {
	__typename: "CompleteQuizFetcher",
	/** The id of the quiz */
	id?: string | undefined
};
	["CreatePayrollAccountResponse"]: {
	__typename: "CreatePayrollAccountResponse",
	/** The provider ID of the payroll account created */
	payrollAccountId: string
};
	["CreatePayrollAccountResponseV2"]: {
	__typename: "CreatePayrollAccountResponseV2",
	/** The outcome of creating the user's payroll account. */
	outcome: GraphQLTypes["CreatePayrollAccountOutcome"]
};
	["CreateTargetDepositDestinationResponse"]: {
	__typename: "CreateTargetDepositDestinationResponse",
	/** Encrypted version of the user's target deposit destination */
	encryptedDDSConfig: string
};
	/** Details on which type of enrollment is applicable to the user and any reasons they are ineligible for that enrollment type. */
["CreditBuilderEnrollmentEligibility"]: {
	__typename: "CreditBuilderEnrollmentEligibility",
	/** Whether the user is eligible for the type of enrollment. If this is false, the ineligibilityDetails will have the reasons they are ineligible. */
	eligible: boolean,
	/** The type of enrollment that the user's eligibility was checked for. */
	enrollmentType: GraphQLTypes["CreditBuilderEnrollmentType"],
	/** The details on why the user is ineligible for the type of enrollment. */
	ineligibilityDetails: Array<GraphQLTypes["CreditBuilderEnrollmentIneligibilityDetails"]>
};
	/** Information on a reason that a user is ineligible for a given enrollment type. */
["CreditBuilderEnrollmentIneligibilityDetails"]: {
	__typename: "CreditBuilderEnrollmentIneligibilityDetails",
	/** A textual description of the reason that can be displayed to the user. */
	description: string,
	/** The reason the user is ineligible. */
	reason: GraphQLTypes["CreditBuilderEnrollmentIneligibilityReason"]
};
	/** Contains global configuration data for the Credit Builder system. */
["CreditBuilderGlobalConfiguration"]: {
	__typename: "CreditBuilderGlobalConfiguration",
	/** The USA states in which Credit Builder is not available. */
	unsupportedStates: Array<GraphQLTypes["UsaState"]>
};
	/** A credit builder installment loan is a secured loan which the user has taken out to build their credit. The loan contains monthly payments which are reported to the bureaus for credit score calculation. */
["CreditBuilderInstallmentLoan"]: {
	__typename: "CreditBuilderInstallmentLoan",
	/** User's external contributions minus disbursals */
	availableSavingsBalance: GraphQLTypes["BigDecimal"],
	/** The charge policy configured for the credit builder loan. This could either be AUTO, meaning standard payments are automatically deducted from the user's external account or MANUAL, meaning the user would need to initiate standard payments themselves. */
	chargePolicy: GraphQLTypes["FrontendInstallmentLoanChargePolicy"],
	/** The date which the Credit Builder loan was closed on. If the loan was never closed, this should be empty. */
	closedOn?: string | undefined,
	/** The disbursals taken against the loans savings balance. */
	disbursals: Array<GraphQLTypes["CreditBuilderTransaction"]>,
	/** The date which the Credit Builder loan is expected to close if all payments are completed successfully. */
	expectedClosedDate: string,
	/** The numeric value for the day of the month the first installment payment is due. */
	expectedDayOfMonthForBilling: number,
	/** The amount user has set to debit their external bank account every month to pay off the installment in dollars. */
	externalMonthlyContributionAmountInDollars: GraphQLTypes["BigDecimal"],
	/** A List of the credit builder installment loan payment schedule dates and the amount due */
	installmentLoanPaymentSchedule: Array<GraphQLTypes["InstallmentLoanPaymentSchedule"]>,
	/** The amount user has set to debit from the Brigit restricted savings account every month to pay off the installment in dollars. */
	internalMonthlyContributionAmountInDollars: GraphQLTypes["BigDecimal"],
	/** The date which the Credit Builder loan was opened. */
	openedOn: string,
	/** Details about past due payments, if applicable. */
	pastDuePayment?: GraphQLTypes["PastDuePayment"] | undefined,
	/** The payment schedule strategy stored in the cb enrollments table for the credit builder loan. If the payment strategy is empty then it calculates the strategy on the fly and it could either be MONTHLY, meaning a user had no income and they selected a specific day of the month to make CB payments or INCOME, where a CB payment due date corresponds with a users payments */
	paymentStrategyForSchedule: GraphQLTypes["FrontendInstallmentLoanPaymentStrategy"],
	/** The payments that have been made towards the loan. */
	payments: Array<GraphQLTypes["CreditBuilderTransaction"]>,
	/** Details about possible pre-payments user can make toward their CB account. */
	prePayment?: GraphQLTypes["PrePayment"] | undefined,
	/** A proposed list of NEW installment loan payment schedule dates for a user in the middle of their term due */
	proposedDraftInstallmentLoanPaymentSchedule: Array<GraphQLTypes["InstallmentLoanPaymentSchedule"]>,
	/** A list of reminders for the user's installment loan account. This list may include upcoming scheduled payment, past due balance,  and same day manual payment reminders. */
	reminders: Array<GraphQLTypes["CreditBuilderReminder"]>,
	/** Payment details that are populated when user has manual payment method and payment is due today */
	sameDatePayment?: GraphQLTypes["SameDayPayment"] | undefined,
	/** Details regarding user's CB savings */
	savingsDetails: GraphQLTypes["CreditBuilderSavingsDetails"],
	/** Details regarding user's credit score */
	scoreDetails: GraphQLTypes["CreditBuilderScoreDetails"],
	/** The fixed number of months for the Credit Builder loan term. */
	termInMonths: GraphQLTypes["Long"],
	/** The total loan amount for the given Credit Builder enrollment term. */
	totalLoanAmountInDollars: GraphQLTypes["BigDecimal"],
	/** The total monthly amount between external and savings contributions that goes to paying off the installment in dollars. */
	totalMonthlyPaymentAmountInDollars: GraphQLTypes["BigDecimal"]
};
	/** A reminder associated with the user's open installment loan account. */
["CreditBuilderReminder"]: {
	__typename: "CreditBuilderReminder",
	/** The description of the reminder to show the user. */
	description: string,
	/** The type of reminder */
	type: GraphQLTypes["FrontendCreditBuilderReminderType"]
};
	/** Details about user's credit score */
["CreditBuilderSavingsDetails"]: {
	__typename: "CreditBuilderSavingsDetails",
	/** The user’s all time total savings across all CB accounts ever (how much the user contributed to all of their Credit Builder account(s), regardless of any subsequent disbursals */
	allTimeSavings: GraphQLTypes["BigDecimal"]
};
	/** Details about user's credit score */
["CreditBuilderScoreDetails"]: {
	__typename: "CreditBuilderScoreDetails",
	/** If CB account is open, delta is the difference between first score since first ever CB enrollment and latest credit score on file. If CB account is closed, delta is the difference between first score since first ever CB enrollment and last score before a user closed their CB account. */
	allTimeScoreDelta?: GraphQLTypes["Long"] | undefined
};
	/** A transaction that was processed for a Credit Builder installment loan. */
["CreditBuilderTransaction"]: {
	__typename: "CreditBuilderTransaction",
	/** The amount of the transaction in dollars. */
	amountInDollars: GraphQLTypes["BigDecimal"],
	/** The due date of the payment */
	dueOn?: string | undefined,
	/** The unique id of the transaction. */
	installmentLoanPaymentId?: GraphQLTypes["Long"] | undefined,
	/** The source account type from which the transaction originated. */
	source: GraphQLTypes["FrontendCreditBuilderTransactionSource"],
	/** The status of the transaction at the current point in time. */
	status: GraphQLTypes["FrontendCreditBuilderTransactionStatus"],
	/** The type of the transaction. Either a payment or a disbursal. */
	type: GraphQLTypes["FrontendCreditBuilderTransactionType"],
	/** The time that the transaction was last updated. */
	updatedAt?: string | undefined
};
	/** User details related to credit builder */
["CreditBuilderUserDetails"]: {
	__typename: "CreditBuilderUserDetails",
	/** The user's Credit Builder population type to determine whether to show CB related content. */
	populationType?: GraphQLTypes["CreditBuilderPopulationType"] | undefined
};
	["CreditLimitBoostDetails"]: {
	__typename: "CreditLimitBoostDetails",
	/** The amount of the credit limit boost in dollars. */
	boostAmount: GraphQLTypes["BigDecimal"],
	/** Whether the boost is available to be used for a loan which means the boost status is equal to OFFERED or ACCEPTED. */
	boostAvailable: boolean,
	/** List of advance options that apply part or all of the credit limit boost. */
	boostedAdvanceOptions: Array<GraphQLTypes["BigDecimal"] | undefined>,
	/** User's max advance amount plus their credit limit boost. */
	boostedMaxLoanAmount: GraphQLTypes["BigDecimal"],
	/** Unique ID of the credit limit boost */
	creditLimitBoostId: string,
	/** Current status of the credit limit boost */
	creditLimitBoostStatus: GraphQLTypes["CreditLimitBoostStatus"],
	/** Whether the boost should be offered to the user which means the boost status equals OFFERED. */
	shouldOfferBoost: boolean
};
	/** A credit monitoring enrollment is a user's relationship with Brigit's Credit Monitoring product, which maintains a history of their credit scores while they are enrolled. */
["CreditMonitoringEnrollment"]: {
	__typename: "CreditMonitoringEnrollment",
	/** The credit scores that Brigit has collected for the user while they have been enrolled in credit monitoring. Scores are sorted by date, starting with the earliest scores. */
	creditScores: Array<GraphQLTypes["CreditScore"]>,
	/** Describes whether a user is currently, or has been, enrolled in credit monitoring with Brigit. */
	enrollmentStatus: GraphQLTypes["CreditMonitoringEnrollmentStatus"],
	/** Representation of the user's recent credit score delta */
	recentScoreChange?: GraphQLTypes["RecentScoreChangeFetcher"] | undefined,
	/** Representation of how the user's credit score journey has progressed since starting credit builder relative to their peer group. */
	scoreMarker?: GraphQLTypes["CreditScoreMarkerFetcher"] | undefined
};
	/** A credit monitoring enrollment is a user's relationship with Brigit's Credit Monitoring product, which maintains a history of their credit scores while they are enrolled. */
["CreditMonitoringUser"]: {
	__typename: "CreditMonitoringUser",
	authToken: string
};
	/** A credit score is a numerical rating representing the perceived creditwothiness of an individual, as evaluated by one of the major US credit bureaus on a particular date. */
["CreditScore"]: {
	__typename: "CreditScore",
	/** The date that this score was calculated, in yyyy-MM-dd format. */
	date: string,
	/** The numerical value of the credit score, ranging from about 250 for a poor score to about 900 for a great score. */
	score: GraphQLTypes["Long"]
};
	["CreditScoreMarkerFetcher"]: {
	__typename: "CreditScoreMarkerFetcher",
	/** The score bucket that the user falls into (e.g. 300-349, 350-399, etc). */
	averageScoreChangeDetails: GraphQLTypes["AverageScoreChangeDetailsFetcher"],
	/** A number representing the days since latest CB enrollment. */
	days: GraphQLTypes["Long"],
	/** A number representing the period the user is in (e.g. 1, 2, 3, ..., 24). */
	period: GraphQLTypes["Long"],
	/** The score bucket that the user falls into (e.g. 300-349, 350-399, etc). */
	scoreBucket: GraphQLTypes["ScoreBucketFetcher"],
	/** The value of the users score change since opening their CB account. */
	userScoreChange: GraphQLTypes["Long"]
};
	["DeleteBankLogin"]: {
	__typename: "DeleteBankLogin",
	/** An Enum denoting if the outcome of deleting the bank login was successful or failure */
	outcome: GraphQLTypes["DeleteBankLoginOutcome"]
};
	["DeletePayrollAccountResponse"]: {
	__typename: "DeletePayrollAccountResponse",
	/** The ID of the payroll account being deleted */
	payrollAccountId: string
};
	["DisableDirectDepositsResponse"]: {
	__typename: "DisableDirectDepositsResponse",
	/** The outcome of disabling the user's direct deposit. */
	outcome: GraphQLTypes["DisableDirectDepositOutcome"]
};
	["DisablePayrollAccountResponse"]: {
	__typename: "DisablePayrollAccountResponse",
	/** The provider ID of the payroll account being disabled */
	payrollAccountId: string
};
	/** A discount is a temporary reduction in the price of a subscription. */
["Discount"]: {
	__typename: "Discount",
	/** If present, contains additional text to show users to explain the discount, such as the date range for which it applies, how it compares to the typical price, or any other information the user should know. */
	disclaimer?: string | undefined,
	/** The price, in US dollars, that will be charged for subscription payments that this discount applies to. */
	discountedAmount: GraphQLTypes["BigDecimal"],
	/** The estimated dates of upcoming subscription payments that this discount will be applied to. */
	discountedPaymentDates: Array<GraphQLTypes["Date"]>
};
	/** Fetcher for Dynamic Promo Card offers */
["DynamicPromoCards"]: {
	__typename: "DynamicPromoCards",
	/** Affiliate link URL for the offer */
	affiliateLinkUrl?: string | undefined,
	/** List of background colors for the offer */
	backgroundColors?: Array<string | undefined> | undefined,
	/** Copy color for the offer */
	copyColor?: GraphQLTypes["CopyColor"] | undefined,
	/** Call-to-action copy for the offer */
	ctaCopy?: string | undefined,
	/** Description of the offer */
	description?: string | undefined,
	/** URL for the offer icon */
	iconUrl?: string | undefined,
	/** Unique identifier for the offer */
	id?: GraphQLTypes["Long"] | undefined,
	/** URL for the offer logo */
	logoUrl?: string | undefined,
	/** Name of the offer */
	offerName?: string | undefined
};
	["FilteredSalaryData"]: {
	__typename: "FilteredSalaryData",
	/** The filter used to aggregate the salary data */
	filter: string,
	/** The average salary for users specific to this filter */
	salary: number
};
	/** Debit card information */
["FrontendAccountWithCard"]: {
	__typename: "FrontendAccountWithCard",
	/** The bank account ID associated with the card */
	bankAccountId: string,
	/** Associated debit card info */
	card?: GraphQLTypes["FrontendDebitCardInfo"] | undefined,
	/** Account current balance */
	currentBalance: GraphQLTypes["BigDecimal"],
	/** Whether or not account is eligible to be selected as the protected account */
	isEligibleProtectedAccount: boolean,
	/** Account joint status */
	isJoint: boolean,
	/** Account removal status */
	isRemoved: boolean,
	/** Account mask */
	mask: string,
	/** The name associated with the card */
	name: string
};
	/** Bank account associated with bank login */
["FrontendBankAccount"]: {
	__typename: "FrontendBankAccount",
	/** Available balance */
	availableBalance?: GraphQLTypes["BigDecimal"] | undefined,
	/** Current balance */
	currentBalance?: GraphQLTypes["BigDecimal"] | undefined,
	/** Bank account ID */
	id: string,
	/** Is joint */
	isJoint: boolean,
	/** Boolean representing whether login has been identified as the user's recommended protected account. Refer to: https://brigit.atlassian.net/browse/ENG-13002 */
	isRecommendedProtectedAccount: boolean,
	/** Bank account mask */
	mask: string,
	/** Bank account name */
	name: string
};
	["FrontendBankLogin"]: {
	__typename: "FrontendBankLogin",
	/** Checking accounts associated with login */
	bankAccounts: Array<GraphQLTypes["FrontendBankAccount"] | undefined>,
	/** Time bank login was created */
	createdAt?: string | undefined,
	/** Bank login ID */
	id: string,
	/** Institution details */
	institution: GraphQLTypes["BankInstitution"],
	/** Institution name */
	institutionName: string,
	/** Boolean representing whether login has been identified as the user's recommended protected login. Refer to: https://brigit.atlassian.net/browse/ENG-13002 */
	isRecommendedProtectedLogin: boolean,
	/** Bank connection status. */
	status: GraphQLTypes["FrontendBankConnectionStatus"]
};
	["FrontendDebitCardInfo"]: {
	__typename: "FrontendDebitCardInfo",
	/** Card block status */
	blockStatus?: string | undefined,
	/** Card's nick name and normally is it's last 4 */
	cardNickname: string,
	/** Card's expiration date */
	expirationDate?: string | undefined,
	/** Card network such as Visa, Mastercard, etc */
	network?: GraphQLTypes["UserFriendlyCardNetwork"] | undefined,
	/** Payment method ID associated with the card */
	paymentMethodId: GraphQLTypes["UUID"],
	/** Reflects micro-deposit verification and status of government id. */
	status: GraphQLTypes["FrontendDebitCardStatus"]
};
	["FrontendExperimentVariantAssignment"]: {
	__typename: "FrontendExperimentVariantAssignment",
	/** Flag to indicate whether the variant returned is the control flow. If the variant is either the
'Control' or 'Holdout', this will return as true. */
	isControlFlow: boolean,
	/** Name of the variant assigned for the sample key. */
	variantName: string,
	/** Value associated with the variant assigned for the sample key. */
	variantValue: string
};
	/** Represents a missed item which consists of all payments due on the same scheduled due date that are outstanding */
["FrontendMissedItem"]: {
	__typename: "FrontendMissedItem",
	/** late fee in cents */
	lateFeeAmountInCents: GraphQLTypes["Long"],
	/** original principal payment in cents */
	principalPaymentAmountInCents: GraphQLTypes["Long"],
	/** Original scheduled date in iso format */
	scheduledDate: string
};
	/** Represents a late fee with the pairing of the fee amount and date it was accrued (the original principal payment date) */
["FrontendOverduePayment"]: {
	__typename: "FrontendOverduePayment",
	/** Date accrued in iso format */
	dateAccrued: string,
	/** fee in cents */
	feeAmountInCents: GraphQLTypes["Long"],
	/** original principal payment in cents */
	principalPaymentAmountInCents: GraphQLTypes["Long"]
};
	/** Represents the pairing of the payment amount and date due for a user */
["FrontendPrincipalPayment"]: {
	__typename: "FrontendPrincipalPayment",
	/** Monthly payment in cents */
	amountInCents: GraphQLTypes["Long"],
	/** Payment date in iso format */
	paymentDate: string
};
	/** Report underwriting checks */
["FrontendUnderwritingCheck"]: {
	__typename: "FrontendUnderwritingCheck",
	/** Underwriting rule category */
	category?: GraphQLTypes["UnderwritingCategory"] | undefined,
	/** Whether user has fulfilled rule or not */
	isFulfilled: boolean,
	/** Underwriting description based on if rule is fulfilled or not */
	reason: string,
	/** Underwriting rule */
	rule: GraphQLTypes["UnderwritingRule"]
};
	/** Controls a customizable banner we may display to users on the home screen when we want to get out some message to users with a quick turnaround. */
["HomeScreenBanner"]: {
	__typename: "HomeScreenBanner",
	/** Cta to show with the banner */
	callToActionText?: string | undefined,
	/** Link url that we will open when a user clicks on the banner */
	linkUrl?: string | undefined,
	/** Main message associated with banner. */
	message: string
};
	/** Frequently Asked Questions related to IC Activation. */
["ICActivationFAQs"]: {
	__typename: "ICActivationFAQs",
	/** Description of the frequently asked question. */
	description: string,
	/** Positional id returned for front end ordering purposes */
	id: number,
	/** Title of the Frequently Asked Question. */
	title: string
};
	/** Experiments related to Instant Cash */
["ICExperimentProperties"]: {
	__typename: "ICExperimentProperties",
	/** Whether we should display the upsell screen or not based on the user's flag and tier history */
	shouldDisplayUpsell: boolean
};
	["IncomeProfile"]: {
	__typename: "IncomeProfile",
	/** Primary filter used to organize salary data */
	filterOne: GraphQLTypes["SalaryAggregateFilterCategory"],
	/** Possible values for filter one */
	filterOneValues: Array<string | undefined>,
	/** Secondary filter used to organize salary data */
	filterTwo?: GraphQLTypes["SalaryAggregateFilterCategory"] | undefined,
	/** Possible values for filter two */
	filterTwoValues: Array<string | undefined>,
	/** List of data points based on the user's income */
	salaryAggregators: Array<GraphQLTypes["SalaryAggregators"] | undefined>
};
	/** An income source contains the user's paycheck and payday details based on their linked bank account. */
["Income_32_Source"]: {
	__typename: "Income_32_Source",
	/** The average amount of money the user earns from this income source. */
	amountMean: GraphQLTypes["BigDecimal"],
	/** For users who get paid twice a month, their payday may be pinned to specific dates such as the 1st and 15th or the 15th and 30th. Day of month 1 refers to the first date that's pinned for the user's payday. The day of month 1 will be 0 if the user doesn't have a paycheck that follows this pattern. */
	dayOfMonth1: number,
	/** For users who get paid twice a month, their payday may be pinned to specific dates such as the 1st and 15th or the 15th and 30th. Day of month 2 refers to the second date that's pinned for the user's payday. The day of month 2 will be 0 if the user doesn't have a paycheck that follows this pattern. */
	dayOfMonth2: number,
	/** Day of week is the specific day the user's payday is on e.g. Thursday. If there's no specific day for that user's payday, Monday will be used as the default. */
	dayOfWeek: GraphQLTypes["DayOfWeek"],
	/** Early payday offset is the number of days the user's income will be deposited into their account early. */
	earlyPayOffset: number,
	/** Is end of month refers to whether or not the user's payday will fall at the end of the month since not all months have the same number of days. */
	endOfMonth: boolean,
	/** The frequency refers to how often the user gets paid such as biweekly, weekly, monthly, etc. */
	frequency: GraphQLTypes["PayFrequency"],
	/** This is true or false depending on if the user's payday is adjusted if it falls on a holiday. If so, then it's true; otherwise, false. */
	holidayAdjustNext: boolean,
	/** The id is the unique id for this specific user's income source. */
	id?: string | undefined,
	/** The income payer ID is similar to a bank memo for the transaction. If there are similar bank memos, they will be fuzzy matched to produce a singular income payer ID. */
	incomePayerId: string,
	/** The period mean refers to the average number of days between paychecks for this income source. This value is typically set for irregular income sources. 0 is set as the default value if this income source doesn't have a period mean. */
	periodMean: number,
	/** The rejection status is the user-friendly description for why the income source could not be verified. If the income source can be verified, then the rejection status will be an empty string. */
	rejectionStatus?: string | undefined,
	/** Status refers to the verification status of this income source e.g. VERIFIED, PENDING, UNVERIFIABLE, and BLACKLISTED. */
	status?: GraphQLTypes["PersistedIncomeStatus"] | undefined,
	/** Is verified is true or false depending on whether or not someone has verified this income source and confirmed it's legitimate. */
	verified?: boolean | undefined,
	/** Week of month refers to the week that the user's payday is on. For example, 1 would refer to the 1st week of the month. The week of month would equal 0 if there's no specific week the user's payday falls on. */
	weekOfMonth: number
};
	["InitiateCatchUpPaymentForInstallmentLoan"]: {
	__typename: "InitiateCatchUpPaymentForInstallmentLoan",
	/** An Enum denoting if the outcome of the catch up payment was successful or failure */
	outcome: GraphQLTypes["FrontendInstallmentLoansPaymentOutcome"]
};
	["InitiateOneTimePayment"]: {
	__typename: "InitiateOneTimePayment",
	/** An Enum denoting if the outcome of the oneTimePayment was successful or failure */
	oneTimePaymentOutcome: GraphQLTypes["OneTimePaymentOutcome"]
};
	["InitiatePayOffPaymentForInstallmentLoan"]: {
	__typename: "InitiatePayOffPaymentForInstallmentLoan",
	/** An Enum denoting if the outcome of the pay off payment was successful or failure */
	outcome: GraphQLTypes["FrontendInstallmentLoansPaymentOutcome"]
};
	["InitiatePrePaymentForInstallmentLoan"]: {
	__typename: "InitiatePrePaymentForInstallmentLoan",
	/** An Enum denoting if the outcome of the pre payment was successful or failure */
	outcome: GraphQLTypes["FrontendInstallmentLoansPaymentOutcome"]
};
	["InputSalaryData"]: {
	__typename: "InputSalaryData",
	/** Outcome denoting if adding the salary data was successful or not */
	outcome: GraphQLTypes["InputSalaryDataOutcome"]
};
	/** Credit builder installment loan payment schedule due dates with amount due. */
["InstallmentLoanPaymentSchedule"]: {
	__typename: "InstallmentLoanPaymentSchedule",
	/** The amount of the payment in dollars. */
	amountInDollars: GraphQLTypes["BigDecimal"],
	/** Payment schedule due date */
	paymentDate: string
};
	/** Represents the post disbursal details for an installment loan */
["InstallmentLoanPostDisbursalInfo"]: {
	__typename: "InstallmentLoanPostDisbursalInfo",
	/** Name of bank account installment loan was disbursed to */
	disbursalBankAccountName: string,
	/** Date loan was disbursed */
	disbursalDate: string,
	/** Status of loan disbursal */
	disbursalStatus: GraphQLTypes["FrontendInstallmentLoansDisbursalStatus"],
	/** Estimated delivery for the principal payment credit if loan is not delivered */
	estimatedDeliveryDate?: string | undefined
};
	/** Details related to both a potential and an existing installment loans user */
["InstallmentLoans"]: {
	__typename: "InstallmentLoans",
	/** Fetch if a user have an installment loan account or not.  */
	currentLoanStatus: GraphQLTypes["FrontendInstallmentLoansCurrentStatus"],
	/** Fetch current open installment loan details */
	currentOpenInstallmentLoan?: GraphQLTypes["InstallmentLoansDetails"] | undefined,
	/** Fetches all installment loans including details for a user */
	details: Array<GraphQLTypes["details"]>,
	/** Fetches the KYC status for a prospective IL user */
	kycDecision: GraphQLTypes["FrontendInstallmentLoansKYCDecisionType"],
	/** Fetches the string contents for the proposed loan agreement */
	signedLoanAgreement: string,
	/** Evaluates and fetches the loan term details for a prospective IL account */
	termRequestDetails: GraphQLTypes["TermRequestDetails"],
	/** Evaluates and fetches the underwriting status for a prospective IL user */
	underwritingDetails: GraphQLTypes["LocUnderwritingDetails"],
	/** Fetches the string contents for the proposed loan agreement */
	unsignedProposedLoanAgreement: string
};
	/** Represents the details for a installment loan */
["InstallmentLoansDetails"]: {
	__typename: "InstallmentLoansDetails",
	/** Is auto pay enabled or disabled */
	autoPayStatus: GraphQLTypes["FrontendInstallmentLoansAutoPay"],
	/** Field that denotes if a user should be able to see the ability to make a and 'manual' payment on the FE */
	manualPaymentEligibilityStatus: GraphQLTypes["FrontendInstallmentLoansManualPaymentEligibilityStatus"],
	/** List of missed items */
	missedItems?: Array<GraphQLTypes["FrontendMissedItem"] | undefined> | undefined,
	/** Next scheduled principal payment if it exists */
	nextScheduledPrincipalPayment?: GraphQLTypes["FrontendPrincipalPayment"] | undefined,
	/** Post disbursal info */
	postDisbursalInstallmentLoanInfo: GraphQLTypes["InstallmentLoanPostDisbursalInfo"],
	/** Remaining due principal payments on the schedule that haven't been processed yet */
	remainingDuePayments: Array<GraphQLTypes["FrontendPrincipalPayment"]>,
	/** Fetches the string contents for the signed loan agreement */
	signedLoanAgreement: string,
	/** Status of the installment loan */
	status: GraphQLTypes["FrontendInstallmentLoansStatus"],
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalPaymentsInCents: GraphQLTypes["Long"],
	/** Total paid amount so far in cents */
	totalPrincipalAmountInCents: GraphQLTypes["Long"],
	/** Installment Loan Id */
	uuid: string
};
	/** Details related to both an existing or unopened line of credit for a user */
["LineOfCredit"]: {
	__typename: "LineOfCredit",
	/** Credit line limit details */
	creditLineLimitDetails: GraphQLTypes["LineOfCreditLineLimitDetails"],
	/** Returns the user to KYC submission response and unfulfilled reasons if it is present */
	kycRequirementResponse: GraphQLTypes["LocRequireKycResponse"],
	/** Checks if it is required for the user to create a KYC submission to progress in onboarding */
	kycRequirementStatus: GraphQLTypes["FrontendLocKycRequirementStatus"],
	/** The users currently open line of credit account. */
	openAccount?: GraphQLTypes["LineOfCreditAccount"] | undefined,
	/** Payments breakdown for user selected credit draw */
	paymentsBreakdown: Array<GraphQLTypes["LocPaymentBreakdown"] | undefined>,
	/** Fetches the user facing product name */
	productName: string,
	/** Account details for a prospective user */
	prospectiveTermDetails: GraphQLTypes["LineOfCreditProspectiveTerms"],
	/** Evaluates and fetches the underwriting status for a prospective LOC user */
	underwritingDetails: GraphQLTypes["LocUnderwritingDetails"],
	/** Fetches the string contents for the proposed unsigned loan agreement */
	unsignedProposedUserAgreement: string
};
	/** A line of credit account that a user can draw money from. */
["LineOfCreditAccount"]: {
	__typename: "LineOfCreditAccount",
	/** The current status of the account. */
	accountStatus: GraphQLTypes["FrontendLocAccountStatus"],
	/** The unique UUID that identifies the account. */
	accountUuid: string,
	activeCreditDraw?: GraphQLTypes["LineOfCreditDrawFetcher"] | undefined,
	/** The autopay status of the account. */
	autopayStatus: GraphQLTypes["FrontendLocAutopayStatus"],
	/** The credit draw details for this line of credit account */
	creditDrawDetails: GraphQLTypes["LineOfCreditDrawRequestDetailsFetcher"],
	/** The total limit that can be drawn up to in cents. */
	creditLimitInCents: GraphQLTypes["Long"],
	/** Fetches the upcoming payment details for the minimum payment. */
	minPaymentDetails: GraphQLTypes["LineOfCreditMinPaymentDetailsFetcher"],
	/** The date the account was opened. */
	openedOn: string,
	/** Total balance owed for fees */
	outstandingAccessFeeBalanceInCents: GraphQLTypes["Long"],
	/** Total outstanding principal balance in cents */
	outstandingPrincipalBalanceInCents: GraphQLTypes["Long"],
	/** The payment charge type of the account. */
	paymentChargeType: GraphQLTypes["FrontendLocUserInitiatedPaymentChargeType"],
	/** Historical payments for given LOC account */
	paymentHistory: GraphQLTypes["LineOfCreditPaymentHistory"],
	prevDraw?: GraphQLTypes["LineOfCreditDrawFetcher"] | undefined,
	/** Contains the list of historical statements and the start + end period for the CURRENT billing cycle / statement period. */
	statementDetails: GraphQLTypes["LineOfCreditStatementDetailsFetcher"],
	/** Total balance owed for principal and remainder owed for fees */
	totalBalanceRemainingIncludingFullFee: GraphQLTypes["Long"],
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalInCents: GraphQLTypes["Long"]
};
	/** Details of an LOC object for line of credit account */
["LineOfCreditDrawFetcher"]: {
	__typename: "LineOfCreditDrawFetcher",
	/** Disbursal amount */
	disbursalAmountInCents: GraphQLTypes["Long"],
	/** When credit draw was disbursed */
	disbursalDate: string,
	/** When disbursal amount is estimated to arrive to user. Returns empty optional if amount has already been delivered. */
	estimatedDeliveryDate?: string | undefined,
	/** Payment status */
	paymentStatus: GraphQLTypes["FrontendPaymentStatus"]
};
	/** Details on amount that can be or has been drawn from a line of credit */
["LineOfCreditDrawRequestDetailsFetcher"]: {
	__typename: "LineOfCreditDrawRequestDetailsFetcher",
	/** The amount that is due today */
	amountDueTodayInCents: GraphQLTypes["Long"],
	/** The estimated date that this the credit draw will settle */
	estimatedDeliveryDate: string,
	/** Max amount a user can request for a credit draw - deprecated */
	maxDrawAmountInCents: GraphQLTypes["Long"],
	/** Minimum amount a user can request for a credit draw - deprecated */
	minDrawAmountInCents: GraphQLTypes["Long"],
	/** The prospective min payment amount for the first payment post this draw. This is different from what lives in the minPaymentDetails */
	prospectiveMinPaymentAmount: GraphQLTypes["Long"],
	/** The estimated date that this the credit draw will settle */
	prospectiveMinPaymentDate: string
};
	/** Contains global configuration data for the Line of credit. */
["LineOfCreditGlobalConfiguration"]: {
	__typename: "LineOfCreditGlobalConfiguration",
	/** The USA states in which line of credit is available. */
	supportedStates: Array<GraphQLTypes["UsaState"]>
};
	/** Fetches LOC credit limit details for user */
["LineOfCreditLineLimitDetails"]: {
	__typename: "LineOfCreditLineLimitDetails",
	/** Max amount a user can request for a credit draw */
	maxDrawAmountInCents: GraphQLTypes["Long"],
	/** Minimum amount a user can request for a credit draw */
	minDrawAmountInCents: GraphQLTypes["Long"]
};
	/** Details regarding minimum payment details */
["LineOfCreditMinPaymentDetailsFetcher"]: {
	__typename: "LineOfCreditMinPaymentDetailsFetcher",
	/** Represents the fee amount that a user must make a payment on. */
	feeAmountInCents: GraphQLTypes["Long"],
	/** Represents the amount that a user must make a payment on. */
	minPaymentAmountInCents: GraphQLTypes["Long"],
	/** Represents the date that a user must make a payment on. */
	minPaymentDate: string,
	/** If the status is NOT DUE, most of the fields are generated on the fly. If it is due, it is the result of the persisted billing cycle details */
	minPaymentStatus: GraphQLTypes["FrontendLocMinPaymentStatus"],
	/** Represents the principal amount that a user must make a payment on. */
	principalAmountInCents: GraphQLTypes["Long"]
};
	/** Payment made against a user's LOC account */
["LineOfCreditPayment"]: {
	__typename: "LineOfCreditPayment",
	/** Payment amount in cents */
	amountInCents: GraphQLTypes["Long"],
	/** Type of payment attempt (manual or automatic) */
	paymentAttemptType: GraphQLTypes["FrontendLocPaymentAttemptType"],
	/** Date payment was made on */
	paymentDate: string,
	/** Payment status (SETTLED, RETURNED, etc.) */
	paymentStatus: GraphQLTypes["FrontendPaymentStatus"]
};
	/** Contains list of historical payments made against LOC account */
["LineOfCreditPaymentHistory"]: {
	__typename: "LineOfCreditPaymentHistory",
	/** List of historical payments */
	payments: Array<GraphQLTypes["LineOfCreditPayment"] | undefined>
};
	/** Detail of the LOC account that a user is thinking of opening */
["LineOfCreditProspectiveTerms"]: {
	__typename: "LineOfCreditProspectiveTerms",
	/** Membership fees for the year */
	annualFeesInCents: GraphQLTypes["Long"],
	/** The day of month the user will have to make their first payment */
	dayOfMonthForRecurringPayment: GraphQLTypes["Long"],
	/** The end date of the 'monthly statement'. All transactions after this date and before the end are included in the statement */
	firstBillingCycleEnd: string,
	/** The start date of the 'monthly statement'. All transactions after this date and before the end are included in the statement */
	firstBillingCycleStart: string,
	/** The minimum payment a user must make every billing cycle. This is moreso a ceiling. */
	minMonthlyPayment: GraphQLTypes["Long"],
	/** Membership fee per month */
	monthlyFeeInCents: GraphQLTypes["Long"],
	/** Total Credit Line Amount */
	totalCreditLineAmountInCents: GraphQLTypes["Long"],
	/** The date the user will have to make their first payment */
	upcomingMinPaymentDate: string
};
	/** A reminder associated with the user's open installment loan account. */
["LineOfCreditStatement"]: {
	__typename: "LineOfCreditStatement",
	endDate: string,
	startDate: string,
	uuid: string
};
	/** Details statement period and prev statements */
["LineOfCreditStatementDetailsFetcher"]: {
	__typename: "LineOfCreditStatementDetailsFetcher",
	currPeriodEndDate: string,
	currPeriodStartDate: string,
	/** Statement balance for the 'prev' statement balance which is the same definition of the balance of the due billing cycle */
	prevStatementBalance: GraphQLTypes["Long"],
	/** Remaining statement balance for the 'prev' statement balance which is the same definition of the remaining balance of the due billing cycle */
	remainingStatementBalance: GraphQLTypes["Long"],
	statementHistory: Array<GraphQLTypes["LineOfCreditStatement"]>
};
	/** A timeline showing the payment history for a loan. */
["LoanTimelineEvent"]: {
	__typename: "LoanTimelineEvent",
	/** User-friendly description explaining the status of the loan payment. */
	description: string,
	/** The amount of the loan payment being made in dollars. */
	dollarAmount?: GraphQLTypes["BigDecimal"] | undefined,
	/** If present, the return type explains why the loan payment was returned. */
	returnType?: GraphQLTypes["FrontendReturnType"] | undefined,
	/** The loan timeline status refers to the payment status of the loan payment such as whether it succeeded or failed. */
	status: GraphQLTypes["LoanTimelineStatus"],
	/** The date and time of the loan payment or event. */
	timestamp?: string | undefined,
	/** The loan timeline type refers to the type of loan payment being made. */
	type: GraphQLTypes["LoanTimelineType"]
};
	["Loans"]: {
	__typename: "Loans",
	/** The number of days that a loan is past the current due date by. */
	daysPastCurrentDueDate?: GraphQLTypes["Long"] | undefined,
	/** The number of days that a loan is past the original due date by. */
	daysPastDue?: GraphQLTypes["Long"] | undefined,
	/** The loan's current due date. */
	dueDate?: string | undefined,
	/** Whether express delivery was used or not */
	express: boolean,
	/** Fee for express delivery in dollars */
	expressTransferFeeDollars: GraphQLTypes["BigDecimal"],
	/** True only if the loan is outstanding with no scheduled payment */
	extendable: boolean,
	/** Unique ID for loan */
	loanId: string,
	/** The Reason a loan is not recoverable */
	notRecoverableReason?: GraphQLTypes["NonRepaymentReason"] | undefined,
	/** The When the Loan was first created */
	originationDate?: string | undefined,
	/** Advance amount for loan in dollars */
	originationDollarAmount: GraphQLTypes["BigDecimal"],
	/** Unpaid loan amount in dollars */
	outstandingDollarAmount: GraphQLTypes["BigDecimal"],
	/** Whether the loan's status is Pre-Charge-Off or not */
	prechargeOff: boolean,
	/** Loan amount that's been repaid in dollars */
	repaidDollarAmount: GraphQLTypes["BigDecimal"],
	/** Whether the loan is repayable or not which means the loan is delivered, outstanding, and has no scheduled payment */
	repayable: boolean,
	/** Timeline of all the payments made for the loan. */
	timeline: Array<GraphQLTypes["LoanTimelineEvent"]>
};
	/** Represents Loc list of payment breakdown for a credit draw */
["LocPaymentBreakdown"]: {
	__typename: "LocPaymentBreakdown",
	/** Loc access fee payment amount in cents */
	accessFeeAmountInCents: GraphQLTypes["Long"],
	/** Loc payments in month   */
	lengthInMonth: GraphQLTypes["Long"],
	/** Loc minimum payment amount in cents */
	minimumPaymentAmountInCents: GraphQLTypes["Long"],
	/** Loc principal payment amount in cents */
	principalPaymentAmountInCents: GraphQLTypes["Long"]
};
	/** Represents Loc kyc response and the list of unfulfilled reasons */
["LocRequireKycResponse"]: {
	__typename: "LocRequireKycResponse",
	/** Loc requirement status */
	requirementStatus: GraphQLTypes["FrontendLocKycRequirementStatus"],
	/** Loc unfulfillment reasons */
	unfulfillmentReasons: Array<GraphQLTypes["FrontendLocKycUnfulfillmentReasons"] | undefined>
};
	/** Represents the underwriting results for a line of credit application. If it is unapproved, the list of unapproved strings will contain data */
["LocUnderwritingDetails"]: {
	__typename: "LocUnderwritingDetails",
	/** Unapproval reasons */
	unapprovalReasons: Array<string | undefined>,
	/** Underwriting Status */
	underwritingStatus: GraphQLTypes["FrontendLocUnderwritingStatus"]
};
	["LoginFailure"]: {
	__typename: "LoginFailure",
	/** A reason for the failure to log in, should be used by the mobile code to decide what to do. */
	loginFailureReason: GraphQLTypes["LoginFailureReason"],
	/** An error message to show the user when logging in fails. */
	userFacingMessage: string
};
	["LoginSuccess"]: {
	__typename: "LoginSuccess",
	/** The auth token for the user, should be put in the store so it can be included is subsequent requests. */
	token: string,
	/** The logged in users id. */
	userId: string
};
	["MarkNotificationCenterEntriesViewed"]: {
	__typename: "MarkNotificationCenterEntriesViewed",
	/** Enum denoting if the outcome of marking the list of notification centers as viewed was a success or failure */
	outcome: GraphQLTypes["MarkNotificationCenterEntriesViewedOutcome"]
};
	["MarkUserViewedClgModal"]: {
	__typename: "MarkUserViewedClgModal",
	/** The user's remaining flags after their CLG Modal flag has been removed. */
	userFlags: Array<GraphQLTypes["UserFlag"] | undefined>
};
	["MarketplaceBadge"]: {
	__typename: "MarketplaceBadge",
	/** The badges display name. */
	displayName: string,
	/** The badges id. */
	id: GraphQLTypes["Long"],
	/** The badges type. */
	type: GraphQLTypes["MarketplaceBadgeType"]
};
	["MarketplaceCategory"]: {
	__typename: "MarketplaceCategory",
	/** Marketplace category description */
	description: string,
	/** Marketplace category display name */
	displayName: string,
	/** Whether any of the offers in this category have a badge or not */
	hasBadge: boolean,
	/** Marketplace category type */
	type: GraphQLTypes["TablessMarketplaceCategoryType"]
};
	["MarketplaceMaintenance"]: {
	__typename: "MarketplaceMaintenance",
	/** Whether the feature is in active maintenance */
	activeMaintenance: boolean,
	/** The feature associated with the potentially active maintenance flag */
	feature: GraphQLTypes["MarketplaceFeature"]
};
	["MarketplaceOfferByCategory"]: {
	__typename: "MarketplaceOfferByCategory",
	category: GraphQLTypes["TablessMarketplaceCategoryType"],
	categoryIconUrl: string,
	offers: Array<GraphQLTypes["TablessMarketplaceOffer"]>
};
	["MarketplaceOffersV2"]: {
	__typename: "MarketplaceOffersV2",
	/** The number of badges on the offers. */
	badgeCount: number,
	/** A list of marketplace categories. */
	categories: Array<GraphQLTypes["MarketplaceCategory"] | undefined>,
	/** A list of offers sorted either by EPC, RPM or priority, depending on what experiments the user is sampled for. */
	fullOffers: Array<GraphQLTypes["TablessMarketplaceOffer"] | undefined>
};
	["MoneyLionEngineOffers"]: {
	__typename: "MoneyLionEngineOffers",
	/** LeadUUID */
	leadUUID: string,
	/** Loan Offers grouped by Partner */
	loanOffers: Array<GraphQLTypes["PartnerLoanOffers"] | undefined>,
	/** Special Offers grouped by Partner */
	specialOffers: Array<GraphQLTypes["PartnerSpecialOffers"] | undefined>,
	/** UUID */
	uUID: string
};
	["MoneyLionLoanOffers"]: {
	__typename: "MoneyLionLoanOffers",
	/** APR */
	apr?: number | undefined,
	/** Company UUID */
	companyUuid?: string | undefined,
	/** Disclosure */
	disclosure?: string | undefined,
	/** Estimated Payment */
	estPayment?: number | undefined,
	/** Expiration */
	expiration?: string | undefined,
	/** Financial Institution UUID */
	financialInstitutionUuid?: string | undefined,
	/** Loan Amount */
	loanAmount?: number | undefined,
	/** Originator ID */
	originatorId?: string | undefined,
	/** Partner Image URL */
	partnerImageUrl?: string | undefined,
	/** Partner Name */
	partnerName: string,
	/** Term */
	term?: string | undefined,
	/** URL */
	url?: string | undefined,
	/** UUID */
	uuid: string
};
	["MoneyLionSpecialOffers"]: {
	__typename: "MoneyLionSpecialOffers",
	/** Description of the specialOffer */
	desc: string,
	/** Name of the specialOffer */
	name: string,
	/** URL of the partner image */
	partnerImageUrl: string,
	/** Name of the partner */
	partnerName: string,
	/** Payout amount */
	payout?: number | undefined,
	/** Product subtype */
	productSubType: GraphQLTypes["ProductSubType"],
	/** Recommendation score */
	recommendationScore?: number | undefined,
	/** URL of the specialOffer */
	url: string,
	/** Unique identifier */
	uuid: string
};
	["Mutation"]: {
	__typename: "Mutation",
	/** Connects an existing payroll account for the user using the user ID and account ID supplied by the provider. Updates connection status and ongoing refresh status if needed. */
	accountConnected: GraphQLTypes["AccountConnectedResponseFetcher"],
	/** Attempts to move the user to the requested subscription tier. The `ChangeSubscriptionTierOutcome` lists some of the correctable reasons this could fail. There are other unactionable reasons this could fail, which will result in errors being thrown. */
	changeSubscriptionTier: GraphQLTypes["ChangeSubscriptionTierResponse"],
	/** Submits the quiz to mark it as completed */
	completeQuiz?: GraphQLTypes["CompleteQuizFetcher"] | undefined,
	/** A new endpoint to create credit builder account regardless income strategy which takes in the contribution amount, charge policy and optional due date. */
	createCreditBuilderUserAccount?: GraphQLTypes["FrontendCreditBuilderEnrollmentStatus"] | undefined,
	/** Onboards a user to a credit monitoring product */
	createCreditMonitoringUser: GraphQLTypes["CreditMonitoringUser"],
	/** Creates an installment loans account for the user id */
	createInstallmentLoansAccount: GraphQLTypes["FrontendInstallmentLoansAccountCreationStatus"],
	/** Creates a new payroll account for the user using the account ID, user ID, and item ID supplied by the provider */
	createPayrollAccount: GraphQLTypes["CreatePayrollAccountResponse"],
	/** Creates a new payroll account and returns a success outcome or a failure if the account wasn't created. */
	createPayrollAccountV2: GraphQLTypes["CreatePayrollAccountResponseV2"],
	/** Creates a deposit destination for a payroll user's direct deposits */
	createTargetDepositDestination: GraphQLTypes["CreateTargetDepositDestinationResponse"],
	/** Delete an existing bank login */
	deleteBankLogin: GraphQLTypes["DeleteBankLogin"],
	/** Deletes a user's payroll account */
	deletePayrollAccount: GraphQLTypes["DeletePayrollAccountResponse"],
	/** Disables a deposit destination for a user's direct deposits */
	disableDirectDeposits: GraphQLTypes["DisableDirectDepositsResponse"],
	/** Disables connection for an existing payroll account for the user using the user ID and account ID supplied by the provider, and updates connection status */
	disablePayrollAccount: GraphQLTypes["DisablePayrollAccountResponse"],
	/** Makes a catch up payment for an installment loan */
	initiateCatchUpPaymentForInstallmentLoan: GraphQLTypes["InitiateCatchUpPaymentForInstallmentLoan"],
	/** Initiates the Credit Draw for a LOC account */
	initiateCreditDraw: GraphQLTypes["FrontendInitiateCreditDrawStatus"],
	/** Initiates a debit payment for the loan using the scheduled repayment date and the payment method id supplied. */
	initiateOneTimePayment: GraphQLTypes["InitiateOneTimePayment"],
	/** Makes a pay off payment for an installment loan */
	initiatePayOffPaymentForInstallmentLoan: GraphQLTypes["InitiatePayOffPaymentForInstallmentLoan"],
	/** Makes a pre payment for an installment loan */
	initiatePrePaymentForInstallmentLoan: GraphQLTypes["InitiatePrePaymentForInstallmentLoan"],
	/** Initiates a user initiated payment for a LOC account */
	initiateUserPaymentForLoc: GraphQLTypes["FrontendLocInitiatePaymentStatus"],
	/** Stores the user's salary data */
	inputSalaryData: GraphQLTypes["InputSalaryData"],
	/** Either initiates the phone auth process or returns a reason why the user can not be logged in. */
	login: GraphQLTypes["LoginUnion"],
	/** Mark notification center entries viewed */
	markNotificationCenterEntriesViewed: GraphQLTypes["MarkNotificationCenterEntriesViewed"],
	/** Marks that the user has viewed the CLG modal screen by removing their user flag. */
	markUserViewedClgModal: GraphQLTypes["MarkUserViewedClgModal"],
	/** Creates an LOC account for the user id */
	openLocAccount: GraphQLTypes["FrontendLocAccountCreationStatus"],
	/** Sets the CB Payment Strategy for a user to the four day retry strategy */
	publishCreditBuilderDraftPaymentSchedule: GraphQLTypes["FrontendCBScheduleVersionStatus"],
	/** Used for recording the user response of a prompt */
	recordUserPromptResponse: GraphQLTypes["RecordUserPromptResponse"],
	/** Attempts to register a new bank login for a user, maintaining the existing login for a user if it exists. */
	registerBankLoginMaintainingExistingLogin: GraphQLTypes["RegisterBankLoginMaintainingExistingLogin"],
	/** Registers a debit card for one-time payments and stores the new payment method */
	registerOneTimeCard: GraphQLTypes["RegisterOneTimeCardResponse"],
	/** Registers a debit card for one-time payments and stores the new payment method. Returns either the newly created payment method info or a user facing message if the user passed in invalid input. */
	registerOneTimeCardV2: GraphQLTypes["RegisterOneTimeCardUnion"],
	/** Repays an advances for user. */
	repayAdvance: GraphQLTypes["RepayAdvance"],
	/** Manually disburses an advance for user. */
	requestManualDisbursal: GraphQLTypes["RequestManualDisbursal"],
	/** Resends an auth verification code to users phone or email */
	resendAuthVerificationCode: GraphQLTypes["ResendAuthVerificationCode"],
	/** Schedules same day manual payment for installment loan account for Credit Builder */
	scheduleCreditBuilderSameDayManualPayment: GraphQLTypes["ScheduleCreditBuilderSameDayManualPayment"],
	/** Starts or resumes a user engagement quiz */
	startOrResumeQuiz: GraphQLTypes["StartOrResumeQuizResponse"],
	/** Sets the CB Payment charge policy Auto or Manual */
	submitCreditBuilderPaymentChargePolicy: GraphQLTypes["FrontendInstallmentLoanChargePolicy"],
	/** Sets the CB Payment Strategy for a user to the four day retry strategy */
	submitCreditBuilderPaymentStrategy: GraphQLTypes["FrontendCreditBuilderPaymentRetryStrategyType"],
	/** Submits Marketplace Loans quiz in order to request MoneyLionOffers for a user */
	submitMoneyLionPersonalLoansQuiz: GraphQLTypes["SubmitMoneyLionOffers"],
	/** Allows submitting feedback for a quiz */
	submitQuizFeedback: GraphQLTypes["SubmitQuizFeedbackFetcher"],
	/** Submits a response to a quiz question */
	submitQuizResponse: GraphQLTypes["SubmitQuizResponse"],
	/** Submits a response for a prompt given a type */
	submitResponseForPromptType: GraphQLTypes["SubmitUserPromptResponse"],
	/** Attempts to switch the user's protected login to a previously linked login */
	switchProtectedLoginToExistingLogin: GraphQLTypes["SwitchProtectedLoginToExistingLogin"],
	/** Attempts to switch the user's protected login to a previously linked login */
	switchProtectedLoginToExistingNonConnectedLogin: GraphQLTypes["SwitchProtectedLoginToExistingNonConnectedLogin"],
	/** Disables connection for an existing payroll account for the user using the user ID and account ID supplied by the provider, and updates connection status. Provider is specified by the caller. */
	testDisablePayrollAccount: GraphQLTypes["DisablePayrollAccountResponse"],
	/** Used for testing errors thrown from a mutation. */
	throwTestException?: string | undefined,
	/** Tracks an impression event */
	trackImpressionEvent: GraphQLTypes["TrackImpressionsFetcher"],
	/** Track notification center entry clicked */
	trackNotificationCenterEntryClick: GraphQLTypes["TrackNotificationCenterEntryClick"],
	/** Update the auto pay status (enabled or disabled) for an installment loan */
	updateAutoPayStatusForInstallmentLoan: GraphQLTypes["UpdateInstallmentLoansAutoPayStatusFetcher"],
	/** Updates autopay status */
	updateAutopayStatusForLoc: GraphQLTypes["FrontendLocAutopayStatus"],
	/** Update whether or not an existing bank account is joint */
	updateBankAccountJointStatus: GraphQLTypes["UpdateBankAccountJointStatus"],
	/** Updates the status of a credit limit boost */
	updateCreditLimitBoostStatus: GraphQLTypes["UpdateCreditLimitBoostStatus"],
	/** Updates payment charge type */
	updatePaymentChargeTypeForLoc: GraphQLTypes["FrontendLocUserInitiatedPaymentChargeType"]
};
	["NotificationCenter"]: {
	__typename: "NotificationCenter",
	/** Return notifications for user to display in notification center */
	notifications: Array<GraphQLTypes["NotificationCenterEntry"]>
};
	["NotificationCenterEntry"]: {
	__typename: "NotificationCenterEntry",
	/** Time notification was added to notification center for user */
	createdAt: string,
	/** Description of notification */
	description: string,
	/** Where we will navigate the user when they click on the notification */
	destination: string,
	/** Whether or not user has viewed notification */
	hasViewed: boolean,
	/** Id of notification */
	id: GraphQLTypes["Long"],
	/** Image URL to display with the notification on the frontend */
	imageUrl?: string | undefined,
	/** Title of notification */
	title: string
};
	/** Information about pagination in a connection. */
["PageInfo"]: {
	__typename: "PageInfo",
	/** When paginating forwards, the cursor to continue. */
	endCursor?: string | undefined,
	/** When paginating forwards, are there more items? */
	hasNextPage: boolean,
	/** When paginating backwards, are there more items? */
	hasPreviousPage: boolean,
	/** When paginating backwards, the cursor to continue. */
	startCursor?: string | undefined
};
	["PartnerLoanOffers"]: {
	__typename: "PartnerLoanOffers",
	/** Offers */
	offers: Array<GraphQLTypes["MoneyLionLoanOffers"] | undefined>,
	/** Partner Name */
	partnerName: string
};
	["PartnerSpecialOffers"]: {
	__typename: "PartnerSpecialOffers",
	/** Offers */
	offers: Array<GraphQLTypes["MoneyLionSpecialOffers"] | undefined>,
	/** Partner Name */
	partnerName: string
};
	/** Details about past due credit builder payments. */
["PastDuePayment"]: {
	__typename: "PastDuePayment",
	/** The total amount past due in dollars. */
	amountDollars: GraphQLTypes["BigDecimal"]
};
	/** Details about the user's payroll connection */
["PayrollConnection"]: {
	__typename: "PayrollConnection",
	/** User ID generated by a payroll vendor for client-side authorization */
	payrollUserId: string,
	/** User token generated by a payroll vendor for client-side authorization */
	userToken: string
};
	["Payroll_32_Account_32_Fetcher_32_With_32_Outcome"]: {
	__typename: "Payroll_32_Account_32_Fetcher_32_With_32_Outcome",
	/** Whether the request was successful */
	outcome?: GraphQLTypes["PayrollAccountFetcherOutcome"] | undefined,
	/** List of all payroll accounts for the user */
	payrollAccounts: Array<GraphQLTypes["Payroll_32_Accounts_32_V2"] | undefined>
};
	["Payroll_32_Accounts"]: {
	__typename: "Payroll_32_Accounts",
	/** Connection status of payroll account */
	connectionStatus: GraphQLTypes["PayrollAccountConnectionStatus"],
	/** Name of employer associated with payroll account */
	employerName?: string | undefined,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	manualDisconnect: boolean,
	/** Status indicating whether payroll account will be updated when data refreshes */
	ongoingRefreshStatus?: GraphQLTypes["OngoingRefreshStatus"] | undefined,
	/** Unique ID for payroll account */
	payrollAccountId: string
};
	["Payroll_32_Accounts_32_V2"]: {
	__typename: "Payroll_32_Accounts_32_V2",
	/** Connection status of payroll account */
	connectionStatus: GraphQLTypes["FrontendPayrollAccountConnectionStatus"],
	/** User facing message explaining why the user isn't eligible for DDS */
	dDSEligibilityMessage?: string | undefined,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	eligibleForDDS: boolean,
	/** Name of employer associated with payroll account */
	employerName?: string | undefined,
	/** Whether the connection was manually disconnected (e.g., by a user or admin */
	manualDisconnect: boolean,
	/** Status indicating whether payroll account will be updated when data refreshes */
	ongoingRefreshStatus?: GraphQLTypes["FrontendOngoingRefreshStatus"] | undefined,
	/** Unique ID for payroll account */
	payrollAccountId: string
};
	/** A user facing summary of insights for a user's bank transactions of loan is a secured loan which the user has taken out to build such as upcoming fees, cashflow, spend, etc. */
["PfmSummary"]: {
	__typename: "PfmSummary",
	/** Returns the sum of the the total debit amounts for plaid transactions with fee categories */
	totalFeesPaid: GraphQLTypes["BigDecimal"]
};
	/** Payment details about possible pre-payments user can make. */
["PrePayment"]: {
	__typename: "PrePayment",
	/** The payment amount in dollars. */
	amountDollars: GraphQLTypes["BigDecimal"],
	/** When the pre-payment is due. */
	dueDate: string,
	/** An optional date that is returned if the the prepayment is not yet eligible for prepayment.   */
	payableAfter?: string | undefined
};
	["PromoCardOffer"]: {
	__typename: "PromoCardOffer",
	/** The url for the offers affiliate link. */
	affiliateLinkUrl: string,
	/** The offers description */
	description: string,
	/** The offers details */
	details?: string | undefined,
	/** The Offers Id */
	id: GraphQLTypes["Long"],
	/** The url for the offers logos image file. */
	logoUrl: string,
	/** The offers name. */
	offerName: string
};
	/** A promotion is an offer to provide a discount to a user for subscribing to Brigit. */
["Promotion"]: {
	__typename: "Promotion",
	/** The amount that the user would pay while the discount is active, in US dollars. */
	discountedAmount: GraphQLTypes["BigDecimal"],
	/** How many months the discount would apply to the user's subscription. */
	numMonths?: number | undefined,
	/** The amount that the user would pay while the discount is active, in US dollars. */
	subscriptionAmountAfterDiscount: GraphQLTypes["BigDecimal"],
	/** The subscription tier level that the promotion applies to. Users would need to subscribe to this particular tier for the promotion to be applied as a discount. */
	subscriptionTierLevel: GraphQLTypes["SubscriptionTierLevel"]
};
	["ProtectionDetails"]: {
	__typename: "ProtectionDetails",
	/** List of user's loans and loan details */
	loans: Array<GraphQLTypes["Loans"] | undefined>,
	/** Status of the user's current advance */
	protectionStatus?: GraphQLTypes["ProtectionStatusV4"] | undefined,
	/** User-friendly description of the status of the user's current advance */
	protectionStatusDescription?: string | undefined
};
	["Query"]: {
	__typename: "Query",
	/** The available subscription tiers are those tiers which can be accessed by the user making the request. They're what we would advertise as the options available to the user. */
	availableSubscriptionTiers: Array<GraphQLTypes["SubscriptionTier"]>,
	/** Global configurations related to the Credit Builder system. */
	creditBuilderGlobalConfiguration: GraphQLTypes["CreditBuilderGlobalConfiguration"],
	/** Get Promo cards under new dynamic marketplace model */
	dynamicPromoCards: Array<GraphQLTypes["DynamicPromoCards"]>,
	/** Fetches an existing assignment given a sample key and experiment name. If no active assignment
exists, eligibility checks pass (such as minimum client version/conflicting experiment), and shouldPerformAssignment
is true, a new assignment will be made at the time of the call.

This method is designed to fail gracefully, returning an Optional.empty() and logging errors if there is
an unexpected issue while executing the workflow such as:
- The experiment is not found.
- The sample weights across variants under test do not sum to 100%.
- There is more than one active variant found for an experiment with a winner selected.

This allows callers to handle the Optional::isEmpty case appropriately. If no assignment
exists and the experiment is in draft, an empty response will be returned. If an assignment
does exist, it will be returned even if the experiment is in draft.

If shouldPerformAssignment is false, no new assignment will be made. However, if an existing
assignment is found, it will be returned. If no assignment has been made but a winning variant
has been selected, the winning variant will be returned. */
	experimentVariantAssignment?: GraphQLTypes["FrontendExperimentVariantAssignment"] | undefined,
	/** Return Frequently Asked Questions as part of an experiment to add clarity to the subscription process when a user is activating via the IC flow. See: https://brigit.atlassian.net/browse/ENG-8843. */
	iCActivationFAQs: Array<GraphQLTypes["ICActivationFAQs"]>,
	/** Global configurations related to the Line of credit system. */
	lineOfCreditGlobalConfiguration: GraphQLTypes["LineOfCreditGlobalConfiguration"],
	/** Get the maintenance status for a marketplace feature */
	marketplaceMaintenance: GraphQLTypes["MarketplaceMaintenance"],
	/** The account of the user making the request */
	me: GraphQLTypes["UserAccount"],
	/** Returns information to the frontend about whether or not the user's current mobile version is supported and, if it's not, whether we should nudge or force users to upgrade.Returns UNKNOWN if the user is on web or if we can't parse the mobile version we receive. */
	mobileVersionSupportType?: GraphQLTypes["FrontendMobileVersionSupportType"] | undefined
};
	["QuizFetcher"]: {
	__typename: "QuizFetcher",
	/** Whether the quiz is completed */
	completed: boolean,
	/** The zoned datetime of when the quiz was completed. Returns an optional because quiz may be incomplete */
	completedAt?: string | undefined,
	/** The zoned datetime of when the quiz was started */
	createdAt: string,
	/** Optional zoned datetime of when the quiz was deleted */
	deletedAt?: string | undefined,
	/** The id of the quiz */
	id: GraphQLTypes["Long"],
	/** The name of the quiz */
	quizName: GraphQLTypes["QuizName"],
	/** The taker of the quiz. It may be a user id or a device id if the user is not yet authenticated. */
	quizTaker: string,
	/** The version id of the quiz */
	quizVersionId: string
};
	["QuizStateFetcher"]: {
	__typename: "QuizStateFetcher",
	/** Whether a user can exit the quiz at any point */
	canExitEarly: boolean,
	/** The current step of the quiz that the user is on. If the quiz has no more steps to complete, an empty optional is returned. */
	currentStepId?: string | undefined,
	/** Whether the quiz can be resume part way through */
	isResumable: boolean,
	/** An array of QuizSteps */
	steps: Array<GraphQLTypes["Step"]>,
	/** The total number of steps in the current state. If branching logic changes length, returns empty optional */
	totalSteps?: GraphQLTypes["Long"] | undefined
};
	["RecentScoreChangeFetcher"]: {
	__typename: "RecentScoreChangeFetcher",
	/** An enum representing the CTA link that should be rendered after the text. */
	callToAction?: GraphQLTypes["ScoreChangeCallToAction"] | undefined,
	/** An optional integer. If present will be used to render the score change indicator on the right side of the card and should contain a positive, negative, or 0 change to show/ If they only have one score and thus can not compute a delta this should be empty. */
	scoreChange?: number | undefined,
	/** The date of the last score pulled. Will be used to render the footer of the component.  */
	scoreUpdateDate: string,
	/** The text to display in the component. */
	text: GraphQLTypes["RecentScoreChangeText"]
};
	["RecordUserPromptResponse"]: {
	__typename: "RecordUserPromptResponse",
	/** The prompt type of a user engagement event */
	campaignPromptType: GraphQLTypes["CampaignPromptType"],
	/** The response type of a user engagement event */
	promptResponseType: GraphQLTypes["UXPromptResponseType"]
};
	/** A Referral object maintains a users referral status for a specific referral. */
["Referral"]: {
	__typename: "Referral",
	/** Represents whether the user has been paid for the referral. */
	paid: boolean,
	/** Represents the value for which the user was paid for the referral. */
	payoutValue: GraphQLTypes["BigDecimal"]
};
	["RegisterBankLoginMaintainingExistingLogin"]: {
	__typename: "RegisterBankLoginMaintainingExistingLogin",
	/** An Enum denoting if the outcome of registering the bank account was successful or failure */
	outcome: GraphQLTypes["RegisterBankLoginMaintainingExistingLoginOutcome"]
};
	["RegisterOneTimeCardInvalidInput"]: {
	__typename: "RegisterOneTimeCardInvalidInput",
	/** User facing message explaining why the user input was invalid. */
	userFacingMessage: string
};
	["RegisterOneTimeCardResponse"]: {
	__typename: "RegisterOneTimeCardResponse",
	/** The display name of the card payment method i.e. VISA ending in 1234 */
	cardNickname: string,
	/** The payment method ID of the one time card payment method created */
	paymentMethodId: string
};
	["RegisterOneTimeCardSuccess"]: {
	__typename: "RegisterOneTimeCardSuccess",
	/** User friendly nickname to refer to card. */
	cardNickname: string,
	/** Newly created payment method ID. */
	paymentMethodId: string
};
	["RepayAdvance"]: {
	__typename: "RepayAdvance",
	/** The advance that we attempted to repay */
	advanceId: GraphQLTypes["Long"],
	/** An Enum denoting if the outcome of the request was successful or failure */
	repayAdvanceOutcome: GraphQLTypes["RepayAdvanceOutcome"],
	/** A String denoting why this request succeeded or failed */
	repayAdvanceOutcomeReason: string
};
	["RequestManualDisbursal"]: {
	__typename: "RequestManualDisbursal",
	/** The loan that was disbursed to the user, on success */
	loanId: GraphQLTypes["Long"],
	/** An Enum denoting if the outcome of the requestManualDisbursal was successful or failure */
	requestManualDisbursalOutcome: GraphQLTypes["RequestManualDisbursalOutcome"],
	/** A String denoting why this request succeeded or failed */
	requestManualDisbursalReason: string
};
	["ResendAuthVerificationCode"]: {
	__typename: "ResendAuthVerificationCode",
	/** An Enum denoting if the outcome of resending the verification code was successful or failure */
	outcome: GraphQLTypes["ResendAuthVerificationCodeOutcome"]
};
	["SalaryAggregators"]: {
	__typename: "SalaryAggregators",
	/** Primary filter used to aggregate salary data */
	filterOne: string,
	/** List of aggregated salary data based on a second filter */
	filterTwoData: Array<GraphQLTypes["FilteredSalaryData"] | undefined>,
	/** The average salary for the primary filter */
	salary: number
};
	/** Installment payment made manually on the due date from the user's external bank account for credit builder. */
["SameDayManualPayment"]: {
	__typename: "SameDayManualPayment",
	/** Amount scheduled to be paid for the manual payment */
	amountInCents: GraphQLTypes["Long"],
	/** Date and time of scheduled manual payment in yyyy-MM-dd format */
	paidOn?: string | undefined,
	/** Status of installment loan payment after manual payment is scheduled */
	status: GraphQLTypes["FrontendCreditBuilderTransactionStatus"]
};
	/** Payment details for manual payment that is due today. */
["SameDayPayment"]: {
	__typename: "SameDayPayment",
	/** The payment amount in dollars. */
	amountDollars: GraphQLTypes["BigDecimal"]
};
	["ScheduleCreditBuilderSameDayManualPayment"]: {
	__typename: "ScheduleCreditBuilderSameDayManualPayment",
	/** The result of scheduling same day manual payment */
	outcome: GraphQLTypes["ScheduleCreditBuilderSameDayManualPaymentOutcome"],
	/** The response object scheduling same day manual payment. Only returns something if the outcome is successful */
	sameDayManualPayment: GraphQLTypes["SameDayManualPayment"]
};
	["ScoreBucketFetcher"]: {
	__typename: "ScoreBucketFetcher",
	/** The lower range of the score bucket, e.g. 349. */
	maximumScore: number,
	/** The lower range of the score bucket, e.g. 300. */
	minimumScore: number
};
	["ScoreCard"]: {
	__typename: "ScoreCard",
	/** The maximum score to be shown on the user's score card */
	maxScore: number,
	/** The minimum approval score needed for the user to be approved based on their underwriting strategy. */
	minApprovalScore: number,
	/** The minimum score to be shown on the user's score card */
	minScore: number,
	/** The user's underwriting score. If their score meets the minimum approval score, then the user will be approved for Instant Cash. */
	score: number,
	/** List of categories that influence the user's underwriting score. */
	scoreCategories: Array<GraphQLTypes["ScoreCategory"] | undefined>
};
	["ScoreCategory"]: {
	__typename: "ScoreCategory",
	/** User-friendly text describing improvements the user can make to improve their performance in this score category. */
	description: string,
	/** The name of the larger categories that affect the user's approval status such as bank account, spending, and earning. */
	name: string,
	/** The status of this category is based on the lowest rank of the statuses in its subcategories. */
	status: GraphQLTypes["ModelRank"],
	/** List of more specific subcategories within this score category. */
	subcategories: Array<GraphQLTypes["ScoreSubcategory"] | undefined>
};
	["ScoreSubcategory"]: {
	__typename: "ScoreSubcategory",
	/** User-friendly text explaining what data is used in this subcategory */
	description: string,
	/** Name of the score subcategory */
	name: string,
	/** A rank of how important this score subcategory contributes to the user's underwriting score */
	status: GraphQLTypes["ModelRank"]
};
	["SelectFieldFetcher"]: {
	__typename: "SelectFieldFetcher",
	/** An optional image to display to the left of the text.  Only populated if the type is GRID */
	icon?: string | undefined,
	/** The text to display for the option */
	optionDisplayValue: string,
	/** Whether the option should be selected by default */
	selected: boolean
};
	["StartOrResumeQuizResponse"]: {
	__typename: "StartOrResumeQuizResponse",
	/** The quiz definition */
	quiz: GraphQLTypes["QuizFetcher"],
	/** The state of the quiz */
	state?: GraphQLTypes["QuizStateFetcher"] | undefined
};
	["Step"]: {
	__typename: "Step",
	/** Optional copy to provide additional context or instruction for the question */
	assistiveCopy?: string | undefined,
	/** Whether a ‘Skip’ button should be rendered allowing user to bypass the step. */
	canSkip: boolean,
	/** Whether assistive copy should be centered */
	centerAssistiveCopy?: boolean | undefined,
	/** Whether the main question text should be centered */
	centerQuestionText: boolean,
	/** Whether select options should be centered */
	centered?: boolean | undefined,
	/** Optional disclosure primary text */
	disclosureCopy?: string | undefined,
	/** Whether a step has information that needs to be sent via VGS or other security proxy */
	hasSensitiveResponse: boolean,
	/** Optional icon image name */
	heroIcon?: string | undefined,
	/** Required if a hero icon exists */
	heroIconSize?: GraphQLTypes["HeroIconSize"] | undefined,
	/** A unique string identifier for the step. Will be used when submitting responses to the step (if the step has responses). For example, the first step of a quiz may have an ID of '1'. */
	id: string,
	/** Whether the next step is known or not (e.g. dependant on the response to this step */
	isBranching: boolean,
	/** Whether its the last step of the quiz */
	isLastStep: boolean,
	/** Whether the user should be able to select/submit multiple options. Only populated for MULTI_SELECT or GRID */
	multipleSelect?: boolean | undefined,
	/** The ID of the next quiz step, if present */
	nextStepId?: string | undefined,
	/** The select field options. Only populated if the type is MULTI-SELECT or GRID */
	options?: Array<GraphQLTypes["SelectFieldFetcher"]> | undefined,
	/** Only populated for type YES_NO where the field has a prior submisson */
	previousSelectedYesNoOption?: string | undefined,
	/** The ID of the prior quiz step, if present */
	priorStepId?: string | undefined,
	/** The main text of the step. You can imagine this as the question text, e.g. 'Do you like pickles?' */
	questionText: string,
	/** Optional copy for the submit button */
	submitText?: string | undefined,
	/** Optional supplemental text */
	supplementalCopy?: string | undefined,
	/** Optional icon to display with the supplemental text */
	supplementalCopyIcon?: string | undefined,
	/** Optional disclosure secondary text */
	supplementalDisclosureCopy?: string | undefined,
	/** Only populated if the type is FREE_TEXT */
	textFields?: Array<GraphQLTypes["TextFieldFetcher"]> | undefined,
	/** The type of the quiz step, e.g. 'INFORMATION', 'TEXT_INPUT', 'MULTI_SELECT' etc */
	type: GraphQLTypes["QuizStepType"]
};
	["SubmitMoneyLionOffers"]: {
	__typename: "SubmitMoneyLionOffers",
	/** LeadUUID */
	leadUUID: string,
	/** Loan Offers grouped by Partner */
	loanOffers: Array<GraphQLTypes["PartnerLoanOffers"] | undefined>,
	/** Special Offers grouped by Partner */
	specialOffers: Array<GraphQLTypes["PartnerSpecialOffers"] | undefined>,
	/** UUID */
	uUID: string
};
	["SubmitQuizFeedbackFetcher"]: {
	__typename: "SubmitQuizFeedbackFetcher",
	/** The id of the quiz */
	id?: string | undefined
};
	["SubmitQuizResponse"]: {
	__typename: "SubmitQuizResponse",
	/** The state of the quiz */
	state?: GraphQLTypes["QuizStateFetcher"] | undefined
};
	["SubmitUserPromptResponse"]: {
	__typename: "SubmitUserPromptResponse",
	/** The outcome of submitting a user prompt response */
	outcome: GraphQLTypes["SubmitUserPromptOutcome"]
};
	/** A subscription is an agreement between Brigit and a user, for them to pay us periodically, and for us to provide them access to some of our services in exchange for that payment. Subscriptions are associated with a particular tier, which dictates what price the user pays and what features they are provided. */
["Subscription"]: {
	__typename: "Subscription",
	/** The tier that the subscription is currently linked to. */
	currentTier: GraphQLTypes["SubscriptionTier"],
	/** The time the current subscription tier became effective, or empty if user is NPE */
	currentTierValidFromTimestamp?: string | undefined,
	/** If present, the subscription currently has a discount applied to it. */
	discount?: GraphQLTypes["Discount"] | undefined,
	/** If user has a connected account, is approved, was previously enabled but currently disabled, has a loan, and does not have an outstanding loan, they are eligible for the reactivation experience. */
	isEligibleForReactivationExperience?: boolean | undefined,
	/** An explanation of how Instant Cash Express Transfer fees would work for a user on this tier. If the tier does not provide access to Instant Cash, instant transfer fees are not applicable and this field will be empty. NOTE: for NPE users this will be the subscription date if the user activates today with their same bank account, this could change if they change their bank account or income source. */
	proposedBillDate?: string | undefined,
	/** If present, the subscription fee schedule the user has been assigned to determine when in the month we'll schedule their monthly subscription payment. */
	subscriptionFeeSchedule?: GraphQLTypes["SubscriptionFeeSchedule"] | undefined,
	/** Subscription payments that have been scheduled for a user, excluding those that were cancelled because the user deactivated during the grace period or upgraded to the Premium plan. */
	subscriptionPayments?: Array<GraphQLTypes["SubscriptionPayment"]> | undefined,
	/** The variation of the provided experiment to use for this subscription. */
	subscriptionTierExperiment: GraphQLTypes["SubscriptionTierExperiment"],
	/** A subscription tier strategy indicates the subscription experience users are subject to,
    the details of which will be reflected in their `availableSubscriptionTiers`. */
	subscriptionTierStrategyName?: string | undefined
};
	["SubscriptionPayment"]: {
	__typename: "SubscriptionPayment",
	/** Full subscription amount user is paying month over month, not taking promotions into account. */
	intendedAmount?: GraphQLTypes["BigDecimal"] | undefined,
	/** Whether a subscription is repayable. Controls whether we'll allow usersto repay a missed subscription from the home screen. */
	isPayable: boolean,
	/** Amount that we attempted to collect for a given subscription. Will be 1:1 with intendedAmount unless user received a promotion for a given month. */
	netAmount: GraphQLTypes["BigDecimal"],
	/** Date on which we'll attempt to collect on the subscription payment, formatted YYYY-MM-dd */
	paymentDate: string,
	/** Status of subscription payment. */
	status: GraphQLTypes["SubscriptionStatus"],
	/** If present, the subscription tier the user was on when we scheduled the givensubscription payment. If not present, the user is a legacy non-tiered user. */
	subscriptionTierLevel?: GraphQLTypes["SubscriptionTierLevel"] | undefined,
	/** UUID of subscription payment */
	uuid: string
};
	/** A subscription tier is an offering for a type of subscription that a user can enroll in. A tier has a price that the user pays to access it, and includes features that Brigit provides in exchange. Some tiers provide fewer features for a lower price, and others provide more features for a higher price. */
["SubscriptionTier"]: {
	__typename: "SubscriptionTier",
	/** The name of this tier to show to the user. */
	displayName: string,
	/** An explanation of how Instant Cash Express Transfer fees would work for a user on this tier. If the tier does not provide access to Instant Cash, instant transfer fees are not applicable and this field will be empty. NOTE: for NPE users this will be the subscription date if the user activates today with their same bank account, this could change if they change their bank account or income source. */
	expressTransferFeeText?: string | undefined,
	/** An identifier for which subscription tier this is. There are a small, fixed number of tiers. */
	level: GraphQLTypes["SubscriptionTierLevel"],
	/** The monthly cost that a user on this tier would pay, in US dollars. */
	pricingAmount: GraphQLTypes["BigDecimal"],
	/** The Brigit products that a user on this tier would have access to. */
	products: Array<GraphQLTypes["Product"]>
};
	/** A subscription tier experiment involves splitting users into different groups to provide them with different subscription-related experiences, to see which experiences work best. This object records a user's participation in a single experiment. */
["SubscriptionTierExperiment"]: {
	__typename: "SubscriptionTierExperiment",
	/** Identifies the experiment. */
	experimentName: GraphQLTypes["SubscriptionTierExperimentName"],
	/** A variant name identifies which group the user is part of for this experiment. The names will depend on the experiment, but CONTROL is typically the name given to the variant that provides an unchanged experience. If only one alternate experience is being tested, the variant for users being shown the new experience is typically TEST. Some experiments may have other variants, however, with unique names. */
	variantName: string
};
	["SwitchProtectedLoginToExistingLogin"]: {
	__typename: "SwitchProtectedLoginToExistingLogin",
	/** An Enum denoting if the outcome of switching the protected login was successful or failure */
	outcome: GraphQLTypes["SwitchProtectedLoginToExistingLoginOutcome"]
};
	["SwitchProtectedLoginToExistingNonConnectedLogin"]: {
	__typename: "SwitchProtectedLoginToExistingNonConnectedLogin",
	/** An Enum denoting if the outcome of switching the protected login to an existing, non-connected login was successful or failure */
	outcome: GraphQLTypes["SwitchProtectedLoginToExistingNonConnectedLoginOutcome"]
};
	["TablessMarketplaceOffer"]: {
	__typename: "TablessMarketplaceOffer",
	/** The url for the offers affiliate link. */
	affiliateLinkUrl: string,
	/** A list of badges to be rendered with the offer. */
	badges: Array<GraphQLTypes["MarketplaceBadge"]>,
	/** The categories the offer is under. */
	categories: Array<GraphQLTypes["TablessMarketplaceCategoryType"]>,
	/** The offers description. */
	description: string,
	/** The offers details. */
	details: string,
	/** The offers id. */
	id: GraphQLTypes["Long"],
	/** The url for the offers logos image file. */
	logoUrl: string,
	/** The offers name. */
	offerName: string
};
	/** Represents the installment loan term details for a prospective user */
["TermRequestDetails"]: {
	__typename: "TermRequestDetails",
	/** Application fee in cents */
	applicationFeeInCents: GraphQLTypes["Long"],
	/** Estimated delivery for the principal payment credit */
	estimatedDeliveryDateForPrincipalCredit: string,
	/** Monthly fee in cents */
	monthlyFeeInCents: GraphQLTypes["Long"],
	/** Pay Frequency type for the payment schedule */
	payFrequency: GraphQLTypes["FrontendInstallmentLoansPayFrequency"],
	/** Payment schedule */
	paymentSchedule: Array<GraphQLTypes["FrontendPrincipalPayment"] | undefined>,
	/** Total months of repayment for the term */
	totalMonthsInTerm: GraphQLTypes["Long"],
	/** Loan amount in cents */
	totalPrincipalAmountInCents: GraphQLTypes["Long"]
};
	["TextFieldFetcher"]: {
	__typename: "TextFieldFetcher",
	/** Prior persisted value input by the user, if exists */
	initValue?: string | undefined,
	/** Whether the text field may contain sensitive information */
	isSensitive: boolean,
	/** What type of keyboard should be shown */
	keyboardType?: GraphQLTypes["KeyboardType"] | undefined,
	/** The text field label */
	label: string,
	/** A list of strings containing the names of methods to format text, e.g. date or dollarAmount */
	textFormat: Array<GraphQLTypes["TextFormatter"]>,
	/** An array of strings of validation that should be applied to the field */
	validate: Array<GraphQLTypes["TextInputValidators"]>,
	/** An array of strings of possible warnings that should be applied to the field */
	warn: Array<GraphQLTypes["TextInputWarning"]>
};
	["TrackImpressionsFetcher"]: {
	__typename: "TrackImpressionsFetcher",
	/** Tracked impression that is saved to the backend if the outcome indicates a success */
	impression: GraphQLTypes["UserImpression"],
	/** Result of the attempt to save the impression */
	outcome: GraphQLTypes["TrackNewImpressionsOutcome"]
};
	["TrackNotificationCenterEntryClick"]: {
	__typename: "TrackNotificationCenterEntryClick",
	/** Enum denoting if the outcome of tracking click on notification center entry was a success or failure */
	outcome: GraphQLTypes["TrackNotificationCenterEntryClickOutcome"]
};
	["UnderwritingDetails"]: {
	__typename: "UnderwritingDetails",
	/** Describes the approval of the user's account which could be approved, unapproved, or not run if the user has not been underwritten yet */
	accountApprovalState: GraphQLTypes["FrontendAccountApprovalState"],
	/** Whether the user is approved or not based on their credit risk state */
	creditRiskState: GraphQLTypes["FrontendCreditRiskState"],
	/** Fetch users approval state from the latest underwriting report */
	isApproved: boolean,
	/** Latest credit limit boost for a user */
	latestCreditLimitBoost?: GraphQLTypes["CreditLimitBoostDetails"] | undefined,
	/** Maximum loan amount for user */
	maxLoanAmount: GraphQLTypes["BigDecimal"],
	/** Minimum loan amount for user */
	minLoanAmount: GraphQLTypes["BigDecimal"],
	/** A numeric representation of the user's progress in being approved based on the number of underwriting rules the user has met and not met */
	progress: number,
	/** A representation of the user's underwriting score and score categories. This field is only present if the user is on a score-based underwriting strategy. */
	scoreCard?: GraphQLTypes["ScoreCard"] | undefined,
	/** Fetch all underwriting checks which include knockout rules and account level rules from the latest underwriting report */
	underwritingChecks: Array<GraphQLTypes["FrontendUnderwritingCheck"]>,
	/** List of underwriting rules and metadata used for underwriting the user */
	underwritingRulesMetadata: Array<GraphQLTypes["UnderwritingRuleMetadata"] | undefined>,
	/** All credit limit boosts for a user */
	userCreditLimitBoosts?: Array<GraphQLTypes["CreditLimitBoostDetails"] | undefined> | undefined
};
	["UnderwritingRuleMetadata"]: {
	__typename: "UnderwritingRuleMetadata",
	/** Depending on the underwriting rule, the cutoff serves as the maximum or minimum amount needed for the user to meet this UW rule */
	cutoff: string,
	/** Name of underwriting rule used for calculating the user's underwriting score */
	name: string
};
	["UpdateBankAccountJointStatus"]: {
	__typename: "UpdateBankAccountJointStatus",
	/** An Enum denoting if the outcome of updating the bank account's joint status was successful or failure */
	outcome: GraphQLTypes["UpdateBankAccountJointStatusOutcome"]
};
	["UpdateCreditLimitBoostStatus"]: {
	__typename: "UpdateCreditLimitBoostStatus",
	/** The updated status of the credit limit boost */
	updatedCreditLimitBoostStatus: GraphQLTypes["FrontendCreditLimitBoostStatus"]
};
	["UpdateInstallmentLoansAutoPayStatusFetcher"]: {
	__typename: "UpdateInstallmentLoansAutoPayStatusFetcher",
	/** An Enum denoting if the outcome of the auto update status is successful */
	outcome: GraphQLTypes["FrontendInstallmentLoansUpdateAutoPayOutcome"]
};
	/** A USA state, territory, or region that has a human-readable name and associated code. */
["UsaState"]: {
	__typename: "UsaState",
	/** The two-letter code for the state (e.g. 'MD') */
	code: string,
	/** The human readable name for the state (e.g. 'Maryland'). */
	name: string
};
	/** A user's account is like their profile within Brigit. It's the object that records the user's state, preferences, and the interactions and agreements made between the user and Brigit. */
["UserAccount"]: {
	__typename: "UserAccount",
	/** List of active logins for user. This will include the user's protected login (which holds the account we're sending advances to and collecting subscriptions from) and their non-protected, active logins, which are non-deleted primary logins that we still maintain a connection to. */
	activeBankLogins: GraphQLTypes["ActiveBankLogins"],
	/** The user's approved loan amounts */
	approvedLoanAmounts?: GraphQLTypes["ApprovedLoanAmounts"] | undefined,
	/** The user's auto advance configuration */
	autoAdvanceDetails: GraphQLTypes["AutoAdvanceDetails"],
	/** Predictions based on the user's linked bank account such as their income sources and likelihood to overdraft. */
	bankAccountPrediction: GraphQLTypes["BankAccountPrediction"],
	/** The user's bank connection details */
	bankConnection?: GraphQLTypes["BankConnection"] | undefined,
	/** Date on which user created their account */
	creationDate?: string | undefined,
	/** The user's eligibility for Credit Builder enrollment. */
	creditBuilderEnrollmentEligibility: GraphQLTypes["CreditBuilderEnrollmentEligibility"],
	/** The user's most recent Credit Builder installment loan. */
	creditBuilderInstallmentLoan?: GraphQLTypes["CreditBuilderInstallmentLoan"] | undefined,
	/** The Sweep Stakes qualification determines if user is qualifies for sweep stake */
	creditBuilderSweepStakesQualification: GraphQLTypes["FrontendCreditBuilderSweepStakesQualificationType"],
	/** User details related to Credit Builder */
	creditBuilderUserDetails: GraphQLTypes["CreditBuilderUserDetails"],
	/** The user's relationship with Brigit's Credit Monitoring product. */
	creditMonitoringEnrollment: GraphQLTypes["CreditMonitoringEnrollment"],
	/** Promo card v1 offers from the dynamic data model */
	dynamicPromoCardV1Offers: Array<GraphQLTypes["PromoCardOffer"]>,
	/** The user's email address. */
	email: string,
	/** The user's email address that is in the process of being verified. */
	emailToVerify?: string | undefined,
	/** Extension credits for user - they can use this to extend the loan due date for any outstanding loan */
	extensionCredits: number,
	/** The user's first name. */
	firstName?: string | undefined,
	/** Flags represent states that a user may be in. Historically they have been used to mark users for inclusion or exclusion in tests, though we hope to move to an alternative way of managing that. */
	flags: Array<GraphQLTypes["UserFlag"]>,
	/** Get if user is in a state where they have been approved for a free instant cash transfer. */
	freeInstantCashTransferState?: boolean | undefined,
	/** Customizable banner to display to user on the home screen */
	homeScreenBanner?: GraphQLTypes["HomeScreenBanner"] | undefined,
	/** User properties related to Instant Cash experiments */
	iCExperimentProperties: GraphQLTypes["ICExperimentProperties"],
	/** The user's ID is their unique identifier within Brigit. While mostly used internally, it may need to be provided to external services to link a user's account to them. */
	id: string,
	/** Whether the user is enrolled, or was previously enrolled, in Brigit's Identity Protection product. */
	identityProtectionEnrollmentStatus: GraphQLTypes["IdentityProtectionEnrollmentStatus"],
	/** Profile representing a user's salary based on specific filters */
	incomeProfile?: GraphQLTypes["IncomeProfile"] | undefined,
	/** The user's prospective and current details related with the Installment Loans product */
	installmentLoans: GraphQLTypes["InstallmentLoans"],
	/** The user's last name. */
	lastName?: string | undefined,
	/** This field is no longer relevant as we have rolled out tiered subscriptions at 100%. It remains here as a placeholder until we set the minimum app version to >= v704, which is the version where we removed this field from the query in the FE */
	legacyProposedNextMonthDiscountedAmount?: GraphQLTypes["BigDecimal"] | undefined,
	/** The user's prospective and current details related with the Installment Loans product */
	lineOfCredit: GraphQLTypes["LineOfCredit"],
	/** The user's location state */
	locationState?: GraphQLTypes["UsaState"] | undefined,
	/** A list of MarketplaceOffers by Category */
	marketplaceOffers: Array<GraphQLTypes["MarketplaceOfferByCategory"]>,
	/** Lists of marketplace offers. */
	marketplaceOffersV2: GraphQLTypes["MarketplaceOffersV2"],
	/** MoneyLionEngineOffers */
	moneyLionEngineOffers: GraphQLTypes["MoneyLionEngineOffers"],
	/** In-app notification center */
	notificationCenter: GraphQLTypes["NotificationCenter"],
	/** Details related to the user's payroll accounts */
	payrollAccounts: Array<GraphQLTypes["Payroll_32_Accounts"] | undefined>,
	/** Details related to the user's payroll accounts */
	payrollAccountsV2: Array<GraphQLTypes["Payroll_32_Accounts_32_V2"] | undefined>,
	/** Details related to the user's payroll accounts */
	payrollAccountsWithOutcome: GraphQLTypes["Payroll_32_Account_32_Fetcher_32_With_32_Outcome"],
	/** Details related to the user's payroll connection */
	payrollConnection: GraphQLTypes["PayrollConnection"],
	/** The user's bank transactional data insights. */
	pfmSummary: GraphQLTypes["PfmSummary"],
	/** The user's phone number. */
	phoneNumber: string,
	/** The user's phone number that is in the process of being verified. */
	phoneNumberToVerify?: string | undefined,
	/** Variant on premium upsell card to display to users on the home screen */
	premiumUpsellCardVariant?: GraphQLTypes["PremiumUpsellCardVariant"] | undefined,
	/** Promo card v1 and v2 offers from the legacy data model */
	promoCardOffers: Array<GraphQLTypes["PromoCardOffer"]>,
	/** If present, the user is being offered a reduced price for the provided tier. Currently, if a user is eligible for a promotion, this field will return the same promotion across all subscription tiers. */
	promotion?: GraphQLTypes["Promotion"] | undefined,
	/** Details related to the user's advances */
	protectionDetails: GraphQLTypes["ProtectionDetails"],
	/** The user's referral link. */
	referralLink: string,
	/** The user's list of referrals. */
	referrals: Array<GraphQLTypes["Referral"]>,
	/** Includes the user's current payment plan, and what features they have access to as part of that. */
	subscription: GraphQLTypes["Subscription"],
	/** Return the subscription fee schedule text dynamically for the activate screen https://brigit.atlassian.net/browse/ENG-9573. */
	subscriptionFeeBillingScheduleText?: string | undefined,
	/** The message to show to the user regarding how their subscription would be charged for the provided tier, including when and for how much. If absent, we either don't have enough information to know what the schedule would be, or the user would not be charged anything. */
	subscriptionScheduleText?: string | undefined,
	/** The user's underwriting details - holds details around the underwriting report such as if a user is eligible for an Instant Cash advance */
	underwritingDetails?: GraphQLTypes["UnderwritingDetails"] | undefined,
	/** The user's Credit Builder debt persona as a result of user engagement quiz */
	userCreditBuilderDebtPersona?: GraphQLTypes["UserDebtPersona"] | undefined,
	/** The user's debt persona as a result of user engagement quiz */
	userDebtPersona?: GraphQLTypes["UserDebtPersona"] | undefined,
	/** The user's consent prompts to display */
	userPrompts: GraphQLTypes["UserPrompts"]
};
	/** User's debt persona information as a result of user engagement quiz */
["UserDebtPersona"]: {
	__typename: "UserDebtPersona",
	/** The user's debt persona */
	debtPersonaType: GraphQLTypes["DebtPersonaType"],
	/** The ID of the quiz */
	quizId: GraphQLTypes["Long"]
};
	["UserImpression"]: {
	__typename: "UserImpression",
	/** Duration in milliseconds */
	durationMs: number,
	/** UI element tracked */
	elementName: GraphQLTypes["ImpressionableElement"],
	/** Impression end time */
	endedAt: string,
	/** Foreign keys to other data useful for understanding the impression */
	eventForeignKeys?: string | undefined,
	/** Impression ID */
	impressionId: string,
	/** Screen of the tracked event */
	screenName: string,
	/** Impression start time */
	startedAt: string,
	/** User ID */
	userId: string
};
	/** Represents a consent experience the user must undergo to opt into a certain feature set */
["UserPrompt"]: {
	__typename: "UserPrompt",
	/** The 0 indexed display rank. Lower the number, the 'higher' the priority */
	displayRank: GraphQLTypes["Long"],
	/** The user prompt type */
	type: GraphQLTypes["UserPromptType"]
};
	/** A list of user prompts to display consent experiences for certain features */
["UserPrompts"]: {
	__typename: "UserPrompts",
	/** List of prompt objects containing the prompt type and the display priority */
	prompts: Array<GraphQLTypes["UserPrompt"]>
};
	/** Represents the details for a installment loan */
["details"]: {
	__typename: "details",
	/** Is auto pay enabled or disabled */
	autoPayStatus: GraphQLTypes["FrontendInstallmentLoansAutoPay"],
	/** Field that denotes if a user should be able to see the ability to make a 'manual' catchup payment on the FE */
	catchupEligibility: GraphQLTypes["FrontendInstallmentLoansManualPaymentEligibilityStatus"],
	/** Name of bank account installment loan was disbursed to */
	disbursalBankAccountName: string,
	/** Date loan was disbursed */
	disbursalDate: string,
	/** Status of loan disbursal */
	disbursalStatus: GraphQLTypes["FrontendInstallmentLoansDisbursalStatus"],
	/** Estimated delivery for the principal payment credit if loan is not delivered */
	estimatedDeliveryDateForPrincipalCredit?: string | undefined,
	/** List of late fees accrued with a fee amount and the date it accrued */
	lateFees?: Array<GraphQLTypes["FrontendOverduePayment"] | undefined> | undefined,
	/** Late fees due in cents */
	lateFeesDue: GraphQLTypes["Long"],
	/** Next scheduled principal payment if it exists */
	nextScheduledPrincipalPayment?: GraphQLTypes["FrontendPrincipalPayment"] | undefined,
	/** Field that denotes if a user should be able to see the ability to make a 'manual' prepay or pay-off payment on the FE */
	prepayAndPayoffEligibility: GraphQLTypes["FrontendInstallmentLoansManualPaymentEligibilityStatus"],
	/** Principal amount that is past due and overdue in cents */
	principalPaymentOutstanding: GraphQLTypes["Long"],
	/** Remaining due principal payments on the schedule that haven't been processed yet */
	remainingDuePayments: Array<GraphQLTypes["FrontendPrincipalPayment"]>,
	/** Total paid amount for principal */
	totalPaidAmountForPrincipalPaymentsInCents: GraphQLTypes["Long"],
	/** Total paid amount so far in cents */
	totalPrincipalAmountInCents: GraphQLTypes["Long"],
	/** Installment Loan Id */
	uuid: string
};
	["BankProvider"]: BankProvider;
	["CampaignPromptType"]: CampaignPromptType;
	["ChangeSubscriptionTierOutcome"]: ChangeSubscriptionTierOutcome;
	["CopyColor"]: CopyColor;
	["CreatePayrollAccountOutcome"]: CreatePayrollAccountOutcome;
	["CreditBuilderEnrollmentIneligibilityReason"]: CreditBuilderEnrollmentIneligibilityReason;
	/** The type of enrollment that currently applies to the user. */
["CreditBuilderEnrollmentType"]: CreditBuilderEnrollmentType;
	["CreditBuilderPopulationType"]: CreditBuilderPopulationType;
	["CreditLimitBoostStatus"]: CreditLimitBoostStatus;
	["CreditMonitoringEnrollmentStatus"]: CreditMonitoringEnrollmentStatus;
	["CreditRating"]: CreditRating;
	["DayOfWeek"]: DayOfWeek;
	["DebtPersonaType"]: DebtPersonaType;
	["DeleteBankLoginOutcome"]: DeleteBankLoginOutcome;
	["DisableDirectDepositOutcome"]: DisableDirectDepositOutcome;
	["EducationLevel"]: EducationLevel;
	["EmploymentPayFrequency"]: EmploymentPayFrequency;
	["EmploymentStatus"]: EmploymentStatus;
	["ExperimentName"]: ExperimentName;
	["FrontEndCreditMonitoringEnrollment"]: FrontEndCreditMonitoringEnrollment;
	["FrontendAccountApprovalState"]: FrontendAccountApprovalState;
	["FrontendBankConnectionStatus"]: FrontendBankConnectionStatus;
	["FrontendBankInstitution"]: FrontendBankInstitution;
	["FrontendCBScheduleVersionReason"]: FrontendCBScheduleVersionReason;
	["FrontendCBScheduleVersionStatus"]: FrontendCBScheduleVersionStatus;
	["FrontendCreditBuilderEnrollmentStatus"]: FrontendCreditBuilderEnrollmentStatus;
	["FrontendCreditBuilderPaymentRetryStrategyType"]: FrontendCreditBuilderPaymentRetryStrategyType;
	["FrontendCreditBuilderReminderType"]: FrontendCreditBuilderReminderType;
	["FrontendCreditBuilderSweepStakesQualificationType"]: FrontendCreditBuilderSweepStakesQualificationType;
	["FrontendCreditBuilderTransactionSource"]: FrontendCreditBuilderTransactionSource;
	["FrontendCreditBuilderTransactionStatus"]: FrontendCreditBuilderTransactionStatus;
	["FrontendCreditBuilderTransactionType"]: FrontendCreditBuilderTransactionType;
	["FrontendCreditLimitBoostStatus"]: FrontendCreditLimitBoostStatus;
	["FrontendCreditRiskState"]: FrontendCreditRiskState;
	["FrontendDebitCardStatus"]: FrontendDebitCardStatus;
	["FrontendInitiateCreditDrawStatus"]: FrontendInitiateCreditDrawStatus;
	["FrontendInstallmentLoanChargePolicy"]: FrontendInstallmentLoanChargePolicy;
	["FrontendInstallmentLoanPaymentStrategy"]: FrontendInstallmentLoanPaymentStrategy;
	["FrontendInstallmentLoansAccountCreationStatus"]: FrontendInstallmentLoansAccountCreationStatus;
	["FrontendInstallmentLoansAutoPay"]: FrontendInstallmentLoansAutoPay;
	["FrontendInstallmentLoansCurrentStatus"]: FrontendInstallmentLoansCurrentStatus;
	["FrontendInstallmentLoansDisbursalStatus"]: FrontendInstallmentLoansDisbursalStatus;
	["FrontendInstallmentLoansKYCDecisionType"]: FrontendInstallmentLoansKYCDecisionType;
	["FrontendInstallmentLoansManualPaymentEligibilityStatus"]: FrontendInstallmentLoansManualPaymentEligibilityStatus;
	["FrontendInstallmentLoansPayFrequency"]: FrontendInstallmentLoansPayFrequency;
	["FrontendInstallmentLoansPaymentOutcome"]: FrontendInstallmentLoansPaymentOutcome;
	["FrontendInstallmentLoansStatus"]: FrontendInstallmentLoansStatus;
	["FrontendInstallmentLoansUpdateAutoPayOutcome"]: FrontendInstallmentLoansUpdateAutoPayOutcome;
	["FrontendLocAccountCreationStatus"]: FrontendLocAccountCreationStatus;
	["FrontendLocAccountStatus"]: FrontendLocAccountStatus;
	["FrontendLocAutopayStatus"]: FrontendLocAutopayStatus;
	["FrontendLocInitiatePaymentStatus"]: FrontendLocInitiatePaymentStatus;
	["FrontendLocKycRequirementStatus"]: FrontendLocKycRequirementStatus;
	["FrontendLocKycUnfulfillmentReasons"]: FrontendLocKycUnfulfillmentReasons;
	["FrontendLocMinPaymentStatus"]: FrontendLocMinPaymentStatus;
	["FrontendLocPaymentAttemptType"]: FrontendLocPaymentAttemptType;
	["FrontendLocUnderwritingStatus"]: FrontendLocUnderwritingStatus;
	["FrontendLocUserInitiatedPaymentChargeType"]: FrontendLocUserInitiatedPaymentChargeType;
	["FrontendMobileVersionSupportType"]: FrontendMobileVersionSupportType;
	["FrontendOngoingRefreshStatus"]: FrontendOngoingRefreshStatus;
	["FrontendPaymentAuthFlow"]: FrontendPaymentAuthFlow;
	["FrontendPaymentStatus"]: FrontendPaymentStatus;
	["FrontendPayrollAccountConnectionStatus"]: FrontendPayrollAccountConnectionStatus;
	["FrontendReturnType"]: FrontendReturnType;
	["FrontendSampleKeyType"]: FrontendSampleKeyType;
	["HeroIconSize"]: HeroIconSize;
	["IdentityProtectionEnrollmentStatus"]: IdentityProtectionEnrollmentStatus;
	["ImpressionableElement"]: ImpressionableElement;
	["InputSalaryDataOutcome"]: InputSalaryDataOutcome;
	["KeyboardType"]: KeyboardType;
	["LoanPurpose"]: LoanPurpose;
	["LoanTimelineStatus"]: LoanTimelineStatus;
	["LoanTimelineType"]: LoanTimelineType;
	["LoginFailureReason"]: LoginFailureReason;
	["MarkNotificationCenterEntriesViewedOutcome"]: MarkNotificationCenterEntriesViewedOutcome;
	["MarketplaceBadgeType"]: MarketplaceBadgeType;
	["MarketplaceFeature"]: MarketplaceFeature;
	["ModelRank"]: ModelRank;
	["NonRepaymentReason"]: NonRepaymentReason;
	["OneTimePaymentOutcome"]: OneTimePaymentOutcome;
	["OngoingRefreshStatus"]: OngoingRefreshStatus;
	["OverdraftLikelihood"]: OverdraftLikelihood;
	["PayFrequency"]: PayFrequency;
	["PayrollAccountConnectionStatus"]: PayrollAccountConnectionStatus;
	["PayrollAccountFetcherOutcome"]: PayrollAccountFetcherOutcome;
	["PayrollProvider"]: PayrollProvider;
	["PersistedIncomeStatus"]: PersistedIncomeStatus;
	["PremiumUpsellCardVariant"]: PremiumUpsellCardVariant;
	["Product"]: Product;
	["ProductSubType"]: ProductSubType;
	["PromptResponseType"]: PromptResponseType;
	["PropertyStatus"]: PropertyStatus;
	["ProtectionMediumType"]: ProtectionMediumType;
	["ProtectionStatusV4"]: ProtectionStatusV4;
	["QuizFeedbackType"]: QuizFeedbackType;
	["QuizName"]: QuizName;
	["QuizStepType"]: QuizStepType;
	["RecentScoreChangeText"]: RecentScoreChangeText;
	["RegisterBankLoginMaintainingExistingLoginOutcome"]: RegisterBankLoginMaintainingExistingLoginOutcome;
	["RepayAdvanceOutcome"]: RepayAdvanceOutcome;
	["RequestManualDisbursalOutcome"]: RequestManualDisbursalOutcome;
	["ResendAuthVerificationCodeOutcome"]: ResendAuthVerificationCodeOutcome;
	["SalaryAggregateFilterCategory"]: SalaryAggregateFilterCategory;
	["SamplingTrigger"]: SamplingTrigger;
	["ScheduleCreditBuilderSameDayManualPaymentOutcome"]: ScheduleCreditBuilderSameDayManualPaymentOutcome;
	["ScoreChangeCallToAction"]: ScoreChangeCallToAction;
	["SubmitUserPromptOutcome"]: SubmitUserPromptOutcome;
	["SubscriptionFeeSchedule"]: SubscriptionFeeSchedule;
	["SubscriptionStatus"]: SubscriptionStatus;
	["SubscriptionTierExperimentName"]: SubscriptionTierExperimentName;
	["SubscriptionTierLevel"]: SubscriptionTierLevel;
	["SwitchProtectedLoginToExistingLoginOutcome"]: SwitchProtectedLoginToExistingLoginOutcome;
	["SwitchProtectedLoginToExistingNonConnectedLoginOutcome"]: SwitchProtectedLoginToExistingNonConnectedLoginOutcome;
	["TablessMarketplaceCategoryType"]: TablessMarketplaceCategoryType;
	["TextFormatter"]: TextFormatter;
	["TextInputValidators"]: TextInputValidators;
	["TextInputWarning"]: TextInputWarning;
	["TrackNewImpressionsOutcome"]: TrackNewImpressionsOutcome;
	["TrackNotificationCenterEntryClickOutcome"]: TrackNotificationCenterEntryClickOutcome;
	["UXPromptResponseType"]: UXPromptResponseType;
	["UnderwritingCategory"]: UnderwritingCategory;
	["UnderwritingRule"]: UnderwritingRule;
	["UpdateBankAccountJointStatusOutcome"]: UpdateBankAccountJointStatusOutcome;
	["UserFlag"]: UserFlag;
	["UserFriendlyCardNetwork"]: UserFriendlyCardNetwork;
	/** The type of prompt a user will see */
["UserPromptType"]: UserPromptType;
	/** java.math.BigDecimal */
["BigDecimal"]: "scalar" & { name: "BigDecimal" };
	/** A calendar day, in ISO-8601 "yyyy-MM-dd" format */
["Date"]: "scalar" & { name: "Date" };
	/** Long type */
["Long"]: "scalar" & { name: "Long" };
	/** UUID V4 format */
["UUID"]: "scalar" & { name: "UUID" }
    }
export enum BankProvider {
	ANCHOR = "ANCHOR",
	CAPITAL_ONE = "CAPITAL_ONE",
	IN_MEMORY = "IN_MEMORY",
	PLAID = "PLAID"
}
export enum CampaignPromptType {
	IC_PREMIUM_UPSELL = "IC_PREMIUM_UPSELL"
}
export enum ChangeSubscriptionTierOutcome {
	ACTIVATION_BLOCKED_FRAUD = "ACTIVATION_BLOCKED_FRAUD",
	ADDITIONAL_BANK_LOGIN_REQUIRED = "ADDITIONAL_BANK_LOGIN_REQUIRED",
	CREDIT_BUILDER_ACCOUNT_CLOSURE_REQUIRED = "CREDIT_BUILDER_ACCOUNT_CLOSURE_REQUIRED",
	INSUFFICIENT_BANK_HISTORY = "INSUFFICIENT_BANK_HISTORY",
	LOAN_REPAYMENT_REQUIRED = "LOAN_REPAYMENT_REQUIRED",
	SUCCESS = "SUCCESS",
	UPGRADE_BLOCKED_FRAUD = "UPGRADE_BLOCKED_FRAUD"
}
export enum CopyColor {
	DARK = "DARK",
	LIGHT = "LIGHT"
}
export enum CreatePayrollAccountOutcome {
	SUCCESS = "SUCCESS",
	UNSUPPORTED_ACCOUNT_TYPE = "UNSUPPORTED_ACCOUNT_TYPE"
}
export enum CreditBuilderEnrollmentIneligibilityReason {
	BLOCKED = "BLOCKED",
	CLOSED_BANK_ACCOUNT = "CLOSED_BANK_ACCOUNT",
	ENROLLMENT_WITH_PENDING_PAYMENTS = "ENROLLMENT_WITH_PENDING_PAYMENTS",
	ENROLLMENT_WITH_REMAINING_MONTHS = "ENROLLMENT_WITH_REMAINING_MONTHS",
	FAILED_KYC = "FAILED_KYC",
	FRAUD = "FRAUD",
	INELIGIBLE_TIER = "INELIGIBLE_TIER",
	LOAN_IN_BAD_STANDING = "LOAN_IN_BAD_STANDING",
	RESIDES_IN_UNSUPPORTED_STATE = "RESIDES_IN_UNSUPPORTED_STATE",
	UNUSABLE_SSN = "UNUSABLE_SSN"
}
/** The type of enrollment that currently applies to the user. */
export enum CreditBuilderEnrollmentType {
	INITIAL_ENROLLMENT = "INITIAL_ENROLLMENT",
	REENROLLMENT = "REENROLLMENT"
}
export enum CreditBuilderPopulationType {
	IC_APPROVED = "IC_APPROVED",
	NA_SELECT = "NA_SELECT"
}
export enum CreditLimitBoostStatus {
	ACCEPTED = "ACCEPTED",
	APPLIED = "APPLIED",
	OFFERED = "OFFERED",
	REJECTED = "REJECTED"
}
export enum CreditMonitoringEnrollmentStatus {
	DISABLED = "DISABLED",
	DOES_NOT_EXIST = "DOES_NOT_EXIST",
	ENABLED = "ENABLED"
}
export enum CreditRating {
	EXCELLENT = "EXCELLENT",
	FAIR = "FAIR",
	GOOD = "GOOD",
	LIMITED = "LIMITED",
	POOR = "POOR",
	UNKNOWN = "UNKNOWN"
}
export enum DayOfWeek {
	FRIDAY = "FRIDAY",
	MONDAY = "MONDAY",
	SATURDAY = "SATURDAY",
	SUNDAY = "SUNDAY",
	THURSDAY = "THURSDAY",
	TUESDAY = "TUESDAY",
	WEDNESDAY = "WEDNESDAY"
}
export enum DebtPersonaType {
	BUILDER = "BUILDER",
	MANAGER = "MANAGER",
	PATHFINDER = "PATHFINDER"
}
export enum DeleteBankLoginOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum DisableDirectDepositOutcome {
	DIRECT_DEPOSIT_NOT_FOUND = "DIRECT_DEPOSIT_NOT_FOUND",
	ERROR = "ERROR",
	SUCCESS = "SUCCESS"
}
export enum EducationLevel {
	ASSOCIATE = "ASSOCIATE",
	BACHELORS = "BACHELORS",
	CERTIFICATE = "CERTIFICATE",
	DID_NOT_GRADUATE = "DID_NOT_GRADUATE",
	DOCTORATE = "DOCTORATE",
	HIGH_SCHOOL = "HIGH_SCHOOL",
	MASTERS = "MASTERS",
	OTHER = "OTHER",
	OTHER_GRAD_DEGREE = "OTHER_GRAD_DEGREE",
	STILL_ENROLLED = "STILL_ENROLLED"
}
export enum EmploymentPayFrequency {
	BIWEEKLY = "BIWEEKLY",
	MONTHLY = "MONTHLY",
	TWICE_MONTHLY = "TWICE_MONTHLY",
	WEEKLY = "WEEKLY"
}
export enum EmploymentStatus {
	EMPLOYED = "EMPLOYED",
	EMPLOYED_FULL_TIME = "EMPLOYED_FULL_TIME",
	EMPLOYED_PART_TIME = "EMPLOYED_PART_TIME",
	MILITARY = "MILITARY",
	NOT_EMPLOYED = "NOT_EMPLOYED",
	OTHER = "OTHER",
	RETIRED = "RETIRED",
	SELF_EMPLOYED = "SELF_EMPLOYED"
}
export enum ExperimentName {
	ADD_3RD_HOME_SCREEN_PROMO_CARD_INSURIFY = "ADD_3RD_HOME_SCREEN_PROMO_CARD_INSURIFY",
	AISLED_EARN_AND_SAVE = "AISLED_EARN_AND_SAVE",
	AISLED_MARKETPLACE = "AISLED_MARKETPLACE",
	CARD_TARGET_PAYMENT_METHOD_ROUTING = "CARD_TARGET_PAYMENT_METHOD_ROUTING",
	CB_ACTIVATION_SCREEN_OPTIMIZATIONS = "CB_ACTIVATION_SCREEN_OPTIMIZATIONS",
	CB_ARRAY_INTEGRATION_IMPROVEMENTS = "CB_ARRAY_INTEGRATION_IMPROVEMENTS",
	CB_AUTOMATIC_RETRY_COLLECTIONS_STRATEGY = "CB_AUTOMATIC_RETRY_COLLECTIONS_STRATEGY",
	CB_CREDIT_TAB_DEBT_POSITIONING_TEST = "CB_CREDIT_TAB_DEBT_POSITIONING_TEST",
	CB_DAILY_SAVINGS_DISBURSAL_TASK = "CB_DAILY_SAVINGS_DISBURSAL_TASK",
	CB_DEBT_QUIZ = "CB_DEBT_QUIZ",
	CB_DEBT_QUIZ_V2 = "CB_DEBT_QUIZ_V2",
	CB_DIRECT_COASTAL_EXPERIMENT = "CB_DIRECT_COASTAL_EXPERIMENT",
	CB_HOME_PLACEMENTS = "CB_HOME_PLACEMENTS",
	CB_NA_POPULATION_TARGETING = "CB_NA_POPULATION_TARGETING",
	CB_NEW_CREDIT_SCORE_AND_PAYMENT_UI = "CB_NEW_CREDIT_SCORE_AND_PAYMENT_UI",
	CB_PAYMENT_SCHEDULE_ADJUSTMENT = "CB_PAYMENT_SCHEDULE_ADJUSTMENT",
	CB_PREMIUM_UPSELL_ENROLLMENT_UX = "CB_PREMIUM_UPSELL_ENROLLMENT_UX",
	CB_RENEWAL_IMPROVEMENTS = "CB_RENEWAL_IMPROVEMENTS",
	CB_RENEWAL_KYC_REQUIREMENT = "CB_RENEWAL_KYC_REQUIREMENT",
	CB_SOCURE_DECISION_MODULE_JAN_2024 = "CB_SOCURE_DECISION_MODULE_JAN_2024",
	CB_TOGGLE_AUTOPAY = "CB_TOGGLE_AUTOPAY",
	CREDIT_LIMIT_EXPANSION = "CREDIT_LIMIT_EXPANSION",
	CREDIT_LIMIT_REVALUATION = "CREDIT_LIMIT_REVALUATION",
	DYNAMIC_ES_DATA_MODEL = "DYNAMIC_ES_DATA_MODEL",
	DYNAMIC_PROMO_CARDS = "DYNAMIC_PROMO_CARDS",
	DYNAMIC_V1_PROMO_CARD_DATA_MODEL = "DYNAMIC_V1_PROMO_CARD_DATA_MODEL",
	FILTERED_MARKETPLACE = "FILTERED_MARKETPLACE",
	FIONA_PL_ENTRY_POINT_NATIVE_UX_MIGRATION = "FIONA_PL_ENTRY_POINT_NATIVE_UX_MIGRATION",
	FREECASH_VS_KASHKICK_HOME_SCREEN_PROMO_CARD_AB = "FREECASH_VS_KASHKICK_HOME_SCREEN_PROMO_CARD_AB",
	GET_HIGHLIGHTED_PARTNERS_FROM_GRAPHQL = "GET_HIGHLIGHTED_PARTNERS_FROM_GRAPHQL",
	GET_MARKETPLACE_OFFERS_FROM_GRAPHQL = "GET_MARKETPLACE_OFFERS_FROM_GRAPHQL",
	GROWTH_ACTIVATE_SCREEN_CTA_CHANGE_FEB_2025 = "GROWTH_ACTIVATE_SCREEN_CTA_CHANGE_FEB_2025",
	GROWTH_ACTIVATE_SCREEN_REDESIGN = "GROWTH_ACTIVATE_SCREEN_REDESIGN",
	GROWTH_APP_STORE_RATINGS_FOR_GALAXY_STORE = "GROWTH_APP_STORE_RATINGS_FOR_GALAXY_STORE",
	GROWTH_BANK_LINK_SCREEN_COPY_CHANGE_Q1_25 = "GROWTH_BANK_LINK_SCREEN_COPY_CHANGE_Q1_25",
	GROWTH_EWA_DISCLAIMER_ON_SUBSCRIPTION_DETAILS_SCREEN = "GROWTH_EWA_DISCLAIMER_ON_SUBSCRIPTION_DETAILS_SCREEN",
	GROWTH_EXPEDITED_REACTIVATION_NOV_24 = "GROWTH_EXPEDITED_REACTIVATION_NOV_24",
	GROWTH_EXPEDITE_REFERRAL_TO_NATIVE_SHARE_SHEET = "GROWTH_EXPEDITE_REFERRAL_TO_NATIVE_SHARE_SHEET",
	GROWTH_IC_ACTIVATE_SCREEN_REFRAME_SUBSCRIPTION_PRICING_V2 = "GROWTH_IC_ACTIVATE_SCREEN_REFRAME_SUBSCRIPTION_PRICING_V2",
	GROWTH_MOVE_CARDINPUT_POST_PRICING_DETAILS = "GROWTH_MOVE_CARDINPUT_POST_PRICING_DETAILS",
	GROWTH_ONBOARDING_PRODUCT_MULTISELECT = "GROWTH_ONBOARDING_PRODUCT_MULTISELECT",
	GROWTH_PREMIUM_UPSELL_BOTTOM_SHEET = "GROWTH_PREMIUM_UPSELL_BOTTOM_SHEET",
	GROWTH_REFERRAL_HOME_BADGE = "GROWTH_REFERRAL_HOME_BADGE",
	GROWTH_REFERRAL_INVITE_FRIENDS_CARD = "GROWTH_REFERRAL_INVITE_FRIENDS_CARD",
	GROWTH_REFERRAL_TODO_CARD = "GROWTH_REFERRAL_TODO_CARD",
	GROWTH_REFRESH_REFERRAL_LINK_FEATURE_FLAG = "GROWTH_REFRESH_REFERRAL_LINK_FEATURE_FLAG",
	GROWTH_TOS_CHECKBOX_ON_ACTIVATION = "GROWTH_TOS_CHECKBOX_ON_ACTIVATION",
	IC_ACTIVATE_SCREEN_REFRAME_SUBSCRIPTION_PRICING = "IC_ACTIVATE_SCREEN_REFRAME_SUBSCRIPTION_PRICING",
	IC_CREDIT_LIMIT_BOOSTS = "IC_CREDIT_LIMIT_BOOSTS",
	IC_CREDIT_LIMIT_BOOSTS_V2 = "IC_CREDIT_LIMIT_BOOSTS_V2",
	IC_DYNAMIC_PRE_ACTIVATION = "IC_DYNAMIC_PRE_ACTIVATION",
	IC_NO_INCOME_USERS_ATTEMPT_COLLECTION_ON_CARD = "IC_NO_INCOME_USERS_ATTEMPT_COLLECTION_ON_CARD",
	IC_PAYROLL_CONNECT_HOME = "IC_PAYROLL_CONNECT_HOME",
	IC_PAYROLL_CONNECT_IC_LANDING = "IC_PAYROLL_CONNECT_IC_LANDING",
	IC_PAYROLL_CONNECT_ONBOARDING = "IC_PAYROLL_CONNECT_ONBOARDING",
	IC_PAYROLL_CONNECT_UNAPPROVED = "IC_PAYROLL_CONNECT_UNAPPROVED",
	IC_REPAY_BY_PAYCHECK_APPROVED_USERS = "IC_REPAY_BY_PAYCHECK_APPROVED_USERS",
	IC_REPAY_BY_PAYCHECK_BETA = "IC_REPAY_BY_PAYCHECK_BETA",
	IC_REPAY_BY_PAYCHECK_PAINTED_DOOR_TEST = "IC_REPAY_BY_PAYCHECK_PAINTED_DOOR_TEST",
	IC_REPAY_BY_PAYCHECK_UNAPPROVED_USERS = "IC_REPAY_BY_PAYCHECK_UNAPPROVED_USERS",
	IC_SAMPLE_PAYMENTS_ONTO_COASTAL = "IC_SAMPLE_PAYMENTS_ONTO_COASTAL",
	IC_SSA_SPECIFIC_INCOME_INFERRER = "IC_SSA_SPECIFIC_INCOME_INFERRER",
	IC_TRADITIONAL_REPAYMENT_ON_CARD = "IC_TRADITIONAL_REPAYMENT_ON_CARD",
	IC_TRADITIONAL_USER_CARD_REQUIRED = "IC_TRADITIONAL_USER_CARD_REQUIRED",
	INSTALLMENT_LOANS_MVP = "INSTALLMENT_LOANS_MVP",
	INSURIFY_HS_PROMO_CARD_P2_AB_TEST = "INSURIFY_HS_PROMO_CARD_P2_AB_TEST",
	LINE_OF_CREDIT_BETA_LAUNCH = "LINE_OF_CREDIT_BETA_LAUNCH",
	LINE_OF_CREDIT_MVP = "LINE_OF_CREDIT_MVP",
	LOANS_TAB_LANDING_SCREEN_UI_UPDATE = "LOANS_TAB_LANDING_SCREEN_UI_UPDATE",
	MARKETPLACE_CAR_INSURANCE_CATEGORY_INJECTION = "MARKETPLACE_CAR_INSURANCE_CATEGORY_INJECTION",
	MARKETPLACE_CAR_INSURANCE_CATEGORY_INJECTION_V2 = "MARKETPLACE_CAR_INSURANCE_CATEGORY_INJECTION_V2",
	MARKETPLACE_ES_SWITCH_PL_AND_EQC_CATEGORY_ORDER = "MARKETPLACE_ES_SWITCH_PL_AND_EQC_CATEGORY_ORDER",
	MARKETPLACE_FILE_TAXES_CATEGORY = "MARKETPLACE_FILE_TAXES_CATEGORY",
	MARKETPLACE_FIONA_PL_HOME_SCREEN_PROMO_TILE_TRAFFIC_SPLIT = "MARKETPLACE_FIONA_PL_HOME_SCREEN_PROMO_TILE_TRAFFIC_SPLIT",
	MARKETPLACE_FREECASH_KASHKICK_ES_EQC_TOP_POSITION = "MARKETPLACE_FREECASH_KASHKICK_ES_EQC_TOP_POSITION",
	MARKETPLACE_HIDE_OFFERS_WITH_CONVERSIONS = "MARKETPLACE_HIDE_OFFERS_WITH_CONVERSIONS",
	MARKETPLACE_PROMO_CARD_IMPRESSION_DURATION_TRACKING = "MARKETPLACE_PROMO_CARD_IMPRESSION_DURATION_TRACKING",
	MARKETPLACE_SORT_OFFERS_BY_PERFORMANCE = "MARKETPLACE_SORT_OFFERS_BY_PERFORMANCE",
	MARKETPLACE_SPLIT_TEST_MONEYLION_PERSONAL_LOAN_OFFER = "MARKETPLACE_SPLIT_TEST_MONEYLION_PERSONAL_LOAN_OFFER",
	MARKETPLACE_YA_PROMO_CARD_V1_AB_TEST_FIONA_HOME_SCREEN = "MARKETPLACE_YA_PROMO_CARD_V1_AB_TEST_FIONA_HOME_SCREEN",
	MEM_ALLOW_USER_TO_REPAY_MULTIPLE_OLD_SUBS = "MEM_ALLOW_USER_TO_REPAY_MULTIPLE_OLD_SUBS",
	MEM_ANDROID_IN_APP_DELETION = "MEM_ANDROID_IN_APP_DELETION",
	MEM_DEACTIVATION_CONFIRMATION_SCREEN = "MEM_DEACTIVATION_CONFIRMATION_SCREEN",
	MEM_DEACTIVATION_SURVEY_ASK_ADVANCE_AMOUNT_NEEDED = "MEM_DEACTIVATION_SURVEY_ASK_ADVANCE_AMOUNT_NEEDED",
	MEM_EXISTING_899_USERS_299_VS_249_TRANSFER_FEE_TEST = "MEM_EXISTING_899_USERS_299_VS_249_TRANSFER_FEE_TEST",
	MEM_EXPAND_SUB_REPAYMENT_TO_MOST_RECENT_ELIGIBLE_SUBS = "MEM_EXPAND_SUB_REPAYMENT_TO_MOST_RECENT_ELIGIBLE_SUBS",
	MEM_FORWARD_BILLING_SUBSCRIPTIONS = "MEM_FORWARD_BILLING_SUBSCRIPTIONS",
	MEM_FORWARD_BILLING_SUBSCRIPTIONS_PHASE_2 = "MEM_FORWARD_BILLING_SUBSCRIPTIONS_PHASE_2",
	MEM_HOME_IC_CARD_EXPRESS_DELIVERY_UPDATES = "MEM_HOME_IC_CARD_EXPRESS_DELIVERY_UPDATES",
	MEM_HOME_SCREEN_TOP_NAVIGATION = "MEM_HOME_SCREEN_TOP_NAVIGATION",
	MEM_MIGRATE_999_099_NPE_USERS_TO_899_199 = "MEM_MIGRATE_999_099_NPE_USERS_TO_899_199",
	MEM_MIGRATE_999_099_PE_USERS_TO_899_199 = "MEM_MIGRATE_999_099_PE_USERS_TO_899_199",
	MEM_MIL_PROMPT_SWITCH_TO_NON_PROTECTED = "MEM_MIL_PROMPT_SWITCH_TO_NON_PROTECTED",
	MEM_MULTI_ITEM_LINKING_PHASE_2 = "MEM_MULTI_ITEM_LINKING_PHASE_2",
	MEM_MULTI_ITEM_LINKING_PREVENT_DUPLICATE_LOGINS = "MEM_MULTI_ITEM_LINKING_PREVENT_DUPLICATE_LOGINS",
	MEM_NEW_SUBSCRIPTION_REPAYMENT_FLOW = "MEM_NEW_SUBSCRIPTION_REPAYMENT_FLOW",
	MEM_NOTIFICATION_CENTER_MVP = "MEM_NOTIFICATION_CENTER_MVP",
	MEM_PAINTED_DOOR_PREMIUM_UPSELL = "MEM_PAINTED_DOOR_PREMIUM_UPSELL",
	MEM_PE_YA_IC_CHURN_REASON_CARD = "MEM_PE_YA_IC_CHURN_REASON_CARD",
	MEM_PREMIUM_UPSELL_HOME_SCREEN_TEST = "MEM_PREMIUM_UPSELL_HOME_SCREEN_TEST",
	MEM_PROGRESS_BASED_TO_DOS = "MEM_PROGRESS_BASED_TO_DOS",
	MEM_PROMPT_LOW_BALANCE_USERS_TO_TAKE_OUT_ADVANCE = "MEM_PROMPT_LOW_BALANCE_USERS_TO_TAKE_OUT_ADVANCE",
	MEM_PROMPT_SUBSCRIPTION_REPAYMENT_POST_ADVANCE_DELIVERY = "MEM_PROMPT_SUBSCRIPTION_REPAYMENT_POST_ADVANCE_DELIVERY",
	MEM_PROMPT_SUB_REPAYMENT_AFTER_ADVANCE_MANUALLY_REPAID = "MEM_PROMPT_SUB_REPAYMENT_AFTER_ADVANCE_MANUALLY_REPAID",
	MEM_REMOVE_TO_DOS_WHEN_EMPTY = "MEM_REMOVE_TO_DOS_WHEN_EMPTY",
	MEM_REVENUE_SPRINT_NEW_USER_299_TRANSFER_FEE_TEST = "MEM_REVENUE_SPRINT_NEW_USER_299_TRANSFER_FEE_TEST",
	MKT_FIONA_INFRA_TRACKING_LINKS_AB_TEST = "MKT_FIONA_INFRA_TRACKING_LINKS_AB_TEST",
	MKT_TAX_SEASON_2025_AISLES_AND_CUSTOM_OFFER_COPY = "MKT_TAX_SEASON_2025_AISLES_AND_CUSTOM_OFFER_COPY",
	MULTIPLE_ADVANCES_TEST = "MULTIPLE_ADVANCES_TEST",
	MULTI_ITEM_LINKING_PHASE_1 = "MULTI_ITEM_LINKING_PHASE_1",
	NA_USER_HOME_SCREEN_SWITCH_BANK_TEST = "NA_USER_HOME_SCREEN_SWITCH_BANK_TEST",
	NA_USER_IC_LANDING_SWITCH_BANK_TEST = "NA_USER_IC_LANDING_SWITCH_BANK_TEST",
	NA_USER_IC_LANDING_SWITCH_BANK_TEST_EDUCATIONAL_UX = "NA_USER_IC_LANDING_SWITCH_BANK_TEST_EDUCATIONAL_UX",
	PAYROLL_CONNECT_PAINTED_DOOR_TEST_A = "PAYROLL_CONNECT_PAINTED_DOOR_TEST_A",
	PAYROLL_CONNECT_PAINTED_DOOR_TEST_BC = "PAYROLL_CONNECT_PAINTED_DOOR_TEST_BC",
	PERSONAL_LOANS_ON_E_AND_S_OFFER_TILE_POSITION_1_ABC_TEST = "PERSONAL_LOANS_ON_E_AND_S_OFFER_TILE_POSITION_1_ABC_TEST",
	PERSONAL_LOANS_SLIDER_WIDGET_AND_OFFER_CAROUSEL_ON_E_AND_S = "PERSONAL_LOANS_SLIDER_WIDGET_AND_OFFER_CAROUSEL_ON_E_AND_S",
	PERSONAL_LOANS_TAB = "PERSONAL_LOANS_TAB",
	PFM_CHATGPT = "PFM_CHATGPT",
	PL_SLIDER_WIDGET_AND_OFFER_CAROUSEL = "PL_SLIDER_WIDGET_AND_OFFER_CAROUSEL",
	PRICING_TEST_599_Q1_2024 = "PRICING_TEST_599_Q1_2024",
	PRICING_TEST_599_Q1_2025 = "PRICING_TEST_599_Q1_2025",
	PROMO_14D_DEEP_LINK_99C = "PROMO_14D_DEEP_LINK_99C",
	PROMO_ABANDONED_CART_FREE_MONTH = "PROMO_ABANDONED_CART_FREE_MONTH",
	PROMO_CASH_BACK_10 = "PROMO_CASH_BACK_10",
	REMOVE_I_WANT_TO_TEXT = "REMOVE_I_WANT_TO_TEXT",
	SCHEDULED_CARD_REPAYMENT_RETRY_STRATEGY = "SCHEDULED_CARD_REPAYMENT_RETRY_STRATEGY",
	SHOP_SMARTER_RENT_A_CENTER_AND_ACIMA = "SHOP_SMARTER_RENT_A_CENTER_AND_ACIMA",
	SHOW_NEW_HOME_SCREEN = "SHOW_NEW_HOME_SCREEN",
	SUBSCRIPTION_REPAYMENT_IN_APP_TEST = "SUBSCRIPTION_REPAYMENT_IN_APP_TEST",
	TABAPAY_ACCOUNT_UPDATER = "TABAPAY_ACCOUNT_UPDATER",
	TEST_EXPERIMENT_NAME = "TEST_EXPERIMENT_NAME",
	TEST_MARKETPLACE_SLIDER_WIDGET = "TEST_MARKETPLACE_SLIDER_WIDGET",
	TEST_SIGN_UP_DISPLAY_TEXT = "TEST_SIGN_UP_DISPLAY_TEXT",
	WEB_FUNNEL_HIDE_APP_STORE_LINKS_TEST = "WEB_FUNNEL_HIDE_APP_STORE_LINKS_TEST",
	WEB_FUNNEL_SET_NAME_ADD_CONTEXT_TEST = "WEB_FUNNEL_SET_NAME_ADD_CONTEXT_TEST",
	WEB_FUNNEL_SHOULD_DISPLAY_HOW_IT_WORKS_ONBOARDING_TEST = "WEB_FUNNEL_SHOULD_DISPLAY_HOW_IT_WORKS_ONBOARDING_TEST"
}
export enum FrontEndCreditMonitoringEnrollment {
	CREDIT_LOCK = "CREDIT_LOCK",
	IDENTITY_PROTECTION = "IDENTITY_PROTECTION",
	INSTANT_MONITORING = "INSTANT_MONITORING",
	STANDARD_MONITORING = "STANDARD_MONITORING"
}
export enum FrontendAccountApprovalState {
	APPROVED = "APPROVED",
	NOT_APPROVED = "NOT_APPROVED",
	NOT_RUN = "NOT_RUN"
}
export enum FrontendBankConnectionStatus {
	ACCOUNT_SELECTION_REQUIRED = "ACCOUNT_SELECTION_REQUIRED",
	CONNECTED = "CONNECTED",
	NEW_LOGIN_REQUIRED = "NEW_LOGIN_REQUIRED",
	RELINK_REQUIRED = "RELINK_REQUIRED"
}
export enum FrontendBankInstitution {
	BANK_OF_AMERICA = "BANK_OF_AMERICA",
	BBT = "BBT",
	BBVA = "BBVA",
	BRANCH = "BRANCH",
	CAPITAL_ONE = "CAPITAL_ONE",
	CHASE = "CHASE",
	CHIME = "CHIME",
	CITI = "CITI",
	CITIZENS = "CITIZENS",
	DAVE = "DAVE",
	FIDELITY = "FIDELITY",
	FIFTH_THIRD = "FIFTH_THIRD",
	FIRST_PLATYPUS_BANK = "FIRST_PLATYPUS_BANK",
	HUNTINGTON = "HUNTINGTON",
	KEYBANK = "KEYBANK",
	NAVY_FEDERAL = "NAVY_FEDERAL",
	PNC = "PNC",
	REGIONS = "REGIONS",
	SIMPLE = "SIMPLE",
	STATE_EMPLOYEE_CREDIT_UNION_NC = "STATE_EMPLOYEE_CREDIT_UNION_NC",
	SUNTRUST = "SUNTRUST",
	TD = "TD",
	USAA = "USAA",
	US_BANK = "US_BANK",
	VARO = "VARO",
	WELLS_FARGO = "WELLS_FARGO",
	WOODFOREST_NATIONAL = "WOODFOREST_NATIONAL"
}
export enum FrontendCBScheduleVersionReason {
	INCOME_CHANGE = "INCOME_CHANGE"
}
export enum FrontendCBScheduleVersionStatus {
	PUBLISHED = "PUBLISHED"
}
export enum FrontendCreditBuilderEnrollmentStatus {
	DISABLED = "DISABLED",
	DOES_NOT_EXIST = "DOES_NOT_EXIST",
	ENABLED = "ENABLED"
}
export enum FrontendCreditBuilderPaymentRetryStrategyType {
	FOUR_DAY_CONTINUOUS_CARD = "FOUR_DAY_CONTINUOUS_CARD",
	NONE = "NONE"
}
export enum FrontendCreditBuilderReminderType {
	BALANCE_PAST_DUE = "BALANCE_PAST_DUE",
	SAME_DAY_MANUAL_PAYMENT = "SAME_DAY_MANUAL_PAYMENT",
	UPCOMING_SCHEDULED_PAYMENT = "UPCOMING_SCHEDULED_PAYMENT"
}
export enum FrontendCreditBuilderSweepStakesQualificationType {
	DOES_NOT_QUALIFY = "DOES_NOT_QUALIFY",
	SWEEPSTAKES_CB_Q1_2025 = "SWEEPSTAKES_CB_Q1_2025",
	SWEEPSTAKES_CB_Q2_2024 = "SWEEPSTAKES_CB_Q2_2024",
	SWEEPSTAKES_CB_Q3_2024 = "SWEEPSTAKES_CB_Q3_2024",
	SWEEPSTAKES_CB_Q4_2024 = "SWEEPSTAKES_CB_Q4_2024"
}
export enum FrontendCreditBuilderTransactionSource {
	EXTERNAL = "EXTERNAL",
	SAVINGS = "SAVINGS"
}
export enum FrontendCreditBuilderTransactionStatus {
	FAILED = "FAILED",
	MISSED = "MISSED",
	PENDING = "PENDING",
	SUCCESS = "SUCCESS"
}
export enum FrontendCreditBuilderTransactionType {
	DISBURSAL = "DISBURSAL",
	PAYMENT = "PAYMENT"
}
export enum FrontendCreditLimitBoostStatus {
	ACCEPTED = "ACCEPTED",
	APPLIED = "APPLIED",
	REJECTED = "REJECTED"
}
export enum FrontendCreditRiskState {
	APPROVED = "APPROVED",
	NOT_APPROVED = "NOT_APPROVED"
}
export enum FrontendDebitCardStatus {
	BLOCKED = "BLOCKED",
	PENDING = "PENDING",
	PENDING_ID = "PENDING_ID",
	REQUIRES_ID = "REQUIRES_ID",
	UNVERIFIED = "UNVERIFIED",
	VERIFIED = "VERIFIED"
}
export enum FrontendInitiateCreditDrawStatus {
	DRAW_INITIATED = "DRAW_INITIATED"
}
export enum FrontendInstallmentLoanChargePolicy {
	AUTO = "AUTO",
	DOES_NOT_EXIST = "DOES_NOT_EXIST",
	MANUAL = "MANUAL"
}
export enum FrontendInstallmentLoanPaymentStrategy {
	INCOME = "INCOME",
	MONTHLY = "MONTHLY"
}
export enum FrontendInstallmentLoansAccountCreationStatus {
	CREATED = "CREATED",
	UNABLE_TO_DISBURSE_PRINCIPAL = "UNABLE_TO_DISBURSE_PRINCIPAL"
}
export enum FrontendInstallmentLoansAutoPay {
	DISABLED = "DISABLED",
	ENABLED = "ENABLED"
}
export enum FrontendInstallmentLoansCurrentStatus {
	CLOSED = "CLOSED",
	DOES_NOT_EXIST = "DOES_NOT_EXIST",
	OPEN = "OPEN"
}
export enum FrontendInstallmentLoansDisbursalStatus {
	CANCELLED = "CANCELLED",
	DELIVERED = "DELIVERED",
	RETURNED = "RETURNED",
	SCHEDULED = "SCHEDULED"
}
export enum FrontendInstallmentLoansKYCDecisionType {
	APPROVED = "APPROVED",
	UNAPPROVED = "UNAPPROVED"
}
export enum FrontendInstallmentLoansManualPaymentEligibilityStatus {
	ELIGIBILE = "ELIGIBILE",
	INELIGIBLE = "INELIGIBLE"
}
export enum FrontendInstallmentLoansPayFrequency {
	BIWEEKLY = "BIWEEKLY",
	MONTHLY = "MONTHLY",
	MONTHLY_WEEKDAY = "MONTHLY_WEEKDAY",
	SEMIMONTHLY = "SEMIMONTHLY",
	SSA = "SSA",
	WEEKLY = "WEEKLY"
}
export enum FrontendInstallmentLoansPaymentOutcome {
	FAILURE = "FAILURE",
	FAILURE_INSUFFICIENT_FUNDS = "FAILURE_INSUFFICIENT_FUNDS",
	SUCCESS = "SUCCESS"
}
export enum FrontendInstallmentLoansStatus {
	CANCELLED = "CANCELLED",
	CLOSED = "CLOSED",
	OPEN = "OPEN",
	PRE_CHARGE_OFF = "PRE_CHARGE_OFF",
	UNDELIVERED = "UNDELIVERED"
}
export enum FrontendInstallmentLoansUpdateAutoPayOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum FrontendLocAccountCreationStatus {
	CREATED = "CREATED"
}
export enum FrontendLocAccountStatus {
	CLOSED = "CLOSED",
	OPEN = "OPEN"
}
export enum FrontendLocAutopayStatus {
	DISABLED = "DISABLED",
	ENABLED = "ENABLED"
}
export enum FrontendLocInitiatePaymentStatus {
	PAYMENT_INITIATED = "PAYMENT_INITIATED",
	PAYMENT_INITIATION_ERROR = "PAYMENT_INITIATION_ERROR"
}
export enum FrontendLocKycRequirementStatus {
	FULFILLED = "FULFILLED",
	UNFULFILLED = "UNFULFILLED"
}
export enum FrontendLocKycUnfulfillmentReasons {
	ACCEPTED_SUBMISSION_STALE = "ACCEPTED_SUBMISSION_STALE",
	DAILY_SUBMISSION_LIMIT_REACHED = "DAILY_SUBMISSION_LIMIT_REACHED",
	SUBMISSION_DOES_NOT_EXIST = "SUBMISSION_DOES_NOT_EXIST",
	SUBMISSION_IS_INTERNAL_FRAUD = "SUBMISSION_IS_INTERNAL_FRAUD",
	SUBMISSION_IS_MLA_USER = "SUBMISSION_IS_MLA_USER",
	SUBMISSION_IS_NOT_ACCEPTED = "SUBMISSION_IS_NOT_ACCEPTED",
	SUBMISSION_MLA_USER_UNKNOWN = "SUBMISSION_MLA_USER_UNKNOWN"
}
export enum FrontendLocMinPaymentStatus {
	DUE = "DUE",
	NOT_DUE = "NOT_DUE",
	PAST_DUE = "PAST_DUE"
}
export enum FrontendLocPaymentAttemptType {
	AUTOMATIC = "AUTOMATIC",
	MANUAL = "MANUAL"
}
export enum FrontendLocUnderwritingStatus {
	APPROVED = "APPROVED",
	UNAPPROVED = "UNAPPROVED"
}
export enum FrontendLocUserInitiatedPaymentChargeType {
	FULL_BALANCE = "FULL_BALANCE",
	MINIMUM_BALANCE = "MINIMUM_BALANCE",
	STATEMENT = "STATEMENT"
}
export enum FrontendMobileVersionSupportType {
	FORCE_UPGRADE = "FORCE_UPGRADE",
	NUDGE_UPGRADE = "NUDGE_UPGRADE",
	SUPPORTED = "SUPPORTED",
	UNKNOWN = "UNKNOWN"
}
export enum FrontendOngoingRefreshStatus {
	DISABLED = "DISABLED",
	ENABLED = "ENABLED",
	IDLE = "IDLE"
}
export enum FrontendPaymentAuthFlow {
	INSTANT_AUTH = "INSTANT_AUTH",
	INSTANT_MATCH = "INSTANT_MATCH",
	UNKNOWN = "UNKNOWN"
}
export enum FrontendPaymentStatus {
	CANCELLED = "CANCELLED",
	PROCESSED = "PROCESSED",
	PROCESSING_FAILED = "PROCESSING_FAILED",
	RETURNED = "RETURNED",
	SCHEDULED = "SCHEDULED",
	SETTLED = "SETTLED"
}
export enum FrontendPayrollAccountConnectionStatus {
	CONNECTED = "CONNECTED",
	CONNECTING = "CONNECTING",
	ERROR = "ERROR",
	NOT_CONNECTED = "NOT_CONNECTED"
}
export enum FrontendReturnType {
	ACCOUNT_CLOSED = "ACCOUNT_CLOSED",
	ACCOUNT_FROZEN = "ACCOUNT_FROZEN",
	INCORRECT_INFO = "INCORRECT_INFO",
	INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
	OTHER = "OTHER",
	USER_BLOCKED = "USER_BLOCKED"
}
export enum FrontendSampleKeyType {
	DEVICE_ID = "DEVICE_ID",
	USER_ID = "USER_ID"
}
export enum HeroIconSize {
	LARGE = "LARGE",
	MEDIUM = "MEDIUM",
	SMALL = "SMALL"
}
export enum IdentityProtectionEnrollmentStatus {
	DISABLED = "DISABLED",
	DOES_NOT_EXIST = "DOES_NOT_EXIST",
	ENABLED = "ENABLED"
}
export enum ImpressionableElement {
	MARKETPLACE_HOME_SCREEN_PROMO_CARD_V1 = "MARKETPLACE_HOME_SCREEN_PROMO_CARD_V1",
	MARKETPLACE_HOME_SCREEN_PROMO_CARD_V2 = "MARKETPLACE_HOME_SCREEN_PROMO_CARD_V2",
	MARKETPLACE_OFFER_TILE = "MARKETPLACE_OFFER_TILE"
}
export enum InputSalaryDataOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum KeyboardType {
	DECIMAL_PAD = "DECIMAL_PAD",
	DEFAULT = "DEFAULT",
	EMAIL_ADDRESS = "EMAIL_ADDRESS",
	NUMBER_PAD = "NUMBER_PAD",
	NUMERIC = "NUMERIC",
	PHONE_PAD = "PHONE_PAD"
}
export enum LoanPurpose {
	AUTO = "AUTO",
	AUTO_PURCHASE = "AUTO_PURCHASE",
	AUTO_REFINANCE = "AUTO_REFINANCE",
	BABY = "BABY",
	BOAT = "BOAT",
	BUSINESS = "BUSINESS",
	CAR_REPAIR = "CAR_REPAIR",
	COSMETIC = "COSMETIC",
	CREDIT_CARD_REFI = "CREDIT_CARD_REFI",
	DEBT_CONSOLIDATION = "DEBT_CONSOLIDATION",
	EMERGENCY = "EMERGENCY",
	ENGAGEMENT = "ENGAGEMENT",
	GREEN = "GREEN",
	HOME_IMPROVEMENT = "HOME_IMPROVEMENT",
	HOME_PURCHASE = "HOME_PURCHASE",
	HOME_REFI = "HOME_REFI",
	HOUSEHOLD_EXPENSES = "HOUSEHOLD_EXPENSES",
	LARGE_PURCHASES = "LARGE_PURCHASES",
	LIFE_EVENT = "LIFE_EVENT",
	MEDICAL_DENTAL = "MEDICAL_DENTAL",
	MOTORCYCLE = "MOTORCYCLE",
	MOVING_RELOCATION = "MOVING_RELOCATION",
	OTHER = "OTHER",
	RV = "RV",
	SPECIAL_OCCASION = "SPECIAL_OCCASION",
	STUDENT_LOAN = "STUDENT_LOAN",
	STUDENT_LOAN_REFI = "STUDENT_LOAN_REFI",
	TAXES = "TAXES",
	UNKNOWN = "UNKNOWN",
	VACATION = "VACATION",
	WEDDING = "WEDDING"
}
export enum LoanTimelineStatus {
	FAILED = "FAILED",
	IN_PROGRESS = "IN_PROGRESS",
	SUCCESS = "SUCCESS",
	UPCOMING = "UPCOMING"
}
export enum LoanTimelineType {
	DELIVERED = "DELIVERED",
	IN_TRANSIT = "IN_TRANSIT",
	PAYMENT = "PAYMENT",
	REPAID = "REPAID",
	SENT = "SENT",
	SETTLEMENT = "SETTLEMENT",
	STILL_DUE = "STILL_DUE",
	USED = "USED"
}
export enum LoginFailureReason {
	EMAIL_NOT_IN_USE_EXCEPTION = "EMAIL_NOT_IN_USE_EXCEPTION",
	PHONE_NOT_IN_USE_EXCEPTION = "PHONE_NOT_IN_USE_EXCEPTION",
	RECAPTCHA_FAILED_EXCEPTION = "RECAPTCHA_FAILED_EXCEPTION",
	RECAPTCHA_TOKEN_NOT_PRESENT = "RECAPTCHA_TOKEN_NOT_PRESENT",
	UNKNOWN_EXCEPTION = "UNKNOWN_EXCEPTION",
	UNUSABLE_USER_ACCOUNT_EXCEPTION = "UNUSABLE_USER_ACCOUNT_EXCEPTION"
}
export enum MarkNotificationCenterEntriesViewedOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum MarketplaceBadgeType {
	NEW = "NEW",
	POPULAR = "POPULAR"
}
export enum MarketplaceFeature {
	ENGINE_PERSONAL_LOANS = "ENGINE_PERSONAL_LOANS"
}
export enum ModelRank {
	HIGH = "HIGH",
	LOW = "LOW",
	MEDIUM = "MEDIUM"
}
export enum NonRepaymentReason {
	BANKRUPT = "BANKRUPT",
	CEASE_AND_DESIST = "CEASE_AND_DESIST",
	CREDIT_BUILDER_FRAUD = "CREDIT_BUILDER_FRAUD",
	DECEASED = "DECEASED",
	DELETED_USER = "DELETED_USER",
	EXECUTION_ERROR = "EXECUTION_ERROR",
	INABILITY_TO_PAY = "INABILITY_TO_PAY",
	NON_INTENT_TO_PAY = "NON_INTENT_TO_PAY",
	USER_COMPLAINT = "USER_COMPLAINT"
}
export enum OneTimePaymentOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum OngoingRefreshStatus {
	DISABLED = "DISABLED",
	ENABLED = "ENABLED",
	IDLE = "IDLE"
}
export enum OverdraftLikelihood {
	LIKELY = "LIKELY",
	NOW = "NOW",
	POTENTIAL = "POTENTIAL",
	UNLIKELY = "UNLIKELY"
}
export enum PayFrequency {
	BIWEEKLY = "BIWEEKLY",
	IRREGULAR = "IRREGULAR",
	MONTHLY = "MONTHLY",
	MONTHLY_WEEKDAY = "MONTHLY_WEEKDAY",
	SEMIMONTHLY = "SEMIMONTHLY",
	SSA = "SSA",
	WEEKLY = "WEEKLY"
}
export enum PayrollAccountConnectionStatus {
	CONNECTED = "CONNECTED",
	CONNECTING = "CONNECTING",
	ERROR = "ERROR",
	NOT_CONNECTED = "NOT_CONNECTED"
}
export enum PayrollAccountFetcherOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum PayrollProvider {
	ARGYLE = "ARGYLE",
	IN_MEMORY = "IN_MEMORY"
}
export enum PersistedIncomeStatus {
	BLACKLISTED = "BLACKLISTED",
	PENDING = "PENDING",
	UNVERIFIABLE = "UNVERIFIABLE",
	VERIFIED = "VERIFIED"
}
export enum PremiumUpsellCardVariant {
	NON_PROMO = "NON_PROMO",
	PROMO_1 = "PROMO_1",
	PROMO_2 = "PROMO_2",
	PROMO_3 = "PROMO_3"
}
export enum Product {
	ACCOUNT = "ACCOUNT",
	CASH_ADVANCE = "CASH_ADVANCE",
	CREDIT_BUILDER = "CREDIT_BUILDER",
	CREDIT_MONITORING = "CREDIT_MONITORING",
	DEFAULT = "DEFAULT",
	FINANCIAL_INSIGHTS = "FINANCIAL_INSIGHTS",
	IDENTITY_PROTECTION = "IDENTITY_PROTECTION",
	MARKETPLACE = "MARKETPLACE",
	PERSONAL_FINANCE_MANAGEMENT = "PERSONAL_FINANCE_MANAGEMENT"
}
export enum ProductSubType {
	ACCIDENTAL_DEATH_BENEFITS = "ACCIDENTAL_DEATH_BENEFITS",
	AUTOMOBILE_REFINANCE = "AUTOMOBILE_REFINANCE",
	CASH_ADVANCE = "CASH_ADVANCE",
	CASH_MANAGEMENT_ACCOUNT = "CASH_MANAGEMENT_ACCOUNT",
	CERTIFICATE_OF_DEPOSIT = "CERTIFICATE_OF_DEPOSIT",
	CHECKING = "CHECKING",
	CO_APPLICANT_LOAN = "CO_APPLICANT_LOAN",
	CREDIT_BUILDER = "CREDIT_BUILDER",
	CREDIT_CARD = "CREDIT_CARD",
	CREDIT_REPAIR = "CREDIT_REPAIR",
	DEBT_RELIEF = "DEBT_RELIEF",
	DIRECT_AFFILIATE = "DIRECT_AFFILIATE",
	EDUCATION_OFFERS = "EDUCATION_OFFERS",
	EMPLOYMENT_OPPORTUNITY = "EMPLOYMENT_OPPORTUNITY",
	FINANCIAL_WELLNESS = "FINANCIAL_WELLNESS",
	HIGH_INTEREST_CHECKING = "HIGH_INTEREST_CHECKING",
	HOME_EQUITY_LINE_OF_CREDIT = "HOME_EQUITY_LINE_OF_CREDIT",
	INDIVIDUAL_RETIREMENT_ACCOUNT = "INDIVIDUAL_RETIREMENT_ACCOUNT",
	INSTALLMENT_LOANS = "INSTALLMENT_LOANS",
	INSTALLMENT_LOAN_MORE_OFFERS = "INSTALLMENT_LOAN_MORE_OFFERS",
	LINE_OF_CREDIT = "LINE_OF_CREDIT",
	MONEY_MARKET_ACCOUNT = "MONEY_MARKET_ACCOUNT",
	OVERDRAFT_PROTECTION = "OVERDRAFT_PROTECTION",
	PERSONAL_LOAN = "PERSONAL_LOAN",
	PURCHASE = "PURCHASE",
	REFINANCE = "REFINANCE",
	REVENUE_BASED_FINANCING = "REVENUE_BASED_FINANCING",
	SAVINGS_ACCOUNT = "SAVINGS_ACCOUNT",
	SECURED_CARD = "SECURED_CARD",
	SECURED_LOAN = "SECURED_LOAN",
	STUDENT_LOAN_REFINANCE = "STUDENT_LOAN_REFINANCE",
	TERM_LIFE = "TERM_LIFE",
	TERM_LIFE_INSTANT = "TERM_LIFE_INSTANT",
	UNCATEGORIZED = "UNCATEGORIZED",
	WHOLE_LIFE = "WHOLE_LIFE"
}
export enum PromptResponseType {
	CONFIRMED = "CONFIRMED",
	DISMISSED = "DISMISSED",
	REJECTED = "REJECTED"
}
export enum PropertyStatus {
	OWN_OUTRIGHT = "OWN_OUTRIGHT",
	OWN_WITH_MORTGAGE = "OWN_WITH_MORTGAGE",
	RENT = "RENT"
}
export enum ProtectionMediumType {
	CASH_ADVANCE = "CASH_ADVANCE",
	CREDIT = "CREDIT",
	DEFAULT = "DEFAULT"
}
export enum ProtectionStatusV4 {
	CONTINUOUS_RETRY = "CONTINUOUS_RETRY",
	DELIVERED = "DELIVERED",
	DISBURSED = "DISBURSED",
	DUE_DATE_CHANGED = "DUE_DATE_CHANGED",
	DUE_DATE_EXTENDED = "DUE_DATE_EXTENDED",
	FAILED_PAYMENT = "FAILED_PAYMENT",
	INSTALLMENT_PLAN_IN_PROGRESS = "INSTALLMENT_PLAN_IN_PROGRESS",
	PAST_DUE = "PAST_DUE",
	PAYMENT_PROCESSING = "PAYMENT_PROCESSING",
	RETRIEVED = "RETRIEVED",
	SPENT = "SPENT",
	USED = "USED",
	WARNING = "WARNING"
}
export enum QuizFeedbackType {
	NUMERIC_1_TO_5 = "NUMERIC_1_TO_5"
}
export enum QuizName {
	CREDIT_BUILDER_DEBT_QUIZ = "CREDIT_BUILDER_DEBT_QUIZ",
	QUIZ_EXAMPLE = "QUIZ_EXAMPLE"
}
export enum QuizStepType {
	FREE_TEXT = "FREE_TEXT",
	INFORMATION = "INFORMATION",
	SELECT = "SELECT"
}
export enum RecentScoreChangeText {
	HOLD_TIGHT = "HOLD_TIGHT",
	NO_CHANGE = "NO_CHANGE",
	PAYMENT_HISTORY_IMPACTING = "PAYMENT_HISTORY_IMPACTING",
	PROBLEM_ACCOUNTS_IMPACTING = "PROBLEM_ACCOUNTS_IMPACTING",
	SCORE_INCREASE = "SCORE_INCREASE"
}
export enum RegisterBankLoginMaintainingExistingLoginOutcome {
	INSUFFICIENT_BANK_PERMISSIONS = "INSUFFICIENT_BANK_PERMISSIONS",
	MUST_SWITCH_TO_SAME_ACCOUNT = "MUST_SWITCH_TO_SAME_ACCOUNT",
	NON_MATCHING_BANK_LOGIN = "NON_MATCHING_BANK_LOGIN",
	NO_CHECKING_ACCOUNTS = "NO_CHECKING_ACCOUNTS",
	SUCCESS = "SUCCESS",
	UNSUPPORTED_INSTITUTION = "UNSUPPORTED_INSTITUTION"
}
export enum RepayAdvanceOutcome {
	FAILURE = "FAILURE",
	FAILURE_ADVANCE_ALREADY_RETRIEVED = "FAILURE_ADVANCE_ALREADY_RETRIEVED",
	FAILURE_ADVANCE_REPAYMENT = "FAILURE_ADVANCE_REPAYMENT",
	FAILURE_IMMEDIATE_WITHDRAWAL_INSUFFICIENT_FUNDS = "FAILURE_IMMEDIATE_WITHDRAWAL_INSUFFICIENT_FUNDS",
	FAILURE_IMMEDIATE_WITHDRAWAL_REPAID = "FAILURE_IMMEDIATE_WITHDRAWAL_REPAID",
	FAILURE_IMMEDIATE_WITHDRAWAL_RETURN = "FAILURE_IMMEDIATE_WITHDRAWAL_RETURN",
	FAILURE_LOAN_NOT_FOUND = "FAILURE_LOAN_NOT_FOUND",
	FAILURE_RECOVERY_PAYMENT_ALREADY_SCHEDULED = "FAILURE_RECOVERY_PAYMENT_ALREADY_SCHEDULED",
	FAILURE_REPAYMENT_DAILY_VOLUME_LIMIT = "FAILURE_REPAYMENT_DAILY_VOLUME_LIMIT",
	SUCCESS = "SUCCESS"
}
export enum RequestManualDisbursalOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum ResendAuthVerificationCodeOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum SalaryAggregateFilterCategory {
	COMPANY = "COMPANY",
	EXPERIENCE_RANGE = "EXPERIENCE_RANGE",
	GENDER = "GENDER",
	LOCATION = "LOCATION"
}
export enum SamplingTrigger {
	ACTIVATION_FLOW_INITIATED = "ACTIVATION_FLOW_INITIATED",
	ACTIVATION_INTEREST = "ACTIVATION_INTEREST",
	ADMIN_OVERRIDE = "ADMIN_OVERRIDE",
	APP_OPENED = "APP_OPENED",
	BANK_CONNECTED = "BANK_CONNECTED",
	CB_INITIATING_KYC = "CB_INITIATING_KYC",
	CB_INSTALLMENT_PAYMENT_INITIATED = "CB_INSTALLMENT_PAYMENT_INITIATED",
	CB_ONBOARDING_COMPLETED = "CB_ONBOARDING_COMPLETED",
	CB_ONBOARDING_STARTED = "CB_ONBOARDING_STARTED",
	CB_PAYMENT_RETRY_INITIATED = "CB_PAYMENT_RETRY_INITIATED",
	CB_PAYMENT_SCHEDULE_CHANGE_INITIATED = "CB_PAYMENT_SCHEDULE_CHANGE_INITIATED",
	CB_SAVINGS_DISBURSAL_INITIATED = "CB_SAVINGS_DISBURSAL_INITIATED",
	DEACTIVATION_SURVEY_ANSWERED = "DEACTIVATION_SURVEY_ANSWERED",
	HOME_SCREEN_LOADED = "HOME_SCREEN_LOADED",
	IC_DYNAMIC_PRE_ACTIVATION_EVALUATED = "IC_DYNAMIC_PRE_ACTIVATION_EVALUATED",
	IC_INCOME_EVALUATED = "IC_INCOME_EVALUATED",
	IC_LANDING_SCREEN_LOADED = "IC_LANDING_SCREEN_LOADED",
	IC_LOAN_PAYMENT_DISBURSED = "IC_LOAN_PAYMENT_DISBURSED",
	IC_LOAN_REPAYMENT_INITIATED = "IC_LOAN_REPAYMENT_INITIATED",
	IC_USER_EVALUATED = "IC_USER_EVALUATED",
	MANUAL_REPAYMENT_SCREEN_LOADED = "MANUAL_REPAYMENT_SCREEN_LOADED",
	MARKETPLACE_OFFERS_RETRIEVED = "MARKETPLACE_OFFERS_RETRIEVED",
	MIGRATE_USERS_TO_HIGHER_TRANSFER_FEE_TASK_RUN = "MIGRATE_USERS_TO_HIGHER_TRANSFER_FEE_TASK_RUN",
	NAVIGATED_TO_CREDIT_TAB = "NAVIGATED_TO_CREDIT_TAB",
	ON_THE_WAY_SCREEN_LOADED = "ON_THE_WAY_SCREEN_LOADED",
	PAYMENT_PROCESSING_ATTEMPTED = "PAYMENT_PROCESSING_ATTEMPTED",
	REFERRAL_LINK_LOADED = "REFERRAL_LINK_LOADED",
	REPAYABLE_SUBSCRIPTION_PAYMENT_SKIPPED_OR_FAILED = "REPAYABLE_SUBSCRIPTION_PAYMENT_SKIPPED_OR_FAILED",
	SIGN_UP_COMPLETED = "SIGN_UP_COMPLETED",
	SUBSCRIPTION_DUE_IN_15_OR_MORE_DAYS = "SUBSCRIPTION_DUE_IN_15_OR_MORE_DAYS",
	SUBSCRIPTION_PAYMENTS_FETCHED = "SUBSCRIPTION_PAYMENTS_FETCHED",
	SUBSCRIPTION_PLANS_SCREEN_LOADED = "SUBSCRIPTION_PLANS_SCREEN_LOADED",
	SUBSCRIPTION_TIERS_RETRIEVED = "SUBSCRIPTION_TIERS_RETRIEVED",
	SUBSCRIPTION_TIER_LEVEL_CHANGED = "SUBSCRIPTION_TIER_LEVEL_CHANGED",
	SWITCH_BANK_INITIATED = "SWITCH_BANK_INITIATED",
	TABAPAY_ACCOUNT_UPDATER_FLOW = "TABAPAY_ACCOUNT_UPDATER_FLOW",
	USER_SIGN_UP = "USER_SIGN_UP",
	WEB_BANK_CONNECT_PROGRESS = "WEB_BANK_CONNECT_PROGRESS",
	WEB_PHONE_AUTH_COMPLETED = "WEB_PHONE_AUTH_COMPLETED"
}
export enum ScheduleCreditBuilderSameDayManualPaymentOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum ScoreChangeCallToAction {
	MAKE_CATCHUP_PAYMENT = "MAKE_CATCHUP_PAYMENT",
	VIEW_CREDIT_REPORT = "VIEW_CREDIT_REPORT"
}
export enum SubmitUserPromptOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum SubscriptionFeeSchedule {
	FIRST_FRIDAY_EVERY_MONTH = "FIRST_FRIDAY_EVERY_MONTH",
	FIRST_PAYDAY = "FIRST_PAYDAY",
	FIXED_DAY = "FIXED_DAY",
	FORWARD_BILLING = "FORWARD_BILLING",
	LAST_FRIDAY_EVERY_MONTH = "LAST_FRIDAY_EVERY_MONTH",
	LAST_PAYDAY = "LAST_PAYDAY"
}
export enum SubscriptionStatus {
	CANCELLED = "CANCELLED",
	DOWNGRADED = "DOWNGRADED",
	FAILED = "FAILED",
	PENDING = "PENDING",
	PROCESSED = "PROCESSED",
	PROCESSING = "PROCESSING",
	REFUNDED = "REFUNDED",
	SKIPPED = "SKIPPED",
	SUCCESS = "SUCCESS",
	UPGRADED = "UPGRADED"
}
export enum SubscriptionTierExperimentName {
	TIERED_PRICING_ACTIVATION_TABBED_VS_SINGLE_PRODUCT = "TIERED_PRICING_ACTIVATION_TABBED_VS_SINGLE_PRODUCT"
}
export enum SubscriptionTierLevel {
	FREE = "FREE",
	LEGACY = "LEGACY",
	PLUS = "PLUS",
	PREMIUM = "PREMIUM",
	PRO = "PRO"
}
export enum SwitchProtectedLoginToExistingLoginOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum SwitchProtectedLoginToExistingNonConnectedLoginOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum TablessMarketplaceCategoryType {
	BOOST_MY_TAX_REFUND = "BOOST_MY_TAX_REFUND",
	BUILD_MY_CREDIT = "BUILD_MY_CREDIT",
	CAR_SAVINGS = "CAR_SAVINGS",
	CUT_CAR_COSTS = "CUT_CAR_COSTS",
	DEBT_RELIEF = "DEBT_RELIEF",
	EARN_QUICK_CASH = "EARN_QUICK_CASH",
	EXPLORE_OTHER_TOOLS = "EXPLORE_OTHER_TOOLS",
	FILE_TAXES = "FILE_TAXES",
	FIND_A_JOB_OR_SIDE_GIG = "FIND_A_JOB_OR_SIDE_GIG",
	GET_A_LOAN = "GET_A_LOAN",
	INVEST_MY_MONEY = "INVEST_MY_MONEY",
	LOWER_CAR_COSTS = "LOWER_CAR_COSTS",
	SAVE_MONEY = "SAVE_MONEY",
	SHOP_CAR_INSURANCE = "SHOP_CAR_INSURANCE",
	SHOP_SMARTER = "SHOP_SMARTER",
	UNSUPPORTED = "UNSUPPORTED"
}
export enum TextFormatter {
	DATE = "DATE",
	DOLLAR_AMOUNT = "DOLLAR_AMOUNT",
	SSN = "SSN"
}
export enum TextInputValidators {
	REQUIRED = "REQUIRED",
	VALID_DATE = "VALID_DATE",
	VALID_EMAIL = "VALID_EMAIL",
	VALID_PHONE_NUMBER = "VALID_PHONE_NUMBER",
	VALID_SSN = "VALID_SSN"
}
export enum TextInputWarning {
	EMAIL_SUGGESTION_WARNING = "EMAIL_SUGGESTION_WARNING"
}
export enum TrackNewImpressionsOutcome {
	FAILED_TO_PARSE_FOREIGN_KEYS_JSON = "FAILED_TO_PARSE_FOREIGN_KEYS_JSON",
	IMPRESSIONS_CLIENT_FAILURE = "IMPRESSIONS_CLIENT_FAILURE",
	SUCCESS = "SUCCESS"
}
export enum TrackNotificationCenterEntryClickOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum UXPromptResponseType {
	AVAILABLE = "AVAILABLE",
	CANCELLED = "CANCELLED",
	CONFIRMED = "CONFIRMED",
	DISMISSED = "DISMISSED",
	REJECTED = "REJECTED"
}
export enum UnderwritingCategory {
	ACTION_REQUIRED = "ACTION_REQUIRED",
	BANK_REQUIREMENT = "BANK_REQUIREMENT",
	INCOME_REQUIREMENT = "INCOME_REQUIREMENT",
	NON_USER_FACING_REQUIREMENT = "NON_USER_FACING_REQUIREMENT",
	REGULATORY_COMPLIANCE = "REGULATORY_COMPLIANCE"
}
export enum UnderwritingRule {
	ACCOUNT_IN_REVIEW = "ACCOUNT_IN_REVIEW",
	ACCOUNT_TRANSFER_STATUS = "ACCOUNT_TRANSFER_STATUS",
	ALREADY_PROTECTED_ACCOUNT = "ALREADY_PROTECTED_ACCOUNT",
	ALREADY_PROTECTED_USER = "ALREADY_PROTECTED_USER",
	BALANCE_GREATER_THAN = "BALANCE_GREATER_THAN",
	BLACKLISTED_INSTITUTION = "BLACKLISTED_INSTITUTION",
	CREDIT_LIMIT_EXPANSION = "CREDIT_LIMIT_EXPANSION",
	CREDIT_LIMIT_EXPANSION_MINIMUM_SCORE = "CREDIT_LIMIT_EXPANSION_MINIMUM_SCORE",
	DEBIT_COUNT_Z = "DEBIT_COUNT_Z",
	FORCE_REJECTION = "FORCE_REJECTION",
	INCOME_VOLATILITY_CUTOFF = "INCOME_VOLATILITY_CUTOFF",
	INFLOW_SUM_35D = "INFLOW_SUM_35D",
	JOINT_ACCOUNT = "JOINT_ACCOUNT",
	LAST_3_MONTH_LOWEST_WATER_MARK_GREATER_THAN = "LAST_3_MONTH_LOWEST_WATER_MARK_GREATER_THAN",
	LOGIN_REQUIRED = "LOGIN_REQUIRED",
	MFA_UNSUPPORTED = "MFA_UNSUPPORTED",
	MINIMUM_AFTER_PAYDAY_BALANCE = "MINIMUM_AFTER_PAYDAY_BALANCE",
	MINIMUM_HISTORY_DAYS_V2 = "MINIMUM_HISTORY_DAYS_V2",
	MINIMUM_INCOME_PER_MONTH = "MINIMUM_INCOME_PER_MONTH",
	MINIMUM_OBSERVED_PAYCHECKS = "MINIMUM_OBSERVED_PAYCHECKS",
	MINIMUM_PAYCHECK_SIZE = "MINIMUM_PAYCHECK_SIZE",
	MINIMUM_PAYDAY_EOD_BALANCE = "MINIMUM_PAYDAY_EOD_BALANCE",
	NO_ACCOUNTS = "NO_ACCOUNTS",
	NO_ACTIVITY_RATE = "NO_ACTIVITY_RATE",
	OUTSTANDING_LOAN = "OUTSTANDING_LOAN",
	PAYROLL_CONNECT = "PAYROLL_CONNECT",
	POSITIVE_CASH_FLOW = "POSITIVE_CASH_FLOW",
	PROTECTED_ACCOUNT_IS_SAVINGS = "PROTECTED_ACCOUNT_IS_SAVINGS",
	UNABLE_TO_FETCH_AUTH = "UNABLE_TO_FETCH_AUTH",
	UNDELIVERABLE_LOAN = "UNDELIVERABLE_LOAN",
	UNSUPPORTED_STATE = "UNSUPPORTED_STATE",
	UNVERIFIED_IDENTITY = "UNVERIFIED_IDENTITY",
	V10_BALANCE_GREATER_THAN = "V10_BALANCE_GREATER_THAN",
	V10_BLACKLISTED_INSTITUTION = "V10_BLACKLISTED_INSTITUTION",
	V10_MINIMUM_AFTER_PAYDAY_BALANCE = "V10_MINIMUM_AFTER_PAYDAY_BALANCE",
	V10_MINIMUM_HISTORY_DAYS = "V10_MINIMUM_HISTORY_DAYS",
	V10_MINIMUM_INCOME_PER_MONTH = "V10_MINIMUM_INCOME_PER_MONTH",
	V10_MINIMUM_OBSERVED_PAYCHECKS = "V10_MINIMUM_OBSERVED_PAYCHECKS",
	V10_MINIMUM_PAYCHECK_SIZE = "V10_MINIMUM_PAYCHECK_SIZE",
	V10_MINIMUM_PAYDAY_EOD_BALANCE = "V10_MINIMUM_PAYDAY_EOD_BALANCE",
	V1_MINIMUM_INCOME = "V1_MINIMUM_INCOME",
	V1_MINIMUM_OBSERVED_PAYCHECKS = "V1_MINIMUM_OBSERVED_PAYCHECKS",
	V2_MINIMUM_HISTORY_DAYS = "V2_MINIMUM_HISTORY_DAYS",
	V2_MINIMUM_INCOME = "V2_MINIMUM_INCOME",
	V2_MINIMUM_OBSERVED_PAYCHECKS = "V2_MINIMUM_OBSERVED_PAYCHECKS",
	V3_BALANCE_GREATER_THAN_NEGATIVE_LOAN = "V3_BALANCE_GREATER_THAN_NEGATIVE_LOAN",
	V3_MINIMUM_HISTORY_DAYS = "V3_MINIMUM_HISTORY_DAYS",
	V3_MINIMUM_INCOME = "V3_MINIMUM_INCOME",
	V3_MINIMUM_OBSERVED_PAYCHECKS = "V3_MINIMUM_OBSERVED_PAYCHECKS",
	V4_BALANCE_GREATER_THAN_NEGATIVE_LOAN = "V4_BALANCE_GREATER_THAN_NEGATIVE_LOAN",
	V4_BLACKLISTED_INSTITUTION = "V4_BLACKLISTED_INSTITUTION",
	V4_MINIMUM_HISTORY_DAYS = "V4_MINIMUM_HISTORY_DAYS",
	V4_MINIMUM_INCOME_PER_MONTH = "V4_MINIMUM_INCOME_PER_MONTH",
	V4_MINIMUM_OBSERVED_PAYCHECKS = "V4_MINIMUM_OBSERVED_PAYCHECKS",
	V5_BALANCE_GREATER_THAN_NEGATIVE_LOAN = "V5_BALANCE_GREATER_THAN_NEGATIVE_LOAN",
	V5_BLACKLISTED_INSTITUTION = "V5_BLACKLISTED_INSTITUTION",
	V5_MINIMUM_HISTORY_DAYS = "V5_MINIMUM_HISTORY_DAYS",
	V5_MINIMUM_INCOME_PER_MONTH = "V5_MINIMUM_INCOME_PER_MONTH",
	V5_MINIMUM_OBSERVED_PAYCHECKS = "V5_MINIMUM_OBSERVED_PAYCHECKS",
	V5_MINIMUM_PAYDAY_EOD_BALANCE = "V5_MINIMUM_PAYDAY_EOD_BALANCE",
	V6_BALANCE_GREATER_THAN_NEGATIVE_LOAN = "V6_BALANCE_GREATER_THAN_NEGATIVE_LOAN",
	V6_BLACKLISTED_INSTITUTION = "V6_BLACKLISTED_INSTITUTION",
	V6_MINIMUM_HISTORY_DAYS = "V6_MINIMUM_HISTORY_DAYS",
	V6_MINIMUM_INCOME_PER_MONTH = "V6_MINIMUM_INCOME_PER_MONTH",
	V6_MINIMUM_OBSERVED_PAYCHECKS = "V6_MINIMUM_OBSERVED_PAYCHECKS",
	V6_MINIMUM_PAYCHECK_SIZE = "V6_MINIMUM_PAYCHECK_SIZE",
	V6_MINIMUM_PAYDAY_EOD_BALANCE = "V6_MINIMUM_PAYDAY_EOD_BALANCE",
	V7_BALANCE_GREATER_THAN_NEGATIVE_LOAN = "V7_BALANCE_GREATER_THAN_NEGATIVE_LOAN",
	V7_BLACKLISTED_INSTITUTION = "V7_BLACKLISTED_INSTITUTION",
	V7_MINIMUM_HISTORY_DAYS = "V7_MINIMUM_HISTORY_DAYS",
	V7_MINIMUM_INCOME_PER_MONTH = "V7_MINIMUM_INCOME_PER_MONTH",
	V7_MINIMUM_OBSERVED_PAYCHECKS = "V7_MINIMUM_OBSERVED_PAYCHECKS",
	V7_MINIMUM_PAYCHECK_SIZE = "V7_MINIMUM_PAYCHECK_SIZE",
	V7_MINIMUM_PAYDAY_EOD_BALANCE = "V7_MINIMUM_PAYDAY_EOD_BALANCE",
	V8_BALANCE_GREATER_THAN_NEGATIVE_LOAN = "V8_BALANCE_GREATER_THAN_NEGATIVE_LOAN",
	V8_BLACKLISTED_INSTITUTION = "V8_BLACKLISTED_INSTITUTION",
	V8_MINIMUM_AFTER_PAYDAY_BALANCE = "V8_MINIMUM_AFTER_PAYDAY_BALANCE",
	V8_MINIMUM_HISTORY_DAYS = "V8_MINIMUM_HISTORY_DAYS",
	V8_MINIMUM_INCOME_PER_MONTH = "V8_MINIMUM_INCOME_PER_MONTH",
	V8_MINIMUM_OBSERVED_PAYCHECKS = "V8_MINIMUM_OBSERVED_PAYCHECKS",
	V8_MINIMUM_PAYCHECK_SIZE = "V8_MINIMUM_PAYCHECK_SIZE",
	V8_MINIMUM_PAYDAY_EOD_BALANCE = "V8_MINIMUM_PAYDAY_EOD_BALANCE",
	V9_BALANCE_GREATER_THAN = "V9_BALANCE_GREATER_THAN",
	V9_BLACKLISTED_INSTITUTION = "V9_BLACKLISTED_INSTITUTION",
	V9_MINIMUM_AFTER_PAYDAY_BALANCE = "V9_MINIMUM_AFTER_PAYDAY_BALANCE",
	V9_MINIMUM_HISTORY_DAYS = "V9_MINIMUM_HISTORY_DAYS",
	V9_MINIMUM_INCOME_PER_MONTH = "V9_MINIMUM_INCOME_PER_MONTH",
	V9_MINIMUM_OBSERVED_PAYCHECKS = "V9_MINIMUM_OBSERVED_PAYCHECKS",
	V9_MINIMUM_PAYCHECK_SIZE = "V9_MINIMUM_PAYCHECK_SIZE",
	V9_MINIMUM_PAYDAY_EOD_BALANCE = "V9_MINIMUM_PAYDAY_EOD_BALANCE"
}
export enum UpdateBankAccountJointStatusOutcome {
	FAILURE = "FAILURE",
	SUCCESS = "SUCCESS"
}
export enum UserFlag {
	ACCOUNT_DELETION_IN_APP_CONTROL_V2 = "ACCOUNT_DELETION_IN_APP_CONTROL_V2",
	ACCOUNT_DELETION_IN_APP_TEST_V2 = "ACCOUNT_DELETION_IN_APP_TEST_V2",
	ACHIEVEMENTS_TESTER = "ACHIEVEMENTS_TESTER",
	ADDRESS_TESTER = "ADDRESS_TESTER",
	APPL_PROMO_MKTG_10_CASH = "APPL_PROMO_MKTG_10_CASH",
	APPROVED_IC_ACTIVATION_TEST_CONTROL = "APPROVED_IC_ACTIVATION_TEST_CONTROL",
	APPROVED_IC_ACTIVATION_TEST_IC_FOCUS = "APPROVED_IC_ACTIVATION_TEST_IC_FOCUS",
	APPROVED_IC_ACTIVATION_TEST_IC_FOCUS_V2 = "APPROVED_IC_ACTIVATION_TEST_IC_FOCUS_V2",
	APPROVED_IC_ACTIVATION_TEST_SUB_FOCUS = "APPROVED_IC_ACTIVATION_TEST_SUB_FOCUS",
	APPROVED_IC_ACTIVATION_TEST_SUB_FOCUS_V2 = "APPROVED_IC_ACTIVATION_TEST_SUB_FOCUS_V2",
	BANKRUPT = "BANKRUPT",
	BANK_BLACKLIST_EXEMPT_UNDERWRITER = "BANK_BLACKLIST_EXEMPT_UNDERWRITER",
	BYPASS_MINIMUM_MOBILE_VERSION = "BYPASS_MINIMUM_MOBILE_VERSION",
	CB_ACTIVATE_SCREEN_CONTROL_PHASE_1 = "CB_ACTIVATE_SCREEN_CONTROL_PHASE_1",
	CB_ACTIVATE_SCREEN_CONTROL_PHASE_2 = "CB_ACTIVATE_SCREEN_CONTROL_PHASE_2",
	CB_ACTIVATE_SCREEN_HOLDOUT_PHASE_1 = "CB_ACTIVATE_SCREEN_HOLDOUT_PHASE_1",
	CB_ACTIVATE_SCREEN_TESTER_PHASE_1 = "CB_ACTIVATE_SCREEN_TESTER_PHASE_1",
	CB_ACTIVATE_SCREEN_TESTER_PHASE_2 = "CB_ACTIVATE_SCREEN_TESTER_PHASE_2",
	CB_ADDRESS_MODULE_UNDERWRITING = "CB_ADDRESS_MODULE_UNDERWRITING",
	CB_ADDRESS_MODULE_UNDERWRITING_V2_CONTROL = "CB_ADDRESS_MODULE_UNDERWRITING_V2_CONTROL",
	CB_ADDRESS_MODULE_UNDERWRITING_V2_TREATMENT = "CB_ADDRESS_MODULE_UNDERWRITING_V2_TREATMENT",
	CB_ALLOW_SAVINGS_DISBURSAL_WITHIN_CUTOFF = "CB_ALLOW_SAVINGS_DISBURSAL_WITHIN_CUTOFF",
	CB_APP_TOUCHPOINT_CROSS_SELL_CONTROL = "CB_APP_TOUCHPOINT_CROSS_SELL_CONTROL",
	CB_APP_TOUCHPOINT_CROSS_SELL_TESTER = "CB_APP_TOUCHPOINT_CROSS_SELL_TESTER",
	CB_AUTOMATIC_SAVINGS_DISBURSAL = "CB_AUTOMATIC_SAVINGS_DISBURSAL",
	CB_BANK_ACCOUNT_CLOSED = "CB_BANK_ACCOUNT_CLOSED",
	CB_BANK_ACCOUNT_CONNECTION_REMOVED = "CB_BANK_ACCOUNT_CONNECTION_REMOVED",
	CB_BLOCK_ENROLLMENT = "CB_BLOCK_ENROLLMENT",
	CB_BLOCK_PAYMENTS = "CB_BLOCK_PAYMENTS",
	CB_BLOCK_SAVINGS_DISBURSAL = "CB_BLOCK_SAVINGS_DISBURSAL",
	CB_CARD_TESTER = "CB_CARD_TESTER",
	CB_CARD_TESTER_CONTROL = "CB_CARD_TESTER_CONTROL",
	CB_CLOSE_LOAN_SURVEY_CONTROL = "CB_CLOSE_LOAN_SURVEY_CONTROL",
	CB_CLOSE_LOAN_SURVEY_TESTER = "CB_CLOSE_LOAN_SURVEY_TESTER",
	CB_COASTAL_CARD_MIGRATION_TESTER = "CB_COASTAL_CARD_MIGRATION_TESTER",
	CB_CONTRIBUTION_ADJUSTMENT_CONTROL = "CB_CONTRIBUTION_ADJUSTMENT_CONTROL",
	CB_CONTRIBUTION_ADJUSTMENT_TESTER = "CB_CONTRIBUTION_ADJUSTMENT_TESTER",
	CB_CREDMO_BYPASS_VERIFICATION = "CB_CREDMO_BYPASS_VERIFICATION",
	CB_CREDMO_FORCE_CREATE_USER_EXCEPTION = "CB_CREDMO_FORCE_CREATE_USER_EXCEPTION",
	CB_CREDMO_FORCE_KBA_BYPASS_EXCEPTION = "CB_CREDMO_FORCE_KBA_BYPASS_EXCEPTION",
	CB_DEBIT_PROMPT_CONTROL = "CB_DEBIT_PROMPT_CONTROL",
	CB_DEBIT_PROMPT_TREATMENT = "CB_DEBIT_PROMPT_TREATMENT",
	CB_FINAL_SAVINGS_SELF_SERVICE_WITHDRAW_CONTROL = "CB_FINAL_SAVINGS_SELF_SERVICE_WITHDRAW_CONTROL",
	CB_FINAL_SAVINGS_SELF_SERVICE_WITHDRAW_TESTER = "CB_FINAL_SAVINGS_SELF_SERVICE_WITHDRAW_TESTER",
	CB_FIRST_MONTH_99C = "CB_FIRST_MONTH_99C",
	CB_FIRST_MONTH_FREE = "CB_FIRST_MONTH_FREE",
	CB_FORCE_DOCV_TESTER = "CB_FORCE_DOCV_TESTER",
	CB_FORCE_FAIL_KYC_TESTER = "CB_FORCE_FAIL_KYC_TESTER",
	CB_FORCE_NA_SELECT_POPULATION = "CB_FORCE_NA_SELECT_POPULATION",
	CB_FORCE_RESUBMIT_DOCV_KYC_TESTER = "CB_FORCE_RESUBMIT_DOCV_KYC_TESTER",
	CB_FORCE_RESUBMIT_KYC_TESTER = "CB_FORCE_RESUBMIT_KYC_TESTER",
	CB_FORCE_SSN_CHECK = "CB_FORCE_SSN_CHECK",
	CB_FORCE_UNDERWRITING_FAIL = "CB_FORCE_UNDERWRITING_FAIL",
	CB_FRAUD_OVERRIDE_FALSE = "CB_FRAUD_OVERRIDE_FALSE",
	CB_FRAUD_OVERRIDE_TRUE = "CB_FRAUD_OVERRIDE_TRUE",
	CB_FUNNEL_CONTROL_PHASE_1 = "CB_FUNNEL_CONTROL_PHASE_1",
	CB_FUNNEL_CONTROL_PHASE_2 = "CB_FUNNEL_CONTROL_PHASE_2",
	CB_FUNNEL_CONTROL_PHASE_3 = "CB_FUNNEL_CONTROL_PHASE_3",
	CB_FUNNEL_HOLDOUT_PHASE_1 = "CB_FUNNEL_HOLDOUT_PHASE_1",
	CB_FUNNEL_HOLDOUT_PHASE_2 = "CB_FUNNEL_HOLDOUT_PHASE_2",
	CB_FUNNEL_SEPTEMBER_TEST_REMEDIATION = "CB_FUNNEL_SEPTEMBER_TEST_REMEDIATION",
	CB_FUNNEL_TESTER_PHASE_1 = "CB_FUNNEL_TESTER_PHASE_1",
	CB_FUNNEL_TESTER_PHASE_2 = "CB_FUNNEL_TESTER_PHASE_2",
	CB_FUNNEL_TESTER_PHASE_3 = "CB_FUNNEL_TESTER_PHASE_3",
	CB_HOME_CROSS_SELL_CONTROL = "CB_HOME_CROSS_SELL_CONTROL",
	CB_HOME_CROSS_SELL_TESTER_CHARGE = "CB_HOME_CROSS_SELL_TESTER_CHARGE",
	CB_HOME_CROSS_SELL_TESTER_DEBT = "CB_HOME_CROSS_SELL_TESTER_DEBT",
	CB_HOME_CROSS_SELL_TESTER_RATES = "CB_HOME_CROSS_SELL_TESTER_RATES",
	CB_INCLUDE_ON_NEXT_METRO2_RUN = "CB_INCLUDE_ON_NEXT_METRO2_RUN",
	CB_KBA_BYPASS_CONTROL = "CB_KBA_BYPASS_CONTROL",
	CB_KBA_BYPASS_T1 = "CB_KBA_BYPASS_T1",
	CB_LANDING_TESTIMONIAL_FLOW_CONTROL = "CB_LANDING_TESTIMONIAL_FLOW_CONTROL",
	CB_LANDING_TESTIMONIAL_FLOW_TESTER = "CB_LANDING_TESTIMONIAL_FLOW_TESTER",
	CB_LINK_PLAID_PLACEMENT_TEST_CONTROL = "CB_LINK_PLAID_PLACEMENT_TEST_CONTROL",
	CB_LINK_PLAID_PLACEMENT_TEST_T1 = "CB_LINK_PLAID_PLACEMENT_TEST_T1",
	CB_LINK_PLAID_PLACEMENT_TEST_T2 = "CB_LINK_PLAID_PLACEMENT_TEST_T2",
	CB_LINK_PLAID_PLACEMENT_TEST_T3 = "CB_LINK_PLAID_PLACEMENT_TEST_T3",
	CB_LIST_VIEW_AND_COPY_CONTROL = "CB_LIST_VIEW_AND_COPY_CONTROL",
	CB_LIST_VIEW_AND_COPY_T1 = "CB_LIST_VIEW_AND_COPY_T1",
	CB_MAX_CONTRIBUTION_WARNING = "CB_MAX_CONTRIBUTION_WARNING",
	CB_MKT_CROSS_SELL_CONTROL = "CB_MKT_CROSS_SELL_CONTROL",
	CB_MKT_CROSS_SELL_TESTER = "CB_MKT_CROSS_SELL_TESTER",
	CB_MULTIPLE_ENROLLMENT_CONTROL = "CB_MULTIPLE_ENROLLMENT_CONTROL",
	CB_MULTIPLE_ENROLLMENT_TESTER = "CB_MULTIPLE_ENROLLMENT_TESTER",
	CB_NO_FILE_TEST_USER = "CB_NO_FILE_TEST_USER",
	CB_NO_INCOME_TESTER = "CB_NO_INCOME_TESTER",
	CB_ONE_YEAR_LOAN_TERM_CONTROL = "CB_ONE_YEAR_LOAN_TERM_CONTROL",
	CB_PREPAY_ANY_TESTER = "CB_PREPAY_ANY_TESTER",
	CB_PROGRESS_BAR_SHOWS_MONTHS_COMPLETED_CONTROL = "CB_PROGRESS_BAR_SHOWS_MONTHS_COMPLETED_CONTROL",
	CB_PROGRESS_BAR_SHOWS_MONTHS_COMPLETED_TESTER = "CB_PROGRESS_BAR_SHOWS_MONTHS_COMPLETED_TESTER",
	CB_RECENT_SCORE_CHANGE_IS_DPD30 = "CB_RECENT_SCORE_CHANGE_IS_DPD30",
	CB_REPLACE_CREDIT_SCORE_HISTORY = "CB_REPLACE_CREDIT_SCORE_HISTORY",
	CB_RESIDES_IN_UNSUPPORTED_STATE = "CB_RESIDES_IN_UNSUPPORTED_STATE",
	CB_RESUBMIT_WITH_RESIDENTIAL_ADDRESS_CONTROL = "CB_RESUBMIT_WITH_RESIDENTIAL_ADDRESS_CONTROL",
	CB_RESUBMIT_WITH_RESIDENTIAL_ADDRESS_T1 = "CB_RESUBMIT_WITH_RESIDENTIAL_ADDRESS_T1",
	CB_RISKY_INSTITUTION_CARD_MARCH_2023_EXP_CONTROL = "CB_RISKY_INSTITUTION_CARD_MARCH_2023_EXP_CONTROL",
	CB_RISKY_INSTITUTION_CARD_MARCH_2023_EXP_TEST = "CB_RISKY_INSTITUTION_CARD_MARCH_2023_EXP_TEST",
	CB_SAVING_DISBURSAL_BY_BALANCE = "CB_SAVING_DISBURSAL_BY_BALANCE",
	CB_SEND_USERS_TO_CARD_CRITERIA_APRIL_2023_EXP_CONTROL = "CB_SEND_USERS_TO_CARD_CRITERIA_APRIL_2023_EXP_CONTROL",
	CB_SEND_USERS_TO_CARD_CRITERIA_APRIL_2023_EXP_TEST = "CB_SEND_USERS_TO_CARD_CRITERIA_APRIL_2023_EXP_TEST",
	CB_SOCURE_DECISION_MODULE_CONTROL = "CB_SOCURE_DECISION_MODULE_CONTROL",
	CB_SOCURE_DECISION_MODULE_TESTER = "CB_SOCURE_DECISION_MODULE_TESTER",
	CB_STANDARD_MANUAL_PAYMENT_CONTROL = "CB_STANDARD_MANUAL_PAYMENT_CONTROL",
	CB_STANDARD_MANUAL_PAYMENT_CONTROL_PHASE_1 = "CB_STANDARD_MANUAL_PAYMENT_CONTROL_PHASE_1",
	CB_STANDARD_MANUAL_PAYMENT_CONTROL_PHASE_2 = "CB_STANDARD_MANUAL_PAYMENT_CONTROL_PHASE_2",
	CB_STANDARD_MANUAL_PAYMENT_CONTROL_PHASE_3 = "CB_STANDARD_MANUAL_PAYMENT_CONTROL_PHASE_3",
	CB_STANDARD_MANUAL_PAYMENT_HOLDOUT_PHASE_1 = "CB_STANDARD_MANUAL_PAYMENT_HOLDOUT_PHASE_1",
	CB_STANDARD_MANUAL_PAYMENT_HOLDOUT_PHASE_2 = "CB_STANDARD_MANUAL_PAYMENT_HOLDOUT_PHASE_2",
	CB_STANDARD_MANUAL_PAYMENT_TESTER = "CB_STANDARD_MANUAL_PAYMENT_TESTER",
	CB_STANDARD_MANUAL_PAYMENT_TESTER_PHASE_1 = "CB_STANDARD_MANUAL_PAYMENT_TESTER_PHASE_1",
	CB_STANDARD_MANUAL_PAYMENT_TESTER_PHASE_2 = "CB_STANDARD_MANUAL_PAYMENT_TESTER_PHASE_2",
	CB_STANDARD_MANUAL_PAYMENT_TESTER_PHASE_3 = "CB_STANDARD_MANUAL_PAYMENT_TESTER_PHASE_3",
	CB_SUSPECTED_FRAUD = "CB_SUSPECTED_FRAUD",
	CB_SWEEPSTAKES_TESTER = "CB_SWEEPSTAKES_TESTER",
	CB_TEST_ONLY_USER = "CB_TEST_ONLY_USER",
	CB_TWO_YEAR_LOAN_TERM_TESTER = "CB_TWO_YEAR_LOAN_TERM_TESTER",
	CB_UNDERWRITING_TESTER = "CB_UNDERWRITING_TESTER",
	CB_WEEKLY_INCOME_TESTER = "CB_WEEKLY_INCOME_TESTER",
	CB_WITHDRAW_EXTERNAL_CONTRIBUTIONS_CONTROL = "CB_WITHDRAW_EXTERNAL_CONTRIBUTIONS_CONTROL",
	CB_WITHDRAW_EXTERNAL_CONTRIBUTIONS_TESTER = "CB_WITHDRAW_EXTERNAL_CONTRIBUTIONS_TESTER",
	CEASE_AND_DESIST = "CEASE_AND_DESIST",
	COMPLAINT_ATTORNEY_GENERAL = "COMPLAINT_ATTORNEY_GENERAL",
	COMPLAINT_BBB = "COMPLAINT_BBB",
	COMPLAINT_CFPB = "COMPLAINT_CFPB",
	COMPLAINT_FRB = "COMPLAINT_FRB",
	CONNECT_BANK_PRECHECKS_CONTROL = "CONNECT_BANK_PRECHECKS_CONTROL",
	CONNECT_BANK_PRECHECKS_VERSION_A_TEST = "CONNECT_BANK_PRECHECKS_VERSION_A_TEST",
	CONNECT_BANK_PRECHECKS_VERSION_B_TEST = "CONNECT_BANK_PRECHECKS_VERSION_B_TEST",
	CREDIT_BUILDER_STATEMENTS_DEV_TESTER = "CREDIT_BUILDER_STATEMENTS_DEV_TESTER",
	CREDIT_BUILDER_TESTER = "CREDIT_BUILDER_TESTER",
	CREDIT_BUILDER_V2B_TESTER = "CREDIT_BUILDER_V2B_TESTER",
	CREDIT_BUILDER_V2B_USER = "CREDIT_BUILDER_V2B_USER",
	CREDIT_BUILDER_V2_TESTER = "CREDIT_BUILDER_V2_TESTER",
	CREDIT_LIMIT_GRADUATION_V2_CONTROL = "CREDIT_LIMIT_GRADUATION_V2_CONTROL",
	CREDIT_LIMIT_GRADUATION_V2_GAMIFICATION_TEST = "CREDIT_LIMIT_GRADUATION_V2_GAMIFICATION_TEST",
	CREDIT_LIMIT_GRADUATION_V2_NO_GAMIFICATION_TEST = "CREDIT_LIMIT_GRADUATION_V2_NO_GAMIFICATION_TEST",
	CREDIT_MONITORING_CREDIT_LOCK_FEATURE = "CREDIT_MONITORING_CREDIT_LOCK_FEATURE",
	CREDIT_MONITORING_IDENTITY_PROTECTION_FEATURE = "CREDIT_MONITORING_IDENTITY_PROTECTION_FEATURE",
	CREDIT_MONITORING_TESTER = "CREDIT_MONITORING_TESTER",
	CX_OVERRIDE_PE_BLOCK_SUSPECTED_FRAUD = "CX_OVERRIDE_PE_BLOCK_SUSPECTED_FRAUD",
	DEACTIVATE_WITH_PCO_LOAN_TEST = "DEACTIVATE_WITH_PCO_LOAN_TEST",
	DEACTIVATION_PROMO_APPLIED_ACTIVATED_ALREADY_SKIPPED_SKIP_NEXT_MONTH = "DEACTIVATION_PROMO_APPLIED_ACTIVATED_ALREADY_SKIPPED_SKIP_NEXT_MONTH",
	DEACTIVATION_PROMO_APPLIED_ACTIVATED_PROCESSING_SKIP_NEXT_MONTH = "DEACTIVATION_PROMO_APPLIED_ACTIVATED_PROCESSING_SKIP_NEXT_MONTH",
	DEACTIVATION_PROMO_APPLIED_SKIPPED = "DEACTIVATION_PROMO_APPLIED_SKIPPED",
	DEACTIVATION_PROMO_HALF_OFF_NEXT_TWO_MONTHS_CONTROL = "DEACTIVATION_PROMO_HALF_OFF_NEXT_TWO_MONTHS_CONTROL",
	DEACTIVATION_PROMO_HALF_OFF_NEXT_TWO_MONTHS_TEST = "DEACTIVATION_PROMO_HALF_OFF_NEXT_TWO_MONTHS_TEST",
	DEACTIVATION_PROMO_HALF_OFF_NEXT_TWO_MONTHS_TEST_PROMO_ACTIVE = "DEACTIVATION_PROMO_HALF_OFF_NEXT_TWO_MONTHS_TEST_PROMO_ACTIVE",
	DEACTVATE_WITH_PCO_LOAN_CONTROL = "DEACTVATE_WITH_PCO_LOAN_CONTROL",
	DEEP_TIMEOUT = "DEEP_TIMEOUT",
	DELETE_ACCOUNT_AFTER_SUBSCRIPTION_REPAID = "DELETE_ACCOUNT_AFTER_SUBSCRIPTION_REPAID",
	DISABLE_PROTECTION_ONCE_LOAN_REPAID = "DISABLE_PROTECTION_ONCE_LOAN_REPAID",
	DISABLE_PROTECTION_WITH_OUTSTANDING_LOAN_CONTROL = "DISABLE_PROTECTION_WITH_OUTSTANDING_LOAN_CONTROL",
	DISABLE_PROTECTION_WITH_OUTSTANDING_LOAN_TEST = "DISABLE_PROTECTION_WITH_OUTSTANDING_LOAN_TEST",
	DISBURSAL_FLOW_V2 = "DISBURSAL_FLOW_V2",
	DYNAMIC_MARKETPLACE_TESTER = "DYNAMIC_MARKETPLACE_TESTER",
	EVOLVE_REMEDIATION_JUNE_2024 = "EVOLVE_REMEDIATION_JUNE_2024",
	EXISTING_USER_MIGRATION_CONTROL = "EXISTING_USER_MIGRATION_CONTROL",
	EXISTING_USER_MIGRATION_NPE_ROUND_1_CONTROL = "EXISTING_USER_MIGRATION_NPE_ROUND_1_CONTROL",
	EXISTING_USER_MIGRATION_NPE_ROUND_1_TEST = "EXISTING_USER_MIGRATION_NPE_ROUND_1_TEST",
	EXISTING_USER_MIGRATION_NPE_ROUND_2_CONTROL = "EXISTING_USER_MIGRATION_NPE_ROUND_2_CONTROL",
	EXISTING_USER_MIGRATION_NPE_ROUND_2_TEST = "EXISTING_USER_MIGRATION_NPE_ROUND_2_TEST",
	EXISTING_USER_MIGRATION_NPE_ROUND_3_CONTROL = "EXISTING_USER_MIGRATION_NPE_ROUND_3_CONTROL",
	EXISTING_USER_MIGRATION_NPE_ROUND_3_TEST = "EXISTING_USER_MIGRATION_NPE_ROUND_3_TEST",
	EXISTING_USER_MIGRATION_NPE_ROUND_4_TEST = "EXISTING_USER_MIGRATION_NPE_ROUND_4_TEST",
	EXISTING_USER_MIGRATION_PE_ROUND_1_CONTROL = "EXISTING_USER_MIGRATION_PE_ROUND_1_CONTROL",
	EXISTING_USER_MIGRATION_PE_ROUND_1_TEST = "EXISTING_USER_MIGRATION_PE_ROUND_1_TEST",
	EXISTING_USER_MIGRATION_PE_ROUND_2_CONTROL = "EXISTING_USER_MIGRATION_PE_ROUND_2_CONTROL",
	EXISTING_USER_MIGRATION_PE_ROUND_2_TEST = "EXISTING_USER_MIGRATION_PE_ROUND_2_TEST",
	EXISTING_USER_MIGRATION_PE_ROUND_3_CONTROL = "EXISTING_USER_MIGRATION_PE_ROUND_3_CONTROL",
	EXISTING_USER_MIGRATION_PE_ROUND_3_TEST = "EXISTING_USER_MIGRATION_PE_ROUND_3_TEST",
	EXISTING_USER_MIGRATION_PE_ROUND_4_TEST = "EXISTING_USER_MIGRATION_PE_ROUND_4_TEST",
	EXISTING_USER_MIGRATION_ROUND_4_TEST = "EXISTING_USER_MIGRATION_ROUND_4_TEST",
	EXISTING_USER_MIGRATION_SUBSCRIPTION_PAUSED = "EXISTING_USER_MIGRATION_SUBSCRIPTION_PAUSED",
	EXISTING_USER_MIGRATION_TEST = "EXISTING_USER_MIGRATION_TEST",
	FB_ACTIVATION_FRAUD_OVERRIDE = "FB_ACTIVATION_FRAUD_OVERRIDE",
	FB_UPGRADE_FRAUD_OVERRIDE = "FB_UPGRADE_FRAUD_OVERRIDE",
	FETCH_VERSIONED_INCOMES = "FETCH_VERSIONED_INCOMES",
	FILTERABLE_EARNEXTRA_TEST_TESTER = "FILTERABLE_EARNEXTRA_TEST_TESTER",
	FLAT_FEE_PRE_CHARGE_OFF_SUBSCRIPTION_SKIP = "FLAT_FEE_PRE_CHARGE_OFF_SUBSCRIPTION_SKIP",
	FORCED_SUBSCRIPTION_CARD_COLLECTION_WINDOW_14D = "FORCED_SUBSCRIPTION_CARD_COLLECTION_WINDOW_14D",
	FORCE_FIXTURE_LEGACY_USER = "FORCE_FIXTURE_LEGACY_USER",
	FORCE_UPGRADE_TESTER = "FORCE_UPGRADE_TESTER",
	FORCE_UPGRADE_UNDER_V300_CONTROL = "FORCE_UPGRADE_UNDER_V300_CONTROL",
	FORCE_UPGRADE_UNDER_V300_TEST = "FORCE_UPGRADE_UNDER_V300_TEST",
	FRONTEND_SEGMENT_TESTER = "FRONTEND_SEGMENT_TESTER",
	GOVERNMENT_SHUTDOWN_DEC_2024 = "GOVERNMENT_SHUTDOWN_DEC_2024",
	HACKWEEK_2023_CB_BYPASS_ELIGIBILITY = "HACKWEEK_2023_CB_BYPASS_ELIGIBILITY",
	HACKWEEK_2024_FLEX_CREDITS = "HACKWEEK_2024_FLEX_CREDITS",
	HACKWEK_2024_DISPLAY_FLEX_CREDITS_MODAL = "HACKWEK_2024_DISPLAY_FLEX_CREDITS_MODAL",
	HAS_CB_4_95_PROMOTIONAL_PLUS_PRICE = "HAS_CB_4_95_PROMOTIONAL_PLUS_PRICE",
	HAS_CB_7_95_PROMOTIONAL_PLUS_PRICE = "HAS_CB_7_95_PROMOTIONAL_PLUS_PRICE",
	HAS_PLAID_LINK_SDK = "HAS_PLAID_LINK_SDK",
	HIDE_MARKETPLACE_PROMO_CARDS = "HIDE_MARKETPLACE_PROMO_CARDS",
	HOMESCREEN_CROSS_SELL_V1_CONTROL = "HOMESCREEN_CROSS_SELL_V1_CONTROL",
	HOMESCREEN_CROSS_SELL_V1_TEST = "HOMESCREEN_CROSS_SELL_V1_TEST",
	HOMESCREEN_IC_CARD_APPROVAL_STATUS_CONTROL = "HOMESCREEN_IC_CARD_APPROVAL_STATUS_CONTROL",
	HOMESCREEN_IC_CARD_APPROVAL_STATUS_TEST = "HOMESCREEN_IC_CARD_APPROVAL_STATUS_TEST",
	HUNTINGTON_REMEDIATION_NOVEMBER_2023 = "HUNTINGTON_REMEDIATION_NOVEMBER_2023",
	IC_99C_TRANSFER_FEE_DEVELOPMENT_TESTER = "IC_99C_TRANSFER_FEE_DEVELOPMENT_TESTER",
	IC_ACTIVATE_FAQ_CONTROL = "IC_ACTIVATE_FAQ_CONTROL",
	IC_ACTIVATE_FAQ_TEST = "IC_ACTIVATE_FAQ_TEST",
	IC_ACTIVATE_SUB_CLARITY_BENEFITS_CONTROL = "IC_ACTIVATE_SUB_CLARITY_BENEFITS_CONTROL",
	IC_ACTIVATE_SUB_CLARITY_BENEFITS_TEST = "IC_ACTIVATE_SUB_CLARITY_BENEFITS_TEST",
	IC_ACTIVATION_PREMIUM_UPSELL_TEST_CARD = "IC_ACTIVATION_PREMIUM_UPSELL_TEST_CARD",
	IC_ACTIVATION_PREMIUM_UPSELL_TEST_CONTROL = "IC_ACTIVATION_PREMIUM_UPSELL_TEST_CONTROL",
	IC_ACTIVATION_PREMIUM_UPSELL_TEST_NOCARD = "IC_ACTIVATION_PREMIUM_UPSELL_TEST_NOCARD",
	IC_CREDIT_LIMIT_INCREASE_STRATEGY_NPE_CONTROL = "IC_CREDIT_LIMIT_INCREASE_STRATEGY_NPE_CONTROL",
	IC_CREDIT_LIMIT_INCREASE_STRATEGY_NPE_TEST = "IC_CREDIT_LIMIT_INCREASE_STRATEGY_NPE_TEST",
	IC_CREDIT_LIMIT_INCREASE_STRATEGY_PE_CONTROL = "IC_CREDIT_LIMIT_INCREASE_STRATEGY_PE_CONTROL",
	IC_CREDIT_LIMIT_INCREASE_STRATEGY_PE_TEST = "IC_CREDIT_LIMIT_INCREASE_STRATEGY_PE_TEST",
	IC_CREDIT_LIMIT_PATHWAY_CONTROL = "IC_CREDIT_LIMIT_PATHWAY_CONTROL",
	IC_CREDIT_LIMIT_PATHWAY_TEST = "IC_CREDIT_LIMIT_PATHWAY_TEST",
	IC_DEVELOPMENT_TEST_USER = "IC_DEVELOPMENT_TEST_USER",
	IC_DISPLAY_CLG_MODAL = "IC_DISPLAY_CLG_MODAL",
	IC_EXPRESS_TRANSFER_FEE_CONTROL = "IC_EXPRESS_TRANSFER_FEE_CONTROL",
	IC_EXPRESS_TRANSFER_FEE_TEST = "IC_EXPRESS_TRANSFER_FEE_TEST",
	IC_LANDING_CHOOSE_AMOUNT_CONTROL = "IC_LANDING_CHOOSE_AMOUNT_CONTROL",
	IC_LANDING_CHOOSE_AMOUNT_TEST = "IC_LANDING_CHOOSE_AMOUNT_TEST",
	IC_LANDING_REQUIREMENTS_TEST = "IC_LANDING_REQUIREMENTS_TEST",
	IC_NEOBANK_DEBIT_CARD_REQUIRED_CONTROL = "IC_NEOBANK_DEBIT_CARD_REQUIRED_CONTROL",
	IC_NEOBANK_DEBIT_CARD_REQUIRED_TEST = "IC_NEOBANK_DEBIT_CARD_REQUIRED_TEST",
	IC_ONE_TIME_PAYMENTS_CONTROL = "IC_ONE_TIME_PAYMENTS_CONTROL",
	IC_ONE_TIME_PAYMENTS_TEST = "IC_ONE_TIME_PAYMENTS_TEST",
	IC_PCO_BANK_ISSUE_EMAIL_CONTROL = "IC_PCO_BANK_ISSUE_EMAIL_CONTROL",
	IC_PCO_BANK_ISSUE_EMAIL_TEST = "IC_PCO_BANK_ISSUE_EMAIL_TEST",
	IC_TRAD_BANK_V1E_STRATEGIES = "IC_TRAD_BANK_V1E_STRATEGIES",
	IC_UPSELL_CONTROL = "IC_UPSELL_CONTROL",
	IC_UPSELL_POST_PURCHASE_SCREEN_TEST = "IC_UPSELL_POST_PURCHASE_SCREEN_TEST",
	IC_UPSELL_PRE_PURCHASE_MODAL_TEST = "IC_UPSELL_PRE_PURCHASE_MODAL_TEST",
	IC_UPSELL_PRE_PURCHASE_SCREEN_TEST = "IC_UPSELL_PRE_PURCHASE_SCREEN_TEST",
	INSTALLMENT_LOANS_FORCE_FAIL_KYC_TESTER = "INSTALLMENT_LOANS_FORCE_FAIL_KYC_TESTER",
	INSTALLMENT_LOANS_FORCE_RISK_SCORE_FAIL = "INSTALLMENT_LOANS_FORCE_RISK_SCORE_FAIL",
	INSTALLMENT_LOANS_FORCE_RISK_SCORE_PASS = "INSTALLMENT_LOANS_FORCE_RISK_SCORE_PASS",
	IN_APP_DEACTIVATION_CONTROL = "IN_APP_DEACTIVATION_CONTROL",
	IN_APP_DEACTIVATION_TEST = "IN_APP_DEACTIVATION_TEST",
	IS_BRIGIT_VISIBLE = "IS_BRIGIT_VISIBLE",
	IS_DOUBLE_SIDED_REFERRAL = "IS_DOUBLE_SIDED_REFERRAL",
	IS_ITESTER = "IS_ITESTER",
	IS_TESTER = "IS_TESTER",
	IS_VIDEO_TESTIMONIAL = "IS_VIDEO_TESTIMONIAL",
	KYC_FORCE_ACCEPT_TESTER = "KYC_FORCE_ACCEPT_TESTER",
	KYC_V2_TESTER = "KYC_V2_TESTER",
	LOC_ALLOW_ANY_STATE = "LOC_ALLOW_ANY_STATE",
	LOC_ALLOW_NEW_YORK_STATE = "LOC_ALLOW_NEW_YORK_STATE",
	LOC_FORCE_COVERED_MLA_USER = "LOC_FORCE_COVERED_MLA_USER",
	LOC_FORCE_NOT_COVERED_MLA_USER = "LOC_FORCE_NOT_COVERED_MLA_USER",
	LOC_FORCE_UNKNOWN_MLA_USER = "LOC_FORCE_UNKNOWN_MLA_USER",
	LOC_UNDERWRITING_FORCE_APPROVED = "LOC_UNDERWRITING_FORCE_APPROVED",
	MARKETPLACE_BOTTOM_TAB_TEST_CONTROL = "MARKETPLACE_BOTTOM_TAB_TEST_CONTROL",
	MARKETPLACE_BOTTOM_TAB_TEST_TESTER = "MARKETPLACE_BOTTOM_TAB_TEST_TESTER",
	MARKETPLACE_DYNAMIC_PROMO_25_OPENS_ROTATION = "MARKETPLACE_DYNAMIC_PROMO_25_OPENS_ROTATION",
	MARKETPLACE_DYNAMIC_PROMO_9_OPENS_ROTATION = "MARKETPLACE_DYNAMIC_PROMO_9_OPENS_ROTATION",
	MARKETPLACE_DYNAMIC_PROMO_TILE_CONTROL = "MARKETPLACE_DYNAMIC_PROMO_TILE_CONTROL",
	MARKETPLACE_DYNAMIC_PROMO_TILE_TESTER = "MARKETPLACE_DYNAMIC_PROMO_TILE_TESTER",
	MARKETPLACE_ENTRY_POINTS_CONTROL = "MARKETPLACE_ENTRY_POINTS_CONTROL",
	MARKETPLACE_ENTRY_POINTS_TEST_INLINE = "MARKETPLACE_ENTRY_POINTS_TEST_INLINE",
	MARKETPLACE_ENTRY_POINTS_TEST_LIST = "MARKETPLACE_ENTRY_POINTS_TEST_LIST",
	MARKETPLACE_HOMESCREEN_PLACEMENTTEST_TEST = "MARKETPLACE_HOMESCREEN_PLACEMENTTEST_TEST",
	MARKETPLACE_NEW_BADGES_0123_CONTROL = "MARKETPLACE_NEW_BADGES_0123_CONTROL",
	MARKETPLACE_NEW_BADGES_0123_TESTER_VAR1 = "MARKETPLACE_NEW_BADGES_0123_TESTER_VAR1",
	MARKETPLACE_NEW_BADGES_0123_TESTER_VAR2 = "MARKETPLACE_NEW_BADGES_0123_TESTER_VAR2",
	MIGRATE_USER_TO_CIM = "MIGRATE_USER_TO_CIM",
	MKT_DEBT_RELIEF_CATEGORY_CONTROL = "MKT_DEBT_RELIEF_CATEGORY_CONTROL",
	MKT_DEBT_RELIEF_CATEGORY_TEST = "MKT_DEBT_RELIEF_CATEGORY_TEST",
	MKT_OFFERS_GRAPHQL_CONTROL = "MKT_OFFERS_GRAPHQL_CONTROL",
	MKT_OFFERS_GRAPHQL_TEST = "MKT_OFFERS_GRAPHQL_TEST",
	MONTHLY_ADVANCE_LIMIT_OVERRIDE = "MONTHLY_ADVANCE_LIMIT_OVERRIDE",
	OVERRIDE_GRACE_PERIOD = "OVERRIDE_GRACE_PERIOD",
	PAUSE_SOCURE_RATE_LIMIT = "PAUSE_SOCURE_RATE_LIMIT",
	PAYMENT_LIMIT_OVERRIDE = "PAYMENT_LIMIT_OVERRIDE",
	PCO_SMS_CONTROL = "PCO_SMS_CONTROL",
	PCO_SMS_SAMPLING_P25 = "PCO_SMS_SAMPLING_P25",
	PCO_SMS_SAMPLING_P50 = "PCO_SMS_SAMPLING_P50",
	PE_BLOCK_SUSPECTED_FRAUD = "PE_BLOCK_SUSPECTED_FRAUD",
	PLAID_CHASE_OOPWV_CONTROL = "PLAID_CHASE_OOPWV_CONTROL",
	PLAID_CHASE_OOPWV_TEST = "PLAID_CHASE_OOPWV_TEST",
	PLAID_REMEMBER_ME_TESTER = "PLAID_REMEMBER_ME_TESTER",
	PLAID_SDK_DISMISS_MODAL_CONTROL = "PLAID_SDK_DISMISS_MODAL_CONTROL",
	PLAID_SDK_DISMISS_MODAL_CONTROL_PHASE2 = "PLAID_SDK_DISMISS_MODAL_CONTROL_PHASE2",
	PLAID_SDK_DISMISS_MODAL_TEST = "PLAID_SDK_DISMISS_MODAL_TEST",
	PLAID_SDK_DISMISS_MODAL_TEST_PHASE2 = "PLAID_SDK_DISMISS_MODAL_TEST_PHASE2",
	PROMO_CARD_V2_CONTROL = "PROMO_CARD_V2_CONTROL",
	PROMO_CARD_V2_TEST = "PROMO_CARD_V2_TEST",
	RECOVERY_V2_TESTER = "RECOVERY_V2_TESTER",
	RECOVERY_V3_TESTER = "RECOVERY_V3_TESTER",
	REQUEST_DEBIT_CARD_PRE_ACTIVATION = "REQUEST_DEBIT_CARD_PRE_ACTIVATION",
	REQUIRE_DEBIT_CARD_CVV_UI_TESTER = "REQUIRE_DEBIT_CARD_CVV_UI_TESTER",
	REQUIRE_DEBIT_CARD_PRE_ACTIVATION = "REQUIRE_DEBIT_CARD_PRE_ACTIVATION",
	RESAMPLED_USERS_CREDIT_LIMIT_EXPANSION = "RESAMPLED_USERS_CREDIT_LIMIT_EXPANSION",
	ROTATE_MARKETPLACE_PROMO_CARD_ON_25_APP_VISITS = "ROTATE_MARKETPLACE_PROMO_CARD_ON_25_APP_VISITS",
	ROTATE_MARKETPLACE_PROMO_CARD_ON_3_APP_VISITS = "ROTATE_MARKETPLACE_PROMO_CARD_ON_3_APP_VISITS",
	ROTATE_MARKETPLACE_PROMO_CARD_ON_9_APP_VISITS = "ROTATE_MARKETPLACE_PROMO_CARD_ON_9_APP_VISITS",
	SKIP_ENABLE_IP_RATELIMIT = "SKIP_ENABLE_IP_RATELIMIT",
	SMPL_PROMO_MKTG_10_CASH = "SMPL_PROMO_MKTG_10_CASH",
	SMPL_PROMO_MKTG_10_CASH_CONTROL = "SMPL_PROMO_MKTG_10_CASH_CONTROL",
	SMPL_PROMO_MKTG_CONTROL = "SMPL_PROMO_MKTG_CONTROL",
	SOCURE_DOCV_USER = "SOCURE_DOCV_USER",
	SOCURE_DOCV_USER_CONTROL = "SOCURE_DOCV_USER_CONTROL",
	SOCURE_REDO_KYC_REQUIREMENT = "SOCURE_REDO_KYC_REQUIREMENT",
	SOCURE_REDO_KYC_REQUIREMENT_CB_UNSUPPORTED_STATE = "SOCURE_REDO_KYC_REQUIREMENT_CB_UNSUPPORTED_STATE",
	SOCURE_TESTER = "SOCURE_TESTER",
	SOCURE_TESTER_FROM_PLAID = "SOCURE_TESTER_FROM_PLAID",
	SWITCH_ACCOUNT_WHILE_OUTSTANDING = "SWITCH_ACCOUNT_WHILE_OUTSTANDING",
	TABAPAY_ACH_TESTER = "TABAPAY_ACH_TESTER",
	TABAPAY_CARD_TESTER = "TABAPAY_CARD_TESTER",
	TABAPAY_CRB_TESTER = "TABAPAY_CRB_TESTER",
	TARGET_METHOD_ROUTING_TESTER = "TARGET_METHOD_ROUTING_TESTER",
	TEST_FLAG = "TEST_FLAG",
	TEXT_LIFECYCLE = "TEXT_LIFECYCLE",
	TIERED_PRICING_LEGACY = "TIERED_PRICING_LEGACY",
	TIERED_PRICING_LEGACY_PHASE3 = "TIERED_PRICING_LEGACY_PHASE3",
	TIERED_PRICING_LEGACY_WEB = "TIERED_PRICING_LEGACY_WEB",
	TIERED_PRICING_NOPROMO_LEGACY = "TIERED_PRICING_NOPROMO_LEGACY",
	TIERED_PRICING_NOPROMO_LEGACY_PHASE3 = "TIERED_PRICING_NOPROMO_LEGACY_PHASE3",
	TIERED_PRICING_TEST = "TIERED_PRICING_TEST",
	TIERED_PRICING_TEST_PHASE3 = "TIERED_PRICING_TEST_PHASE3",
	TIERED_PRICING_TEST_WEB = "TIERED_PRICING_TEST_WEB",
	TIERED_SUBSCRIPTIONS_LEGACY_TESTER = "TIERED_SUBSCRIPTIONS_LEGACY_TESTER",
	USE_ITERABLE_FOR_BALANCE_UPDATED = "USE_ITERABLE_FOR_BALANCE_UPDATED",
	USE_ITERABLE_FOR_LOAN_CYCLE = "USE_ITERABLE_FOR_LOAN_CYCLE",
	USE_ITERABLE_FOR_RECOVERY = "USE_ITERABLE_FOR_RECOVERY",
	USE_KYC_V2_FLOW = "USE_KYC_V2_FLOW",
	USE_KYC_V2_FLOW_CONTROL = "USE_KYC_V2_FLOW_CONTROL",
	USE_SIMULATED_PAYMENTS = "USE_SIMULATED_PAYMENTS"
}
export enum UserFriendlyCardNetwork {
	ACCEL = "ACCEL",
	AMEX = "AMEX",
	CCPAY = "CCPAY",
	CU24 = "CU24",
	DISCOVER = "DISCOVER",
	INTERNATIONAL = "INTERNATIONAL",
	MASTERCARD = "MASTERCARD",
	NYCE = "NYCE",
	PULSE = "PULSE",
	STAR = "STAR",
	UNKNOWN = "UNKNOWN",
	VISA = "VISA"
}
/** The type of prompt a user will see */
export enum UserPromptType {
	CHANGE_DUE_DATE_SCHEDULE = "CHANGE_DUE_DATE_SCHEDULE",
	PREMIUM_UPSELL_TOAST_PUSH_FEB2024 = "PREMIUM_UPSELL_TOAST_PUSH_FEB2024",
	RETRY_ATTEMPTS = "RETRY_ATTEMPTS"
}

type ZEUS_VARIABLES = {
	["BankProvider"]: ValueTypes["BankProvider"];
	["CampaignPromptType"]: ValueTypes["CampaignPromptType"];
	["ChangeSubscriptionTierOutcome"]: ValueTypes["ChangeSubscriptionTierOutcome"];
	["CopyColor"]: ValueTypes["CopyColor"];
	["CreatePayrollAccountOutcome"]: ValueTypes["CreatePayrollAccountOutcome"];
	["CreditBuilderEnrollmentIneligibilityReason"]: ValueTypes["CreditBuilderEnrollmentIneligibilityReason"];
	["CreditBuilderEnrollmentType"]: ValueTypes["CreditBuilderEnrollmentType"];
	["CreditBuilderPopulationType"]: ValueTypes["CreditBuilderPopulationType"];
	["CreditLimitBoostStatus"]: ValueTypes["CreditLimitBoostStatus"];
	["CreditMonitoringEnrollmentStatus"]: ValueTypes["CreditMonitoringEnrollmentStatus"];
	["CreditRating"]: ValueTypes["CreditRating"];
	["DayOfWeek"]: ValueTypes["DayOfWeek"];
	["DebtPersonaType"]: ValueTypes["DebtPersonaType"];
	["DeleteBankLoginOutcome"]: ValueTypes["DeleteBankLoginOutcome"];
	["DisableDirectDepositOutcome"]: ValueTypes["DisableDirectDepositOutcome"];
	["EducationLevel"]: ValueTypes["EducationLevel"];
	["EmploymentPayFrequency"]: ValueTypes["EmploymentPayFrequency"];
	["EmploymentStatus"]: ValueTypes["EmploymentStatus"];
	["ExperimentName"]: ValueTypes["ExperimentName"];
	["FrontEndCreditMonitoringEnrollment"]: ValueTypes["FrontEndCreditMonitoringEnrollment"];
	["FrontendAccountApprovalState"]: ValueTypes["FrontendAccountApprovalState"];
	["FrontendBankConnectionStatus"]: ValueTypes["FrontendBankConnectionStatus"];
	["FrontendBankInstitution"]: ValueTypes["FrontendBankInstitution"];
	["FrontendCBScheduleVersionReason"]: ValueTypes["FrontendCBScheduleVersionReason"];
	["FrontendCBScheduleVersionStatus"]: ValueTypes["FrontendCBScheduleVersionStatus"];
	["FrontendCreditBuilderEnrollmentStatus"]: ValueTypes["FrontendCreditBuilderEnrollmentStatus"];
	["FrontendCreditBuilderPaymentRetryStrategyType"]: ValueTypes["FrontendCreditBuilderPaymentRetryStrategyType"];
	["FrontendCreditBuilderReminderType"]: ValueTypes["FrontendCreditBuilderReminderType"];
	["FrontendCreditBuilderSweepStakesQualificationType"]: ValueTypes["FrontendCreditBuilderSweepStakesQualificationType"];
	["FrontendCreditBuilderTransactionSource"]: ValueTypes["FrontendCreditBuilderTransactionSource"];
	["FrontendCreditBuilderTransactionStatus"]: ValueTypes["FrontendCreditBuilderTransactionStatus"];
	["FrontendCreditBuilderTransactionType"]: ValueTypes["FrontendCreditBuilderTransactionType"];
	["FrontendCreditLimitBoostStatus"]: ValueTypes["FrontendCreditLimitBoostStatus"];
	["FrontendCreditRiskState"]: ValueTypes["FrontendCreditRiskState"];
	["FrontendDebitCardStatus"]: ValueTypes["FrontendDebitCardStatus"];
	["FrontendInitiateCreditDrawStatus"]: ValueTypes["FrontendInitiateCreditDrawStatus"];
	["FrontendInstallmentLoanChargePolicy"]: ValueTypes["FrontendInstallmentLoanChargePolicy"];
	["FrontendInstallmentLoanPaymentStrategy"]: ValueTypes["FrontendInstallmentLoanPaymentStrategy"];
	["FrontendInstallmentLoansAccountCreationStatus"]: ValueTypes["FrontendInstallmentLoansAccountCreationStatus"];
	["FrontendInstallmentLoansAutoPay"]: ValueTypes["FrontendInstallmentLoansAutoPay"];
	["FrontendInstallmentLoansCurrentStatus"]: ValueTypes["FrontendInstallmentLoansCurrentStatus"];
	["FrontendInstallmentLoansDisbursalStatus"]: ValueTypes["FrontendInstallmentLoansDisbursalStatus"];
	["FrontendInstallmentLoansKYCDecisionType"]: ValueTypes["FrontendInstallmentLoansKYCDecisionType"];
	["FrontendInstallmentLoansManualPaymentEligibilityStatus"]: ValueTypes["FrontendInstallmentLoansManualPaymentEligibilityStatus"];
	["FrontendInstallmentLoansPayFrequency"]: ValueTypes["FrontendInstallmentLoansPayFrequency"];
	["FrontendInstallmentLoansPaymentOutcome"]: ValueTypes["FrontendInstallmentLoansPaymentOutcome"];
	["FrontendInstallmentLoansStatus"]: ValueTypes["FrontendInstallmentLoansStatus"];
	["FrontendInstallmentLoansUpdateAutoPayOutcome"]: ValueTypes["FrontendInstallmentLoansUpdateAutoPayOutcome"];
	["FrontendLocAccountCreationStatus"]: ValueTypes["FrontendLocAccountCreationStatus"];
	["FrontendLocAccountStatus"]: ValueTypes["FrontendLocAccountStatus"];
	["FrontendLocAutopayStatus"]: ValueTypes["FrontendLocAutopayStatus"];
	["FrontendLocInitiatePaymentStatus"]: ValueTypes["FrontendLocInitiatePaymentStatus"];
	["FrontendLocKycRequirementStatus"]: ValueTypes["FrontendLocKycRequirementStatus"];
	["FrontendLocKycUnfulfillmentReasons"]: ValueTypes["FrontendLocKycUnfulfillmentReasons"];
	["FrontendLocMinPaymentStatus"]: ValueTypes["FrontendLocMinPaymentStatus"];
	["FrontendLocPaymentAttemptType"]: ValueTypes["FrontendLocPaymentAttemptType"];
	["FrontendLocUnderwritingStatus"]: ValueTypes["FrontendLocUnderwritingStatus"];
	["FrontendLocUserInitiatedPaymentChargeType"]: ValueTypes["FrontendLocUserInitiatedPaymentChargeType"];
	["FrontendMobileVersionSupportType"]: ValueTypes["FrontendMobileVersionSupportType"];
	["FrontendOngoingRefreshStatus"]: ValueTypes["FrontendOngoingRefreshStatus"];
	["FrontendPaymentAuthFlow"]: ValueTypes["FrontendPaymentAuthFlow"];
	["FrontendPaymentStatus"]: ValueTypes["FrontendPaymentStatus"];
	["FrontendPayrollAccountConnectionStatus"]: ValueTypes["FrontendPayrollAccountConnectionStatus"];
	["FrontendReturnType"]: ValueTypes["FrontendReturnType"];
	["FrontendSampleKeyType"]: ValueTypes["FrontendSampleKeyType"];
	["HeroIconSize"]: ValueTypes["HeroIconSize"];
	["IdentityProtectionEnrollmentStatus"]: ValueTypes["IdentityProtectionEnrollmentStatus"];
	["ImpressionableElement"]: ValueTypes["ImpressionableElement"];
	["InputSalaryDataOutcome"]: ValueTypes["InputSalaryDataOutcome"];
	["KeyboardType"]: ValueTypes["KeyboardType"];
	["LoanPurpose"]: ValueTypes["LoanPurpose"];
	["LoanTimelineStatus"]: ValueTypes["LoanTimelineStatus"];
	["LoanTimelineType"]: ValueTypes["LoanTimelineType"];
	["LoginFailureReason"]: ValueTypes["LoginFailureReason"];
	["MarkNotificationCenterEntriesViewedOutcome"]: ValueTypes["MarkNotificationCenterEntriesViewedOutcome"];
	["MarketplaceBadgeType"]: ValueTypes["MarketplaceBadgeType"];
	["MarketplaceFeature"]: ValueTypes["MarketplaceFeature"];
	["ModelRank"]: ValueTypes["ModelRank"];
	["NonRepaymentReason"]: ValueTypes["NonRepaymentReason"];
	["OneTimePaymentOutcome"]: ValueTypes["OneTimePaymentOutcome"];
	["OngoingRefreshStatus"]: ValueTypes["OngoingRefreshStatus"];
	["OverdraftLikelihood"]: ValueTypes["OverdraftLikelihood"];
	["PayFrequency"]: ValueTypes["PayFrequency"];
	["PayrollAccountConnectionStatus"]: ValueTypes["PayrollAccountConnectionStatus"];
	["PayrollAccountFetcherOutcome"]: ValueTypes["PayrollAccountFetcherOutcome"];
	["PayrollProvider"]: ValueTypes["PayrollProvider"];
	["PersistedIncomeStatus"]: ValueTypes["PersistedIncomeStatus"];
	["PremiumUpsellCardVariant"]: ValueTypes["PremiumUpsellCardVariant"];
	["Product"]: ValueTypes["Product"];
	["ProductSubType"]: ValueTypes["ProductSubType"];
	["PromptResponseType"]: ValueTypes["PromptResponseType"];
	["PropertyStatus"]: ValueTypes["PropertyStatus"];
	["ProtectionMediumType"]: ValueTypes["ProtectionMediumType"];
	["ProtectionStatusV4"]: ValueTypes["ProtectionStatusV4"];
	["QuizFeedbackType"]: ValueTypes["QuizFeedbackType"];
	["QuizName"]: ValueTypes["QuizName"];
	["QuizStepType"]: ValueTypes["QuizStepType"];
	["RecentScoreChangeText"]: ValueTypes["RecentScoreChangeText"];
	["RegisterBankLoginMaintainingExistingLoginOutcome"]: ValueTypes["RegisterBankLoginMaintainingExistingLoginOutcome"];
	["RepayAdvanceOutcome"]: ValueTypes["RepayAdvanceOutcome"];
	["RequestManualDisbursalOutcome"]: ValueTypes["RequestManualDisbursalOutcome"];
	["ResendAuthVerificationCodeOutcome"]: ValueTypes["ResendAuthVerificationCodeOutcome"];
	["SalaryAggregateFilterCategory"]: ValueTypes["SalaryAggregateFilterCategory"];
	["SamplingTrigger"]: ValueTypes["SamplingTrigger"];
	["ScheduleCreditBuilderSameDayManualPaymentOutcome"]: ValueTypes["ScheduleCreditBuilderSameDayManualPaymentOutcome"];
	["ScoreChangeCallToAction"]: ValueTypes["ScoreChangeCallToAction"];
	["SubmitUserPromptOutcome"]: ValueTypes["SubmitUserPromptOutcome"];
	["SubscriptionFeeSchedule"]: ValueTypes["SubscriptionFeeSchedule"];
	["SubscriptionStatus"]: ValueTypes["SubscriptionStatus"];
	["SubscriptionTierExperimentName"]: ValueTypes["SubscriptionTierExperimentName"];
	["SubscriptionTierLevel"]: ValueTypes["SubscriptionTierLevel"];
	["SwitchProtectedLoginToExistingLoginOutcome"]: ValueTypes["SwitchProtectedLoginToExistingLoginOutcome"];
	["SwitchProtectedLoginToExistingNonConnectedLoginOutcome"]: ValueTypes["SwitchProtectedLoginToExistingNonConnectedLoginOutcome"];
	["TablessMarketplaceCategoryType"]: ValueTypes["TablessMarketplaceCategoryType"];
	["TextFormatter"]: ValueTypes["TextFormatter"];
	["TextInputValidators"]: ValueTypes["TextInputValidators"];
	["TextInputWarning"]: ValueTypes["TextInputWarning"];
	["TrackNewImpressionsOutcome"]: ValueTypes["TrackNewImpressionsOutcome"];
	["TrackNotificationCenterEntryClickOutcome"]: ValueTypes["TrackNotificationCenterEntryClickOutcome"];
	["UXPromptResponseType"]: ValueTypes["UXPromptResponseType"];
	["UnderwritingCategory"]: ValueTypes["UnderwritingCategory"];
	["UnderwritingRule"]: ValueTypes["UnderwritingRule"];
	["UpdateBankAccountJointStatusOutcome"]: ValueTypes["UpdateBankAccountJointStatusOutcome"];
	["UserFlag"]: ValueTypes["UserFlag"];
	["UserFriendlyCardNetwork"]: ValueTypes["UserFriendlyCardNetwork"];
	["UserPromptType"]: ValueTypes["UserPromptType"];
	["BigDecimal"]: ValueTypes["BigDecimal"];
	["Date"]: ValueTypes["Date"];
	["Long"]: ValueTypes["Long"];
	["UUID"]: ValueTypes["UUID"];
}