import React, { FunctionComponent } from 'react';
import { WrappedFieldProps } from 'redux-form';

export const Checkbox: FunctionComponent<WrappedFieldProps> = (props) => {
  const {
    input: { onChange, value },
    children,
  } = props;

  return (
    <div onClick={() => onChange(!value)} className="mb-3 d-flex">
      <img className="pr-3" src={value ? '/checked_box.svg' : '/unchecked_box.svg'} />
      <div>{children}</div>
    </div>
  );
};
