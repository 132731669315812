import { ConnectorProps } from '@hellobrigit/brigit-common';
import { FadeIn } from 'animate-components';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { SetStepAction } from '../../actions/nextStepActions';
import { OnboardingStep } from '../../constants/OnboardingSteps';

const connector = connect(null, { setStep: SetStepAction });

type Props = ConnectorProps<typeof connector>;

const WaitlistBase: FunctionComponent<Props> = ({ setStep }) => {
  return (
    <FadeIn duration="1s">
      <Row className="justify-content-center title-margin-top brigit-row">
        <Col lg="6" md="6" sm="12">
          <div className="d-flex flex-column text-center">
            <img className="mb-lg-5 mb-2" style={{ height: '150px' }} src="/map_new.svg" />
            <h2>You're on the Waitlist!</h2>
            <div className="title-subtext mb-5 d-flex justify-content-center">
              <div style={{ width: '80%', lineHeight: '36px' }}>
                Brigit is not yet available in your state, but we are working hard to launch
                nationally in the coming weeks.
                <br />
                <br />
                As soon as we become available, we'll send you an email with your Access Code.{' '}
                <br />
                <br />
                Have a code? Enter
                <span
                  onClick={() => setStep({ step: OnboardingStep.ENTER_CODE })}
                  className="font-dark-green semi-bold pointer"
                >
                  {' '}
                  here.
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </FadeIn>
  );
};

export const Waitlist = connector(WaitlistBase);
