import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { dangerLight } from '../../utils/colors';
import { H5, P1 } from '../Typography';

const WARNING_ICON_SRC = '/icons/red-exclamation.png';

interface Props {
  errors: (string | JSX.Element)[];
}

const RecoveryErrorAlertItem: FunctionComponent<{ error: string | JSX.Element }> = ({ error }) => (
  <div className="d-flex" style={{ paddingBottom: '15px' }}>
    <img src={WARNING_ICON_SRC} height="15px" />
    <P1 className="mb-0" style={{ paddingLeft: '10px', color: dangerLight }}>
      {error}
    </P1>
  </div>
);

export const RecoveryErrorAlert: FunctionComponent<Props> = ({ errors }) => {
  // eslint-disable-next-line react/no-array-index-key
  const errorItems = errors.map((e, idx) => <RecoveryErrorAlertItem key={idx} error={e} />);

  return (
    <Row
      style={{
        paddingTop: '15px',
        paddingLeft: '5px',
        paddingRight: '5px',
        backgroundColor: 'rgb(245,55,88, 0.13)',
      }}
    >
      <Col>
        <H5 className="text-lg-center mb-0" style={{ paddingBottom: '10px', color: dangerLight }}>
          There was a problem:
        </H5>
        <div className="d-flex flex-column align-items-lg-center">{errorItems}</div>
      </Col>
    </Row>
  );
};
