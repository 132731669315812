import { Store } from 'redux';
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AppState } from '../store/types';
import { setAxiosAuthHeader } from '../utils/setAxiosAuthHeader';
import { abTestsEpic } from './abTests';
import { analyticsEpic } from './analytics';

export function observeStore(store: Store<AppState>) {
  const state$ = new Observable<AppState>((observer) =>
    store.subscribe(() => observer.next(store.getState())),
  );
  state$
    .pipe(
      map((state) => state.user.token),
      filter((token) => token !== undefined),
      distinctUntilChanged(),
    )
    .subscribe((token) => {
      setAxiosAuthHeader(token);
    });
}

export const rootEpic = combineEpics(analyticsEpic, abTestsEpic);
