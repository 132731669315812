import { RecoveryPaymentSource } from '@hellobrigit/brigit-rest-api';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { RecoveryLocationState } from '../../containers/types';
import {
  BankRecoveryRoutes,
  DebitRecoveryRoutes,
  InstallmentRoutes,
  RecoveryRoutes,
} from '../../utils/routes';
import { H4 } from '../Typography';
import { BankBreadCrumbs } from './bank/BankBreadCrumbs';
import { DebitBreadCrumbs } from './debit/DebitBreadCrumbs';

interface OwnProps {
  paymentMethod: RecoveryPaymentSource;
  activeRoute: DebitRecoveryRoutes | BankRecoveryRoutes | RecoveryRoutes | InstallmentRoutes;
}

type Props = OwnProps & RecoveryLocationState;

export const RepaymentHeader: FunctionComponent<Props> = (props) => {
  const { paymentMethod, payNow, activeRoute } = props;
  const isReviewInstallmentDetails = activeRoute === InstallmentRoutes.REVIEW_DETAILS;

  const breadcrumbs =
    paymentMethod === RecoveryPaymentSource.CARD ? (
      <DebitBreadCrumbs
        activeRoute={activeRoute as DebitRecoveryRoutes | RecoveryRoutes}
        payNow={payNow}
      />
    ) : (
      <BankBreadCrumbs
        activeRoute={activeRoute as BankRecoveryRoutes | RecoveryRoutes}
        payNow={payNow}
      />
    );

  return (
    <Row
      className="bg-dark-green font-white"
      style={{ height: isReviewInstallmentDetails ? '78px' : '110px' }}
    >
      <Col>
        <div className="d-lg-flex flex-lg-column align-items-lg-center d-block">
          <H4 className="font-white mb-0" style={{ marginTop: 10 }}>
            Repayment plan
          </H4>
          {!isReviewInstallmentDetails && breadcrumbs}
        </div>
      </Col>
    </Row>
  );
};
