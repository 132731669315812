import { ApolloError } from '@apollo/client/errors';
import { isNil } from 'lodash';
import { Middleware } from 'redux';
import {
  ChangeSubscriptionTierAction,
  GetSubscriptionsProposedBillDateAction,
  GetSubscriptionsScheduleTextAction,
  LoadSubscriptionTiersAction,
  SetChangeSubscriptionTierErrorAction,
  SetChangeSubscriptionTierLoadingAction,
  SetLoadSubscriptionTiersLoadingAction,
  SetSubscriptionsProposedBillDateAction,
  SetSubscriptionsScheduleTextAction,
  SetSubscriptionTiersLoadedAction,
  UpdateCurrentSubscriptionTierAction,
} from '../actions/subscriptionsActions';
import { Product, SubscriptionTierLevel } from '../graphql/zeus';
import { GraphQL } from '../services/graphql';
import { SubscriptionType } from '../store/types';

// Represents the SubscriptionTier object that gets returned from the GraphQL query
interface SubscriptionTierResponse {
  level: SubscriptionTierLevel;
  displayName: string;
  pricingAmount: unknown;
  products: Product[];
}

const mapResponseToSubscriptionTier = ({
  displayName,
  level,
  pricingAmount,
  products,
}: SubscriptionTierResponse) => ({
  displayName,
  level,
  pricingAmount: pricingAmount as number,
  products,
});

const getSubscriptionTypeFromStrategy = (strategyName: string | undefined): SubscriptionType => {
  if (isNil(strategyName)) {
    return SubscriptionType.DEFAULT;
  }

  return SubscriptionType.TIERED_SUBSCRIPTIONS_NEW_TIERS;
};

export const subscriptionsMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);

  if (LoadSubscriptionTiersAction.is(action)) {
    store.dispatch(SetLoadSubscriptionTiersLoadingAction.create(true));
    GraphQL.query(
      {
        availableSubscriptionTiers: {
          displayName: true,
          level: true,
          pricingAmount: true,
          products: true,
        },
        me: {
          subscription: {
            currentTier: { level: true, displayName: true, pricingAmount: true, products: true },
            subscriptionTierStrategyName: true,
          },
        },
      },
      { operationName: 'FetchSubscriptionInformation' },
    )
      .then(
        ({
          me: {
            subscription: { currentTier, subscriptionTierStrategyName },
          },
          availableSubscriptionTiers,
        }) => {
          const mappedTiers = availableSubscriptionTiers.map((t) =>
            mapResponseToSubscriptionTier(t),
          );

          store.dispatch(
            SetSubscriptionTiersLoadedAction.create({
              currentSubscriptionTier: mapResponseToSubscriptionTier(currentTier),
              availableSubscriptionTiers: mappedTiers,
              subscriptionType: getSubscriptionTypeFromStrategy(subscriptionTierStrategyName),
            }),
          );
        },
      )
      .finally(() => {
        store.dispatch(SetLoadSubscriptionTiersLoadingAction.create(false));
      });
  }

  if (ChangeSubscriptionTierAction.is(action)) {
    store.dispatch(SetChangeSubscriptionTierLoadingAction.create());

    const {
      payload: { subscriptionTierLevel, loanAmount, mediumType, previousTierLevel },
    } = action;

    GraphQL.mutate(
      {
        changeSubscriptionTier: [
          { subscriptionTierLevel, loanAmount, mediumType },
          {
            updatedSubscription: {
              currentTier: { displayName: true, level: true, pricingAmount: true, products: true },
            },
            outcome: true,
          },
        ],
      },
      { operationName: 'ChangeSubscriptionTier' },
    )
      .then(
        ({
          changeSubscriptionTier: {
            updatedSubscription: { currentTier },
            outcome,
          },
        }) => {
          store.dispatch(
            UpdateCurrentSubscriptionTierAction.create({
              currentSubscriptionTier: mapResponseToSubscriptionTier(currentTier),
              changeSubscriptionTierOutcome: outcome,
            }),
          );
          if (
            previousTierLevel === SubscriptionTierLevel.FREE &&
            subscriptionTierLevel !== SubscriptionTierLevel.FREE
          ) {
            analytics.track('Enable Protection');
          }
        },
      )
      .catch((e) => {
        if (e instanceof ApolloError) {
          const { graphQLErrors } = e;
          if (graphQLErrors && graphQLErrors.length > 0) {
            const {
              message,
              extensions: { userFacingMessage },
            } = graphQLErrors[0];

            store.dispatch(
              SetChangeSubscriptionTierErrorAction.create({
                message,
                userFacingMessage: userFacingMessage as string,
              }),
            );
          }
          return;
        }

        const {
          response: { errors },
        } = e;
        const {
          message,
          extensions: { userFacingMessage },
        } = errors[0];

        store.dispatch(
          SetChangeSubscriptionTierErrorAction.create({
            message,
            userFacingMessage,
          }),
        );
      });
  }

  if (GetSubscriptionsScheduleTextAction.is(action)) {
    // clean the old value
    store.dispatch(
      SetSubscriptionsScheduleTextAction.create({
        subscriptionScheduleText: '',
      }),
    );

    const {
      payload: { subscriptionTierLevel },
    } = action;
    GraphQL.query(
      {
        me: {
          subscriptionScheduleText: [{ level: subscriptionTierLevel }, true],
        },
      },
      { operationName: 'FetchSubscriptionScheduleText' },
    )
      .then((response) => {
        const {
          me: { subscriptionScheduleText },
        } = response;
        store.dispatch(
          SetSubscriptionsScheduleTextAction.create({
            subscriptionScheduleText,
          }),
        );
      })
      .catch(() => {
        store.dispatch(
          SetSubscriptionsScheduleTextAction.create({
            subscriptionScheduleText: '',
          }),
        );
      });
  }

  if (GetSubscriptionsProposedBillDateAction.is(action)) {
    GraphQL.query(
      {
        me: { subscription: { proposedBillDate: true } },
      },
      { operationName: 'FetchSubscriptionProposedBillingDate' },
    )
      .then((response) => {
        const {
          me: {
            subscription: { proposedBillDate },
          },
        } = response;
        store.dispatch(
          SetSubscriptionsProposedBillDateAction.create({
            proposedBillDate,
          }),
        );
      })
      .catch(() => {
        store.dispatch(
          SetSubscriptionsProposedBillDateAction.create({
            proposedBillDate: '',
          }),
        );
      });
  }
  return result;
};
