export const OnboardingRoutes = {
  EMAIL_NUMBER: '/signup/email',
  AUTH_PHONE: '/auth_phone',
  AUTH_EMAIL: '/auth_email',
  SET_PIN: '/signup/set_pin',
  SET_NAME: '/signup/set_name',
  LINK_BANK: '/signup/link_bank',
  SELECT_ACCOUNT: '/signup/select_account',
  WAIT_FOR_BANK: '/signup/bank',
  HOW_IT_WORKS: '/signup/how_it_works',
  WAITLIST: '/signup/waitlist',
  ENTER_CODE: '/signup/enter_code',
  LOGIN: '/login',
  AUTH_PIN: '/login/auth_pin',
  RELINK_BANK: '/home',
  ACTIVATE_SAFETY: '/signup/activate_safety',
  DENIED_PROTECTION: '/signup/denied_protection',
  HOME: '/home',
};

export enum OnboardingStep {
  EMAIL_NUMBER = 'EMAIL_NUMBER',
  AUTH_PHONE = 'AUTH_PHONE',
  AUTH_EMAIL = 'AUTH_EMAIL',
  SET_PIN = 'SET_PIN',
  SET_NAME = 'SET_NAME',
  LINK_BANK = 'LINK_BANK',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  WAIT_FOR_BANK = 'WAIT_FOR_BANK',
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  WAITLIST = 'WAITLIST',
  ENTER_CODE = 'ENTER_CODE',
  LOGIN = 'LOGIN',
  AUTH_PIN = 'AUTH_PIN',
  RELINK_BANK = 'RELINK_BANK',
  ACTIVATE_SAFETY = 'ACTIVATE_SAFETY',
  DENIED_PROTECTION = 'DENIED_PROTECTION',
  HOME = 'HOME',
}

export enum SignupSource {
  WEB = 'WEB',
}
