import { Middleware } from 'redux';
import { LoadLocationStateAction, SetLocationStateAction } from '../actions/underwritingActions';
import { GraphQL } from '../services/graphql';

export const underwritingMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);

  if (LoadLocationStateAction.is(action)) {
    GraphQL.query(
      {
        me: {
          locationState: { name: true },
        },
      },
      { operationName: 'FetchLocationState' },
    )
      .then(({ me }) => {
        store.dispatch(SetLocationStateAction.create({ locationState: me?.locationState?.name }));
      })
      .catch(() => {
        store.dispatch(SetLocationStateAction.create({ locationState: undefined }));
      });
  }

  return result;
};
