/**
 * https://plaid.com/docs/#event-names
 */
export type OnEventEventName =
  | 'ERROR'
  | 'EXIT'
  | 'HANDOFF'
  | 'OPEN'
  | 'OPEN_MY_PLAID'
  | 'SEARCH_INSTITUTION'
  | 'SELECT_INSTITUTION'
  | 'SUBMIT_CREDENTIALS'
  | 'SUBMIT_MFA'
  | 'TRANSITION_VIEW';

/**
 * https://plaid.com/docs/#metadata-view_name
 */
type OnEventViewName =
  | 'CONNECTED'
  | 'CREDENTIAL'
  | 'ERROR'
  | 'EXIT'
  | 'LOADING'
  | 'MFA'
  | 'RECAPTCHA'
  | 'SELECT_ACCOUNT'
  | 'SELECT_INSTITUTION';

/**
 * https://plaid.com/docs/#metadata-reference
 */
export interface OnEventMetadata {
  error_code?: string;
  error_message?: string;
  error_type?: string;
  exit_status?: string;
  event_name: OnEventEventName;
  institution_id: string;
  institution_name: string;
  institution_search_query: string;
  request_id: string;
  link_session_id: string;
  mfa_type: string;
  view_name: OnEventViewName;
  timestamp: string;
}

interface Props {
  disabled?: boolean;
  token: string;
  onSuccess: (publicToken: string, metadata?: object) => void;
  onExit?: (err?: string, metadata?: object) => void;
  onEvent?: (eventName: OnEventEventName, metadata?: OnEventMetadata) => void;
}

export const PlaidLinkService = {
  open: (props: Props) => {
    const { token, onEvent, onSuccess, onExit } = props;
    window.Plaid.create({
      token,
      apiVersion: 'v2',
      clientName: 'Brigit',
      onSuccess,
      onEvent,
      onExit,
    }).open();
  },
};
