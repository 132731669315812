/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	CreditBuilderInstallmentLoan:{
		proposedDraftInstallmentLoanPaymentSchedule:{
			reason:"FrontendCBScheduleVersionReason"
		}
	},
	InstallmentLoans:{
		signedLoanAgreement:{

		}
	},
	LineOfCredit:{
		paymentsBreakdown:{
			creditDrawAmountInCents:"Long"
		}
	},
	Mutation:{
		accountConnected:{

		},
		changeSubscriptionTier:{
			loanAmount:"BigDecimal",
			mediumType:"ProtectionMediumType",
			subscriptionTierLevel:"SubscriptionTierLevel"
		},
		completeQuiz:{

		},
		createCreditBuilderUserAccount:{
			chargePolicy:"FrontendInstallmentLoanChargePolicy",
			userContributionInCents:"Long"
		},
		createCreditMonitoringUser:{
			enrollment:"FrontEndCreditMonitoringEnrollment"
		},
		createInstallmentLoansAccount:{
			autoPayStatus:"FrontendInstallmentLoansAutoPay"
		},
		createPayrollAccount:{

		},
		createPayrollAccountV2:{

		},
		deleteBankLogin:{

		},
		deletePayrollAccount:{

		},
		disableDirectDeposits:{

		},
		disablePayrollAccount:{

		},
		initiateCatchUpPaymentForInstallmentLoan:{

		},
		initiateCreditDraw:{
			creditDrawAmount:"Long",
			minPaymentAmount:"Long"
		},
		initiateOneTimePayment:{

		},
		initiatePayOffPaymentForInstallmentLoan:{

		},
		initiatePrePaymentForInstallmentLoan:{

		},
		initiateUserPaymentForLoc:{
			chargeType:"FrontendLocUserInitiatedPaymentChargeType"
		},
		inputSalaryData:{
			averageSalary:"Long"
		},
		login:{

		},
		markNotificationCenterEntriesViewed:{
			notificationIds:"Long"
		},
		openLocAccount:{
			userSetAutoPayStatus:"FrontendLocAutopayStatus"
		},
		publishCreditBuilderDraftPaymentSchedule:{
			reason:"FrontendCBScheduleVersionReason"
		},
		recordUserPromptResponse:{
			promptType:"CampaignPromptType",
			responseType:"PromptResponseType"
		},
		registerBankLoginMaintainingExistingLogin:{
			paymentAuthFlow:"FrontendPaymentAuthFlow",
			provider:"BankProvider"
		},
		registerOneTimeCard:{

		},
		registerOneTimeCardV2:{

		},
		repayAdvance:{

		},
		requestManualDisbursal:{
			amount:"BigDecimal"
		},
		resendAuthVerificationCode:{

		},
		startOrResumeQuiz:{
			name:"QuizName"
		},
		submitCreditBuilderPaymentChargePolicy:{
			chargePolicy:"FrontendInstallmentLoanChargePolicy"
		},
		submitCreditBuilderPaymentStrategy:{
			retryStrategyType:"FrontendCreditBuilderPaymentRetryStrategyType"
		},
		submitMoneyLionPersonalLoansQuiz:{
			creditRating:"CreditRating",
			educationLevel:"EducationLevel",
			employmentPayFrequency:"EmploymentPayFrequency",
			employmentStatus:"EmploymentStatus",
			propertyStatus:"PropertyStatus",
			purpose:"LoanPurpose"
		},
		submitQuizFeedback:{
			feedbackType:"QuizFeedbackType"
		},
		submitQuizResponse:{

		},
		submitResponseForPromptType:{
			promptResponseType:"PromptResponseType",
			promptType:"UserPromptType"
		},
		switchProtectedLoginToExistingLogin:{

		},
		switchProtectedLoginToExistingNonConnectedLogin:{

		},
		testDisablePayrollAccount:{
			payrollProvider:"PayrollProvider"
		},
		throwTestException:{

		},
		trackImpressionEvent:{

		},
		trackNotificationCenterEntryClick:{
			notificationId:"Long"
		},
		updateAutoPayStatusForInstallmentLoan:{
			newAutoPayStatus:"FrontendInstallmentLoansAutoPay"
		},
		updateAutopayStatusForLoc:{
			newAutopayStatus:"FrontendLocAutopayStatus"
		},
		updateBankAccountJointStatus:{

		},
		updateCreditLimitBoostStatus:{
			creditLimitBoostStatus:"FrontendCreditLimitBoostStatus"
		},
		updatePaymentChargeTypeForLoc:{
			newPaymentChargeType:"FrontendLocUserInitiatedPaymentChargeType"
		}
	},
	Query:{
		experimentVariantAssignment:{
			name:"ExperimentName",
			sampleKeyType:"FrontendSampleKeyType",
			samplingTrigger:"SamplingTrigger"
		},
		marketplaceMaintenance:{
			feature:"MarketplaceFeature"
		}
	},
	Subscription:{
		subscriptionTierExperiment:{
			experimentName:"SubscriptionTierExperimentName"
		}
	},
	UserAccount:{
		creditBuilderEnrollmentEligibility:{
			reasons:"CreditBuilderEnrollmentIneligibilityReason"
		},
		incomeProfile:{
			filterOne:"SalaryAggregateFilterCategory",
			filterTwo:"SalaryAggregateFilterCategory"
		},
		promotion:{
			level:"SubscriptionTierLevel"
		},
		subscriptionScheduleText:{
			level:"SubscriptionTierLevel"
		}
	},
	BankProvider: "enum" as const,
	CampaignPromptType: "enum" as const,
	ChangeSubscriptionTierOutcome: "enum" as const,
	CopyColor: "enum" as const,
	CreatePayrollAccountOutcome: "enum" as const,
	CreditBuilderEnrollmentIneligibilityReason: "enum" as const,
	CreditBuilderEnrollmentType: "enum" as const,
	CreditBuilderPopulationType: "enum" as const,
	CreditLimitBoostStatus: "enum" as const,
	CreditMonitoringEnrollmentStatus: "enum" as const,
	CreditRating: "enum" as const,
	DayOfWeek: "enum" as const,
	DebtPersonaType: "enum" as const,
	DeleteBankLoginOutcome: "enum" as const,
	DisableDirectDepositOutcome: "enum" as const,
	EducationLevel: "enum" as const,
	EmploymentPayFrequency: "enum" as const,
	EmploymentStatus: "enum" as const,
	ExperimentName: "enum" as const,
	FrontEndCreditMonitoringEnrollment: "enum" as const,
	FrontendAccountApprovalState: "enum" as const,
	FrontendBankConnectionStatus: "enum" as const,
	FrontendBankInstitution: "enum" as const,
	FrontendCBScheduleVersionReason: "enum" as const,
	FrontendCBScheduleVersionStatus: "enum" as const,
	FrontendCreditBuilderEnrollmentStatus: "enum" as const,
	FrontendCreditBuilderPaymentRetryStrategyType: "enum" as const,
	FrontendCreditBuilderReminderType: "enum" as const,
	FrontendCreditBuilderSweepStakesQualificationType: "enum" as const,
	FrontendCreditBuilderTransactionSource: "enum" as const,
	FrontendCreditBuilderTransactionStatus: "enum" as const,
	FrontendCreditBuilderTransactionType: "enum" as const,
	FrontendCreditLimitBoostStatus: "enum" as const,
	FrontendCreditRiskState: "enum" as const,
	FrontendDebitCardStatus: "enum" as const,
	FrontendInitiateCreditDrawStatus: "enum" as const,
	FrontendInstallmentLoanChargePolicy: "enum" as const,
	FrontendInstallmentLoanPaymentStrategy: "enum" as const,
	FrontendInstallmentLoansAccountCreationStatus: "enum" as const,
	FrontendInstallmentLoansAutoPay: "enum" as const,
	FrontendInstallmentLoansCurrentStatus: "enum" as const,
	FrontendInstallmentLoansDisbursalStatus: "enum" as const,
	FrontendInstallmentLoansKYCDecisionType: "enum" as const,
	FrontendInstallmentLoansManualPaymentEligibilityStatus: "enum" as const,
	FrontendInstallmentLoansPayFrequency: "enum" as const,
	FrontendInstallmentLoansPaymentOutcome: "enum" as const,
	FrontendInstallmentLoansStatus: "enum" as const,
	FrontendInstallmentLoansUpdateAutoPayOutcome: "enum" as const,
	FrontendLocAccountCreationStatus: "enum" as const,
	FrontendLocAccountStatus: "enum" as const,
	FrontendLocAutopayStatus: "enum" as const,
	FrontendLocInitiatePaymentStatus: "enum" as const,
	FrontendLocKycRequirementStatus: "enum" as const,
	FrontendLocKycUnfulfillmentReasons: "enum" as const,
	FrontendLocMinPaymentStatus: "enum" as const,
	FrontendLocPaymentAttemptType: "enum" as const,
	FrontendLocUnderwritingStatus: "enum" as const,
	FrontendLocUserInitiatedPaymentChargeType: "enum" as const,
	FrontendMobileVersionSupportType: "enum" as const,
	FrontendOngoingRefreshStatus: "enum" as const,
	FrontendPaymentAuthFlow: "enum" as const,
	FrontendPaymentStatus: "enum" as const,
	FrontendPayrollAccountConnectionStatus: "enum" as const,
	FrontendReturnType: "enum" as const,
	FrontendSampleKeyType: "enum" as const,
	HeroIconSize: "enum" as const,
	IdentityProtectionEnrollmentStatus: "enum" as const,
	ImpressionableElement: "enum" as const,
	InputSalaryDataOutcome: "enum" as const,
	KeyboardType: "enum" as const,
	LoanPurpose: "enum" as const,
	LoanTimelineStatus: "enum" as const,
	LoanTimelineType: "enum" as const,
	LoginFailureReason: "enum" as const,
	MarkNotificationCenterEntriesViewedOutcome: "enum" as const,
	MarketplaceBadgeType: "enum" as const,
	MarketplaceFeature: "enum" as const,
	ModelRank: "enum" as const,
	NonRepaymentReason: "enum" as const,
	OneTimePaymentOutcome: "enum" as const,
	OngoingRefreshStatus: "enum" as const,
	OverdraftLikelihood: "enum" as const,
	PayFrequency: "enum" as const,
	PayrollAccountConnectionStatus: "enum" as const,
	PayrollAccountFetcherOutcome: "enum" as const,
	PayrollProvider: "enum" as const,
	PersistedIncomeStatus: "enum" as const,
	PremiumUpsellCardVariant: "enum" as const,
	Product: "enum" as const,
	ProductSubType: "enum" as const,
	PromptResponseType: "enum" as const,
	PropertyStatus: "enum" as const,
	ProtectionMediumType: "enum" as const,
	ProtectionStatusV4: "enum" as const,
	QuizFeedbackType: "enum" as const,
	QuizName: "enum" as const,
	QuizStepType: "enum" as const,
	RecentScoreChangeText: "enum" as const,
	RegisterBankLoginMaintainingExistingLoginOutcome: "enum" as const,
	RepayAdvanceOutcome: "enum" as const,
	RequestManualDisbursalOutcome: "enum" as const,
	ResendAuthVerificationCodeOutcome: "enum" as const,
	SalaryAggregateFilterCategory: "enum" as const,
	SamplingTrigger: "enum" as const,
	ScheduleCreditBuilderSameDayManualPaymentOutcome: "enum" as const,
	ScoreChangeCallToAction: "enum" as const,
	SubmitUserPromptOutcome: "enum" as const,
	SubscriptionFeeSchedule: "enum" as const,
	SubscriptionStatus: "enum" as const,
	SubscriptionTierExperimentName: "enum" as const,
	SubscriptionTierLevel: "enum" as const,
	SwitchProtectedLoginToExistingLoginOutcome: "enum" as const,
	SwitchProtectedLoginToExistingNonConnectedLoginOutcome: "enum" as const,
	TablessMarketplaceCategoryType: "enum" as const,
	TextFormatter: "enum" as const,
	TextInputValidators: "enum" as const,
	TextInputWarning: "enum" as const,
	TrackNewImpressionsOutcome: "enum" as const,
	TrackNotificationCenterEntryClickOutcome: "enum" as const,
	UXPromptResponseType: "enum" as const,
	UnderwritingCategory: "enum" as const,
	UnderwritingRule: "enum" as const,
	UpdateBankAccountJointStatusOutcome: "enum" as const,
	UserFlag: "enum" as const,
	UserFriendlyCardNetwork: "enum" as const,
	UserPromptType: "enum" as const,
	BigDecimal: `scalar.BigDecimal` as const,
	Date: `scalar.Date` as const,
	Long: `scalar.Long` as const,
	UUID: `scalar.UUID` as const
}

export const ReturnTypes: Record<string,any> = {
	include:{
		if:"Boolean"
	},
	skip:{
		if:"Boolean"
	},
	deprecated:{
		reason:"String"
	},
	specifiedBy:{
		url:"String"
	},
	LoginUnion:{
		"...on LoginFailure":"LoginFailure",
		"...on LoginSuccess":"LoginSuccess"
	},
	RegisterOneTimeCardUnion:{
		"...on RegisterOneTimeCardInvalidInput":"RegisterOneTimeCardInvalidInput",
		"...on RegisterOneTimeCardSuccess":"RegisterOneTimeCardSuccess"
	},
	AccountConnectedResponseFetcher:{
		payrollAccountId:"String"
	},
	ActiveBankLogins:{
		logins:"FrontendBankLogin"
	},
	ApprovedLoanAmounts:{
		approvedLoanAmounts:"BigDecimal"
	},
	AutoAdvanceDetails:{
		amount:"BigDecimal",
		deliveryDate:"String",
		dueDate:"String",
		isAutoEnabled:"Boolean",
		willAutoDisburse:"Boolean"
	},
	AverageScoreChangeDetailsFetcher:{
		averageScoreChange:"Int",
		samplePeriodEnd:"String",
		samplePeriodStart:"String"
	},
	BankAccountPrediction:{
		incomeSources:"Income_32_Source",
		nextPayday:"String",
		overdraftLikelihood:"OverdraftLikelihood",
		zeroDate:"String"
	},
	BankConnection:{
		accountMask:"String",
		balanceAvailable:"BigDecimal",
		balanceCurrent:"BigDecimal",
		bankLoginId:"String",
		bankProvider:"BankProvider",
		checkingAccounts:"FrontendAccountWithCard",
		institution:"BankInstitution",
		lastUpdatedTimestamp:"String",
		paymentAuthFlow:"FrontendPaymentAuthFlow",
		protectedAccountId:"String",
		status:"FrontendBankConnectionStatus"
	},
	BankInstitution:{
		id:"String",
		institutionName:"String",
		logoUrl:"String",
		type:"FrontendBankInstitution"
	},
	ChangeSubscriptionTierResponse:{
		outcome:"ChangeSubscriptionTierOutcome",
		updatedSubscription:"Subscription"
	},
	CompleteQuizFetcher:{
		id:"String"
	},
	CreatePayrollAccountResponse:{
		payrollAccountId:"String"
	},
	CreatePayrollAccountResponseV2:{
		outcome:"CreatePayrollAccountOutcome"
	},
	CreateTargetDepositDestinationResponse:{
		encryptedDDSConfig:"String"
	},
	CreditBuilderEnrollmentEligibility:{
		eligible:"Boolean",
		enrollmentType:"CreditBuilderEnrollmentType",
		ineligibilityDetails:"CreditBuilderEnrollmentIneligibilityDetails"
	},
	CreditBuilderEnrollmentIneligibilityDetails:{
		description:"String",
		reason:"CreditBuilderEnrollmentIneligibilityReason"
	},
	CreditBuilderGlobalConfiguration:{
		unsupportedStates:"UsaState"
	},
	CreditBuilderInstallmentLoan:{
		availableSavingsBalance:"BigDecimal",
		chargePolicy:"FrontendInstallmentLoanChargePolicy",
		closedOn:"String",
		disbursals:"CreditBuilderTransaction",
		expectedClosedDate:"String",
		expectedDayOfMonthForBilling:"Int",
		externalMonthlyContributionAmountInDollars:"BigDecimal",
		installmentLoanPaymentSchedule:"InstallmentLoanPaymentSchedule",
		internalMonthlyContributionAmountInDollars:"BigDecimal",
		openedOn:"String",
		pastDuePayment:"PastDuePayment",
		paymentStrategyForSchedule:"FrontendInstallmentLoanPaymentStrategy",
		payments:"CreditBuilderTransaction",
		prePayment:"PrePayment",
		proposedDraftInstallmentLoanPaymentSchedule:"InstallmentLoanPaymentSchedule",
		reminders:"CreditBuilderReminder",
		sameDatePayment:"SameDayPayment",
		savingsDetails:"CreditBuilderSavingsDetails",
		scoreDetails:"CreditBuilderScoreDetails",
		termInMonths:"Long",
		totalLoanAmountInDollars:"BigDecimal",
		totalMonthlyPaymentAmountInDollars:"BigDecimal"
	},
	CreditBuilderReminder:{
		description:"String",
		type:"FrontendCreditBuilderReminderType"
	},
	CreditBuilderSavingsDetails:{
		allTimeSavings:"BigDecimal"
	},
	CreditBuilderScoreDetails:{
		allTimeScoreDelta:"Long"
	},
	CreditBuilderTransaction:{
		amountInDollars:"BigDecimal",
		dueOn:"String",
		installmentLoanPaymentId:"Long",
		source:"FrontendCreditBuilderTransactionSource",
		status:"FrontendCreditBuilderTransactionStatus",
		type:"FrontendCreditBuilderTransactionType",
		updatedAt:"String"
	},
	CreditBuilderUserDetails:{
		populationType:"CreditBuilderPopulationType"
	},
	CreditLimitBoostDetails:{
		boostAmount:"BigDecimal",
		boostAvailable:"Boolean",
		boostedAdvanceOptions:"BigDecimal",
		boostedMaxLoanAmount:"BigDecimal",
		creditLimitBoostId:"String",
		creditLimitBoostStatus:"CreditLimitBoostStatus",
		shouldOfferBoost:"Boolean"
	},
	CreditMonitoringEnrollment:{
		creditScores:"CreditScore",
		enrollmentStatus:"CreditMonitoringEnrollmentStatus",
		recentScoreChange:"RecentScoreChangeFetcher",
		scoreMarker:"CreditScoreMarkerFetcher"
	},
	CreditMonitoringUser:{
		authToken:"String"
	},
	CreditScore:{
		date:"String",
		score:"Long"
	},
	CreditScoreMarkerFetcher:{
		averageScoreChangeDetails:"AverageScoreChangeDetailsFetcher",
		days:"Long",
		period:"Long",
		scoreBucket:"ScoreBucketFetcher",
		userScoreChange:"Long"
	},
	DeleteBankLogin:{
		outcome:"DeleteBankLoginOutcome"
	},
	DeletePayrollAccountResponse:{
		payrollAccountId:"String"
	},
	DisableDirectDepositsResponse:{
		outcome:"DisableDirectDepositOutcome"
	},
	DisablePayrollAccountResponse:{
		payrollAccountId:"String"
	},
	Discount:{
		disclaimer:"String",
		discountedAmount:"BigDecimal",
		discountedPaymentDates:"Date"
	},
	DynamicPromoCards:{
		affiliateLinkUrl:"String",
		backgroundColors:"String",
		copyColor:"CopyColor",
		ctaCopy:"String",
		description:"String",
		iconUrl:"String",
		id:"Long",
		logoUrl:"String",
		offerName:"String"
	},
	FilteredSalaryData:{
		filter:"String",
		salary:"Int"
	},
	FrontendAccountWithCard:{
		bankAccountId:"String",
		card:"FrontendDebitCardInfo",
		currentBalance:"BigDecimal",
		isEligibleProtectedAccount:"Boolean",
		isJoint:"Boolean",
		isRemoved:"Boolean",
		mask:"String",
		name:"String"
	},
	FrontendBankAccount:{
		availableBalance:"BigDecimal",
		currentBalance:"BigDecimal",
		id:"String",
		isJoint:"Boolean",
		isRecommendedProtectedAccount:"Boolean",
		mask:"String",
		name:"String"
	},
	FrontendBankLogin:{
		bankAccounts:"FrontendBankAccount",
		createdAt:"String",
		id:"String",
		institution:"BankInstitution",
		institutionName:"String",
		isRecommendedProtectedLogin:"Boolean",
		status:"FrontendBankConnectionStatus"
	},
	FrontendDebitCardInfo:{
		blockStatus:"String",
		cardNickname:"String",
		expirationDate:"String",
		network:"UserFriendlyCardNetwork",
		paymentMethodId:"UUID",
		status:"FrontendDebitCardStatus"
	},
	FrontendExperimentVariantAssignment:{
		isControlFlow:"Boolean",
		variantName:"String",
		variantValue:"String"
	},
	FrontendMissedItem:{
		lateFeeAmountInCents:"Long",
		principalPaymentAmountInCents:"Long",
		scheduledDate:"String"
	},
	FrontendOverduePayment:{
		dateAccrued:"String",
		feeAmountInCents:"Long",
		principalPaymentAmountInCents:"Long"
	},
	FrontendPrincipalPayment:{
		amountInCents:"Long",
		paymentDate:"String"
	},
	FrontendUnderwritingCheck:{
		category:"UnderwritingCategory",
		isFulfilled:"Boolean",
		reason:"String",
		rule:"UnderwritingRule"
	},
	HomeScreenBanner:{
		callToActionText:"String",
		linkUrl:"String",
		message:"String"
	},
	ICActivationFAQs:{
		description:"String",
		id:"Int",
		title:"String"
	},
	ICExperimentProperties:{
		shouldDisplayUpsell:"Boolean"
	},
	IncomeProfile:{
		filterOne:"SalaryAggregateFilterCategory",
		filterOneValues:"String",
		filterTwo:"SalaryAggregateFilterCategory",
		filterTwoValues:"String",
		salaryAggregators:"SalaryAggregators"
	},
	Income_32_Source:{
		amountMean:"BigDecimal",
		dayOfMonth1:"Int",
		dayOfMonth2:"Int",
		dayOfWeek:"DayOfWeek",
		earlyPayOffset:"Int",
		endOfMonth:"Boolean",
		frequency:"PayFrequency",
		holidayAdjustNext:"Boolean",
		id:"String",
		incomePayerId:"String",
		periodMean:"Int",
		rejectionStatus:"String",
		status:"PersistedIncomeStatus",
		verified:"Boolean",
		weekOfMonth:"Int"
	},
	InitiateCatchUpPaymentForInstallmentLoan:{
		outcome:"FrontendInstallmentLoansPaymentOutcome"
	},
	InitiateOneTimePayment:{
		oneTimePaymentOutcome:"OneTimePaymentOutcome"
	},
	InitiatePayOffPaymentForInstallmentLoan:{
		outcome:"FrontendInstallmentLoansPaymentOutcome"
	},
	InitiatePrePaymentForInstallmentLoan:{
		outcome:"FrontendInstallmentLoansPaymentOutcome"
	},
	InputSalaryData:{
		outcome:"InputSalaryDataOutcome"
	},
	InstallmentLoanPaymentSchedule:{
		amountInDollars:"BigDecimal",
		paymentDate:"String"
	},
	InstallmentLoanPostDisbursalInfo:{
		disbursalBankAccountName:"String",
		disbursalDate:"String",
		disbursalStatus:"FrontendInstallmentLoansDisbursalStatus",
		estimatedDeliveryDate:"String"
	},
	InstallmentLoans:{
		currentLoanStatus:"FrontendInstallmentLoansCurrentStatus",
		currentOpenInstallmentLoan:"InstallmentLoansDetails",
		details:"details",
		kycDecision:"FrontendInstallmentLoansKYCDecisionType",
		signedLoanAgreement:"String",
		termRequestDetails:"TermRequestDetails",
		underwritingDetails:"LocUnderwritingDetails",
		unsignedProposedLoanAgreement:"String"
	},
	InstallmentLoansDetails:{
		autoPayStatus:"FrontendInstallmentLoansAutoPay",
		manualPaymentEligibilityStatus:"FrontendInstallmentLoansManualPaymentEligibilityStatus",
		missedItems:"FrontendMissedItem",
		nextScheduledPrincipalPayment:"FrontendPrincipalPayment",
		postDisbursalInstallmentLoanInfo:"InstallmentLoanPostDisbursalInfo",
		remainingDuePayments:"FrontendPrincipalPayment",
		signedLoanAgreement:"String",
		status:"FrontendInstallmentLoansStatus",
		totalPaidAmountForPrincipalPaymentsInCents:"Long",
		totalPrincipalAmountInCents:"Long",
		uuid:"String"
	},
	LineOfCredit:{
		creditLineLimitDetails:"LineOfCreditLineLimitDetails",
		kycRequirementResponse:"LocRequireKycResponse",
		kycRequirementStatus:"FrontendLocKycRequirementStatus",
		openAccount:"LineOfCreditAccount",
		paymentsBreakdown:"LocPaymentBreakdown",
		productName:"String",
		prospectiveTermDetails:"LineOfCreditProspectiveTerms",
		underwritingDetails:"LocUnderwritingDetails",
		unsignedProposedUserAgreement:"String"
	},
	LineOfCreditAccount:{
		accountStatus:"FrontendLocAccountStatus",
		accountUuid:"String",
		activeCreditDraw:"LineOfCreditDrawFetcher",
		autopayStatus:"FrontendLocAutopayStatus",
		creditDrawDetails:"LineOfCreditDrawRequestDetailsFetcher",
		creditLimitInCents:"Long",
		minPaymentDetails:"LineOfCreditMinPaymentDetailsFetcher",
		openedOn:"String",
		outstandingAccessFeeBalanceInCents:"Long",
		outstandingPrincipalBalanceInCents:"Long",
		paymentChargeType:"FrontendLocUserInitiatedPaymentChargeType",
		paymentHistory:"LineOfCreditPaymentHistory",
		prevDraw:"LineOfCreditDrawFetcher",
		statementDetails:"LineOfCreditStatementDetailsFetcher",
		totalBalanceRemainingIncludingFullFee:"Long",
		totalPaidAmountForPrincipalInCents:"Long"
	},
	LineOfCreditDrawFetcher:{
		disbursalAmountInCents:"Long",
		disbursalDate:"String",
		estimatedDeliveryDate:"String",
		paymentStatus:"FrontendPaymentStatus"
	},
	LineOfCreditDrawRequestDetailsFetcher:{
		amountDueTodayInCents:"Long",
		estimatedDeliveryDate:"String",
		maxDrawAmountInCents:"Long",
		minDrawAmountInCents:"Long",
		prospectiveMinPaymentAmount:"Long",
		prospectiveMinPaymentDate:"String"
	},
	LineOfCreditGlobalConfiguration:{
		supportedStates:"UsaState"
	},
	LineOfCreditLineLimitDetails:{
		maxDrawAmountInCents:"Long",
		minDrawAmountInCents:"Long"
	},
	LineOfCreditMinPaymentDetailsFetcher:{
		feeAmountInCents:"Long",
		minPaymentAmountInCents:"Long",
		minPaymentDate:"String",
		minPaymentStatus:"FrontendLocMinPaymentStatus",
		principalAmountInCents:"Long"
	},
	LineOfCreditPayment:{
		amountInCents:"Long",
		paymentAttemptType:"FrontendLocPaymentAttemptType",
		paymentDate:"String",
		paymentStatus:"FrontendPaymentStatus"
	},
	LineOfCreditPaymentHistory:{
		payments:"LineOfCreditPayment"
	},
	LineOfCreditProspectiveTerms:{
		annualFeesInCents:"Long",
		dayOfMonthForRecurringPayment:"Long",
		firstBillingCycleEnd:"String",
		firstBillingCycleStart:"String",
		minMonthlyPayment:"Long",
		monthlyFeeInCents:"Long",
		totalCreditLineAmountInCents:"Long",
		upcomingMinPaymentDate:"String"
	},
	LineOfCreditStatement:{
		endDate:"String",
		startDate:"String",
		uuid:"String"
	},
	LineOfCreditStatementDetailsFetcher:{
		currPeriodEndDate:"String",
		currPeriodStartDate:"String",
		prevStatementBalance:"Long",
		remainingStatementBalance:"Long",
		statementHistory:"LineOfCreditStatement"
	},
	LoanTimelineEvent:{
		description:"String",
		dollarAmount:"BigDecimal",
		returnType:"FrontendReturnType",
		status:"LoanTimelineStatus",
		timestamp:"String",
		type:"LoanTimelineType"
	},
	Loans:{
		daysPastCurrentDueDate:"Long",
		daysPastDue:"Long",
		dueDate:"String",
		express:"Boolean",
		expressTransferFeeDollars:"BigDecimal",
		extendable:"Boolean",
		loanId:"String",
		notRecoverableReason:"NonRepaymentReason",
		originationDate:"String",
		originationDollarAmount:"BigDecimal",
		outstandingDollarAmount:"BigDecimal",
		prechargeOff:"Boolean",
		repaidDollarAmount:"BigDecimal",
		repayable:"Boolean",
		timeline:"LoanTimelineEvent"
	},
	LocPaymentBreakdown:{
		accessFeeAmountInCents:"Long",
		lengthInMonth:"Long",
		minimumPaymentAmountInCents:"Long",
		principalPaymentAmountInCents:"Long"
	},
	LocRequireKycResponse:{
		requirementStatus:"FrontendLocKycRequirementStatus",
		unfulfillmentReasons:"FrontendLocKycUnfulfillmentReasons"
	},
	LocUnderwritingDetails:{
		unapprovalReasons:"String",
		underwritingStatus:"FrontendLocUnderwritingStatus"
	},
	LoginFailure:{
		loginFailureReason:"LoginFailureReason",
		userFacingMessage:"String"
	},
	LoginSuccess:{
		token:"String",
		userId:"String"
	},
	MarkNotificationCenterEntriesViewed:{
		outcome:"MarkNotificationCenterEntriesViewedOutcome"
	},
	MarkUserViewedClgModal:{
		userFlags:"UserFlag"
	},
	MarketplaceBadge:{
		displayName:"String",
		id:"Long",
		type:"MarketplaceBadgeType"
	},
	MarketplaceCategory:{
		description:"String",
		displayName:"String",
		hasBadge:"Boolean",
		type:"TablessMarketplaceCategoryType"
	},
	MarketplaceMaintenance:{
		activeMaintenance:"Boolean",
		feature:"MarketplaceFeature"
	},
	MarketplaceOfferByCategory:{
		category:"TablessMarketplaceCategoryType",
		categoryIconUrl:"String",
		offers:"TablessMarketplaceOffer"
	},
	MarketplaceOffersV2:{
		badgeCount:"Int",
		categories:"MarketplaceCategory",
		fullOffers:"TablessMarketplaceOffer"
	},
	MoneyLionEngineOffers:{
		leadUUID:"String",
		loanOffers:"PartnerLoanOffers",
		specialOffers:"PartnerSpecialOffers",
		uUID:"String"
	},
	MoneyLionLoanOffers:{
		apr:"Float",
		companyUuid:"String",
		disclosure:"String",
		estPayment:"Float",
		expiration:"String",
		financialInstitutionUuid:"String",
		loanAmount:"Int",
		originatorId:"String",
		partnerImageUrl:"String",
		partnerName:"String",
		term:"String",
		url:"String",
		uuid:"String"
	},
	MoneyLionSpecialOffers:{
		desc:"String",
		name:"String",
		partnerImageUrl:"String",
		partnerName:"String",
		payout:"Float",
		productSubType:"ProductSubType",
		recommendationScore:"Float",
		url:"String",
		uuid:"String"
	},
	Mutation:{
		accountConnected:"AccountConnectedResponseFetcher",
		changeSubscriptionTier:"ChangeSubscriptionTierResponse",
		completeQuiz:"CompleteQuizFetcher",
		createCreditBuilderUserAccount:"FrontendCreditBuilderEnrollmentStatus",
		createCreditMonitoringUser:"CreditMonitoringUser",
		createInstallmentLoansAccount:"FrontendInstallmentLoansAccountCreationStatus",
		createPayrollAccount:"CreatePayrollAccountResponse",
		createPayrollAccountV2:"CreatePayrollAccountResponseV2",
		createTargetDepositDestination:"CreateTargetDepositDestinationResponse",
		deleteBankLogin:"DeleteBankLogin",
		deletePayrollAccount:"DeletePayrollAccountResponse",
		disableDirectDeposits:"DisableDirectDepositsResponse",
		disablePayrollAccount:"DisablePayrollAccountResponse",
		initiateCatchUpPaymentForInstallmentLoan:"InitiateCatchUpPaymentForInstallmentLoan",
		initiateCreditDraw:"FrontendInitiateCreditDrawStatus",
		initiateOneTimePayment:"InitiateOneTimePayment",
		initiatePayOffPaymentForInstallmentLoan:"InitiatePayOffPaymentForInstallmentLoan",
		initiatePrePaymentForInstallmentLoan:"InitiatePrePaymentForInstallmentLoan",
		initiateUserPaymentForLoc:"FrontendLocInitiatePaymentStatus",
		inputSalaryData:"InputSalaryData",
		login:"LoginUnion",
		markNotificationCenterEntriesViewed:"MarkNotificationCenterEntriesViewed",
		markUserViewedClgModal:"MarkUserViewedClgModal",
		openLocAccount:"FrontendLocAccountCreationStatus",
		publishCreditBuilderDraftPaymentSchedule:"FrontendCBScheduleVersionStatus",
		recordUserPromptResponse:"RecordUserPromptResponse",
		registerBankLoginMaintainingExistingLogin:"RegisterBankLoginMaintainingExistingLogin",
		registerOneTimeCard:"RegisterOneTimeCardResponse",
		registerOneTimeCardV2:"RegisterOneTimeCardUnion",
		repayAdvance:"RepayAdvance",
		requestManualDisbursal:"RequestManualDisbursal",
		resendAuthVerificationCode:"ResendAuthVerificationCode",
		scheduleCreditBuilderSameDayManualPayment:"ScheduleCreditBuilderSameDayManualPayment",
		startOrResumeQuiz:"StartOrResumeQuizResponse",
		submitCreditBuilderPaymentChargePolicy:"FrontendInstallmentLoanChargePolicy",
		submitCreditBuilderPaymentStrategy:"FrontendCreditBuilderPaymentRetryStrategyType",
		submitMoneyLionPersonalLoansQuiz:"SubmitMoneyLionOffers",
		submitQuizFeedback:"SubmitQuizFeedbackFetcher",
		submitQuizResponse:"SubmitQuizResponse",
		submitResponseForPromptType:"SubmitUserPromptResponse",
		switchProtectedLoginToExistingLogin:"SwitchProtectedLoginToExistingLogin",
		switchProtectedLoginToExistingNonConnectedLogin:"SwitchProtectedLoginToExistingNonConnectedLogin",
		testDisablePayrollAccount:"DisablePayrollAccountResponse",
		throwTestException:"String",
		trackImpressionEvent:"TrackImpressionsFetcher",
		trackNotificationCenterEntryClick:"TrackNotificationCenterEntryClick",
		updateAutoPayStatusForInstallmentLoan:"UpdateInstallmentLoansAutoPayStatusFetcher",
		updateAutopayStatusForLoc:"FrontendLocAutopayStatus",
		updateBankAccountJointStatus:"UpdateBankAccountJointStatus",
		updateCreditLimitBoostStatus:"UpdateCreditLimitBoostStatus",
		updatePaymentChargeTypeForLoc:"FrontendLocUserInitiatedPaymentChargeType"
	},
	NotificationCenter:{
		notifications:"NotificationCenterEntry"
	},
	NotificationCenterEntry:{
		createdAt:"String",
		description:"String",
		destination:"String",
		hasViewed:"Boolean",
		id:"Long",
		imageUrl:"String",
		title:"String"
	},
	PageInfo:{
		endCursor:"String",
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean",
		startCursor:"String"
	},
	PartnerLoanOffers:{
		offers:"MoneyLionLoanOffers",
		partnerName:"String"
	},
	PartnerSpecialOffers:{
		offers:"MoneyLionSpecialOffers",
		partnerName:"String"
	},
	PastDuePayment:{
		amountDollars:"BigDecimal"
	},
	PayrollConnection:{
		payrollUserId:"String",
		userToken:"String"
	},
	Payroll_32_Account_32_Fetcher_32_With_32_Outcome:{
		outcome:"PayrollAccountFetcherOutcome",
		payrollAccounts:"Payroll_32_Accounts_32_V2"
	},
	Payroll_32_Accounts:{
		connectionStatus:"PayrollAccountConnectionStatus",
		employerName:"String",
		manualDisconnect:"Boolean",
		ongoingRefreshStatus:"OngoingRefreshStatus",
		payrollAccountId:"String"
	},
	Payroll_32_Accounts_32_V2:{
		connectionStatus:"FrontendPayrollAccountConnectionStatus",
		dDSEligibilityMessage:"String",
		eligibleForDDS:"Boolean",
		employerName:"String",
		manualDisconnect:"Boolean",
		ongoingRefreshStatus:"FrontendOngoingRefreshStatus",
		payrollAccountId:"String"
	},
	PfmSummary:{
		totalFeesPaid:"BigDecimal"
	},
	PrePayment:{
		amountDollars:"BigDecimal",
		dueDate:"String",
		payableAfter:"String"
	},
	PromoCardOffer:{
		affiliateLinkUrl:"String",
		description:"String",
		details:"String",
		id:"Long",
		logoUrl:"String",
		offerName:"String"
	},
	Promotion:{
		discountedAmount:"BigDecimal",
		numMonths:"Int",
		subscriptionAmountAfterDiscount:"BigDecimal",
		subscriptionTierLevel:"SubscriptionTierLevel"
	},
	ProtectionDetails:{
		loans:"Loans",
		protectionStatus:"ProtectionStatusV4",
		protectionStatusDescription:"String"
	},
	Query:{
		availableSubscriptionTiers:"SubscriptionTier",
		creditBuilderGlobalConfiguration:"CreditBuilderGlobalConfiguration",
		dynamicPromoCards:"DynamicPromoCards",
		experimentVariantAssignment:"FrontendExperimentVariantAssignment",
		iCActivationFAQs:"ICActivationFAQs",
		lineOfCreditGlobalConfiguration:"LineOfCreditGlobalConfiguration",
		marketplaceMaintenance:"MarketplaceMaintenance",
		me:"UserAccount",
		mobileVersionSupportType:"FrontendMobileVersionSupportType"
	},
	QuizFetcher:{
		completed:"Boolean",
		completedAt:"String",
		createdAt:"String",
		deletedAt:"String",
		id:"Long",
		quizName:"QuizName",
		quizTaker:"String",
		quizVersionId:"String"
	},
	QuizStateFetcher:{
		canExitEarly:"Boolean",
		currentStepId:"String",
		isResumable:"Boolean",
		steps:"Step",
		totalSteps:"Long"
	},
	RecentScoreChangeFetcher:{
		callToAction:"ScoreChangeCallToAction",
		scoreChange:"Int",
		scoreUpdateDate:"String",
		text:"RecentScoreChangeText"
	},
	RecordUserPromptResponse:{
		campaignPromptType:"CampaignPromptType",
		promptResponseType:"UXPromptResponseType"
	},
	Referral:{
		paid:"Boolean",
		payoutValue:"BigDecimal"
	},
	RegisterBankLoginMaintainingExistingLogin:{
		outcome:"RegisterBankLoginMaintainingExistingLoginOutcome"
	},
	RegisterOneTimeCardInvalidInput:{
		userFacingMessage:"String"
	},
	RegisterOneTimeCardResponse:{
		cardNickname:"String",
		paymentMethodId:"String"
	},
	RegisterOneTimeCardSuccess:{
		cardNickname:"String",
		paymentMethodId:"String"
	},
	RepayAdvance:{
		advanceId:"Long",
		repayAdvanceOutcome:"RepayAdvanceOutcome",
		repayAdvanceOutcomeReason:"String"
	},
	RequestManualDisbursal:{
		loanId:"Long",
		requestManualDisbursalOutcome:"RequestManualDisbursalOutcome",
		requestManualDisbursalReason:"String"
	},
	ResendAuthVerificationCode:{
		outcome:"ResendAuthVerificationCodeOutcome"
	},
	SalaryAggregators:{
		filterOne:"String",
		filterTwoData:"FilteredSalaryData",
		salary:"Int"
	},
	SameDayManualPayment:{
		amountInCents:"Long",
		paidOn:"String",
		status:"FrontendCreditBuilderTransactionStatus"
	},
	SameDayPayment:{
		amountDollars:"BigDecimal"
	},
	ScheduleCreditBuilderSameDayManualPayment:{
		outcome:"ScheduleCreditBuilderSameDayManualPaymentOutcome",
		sameDayManualPayment:"SameDayManualPayment"
	},
	ScoreBucketFetcher:{
		maximumScore:"Int",
		minimumScore:"Int"
	},
	ScoreCard:{
		maxScore:"Int",
		minApprovalScore:"Int",
		minScore:"Int",
		score:"Int",
		scoreCategories:"ScoreCategory"
	},
	ScoreCategory:{
		description:"String",
		name:"String",
		status:"ModelRank",
		subcategories:"ScoreSubcategory"
	},
	ScoreSubcategory:{
		description:"String",
		name:"String",
		status:"ModelRank"
	},
	SelectFieldFetcher:{
		icon:"String",
		optionDisplayValue:"String",
		selected:"Boolean"
	},
	StartOrResumeQuizResponse:{
		quiz:"QuizFetcher",
		state:"QuizStateFetcher"
	},
	Step:{
		assistiveCopy:"String",
		canSkip:"Boolean",
		centerAssistiveCopy:"Boolean",
		centerQuestionText:"Boolean",
		centered:"Boolean",
		disclosureCopy:"String",
		hasSensitiveResponse:"Boolean",
		heroIcon:"String",
		heroIconSize:"HeroIconSize",
		id:"String",
		isBranching:"Boolean",
		isLastStep:"Boolean",
		multipleSelect:"Boolean",
		nextStepId:"String",
		options:"SelectFieldFetcher",
		previousSelectedYesNoOption:"String",
		priorStepId:"String",
		questionText:"String",
		submitText:"String",
		supplementalCopy:"String",
		supplementalCopyIcon:"String",
		supplementalDisclosureCopy:"String",
		textFields:"TextFieldFetcher",
		type:"QuizStepType"
	},
	SubmitMoneyLionOffers:{
		leadUUID:"String",
		loanOffers:"PartnerLoanOffers",
		specialOffers:"PartnerSpecialOffers",
		uUID:"String"
	},
	SubmitQuizFeedbackFetcher:{
		id:"String"
	},
	SubmitQuizResponse:{
		state:"QuizStateFetcher"
	},
	SubmitUserPromptResponse:{
		outcome:"SubmitUserPromptOutcome"
	},
	Subscription:{
		currentTier:"SubscriptionTier",
		currentTierValidFromTimestamp:"String",
		discount:"Discount",
		isEligibleForReactivationExperience:"Boolean",
		proposedBillDate:"String",
		subscriptionFeeSchedule:"SubscriptionFeeSchedule",
		subscriptionPayments:"SubscriptionPayment",
		subscriptionTierExperiment:"SubscriptionTierExperiment",
		subscriptionTierStrategyName:"String"
	},
	SubscriptionPayment:{
		intendedAmount:"BigDecimal",
		isPayable:"Boolean",
		netAmount:"BigDecimal",
		paymentDate:"String",
		status:"SubscriptionStatus",
		subscriptionTierLevel:"SubscriptionTierLevel",
		uuid:"String"
	},
	SubscriptionTier:{
		displayName:"String",
		expressTransferFeeText:"String",
		level:"SubscriptionTierLevel",
		pricingAmount:"BigDecimal",
		products:"Product"
	},
	SubscriptionTierExperiment:{
		experimentName:"SubscriptionTierExperimentName",
		variantName:"String"
	},
	SwitchProtectedLoginToExistingLogin:{
		outcome:"SwitchProtectedLoginToExistingLoginOutcome"
	},
	SwitchProtectedLoginToExistingNonConnectedLogin:{
		outcome:"SwitchProtectedLoginToExistingNonConnectedLoginOutcome"
	},
	TablessMarketplaceOffer:{
		affiliateLinkUrl:"String",
		badges:"MarketplaceBadge",
		categories:"TablessMarketplaceCategoryType",
		description:"String",
		details:"String",
		id:"Long",
		logoUrl:"String",
		offerName:"String"
	},
	TermRequestDetails:{
		applicationFeeInCents:"Long",
		estimatedDeliveryDateForPrincipalCredit:"String",
		monthlyFeeInCents:"Long",
		payFrequency:"FrontendInstallmentLoansPayFrequency",
		paymentSchedule:"FrontendPrincipalPayment",
		totalMonthsInTerm:"Long",
		totalPrincipalAmountInCents:"Long"
	},
	TextFieldFetcher:{
		initValue:"String",
		isSensitive:"Boolean",
		keyboardType:"KeyboardType",
		label:"String",
		textFormat:"TextFormatter",
		validate:"TextInputValidators",
		warn:"TextInputWarning"
	},
	TrackImpressionsFetcher:{
		impression:"UserImpression",
		outcome:"TrackNewImpressionsOutcome"
	},
	TrackNotificationCenterEntryClick:{
		outcome:"TrackNotificationCenterEntryClickOutcome"
	},
	UnderwritingDetails:{
		accountApprovalState:"FrontendAccountApprovalState",
		creditRiskState:"FrontendCreditRiskState",
		isApproved:"Boolean",
		latestCreditLimitBoost:"CreditLimitBoostDetails",
		maxLoanAmount:"BigDecimal",
		minLoanAmount:"BigDecimal",
		progress:"Int",
		scoreCard:"ScoreCard",
		underwritingChecks:"FrontendUnderwritingCheck",
		underwritingRulesMetadata:"UnderwritingRuleMetadata",
		userCreditLimitBoosts:"CreditLimitBoostDetails"
	},
	UnderwritingRuleMetadata:{
		cutoff:"String",
		name:"String"
	},
	UpdateBankAccountJointStatus:{
		outcome:"UpdateBankAccountJointStatusOutcome"
	},
	UpdateCreditLimitBoostStatus:{
		updatedCreditLimitBoostStatus:"FrontendCreditLimitBoostStatus"
	},
	UpdateInstallmentLoansAutoPayStatusFetcher:{
		outcome:"FrontendInstallmentLoansUpdateAutoPayOutcome"
	},
	UsaState:{
		code:"String",
		name:"String"
	},
	UserAccount:{
		activeBankLogins:"ActiveBankLogins",
		approvedLoanAmounts:"ApprovedLoanAmounts",
		autoAdvanceDetails:"AutoAdvanceDetails",
		bankAccountPrediction:"BankAccountPrediction",
		bankConnection:"BankConnection",
		creationDate:"String",
		creditBuilderEnrollmentEligibility:"CreditBuilderEnrollmentEligibility",
		creditBuilderInstallmentLoan:"CreditBuilderInstallmentLoan",
		creditBuilderSweepStakesQualification:"FrontendCreditBuilderSweepStakesQualificationType",
		creditBuilderUserDetails:"CreditBuilderUserDetails",
		creditMonitoringEnrollment:"CreditMonitoringEnrollment",
		dynamicPromoCardV1Offers:"PromoCardOffer",
		email:"String",
		emailToVerify:"String",
		extensionCredits:"Int",
		firstName:"String",
		flags:"UserFlag",
		freeInstantCashTransferState:"Boolean",
		homeScreenBanner:"HomeScreenBanner",
		iCExperimentProperties:"ICExperimentProperties",
		id:"String",
		identityProtectionEnrollmentStatus:"IdentityProtectionEnrollmentStatus",
		incomeProfile:"IncomeProfile",
		installmentLoans:"InstallmentLoans",
		lastName:"String",
		legacyProposedNextMonthDiscountedAmount:"BigDecimal",
		lineOfCredit:"LineOfCredit",
		locationState:"UsaState",
		marketplaceOffers:"MarketplaceOfferByCategory",
		marketplaceOffersV2:"MarketplaceOffersV2",
		moneyLionEngineOffers:"MoneyLionEngineOffers",
		notificationCenter:"NotificationCenter",
		payrollAccounts:"Payroll_32_Accounts",
		payrollAccountsV2:"Payroll_32_Accounts_32_V2",
		payrollAccountsWithOutcome:"Payroll_32_Account_32_Fetcher_32_With_32_Outcome",
		payrollConnection:"PayrollConnection",
		pfmSummary:"PfmSummary",
		phoneNumber:"String",
		phoneNumberToVerify:"String",
		premiumUpsellCardVariant:"PremiumUpsellCardVariant",
		promoCardOffers:"PromoCardOffer",
		promotion:"Promotion",
		protectionDetails:"ProtectionDetails",
		referralLink:"String",
		referrals:"Referral",
		subscription:"Subscription",
		subscriptionFeeBillingScheduleText:"String",
		subscriptionScheduleText:"String",
		underwritingDetails:"UnderwritingDetails",
		userCreditBuilderDebtPersona:"UserDebtPersona",
		userDebtPersona:"UserDebtPersona",
		userPrompts:"UserPrompts"
	},
	UserDebtPersona:{
		debtPersonaType:"DebtPersonaType",
		quizId:"Long"
	},
	UserImpression:{
		durationMs:"Int",
		elementName:"ImpressionableElement",
		endedAt:"String",
		eventForeignKeys:"String",
		impressionId:"String",
		screenName:"String",
		startedAt:"String",
		userId:"String"
	},
	UserPrompt:{
		displayRank:"Long",
		type:"UserPromptType"
	},
	UserPrompts:{
		prompts:"UserPrompt"
	},
	details:{
		autoPayStatus:"FrontendInstallmentLoansAutoPay",
		catchupEligibility:"FrontendInstallmentLoansManualPaymentEligibilityStatus",
		disbursalBankAccountName:"String",
		disbursalDate:"String",
		disbursalStatus:"FrontendInstallmentLoansDisbursalStatus",
		estimatedDeliveryDateForPrincipalCredit:"String",
		lateFees:"FrontendOverduePayment",
		lateFeesDue:"Long",
		nextScheduledPrincipalPayment:"FrontendPrincipalPayment",
		prepayAndPayoffEligibility:"FrontendInstallmentLoansManualPaymentEligibilityStatus",
		principalPaymentOutstanding:"Long",
		remainingDuePayments:"FrontendPrincipalPayment",
		totalPaidAmountForPrincipalPaymentsInCents:"Long",
		totalPrincipalAmountInCents:"Long",
		uuid:"String"
	},
	BigDecimal: `scalar.BigDecimal` as const,
	Date: `scalar.Date` as const,
	Long: `scalar.Long` as const,
	UUID: `scalar.UUID` as const
}

export const Ops = {
mutation: "Mutation" as const,
	query: "Query" as const,
	subscription: "Subscription" as const
}