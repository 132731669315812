import '../styles/main.css';
import { ConnectorProps, SetPinApi } from '@hellobrigit/brigit-common';
import { isNil } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { SetWebFunnelSetNameAddContextAssignmentAction } from '../actions/onboardingActions';
import { BrigitRoute } from '../components/BrigitRoute';
import {
  BankConnectProgress,
  EmailAndNumberForm,
  LinkBank,
  SetPinForm,
} from '../components/onboarding';
import { OnboardingNavBar } from '../components/onboarding/OnboardingNavBar';
import SelectBankAccount from '../components/onboarding/SelectBankAccount';
import { SetNameForm } from '../components/onboarding/SetName';
import { Waitlist } from '../components/onboarding/Waitlist';
import { AppLinks } from '../constants/AppLinks';
import { OnboardingRoutes, OnboardingStep } from '../constants/OnboardingSteps';
import { AppState } from '../store';

const mapStateToProps = (state: AppState) => {
  const {
    api,
    onboardingStage: { step },
    user: {
      details: { flagsList },
      id,
    },
  } = state;
  return {
    flagsList,
    setPinCall: api.get(SetPinApi.id),
    step,
    id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPin: (params) => {
      SetPinApi.bindDispatch(dispatch)({ pin: params.pin });
    },
    ...bindActionCreators(
      { setWebFunnelSetNameAddContextAssignment: SetWebFunnelSetNameAddContextAssignmentAction },
      dispatch,
    ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectorProps<typeof connector>;

const OnboardingContainer: FunctionComponent<Props> = (props) => {
  const { step, setPin, setPinCall } = props;

  const shouldDisplayAppLinks = useMemo(() => {
    return (
      !isNil(step) && !(step === OnboardingStep.EMAIL_NUMBER || step === OnboardingStep.AUTH_PHONE)
    );
  }, [step]);

  const handleGooglePlayLinkClick = (event) => {
    event.preventDefault();
    analytics.track('Clicked Google Play link');
    // Give the external link time to be tracked before navigating away
    // The delay is the same delay Segment uses internally: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-link
    setTimeout(() => {
      window.location.href = AppLinks.GOOGLE_PLAY;
    }, 300);
  };

  const handleAppStoreLinkClick = (event) => {
    event.preventDefault();
    analytics.track('Clicked App Store link');
    // Give the external link time to be tracked before navigating away
    // The delay is the same delay Segment uses internally: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-link
    setTimeout(() => {
      window.location.href = AppLinks.APP_STORE;
    }, 300);
  };

  return (
    <Container className="min-height-100vh" fluid>
      <OnboardingNavBar step={step} />
      <Switch>
        <BrigitRoute
          path={OnboardingRoutes[OnboardingStep.EMAIL_NUMBER]}
          currentStep={step}
          component={<EmailAndNumberForm />}
          routeStep={OnboardingStep.EMAIL_NUMBER}
        />
        <BrigitRoute
          path={OnboardingRoutes[OnboardingStep.SET_PIN]}
          currentStep={step}
          component={<SetPinForm onSubmit={setPin} apiCall={setPinCall} />}
          routeStep={OnboardingStep.SET_PIN}
        />
        <BrigitRoute
          path={OnboardingRoutes[OnboardingStep.SET_NAME]}
          currentStep={step}
          component={<SetNameForm />}
          routeStep={OnboardingStep.SET_NAME}
        />
        <BrigitRoute
          path={OnboardingRoutes[OnboardingStep.LINK_BANK]}
          currentStep={step}
          component={<LinkBank />}
          routeStep={OnboardingStep.LINK_BANK}
        />
        <BrigitRoute
          path={OnboardingRoutes[OnboardingStep.SELECT_ACCOUNT]}
          currentStep={step}
          component={<SelectBankAccount />}
          routeStep={OnboardingStep.SELECT_ACCOUNT}
        />
        <BrigitRoute
          path={OnboardingRoutes[OnboardingStep.WAIT_FOR_BANK]}
          currentStep={step}
          component={<BankConnectProgress />}
          routeStep={OnboardingStep.WAIT_FOR_BANK}
        />
        <BrigitRoute
          path={OnboardingRoutes[OnboardingStep.WAITLIST]}
          currentStep={step}
          component={<Waitlist />}
          routeStep={OnboardingStep.WAITLIST}
        />
        {step && <Redirect to={OnboardingRoutes[step]} />}
      </Switch>
      {shouldDisplayAppLinks && (
        <Row className="justify-content-center title-margin-top brigit-row">
          <Col lg="6" md="6" sm="12">
            <div className="text-center">
              <a onClick={handleGooglePlayLinkClick} href={AppLinks.GOOGLE_PLAY}>
                <img className="pt-3 pr-1" style={{ width: '126px' }} src="/google_play.svg" />
              </a>
              <a onClick={handleAppStoreLinkClick} href={AppLinks.APP_STORE}>
                <img className="pt-3 pl-1" style={{ width: '126px' }} src="/app_store.svg" />
              </a>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default withRouter(connector(OnboardingContainer));
