import { ConnectorProps } from '@hellobrigit/brigit-common';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { BrigitRoute } from '../components/BrigitRoute';
import { AuthPinForm } from '../components/login/AuthPin';
import { LoginForm } from '../components/login/LoginForm';
import { OnboardingNavBar } from '../components/onboarding/OnboardingNavBar';
import { OnboardingStep } from '../constants/onboarding';
import { OnboardingRoutes } from '../constants/OnboardingSteps';
import { AppState } from '../store';

const mapStateToProps = (state: AppState) => {
  const {
    onboardingStage: { step },
  } = state;
  return { step };
};

const connector = connect(mapStateToProps);

type Props = ConnectorProps<typeof connector>;

const LoginContainer: FunctionComponent<Props> = (props) => {
  const { step } = props;
  return (
    <Container className="min-height-100vh" fluid>
      <OnboardingNavBar hideLogout />
      <Switch>
        <BrigitRoute
          exact
          path={OnboardingRoutes[OnboardingStep.LOGIN]}
          currentStep={step}
          component={<LoginForm />}
          routeStep={OnboardingStep.LOGIN}
        />
        <BrigitRoute
          path={OnboardingRoutes[OnboardingStep.AUTH_PIN]}
          currentStep={step}
          component={<AuthPinForm />}
          routeStep={OnboardingStep.AUTH_PIN}
        />
      </Switch>
    </Container>
  );
};

export default connector(LoginContainer);
