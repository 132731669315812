import React, { CSSProperties, FunctionComponent } from 'react';
import { P1 } from './Typography';

interface Props {
  style?: CSSProperties;
}

export const ListHeader: FunctionComponent<Props> = ({ style, children }) => {
  return (
    <div
      className="bg-lightest"
      style={{
        paddingTop: 11,
        paddingBottom: 11,
        paddingLeft: 15,
        paddingRight: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...style,
      }}
    >
      <P1 className="bold mb-0 font-grey">{children}</P1>
    </div>
  );
};
